import { PaymentMethod } from "./model/paymentTransaction";

export interface Payment {
    paymentMethod: PaymentMethod;
    amount: number;
    maskedAccountNumber: string;
    customerId?: number;
    eProducerAccountId?: number;
    lastName: string;
    firstName: string;
    companyName: string;
    institutionName: string;
    processingFee: number;
    systemAccountId?: number;
    referenceNumber: string;
    comments: string;
    email: string;
    phone: string;
    zip: string;
    state: string;
    city: string;
    suiteAptNumber: string;
    address: string;
    routingNumber: string;
    accountNumber: string;
    securityCode: string;
    cardNumber: string;
    expirationMonth: string;
    expirationYear: string;
    createdDateTime?: Date;
    checkNumber?: string;
}