import { AnalyticsService } from "../../../../../api/analyticsService";
import { CacheFactory } from "../../../../../api/types/cacheFactory";
import { CacheStore } from "../../../../../api/types/cacheStore";
import { BusyIndicator } from "../../../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../../../configuration/injectables";
import { DashboardService } from "../../dashboardService";
import { DashboardWidget } from "../../types/dashboardWidget";
import { StartEndDate } from "../activityTrackerAgent/dashboardWidgetActivityTracker";
import app from "../../../../../main";
import { IScope } from "angular";
import * as angular from "angular";
import * as moment from "moment";
import { JQueryService } from "../../../../../utilities/jquery/jQueryService";
import { AmChartService, AgentActivityTrackerChartOptions } from "../../../../../utilities/amCharts/amChartService";

type DashboardWidgetActivityTrackerCarrierFilterOptions = {
    date: StartEndDate;
};

class DashboardWidgetActivityTrackerCarrierController {

    public static $inject = [
        Injectables.$scope, 
        Injectables.AnalyticsService, 
        Injectables.DashboardService, 
        Injectables.CacheFactory,
        Injectables.JQueryService,
        Injectables.AmChartService
    ];

    constructor(
        private readonly $scope: IScope,
        private readonly analyticsService: AnalyticsService,
        private readonly dashboardService: DashboardService,
        private readonly cacheFactory: CacheFactory,
        private readonly jQueryService: JQueryService,
        private readonly amChartService: AmChartService
    ) {}

    public widget: DashboardWidget;
    public busyIndicator: BusyIndicator;
    public widgetIndex: number;
    public cacheStore: CacheStore;
    public amCharts: any;
    public filters: DashboardWidgetActivityTrackerCarrierFilterOptions;
    

    public loadChart = () => {
        this.busyIndicator.promise = this.analyticsService.getCarrierActivities()
            .then((data) => {

                const chartOptions: AgentActivityTrackerChartOptions = {
                    submissionsHidden: this.widget.options.submissionsIsHidden,
                    newBusinessHidden: this.widget.options.newBusineessIsHidden,
                    onDataVisiblityChanged: this.toggleDataVisiblity
                };

                this.amChartService.makeAgentActivityTrackerChart("chartdiv" + this.widgetIndex, data, chartOptions);
            });
    }

    public toggleDataVisiblity = (evt) => {
        this.widget.options[evt.dataItem.valueField + 'IsHidden'] = evt.dataItem.hidden;
        this.dashboardService.save();
    }

    public $onInit = () => {
        this.amCharts = (window as any).AmCharts;

        this.busyIndicator = {
            message: 'Loading...'
        };

        this.cacheStore = this.cacheFactory.get('dashboard');

        if (!this.cacheStore) {
            this.cacheStore = this.cacheFactory('dashboard', {});
        }

        this.filters = {
            date: {
            startDate: moment().subtract(1, 'months').subtract(1, 'days'),
            endDate: moment().subtract(1, 'days')
            }
        };

        this.$scope.$watch('vm.filters.date', (newDate: StartEndDate) => {
            this.jQueryService.getElement(".this.amChartsPeriodSelector .this.amCharts-start-date-input")
                .val(moment(newDate.startDate).format('MM/DD/YYYY'));

            this.jQueryService.getElement(".this.amChartsPeriodSelector .this.amCharts-end-date-input")
                .val(moment(newDate.endDate).format('MM/DD/YYYY'))
                .trigger('blur');
        }, false);

        this.loadChart();

        this.dashboardService.refreshFunctions.push(this.loadChart);
    }
}

const dashboardWidgetActivityTrackerCarrierComponent = {
    templateUrl: 'app/states/common/dashboard/widgets/activityTrackerCarrier/dashboardWidgetActivityTrackerCarrier.html',
    controller: DashboardWidgetActivityTrackerCarrierController,
    controllerAs: 'vm',
    bindings: {
        removeWidget: '&', 
        widget: '=', 
        cloneWidget: '&',
        widgetIndex: '<'
    }
};

angular 
    .module('app')
    .component('dashboardWidgetActivityTrackerCarrier', dashboardWidgetActivityTrackerCarrierComponent);