import { IBaseEntity } from "./iBaseEntity";
import { UnderwritingConfiguration } from "./underwritingConfiguration";

export interface UnderwritingRequiredField extends IBaseEntity { 
    underwritingConfigurationId: number;
    fieldName: string;
    underwritingConfiguration: UnderwritingConfiguration;
    id: number;
    createdDateTime: Date;
}
