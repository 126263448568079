import app from "../../main";
import { Injectables } from "../injectables";

const ngToastConfig = (ngToast: any) => {
    ngToast.configure({
        verticalPosition: 'top',
        horizontalPosition: 'center',
        maxNumber: 3
    });
}

ngToastConfig.$inject = [Injectables.ngToastProvider];

app.config(ngToastConfig);