import { Quote } from "../quote";
import { DocumentVersion } from "./documentVersion";
import { IBaseEntity } from "./iBaseEntity";

export interface QuoteDocumentVersion extends IBaseEntity { 
    quoteId: number;
    documentVersionId: number;
    quote: Quote;
    documentVersion: DocumentVersion;
    id: number;
    createdDateTime: Date;
}