export default (element: JQuery) => {
    var value = element.attr('aa-option-value') ? element.attr('aa-option-value') : 'value';
    var text = element.attr('aa-option-text') ? element.attr('aa-option-text') : 'label';
    var field = element.attr('aa-field');
    var options = element.attr('options');

    element.attr('is-select', '');
    var unselectedText = element.attr('aa-unselected-text') ? element.attr('aa-unselected-text') : element.attr('aa-label');

    var preScript     = '<script type="text/ng-template" id="customPopupTemplate.html"><div class="custom-popup-wrapper" ng-style="{top: position().top+"px", left: position().left+"px"}" style="display: block; ng-show="isOpen() && !moveInProgress aria-hidden="{{!isOpen()}}"><p class="message">'+ unselectedText + '</p>';
    var scriptElement = '<ul class="dropdown-menu" role="listbox">';
    scriptElement += '<li class="uib-typeahead-match" ng-repeat="match in matches" + ng-selected="' + field + ' == opt.' + value + '" value="{{opt.' + value + '}}"  ng-disabled="opt.isDisabled" ng-mouseenter="selectActive($index)" ng-click="selectMatch($index)" role="option" id="{{::match.id}}"><div uib-typeahead-match index="$index" match="match" query="query" template-url="templateUrl"></div></li>';
    var postScript = '</ul></div></script>';

    var wrapPre = '<div class="container-fluid typeahead-demo">';
    var newElement = '<input placeholder="' + unselectedText + '" type="text" ng-model="' + field + '" uib-typeahead="opt as opt.' + text + ' for opt in ' + options + ' | filter:$viewValue | limitTo:8" class="form-control" typeahead-min-length="0">';
    var wrapPost = '</div>';

    var fullScriptElement = preScript + scriptElement + postScript;
    var fullElement = fullScriptElement + wrapPre + newElement + wrapPost;

    element.replaceWith(fullElement);
};