import { EProducerFormStep } from "./eProducerFormStep";

export interface EProducerForm { 
    steps: EProducerFormStep[];
    requiresCredit: boolean;
    allowsMultiplePeople: boolean;
    minimumEffectiveDate: Date;
    maximumEffectiveDate: Date;
    requiresYearsInBusiness: boolean;
    invalidBondAmountMessage: string;
}


