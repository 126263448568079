import * as angular from "angular";
import { IScope } from "angular";
import * as moment from "moment";
import { AnalyticsService } from "../../../../../api/analyticsService";
import { SfaaCodeService } from "../../../../../api/sfaaCodeService";
import { CacheFactory } from "../../../../../api/types/cacheFactory";
import { BusyIndicator } from "../../../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../../../configuration/injectables";
import app from "../../../../../main";
import { DashboardService } from "../../dashboardService";
import { DashboardWidget } from "../../types/dashboardWidget";
import { AmChartService } from "../../../../../utilities/amCharts/amChartService";
import DropdownOption from "../../../../../api/types/dropdownOption";
import { constants } from "../../../../../configuration/constants";

class DashboardWidgetStateActivityCarrierController {

    public static $inject = [
        Injectables.DashboardService,
        Injectables.AnalyticsService,
        Injectables.SfaaCodeService,
        Injectables.AmChartService
    ];

    constructor(
        private readonly dashboardService: DashboardService,
        private readonly analyticsService: AnalyticsService,
        private readonly sfaaCodeService: SfaaCodeService,
        private readonly amChartService: AmChartService
    ) {
    }

    public widget: DashboardWidget;
    public busyIndicator: BusyIndicator;
    public widgetIndex: number;
    public sfaaCodeOptions: DropdownOption<string>[];
    public transactionTypeOptions: DropdownOption<string>[];
    public dateFilter: { startDate: Date, endDate: Date };

    public loadTransactionTypes = () => {
        this.transactionTypeOptions = constants.transactionTypeDropdownOptions;
        this.transactionTypeOptions.unshift(
            { label: 'All Transaction Types', value: null }, 
            { isDivider: true }
        );
    }

    public loadSfaaCodes = () => {
        this.sfaaCodeOptions = [];

        this.sfaaCodeService.getAllSfaaCodeOptions()
            .then((options) => {
                this.sfaaCodeOptions = options;
                this.sfaaCodeOptions.unshift(
                    { label: 'All SFAA Codes', value: null }, 
                    { isDivider: true }
                );
            });
    }

    public loadChart = () => {

        var sfaaCodeId = null;
        if (this.widget.options.filters.selectedSfaaCode) {
            sfaaCodeId = this.widget.options.filters.selectedSfaaCode.value;
        }

        this.busyIndicator.promise = this.analyticsService.getCarrierStateActivity(
            this.widget.options.filters.transactionType.value, 
            this.dateFilter.startDate, 
            this.dateFilter.endDate, 
            this.widget.options.filters.selectedSfaaCode.value)
            .then((data) => {
                this.amChartService.makeUsaMapChart("mapDiv"+this.widgetIndex, data);
            });
    }

    public $onInit = () => {
        this.busyIndicator = {
            message: 'Loading...'
        };

        this.dateFilter = {
            startDate: moment().subtract(1, 'years').toDate(),
            endDate: moment().subtract(1, 'days').toDate()
        }
    
        this.loadSfaaCodes();
        this.loadTransactionTypes();

        this.dashboardService.refreshFunctions.push(this.loadChart);
        this.loadChart();
    }
}

const dashboardWidgetStateActivityCarrierComponent = {
    bindings: {
        widget: '=',
        widgetIndex: '<' 
    },
    templateUrl: 'app/states/common/dashboard/widgets/stateActivityCarrier/dashboardWidgetStateActivityCarrier.html',
    controller: DashboardWidgetStateActivityCarrierController,
    controllerAs: 'vm'
};

app.component('dashboardWidgetStateActivityCarrier', dashboardWidgetStateActivityCarrierComponent);