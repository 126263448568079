import { DateOfLastActivity } from "./dateOfLastActivity";
import { DateOfRequest } from "./dateOfRequest";
import { DoNotCombineIndicator } from "./doNotCombineIndicator";
import { FileSinceDate } from "./fileSinceDate";
import { FraudVictimIndicator } from "./fraudVictimIndicator";
import { HitCode } from "./hitCode";
import { Identityscans } from "./identityscans";
import { LinkIndicator } from "./linkIndicator";
import { Safescans } from "./safescans";
import { SSNAffirmIndicator } from "./sSNAffirmIndicator";

export interface USCreditFile { 
    hitCode: HitCode;
    fileSinceDate: FileSinceDate;
    dateOfLastActivity: DateOfLastActivity;
    dateOfRequest: DateOfRequest;
    linkIndicator: LinkIndicator;
    doNotCombineIndicator: DoNotCombineIndicator;
    fraudVictimIndicator: FraudVictimIndicator;
    safescans: Safescans;
    identityscans: Identityscans;
    nameMatch: string;
    addressDiscrepancyIndicator: string;
    ssnAffirmIndicator: SSNAffirmIndicator;
    createCode: string;
    fileStatus1: string;
    fileStatus2: string;
    fileStatus3: string;
    bureauCode: string;
}