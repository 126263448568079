import { Injectables } from "../../../configuration/injectables";
import { SystemAccountService } from "../../../api/systemAccountService";
import { IPromise } from "angular";
import { IDebounceDelayer } from "../../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../../utilities/tables/table";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { BondTransactionService } from "../../../api/bondTransactionService";
import { TransactionReportTableQueryOptions } from "./transactionReportTableQueryOptions";
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";
import { BondTransaction } from "../../../api/types/model/bondTransaction";

export class BondTransactionReportController {
    
    public static $inject = [
        Injectables.BondTransactionService,
        Injectables.IDebouceDelayer,
        Injectables.SystemAccountService,
        Injectables.CurrentUserResolver
    ];

    constructor(
        private readonly bondTransactionService: BondTransactionService,
        debouceDelayer: IDebounceDelayer,
        private readonly systemAccountService: SystemAccountService,
        private readonly currentUserResolver: CurrentUserResolver,
    ) {
        this.table = new Table(debouceDelayer);
        this.table.queryOptions.orderBy = "BondTransactions.CreatedDateTime DESC" ;
        this.table.onChange = this.loadData;
    }

    public readonly table: Table<BondTransaction, TransactionReportTableQueryOptions>;
    public isCarrier: boolean;
    public epicIntegrationEnabled: boolean;
    public busyIndicator: BusyIndicator;

    public exportExcelReport = () => {
        this.busyIndicator = {
            message: "Preparing Report...",
            promise: this.bondTransactionService.getBondTransactionExcelReport(
                this.table.queryOptions
            )
        };
    }

    public loadData = () => {
        this.busyIndicator.promise = this.bondTransactionService
            .getBondTransactionsReportItems(this.table.queryOptions)
            .then((response) => { 
                this.table.setData(response.items, response.totalRecordCount)
            });
    }

    public checkIntegrationActive(): IPromise<void> {
        return this.systemAccountService.isEpicIntegrationEnabled()
            .then((epicIntegrationEnabled) => {
                this.epicIntegrationEnabled = epicIntegrationEnabled;
            });
    }

    public $onInit(): void {
        this.isCarrier = this.currentUserResolver.getCurrentUser().systemAccount.isCarrier;
        this.busyIndicator = {
            message: "Loading Bond Transactions...",
        };
        
        this.checkIntegrationActive();
        this.loadData();
    }
}
