// tslint:disable:quotemark
// tslint:disable:object-literal-key-quotes

import { IPromise } from "angular";
import { AuthService } from "../../../api/authService";
import { Injectables } from "../../../configuration/injectables";
import app from "../../../main";
import { JQueryService } from "../../../utilities/jquery/jQueryService";
import { State } from "../../state";
import { LoginStateParams } from "./LoginStateParams";

export class LoginController {
    public static $inject = [
        Injectables.$state,
        Injectables.$stateParams,
        Injectables.AuthService,
        Injectables.JQueryService
    ];

    constructor(
        private readonly $state: State,
        private readonly $stateParams: LoginStateParams,
        private readonly authService: AuthService,
        private readonly jQueryService: JQueryService
    ) {}

    public loginData: any;
    public emailFocus: boolean;
    public formSubmitted: boolean;
    public emailValidated: boolean;
    public incorrectPassword: boolean;
    public message: string;
    public authPromise: IPromise<any>;

    public $onInit(): void {
        this.loginData = {};
        this.emailFocus = true;
        this.formSubmitted = false;
        this.emailValidated = false;
        this.incorrectPassword = false;
        this.message = "";

        if (this.$stateParams.email) {
            this.loginData.email = this.$stateParams.email;
        }

        if (this.$stateParams.message) {
            this.message = this.$stateParams.message;
        }

        this.jQueryService
            .getElement(".validation-notification")
            .addClass("ng-hide");
    }

    public login(formValid: boolean): void {
        this.jQueryService
            .getElement("#loginButton")
            .addClass("a-btn-a")
            .val("Let's look you up...");

        this.formSubmitted = true;

        if (formValid) {
            this.authPromise = this.authService
                .login(this.loginData)
                .then(() => {})
                .catch((err) => {
                    this.message = err.message;
                    this.incorrectPassword = true;

                    this.jQueryService
                        .getElement("#loginButton")
                        .removeClass("a-btn-a")
                        .val("Sign In");
                });
        } else {
            this.jQueryService
                .getElement("#loginButton")
                .removeClass("a-btn-a")
                .val("Sign In");
        }
    }

    public forgotPassword(): void {
        this.$state.go("forgotPassword", { email: this.loginData.email });
    }
}

app.controller("LoginController", LoginController);
