import { IBaseEntity } from "./iBaseEntity";
import { SystemAccount } from "./systemAccount";

export interface ReinstatementReason extends IBaseEntity { 
    code: string;
    description: string;
    systemAccountId: number;
    systemAccount: SystemAccount;
    id: number;
    createdDateTime: Date;
}