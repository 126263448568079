import { IBaseEntity } from "./iBaseEntity";
import { SystemAccount } from "./systemAccount";

export interface RuleCondition extends IBaseEntity {
    name: string;
    comments: string;
    parentRuleConditionId: number;
    ruleConditions: RuleCondition[];
    conditionType: RuleConditionType;
    operatorType: RuleOperatorType;
    comparingValue: string;
    systemAccountId: number;
    systemAccount: SystemAccount;
    masterApplicationQuestionId: number;
    requiresCredit: boolean;
    id: number;
    createdDateTime: Date;
}

export enum RuleConditionType {
    BondAmount = "BondAmount",
    UnderwritingQuestion = "UnderwritingQuestion"
}
export enum RuleOperatorType {
    GreaterThan = "GreaterThan",
    GreaterThanOrEqualTo = "GreaterThanOrEqualTo",
    LessThan = "LessThan",
    LessThanOrEqualTo = "LessThanOrEqualTo",
    EqualTo = "EqualTo",
    NotEqualTo = "NotEqualTo"
}