import { IHttpProvider } from "angular";
import app from "../../main";

const jsonFormatterInterceptor = () => {

    const cleanRequestDataObject = (obj: object) => {
        for (var property in obj) {
            if (obj.hasOwnProperty(property) && property.indexOf('$') == 0) {
                delete obj[property];
            } else if (obj[property] !== null && typeof obj[property] === 'object') {
                cleanRequestDataObject(obj[property]);
            }
        }
    }

    return {
        request: (request) => {
            
            if (request.data !== null && typeof request.data === 'object') {
                cleanRequestDataObject(request.data);
            }

            return request;
        },
        response: (response) => {
            return response;
        }
    }
}

app.factory('jsonFormatterInterceptor', jsonFormatterInterceptor);

app.config(['$httpProvider', ($httpProvider: IHttpProvider) => {
    $httpProvider.interceptors.push('jsonFormatterInterceptor');
}]);
