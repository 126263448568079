import { ObligeeService } from "../../api/obligeeService";
import { Obligee } from "../../api/types/model/obligee";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ObligeeDetail } from "../../states/common/obligeeDetail/obligeeDetails";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { ObligeeOptions } from "./obligeeModalOptions";
import { ObligeeResult } from "./obligeeModalResult";
import app from "../../main";

export class ModalObligeeController {

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.ObligeeService,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly $uibModalInstance: Modal<ObligeeResult>,
        private readonly options: ObligeeOptions,
        private readonly obligeeService: ObligeeService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) { }

    public obligee: ObligeeDetail;
    public busyIndicator: BusyIndicator;

    public $onInit(): void {
        this.busyIndicator = {};
        
        if (this.options.obligeeId) {
            this.loadObligee(this.options.obligeeId);
        } else {
            this.createObligee();
        }
    }

    private loadObligee(obligeeId: number) {
        this.busyIndicator.message = 'Loading...';
        this.busyIndicator.promise = this.obligeeService.getObligeeDetail(obligeeId)
            .then((obligee) => {
                this.obligee = obligee;
            });
    }

    private createObligee(): void {
        this.obligee = { isActive: true } as ObligeeDetail;
    }

    public save(): void {
        this.busyIndicator.message = 'Saving...';
        this.busyIndicator.promise = this.obligeeService.saveObligee(this.obligee)
            .then((response) => {
                this.obligee.id = response;
                this.toastMessageCreator.createSuccessMessage('Obligee saved successfully');
                this.$uibModalInstance.close({obligee: this.obligee as Obligee});
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to save the obligee');
            });
    }

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }
}

app.controller('ModalObligeeController', ModalObligeeController);