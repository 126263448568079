import { IWindowService } from "angular";
import { AuthDataLocalStorage } from "../../api/types/authDataLocalStorage";
import { RedirectDataLocalStorage } from "../../api/types/redirectDataLocalStorage";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { DocumentLibrarySettings } from "../../states/common/documentLibrary/DocumentLibrarySettings";

export class LocalStorageService {

    public static $inject = [
        Injectables.$window
    ];

    private readonly AUTHENTICATION_DATA_KEY: string = 'a3AuthorizationData';
    private readonly DOCUMENT_LIBRARY_SETTINGS_KEY: string = 'documentLibrarySettings';
    private readonly REDIRECT_KEY: string = 'a3RedirectData';

    constructor (
        private readonly $window: IWindowService
    ) {}

    private getData = (key: string) => {
        const dataString = this.$window.localStorage.getItem(key);

        if (!dataString) {
            return null;
        }

        return JSON.parse(dataString);
    }

    private setData = (key: string, obj: object) => {
        const dataString = JSON.stringify(obj);

        this.$window.localStorage.setItem(key, dataString);
    }

    private removeData = (key: string) => {
        try {
            this.$window.localStorage.removeItem(key);
        } catch (err) {
            console.log(err);
        }
    }

    public removeAuthenticationData = () => {
        this.removeData(this.AUTHENTICATION_DATA_KEY);
    }

    public setAuthenticationData = (authenticationData: AuthDataLocalStorage) => {
        this.setData(this.AUTHENTICATION_DATA_KEY, authenticationData);
    }

    public getAuthenticationData = (): AuthDataLocalStorage => {
        return this.getData(this.AUTHENTICATION_DATA_KEY);
    }

    public removeDocumentLibrarySettings = () => {
        this.removeData(this.DOCUMENT_LIBRARY_SETTINGS_KEY);
    }

    public setDocumentLibrarySettings = (documentLibrarySettings: DocumentLibrarySettings) => {
        this.setData(this.DOCUMENT_LIBRARY_SETTINGS_KEY, documentLibrarySettings);
    }

    public getDocumentLibrarySettings = (): DocumentLibrarySettings => {
        return this.getData(this.DOCUMENT_LIBRARY_SETTINGS_KEY);
    }

    public setA3RedirectData = (a3RedirectData: RedirectDataLocalStorage) => {
        this.setData(this.REDIRECT_KEY, a3RedirectData);
    }

    public getA3RedirectData = (): RedirectDataLocalStorage => {
        return this.getData(this.REDIRECT_KEY);
    }

    public removeA3RedirectData = () => {
        this.removeData(this.REDIRECT_KEY)
    }
}

app.service(Injectables.LocalStorageService, LocalStorageService);
