import { IPromise } from "angular";

export class SearchControl<T> {

    constructor() {
        this.emptyStateMessage = 'No records found';
        this.label = '';
        this.debounce = 250;
        this.allowSelectionClearing = false;
        this.isRequired = false;
        this.clearOnSelection = true;
    }

    public matches: T[];
    public selected: T;
    public label?: string;
    public emptyStateMessage?: string;
    public onSelect?: (item) => void;
    public name?: string;
    public placeholder?: string;
    public onSearch?: (searchPhrase: string) =>IPromise<T[]>;
    public debounce?: number;
    public allowSelectionClearing?: boolean;
    public isRequired: boolean;
    public isDisabled: boolean;
    public buildSelectionLabel?: () => string;
    public buildOptionLabel?: (item: T) => string;
    public clearOnSelection: boolean;
    public loadOnClick: boolean;
    public loadOnFocus: boolean;

    public clear = () => {
        this.matches = [];
        this.selected = null;
    }
}