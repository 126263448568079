import { IDirectiveFactory, IDirectiveLinkFn, IScope } from "angular";
import app from "../../main";
import Inputmask from "inputmask";

const emailMaskDirective: IDirectiveFactory = () => {

    const link: IDirectiveLinkFn = (
        scope: IScope,
        elem: JQuery
    ) => {
        const mask = new Inputmask({ alias: 'email' });
        mask.mask(elem);
    }

    return {
        link: link
    }
}

app.directive('maskEmail', emailMaskDirective);