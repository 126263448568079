import { Bond } from "../bond";
import { BondTransactionDocumentVersion } from "./bondTransactionDocumentVersion";
import { IBaseEntity } from "./iBaseEntity";
import { SystemAccount } from "./systemAccount";
import { User } from "./user";

export interface DocumentCorrectionRequest extends IBaseEntity { 
    status: DocumentCorrectionRequestStatus;
    userId: number;
    systemAccountId: number;
    comments: string;
    documentId: number;
    bondId: number;
    bondTransactionDocumentVersionId: number;
    bondTransactionDocumentVersion: BondTransactionDocumentVersion;
    user: User;
    document: Document;
    systemAccount: SystemAccount;
    bond: Bond;
    id: number;
    createdDateTime: Date;
}

export enum DocumentCorrectionRequestStatus {	
    Requested = "Requested",
    Completed = "Completed"
}