import { CustomerSources } from "../../api/types/customerSources";
import { Customer } from "../../api/types/model/customer";
import { CustomerLookupOptions } from "./customerLookupModalOptions";


export class CustomerLookupResult {
    constructor(
        public customer: Customer,
        public source: CustomerSources,
        public resultAction: CustomerLookupOptions) { }
}
