import { IBaseEntity } from "./iBaseEntity";
import { MasterApplicationQuestion } from "./masterApplicationQuestion";

export interface QuestionResponse extends IBaseEntity { 
    responseText: string;
    questionText: string;
    masterApplicationQuestion: MasterApplicationQuestion;
    masterApplicationQuestionId: number;
    originType: AdditionalQuestionResponseType;
    id: number;
    createdDateTime: Date;
}

export enum AdditionalQuestionResponseType {	
    Underwriting = "Underwriting",
    BondType = "BondType"
}

export interface IQuestionResponse extends IBaseEntity { 
    responseText: string;
    questionText: string;
    masterApplicationQuestion: MasterApplicationQuestion;
    masterApplicationQuestionId: number;
    originType: AdditionalQuestionResponseType;
}