import * as angular from "angular";
import { ITimeoutService } from "angular";
import { BondTypeService } from "../../../../../api/bondTypeService";
import { ODataFactory, ODataFilterCollection } from "../../../../../api/odata";
import { AuditLog } from "../../../../../api/types/model/auditLog";
import { AmSelectOption } from "../../../../../components/amSelectDropdown/amSelect";
import { BusyIndicator } from "../../../../../components/busyIndicator/busyIndicator";
import { constants } from "../../../../../configuration/constants";
import { Injectables } from "../../../../../configuration/injectables";
import app from "../../../../../main";
import { WidgetColumn } from "../../columnSelector/widgetColumn";
import { DashboardService } from "../../dashboardService";
import { DashboardWidget } from "../../types/dashboardWidget";


class DashboardWidgetNewlyActiveBondTypesController {

    public static $inject = [
        Injectables.ODataFactory,
        Injectables.DashboardService,
        Injectables.$timeout,
        Injectables.BondTypeService
    ];

    constructor(
        private readonly odata: ODataFactory,
        private readonly dashboardService: DashboardService,
        private readonly $timeout: ITimeoutService,
        private readonly bondTypeService: BondTypeService
    ) {
    }

    public orderby: string;
    public currentPage: number;
    public recordsPerPage: number;
    public widget: DashboardWidget;
    public filterCollection: ODataFilterCollection;
    public busyIndicator: BusyIndicator;
    public records: AuditLog[];
    public totalRecords: number;
    public totalPages: number;
    public columns: WidgetColumn[];
    public recordsPerPageOptions: AmSelectOption<number>[];
    public errorMessage: string;
    
    public hasFilters = () => {
        if (this.filterCollection.getEnabledFilters().length <= 0) { return false }
        return true;
    }

    public setRecordPerPage = (selectedOption) => {
        this.recordsPerPage = selectedOption.value;
        this.loadGrid();
        this.widget.options.recordsPerPage = this.recordsPerPage;
        this.dashboardService.save();
    }

    public setOrderby = (orderby) => {
        if (this.orderby.substring(0, orderby.length) === orderby) { // if same property as previous sort
            if (this.orderby.indexOf(' desc') === -1) { // toggle desc/asc
                orderby = orderby.replace(/,/g, ' desc,') + ' desc';
            } else {
                orderby = orderby.replace(' desc', '');
            }
        }
        this.orderby = orderby;
        this.widget.options.orderby = orderby;
        this.dashboardService.save();
    }

    public loadGrid = (orderby?: string) => {
        if (orderby) {
            this.setOrderby(orderby);
        }

        this.bondTypeService.getNewlyActiveBondTypeWidgetData(
                this.orderby,
                this.currentPage,
                this.recordsPerPage,
                this.filterCollection
            )
            .then((widgetData) => {
                this.records = widgetData.records;
                this.totalRecords = widgetData.totalRecords;
                this.totalPages = Math.ceil(this.totalRecords / this.recordsPerPage);
            })
            .catch(() => {
                this.errorMessage = "An error occurred trying to get the bond types";
            });
    }

    public $onInit = () => {
        this.busyIndicator = {
            message: 'Loading...'
        };
        
        this.recordsPerPageOptions = constants.recordsPerPageOptions;

        this.recordsPerPage = 10;
        this.orderby = 'createdDateTime desc';
        this.totalRecords = 0;
        this.currentPage = 1;
        this.totalPages = 1;

        this.columns = [];

        this.filterCollection = this.odata.getFilterCollection([]);

        if (angular.isDefined(this.widget.options.orderby)) {
            this.orderby = this.widget.options.orderby;
        }

        if (!angular.isDefined(this.widget.options.title)) {
            this.widget.options.title = 'Newly Activated Bond Types';
        }

        if (angular.isDefined(this.widget.options.recordsPerPage)) {
            this.recordsPerPage = this.widget.options.recordsPerPage;
        }

        if (angular.isDefined(this.widget.options.filters)) {
            this.filterCollection.mergeFilters(this.widget.options.filters);
        }

        this.$timeout(() => {
            this.loadGrid();
        });

        this.dashboardService.refreshFunctions.push(this.loadGrid);
    }
}

const dashboardWidgetNewlyActiveBondTypesComponent = {
    bindings: {
        removeWidget: '&',
        widget: '=',
        cloneWidget: '&',
        widgetIndex: '<'
    },
    templateUrl: 'app/states/common/dashboard/widgets/newlyActiveBondTypes/dashboardWidgetNewlyActiveBondTypes.html',
    controller: DashboardWidgetNewlyActiveBondTypesController,
    controllerAs: 'vm'
};

app.component('dashboardWidgetNewlyActiveBondTypes', dashboardWidgetNewlyActiveBondTypesComponent);    