import app from "../../main";
import { HasNames } from "./hasNames";

export const fullNameFilter = () => {

    return (obj: HasNames): string => {
        
        if (!obj) {
            return '';
        }

        return obj.firstName + ' ' + obj.lastName;
    }
};    

app.filter ('fullName', fullNameFilter);
