import {
    ODataFactory,
    ODataFilterCollection,
    ODataFilterDataType,
    ODataEndpoint
} from "../../../../../api/odata";
import { SfaaCodeService } from "../../../../../api/sfaaCodeService";
import { TagService } from "../../../../../api/tagService";
import { Bond } from "../../../../../api/types/bond";
import isDefined from "../../../../../utilities/angularUtilities/isDefined";
import { SelectOption } from "../../../../../api/types/selectOption";
import { BusyIndicator } from "../../../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../../../configuration/injectables";
import { DashboardService } from "../../dashboardService";
import { DashboardWidget } from "../../types/dashboardWidget";
import app from "../../../../../main";
import A3ApiResponse from "../../../../../api/types/a3ApiResponse";
import { ITimeoutService } from "angular";
import { Table } from "../../../../../utilities/tables/table";
import { IDebounceDelayer } from "../../../../../utilities/debounceDelayer/iDebouceDelayer";
import { CurrentUserResolver } from "../../../../../utilities/currentUserResolver/currentUserResolver";

class DashboardWidgetBondsCarrierController {
    public static $inject = [
        Injectables.ODataFactory,
        Injectables.DashboardService,
        Injectables.$timeout,
        Injectables.TagService,
        Injectables.SfaaCodeService,
        Injectables.CurrentUserResolver,
        Injectables.IDebouceDelayer
    ];

    constructor(
        private readonly odata: ODataFactory,
        private readonly dashboardService: DashboardService,
        private readonly $timeout: ITimeoutService,
        private readonly tagService: TagService,
        private readonly sfaaCodeService: SfaaCodeService,
        private readonly currentUserResolver: CurrentUserResolver,
        debouceDelayer: IDebounceDelayer
    ) {
        this.table = new Table(debouceDelayer);
    }

    public widget: DashboardWidget;
    public busyIndicator: BusyIndicator;
    public table: Table<Bond, null>;
    public filterCollection: ODataFilterCollection;
    public errorMessage: string;
    public sfaaCodeOptions: SelectOption<string>[];
    public tagOptions: SelectOption<string>[];

    public setFilters = () => {
        this.filterCollection = this.odata.getFilterCollection([
            this.odata.getFilter(
                "status",
                ODataFilterDataType.select,
                "Status",
                null,
                null,
                true,
                [
                    { label: "Active", value: "'Active'" },
                    {
                        label: "Pending Cancellation",
                        value: "'PendingCancellation'"
                    },
                    { label: "Cancelled", value: "'Cancelled'" },
                    {
                        label: "Pending Reinstatement",
                        value: "'PendingReinstatement'"
                    }
                ],
                false
            ),
            this.odata.getFilter(
                "bondAmount",
                ODataFilterDataType.money,
                "Bond Amount"
            ),
            this.odata.getFilter(
                "renewalStatus",
                ODataFilterDataType.select,
                "Renewal Application Status",
                null,
                null,
                true,
                [
                    {
                        label: "No Renewal Application",
                        value: "'None'"
                    },
                    {
                        label: "Renewal Application Submitted",
                        value: "'ApplicationSubmitted'"
                    }
                ],
                false
            ),
            this.odata.getFilter(
                "expirationDate",
                ODataFilterDataType.daysTill,
                "Expiration Date"
            ),
            this.odata.getFilter(
                "createdDateTime",
                ODataFilterDataType.date,
                "Purchase Date"
            ),
            this.odata.getFilter(
                "effectiveDate",
                ODataFilterDataType.date,
                "Effective Date"
            ),
            this.odata.getFilter(
                "expirationDate",
                ODataFilterDataType.date,
                "Expiration Date"
            ),
            this.odata.getFilter(
                "bondType/name",
                ODataFilterDataType.string,
                "Bond Type"
            ),
            this.odata.getFilter(
                "bondType/obligee/requisitioningState",
                ODataFilterDataType.state,
                "Requisitioning State"
            ),
            this.odata.getFilter(
                "bondType/sfaaCode/code",
                ODataFilterDataType.select,
                "SFAA Code",
                null,
                null,
                true,
                this.sfaaCodeOptions
            ),
            this.odata.getFilter(
                "Tags/any(t:contains(t/name,'{0}'))",
                ODataFilterDataType.select,
                "Tagged ",
                null,
                null,
                true,
                this.tagOptions,
                true,
                "with "
            ),
            this.odata.getFilter(
                "customer/eProducerAccount/name",
                ODataFilterDataType.string,
                "Broker Name",
                null,
                null,
                !this.currentUserResolver.getCurrentUser().systemAccount
                    .isCarrier
            ),
            this.odata.getFilter(
                "customer/eProducerAccount/agencyCode",
                ODataFilterDataType.string,
                "Broker Agency Code",
                null,
                null,
                !this.currentUserResolver.getCurrentUser().systemAccount
                    .isCarrier
            ),
            this.odata.getFilter(
                "customer/eProducerAccount/agencySubCode",
                ODataFilterDataType.string,
                "Broker Sub Code",
                null,
                null,
                !this.currentUserResolver.getCurrentUser().systemAccount
                    .isCarrier
            )
        ]);
    };

    public saveRecordsPerPage = () => {
        this.widget.options.recordsPerPage = this.table.pager.recordsPerPage;
        this.dashboardService.save();
    };

    public saveOrderby = () => {
        this.widget.options.orderby = this.table.sorter.sortBy;
        this.dashboardService.save();
    };
    public loadSfaaCodes = () => {
        return this.sfaaCodeService.getAllSfaaCodeOptions().then((options) => {
            this.sfaaCodeOptions = options;
        });
    };

    public loadTagOptions = () => {
        return this.tagService.loadTagOptions().then((tagOptions) => {
            this.tagOptions = tagOptions;
        });
    };

    public buildQuery = () => {
        const query = this.odata
            .getQuery()
            .orderby(this.table.sorter.sortBy)
            .skip(
                (this.table.pager.currentPage - 1) *
                    this.table.pager.recordsPerPage
            )
            .top(this.table.pager.recordsPerPage)
            .expand(
                "customer($select=id,name,customerType)," +
                    "people($select=id,cellPhone,homePhone,email)," +
                    "companies($select=id,phone,email)," +
                    "bondType($select=id,name)," +
                    "carrierSystemAccount($Select=companyName)"
            )
            .select(
                "id,customerId,nameOnBond,bondNumber,status,cancellationStatus,riderStatus,reinstatementStatus,bondAmount,effectiveDate,expirationDate,createdDateTime"
            );

        const filterExpr = this.filterCollection.getFilterExpression();
        if (filterExpr) {
            query.filter(filterExpr);
        }

        return query;
    };

    public loadGrid = () => {
        if (this.table.sorter.sortBy !== this.widget.options.orderby) {
            this.saveOrderby();
        }

        if (
            this.table.pager.recordsPerPage !=
            this.widget.options.recordsPerPage
        ) {
            this.saveRecordsPerPage();
        }

        var query = this.buildQuery();

        const dataSvc = this.odata.getService(ODataEndpoint.Bond);

        this.busyIndicator.promise = dataSvc
            .get<A3ApiResponse<Bond[]>>(query)
            .then((response) => {
                this.table.setData(
                    response.data.value,
                    response.data["@odata.count"]
                );
            })
            .catch(() => {
                this.errorMessage =
                    "An error occurred trying to get the widget data";
            });
    };

    public $onInit = () => {
        this.busyIndicator = {
            message: "Loading..."
        };

        this.tagOptions = [];
        this.sfaaCodeOptions = [];

        this.loadTagOptions();
        this.loadSfaaCodes();

        this.$timeout(() => {
            this.loadGrid();
        });

        if (isDefined(this.widget.options.filters)) {
            this.filterCollection.mergeFilters(this.widget.options.filters);
        }

        this.setFilters();

        this.dashboardService.refreshFunctions.push(this.loadGrid);

        this.table.onChange = this.loadGrid;
    };
}

const dashboardWidgetBondsCarrierComponent = {
    bindings: {
        widget: "="
    },
    templateUrl:
        "app/states/common/dashboard/widgets/bondsCarrier/dashboardWidgetBondsCarrier.html",
    controller: DashboardWidgetBondsCarrierController,
    controllerAs: "vm"
};

app.component(
    "dashboardWidgetBondsCarrier",
    dashboardWidgetBondsCarrierComponent
);
