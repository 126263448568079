import {
    ODataFactory,
    ODataFilterCollection,
    ODataFilterDataType,
    ODataEndpoint
} from "../../../../../api/odata";
import { TagService } from "../../../../../api/tagService";
import { Application } from "../../../../../api/types/model/application";
import isDefined from "../../../../../utilities/angularUtilities/isDefined";
import { SelectOption } from "../../../../../api/types/selectOption";
import { BusyIndicator } from "../../../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../../../configuration/injectables";
import { DashboardService } from "../../dashboardService";
import { DashboardWidget } from "../../types/dashboardWidget";
import app from "../../../../../main";
import A3ApiResponse from "../../../../../api/types/a3ApiResponse";
import { Table } from "../../../../../utilities/tables/table";
import { IDebounceDelayer } from "../../../../../utilities/debounceDelayer/iDebouceDelayer";
import { QuoteStatus } from "../../../../../api/types/model/quote";
import { CurrentUserResolver } from "../../../../../utilities/currentUserResolver/currentUserResolver";
import { UserService } from "../../../../../api/userService";
import { IQService } from "angular";

class DashboardWidgetOpenApplicationsListController {
    public static $inject = [
        Injectables.CurrentUserResolver,
        Injectables.ODataFactory,
        Injectables.DashboardService,
        Injectables.TagService,
        Injectables.IDebouceDelayer,
        Injectables.UserService,
        Injectables.$q
    ];

    constructor(
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly odata: ODataFactory,
        private readonly dashboardService: DashboardService,
        private readonly tagService: TagService,
        debouceDelayer: IDebounceDelayer,
        private readonly userService: UserService,
        private readonly $q: IQService
    ) {
        this.table = new Table(debouceDelayer);
    }

    public widget: DashboardWidget;
    public busyIndicator: BusyIndicator;
    public table: Table<Application, null>;
    public filterCollection: ODataFilterCollection;
    public tagOptions: SelectOption<string>[];
    public producerUserOptions: SelectOption<number>[];
    public clientServiceUserOptions: SelectOption<number>[];
    public errorMessage: string;

    public setFilters = () => {
        this.filterCollection = this.odata.getFilterCollection([
            this.odata.getFilter(
                "status",
                ODataFilterDataType.select,
                "Status",
                null,
                null,
                true,
                [
                    { label: "Submitted", value: "'Submitted'" },
                    { label: "Not Submitted", value: "'NotSubmitted'" },
                    { label: "Closed", value: "'Closed'" }
                ],
                false
            ),
            this.odata.getFilter(
                "quotes/any(q: q/status eq 'Approved')",
                ODataFilterDataType.bool,
                "Has Approved Quotes",
                null,
                null,
                true,
                [
                    { label: "Yes", value: "true" },
                    { label: "No", value: "false" }
                ]
            ),
            this.odata.getFilter(
                "applicationType",
                ODataFilterDataType.select,
                "Application Type",
                null,
                null,
                true,
                [
                    {label: "New Business", value: "'SingleBond'"},
                    {label: "Renewal", value: "'SingleBondRenewal'"}
                ],
                false
            ),
            this.odata.getFilter(
                "bondAmount",
                ODataFilterDataType.money,
                "Bond Amount"
            ),
            this.odata.getFilter(
                "createdDateTime",
                ODataFilterDataType.date,
                "Submitted"
            ),
            this.odata.getFilter(
                "bondType/name",
                ODataFilterDataType.string,
                "Bond Type"
            ),
            this.odata.getFilter(
                "customer/name",
                ODataFilterDataType.string,
                "Name"
            ),
            this.odata.getFilter(
                "desiredEffectiveDate",
                ODataFilterDataType.date,
                "Effective"
            ),
            this.odata.getFilter(
                "originSystem",
                ODataFilterDataType.select,
                "Source",
                null,
                null,
                true,
                [
                    {
                        label: "A3 Dashboard",
                        value: "'A3Dashboard'"
                    },
                    {
                        label: "EProducer",
                        value: "'EProducer'"
                    },
                    {
                        label: "Unknown",
                        value: "'Unknown'"
                    }
                ],
                false
            ),
            this.odata.getFilter(
                "producerUserId",
                ODataFilterDataType.select,
                "Producer ",
                null,
                null,
                !this.currentUserResolver.getCurrentUser().systemAccount.isCarrier,
                this.producerUserOptions
            ),
            this.odata.getFilter(
                "clientServiceAgentUserId",
                ODataFilterDataType.select,
                "Client service agent ",
                null,
                null,
                !this.currentUserResolver.getCurrentUser().systemAccount.isCarrier,
                this.clientServiceUserOptions
            ),
            this.odata.getFilter(
                "Tags/any(t:contains(t/name,'{0}'))",
                ODataFilterDataType.select,
                "Tagged ",
                null,
                null,
                true,
                this.tagOptions,
                true,
                "with "
            ),
            this.odata.getFilter(
                "customer/eProducerAccount/name",
                ODataFilterDataType.string,
                "Broker Name",
                null,
                null,
                !this.currentUserResolver.getCurrentUser().systemAccount.isCarrier
            ),
            this.odata.getFilter(
                "customer/eProducerAccount/agencyCode",
                ODataFilterDataType.string,
                "Broker Agency Code",
                null,
                null,
                !this.currentUserResolver.getCurrentUser().systemAccount.isCarrier
            ),
            this.odata.getFilter(
                "customer/eProducerAccount/agencySubCode",
                ODataFilterDataType.string,
                "Broker Sub Code",
                null,
                null,
                !this.currentUserResolver.getCurrentUser().systemAccount.isCarrier
            )
        ]);
    };

    public saveRecordsPerPage = () => {
        this.widget.options.recordsPerPage = this.table.pager.recordsPerPage;
        this.dashboardService.save();
    };

    public saveOrderby = () => {
        this.widget.options.orderby = this.table.sorter.sortBy;
        this.dashboardService.save();
    };

    public buildQuery = () => {
        const cols = this.widget.options.columns;
        let customerExpand = "id,",
            peopleExpand = "id,",
            companiesExpand = "id,",
            bondTypeExpand = "id,";

        customerExpand += cols.customer ? "name," : "";
        customerExpand += cols.phone || cols.email ? "customerType," : "";
        customerExpand = customerExpand.slice(0, -1);

        peopleExpand += cols.phone ? "cellPhone,homePhone," : "";
        peopleExpand += cols.email ? "email," : "";
        peopleExpand = peopleExpand.slice(0, -1);

        companiesExpand += cols.phone ? "phone," : "";
        companiesExpand += cols.email ? "email," : "";
        companiesExpand = companiesExpand.slice(0, -1);

        bondTypeExpand += cols.bondType ? "name," : "";
        bondTypeExpand += bondTypeExpand.slice(0, -1);

        const query = this.odata
            .getQuery()
            .orderby(this.table.sorter.sortBy)
            .skip(
                (this.table.pager.currentPage - 1) *
                    this.table.pager.recordsPerPage
            )
            .top(this.table.pager.recordsPerPage)
            .expand(
                "customer($select=" +
                    customerExpand +
                    "),people($select=" +
                    peopleExpand +
                    "),companies($select=" +
                    companiesExpand +
                    "),bondType($select=" +
                    bondTypeExpand +
                    "),quotes($select=status)"
            )
            .filter("status ne 'Completed'");

        // add filter expression if filters are set

        const filterExpr = this.filterCollection.getFilterExpression();
        if (filterExpr) {
            query.filter(filterExpr);

            if (filterExpr.indexOf("status") < 0) {
                query.and("status ne 'Closed'");
            }
        } else {
            // if not filtering by status then default th not include closed
            query.and("status ne 'Closed'");
        }

        return query;
    };

    public loadGrid = () => {
        if (this.table.sorter.sortBy !== this.widget.options.orderby) {
            this.saveOrderby();
        }

        if (
            this.table.pager.recordsPerPage !=
            this.widget.options.recordsPerPage
        ) {
            this.saveRecordsPerPage();
        }

        const query = this.buildQuery();

        const dataSvc = this.odata.getService(ODataEndpoint.Application);

        this.busyIndicator.promise = dataSvc
            .get<A3ApiResponse<Application[]>>(query)
            .then((response) => {
                for (let i = 0; i < response.data.value.length; i++) {
                    const app = response.data.value[i];
                    app["approvedQuoteCount"] = app.quotes.filter(
                        (quote) => quote.status == QuoteStatus.Approved
                    ).length;
                    app["declinedQuoteCount"] = app.quotes.filter(
                        (quote) => quote.status == QuoteStatus.Declined
                    ).length;
                    app["pendingQuoteCount"] = app.quotes.filter(
                        (quote) => quote.status == QuoteStatus.Pending
                    ).length;
                }
                this.table.setData(
                    response.data.value,
                    response.data["@odata.count"]
                );
            })
            .catch(() => {
                this.errorMessage =
                    "An error occurred trying to get the widget data";
            });
    };

    public loadTagOptions = () => {
        return this.tagService.loadTagOptions()
            .then((tagOptions) => {
                this.tagOptions = tagOptions;
            });
    };

    public loadProducerUserOptions = () => {
        return this.userService.getUsersForDropdown({isProducer: true})
            .then((options) => {
                this.producerUserOptions = options;
            });
    }

    public loadClientServiceUserOptions = () => {
        return this.userService.getUsersForDropdown({isClientServiceAgent: true})
            .then((options) => {
                this.clientServiceUserOptions = options;
            });
    }

    public $onInit = () => {
       
        this.tagOptions = [];
        this.producerUserOptions = [];
        this.clientServiceUserOptions = [];

        const promises = [
            this.loadTagOptions(),
            this.loadProducerUserOptions(),
            this.loadClientServiceUserOptions()
        ];

        this.busyIndicator = {
            message: "Loading...",
            promise: this.$q.all(promises).then(() => {
                this.setFilters();
                
                if (isDefined(this.widget.options.filters)) {
                    this.filterCollection.mergeFilters(this.widget.options.filters);
                }
                
                const loadingPromise = this.loadGrid();
                this.dashboardService.refreshFunctions.push(this.loadGrid);

                return loadingPromise;
            })
        }

        this.table.onChange = this.loadGrid;
    };
}

const dashboardWidgetOpenApplicationsListComponent = {
    templateUrl: "app/states/common/dashboard/widgets/openApplicationList/dashboardWidgetOpenApplicationsList.html",
    bindings: {
        widget: "="
    },
    controllerAs: "vm",
    controller: DashboardWidgetOpenApplicationsListController
};

app.component(
    "dashboardWidgetOpenApplicationsList",
    dashboardWidgetOpenApplicationsListComponent
);
