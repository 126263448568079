import { IControllerConstructor, IDocumentService, Injectable } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { JQueryService } from "../../utilities/jquery/jQueryService";
import { IUibModal } from "./iUibModal";
import { ModalResult } from "./modalResult";
import { ModalSize } from "./modalSizes";


export class ModalCreator {

    public static $inject = [
        Injectables.$uibModal,
        Injectables.JQueryService
    ];

    constructor(
        private readonly $uibModal: IUibModal,
        private readonly jQueryService: JQueryService,
    ) {
    }

    public createModal = <T, K>(
        templateUrl: string,
        controller: string | Injectable<IControllerConstructor>,
        size: ModalSize,
        options?: K,
        windowClass?: string): ModalResult<T> => {

        return this.$uibModal.open<T>({
            templateUrl: templateUrl, 
            controller: controller, 
            size: size, 
            backdrop: 'static',
            appendTo: this.jQueryService.getElement('.main-view .themed').eq(0),
            bindToController: true,
            controllerAs: 'vm',
            resolve: {
                options: () => options
            },
            windowClass: windowClass,
            
        });
    }

    public $onInit = () => {}
}

app.service(Injectables.ModalCreator, ModalCreator);