export interface SearchResultMatch { 
    applicationNumber: string;
    bondId: number;
    bondNumber: string;
    bondLegacyId: string;
    bondType: string;
    customerId: string;
    customerName: string;
    applicationId: string;
    applicationLegacyId: string;
    email: string;
    invoiceNumber: string;
    invoiceId: string;
    personName: string;
    phone: string;
    quoteNumber: string;
    nameOnBond: string;
    billingNumberId: string;
    activityId: number;
    billingEntryId: string;
}    
