import { IndemnityRequirements } from "../model/quote";
import { BondAccountRateTier } from "./bondAccountRateTier";
import { BondAccountRateType } from "./bondAccountRateType";
import { BondAccountStatus } from "./bondAccountStatus";

export interface BondAccount {
    id: number;
    aggregateBondLimit: number;
    singleBondLimit: number;
    customerId: number;
    carrierSystemAccountId: number;
    originalEffectiveDate: Date;
    effectiveDate: Date;
    expirationDate: Date;
    ratePerThousand: number;
    carrierAccountNumber: string;        
    indemnityRequirements: IndemnityRequirements;
    requiresCollateral: boolean;
    requiresPersonalFinancials: boolean;
    requiresCorporateFinancials: boolean;
    status: BondAccountStatus;
    rateType: BondAccountRateType;
    rateTiers: BondAccountRateTier[];
}
