import { IDirectiveFactory, IDirectiveLinkFn, IScope } from "angular";
import app from "../../main";
import Inputmask from "inputmask";

const percentMaskDirective: IDirectiveFactory = () => {
    
    const link: IDirectiveLinkFn = (
        scope: IScope,
        elem: JQuery
    ) => {
        const mask = new Inputmask('numeric', { 
            radixPoint: '.', 
            groupSeparator: ',',
            suffix:' %', 
            allowMinus: false, 
            digits: 2, 
            rightAlign: false 
        });

        mask.mask(elem);
    }
    
    return {
        link: link
    };
}

app.directive('maskPercent', percentMaskDirective);
