import { SystemAccountService } from "../../../api/systemAccountService";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../configuration/injectables";
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";

export class BondNumberGridController {

    public static $inject = [
        Injectables.CurrentUserResolver,
        Injectables.SystemAccountService,
    ];

    constructor(
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly systemAccountService: SystemAccountService,
    ) { }

    public busyIndicator: BusyIndicator;
    public integratedCarrier: boolean;

    public $onInit(): void {
        this.busyIndicator = {
            message: 'Loading...',
            promise: this.systemAccountService.getHasBondNumberIntegration(this.currentUserResolver.getCurrentUser().systemAccount.id)
                .then((response) => {
                    this.integratedCarrier = response;
                })
        };
    }
}