import app from "../../main";

export const timeAgoFilter = (nowTime, timeAgo) => {

    return (value, format, timezone) => {
    
        var fromTime = timeAgo.parse(value);
        var diff = nowTime() - fromTime;
    
        return timeAgo.inWords(diff, fromTime, format, timezone);
    };
}

timeAgoFilter.$inject = [
    'nowTime',
    'timeAgo'
];

app.filter('timeAgo', timeAgoFilter);
