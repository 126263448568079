import { AuthService } from "../../api/authService";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { ChangePasswordResult } from "./ChangePasswordResult";
import app from "../../main";

export class ChangePasswordModalController {

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.AuthService,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly $uibModalInstance: Modal<ChangePasswordResult>,
        private readonly authService: AuthService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) { }

    public password: string;
    public busyIndicator: BusyIndicator;

    public submit(): void {
        this.busyIndicator.promise = this.authService.changeCurrentUserPassword(this.password)
            .then(() => {
                this.$uibModalInstance.close(new ChangePasswordResult());
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage("We're sorry. An error occurred while trying to update your password. ");
            });
    }

    public cancel(): void {
        this.$uibModalInstance.dismiss();
    }

    public $onInit(): void {
        this.busyIndicator = {
            message: 'Updating Password...'
        };
    }
}

app.controller('ChangePasswordModalController', ChangePasswordModalController);
