import { IPromise, IQService } from "angular";
import { SystemAccountService } from "../../api/systemAccountService";
import { TransactionType } from "../../api/types/model/bondTransaction";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { ModalOpener } from "../../modals/modalOpener";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";
import { BondTransactionListItem } from "./bondTransactionListItem";
import { SelectOption } from "../../api/types/selectOption";
import { BondTransactionService } from "../../api/bondTransactionService";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import BondTransactionToUpdate from "./bondTransactionToUpdate";
import { BusyIndicator } from "../busyIndicator/busyIndicator";

class BondTransactionListController {

    public static $inject = [
        Injectables.CurrentUserResolver,
        Injectables.ModalOpener,
        Injectables.SystemAccountService,
        Injectables.$q,
        Injectables.BondTransactionService,
        Injectables.ToastMessageCreator
    ];

    public isCarrier: boolean;
    public epicIntegrationEnabled: boolean;
    public transactions: BondTransactionListItem[];
    public onTransactionBillingEntriesSaved: () => void;
    public aifOptions: SelectOption<number>[];
    public busyIndicator: BusyIndicator;
    
    constructor(
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly modalOpener: ModalOpener,
        private readonly systemAccountService: SystemAccountService,
        private readonly $q: IQService,
        private readonly bondTransactionService: BondTransactionService,
        private readonly toastMessageCreator: ToastMessageCreator 
    ) {}

    public showShippingModal(bondTransaction: any): void {
        this.modalOpener.showShippingModal(bondTransaction)
            .result
            .then(() => {})
            .catch(() => {});
    }

    public showChangeDescription(transaction: BondTransactionListItem) {
        return transaction.transactionType === TransactionType.Rider ||
                transaction.transactionType === TransactionType.Correction;    
    }
    
    public async loadAttorneyInFactOptions(): Promise<void> {
        this.aifOptions = await this.systemAccountService.getAttorneyInFactOptions();
    }

    public save(transaction: BondTransactionListItem) {

        const billingEntriesToUpdate = transaction.details.billingEntries
            .filter((billingEntry) => !!billingEntry.id)
            .map((billingEntry) => {
                return {
                    id: billingEntry.id,
                    billingEntryType: billingEntry.billingEntryType,
                    bondTransactionId: transaction.id,
                    code: billingEntry.code,
                    commission: billingEntry.commission,
                    productionCredit: billingEntry.productionCredit,
                    feeId: billingEntry.feeId,
                    description: billingEntry.description,
                    billToType: billingEntry.billToType,
                    brokerCommission: billingEntry.brokerCommission,
                    producerUserId: billingEntry.producerUserId
                }
            });

        const billingEntriesToCreate = transaction.details.billingEntries
            .filter((billingEntry) => !billingEntry.id)
            .map((billingEntry) => {
                return {
                    bondTransactionId: transaction.id,
                    billingEntryType: billingEntry.billingEntryType,
                    code: billingEntry.code,
                    description: billingEntry.description,
                    originalAmount: billingEntry.originalAmount,
                    commission: billingEntry.commission,
                    productionCredit: billingEntry.productionCredit,
                    billToType: billingEntry.billToType,
                    brokerCommission: billingEntry.brokerCommission,
                    producerUserId: billingEntry.producerUserId
                }
            });

        const bondTransactionToUpdate = { 
            ...transaction.details,
            billingEntriesToUpdate: billingEntriesToUpdate,
            billingEntriesToCreate: billingEntriesToCreate
        } as BondTransactionToUpdate;
        
        this.busyIndicator.message = 'Saving...';
        this.busyIndicator.promise = this.bondTransactionService.saveBondTransaction(bondTransactionToUpdate)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Bond transaction saved successfully');
                if (this.onTransactionBillingEntriesSaved instanceof Function) {
                    this.onTransactionBillingEntriesSaved();
                }
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to save the bond transaction');
            });
    }
    
    public showHistory(transaction: BondTransactionListItem) {
        this.modalOpener.showBondTransactionHistoryModal(transaction.id);
    }

    public transactionExpanderClicked(transaction: BondTransactionListItem) {
        if (transaction.transactionType == TransactionType.Import) {
            return;
        }

        transaction['showDetail'] = !transaction['showDetail'];

        if (transaction['showDetail']) {
            this.bondTransactionService.getBondTransactionDetails(transaction.id)
                .then((bondTransactionDetails) => transaction.details = bondTransactionDetails);
        }
    }

    public checkIntegrationActive(): IPromise<void>{
        return this.systemAccountService.isEpicIntegrationEnabled()
            .then((epicIntegrationEnabled) => {
                this.epicIntegrationEnabled = epicIntegrationEnabled;
            });
    }

    public $onInit(): void {
        this.busyIndicator = {};
        this.isCarrier = this.currentUserResolver.getCurrentUser().systemAccount.isCarrier;

        const promises = [
            this.checkIntegrationActive(),
            this.loadAttorneyInFactOptions()
        ];

        this.$q.all(promises);
    }
}

const bondTransactionListDirective = () => ({
    bindToController: {
        transactions: '=',
        onTransactionBillingEntriesSaved: '&?'
    },
    controller: BondTransactionListController,
    controllerAs: 'vm',
    replace: true,
    restrict: 'E',
    scope: {},
    templateUrl: 'app/components/bondTransactionList/bondTransactionList.html',
});

app.directive('bondTransactionList', bondTransactionListDirective);