import DropdownOption from "../../api/types/dropdownOption";
import app from "../../main";

class DropdownController {
    
    constructor() {
        this.menuPosition = 'left'
    };

    public get menuPositionClass() {
        switch (this.menuPosition.toLowerCase()) {
            case 'left':
                return 'dropdown-menu-left';
            case 'right':
                return 'dropdown-menu-right';
        }

        return '';
    }

    public menuPosition: string;
    public options: DropdownOption<any>[];
    public selectedOption: DropdownOption<any>;
    public onSelected: (param: {option: DropdownOption<any>}) => void;

    public optionSelected = (option: DropdownOption<any>) => {
        this.selectedOption = option;

        if (this.onSelected instanceof Function) {
            this.onSelected({option: this.selectedOption});
        }

        if (option.onClick instanceof Function) {
            option.onClick();
        }
    }
}

const dropdownComponent = {
    bindings: {
        options: '<',
        onSelected: '&?',
        selectedOption: '<?',
        buttonText: '@?',
        buttonClass: '@?',
        buttonId: '@?',
        rightButtonIcon: '@?',
        menuPosition: '@?'
    },
    controllerAs: 'vm',
    controller: DropdownController,
    templateUrl: 'app/components/dropdown/dropdown.html'
}

app.component('dropdown', dropdownComponent);