import { CacheStore } from "../types/cacheStore";
import { IBaseEntity } from "../types/model/iBaseEntity";
import A3ApiResponse from "../types/a3ApiResponse";
import { ODataEndpoint, ODataQuery } from ".";
import { IHttpPromise, IHttpService, IPromise } from "angular";
import { SystemSettings } from "../../configuration/settings/systemSettings";

export default class ODataService<T extends IBaseEntity> {

    public query: ODataQuery;

    constructor(
        public readonly endpoint: ODataEndpoint,
        public readonly cacheStore: CacheStore,
        private readonly $http: IHttpService,
        private readonly sysConfig: SystemSettings) {

        this.query = new ODataQuery();
    }

    /***
    * Sends HTTP Delete request to the A3 OData Api using the endpoint specified.
    */
    public delete<K = void>(id: number): IHttpPromise<K> {
        return this.$http.delete<K>(this.sysConfig.odataBaseUrl + this.endpoint + '(' + id + ')');
    }

    /***
    * Sends HTTP Post request to the A3 OData Api using the endpoint specified. Post is usually used to insert a new entity.
    */
    public post<K = void>(entity: T): IHttpPromise<K> {
        return this.$http.post<K>(this.sysConfig.odataBaseUrl + this.endpoint, entity);
    }

    /***
     * Sends HTTP Put request to the A3 OData Api using the endpoint specified. Put is usually used to update an entity.
     */
    public put<K = void>(id: number, entity: T): IHttpPromise<K> {
        return this.$http.put<K>(this.sysConfig.odataBaseUrl + this.endpoint + '(' + id + ')', entity);
    }

    /*
        * Sends HTTP Put or Post Request depending on whether or not the entity has the id set
        */
    public save<K = void>(object: T): IHttpPromise<K> {
        if (object.id) {
            return this
                .put<K>(object.id, object);
        } else {
            return this
                .post<K>(object);
        }
    }

    /***
     * Sends HTTP Get request to the A3 OData Api using the endpoint specified. Get is used to retrieve data from the 
     */
    public get<K = A3ApiResponse<T[]>>(query?: ODataQuery): IHttpPromise<K> {
        let url = this.sysConfig.odataBaseUrl + this.endpoint + '?';

        if (query) {
            url += query.toString();
        }
        else {
            url += this.query.toString();
        }

        return this.$http.get<K>(url, { cache: this.cacheStore });
    }

    /***
     * Sends HTTP Patch request to the A3 OData Api using the endpoint specified. Patch is usually used to update part of an entity. Only properties
     * that are specified in the entity object sent in the request are updated
     */
    public patch<K = void>(id: number, entity: T): IHttpPromise<K> {
        return this.$http.patch<K>(this.sysConfig.odataBaseUrl + this.endpoint + '(' + id + ')', entity);
    }

    /***
     * Sends HTTP Get request to the A3 OData Api using the endpoint specified.
     * Get is used to retrieve a single record from the specified OData Api endpoint
     */
    public getById(id: number): IPromise<T | null> {
        return this.$http
            .get<A3ApiResponse<T[]>>(this.sysConfig.odataBaseUrl+ this.endpoint + '(' + id + ')', { cache: this.cacheStore })
            .then((response) => {
                if (response.data.value && response.data.value.length) {
                    return response.data.value[0];
                } else {
                    return null
                }
            });
    }

    /***
     * Sends HTTP Get request to the A3 OData Api using the endpoint specified.
     * count is used to retrieve a count of records from the specified OData Api endpoint
     */
    public count(query?: ODataQuery): IHttpPromise<number> {
        let url = this.sysConfig.odataBaseUrl + this.endpoint + '/$count?';

        if (query) {
            url += query.toString();
        }
        else {
            url += this.query.toString();
        }

        return this.$http.get<number>(query ? url + query.toString() : url, { cache: this.cacheStore });
    }

    /***
    * Sends a HTTP Post request to a specified custom Post Function defined in A3's Odata configuration
    */
    public postFunction<K>(functionName: string, data: any): IHttpPromise<K> {
        return this.$http.post<K>(this.sysConfig.odataBaseUrl + this.endpoint + '/' + functionName, data);
    }

    /***
     * Sends a HTTP Delete request to a specified custom Delete Function defined in the A3 OData configuration
     */
    public deleteFunction<K>(functionName: string, data): IHttpPromise<K> {
        return this.$http.delete<K>(this.sysConfig.odataBaseUrl + this.endpoint + '/' + functionName, data);
    }

    /***
     * Sends a HTTP Post request to a specified custom Post Action defined in the A3 OData configuration
     */
    public entityAction<K>(id: number, actionName: string, data): IHttpPromise<K> {
        return this.$http.post<K>(this.sysConfig.odataBaseUrl + this.endpoint + '(' + id + ')/' + actionName, data);
    }

    /***
     * Sends a HTTP Get request to a specified custom Get Function defined in the A3 OData configuration
     */
    public entityFunction<K>(id: number, actionName: string): IHttpPromise<K> {
        return this.$http.get<K>(this.sysConfig.odataBaseUrl + this.endpoint + '(' + id + ')/' + actionName, { cache: this.cacheStore });
    }

    /***
     * Sends an HTTP Get request to the specified collection function.
     */
    public collectionFunction<K>(actionName: string): IHttpPromise<K> {
        return this.$http.get<K>(this.sysConfig.odataBaseUrl + this.endpoint + '/' + actionName, { cache: this.cacheStore });
    }
}
