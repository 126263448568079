export interface AttachmentTableListItem {
    id: number;
    createdDateTime: Date;
    filename: string;
    comments: string;
    fileSize: number;
    visibleToAllCarriers: boolean;
    visibleToAgent: boolean;
    visibleInEProducer: boolean;
    activityId: number;
    fileId: number;
    attachmentType: string;
    isBroker: boolean;
    sharedWithCarrierCompanyName: string;
    uploadedByUserId?: number;
    uploadedByUserFullName?: string;
    uploadedByCompanyName?: string;
    systemAccountId: number;
    uploadedByEProducerUserId?: number;
}