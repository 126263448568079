import { Injectables } from "../../configuration/injectables";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";
import app from "../../main";
import { ISCEService } from "angular";
import ApplicationStatusFilterable from "./applicationStatusFilterable";
import { ConstantsStatusDisplay } from "../../configuration/constants";
import { ApplicationStatus } from "../../api/types/model/application";

export const applicationStatusFilter = (
    $sce: ISCEService, 
    currentUserResolver: CurrentUserResolver
) => {

    const isCarrier = currentUserResolver.getCurrentUser().systemAccount.isCarrier;

    return (applicationDetail: ApplicationStatusFilterable): ConstantsStatusDisplay => {
        let status = '';

        if ( applicationDetail){
            if (isCarrier) {
            
                if (applicationDetail.status !== ApplicationStatus.Submitted) {
                    if (applicationDetail.status == ApplicationStatus.NotSubmitted) {
                        status = 'Not Submitted';
                    } else {
                        status = applicationDetail.status;
                    }
                } else {
                    if (applicationDetail.isAdverseActionByCurrentCarrier) {
                        status = '<span class="red">Declined - Adverse Action</span>';
                    } else if (applicationDetail.isDeclinedByCurrentCarrier) {
                        status = 'Declined';
                    } else if (applicationDetail.approvedQuoteCount > 0) {
                        status = '<span class="green">Approved</span>';
                    } else {
                        status = 'Manual Review';
                    }
                }
            }

            if (!isCarrier) {
                if (applicationDetail.status !== ApplicationStatus.Submitted) {
                    if (applicationDetail.status == ApplicationStatus.NotSubmitted) {
                        status = 'Not Submitted';
                    } else if (applicationDetail.status == ApplicationStatus.Completed) {
                        status = 'Purchased';
                    } else {
                        status = applicationDetail.status;
                    }
                } else {
                    if (applicationDetail.approvedQuoteCount > 0) {
                        status = '<span class="green">Approved</span>';
                    } else {
                        status = 'Manual Review';
                    }
                }
            }
        }
        else {
            status ='';
        }

        return $sce.trustAsHtml(status);
    }
}

applicationStatusFilter.$inject = [
    Injectables.$sce,
    Injectables.CurrentUserResolver
];

app.filter('applicationStatus', applicationStatusFilter);