import { Injectables } from "../../../configuration/injectables";
import { State } from "../../state";
import { CurrentState } from "../../CurrentState";
import { StateChangeListener } from "../../stateChangeListener";

export class ContentController {
    static $inject = [
        Injectables.$state,
        Injectables.StateChangeListener
    ];

    constructor(
        private readonly $state: State,
        private readonly stateChangeListener: StateChangeListener
    ) {}

    public currentState: CurrentState;

    public $onInit = () => {
        this.currentState = this.$state.current;
        
        this.stateChangeListener.onStateChanged((toState, toParams, prevState, prevStateParams) => {
            this.currentState = toState;
        });
    }
}