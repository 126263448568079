import { BondCorrectionBond } from "../../api/types/bondCorrectionBond";
import { BondService } from "../../api/bondService";
import { SystemAccountService } from "../../api/systemAccountService";
import { BondChange, BondChangeType } from "../../api/types/model/bondChange";
import { BondCorrection } from "../../api/types/model/bondCorrection";
import { SelectOption } from "../../api/types/selectOption";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { IEmployerAddressFilter } from "../../filters/employerAddressFilter/employerAddressFilterType";
import { IMailingAddressFilter } from "../../filters/mailingAddressFilter/mailingAddressFilterType";
import { IPersonNameFilter } from "../../filters/personName/personNameFilterType";
import { IPhysicalAddressFilter } from "../../filters/physicalAddressFilter/physicalAddressFilterType";
import { ISpouseNameFilter } from "../../filters/spouseName/spouseNameFilterType";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { BondCorrectionOptions } from "./bondCorrection";
import { BondCorrectionResult } from "./BondCorrectionResult";
import { BondCorrectionResultAction } from "./BondCorrectionResultAction";
import app from "../../main";
import { IPromise, ISCEService, IFilterService, IQService } from "angular";
import { FormatType } from "../../filters/physicalAddressFilter/formatType";
import * as moment from "moment";
import toCamel from "../../utilities/stringUtilities/toCamel";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";

export class BondCorrectionModalController {

    public changes: BondChange[];
    public selectedAttorneyInFactId: number;
    public attorneyInFactOptions: SelectOption<number>[];
    public processingPromise: IPromise<any>;
    public bond: BondCorrectionBond;
    public comments: string;

    public get isCarrier(): boolean {
        return this.currentUserResolver.getCurrentUser().systemAccount.isCarrier;
    }

    public get hasChanges(): boolean {
        return Array.isArray(this.changes) && this.changes.length > 0;
    }

    public static $inject = [
        Injectables.CurrentUserResolver,
        Injectables.SystemAccountService,
        Injectables.Options,
        Injectables.$uibModalInstance,
        Injectables.BondService,
        Injectables.$sce,
        Injectables.$filter,
        Injectables.$q,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly systemAccountService: SystemAccountService,
        private readonly options: BondCorrectionOptions,
        private readonly $uibModalInstance: Modal<BondCorrectionResult>,
        private readonly bondService: BondService,
        private readonly $sce: ISCEService,
        private readonly $filter: IFilterService,
        private readonly $q: IQService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) { }

    public $onInit(): void {
        this.bond = this.options.bond;

        if (Array.isArray(this.options.changes)) {
            this.changes = this.options.changes;
        } else {
            this.changes = [];
        }

        this.processingPromise = this.loadAttorneyInFactOptions();
    }

    public async loadAttorneyInFactOptions(): Promise<void> {
        this.attorneyInFactOptions = await this.systemAccountService.getAttorneyInFactOptions(this.bond.id);
        this.selectedAttorneyInFactId = this.systemAccountService.getDefaultAttorneyInFactUserId(this.attorneyInFactOptions);
    }

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }

    public apply(): void {
        if (!this.isValid()) {
            return;
        }

        const deferred = this.$q.defer();
        this.processingPromise = deferred.promise.then(() => { });

        this.bondService
            .applyBondCorrection(this.buildBondCorrection())
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Bond correction has been applied.');
                this.$uibModalInstance.close(new BondCorrectionResult(BondCorrectionResultAction.Submitted));
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to apply the bond correction.');
                deferred.resolve();
            });
    }

    private isValid(): boolean {
        return this.changes.length > 0 && this.selectedAttorneyInFactId > 0;
    }

    private buildBondCorrection(): BondCorrection {
        return {
            bondId: this.bond.id,
            changes: this.getChanges(),
            effectiveDate: moment.utc().startOf('day').toDate(),
            attorneyInFactId: this.selectedAttorneyInFactId,
            comments: this.comments,
            wasEnteredByAgent: !this.isCarrier
        } as any as BondCorrection;
    }

    public getChanges(): BondChange[] {
        return this.changes.map((change) => {
            const mappedChange = { ...change };

            mappedChange.changeType = change.changeType.toString().charAt(0).toUpperCase() + change.changeType.toString().slice(1) as BondChangeType;

            // if original values are present then ensure it's not an angular $sce object
            if (change.originalValue) {
                mappedChange.originalValue = typeof change.originalValue === 'object'
                    ? this.$sce.getTrustedHtml(change.originalValue)
                    : change.originalValue;
            }

            switch (change.changeType) {
                case BondChangeType.IndividualName:
                    mappedChange.newValue = this.$filter<IPersonNameFilter>('personName')({
                        firstName: change['$newValue_individualFirstName'],
                        lastName: change['$newValue_individualLastName'],
                        middleName: change['$newValue_individualMiddleName'],
                        prefix: change['$newValue_individualPrefix'],
                        suffix: change['$newValue_individualSuffix']
                    }, FormatType.Pipe);
                    break;

                case BondChangeType.IndividualSpouseName:
                    mappedChange.newValue = this.$filter<ISpouseNameFilter>('spouseName')({
                        spouseFirstName: change['$newValue_individualSpouseFirstName'],
                        spouseLastName: change['$newValue_individualSpouseLastName'],
                        spouseMiddleName: change['$newValue_individualSpouseMiddleName'],
                    }, FormatType.Pipe);
                    break;

                case BondChangeType.IndividualEmployerAddress:
                    mappedChange.newValue = this
                        .$sce
                        .getTrustedHtml(this.$filter<IEmployerAddressFilter>('employerAddress')({
                            employerAddress: change['$newValue_individualEmployerAddress'],
                            employerCity: change['$newValue_individualEmployerCity'],
                            employerCounty: change['$newValue_individualEmployerCounty'],
                            employerState: change['$newValue_individualEmployerState'],
                            employerSuiteAptNumber: change['$newValue_individualEmployerApptNumber'],
                            employerZip: change['$newValue_individualEmployerZip'],
                        }, FormatType.Pipe));
                    break;

                case BondChangeType.IndividualPhysicalAddress:
                    mappedChange.newValue = this
                        .$sce
                        .getTrustedHtml(this.$filter<IPhysicalAddressFilter>('physicalAddress')({
                            physicalAddress: change['$newValue_individualAddress'],
                            physicalCity: change['$newValue_individualCity'],
                            physicalCounty: change['$newValue_individualCounty'],
                            physicalState: change['$newValue_individualState'],
                            physicalSuiteAptNumber: change['$newValue_individualApptNumber'],
                            physicalZip: change['$newValue_individualZip'],
                        }, FormatType.Pipe));
                    break;

                case BondChangeType.IndividualMailingAddress:
                    mappedChange.newValue = this
                        .$sce
                        .getTrustedHtml(this.$filter<IMailingAddressFilter>('mailingAddress')({
                            mailAddress: change['$newValue_individualMailAddress'],
                            mailCity: change['$newValue_individualMailCity'],
                            mailCounty: change['$newValue_individualMailCounty'],
                            mailState: change['$newValue_individualMailState'],
                            mailSuiteAptNumber: change['$newValue_individualMailApptNumber'],
                            mailZip: change['$newValue_individualMailZip'],
                        }, FormatType.Pipe));
                    break;

                case BondChangeType.CompanyPhysicalAddress:
                    mappedChange.newValue = this
                        .$sce
                        .getTrustedHtml(this.$filter<IPhysicalAddressFilter>('physicalAddress')({
                            physicalAddress: change['$newValue_companyAddress'],
                            physicalCity: change['$newValue_companyCity'],
                            physicalCounty: change['$newValue_companyCounty'],
                            physicalState: change['$newValue_companyState'],
                            physicalSuiteAptNumber: change['$newValue_companyApptNumber'],
                            physicalZip: change['$newValue_companyZip'],
                        }, FormatType.Pipe));
                    break;

                case BondChangeType.CompanyMailingAddress:
                    mappedChange.newValue = this
                        .$sce
                        .getTrustedHtml(this.$filter<IMailingAddressFilter>('mailingAddress')({
                            mailAddress: change['$newValue_companyMailAddress'],
                            mailCity: change['$newValue_companyMailCity'],
                            mailCounty: change['$newValue_companyMailCounty'],
                            mailState: change['$newValue_companyMailState'],
                            mailSuiteAptNumber: change['$newValue_companyMailApptNumber'],
                            mailZip: change['$newValue_companyMailZip'],
                        }, FormatType.Pipe));
                    break;

                case BondChangeType.ObligeeMailAddress:
                    mappedChange.newValue = this
                        .$sce
                        .getTrustedHtml(this.$filter<IMailingAddressFilter>('mailingAddress')({
                            mailAddress: change['$newValue_obligeeMailAddress'],
                            mailCity: change['$newValue_obligeeMailCity'],
                            mailCounty: change['$newValue_obligeeMailCounty'],
                            mailState: change['$newValue_obligeeMailState'],
                            mailSuiteAptNumber: change['$newValue_obligeeMailApptNumber'],
                            mailZip: change['$newValue_obligeeMailZip'],
                        }, FormatType.Pipe));
                    break;

                case BondChangeType.ObligeePhysicalAddress:
                    mappedChange.newValue = this
                        .$sce
                        .getTrustedHtml(this.$filter<IPhysicalAddressFilter>('physicalAddress')({
                            physicalAddress: change['$newValue_obligeePhysicalAddress'],
                            physicalCity: change['$newValue_obligeePhysicalCity'],
                            physicalCounty: change['$newValue_obligeePhysicalCounty'],
                            physicalState: change['$newValue_obligeePhysicalState'],
                            physicalSuiteAptNumber: change['$newValue_obligeePhysicalApptNumber'],
                            physicalZip: change['$newValue_obligeePhysicalZip'],
                        }, FormatType.Pipe));
                    break;

                case BondChangeType.BondTypeId:           
                        mappedChange.newValueText = change['$newValue_bondType'].name;
                        mappedChange.newValue = change['$newValue_bondType'].id;
                    break;

                default:
                    mappedChange.newValue = change['$newValue_' + toCamel(change.changeType.toString())];
                    break;
            }

            return mappedChange;
        });
    }
}

app.controller('BondCorrectionModalController', BondCorrectionModalController);
