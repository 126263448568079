export interface InvoiceTableLineItem {
    id: number;
    bondId: number;
    bondNumber: string;
    bondAmount: number;
    bondType: string;
    effectiveDate: Date;
    expirationDate: Date;
    transactionEffectiveDate: Date;
    nameOnBond: string;
    writingCompany: string;
    billingEntryId: number;
    code: string;
    description: string;
    amountDue: number;
    amountReceived: number;
}