enum ODataEndpoint {
    User = 'User',
    SystemAccount = 'SystemAccount',
    UserGroup = 'UserGroup',
    Obligee = 'Obligee',
    Bond = 'Bond',
    BondType = 'BondType',
    SfaaCode = 'SfaaCode',
    Document = 'Document',
    DocumentCategory = 'DocumentCategory',
    DocumentRating = 'DocumentRating',
    DocumentTag = 'DocumentTag',
    DocumentVersion = 'DocumentVersion',
    CarrierAppointment = 'CarrierAppointment',
    Customer = 'Customer',
    Application = 'Application',
    Quote = 'Quote',
    MasterApplicationQuestion = 'MasterApplicationQuestion',
    Rate = 'Rate',
    ZipLookup = 'ZipLookup',
    BondNumberGroup = 'BondNumberGroup',
    SystemAccountContact = 'SystemAccountContact',
    WritingCompany = 'WritingCompany',
    UnderwritingConfiguration = 'UnderwritingConfiguration',
    RequiredFieldSet = 'RequiredFieldSet',
    CancellationRequest = 'CancellationRequest',
    RiderRequest = 'RiderRequest',
    ReinstatementRequest = 'ReinstatementRequest',
    BondTransaction = 'BondTransaction',
    PaymentTransaction = 'PaymentTransaction',
    Lead = 'Lead',
    Memo = 'Memo',
    ApplicationFollowUp = 'ApplicationFollowUp',
    ApplicationFollowUpStrategy = 'ApplicationFollowUpStrategy',
    EmailTemplate = 'EmailTemplate',
    AutoFee = 'AutoFee',
    Release = 'Release',
    SystemAccountStatement = 'SystemAccountStatement',
    AuditLog = 'AuditLog',
    CreditReport = 'CreditReport',
    IndemnitySignature = 'IndemnitySignature',
    EProducerAccount = 'EProducerAccount',
    EProducerUser = 'EProducerUser',
    Attachment = 'Attachment',
    BrowserNotification = 'BrowserNotification',
    tag = 'Tag',
};

export default ODataEndpoint;