import { AnalyticsService } from "../../../../../api/analyticsService";
import { BusyIndicator } from "../../../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../../../configuration/injectables";
import { DashboardService } from "../../dashboardService";
import { DashboardWidget } from "../../types/dashboardWidget";
import app from "../../../../../main";
import * as angular from "angular";
import { AmChartService } from "../../../../../utilities/amCharts/amChartService";

class DashboardWidgetBondsByAgentBreakdownCarrierController {
    public static $inject = [
        Injectables.AnalyticsService,
        Injectables.DashboardService,
        Injectables.AmChartService
    ];

    constructor(
        private readonly analyticsService: AnalyticsService,
        private readonly dashboardService: DashboardService,
        private readonly amChartService: AmChartService
    ) {}

    public widget: DashboardWidget;
    public busyIndicator: BusyIndicator;
    public widgetIndex: number;

    public loadChart = () => {
        this.busyIndicator.promise = this.analyticsService
            .getActiveBondAgencyBreakdown()
            .then((data) => {
                this.amChartService.makePieChart(
                    "mapDiv" + this.widgetIndex,
                    data
                );
            });
    };

    public $onInit = () => {
        this.busyIndicator = {
            message: "Loading..."
        };

        if (!angular.isDefined(this.widget.options.title)) {
            this.widget.options.title = "Bond by Agency Breakdown";
        }

        this.loadChart();

        this.dashboardService.refreshFunctions.push(this.loadChart);
    };
}

const dashboardWidgetBondsByAgentBreakdownCarrierComponent = {
    bindings: {
        widget: "=",
        widgetIndex: "<"
    },
    templateUrl:
        "app/states/common/dashboard/widgets/breakdownByAgent/dashboardWidgetBondsByAgentBreakdownCarrier.html",
    controller: DashboardWidgetBondsByAgentBreakdownCarrierController,
    controllerAs: "vm"
};

app.component(
    "dashboardWidgetBondsByAgentBreakdownCarrier",
    dashboardWidgetBondsByAgentBreakdownCarrierComponent
);
