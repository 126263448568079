import app from "../../main";
import { SearchControl } from "../../utilities/searchControl";

class SearchDropdownController {
    
    public searchControl: SearchControl<{}>;
    private previousSearchPhrase: string = null;        
    private cachedResults: {} = {};
    private readonly ALL_CACHE_KEY = 'all'

    public clicked = (searchPhrase: string) => {
        if (this.searchControl.loadOnClick) {
            this.search(searchPhrase);
        }
    }

    public onSelect = ($item: any, $model: any) => {
        if (typeof this.searchControl.onSelect === 'function') {
            this.searchControl.onSelect($item);
        }
        
        if (this.searchControl.clearOnSelection) {
            this.searchControl.matches = [];
        }
    }

    public search = (searchPhrase: string) => {
        if (this.previousSearchPhrase === searchPhrase) {
            return;
        }
        this.previousSearchPhrase = searchPhrase;

        if (!this.searchControl.loadOnFocus && this.cachedResults[searchPhrase]) {
            
            // if not all records are loaded && cached results exist for searchphrase              
            this.searchControl.matches = this.cachedResults[searchPhrase];
            return;

        } else if (this.searchControl.loadOnFocus && this.cachedResults[this.ALL_CACHE_KEY]) {
            
            // if all records are loaded then filter from previously loaded cache searching by searchphrase
            this.searchControl.matches = this.cachedResults[this.ALL_CACHE_KEY].filter(
                (cachedResult) => this.searchControl.buildOptionLabel(cachedResult).indexOf(searchPhrase) >= 0
            );
            return;
        }

        this.searchControl.onSearch(searchPhrase)
            .then((results) => {
                this.searchControl.matches = results;
                
                if (this.searchControl.loadOnFocus) {
                    this.cachedResults[this.ALL_CACHE_KEY] = results;
                }
            });
    }

    public $onInit() {
        this.cachedResults = {};
    }
}

const searchDropdownComponent = {
    bindings: {
        searchControl: '=',
        showUserAvatar: '@?',
    },
    controller: SearchDropdownController,
    controllerAs: 'vm',
    templateUrl: 'app/components/searchDropdown/searchDropdown.html'
};

app.component('searchDropdown', searchDropdownComponent);
