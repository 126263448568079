import { ODataFactory, ODataEndpoint } from "../../../api/odata";
import { SystemAccountService } from "../../../api/systemAccountService";
import { BondNumberGroup } from "../../../api/types/model/bondNumberGroup";
import { BondType } from "../../../api/types/model/bondType";
import { MasterApplicationQuestion } from "../../../api/types/model/masterApplicationQuestion";
import { Rate } from "../../../api/types/model/rate";
import { RateOverride } from "../../../api/types/model/rateOverride";
import { RateOverrideCondition } from "../../../api/types/model/rateOverrideCondition";
import { RequiredFieldSet } from "../../../api/types/model/requiredFieldSet";
import { RuleConditionType } from "../../../api/types/model/ruleCondition";
import { UnderwritingConfiguration } from "../../../api/types/model/underwritingConfiguration";
import { UnderwritingConfigurationBondFormAttachment } from "../../../api/types/model/underwritingConfigurationBondFormAttachment";
import { UnderwritingConfigurationBondFormAttachmentCondition } from "../../../api/types/model/underwritingConfigurationBondFormAttachmentCondition";
import { UnderwritingQuestion } from "../../../api/types/model/underwritingQuestion";
import { WritingCompany } from "../../../api/types/model/writingCompany";
import { PageResponse } from "../../../api/types/pageResponse";
import { SelectOption } from "../../../api/types/selectOption";
import { UnderwritingConfigurationService } from "../../../api/underwritingConfigurationService";
import { Injectables } from "../../../configuration/injectables";
import { ModalOpener } from "../../../modals/modalOpener";
import { SearchControl } from "../../../utilities/searchControl";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { Document } from "../../../api/types/model/document";
import { State } from "../../state";
import * as angular from "angular";
import { IQService, IHttpService, IPromise } from "angular";
import { SystemSettings } from "../../../configuration/settings/systemSettings";
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";
import { QuoteStatus } from "../../../api/types/model/quote";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import DocumentSearchResult from "../../../api/types/documents/documentSearchResult";
import { DocumentService } from "../../../api/documentService";

export class UnderwritingConfigurationDetailController {
    public static $inject = [
        Injectables.$stateParams,
        Injectables.CurrentUserResolver,
        Injectables.UnderwritingConfigurationService,
        Injectables.ODataFactory,
        Injectables.$q,
        Injectables.$state,
        Injectables.SystemSettings,
        Injectables.$http,
        Injectables.SystemAccountService,
        Injectables.ModalOpener,
        Injectables.ToastMessageCreator,
        Injectables.DocumentService
    ];

    constructor(
        private readonly $stateParams: UnderwritingConfigurationDetailParams,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly underwritingConfiguraitonService: UnderwritingConfigurationService,
        private readonly odata: ODataFactory,
        private readonly $q: IQService,
        private readonly $state: State,
        private readonly systemSettings: SystemSettings,
        private readonly $http: IHttpService,
        private readonly systemAccountService: SystemAccountService,
        private readonly modalOpener: ModalOpener,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly documentService: DocumentService
    ) {}

    public busyIndicator: BusyIndicator;
    public config: UnderwritingConfigurationDetail;
    public quoteStatusOptions: SelectOption<string>[];
    public isCarrierBondNumberIntegrationEnabled: boolean;
    public documentSearch: SearchControl<DocumentSearchResult>;
    public bondTypeSearch: SearchControl<BondType>;
    public questionSearch: SearchControl<MasterApplicationQuestion>;
    public writingCompanies: WritingCompany[];
    public requiredFieldSets: RequiredFieldSet[];
    public bondNumberGroups: BondNumberGroup[];
    public rates: Rate[];
    public detailForm: any;
    public commissionOverrideEnabled: boolean;
    public renewalCommissionOverrideEnabled: boolean;

    public searchBondTypes(searchPhrase: string): IPromise<void> {
        const bondTypeSvc = this.odata.getService<BondType>(
            ODataEndpoint.BondType
        );
        const query = this.odata.getQuery();
        query.filter("(contains(name,'" + searchPhrase + "'))");
        query.orderby("name");
        query.top(25);

        return bondTypeSvc.get(query).then((response) => {
            this.bondTypeSearch.matches = response.data.value;
        });
    }

    public showNewRateModal(): void {
        this.busyIndicator.promise = this.modalOpener
            .showRateModal()
            .result.then((rate) => {
                return this.loadRates().then(() => {
                    this.config.rateId = rate.id;
                });
            })
            .catch(() => {});
    }

    public bondTypeSelected(): void {
        this.config.bondTypeId = this.config.bondType.id;
    }

    public createRadioChoices(question): void {
        const options: SelectOption[] = [];

        angular.forEach(question.masterApplicationQuestionChoices, (choice) => {
            options.push({ label: choice.text, value: choice.text });
        });

        question.options = options;
    }

    public save(): void {
        this.prepareRequest();

        this.busyIndicator.message = "Saving...";
        this.busyIndicator.promise = this.underwritingConfiguraitonService
            .saveUnderwritingConfiguration(this.config)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage(
                    "Underwriting configuration saved successfully"
                );
                this.$state.go("main.underwritingConfigurationGrid");
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage(
                    "An error occurred while saving the underwriting configuration"
                );
            });
    }

    public minimumBondAmountChange(): void {
        if (!this.config.minimumBondAmount) {
            this.detailForm.$aaFormExtensions.minimumBondAmount.$ngModel.$setPristine();
        }
    }

    public maximumBondAmountChange(): void {
        if (!this.config.maximumBondAmount) {
            this.detailForm.$aaFormExtensions.maximumBondAmount.$ngModel.$setPristine();
        }
    }

    public searchQuestions(
        searchControl: SearchControl<any>,
        searchPhrase: string
    ): void {
        const questionSvc = this.odata.getService<MasterApplicationQuestion>(
            ODataEndpoint.MasterApplicationQuestion
        );
        const query = this.odata.getQuery();
        query.top(20);
        query.filter(
            "contains(question,'" +
                searchPhrase +
                "') and isSubQuestion eq false"
        );
        query.expand("masterApplicationQuestionChoices");

        questionSvc.get(query).then((response) => {
            searchControl.matches = response.data.value;
        });
    }

    public addQuestion(): void {
        if (!this.questionSearch || !this.questionSearch.selected) {
            return;
        }

        if (this.config.underwritingQuestions) {
            for (let i = 0; i < this.config.underwritingQuestions.length; i++) {
                if (
                    this.config.underwritingQuestions[i]
                        .masterApplicationQuestionId ===
                    this.questionSearch.selected.id
                ) {
                    this.questionSearch.selected = null;
                    return;
                }
            }
        } else {
            this.config.underwritingQuestions = [];
        }

        this.config.underwritingQuestions.push({
            isRequired: true,
            underwritingConfigurationId: this.config.id,
            masterApplicationQuestionId: this.questionSearch.selected.id,
            masterApplicationQuestion: this.questionSearch.selected
        } as UnderwritingQuestion);

        this.questionSearch.selected = null;
    }

    public removeQuestion(index: number): void {
        this.config.underwritingQuestions.splice(index, 1);
    }

    private loadExistingConfiguration(): IPromise<void> {
        return this.underwritingConfiguraitonService
            .getUnderwritingConfigurationDetail(this.config.id)
            .then((underwritingConfiguration) => {
                this.config =
                    underwritingConfiguration as UnderwritingConfigurationDetail;

                this.config.$overrideMinimumPremium =
                    angular.isDefined(this.config.minimumPremium) &&
                    this.config.minimumPremium > 0;

                if (
                    this.config.commissionOverride ||
                    this.config.commissionOverride === 0
                ) {
                    this.commissionOverrideEnabled = true;
                    this.config.commissionOverride =
                        this.config.commissionOverride * 100;
                }

                if (
                    this.config.renewalCommissionOverride ||
                    this.config.renewalCommissionOverride === 0
                ) {
                    this.renewalCommissionOverrideEnabled = true;
                    this.config.renewalCommissionOverride =
                        this.config.renewalCommissionOverride * 100;
                }

                for (let i = 0; i < this.config.rateOverrides.length; i++) {
                    for (
                        let j = 0;
                        j < this.config.rateOverrides[i].conditions.length;
                        j++
                    ) {
                        if (
                            this.config.rateOverrides[i].conditions[j]
                                .conditionType ===
                            RuleConditionType.UnderwritingQuestion
                        ) {
                            this.config.rateOverrides[i].conditions[j][
                                "questionSearch"
                            ] = new SearchControl();
                            this.config.rateOverrides[i].conditions[j][
                                "questionSearch"
                            ].selected =
                                this.config.rateOverrides[i].conditions[
                                    j
                                ].masterApplicationQuestion;
                            this.createRadioChoices(
                                this.config.rateOverrides[i].conditions[j]
                                    .masterApplicationQuestion
                            );
                        }
                    }
                }

                for (
                    let i = 0;
                    i < this.config.bondFormAttachments.length;
                    i++
                ) {
                    for (
                        let j = 0;
                        j <
                        this.config.bondFormAttachments[i].conditions.length;
                        j++
                    ) {
                        this.config.bondFormAttachments[i].conditions[j][
                            "questionSearch"
                        ] = new SearchControl();
                        this.config.bondFormAttachments[i].conditions[j][
                            "questionSearch"
                        ].selected =
                            this.config.bondFormAttachments[i].conditions[
                                j
                            ].masterApplicationQuestion;
                        this.createRadioChoices(
                            this.config.bondFormAttachments[i].conditions[j]
                                .masterApplicationQuestion
                        );
                    }
                }
            });
    }

    private loadWritingCompanies(): IPromise<void> {
        const writingCompanySvc = this.odata.getService<WritingCompany>(
            ODataEndpoint.WritingCompany
        );

        const query = this.odata.getQuery();
        query.orderby("name");

        return writingCompanySvc.get(query).then((response) => {
            this.writingCompanies = response.data.value;
        });
    }

    private loadRequiredFieldSets(): IPromise<void> {
        const requiredFieldSetSvc = this.odata.getService<RequiredFieldSet>(
            ODataEndpoint.RequiredFieldSet
        );

        const query = this.odata.getQuery();
        query.orderby("name");

        return requiredFieldSetSvc.get(query).then((response) => {
            this.requiredFieldSets = response.data.value;
        });
    }

    private loadBondNumberGroups(): IPromise<void> {
        const bondNumberGroupsSvc = this.odata.getService<BondNumberGroup>(
            ODataEndpoint.BondNumberGroup
        );

        const query = this.odata.getQuery();
        query.orderby("name");

        return bondNumberGroupsSvc.get(query).then((response) => {
            this.bondNumberGroups = response.data.value;
        });
    }

    private loadRates(): IPromise<void> {
        const rateSvc = this.odata.getService<Rate>(ODataEndpoint.Rate);

        const query = this.odata.getQuery();
        query.orderby("name");

        return rateSvc.get(query).then((response) => {
            this.rates = response.data.value;
        });
    }

    private loadHasBondNumberIntegration(): IPromise<void> {
        return this.systemAccountService
            .getHasBondNumberIntegration(
                this.currentUserResolver.getCurrentUser().user.systemAccountId
            )
            .then((response) => {
                this.isCarrierBondNumberIntegrationEnabled = response;
            });
    }

    private prepareRequest(): void {
        if (!this.config.$overrideMinimumPremium) {
            delete this.config.minimumPremium;
        }

        for (let i = 0; i < this.config.underwritingQuestions.length; i++) {
            delete this.config.underwritingQuestions[i]
                .masterApplicationQuestion["options"];
        }

        for (let i = 0; i < this.config.bondFormAttachments.length; i++) {
            delete this.config.bondFormAttachments[i].document;

            if (!this.config.bondFormAttachments[i].conditions) {
                this.config.bondFormAttachments[i].conditions = [];
            }

            for (
                let k = 0;
                k < this.config.bondFormAttachments[i].conditions.length;
                k++
            ) {
                const condition =
                    this.config.bondFormAttachments[i].conditions[k];
                condition.masterApplicationQuestionId =
                    condition.masterApplicationQuestion.id;
                delete condition.masterApplicationQuestion["options"];
                delete condition["questionSearch"];
                delete condition.masterApplicationQuestion;
            }
        }

        for (let i = 0; i < this.config.rateOverrides.length; i++) {
            this.config.rateOverrides[i].sequence = i;

            for (
                let k = 0;
                k < this.config.rateOverrides[i].conditions.length;
                k++
            ) {
                const condition = this.config.rateOverrides[i].conditions[k];

                if (condition.conditionType === RuleConditionType.BondAmount) {
                    delete condition.requiredValue;
                } else if (
                    condition.conditionType ===
                    RuleConditionType.UnderwritingQuestion
                ) {
                    condition.masterApplicationQuestionId =
                        condition.masterApplicationQuestion.id;
                    delete condition.minimumBondAmount;
                    delete condition.maximumBondAmount;
                }

                delete condition.masterApplicationQuestion;
                delete condition["questionSearch"];
            }
        }

        if (!this.commissionOverrideEnabled) {
            this.config.commissionOverride = null;
        } else {
            this.config.commissionOverride =
                this.config.commissionOverride * 0.01;
        }

        if (!this.renewalCommissionOverrideEnabled) {
            this.config.renewalCommissionOverride = null;
        } else {
            this.config.renewalCommissionOverride =
                this.config.renewalCommissionOverride * 0.01;
        }
    }

    public searchDocuments(searchPhrase: string): void {
        this.documentService.searchDocuments(searchPhrase)
            .then((response) => {
                this.documentSearch.matches = response;
            });
    }

    public addDocument(): void {
        if (!this.documentSearch || !this.documentSearch.selected) {
            return;
        }

        if (!this.config.bondFormAttachments) {
            this.config.bondFormAttachments = [];
        }

        for (let i = 0; i < this.config.bondFormAttachments.length; i++) {
            if (
                this.config.bondFormAttachments[i].documentId ===
                this.documentSearch.selected.id
            ) {
                this.documentSearch.selected = null;
                return;
            }
        }

        this.config.bondFormAttachments.push({
            underwritingConfigurationId: this.config.id,
            documentId: this.documentSearch.selected.id,
            document: {
                id: this.documentSearch.selected.id,
                name: this.documentSearch.selected.name
            } as Document
        } as UnderwritingConfigurationBondFormAttachment);

        this.documentSearch.selected = null;
    }

    public removeDocument(index: number): void {
        this.config.bondFormAttachments.splice(index, 1);
    }

    public setRateOverrideConditionMasterApplicationQuestion(
        condition: RateOverrideCondition,
        masterApplicationQuestion: MasterApplicationQuestion
    ): void {
        condition.masterApplicationQuestion = { ...masterApplicationQuestion };
        this.createRadioChoices(condition.masterApplicationQuestion);
    }

    public addBondFormAttachmentCondition(
        bondFormAttachment: UnderwritingConfigurationBondFormAttachment
    ): void {
        if (!bondFormAttachment.conditions) {
            bondFormAttachment.conditions = [];
        }

        const condition = {};
        condition["questionSearch"] = new SearchControl();
        bondFormAttachment.conditions.push(
            condition as UnderwritingConfigurationBondFormAttachmentCondition
        );
    }

    public removeBondFormAttachmentCondition(
        bondFormAttachment: UnderwritingConfigurationBondFormAttachment,
        index: number
    ): void {
        bondFormAttachment.conditions.splice(index, 1);
    }

    public setBondAttachmentConditionMasterApplicationQuestion(
        condition: UnderwritingConfigurationBondFormAttachmentCondition,
        masterApplicationQuestion: MasterApplicationQuestion
    ): void {
        condition.masterApplicationQuestion = { ...masterApplicationQuestion };
        this.createRadioChoices(condition.masterApplicationQuestion);
    }

    public addRateOverrideCondition(rateOverride: RateOverride): void {
        const condition = {
            conditionType: RuleConditionType.UnderwritingQuestion
        };
        condition["questionSearch"] = new SearchControl();
        rateOverride.conditions.push(condition as RateOverrideCondition);
    }

    public removeRateOverrideCondition(
        rateOverride: RateOverride,
        index: number
    ): void {
        rateOverride.conditions.splice(index, 1);
    }

    public addRateOverride(): void {
        if (!this.config.rateOverrides) {
            this.config.rateOverrides = [];
        }

        this.config.rateOverrides.push({
            conditions: []
        } as RateOverride);
    }

    public removeRateOverride(index: number): void {
        this.config.rateOverrides.splice(index, 1);
    }

    public $onInit(): void {
        this.documentSearch = new SearchControl();
        this.bondTypeSearch = new SearchControl();
        this.questionSearch = new SearchControl();

        this.quoteStatusOptions = [
            {
                label: "Pending",
                value: "Pending"
            },
            {
                label: "Declined",
                value: "Declined"
            },
            {
                label: "Approved",
                value: "Approved"
            }
        ];

        const promise = this.$q.all([
            this.loadWritingCompanies(),
            this.loadRequiredFieldSets(),
            this.loadBondNumberGroups(),
            this.loadRates(),
            this.loadHasBondNumberIntegration()
        ]);

        this.busyIndicator = {
            message: "Loading...",
            promise: promise.then(() => {
                if (this.$stateParams.id) {
                    this.config = {
                        id: this.$stateParams.id
                    } as UnderwritingConfigurationDetail;
                    return this.loadExistingConfiguration();
                } else {
                    this.config = {
                        id: this.$stateParams.id || 0,
                        isActive: true,
                        systemAccountId:
                            this.currentUserResolver.getCurrentUser().user
                                .systemAccountId,
                        requiresCreditReport: false,
                        bondNumberGroupId: null,
                        writingCompanyId: null,
                        requiredFieldSetId: null,
                        passStatus: QuoteStatus.Approved,
                        failStatus: QuoteStatus.Pending,
                        underwritingQuestions: [],
                        bondFormAttachments: [],
                        rateOverrides: [],
                        $overrideMinimumPremium: false
                    } as UnderwritingConfigurationDetail;

                    return this.$q.when();
                }
            })
        };
    }
}

export interface UnderwritingConfigurationDetail
    extends UnderwritingConfiguration {
    $overrideMinimumPremium: boolean;
}

export interface UnderwritingConfigurationDetailParams {
    id: number;
}
