import { IPromise } from "angular";
import { Injectables } from "../configuration/injectables";
import app from "../main";

export class CacheStore {

    public static $inject = [];

    constructor() {
        this.cache = {};
    }

    private cache: object;

    public getData = (url: string, fetchFunction: () => IPromise<any>) => {
        if(this.cache[url]) {
            return this.cache[url];
        }

        let promise = fetchFunction()
            .catch(() => this.clearSingle(url));

        this.cache[url] = promise;

        return promise;
    }

    public clearSingle = (url: string) => {
        delete this.cache[url];
    }

    public clearAll = () => {
        this.cache = {};
    }
}

app.service(Injectables.CacheStore, CacheStore);
