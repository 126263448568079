import { AuthService } from "../../api/authService";
import { CurrentUser } from "../../api/types/authentication/currentUser";
import { Injectables } from "../../configuration/injectables";
import { State } from "../../states/state";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";
import app from "../../main";
import { SystemSettings } from "../../configuration/settings/systemSettings";

export class LoggedInUserDropdownController {
    static $inject = [
        Injectables.CurrentUserResolver,
        Injectables.AuthService,
        Injectables.$state,
        Injectables.SystemSettings
    ];

    constructor(
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly authService: AuthService,
        private readonly $state: State,
        private readonly systemSettings: SystemSettings
    ) {}

    public userDropdownOpen: boolean;
    public currentUser: CurrentUser;

    public logout() {
        const isSingleSignOn = this.currentUserResolver.getCurrentUser().isSingleSignOn;

        this.authService.logout();

        if (!isSingleSignOn) {
            this.$state.go('login');
        } else {
            window.location = this.systemSettings.ajgSsoLoginUrl as Location | (string & Location);
        }
        
    };

    public $onInit = () => {
        this.currentUser = this.currentUserResolver.getCurrentUser();
    }
}

const loggedInUserDropdownComponent = {
    templateUrl: 'app/components/loggedInUserDropdown/loggedInUserDropdown.html',
    controller: LoggedInUserDropdownController,
    controllerAs: 'vm'
};

app.component('loggedInUserDropdown', loggedInUserDropdownComponent);