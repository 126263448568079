import { City } from "./city";
import { EquifaxMatchCodes } from "./matchCodes";
import { PersonName } from "./personName";
import { EquifaxProblemCode } from "./problemCode";
import { ProblemReportDate } from "./problemReportDate";
import { EquifaxResponseCode } from "./responseCode";
import { EquifaxResponseType } from "./responseType";
import { EquifaxTransactionDate } from "./transactionDate";

export interface USOFACAlert { 
    revisedLegalVerbiageIndicator: string;
    cdcMemberCode: string;
    cdcBranchAccountNumber: string;
    transactionDate: EquifaxTransactionDate;
    transactionTime: string;
    responseType: EquifaxResponseType;
    responseCode: EquifaxResponseCode;
    problemCode: EquifaxProblemCode;
    matchCodes: EquifaxMatchCodes;
    problemReportDate: ProblemReportDate;
    issueSource: string;
    issueId: string;
    comment: string;
    personName: PersonName;
    addressLine1: string;
    city: City;
    state: string;
    postalCode: string;
    countryCode: string;
    legalVerbiage: string;
}