import { IPromise, ITimeoutService } from "angular";
import { ODataFactory, ODataEndpoint } from "../../../api/odata";
import { ApplicationType } from "../../../api/types/model/application";
import { CustomerType, Customer } from "../../../api/types/model/customer";
import { SelectOption } from "../../../api/types/selectOption";
import { Injectables } from "../../../configuration/injectables";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { State } from "../../state";

export class CustomerSelectionController {

    public static $inject = [
        Injectables.$state,
        Injectables.$stateParams,
        Injectables.ODataFactory,
        Injectables.$timeout,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly $state: State,
        private readonly $stateParams: CustomerSelectionStateParams,
        private readonly odata: ODataFactory,
        private readonly $timeout: ITimeoutService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) { }

    public filterTimer: any;
    public formSubmitted = false;
    public appType = this.$stateParams.appType;
    public newCustomer = this.$stateParams.customerType === 'new';
    public existingCustomer = this.$stateParams.customerType === 'existing';
    public customerTypes: SelectOption<string>[] = [{ label: 'Company', value: 'Company' }, { label: 'Individual', value: 'Individual' }];
    public newCustomerType =  CustomerType.Individual;
    public newCompanyCustomer = { customerType:  CustomerType.Company, companies: [{}] } as  Customer;
    public newIndividualCustomer = { customerType:  CustomerType.Individual, people: [{}] } as  Customer;
    public lookupCustomerObject: LookupCustomer = { companyName: '', firstName: '', lastName: '', email: '', cellPhone: '' };
    public lookupPromise: IPromise<any>;
    public matchingCustomers:  Customer[];

    public lookupCustomer(newCustomer): void {

        this.lookupCustomerObject.companyName = newCustomer && this.newCustomerType === 'Company' ? this.newCompanyCustomer.companies[0].name : this.lookupCustomerObject.companyName;
        this.lookupCustomerObject.firstName = newCustomer && this.newCustomerType !== 'Company' ? this.newIndividualCustomer.people[0].firstName : this.lookupCustomerObject.firstName;
        this.lookupCustomerObject.lastName = newCustomer && this.newCustomerType !== 'Company' ? this.newIndividualCustomer.people[0].lastName : this.lookupCustomerObject.lastName;
        this.lookupCustomerObject.email = newCustomer && this.newCustomerType !== 'Company' ? this.newIndividualCustomer.people[0].email : this.lookupCustomerObject.email;
        this.lookupCustomerObject.cellPhone = newCustomer && this.newCustomerType !== 'Company' ? this.newIndividualCustomer.people[0].cellPhone : this.lookupCustomerObject.cellPhone;

        // filter timer creates a pause between last key stroke and query
        if (this.filterTimer) { this.$timeout.cancel(this.filterTimer); }
        this.filterTimer = this.$timeout(() => {

            const query = this.odata.getQuery();
            query.top(15);
            query.expand('people,companies');
            let filter = `companies/any(c: startswith(c/name, '${this.lookupCustomerObject.companyName}'))`;

            if (this.lookupCustomerObject.firstName || this.lookupCustomerObject.lastName || this.lookupCustomerObject.email || this.lookupCustomerObject.cellPhone) {
                filter += ' or (';
                filter += this.lookupCustomerObject.firstName ? `people/any(p: startswith(p/firstName,'${this.lookupCustomerObject.firstName}')) and ` : '';
                filter += this.lookupCustomerObject.lastName ? `people/any(p: startswith(p/lastName,'${this.lookupCustomerObject.lastName}')) and ` : '';
                filter += this.lookupCustomerObject.email ? `people/any(p: startswith(p/email,'${this.lookupCustomerObject.email}')) and ` : '';
                filter += this.lookupCustomerObject.cellPhone ? `people/any(p: startswith(p/cellPhone,'${this.lookupCustomerObject.cellPhone}'))` : '';
                filter = filter.replace(/ and +$/, '');
                filter += ')';
            }

            query.filter(filter);
            const customerSvc = this.odata.getService< Customer>(ODataEndpoint.Customer);

            this.lookupPromise = customerSvc.get(query)
                .then((response) => {
                    this.matchingCustomers = response.data.value;
                })
                .catch(() => { });

        },
            500);
    }

    public addNewCustomer(): void {
        let cust:  Customer;

        if (this.newCustomerType ===  CustomerType.Company) {
            cust = this.newCompanyCustomer;
        } else {
            cust = this.newIndividualCustomer;
        }

        const customerSvc = this.odata.getService< Customer>(ODataEndpoint.Customer);
        customerSvc.post< Customer>(cust)
            .then((response) => {
                this.selectCustomer(response.data.id);
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to create the new customer');
            });
    }

    public selectCustomer(customerId: number): void {
        this.$state.go('main.application', { appType: this.appType, customerId: customerId, bondId: null });
    }

    public $onInit(): void { }
}

export class LookupCustomer {
    public companyName: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public cellPhone: string;
}

export class CustomerSelectionStateParams {
    public customerType: string;
    public appType:  ApplicationType;
}