import { IDeferred, IPromise, IQService } from "angular";
import { WritingCompany } from "../../api/types/model/writingCompany";
import { SelectOption } from "../../api/types/selectOption";
import { WritingCompanyService } from "../../api/writingCompanyService";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { SystemSettings } from "../../configuration/settings/systemSettings";

import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { WritingCompanyOptions } from "./writingCompanyModalOptions";

export class WritingCompanyModalController {

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.WritingCompanyService,
        Injectables.$q,
        Injectables.ToastMessageCreator,
        Injectables.SystemSettings
    ];

    constructor(
        private readonly $uibModalInstance: Modal<WritingCompany>,
        private readonly options: WritingCompanyOptions,
        private readonly writingCompanyService: WritingCompanyService,private readonly $q: IQService,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly systemSettings: SystemSettings) { 
    }


    public seal: File;
    public sealDropzone: Dropzone;
    public writingCompany: WritingCompany;
    public documentSearch: any;
    public selectedPoaDocument: any;
    public priorityOptions: SelectOption[];
    public sealPicturePath: File;
    public poaInvalid: boolean;
    public sealInvalid: boolean;
    public processingPromise: IPromise<any>;
    public defer:IDeferred<any>;

    public sealDzAddedFile(file: File): void {
        this.seal = file;
        this.sealInvalid = false;

        if (this.sealDropzone.files.length > 1) {
            this.sealDropzone.files[0] = this.sealDropzone.files[1];
            this.sealDropzone.files.splice(1, 1);
        }
    }

    public sealDzError(file: File, response: any, xhr: any): void {
        if (!response.Message) {
            this.seal = undefined;
        }
        this.defer.reject();
    }

    public sealDzProcessing(file: File): void {
        this.sealDropzone.options.url = this.systemSettings.apiBaseUrl + 'upload/WritingCompanySeal';
    }

    public sealDzSuccess(file: File, response: any): void {
        this.sealDropzone.removeAllFiles();
        this.writingCompany.corporateSealFileId = response;
        this.defer.resolve();
    }

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }

    public save(): void {
        if (this.sealDropzone.files.length > 0) {
            this.sealDropzone.processQueue();

            this.defer = this.$q.defer();
            this.processingPromise = this.defer.promise.then(() => this.submit());
        } else {
            this.processingPromise = this.submit();
        }
    }

    public submit = () => {
        if (!this.writingCompany.powerOfAttorneyDocument) {
            this.poaInvalid = true;
            return this.$q.when();
        }

        if (!this.writingCompany.corporateSealFileId) {
            this.sealInvalid = true;
            return this.$q.when();
        }

        return this.writingCompanyService
            .saveWritingCompany(this.writingCompany.id, this.writingCompany)
            .then((savedWritingCompany) => {
                if (savedWritingCompany) {
                    this.writingCompany.id = savedWritingCompany.id;
                }

                this.$uibModalInstance.close(this.writingCompany);

                this.toastMessageCreator.createSuccessMessage('Writing company saved successfully');
            });
    }

    public searchDocuments(search): void {
        this.writingCompanyService
            .searchDocuments(search)
            .then((documents) => {
                this.documentSearch = documents;
            });
    }

    public selectDocument(): void {
        if (!this.selectedPoaDocument) {
            return;
        }

        this.writingCompany.powerOfAttorneyDocument = this.selectedPoaDocument;
        this.writingCompany.powerOfAttorneyDocumentId = this.selectedPoaDocument.id;

        delete this.writingCompany.powerOfAttorneyDocument['rating'];
        delete this.writingCompany.powerOfAttorneyDocument['documentCategoryName'];
        delete this.writingCompany.powerOfAttorneyDocument['documentRatings'];
        delete this.writingCompany.powerOfAttorneyDocument['documentCategory'].name;
        delete this.writingCompany.powerOfAttorneyDocument['createdDateTime']; //was powerOfAttorneyDocument.modifiedDateTime not currently accessable

        this.selectedPoaDocument = null;
        this.poaInvalid = false;
    }

    public onSelected($item: any): void {
        this.selectedPoaDocument = $item;
    }

    public $onInit(): void {
        this.writingCompany = this.options.company;

        this.priorityOptions = [{ label: '1', value: 1 }, { label: '2', value: 2 }, { label: '3', value: 3 }, { label: '4', value: 4 }, { label: '5', value: 5 }];

        if (this.writingCompany && this.writingCompany.id) {
            this.writingCompanyService
                .getSealPicture(this.writingCompany.id)
                .then((imgData) => {
                    this.sealPicturePath = imgData;
                });
        }
    }
}