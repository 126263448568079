import { BondAccountStatus } from "./bondAccountStatus";

export interface BondAccountListItem {
    id: number;
    carrierName: string;
    carrierAccountNumber: string;
    carrierSystemAccountId: number;
    status: BondAccountStatus;
    singleBondLimit: number;
    aggregateBondLimit: number;
    effectiveDate: Date;
    expirationDate: Date;
}