import { AccountDesignator } from "./accountDesignator";
import { AccountType } from "./accountType";
import { ActivityDesignator } from "./activityDesignator";
import { ActualPaymentAmount } from "./actualPaymentAmount";
import { BalanceAmount } from "./balanceAmount";
import { BalloonPaymentAmount } from "./balloonPaymentAmount";
import { BalloonPaymentDueDate } from "./balloonPaymentDueDate";
import { CreditLimit } from "./creditLimit";
import { CreditorClassification } from "./creditorClassification";
import { CreditorId } from "./creditorId";
import { DateClosed } from "./dateClosed";
import { DateMajorDelinquencyReported } from "./dateMajorDelinquencyReported";
import { DateOfFirstDelinquency } from "./dateOfFirstDelinquency";
import { DateOfLastActivity } from "./dateOfLastActivity";
import { DateOfLastPayment } from "./dateOfLastPayment";
import { DateOpened } from "./dateOpened";
import { DateReported } from "./dateReported";
import { DeferredPaymentStartDate } from "./deferredPaymentStartDate";
import { HighCreditAmount } from "./highCreditAmount";
import { HistoryDerogatoryCounters } from "./historyDerogatoryCounters";
import { Narratives } from "./narratives";
import { OriginalChargeOffAmount } from "./originalChargeOffAmount";
import { PastDueAmount } from "./pastDueAmount";
import { PortfolioType } from "./portfolioType";
import { PreviousHighDateOutsideHistory } from "./previousHighDateOutsideHistory";
import { PreviousHighPaymentRates } from "./previousHighPaymentRates";
import { PreviousHighRateOutsideHistory } from "./previousHighRateOutsideHistory";
import { PurchasedPortfolioIndicator } from "./purchasedPortfolioIndicator";
import { ScheduledPaymentAmount } from "./scheduledPaymentAmount";
import { Status } from "./status";
import { TermsFrequency } from "./termsFrequency";
import { UpdateIndicator } from "./updateIndicator";

export interface USTrade { 
    creditorId: CreditorId;
    dateReported: DateReported;
    dateOpened: DateOpened;
    accountNumber: string;
    highCreditAmount: HighCreditAmount;
    creditLimit: CreditLimit;
    balanceAmount: BalanceAmount;
    pastDueAmount: PastDueAmount;
    portfolioType: PortfolioType;
    status: Status;
    monthsReviewed: string;
    accountDesignator: AccountDesignator;
    dateOfLastActivity: DateOfLastActivity;
    dateOfFirstDelinquency: DateOfFirstDelinquency;
    historyDerogatoryCounters: HistoryDerogatoryCounters;
    previousHighPaymentRates: PreviousHighPaymentRates;
    accountType: AccountType;
    dateOfLastPayment: DateOfLastPayment;
    dateClosed: DateClosed;
    dateMajorDelinquencyReported: DateMajorDelinquencyReported;
    actualPaymentAmount: ActualPaymentAmount;
    scheduledPaymentAmount: ScheduledPaymentAmount;
    termsFrequency: TermsFrequency;
    termsDuration: string;
    purchasedPortfolioIndicator: PurchasedPortfolioIndicator;
    purchasedPortfolioName: string;
    creditorClassification: CreditorClassification;
    activityDesignator: ActivityDesignator;
    originalChargeOffAmount: OriginalChargeOffAmount;
    deferredPaymentStartDate: DeferredPaymentStartDate;
    balloonPaymentAmount: BalloonPaymentAmount;
    balloonPaymentDueDate: BalloonPaymentDueDate;
    mortgageIDNumber: string;
    previousHighRateOutsideHistory: PreviousHighRateOutsideHistory;
    previousHighDateOutsideHistory: PreviousHighDateOutsideHistory;
    paymentHistory: string;
    updateIndicator: UpdateIndicator;
    narratives: Narratives;
}