import BillingEntryType from "../../../../app/api/types/billingEntries/billingEntryType";
import { TransactionType } from "../../../../app/api/types/model/bondTransaction";
import { BillToType } from "../../../api/types/billToType";
export interface BillingEntryReportItem {
    id: number;
    bondId: number;
    invoiceId?: number;
    invoiceNumber?: number;
    createdDateTime: Date;
	billingEntryType: BillingEntryType;
    code: string;
	originalAmount: number;
	amountReceived: number;
    amountDue: number;
    customerId: number;
    customerName: string;
    producerUserId: number;
    producerUserName:string;
    productionCredit: boolean;
    lookupCode: string;
    eProducerAccountId: number;
    eProducerAccouontName: string;
    brokerCommission: number;
    billToType: BillToType;
    transactionType: TransactionType;
    bondNumber: string;
}
