import { ObligeeService } from "../../../api/obligeeService";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../configuration/injectables";
import { ModalOpener } from "../../../modals/modalOpener";
import { IDebounceDelayer } from "../../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../../utilities/tables/table";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { ObligeeTableItem } from "./obligeeTableItem";
import { ObligeeTableQueryOptions } from "./obligeeTableQueryOptions";

export class ObligeeGridController {
    
    public static $inject = [
        Injectables.ObligeeService,
        Injectables.ModalOpener,
        Injectables.IDebouceDelayer,
        Injectables.ToastMessageCreator
    ];

    public constructor(
        private readonly obligeeService: ObligeeService,
        private readonly modalOpener: ModalOpener,
        debouceDelayer: IDebounceDelayer,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {             
        this.table = new Table(debouceDelayer);
        this.table.onChange = this.loadData;
    }

    public table: Table<ObligeeTableItem, ObligeeTableQueryOptions>;
    public busyIndicator: BusyIndicator;

    public loadData = (): void => {
        this.busyIndicator.message = 'Loading Obligees...';
        this.busyIndicator.promise = this.obligeeService.getObligees(this.table.queryOptions)
            .then((response) => {
                this.table.setData(response.items, response.totalRecordCount);
            });
    }

    public $onInit(): void {
        this.busyIndicator = {};
        this.loadData();
    }

    public showHistory(obligee: ObligeeTableItem) {
        this.modalOpener.showObligeeHistoryModal(obligee.id);
    }

    public promptToDelete(obligee: ObligeeTableItem) {
        this.modalOpener.confirmModal(
                'Delete Obligee',
                'Are you sure you want to delete the user obligee named ' + obligee.name + '?',
                'Delete',
                'Cancel')
            .result
            .then(() => {
                this.busyIndicator.message = "Deleting...";
                this.busyIndicator.promise = this.obligeeService.deleteById(obligee.id)
                    .then(() => {
                        this.loadData();
                        this.toastMessageCreator.createSuccessMessage('Obligee was deleted successfully');
                    })
                    .catch(() => {
                        this.toastMessageCreator.createErrorMessage('An Error occurred deleting the obligee');
                    })
            })
            .catch(() => {});
    }
}
