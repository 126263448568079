import { Injectables } from "../../configuration/injectables";
import { FormatType } from "../physicalAddressFilter/physicalAddressFilter";
import app from "../../main";
import { ISCEService } from 'angular';

export const MailingAddressFilter = ($sce: ISCEService) => {
    
    return (object?: any, formatType?: FormatType) => {
        if (!object) { return ''; }

        let address = '';

        switch (formatType) {
            case FormatType.TwoLine:
                address = (object.mailAddress ? object.mailAddress : '') + ' ' +
                    (object.mailSuiteAptNumber ? object.mailSuiteAptNumber : '') + '<br/>' +
                    (object.mailCity ? object.mailCity : '') + ' ' +
                    (object.mailState ? object.mailState : '') + ' ' +
                    (object.mailZip ? object.mailZip : '') + ' ' +
                    (object.mailCounty ? '<br/>' + object.mailCounty + ' county' : '');
                break;
            case FormatType.Pipe:
                address = (object.mailAddress ? object.mailAddress : '') + '{|}' +
                    (object.mailSuiteAptNumber ? object.mailSuiteAptNumber : '') + '{|}' +
                    (object.mailCity ? object.mailCity : '') + '{|}' +
                    (object.mailState ? object.mailState : '') + '{|}' +
                    (object.mailZip ? object.mailZip : '') + '{|}' +
                    (object.mailCounty ? object.mailCounty : '');
                break;
            default:
                address = (object.mailAddress ? object.mailAddress : '') + ' ' +
                    (object.mailSuiteAptNumber ? object.mailSuiteAptNumber : '') + ' ' +
                    (object.mailCity ? object.mailCity : '') + ', ' +
                    (object.mailState ? object.mailState : '') + ' ' +
                    (object.mailZip ? object.mailZip : '') + ' ' +
                    (object.mailCounty ? object.mailCounty + ' county' : '');
                break;
        }
        return $sce.trustAsHtml(address);
        
    };
}

MailingAddressFilter.$inject = [Injectables.$sce];

app.filter('mailingAddress', MailingAddressFilter);
