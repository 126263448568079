import { BondService } from "../../api/bondService";
import { BondAccountRateType } from "../../api/types/bondAccounts/bondAccountRateType";
import { InvoiceCreationType } from "../../api/types/invoices/invoiceCreationType";
import { BondStatus } from "../../api/types/model/bond";
import { Fee } from "../../api/types/model/fee";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ICurrencyFormatter } from "../../utilities/currencyFormatter/iCurrencyFormatter";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { RenewalModalBond } from "./renewalModalBond";
import { RenewalModalOptions } from "./renewalModalOptions";
import { RenewalModalResult } from "./renewalModalResult";
import { RenewalModalSubmission } from "./renewalModalSubmission";
import { RenewalRequest } from "./renewalRequest";
import { RenewalSubmissionCalculatorResult } from "./renewalSubmissionCalculatorResult";
import { RenewalSubmissionCalculatorResultSummary } from "./renewalSubmissionCalculatorResultSummary";
import app from "../../main";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";

export class RenewalModalController {
    
    public static $inject = [
        Injectables.Options,
        Injectables.$uibModalInstance,
        Injectables.BondService,
        Injectables.CurrencyFormatter,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly options: RenewalModalOptions,
        private readonly $uibModalInstance: Modal<RenewalModalResult>,
        private readonly bondService: BondService,
        private readonly currencyFormatter: ICurrencyFormatter,
        private readonly toastMessageCreator: ToastMessageCreator
    ){}

    public busyIndicator: BusyIndicator;
    public bonds: RenewalModalBond[];
    public invoiceCreationType: InvoiceCreationType;
    public isConfirmationPaneVisible = false;
    public renewalCalculationSummary: RenewalSubmissionCalculatorResultSummary;
    public errorMessage: string

    public addFee(bond: RenewalModalBond) {
        bond.fees.push({} as Fee);
    }

    public removeFee(bond: RenewalModalBond, index: number) {
        bond.fees.splice(index, 1);
    }

    public getRenewalCalculation(bondId: number): RenewalSubmissionCalculatorResult {
        if (!this.renewalCalculationSummary) {
            return null;
        }

        return this.renewalCalculationSummary.calculatedRenewals
            .filter((bond) => bond.bondId == bondId)[0];
    }

    public remove(index: number): void {
        this.bonds.splice(index, 1);
    }

    public showConfirmationPane(): void {
        const renewalSubmissions: RenewalModalSubmission[] = this.bonds.map((bond) => {
            return {
                bondId: bond.id,
                effectiveDate: bond.newEffectiveDate,
                expirationDate: bond.newExpirationDate,
                isBondAccountPremium: bond.isBondAccountPremium,
                premium: bond.premium,
                commissionPercent: bond.commissionPercent,
                commission: bond.commission,
                commissionType: bond.commissionType,
                fees: bond.fees
            }
        });
        
        this.busyIndicator.message = 'Calculating Renewal Totals...';
        this.busyIndicator.promise = this.bondService.calculateRenewalTotals(renewalSubmissions)
            .then((results) => {
                this.renewalCalculationSummary = results;
                this.isConfirmationPaneVisible = true;
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred calculating the renewal totals');
            });
    }

    public hideConfirmationPane(): void {
        this.isConfirmationPaneVisible = false;
    }

    public submit(): void {
        const renewalRequest: RenewalRequest = {
            invoiceCreationType: this.invoiceCreationType,
            bonds: this.renewalCalculationSummary.calculatedRenewals
        };

        this.busyIndicator.message = 'Submitting Renewals...';
        this.busyIndicator.promise = this.bondService.renewBonds(renewalRequest)
            .then(() => {
                this.$uibModalInstance.close({} as RenewalModalResult);
                this.toastMessageCreator.createSuccessMessage('Bond renewals completed successfully');
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to renew the selected bonds');
            })
    }

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }

    private loadBonds() {
        this.busyIndicator.message = 'Loading Bonds...';
        this.busyIndicator.promise = this.bondService.getBondsForRenewalModal(this.options.bondIds)
            .then((bonds) => {
                this.bonds = bonds;

                this.setBondPremiumOptions();

                if (this.bonds.some((bond) => bond.status !== BondStatus.Active)) {
                    this.errorMessage = 'Some of the selected bonds are not currently active and cannot be renewed';
                }
            });
    }

    private setBondPremiumOptions() {
        for(let i=0; i<this.bonds.length; i++) {
            const bond = this.bonds[i];

            bond.premiumOptions = [];

            if (bond.bondAccountId) {
                let bondAccountPremiumValue = '';

                if (bond.bondAccountRateType == BondAccountRateType.RatePerThousand &&
                    bond.bondAccountRatePerThousand){
                    bondAccountPremiumValue = `${this.currencyFormatter.format(bond.bondAccountRatePerThousand)}/MM`;
                } else if (bond.bondAccountRateType == BondAccountRateType.Tiered) {
                    bondAccountPremiumValue = 'Tiered';
                }

                if (bondAccountPremiumValue) {
                    bond.premiumOptions.push({
                        label: 'Bond Line Rate - ' + bondAccountPremiumValue,
                        value: 'BondAccount'
                    });
                } else {
                    bond.premiumOptions.push({
                        label: 'Bond Line - No Rate',
                        value: 'BondAccount',
                        isDisabled: true
                    });
                }
            }

            bond.premiumOptions.push({
                label: `${this.currencyFormatter.format(bond.lastPremiumAmount)} - Last Term`,
                value: bond.lastPremiumAmount.toString()
            });
            
            bond.premiumOptions.push({
                label: 'Other',
                value: 'other'
            });
        }
    }

    public $onInit() {
        this.busyIndicator = {};
        this.loadBonds();
    }
}

app.controller('RenewalModalController', RenewalModalController);