import { DocumentVersion } from "../documentVersion";
import { BondTypeDocument } from "./bondTypeDocument";
import { DocumentCategory } from "./documentCategory";
import { DocumentRating } from "./documentRating";
import { IBaseEntity } from "./iBaseEntity";
import { SystemAccount } from "./systemAccount";

export interface Document extends IBaseEntity { 
    bondTypeDocuments: BondTypeDocument[];
    documentCategory: DocumentCategory;
    documentCategoryId: number;
    documentRatings: DocumentRating[];
    documentVersions: DocumentVersion[];
    isArchived: boolean;
    isDefaultApplication: boolean;
    isDefaultCoverLetter: boolean;
    isDefaultIndemnity: boolean;
    isDefaultInvoice: boolean;
    isDefaultNotaryAcknowledgement: boolean;
    isDefaultNoticeOfCancellation: boolean;
    isDefaultAdverseActionNotice: boolean;
    isDefaultQuote: boolean;
    isPublic: boolean;
    isReinstatementNotification: boolean;
    isRiderDocument: boolean;
    lastModifiedDateTime: Date;
    name: string;
    numberOfUser: number;
    reviewComments: string;
    status: DocumentStatus;
    systemAccount: SystemAccount;
    systemAccountId: number;
    id: number;
    createdDateTime: Date;
}

export enum DocumentStatus {	
    Untouched = "Untouched",
    PrimaryReview = "PrimaryReview",
    SecondaryReview = "SecondaryReview",
    Approved = "Approved",
    ProblemReported = "ProblemReported"
}

export enum DocumentType {	
    Applicaiton = "Applicaiton",
    Bond = "Bond",
    Rider = "Rider",
    Reinstatement = "Reinstatement",
    Cancellation = "Cancellation",
    Renewal = "Renewal",
    Invoice = "Invoice",
    Quote = "Quote"
}