import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";

export class BondTypeSelectionModalController {

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options
    ];

    constructor(
        private readonly $uibModalInstance: Modal<BondTypeSelectionModalResult>,
        private readonly options: BondTypeSelectionModalOptions
    ) { }

    public bondTypeSelected(bondTypeId: number): void {
        this.$uibModalInstance.close(new BondTypeSelectionModalResult(bondTypeId));
    }

    
    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }

    public $onInit(): void {
    
    }
}

app.controller('bondTypeSelectionModalController', BondTypeSelectionModalController);

export class BondTypeSelectionModalResult {
    constructor(public readonly bondTypeId: number) {}
}

export type BondTypeSelectionModalOptions = {}
