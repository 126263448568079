import { Injectables } from "../configuration/injectables";
import { ODataFactory, ODataTimeFrameFilterType, ODataEndpoint } from "./odata";
import { Bond } from "./types/bond";
import A3ApiResponse from "./types/a3ApiResponse";
import { CarrierBreakdownDto } from "./types/carrierBreakdownDto";
import { GetActivitiesDto } from "./types/getActivitiesDto";
import { GetCarrierActivitiesDto } from "./types/getCarrierActivitiesDto";
import { GetStateActivityDto } from "./types/getStateActivityDto";
import { TransactionType } from "./types/model/bondTransaction";
import { StateActivityCount } from "./types/stateActivityCount";
import app from "../main";
import { IHttpService, IPromise } from "angular";
import { SystemSettings } from "../configuration/settings/systemSettings";

export class AnalyticsService {

    public static $inject = [
        Injectables.$http, 
        Injectables.ODataFactory,   
        Injectables.SystemSettings
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly odata: ODataFactory,
        private readonly systemSettings: SystemSettings
    ) {
    }

    public getActivities(): IPromise<GetActivitiesDto[]> {
        return this.$http
            .get<A3ApiResponse<GetActivitiesDto[]>>(this.systemSettings.apiBaseUrl + 'Analytics/GetActivities')
            .then((response) => response.data.value);
    }

    public getActiveBondAgencyBreakdown(): IPromise<CarrierBreakdownDto> {
        return this.$http
            .get<A3ApiResponse<CarrierBreakdownDto>>(this.systemSettings.apiBaseUrl + 'Analytics/GetActiveBondAgencyBreakdown')
            .then((response) => response.data.value);
    }

    public getActiveBondCarrierBreakdown(): IPromise<CarrierBreakdownDto> {
        return this.$http
            .get<A3ApiResponse<CarrierBreakdownDto>>(this.systemSettings.apiBaseUrl + 'Analytics/GetActiveBondCarrierBreakdown')
            .then((response) => response.data.value);
    }

    public getCarrierActivities(): IPromise<GetCarrierActivitiesDto[]> {
        return this.$http
            .get<A3ApiResponse<GetCarrierActivitiesDto[]>>(this.systemSettings.apiBaseUrl + 'Analytics/GetCarrierActivities')
            .then((response) => response.data.value);
    }

    public getCarrierCloseRatio(timeFrameFilterType: ODataTimeFrameFilterType): IPromise<string> {
        const svc = this.odata.getService<Bond>(ODataEndpoint.Bond);
        svc.query.filterByTimeFrame(timeFrameFilterType, 'createdDateTime');

        const url = this.systemSettings.apiBaseUrl + 'Analytics/GetCarrierCloseRatio?' + svc.query.toString().replace(/\$/g, '');

        return this.$http
            .get<A3ApiResponse<string>>(url)
            .then((response) => response.data.value);
    }

    public getCarrierStateActivity(type: TransactionType, startDate: Date, endDate: Date, sfaaCodeId: number | string): IPromise<StateActivityCount[]> {
        let sfaaIdConverted: number;
        if (sfaaCodeId && isNaN(sfaaCodeId as number)) {
            sfaaIdConverted = parseInt((sfaaCodeId as string).replace(/"/g, ''), 10);
        }
        else { sfaaIdConverted = null; }

        const request: GetStateActivityDto = {
            endDate: endDate,
            sfaaCodeId: sfaaIdConverted,
            startDate: startDate,
            transactionType: type,
        };

        return this.$http
            .post<A3ApiResponse<StateActivityCount[]>>(this.systemSettings.apiBaseUrl + 'Analytics/GetCarrierStateActivity', request)
            .then((response) => response.data.value);
    }

    public getStateActivity(type: TransactionType, startDate: Date, endDate: Date, sfaaCodeId: number | string): IPromise<StateActivityCount[]> {
        let sfaaIdConverted: number;
        if (sfaaCodeId && isNaN(sfaaCodeId as number)) {
            sfaaIdConverted = parseInt((sfaaCodeId as string).replace(/"/g, ''), 10);
        }
        else { sfaaIdConverted = null; }

        const request: GetStateActivityDto = {
            endDate: endDate,
            sfaaCodeId: sfaaIdConverted,
            startDate: startDate,
            transactionType: type,
        };

        return this.$http
            .post<A3ApiResponse<StateActivityCount[]>>(this.systemSettings.apiBaseUrl + 'Analytics/GetStateActivity', request)
            .then((response) => response.data.value);
    }

    public last180ToLast90DaysCarrierCloseRatio(): IPromise<string> {
        return this.getCarrierCloseRatio(ODataTimeFrameFilterType.Last180ToLast90Days);
    }

    public last90DaysCarrierCloseRatio(): IPromise<string> {
        return this.getCarrierCloseRatio(ODataTimeFrameFilterType.Last90Days);
    }

    public lastMonthCarrierCloseRatio(): IPromise<string> {
        return this.getCarrierCloseRatio(ODataTimeFrameFilterType.LastMonth);
    }

    public lastWeekCarrierCloseRatio(): IPromise<string> {
        return this.getCarrierCloseRatio(ODataTimeFrameFilterType.LastWeek);
    }

    public lastYearCarrierCloseRatio(): IPromise<string> {
        return this.getCarrierCloseRatio(ODataTimeFrameFilterType.LastYear);
    }

    public mtdCarrierCloseRatio(): IPromise<string> {
        return this.getCarrierCloseRatio(ODataTimeFrameFilterType.Mtd);
    }

    public thisWeekCarrierCloseRatio(): IPromise<string> {
        return this.getCarrierCloseRatio(ODataTimeFrameFilterType.ThisWeek);
    }

    public twoMonthsAgoCarrierCloseRatio(): IPromise<string> {
        return this.getCarrierCloseRatio(ODataTimeFrameFilterType.TwoMonthsAgo);
    }

    public ytdCarrierCloseRatio(): IPromise<string> {
        return this.getCarrierCloseRatio(ODataTimeFrameFilterType.Ytd);
    }
}

app.service(Injectables.AnalyticsService, AnalyticsService);
