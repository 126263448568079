

export interface A3WebApplicationFormFields { 
    personFirstName: boolean;
    personMiddleName: boolean;
    personLastName: boolean;
    personSuffix: boolean;
    personPrefix: boolean;
    personSsn: boolean;
    personDob: boolean;
    personDriversLicenseNumber: boolean;
    personDriversLicenseNumberState: boolean;
    personHomePhone: boolean;
    personCellPhone: boolean;
    personFax: boolean;
    personEmail: boolean;
    personGender: boolean;
    personPhysicalAddress: boolean;
    personPhysicalSuite: boolean;
    personPhysicalZip: boolean;
    personPhysicalCity: boolean;
    personPhysicalCounty: boolean;
    personPhysicalState: boolean;
    personMailAddress: boolean;
    personMailSuite: boolean;
    personMailZip: boolean;
    personMailCity: boolean;
    personMailCounty: boolean;
    personMailState: boolean;
    personResidenceType: boolean;
    personDateMovedToResidence: boolean;
    companyName: boolean;
    companyDba: boolean;
    companyType: boolean;
    companyPhone: boolean;
    companyFax: boolean;
    companyFein: boolean;
    companyDateFormed: boolean;
    companyPhysicalAddress: boolean;
    companyPhysicalSuite: boolean;
    companyPhysicalZip: boolean;
    companyPhysicalCity: boolean;
    companyPhysicalCounty: boolean;
    companyPhysicalState: boolean;
    companyMailAddress: boolean;
    companyMailSuite: boolean;
    companyMailZip: boolean;
    companyMailCity: boolean;
    companyMailCounty: boolean;
    companyMailState: boolean;
    personMaritalStatus: boolean;
    personSpousePhone: boolean;
    personSpouseEmail: boolean;
    personSpouseFirstName: boolean;
    personSpouseLastName: boolean;
    personSpouseMiddleName: boolean;
    personSpouseGender: boolean;
    personSpouseDob: boolean;
    personSpouseSsn: boolean;
    personYearsExperience: boolean;
}
