import { ActivityStatus } from "../../api/types/activities/activityStatus";
import { ActivityTypeAssignment } from "../../api/types/activities/activityTypeAssignment";

export interface ActivityListItem {
    id: number;
    description: string;
    lastUpdateDateTime: Date;
    createdDateTime: Date;
    status: ActivityStatus;
    activityTypeAssignments: ActivityTypeAssignment[];
    bondNumber: string;
    bondId?: number;
    customerId?: number;
    customerName: string;
    hasAttachment: boolean;
    createdByCarrierName?: string;
    assignedToUserId?: number;
    assignedToFirstName?: string;
    assignedToLastName?: string;
    dueDate?: Date;
    priorityLevel?: number;
    isFollowed?: boolean;
    }