import { BondNumberGroup } from "./bondNumberGroup";
import { BondType } from "./bondType";
import { IBaseEntity } from "./iBaseEntity";
import { QuoteStatus, IndemnityRequirements } from "./quote";
import { Rate } from "./rate";
import { RateOverride } from "./rateOverride";
import { RequiredFieldSet } from "./requiredFieldSet";
import { SystemAccount } from "./systemAccount";
import { UnderwritingConfigurationBondFormAttachment } from "./underwritingConfigurationBondFormAttachment";
import { UnderwritingConfigurationRate } from "./underwritingConfigurationRate";
import { UnderwritingQuestion } from "./underwritingQuestion";
import { UnderwritingRequiredField } from "./underwritingRequiredField";
import { WritingCompany } from "./writingCompany";

export interface UnderwritingConfiguration extends IBaseEntity {
    systemAccountId: number;
    isActive: boolean;
    bondFormAttachments: UnderwritingConfigurationBondFormAttachment[];
    bondNumberGroup: BondNumberGroup;
    bondNumberGroupId: number;
    bondType: BondType;
    bondTypeId: number;
    failStatus: QuoteStatus;
    highlightComments: string;
    indemnityRequirements: IndemnityRequirements;
    maximumBondAmount: number;
    maximumNrs: number;
    minimumBondAmount: number;
    minimumFico: number;
    minimumVantage: number;
    minimumPremium: number;
    minimumYearsInBusiness: number;
    passStatus: QuoteStatus;
    rate: Rate;
    rateId: number;
    requiredFieldSet: RequiredFieldSet;
    requiredFieldSetId: number;
    requiresCreditReport: boolean;
    systemAccount: SystemAccount;
    term: number;
    underwritingQuestions: UnderwritingQuestion[];
    underwritingRequiredFields: UnderwritingRequiredField[];
    writingCompany: WritingCompany;
    writingCompanyId: number;
    commissionOverride?: number;
    renewalCommissionOverride?: number;
    id: number;
    createdDateTime: Date;
    underwritingConfigurationRates: UnderwritingConfigurationRate[];
    rateOverrides: RateOverride[];
}
