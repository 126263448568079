import { Quote } from "../quote";
import { RiderRequest } from "../riderRequest";
import { CancellationRequest } from "./cancellationRequest";
import { IBaseEntity } from "./iBaseEntity";
import { ReinstatementRequest } from "./reinstatementRequest";

export interface Fee extends IBaseEntity { 
    amount: number;
    cancellationRequest: CancellationRequest;
    cancellationRequestId: number;
    description: string;
    quote: Quote;
    quoteId: number;
    reinstatementRequest: ReinstatementRequest;
    reinstatementRequestId: number;
    riderRequest: RiderRequest;
    riderRequestId: number;
    id: number;
    createdDateTime: Date;
    autoFeeId?: number;
    creditProducer?: boolean;
    includeInBrokerCommissionCalculation?: boolean;
    code?: string;
}