import { IPromise, ITimeoutService } from "angular";
import { TagService } from "../../api/tagService";
import { SelectOption } from "../../api/types/selectOption";
import { Tag } from "../../api/types/tag";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";

export class TagContainerController {
    public static $inject = [Injectables.TagService];

    constructor(private readonly tagService: TagService) {}

    // binding
    public applicationId: number;
    public customerId: number;
    public bondId: number;
    public eproducerAccountId: number;
    public leadId: number;

    public newTagName: string;
    public creatingNewTag: boolean;
    public matchingTags: Tag[];
    public tags: Tag[];
    public processingPromise: IPromise<any>;
    public tagOptions: SelectOption<string>[];
    public tagType: string;

    public cancelCreate(): void {
        this.newTagName = "";
        this.creatingNewTag = false;
    }

    public clickNew(): void {
        this.loadTagOptions();
        this.creatingNewTag = true;
    }

    public alreadyExists(tagName): boolean {
        return this.tags.some((tag) => tag.name === tagName);
    }

    public create(): void {
        if (!this.newTagName || this.alreadyExists(this.newTagName)) {
            this.cancelCreate();
            return;
        }

        const tag: Tag = {
            applicationId: this.applicationId,
            customerId: this.customerId,
            bondId: this.bondId,
            leadId: this.leadId,
            eProducerAccountId: this.eproducerAccountId,
            name: this.newTagName
        } as Tag;

        this.processingPromise = this.tagService.create(tag).then((newTag) => {
            this.tags.push(newTag);
            this.cancelCreate();
            this.loadTags();
        });
    }

    public deleteTag(tag: Tag, $index: number): void {
        if (!tag) {
            throw new Error("tag is not valid");
        }

        this.processingPromise = this.tagService.deleteById(tag.id).then(() => {
            this.tags.splice($index, 1);
        });
    }

    public getTagType(): string {
        if (this.customerId) {
            return "Tag Customer";
        }
        if (this.bondId) {
            return "Tag Bond";
        }
        if (this.eproducerAccountId) {
            return "Tag Broker";
        }
        if (this.applicationId) {
            return "Tag Application";
        }
        if (this.leadId) {
            return "Tag Lead";
        }

        return "Add New Tag";
    }

    public loadTags(): IPromise<void> {
        let promise: IPromise<any>;

        if (this.customerId) {
            promise = this.tagService.getByCustomerId(this.customerId);
        } else if (this.bondId) {
            promise = this.tagService.getByBondId(this.bondId);
        } else if (this.eproducerAccountId) {
            promise = this.tagService.getByEProducerAccountId(
                this.eproducerAccountId
            );
        } else if (this.applicationId) {
            promise = this.tagService.getByApplicationId(this.applicationId);
        } else if (this.leadId) {
            promise = this.tagService.getByLeadId(this.leadId);
        } else {
            throw new Error("Unable to determine how to get tags");
        }

        return promise.then((tags) => {
            this.tags = tags;
        });
    }

    public async loadTagOptions(): Promise<void> {
        this.tagOptions = await this.tagService.loadTagOptions();
    }

    public $onInit(): void {
        this.tags = [];

        this.loadTagOptions();
        this.tagType = this.getTagType();
        this.loadTags();
    }
}

const tagConainerDirective = () => {
    return {
        restrict: "E",
        templateUrl: "app/components/tagContainer/tagContainer.html",
        replace: true,
        scope: {},
        bindToController: {
            applicationId: "=",
            customerId: "=",
            bondId: "=",
            eproducerAccountId: "=",
            leadId: "="
        },
        controllerAs: "vm",
        controller: TagContainerController
    };
};

app.directive("tagContainer", tagConainerDirective);
