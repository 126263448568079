import { PaymentService } from "../../api/paymentService";
import { PaymentStatus } from "../../api/types/payments/paymentStatus";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { PaymentReportItem } from "../../states/agentSpecific/payments/paymentReportItem";
import { IDebounceDelayer } from "../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../utilities/tables/table";
import { BusyIndicator } from "../busyIndicator/busyIndicator";
import { PaymentReportFilterOptions } from "../paymentsReportFilterDropdown/paymentReportFilterOptions";

export class PaymentReportController {
    public static $inject = [
        Injectables.PaymentService,
        Injectables.IDebouceDelayer
    ];

    constructor(
        private readonly paymentService: PaymentService,
        debouceDelayer: IDebounceDelayer
    ) {
        this.table = new Table(debouceDelayer);
        this.table.queryOptions.status = PaymentStatus.Approved;
    }

    public readonly table: Table<PaymentReportItem, PaymentReportFilterOptions>;
    public readonly filters: PaymentReportFilterOptions;
    public readonly onTableChanged: (param: {
        table: Table<PaymentReportItem, PaymentReportFilterOptions>;
    }) => void;
    public readonly onTableReady: (param: {
        table: Table<PaymentReportItem, PaymentReportFilterOptions>;
    }) => void;

    public busyIndicator: BusyIndicator;

    public exportReport = () => {
        this.busyIndicator.message = "Loading for export...";
        this.busyIndicator.promise = this.paymentService
            .downloadPaymentReport(this.table.queryOptions)
            .then(() => {});
    };

    public refresh = () => {
        this.loadData();
    };

    public loadData = () => {
        this.busyIndicator.message = "Loading Payments...";
        this.busyIndicator.promise = this.paymentService
            .getPaymentReportData(this.table.queryOptions)
            .then((response) => {
                for(let i = 0; i < response.items.length; i++) {
                    if (response.items[i].referenceNumber) {
                        response.items[i].referenceNumbers = 
                            response.items[i].referenceNumber
                                .split(',')
                                .map((referenceNumber) => referenceNumber.trim());
                    }
                }

                this.table.setData(response.items, response.totalRecordCount);
            });
    };

    public $onInit(): void {
        this.busyIndicator = {};

        if (this.filters) {
            this.table.applyFilters(this.filters);
        }

        this.loadData();
        this.table.onChange = () => {
            this.loadData();

            if (this.onTableChanged instanceof Function) {
                this.onTableChanged({ table: this.table });
            }
        };

        if (this.onTableReady instanceof Function) {
            this.onTableReady({ table: this.table });
        }
    }
}

const paymentReportComponent = {
    bindings: {
        filters: "=?",
        onTableChanged: "&?",
        onTableReady: "&?"
    },
    controller: PaymentReportController,
    templateUrl: "app/components/paymentReport/paymentReport.html",
    controllerAs: "vm"
};

app.component("paymentReport", paymentReportComponent);
