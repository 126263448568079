import { IBaseEntity } from "./iBaseEntity";
import { SystemAccount } from "./systemAccount";
import { SystemAccountStatementLineItem } from "./systemAccountStatementLineItem";

export interface SystemAccountStatement extends IBaseEntity { 
    systemAccountId: number;
    creditCardFeesDue: number;
    currentBalance: number;
    dueDate: Date;
    endDate: Date;
    lineItems: SystemAccountStatementLineItem[];
    past30DaysBalance: number;
    past60DaysBalance: number;
    past90DaysBalance: number;
    startDate: Date;
    systemAccount: SystemAccount;
    totalDue: number;
    totalPaid: number;
    transactionFeesDue: number;
    id: number;
    createdDateTime: Date;
}