import { Attachment } from "./attachment";
import { SystemType } from "./model/application";
import { BondStatus, RenewalStatus } from "./model/bond";
import { BondAccount } from "./model/bondAccount";
import { BondAdditionalQuestionResponse } from "./model/bondAdditionalQuestionResponse";
import { BondCorrection } from "./model/bondCorrection";
import { BondTransaction } from "./model/bondTransaction";
import { BondType } from "./model/bondType";
import { CancellationRequest, CancellationStatus } from "./model/cancellationRequest";
import { Company } from "./model/company";
import { Customer } from "./model/customer";
import { EProducerAccount } from "./model/eProducerAccount";
import { Memo } from "./model/memo";
import { Obligee } from "./model/obligee";
import { Person } from "./model/person";
import { QuestionResponse } from "./model/questionResponse";
import { ReinstatementRequest, ReinstatementStatus } from "./model/reinstatementRequest";
import { RiderStatus } from "./model/riderRequest";
import { SystemAccount } from "./model/systemAccount";
import { WritingCompany } from "./model/writingCompany";
import { Quote } from "./quote";
import { RiderRequest } from "./riderRequest";
import { Tag } from "./tag";

export interface IBond extends Bond {
    bondAdditionalQuestionResponses: BondAdditionalQuestionResponse[];
}

export class Bond implements IBond {
    bondAdditionalQuestionResponses: BondAdditionalQuestionResponse[];
    carrierSystemAccountId: number;
    eProducerAccountId: number;
    eProducerAccount: EProducerAccount;
    ams360PolicyId: string;
    bondAccount: BondAccount;
    bondAccountId: number;
    bondTransactions: BondTransaction[];
    bondCorrections: BondCorrection[];
    bondType: BondType;
    bondTypeId: number;
    cancellationDate: Date;
    cancellationRequests: CancellationRequest[];
    cancellationStatus: CancellationStatus;
    carrierSystemAccount: SystemAccount;
    customer: Customer;
    customerId: number;
    originSystem: SystemType;
    quote: Quote;
    quoteId: number;
    reinstatementDate: Date;
    reinstatementRequests: ReinstatementRequest[];
    memos: Memo[];
    reinstatementStatus: ReinstatementStatus;
    riderRequests: RiderRequest[];
    riderStatus: RiderStatus;
    status: BondStatus;
    writingCompany: WritingCompany;
    writingCompanyId: number;
    tags: Tag[];
    people: Person[];
    companies: Company[];
    questionResponses: QuestionResponse[];
    bondNumber: string;
    nameOnBond: string;
    bondAmount: number;
    expirationDate: Date;
    effectiveDate: Date;
    originalEffectiveDate: Date;
    amountAsText: string;
    bondAmountAsText: string;
    amountAsTextNumbersOnly: string;
    amount: number;
    id: number;
    createdDateTime: Date;
    attachments: Attachment[];
    obligeeId: number;
    obligee: Obligee;
    renewalStatus: RenewalStatus;
    reviewRequired: boolean;
    reviewComments: string;
    allowPrincipalAssignment: boolean;
}