import app from "../../main";

class FileIconController {
    
    public static $inject = [];

    public filename: string;

    public get fileExtension(): string {
        if (!this.filename) {
            return null;
        }

        let ext = this.filename.split('.').pop().toLowerCase();

        if (ext === 'doc') {
            return 'docx';
        } 
        
        if (ext === 'xls') {
            return 'xlsx';
        }
        
        if (ext === 'png' || 
            ext === 'jpg' || 
            ext === 'jpeg' || 
            ext === 'gif' || 
            ext === 'bmp') {
            return 'image';
        }

        if (ext) {
            return ext;
        }

        return '';
    }

    constructor() { }

    public $onInit(): void {}
}

const fileIconComponent = {
    templateUrl: 'app/components/fileIcon/fileIcon.html',
    bindings: {
        filename: '<'
    },
    controller: FileIconController,
    controllerAs: 'vm'
};

app.component('fileIcon', fileIconComponent);
