export enum WidgetType {
    dashboardWidgetActivityTracker = "dashboard-widget-activity-tracker",
    dashboardWidgetActivityTrackerCarrier = "dashboard-widget-activity-tracker-carrier",
    dashboardWidgetBondFormCount = "dashboard-widget-bond-form-count",
    dashboardWidgetBondsAgent = "dashboard-widget-bonds-agent",
    dashboardWidgetBondsByAgentBreakdownCarrier = "dashboard-widget-bonds-by-agent-breakdown-carrier",
    dashboardWidgetBondsByCarrierBreakdownAgent = "dashboard-widget-bonds-by-carrier-breakdown-agent",
    dashboardWidgetBondsCarrier = "dashboard-widget-bonds-carrier",
    dashboardWidgetBondsToRenewAgent = "dashboard-widget-bonds-to-renew-agent",
    dashboardWidgetBondTransactionsAgent = "dashboard-widget-bond-transactions-agent",
    dashboardWidgetBondTransactionsCarrier = "dashboard-widget-bond-transactions-carrier",
    dashboardWidgetBondTypeChangesCount = "dashboard-widget-bond-type-changes-count",
    dashboardWidgetBondTypeConfigurationCount = "dashboard-widget-bond-type-configuration-count",
    dashboardWidgetBondTypeConfigurationList = "dashboard-widget-bond-type-configuration-list",
    dashboardWidgetBondTypeConfigurationsByState = "dashboard-widget-bond-type-configurations-by-state",
    dashboardWidgetBondTypeLeaderboard = "dashboard-widget-bond-type-leaderboard",
    dashboardWidgetBondTypeProgress = "dashboard-widget-bond-type-progress",
    dashboardWidgetCancellationRequests = "dashboard-widget-cancellation-requests",

    dashboardWidgetLeads = "dashboard-widget-leads",
    dashboardWidgetLeadTotals = "dashboard-widget-lead-totals",
    dashboardWidgetLeadTotalsCarrier = "dashboard-widget-lead-totals-carrier",
    dashboardWidgetNewlyActiveBondTypes = "dashboard-widget-newly-active-bond-types",
    dashboardWidgetOpenApplicationsCountCarrier = "dashboard-widget-open-applications-count-carrier",
    dashboardWidgetOpenApplicationsCountAgent = "dashboard-widget-open-applications-count-agent",
    dashboardWidgetOpenApplicationsList = "dashboard-widget-open-applications-list",
    dashboardWidgetOpenApplicationsListCarrier = "dashboard-widget-open-applications-list-carrier",
    dashboardWidgetPayments = "dashboard-widget-payments",
    dashboardWidgetPendingIndemnitySignatures = "dashboard-widget-pending-indemnity-signatures",
    dashboardWidgetReinstatementRequests = "dashboard-widget-reinstatement-requests",
    dashboardWidgetRiderRequests = "dashboard-widget-rider-requests",
    dashboardWidgetStateActivity = "dashboard-widget-state-activity",
    dashboardWidgetStateActivityCarrier = "dashboard-widget-state-activity-carrier",
    dashboardWidgetTransactionCount = "dashboard-widget-transaction-count",
    dashboardWidgetTransactionCountCarrier = "dashboard-widget-transaction-count-carrier",
    dashboardWidgetTransactionSum = "dashboard-widget-transaction-sum",
    dashboardWidgetTransactionSumCarrier = "dashboard-widget-transaction-sum-carrier",
    dashboardWidgetUnderwritingConfigurationsCarrier = "dashboard-widget-underwriting-configurations-carrier",
    importBondsWidget = "import-bonds-widget",
    activityListWidget = "dashboard-widget-activity-list"
}

export const standardWidgets = [
    WidgetType.activityListWidget,
    WidgetType.importBondsWidget,
    WidgetType.dashboardWidgetBondsAgent,
    WidgetType.dashboardWidgetBondsCarrier,
    WidgetType.dashboardWidgetBondsToRenewAgent,
    WidgetType.dashboardWidgetBondTransactionsAgent,
    WidgetType.dashboardWidgetBondTransactionsCarrier,
    WidgetType.dashboardWidgetBondTypeConfigurationList,
    WidgetType.dashboardWidgetBondTypeLeaderboard,
    WidgetType.dashboardWidgetBondTypeProgress,
    WidgetType.dashboardWidgetCancellationRequests,
    WidgetType.dashboardWidgetLeads,
    WidgetType.dashboardWidgetNewlyActiveBondTypes,
    WidgetType.dashboardWidgetOpenApplicationsList,
    WidgetType.dashboardWidgetOpenApplicationsListCarrier,
    WidgetType.dashboardWidgetPayments,
    WidgetType.dashboardWidgetPendingIndemnitySignatures,
    WidgetType.dashboardWidgetReinstatementRequests,
    WidgetType.dashboardWidgetRiderRequests,
    WidgetType.dashboardWidgetUnderwritingConfigurationsCarrier
];

export const valueWidgets = [
    WidgetType.dashboardWidgetTransactionSum,
    WidgetType.dashboardWidgetBondFormCount,
    WidgetType.dashboardWidgetLeadTotals,
    WidgetType.dashboardWidgetBondTypeChangesCount,
    WidgetType.dashboardWidgetBondTypeConfigurationCount,
    WidgetType.dashboardWidgetLeadTotalsCarrier,
    WidgetType.dashboardWidgetOpenApplicationsCountCarrier,
    WidgetType.dashboardWidgetOpenApplicationsCountAgent,
    WidgetType.dashboardWidgetTransactionCount,
    WidgetType.dashboardWidgetTransactionCountCarrier,
    WidgetType.dashboardWidgetTransactionSumCarrier
];

export const chartWidget = [
    WidgetType.dashboardWidgetActivityTracker,
    WidgetType.dashboardWidgetActivityTrackerCarrier,
    WidgetType.dashboardWidgetBondsByAgentBreakdownCarrier,
    WidgetType.dashboardWidgetBondsByCarrierBreakdownAgent,
    WidgetType.dashboardWidgetBondTypeConfigurationsByState,
    WidgetType.dashboardWidgetStateActivity,
    WidgetType.dashboardWidgetStateActivityCarrier
];
