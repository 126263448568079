import { Fee } from "../../api/types/model/fee";

export interface RenewalSubmissionCalculatorResult {
    bondId: number;
    premium: number;
    fees: Fee[];
    totalFees: number;
    commission: number;
    brokerCommission: number;
    totalAmountDue: number;
}