export interface AttachmentModalData {
    id?: number;
    visibleToSystemAccountId?: number;
    visibleToAgent: boolean;
    visibleInEProducer: boolean;
    applicationId?: number;
    customerId: number;
    eProducerAccountId?: number;
    bondId?: number;
    fileId: number;
    comments: string;
    filename: string;
    fileSize: number;
}