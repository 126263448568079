import { Bond } from "../bond";
import { BondChange } from "./bondChange";
import { IBaseEntity } from "./iBaseEntity";
import { User } from "./user";

export interface BondCorrection extends IBaseEntity { 
    id: number;
    bondId: number;
    changes: BondChange[];
    effectiveDate: Date;
    attorneyInFactId: number;
    comments: string;
    userId: number;    
    wasEnteredByAgent: boolean;
    createdDateTime: Date;
}