import { IBaseEntity } from "./iBaseEntity";

export interface ZipLookup extends IBaseEntity { 
    zip: number;
    longitude: number;
    latitude: number;
    city: string;
    state: string;
    zipType: string;
    id: number;
    createdDateTime: Date;
}
