import { IJQueryService } from "../../../utilities/jquery/iJQueryService";

export default (
    element: JQuery, 
    labelText: string, 
    isRequired: boolean, 
    jQueryService: IJQueryService
) => {    
    const dropdownCaret = element.prop('tagName') === 'SELECT' ? '<i class="fa fa-caret-down"></i>' : '';

    // if ng-required is used then add ng-class to toggle the * accordingly in the label
    const ngRequiredHandler = element.attr('ng-required') ? `ng-show="${element.attr('ng-required')}"` : '';

    const requiredIndicator = (isRequired || ngRequiredHandler) ? ` <span class="validation-indicator" ${ngRequiredHandler}>*</span>` : '';
    const label = jQueryService.getElement(`
        <div class="label">
            ${labelText} 
            ${dropdownCaret} 
            ${requiredIndicator}
        </div>
    `);

    element
        .parent()
        .parent()
        .append(label);

    return label;
};