import { DocumentLibraryService } from "../../api/documentLibraryService";
import { DownloadBatchType } from "../../api/types/downloadBatchType";
import { SelectOption } from "../../api/types/selectOption";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ModalExportFormDownloadOptions } from "./exportFormsModalOptions";
import app from "../../main";
import * as saveAs from "file-saver";
import * as moment from "moment";

export class ExportFormsModalController {

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.DocumentLibraryService
    ];

    constructor (
        private readonly $uibModalInstance: Modal<void>,
        private readonly documentLibraryService: DocumentLibraryService
    ) {} 

    public typeOptions: SelectOption<DownloadBatchType>[];
    // public downloadOptions: DownloadDocumentsOptions;
    public downloadOptions: ModalExportFormDownloadOptions;
    public busyIndicator: BusyIndicator;

    public cancel = () => {
        this.$uibModalInstance.dismiss('cancel');
    };

    public submit = () => {
        this.busyIndicator.promise = this.documentLibraryService.downloadBatch(this.downloadOptions)
            .then((file) => {
                let blob = new Blob([file], { type: 'application/x-zip-compressed' });

                //uses FileSave.js
                //http://purl.eligrey.com/github/FileSaver.js/blob/master/FileSaver.js
                saveAs(blob, 'A3_Forms.zip');

                this.$uibModalInstance.close();
            });
    }

    public $onInit = () => {
        this.busyIndicator = {
            message: 'Preparing to download...'
        };

        this.typeOptions = [
            { label: 'Newly Added', value: DownloadBatchType.Added }, 
            { label: 'Updated', value: DownloadBatchType.Updated },
            { label: 'Both New and Updated', value: DownloadBatchType.Both }
        ];

        this.downloadOptions = {
            includeManifest: true,
            includeBondForms: true,
            type: DownloadBatchType.Both,
            endDate: moment().format('MM/DD/YYYY')
        } as ModalExportFormDownloadOptions;
    }
}
        
app.controller('ExportFormsModalController', ExportFormsModalController);
