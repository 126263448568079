import { IHttpService, IPromise } from "angular";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/settings/systemSettings";
import app from "../main";
import { UtilityService } from "../utilities/utilityService";
import { ODataFactory, ODataEndpoint, ODataTimeFrameFilterType } from "./odata";
import A3ApiResponse from "./types/a3ApiResponse";
import { GetCarrierTransactionReportDto } from "./types/getCarrierTransactionReportDto";
import { GetRiderTransactionReportDto } from "./types/getRiderTransactionReportDto";
import {
    BondTransaction,
    TransactionType
} from "./types/model/bondTransaction";
//import { BondTransaction } from "../states/common/bondTransactions/BondTransaction";
import { BondType } from "./types/model/bondType";
import { EpicNewBusinessExportRequest } from "./types/model/epicNewBusinessExportRequest";
import { TransactionTypeDescription } from "./types/transactionTypeDescription";
import { UpdateBondTransactionDocumentVersionDto } from "./types/updateBondTransactionDocumentVersionDto";
import BondTransactionListItemDetails from "../components/bondTransactionList/bondTransactionListItemDetails";
import BondTransactionToUpdate from "../components/bondTransactionList/bondTransactionToUpdate";
import { BondTransactionHistoryTableQueryOptions } from "../modals/bondTransactionHistoryModal/bondTransactionHistoryTableQueryOptions";
import { PageResponse } from "./types/pageResponse";
import { BondTransactionHistoryListItem } from "../modals/bondTransactionHistoryModal/bondTransactionHistoryListItem";
import { TransactionReportTableQueryOptions } from "../states/common/bondTransactions/transactionReportTableQueryOptions";
import { FileDownloader } from "./fileDownloader";
import BrokerCommissionCalulationParameters from "./types/brokerCommissionCalulationParameters";
import { BondTransactionWithDocumentList } from "./types/bondTransactionWithDocumentList";

export class BondTransactionService {
    public static $inject = [
        Injectables.$http,
        Injectables.ODataFactory,
        Injectables.UtilityService,
        Injectables.SystemSettings,
        Injectables.FileDownloader
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly odata: ODataFactory,
        private readonly utilityService: UtilityService,
        private readonly systemSettings: SystemSettings,
        private readonly fileDownloader: FileDownloader
    ) {}

    public getEpicNewBusinessExportRequest(bondTransactionId: number): IPromise<EpicNewBusinessExportRequest> {
        const url = `${this.systemSettings.apiBaseUrl}BondTransactionActions/GetEpicNewBusinessExportRequest?bondTransactionId=${bondTransactionId}`;

        return this.$http
            .get<A3ApiResponse<EpicNewBusinessExportRequest>>(url)
            .then((response) => response.data.value);
    }

    public calculateBrokerCommission(parameters: BrokerCommissionCalulationParameters) {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/CalculateBrokerCommission?eProducerAccountId=${parameters.eProducerAccountId}&premium=${parameters.premium}&commission=${parameters.commission}`;
        
        return this.$http
            .get<A3ApiResponse<number>>(url)
            .then((response) => response.data.value);
    }

    public calculateNewBillingEntryCommission(bondTransactionId: number, premium: number)
    {
        const url = `${this.systemSettings.apiBaseUrl}BillingEntryActions/GetNewBillingEntryDefaultCommission?bondTransactionId=${bondTransactionId}&premium=${premium}`;

        return this.$http
            .get<A3ApiResponse<number>>(url)
            .then((response) => response.data.value);
    }

    public getBondTransactionHistoryListItems(tableQueryOptions: BondTransactionHistoryTableQueryOptions) {
        if (!tableQueryOptions.pageNumber) {
            tableQueryOptions.pageNumber = 1;
    }

    if (!tableQueryOptions.recordsPerPage) {
        tableQueryOptions.recordsPerPage = 10;
    }

    if (!tableQueryOptions.searchPhrase) {
        tableQueryOptions.searchPhrase = '';
    } 

    if (!tableQueryOptions.orderBy) {
        tableQueryOptions.orderBy = 'CreatedDateTime';
    }

    let queryString = `bondTransactionId=${tableQueryOptions.bondTransactionId}`;
    queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
    queryString += `&pageNumber=${tableQueryOptions.pageNumber}`;       
    queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;
    queryString += `&orderBy=${tableQueryOptions.orderBy}`;

    return this.$http
        .get<A3ApiResponse<PageResponse<BondTransactionHistoryListItem>>>(`${this.systemSettings.apiBaseUrl}BondTransactionActions/GetBondTransactionHistoryListItems?${queryString}`)
        .then((response) => response.data.value);         
    }

    public saveAndExportEpicNewBusinessExportRequest(
        exportRequest: EpicNewBusinessExportRequest
    ): IPromise<void> {
        const url =
            this.systemSettings.apiBaseUrl +
            "EpicActions/UpdateAndExportNewBusinessToEpic";

        return this.$http.post<void>(url, exportRequest).then(() => {});
    }

    public saveBondTransaction(bondTransactionDetail: BondTransactionToUpdate) {
        return this.$http.post(`${this.systemSettings.apiBaseUrl}BondTransactionActions/SaveBondTransaction`, bondTransactionDetail)
            .then(() => {});
    }

    public getBondTransactionsReportItems(tableQueryOptions: TransactionReportTableQueryOptions) : IPromise<PageResponse<BondTransaction>> {
        const queryString = this.buildBondTransactionQueryString(tableQueryOptions);
        const url = `${this.systemSettings.apiBaseUrl}BondTransactionActions/GetBondTransactionsReportItems${queryString}`;

        return this.$http
            .get<A3ApiResponse<PageResponse<BondTransaction>>>(url)
            .then((response)=> response.data.value) ;
    }

    public getBondTransactionExcelReport(tableQueryOptions: TransactionReportTableQueryOptions) : IPromise<PageResponse<BondTransaction>> {
        const queryString = this.buildBondTransactionQueryString(tableQueryOptions);
        const url = `${this.systemSettings.apiBaseUrl}BondTransactionActions/GetBondTransactionExcelReport${queryString}`;
        return this.fileDownloader.downloadFile(url,null);
    }

    private buildBondTransactionQueryString(tableQueryOptions: TransactionReportTableQueryOptions) {
        if (!tableQueryOptions) {
            tableQueryOptions = {
                pageNumber: 1,
                recordsPerPage: 10,
            } as TransactionReportTableQueryOptions;
        }

        if (!tableQueryOptions.searchPhrase) {
            tableQueryOptions.searchPhrase = "";
        }

        if (!tableQueryOptions.orderBy) {
            tableQueryOptions.orderBy = "BondTransactions.CreatedDateTime DESC";
        }

        if (!tableQueryOptions.pageNumber) {
            tableQueryOptions.pageNumber = 1;
        }

        if (!tableQueryOptions.recordsPerPage) {
            tableQueryOptions.recordsPerPage = 10;
        }

        let queryString = "?";

        queryString += `&pageNumber=${tableQueryOptions.pageNumber}`;
        queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
        queryString += `&orderBy=${tableQueryOptions.orderBy}`;
        queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;

        if ( tableQueryOptions.carrierSystemAccountId) {
            queryString += `&carrierSystemAccountId=${tableQueryOptions.carrierSystemAccountId}`;
        }
        
        if ( tableQueryOptions.agencySystemAccountId) {
            queryString += `&agencySystemAccountId=${tableQueryOptions.agencySystemAccountId}`;
        }

        if (tableQueryOptions.minimumEffectiveDate) {
            queryString += `&minimumEffectiveDate=${tableQueryOptions.minimumEffectiveDate}`;
        }

        if (tableQueryOptions.maximumEffectiveDate) {
            queryString += `&maximumEffectiveDate=${tableQueryOptions.maximumEffectiveDate}`;
        }
        
        if (tableQueryOptions.minimumCreatedDateTime) {
            queryString += `&minimumCreatedDateTime=${tableQueryOptions.minimumCreatedDateTime}`;
        }

        if (tableQueryOptions.maximumCreatedDateTime) {
            queryString += `&maximumCreatedDateTime=${tableQueryOptions.maximumCreatedDateTime}`;
        }

        if (tableQueryOptions.minimumBondAmount) {
            queryString += `&minimumBondAmount=${tableQueryOptions.minimumBondAmount}`;
        }

        if (tableQueryOptions.maximumBondAmount) {
            queryString += `&maximumBondAmount=${tableQueryOptions.maximumBondAmount}`;
        }

        if (tableQueryOptions.minimumCommissionAmount) {
            queryString += `&minimumCommissionAmount=${tableQueryOptions.minimumCommissionAmount}`;
        }

        if (tableQueryOptions.maximumCommissionAmount) {
            queryString += `&maximumCommissionAmount=${tableQueryOptions.maximumCommissionAmount}`;
        }

        if (tableQueryOptions.minimumRefundAmount) {
            queryString += `&minimumRefundAmount=${tableQueryOptions.minimumRefundAmount}`;
        }

        if (tableQueryOptions.maximumRefundAmount) {
            queryString += `&maximumRefundAmount=${tableQueryOptions.maximumRefundAmount}`;
        }

        if (tableQueryOptions.minimumPremiumAmount) {
            queryString += `&minimumPremiumAmount=${tableQueryOptions.minimumPremiumAmount}`;
        }

        if (tableQueryOptions.maximumPremiumAmount) {
            queryString += `&maximumPremiumAmount=${tableQueryOptions.maximumPremiumAmount}`;
        }

        if (tableQueryOptions.transactionType) {
            queryString += `&transactionType=${tableQueryOptions.transactionType}`;
        }

        if (tableQueryOptions.requestedState) {
            queryString += `&requestedState=${tableQueryOptions.requestedState}`;
        }

        if (tableQueryOptions.requestedBondTypeName) {
            queryString += `&requestedBondTypeName=${tableQueryOptions.requestedBondTypeName}`;
        }

        if (tableQueryOptions.tag) {
            queryString += `&tag=${tableQueryOptions.tag}`;
        }

        if (tableQueryOptions.sfaaCodeId) {
            queryString += `&sfaaCodeId=${tableQueryOptions.sfaaCodeId}`;
        }

        if (tableQueryOptions.agencyCode) {
            queryString += `&agencyCode=${tableQueryOptions.agencyCode}`;
        }

        if (tableQueryOptions.agencySubCode) {
            queryString += `&agencySubCode=${tableQueryOptions.agencySubCode}`;
        }

        if (tableQueryOptions.requestedBrokerContains) {
            queryString += `&requestedBrokerContains=${tableQueryOptions.requestedBrokerContains}`;
        }

        if (tableQueryOptions.bondStatus) {
            queryString += `&bondStatus=${tableQueryOptions.bondStatus}`;
        }

        return queryString;
    }

    public getBondTransactionDetails(bondTransactionId: number): IPromise<BondTransactionListItemDetails> {
        return this.$http.get<A3ApiResponse<BondTransactionListItemDetails>>(`${this.systemSettings.apiBaseUrl}BondTransactionActions/GetBondTransactionListDetails?bondTransactionId=${bondTransactionId}`)
            .then((response) => response.data.value);
    }

    public downloadDocuments(
        transactionId: number,
        documents: number[],
        includePoaSignature: boolean,
        includeNotaryAcknowledgement: boolean,
        attorneyInFactUserId: number,
        sequenceIds: number[]
    ): IPromise<void> {
        let url = this.systemSettings.apiBaseUrl;
        url += "DataDocument/DownloadTransactionDocuments?";
        url += `attorneyInFactUserId=${attorneyInFactUserId}`;
        url += `&transactionId=${transactionId}`;

        for (const document of documents) {
            url += `&documents=${document}`;
        }

        for (const sequenceId of sequenceIds) {
            url += `&sequence=${sequenceId}`;
        }

        url += `&includePoaSignature=${includePoaSignature}`;
        url += `&includeNotaryAcknowledgement=${includeNotaryAcknowledgement}`;

        return this.$http
            .get(url, { responseType: "arraybuffer" })
            .then((response) => {
                this.utilityService.processDocumentDownloadResponse(response);
            });
    }

    public updateBondTransactionDocumentVersion(
        btdvId: number
    ): IPromise<void> {
        const dto = {
            bondTransactionDocumentVersionId: btdvId
        } as UpdateBondTransactionDocumentVersionDto;

        return this.$http
            .post(
                this.systemSettings.apiBaseUrl +
                    "BondTransactionActions/UpdateBondTransactionDocumentVersion",
                dto
            )
            .then(() => {});
    }

    public getBondTransactionReportForCarrierExport(
        filter?: string
    ): IPromise<GetCarrierTransactionReportDto[]> {
        if (filter) {
            filter = `$filter=${filter}&`;
        }

        return this.$http
            .get<A3ApiResponse<GetCarrierTransactionReportDto[]>>(
                this.systemSettings.apiBaseUrl +
                    "BondTransactionActions/GetCarrierTransactionReport?" +
                    filter +
                    "$orderby=createdDateTime"
            )
            .then((response) => response.data.value);
    }

    public getCount(
        aggregateType: ODataTimeFrameFilterType,
        transactionType?: TransactionType
    ): IPromise<number> {
        transactionType =
            this.getTransactionTypeFromDescription(transactionType);

        const svc = this.odata.getService<BondTransaction>(
            ODataEndpoint.BondTransaction
        );
        svc.query.filterByTimeFrame(aggregateType, "createdDateTime");

        if (transactionType) {
            svc.query.and(
                `transactionType eq '${transactionType}'`
            );
        }

        return svc.count().then((response) => response.data);
    }

    public getRiderReportForExport(
        filter?: string
    ): IPromise<GetRiderTransactionReportDto[]> {
        if (filter) {
            filter = `$filter=${filter}&`;
        }

        return this.$http
            .get<A3ApiResponse<GetRiderTransactionReportDto[]>>(
                this.systemSettings.apiBaseUrl +
                    "BondTransactionActions/GetRiderTransactionReport?" +
                    filter +
                    "$orderby=createdDateTime"
            )
            .then((response) => response.data.value);
    }

    public getSum(
        property: string,
        aggregateType: ODataTimeFrameFilterType,
        transactionType?: TransactionType
    ): IPromise<number> {
        transactionType =
            this.getTransactionTypeFromDescription(transactionType);

        const svc = this.odata.getService<BondTransaction>(
            ODataEndpoint.BondTransaction
        );

        svc.query.select(property);
        svc.query.filterByTimeFrame(aggregateType, "createdDateTime");

        if (transactionType) {
            svc.query.and(
                `transactionType eq '${transactionType}'`
            );
        }

        return svc.get().then((response) => {
            let sum = 0;

            for (let i = 0; i < response.data.value.length; i++) {
                sum += response.data.value[i][property];
            }

            return sum;
        });
    }

    public getSystemCount(
        aggregateType: ODataTimeFrameFilterType,
        transactionType?: TransactionType
    ): IPromise<number> {
        transactionType =
            this.getTransactionTypeFromDescription(transactionType);

        const svc = this.odata.getService<BondTransaction>(
            ODataEndpoint.BondTransaction
        );

        svc.query.filterByTimeFrame(aggregateType, "createdDateTime");

        if (transactionType) {
            svc.query.and(
                `transactionType eq '${transactionType}'`
            );
        }

        const url =
            this.systemSettings.apiBaseUrl +
            "Analytics/GetSystemTransactionCountTotal?" +
            svc.query.toString().replace(/\$/g, "");

        return this.$http
            .get<A3ApiResponse<number>>(url)
            .then((response) => response.data.value);
    }

    public isTransactionType = (description: string) => {
        return (
            description === TransactionType.NewBusiness ||
            description === TransactionType.Renewal ||
            description === TransactionType.Rider ||
            description === TransactionType.Correction ||
            description === TransactionType.Reinstatement ||
            description === TransactionType.Cancellation
        );
    };

    public getTransactionTypeFromDescription(
        description: string
    ): TransactionType {
        if (this.isTransactionType(description)) {
            return description as TransactionType;
        }

        switch (description) {
            case TransactionTypeDescription.NewBusiness:
                return TransactionType.NewBusiness;
            case TransactionTypeDescription.Rider:
                return TransactionType.Rider;
            case TransactionTypeDescription.Cancellation:
                return TransactionType.Cancellation;
            case TransactionTypeDescription.Reinstatement:
                return TransactionType.Reinstatement;
            case TransactionTypeDescription.Renewal:
                return TransactionType.Renewal;
            default:
                return null;
        }
    }

    public getTransactionWithDocumentList(transactionId: number): IPromise<BondTransactionWithDocumentList> {
        const url = `${this.systemSettings.apiBaseUrl}BondTransactionActions/GetTransactionWithDocumentList?bondTransactionId=${transactionId}`;

        return this.$http.get<A3ApiResponse<BondTransactionWithDocumentList>>(url)
            .then((response) => response.data.value);
    }

    public last180ToLast90DaysCount(
        transactionType: TransactionType
    ): IPromise<number> {
        return this.getCount(
            ODataTimeFrameFilterType.Last180ToLast90Days,
            transactionType
        );
    }

    public last180ToLast90DaysSum(
        property: string,
        transactionType: TransactionType
    ): IPromise<number> {
        return this.getSum(
            property,
            ODataTimeFrameFilterType.Last180ToLast90Days,
            transactionType
        );
    }

    public last180ToLast90DaysSystemCount(
        transactionType: TransactionType
    ): IPromise<number> {
        return this.getSystemCount(
            ODataTimeFrameFilterType.Last180ToLast90Days,
            transactionType
        );
    }

    public last90DaysCount(transactionType: TransactionType): IPromise<number> {
        return this.getCount(
            ODataTimeFrameFilterType.Last90Days,
            transactionType
        );
    }

    public last90DaysSum(
        property: string,
        transactionType: TransactionType
    ): IPromise<number> {
        return this.getSum(
            property,
            ODataTimeFrameFilterType.Last90Days,
            transactionType
        );
    }

    public last90DaysSystemCount(
        transactionType: TransactionType
    ): IPromise<number> {
        return this.getSystemCount(
            ODataTimeFrameFilterType.Last90Days,
            transactionType
        );
    }

    public lastMonthCount(transactionType: TransactionType): IPromise<number> {
        return this.getCount(
            ODataTimeFrameFilterType.LastMonth,
            transactionType
        );
    }

    public lastMonthSum(
        property: string,
        transactionType: TransactionType
    ): IPromise<number> {
        return this.getSum(
            property,
            ODataTimeFrameFilterType.LastMonth,
            transactionType
        );
    }

    public lastMonthSystemCount(
        transactionType: TransactionType
    ): IPromise<number> {
        return this.getSystemCount(
            ODataTimeFrameFilterType.LastMonth,
            transactionType
        );
    }

    public lastWeekCount(transactionType: TransactionType): IPromise<number> {
        return this.getCount(
            ODataTimeFrameFilterType.LastWeek,
            transactionType
        );
    }

    public lastWeekSum(
        property: string,
        transactionType: TransactionType
    ): IPromise<number> {
        return this.getSum(
            property,
            ODataTimeFrameFilterType.LastWeek,
            transactionType
        );
    }

    public lastWeekSystemCount(
        transactionType: TransactionType
    ): IPromise<number> {
        return this.getSystemCount(
            ODataTimeFrameFilterType.LastWeek,
            transactionType
        );
    }

    public lastYearCount(transactionType: TransactionType): IPromise<number> {
        return this.getCount(
            ODataTimeFrameFilterType.LastYear,
            transactionType
        );
    }

    public lastYearSum(
        property: string,
        transactionType: TransactionType
    ): IPromise<number> {
        return this.getSum(
            property,
            ODataTimeFrameFilterType.LastYear,
            transactionType
        );
    }

    public lastYearSystemCount(
        transactionType: TransactionType
    ): IPromise<number> {
        return this.getSystemCount(
            ODataTimeFrameFilterType.LastYear,
            transactionType
        );
    }

    public mtdCount(transactionType: TransactionType): IPromise<number> {
        return this.getCount(ODataTimeFrameFilterType.Mtd, transactionType);
    }

    public mtdSum(
        property: string,
        transactionType: TransactionType
    ): IPromise<number> {
        return this.getSum(
            property,
            ODataTimeFrameFilterType.Mtd,
            transactionType
        );
    }

    public mtdSystemCount(transactionType: TransactionType): IPromise<number> {
        return this.getSystemCount(
            ODataTimeFrameFilterType.Mtd,
            transactionType
        );
    }

    public thisWeekCount(transactionType: TransactionType): IPromise<number> {
        return this.getCount(
            ODataTimeFrameFilterType.ThisWeek,
            transactionType
        );
    }

    public thisWeekSum(
        property: string,
        transactionType: TransactionType
    ): IPromise<number> {
        return this.getSum(
            property,
            ODataTimeFrameFilterType.ThisWeek,
            transactionType
        );
    }

    public thisWeekSystemCount(
        transactionType: TransactionType
    ): IPromise<number> {
        return this.getSystemCount(
            ODataTimeFrameFilterType.ThisWeek,
            transactionType
        );
    }

    public twoMonthsAgoCount(
        transactionType: TransactionType
    ): IPromise<number> {
        return this.getCount(
            ODataTimeFrameFilterType.TwoMonthsAgo,
            transactionType
        );
    }

    public twoMonthsAgoSum(
        property: string,
        transactionType: TransactionType
    ): IPromise<number> {
        return this.getSum(
            property,
            ODataTimeFrameFilterType.TwoMonthsAgo,
            transactionType
        );
    }

    public twoMonthsAgoSystemCount(
        transactionType: TransactionType
    ): IPromise<number> {
        return this.getSystemCount(
            ODataTimeFrameFilterType.TwoMonthsAgo,
            transactionType
        );
    }

    public ytdCount(transactionType: TransactionType): IPromise<number> {
        return this.getCount(ODataTimeFrameFilterType.Ytd, transactionType);
    }

    public ytdSum(
        property: string,
        transactionType: TransactionType
    ): IPromise<number> {
        return this.getSum(
            property,
            ODataTimeFrameFilterType.Ytd,
            transactionType
        );
    }

    public ytdSystemCount(transactionType: TransactionType): IPromise<number> {
        return this.getSystemCount(
            ODataTimeFrameFilterType.Ytd,
            transactionType
        );
    }
}

app.service(Injectables.BondTransactionService, BondTransactionService);