import { Injectables } from "../configuration/injectables";
import { GrantCustomerEProducerAccessRequest } from "../modals/grantCustomerEproducerAccessModal/grantCustomerEProducerAccessRequest";
import { ODataFactory, ODataEndpoint } from "./odata";
import { Application } from "./types/model/application";
import { ApproveEProducerAccountDto } from "./types/approveEProducerAccountDto";
import { AssignCustomerToAccountDto } from "./types/assignCustomerToAccountDto";
import { Attachment } from "./types/attachment";
import { DeleteAccountDto } from "./types/deleteAccountDto";
import { EProducerAccountListItem } from "../states/agentSpecific/eProducerBrokers/eProducerAccountListItem";
import { EProducerAccountSearchResult } from "./types/eProducerAccountSearchResult";
import { EProducerBrandingSettings } from "./types/eProducerBrandingSettings" ;
import {
    EProducerAccountType,
    EProducerAccount,
    eProducerSummary
} from "./types/model/eProducerAccount";
import { EProducerUser } from "./types/model/eProducerUser";
import { EProducerAccountFavoriteBondTypeConfiguration } from "./types/model/favoriteBondTypeConfiguration";
import { Invoice } from "./types/model/invoice";
import { PaymentTransaction } from "./types/model/paymentTransaction";
import { PaymentStatus } from "./types/payments/paymentStatus";
import { RequestPasswordResetDto } from "./types/requestPasswordResetDto";
import { CacheFactory } from "./types/cacheFactory";
import A3ApiResponse from "./types/a3ApiResponse";
import app from "../main";
import { IHttpService, IQService, IPromise } from "angular";
import { SystemSettings } from "../configuration/settings/systemSettings";
import { SelectOption } from "./types/selectOption";
import EProducerAccountBrokerCategory from "./types/eProducerAccountBrokerCategory";
import { Person } from "./types/model/person";
import {EProducerDropdownFilterOptions} from "../states/agentSpecific/eProducerBrokers/eProducerAccountsDropdownFilterOptions";
import { PageResponse } from "./types/pageResponse";
import { CacheStore } from "./cacheStore";

export class EProducerService {
    public static $inject = [
        Injectables.$http,
        Injectables.ODataFactory,
        Injectables.$q,
        Injectables.SystemSettings,
        Injectables.CacheStore
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly odata: ODataFactory,
        private readonly $q: IQService,
        private readonly systemSettings: SystemSettings,
        private readonly cacheStore: CacheStore
    ) {}


    public searchForDropdown(
        search: string,
        eproducerAccountType: EProducerAccountType
    ): IPromise<EProducerAccountSearchResult[]> {
        return this.$http
            .get<A3ApiResponse<EProducerAccountSearchResult[]>>(
                this.systemSettings.apiBaseUrl +
                    "EProducerActions/SearchForDropdown?searchPhrase=" +
                    search +
                    "&eProducerAccountType=" +
                    eproducerAccountType
            )
            .then((response) => response.data.value);
    }

    public GetPeopleContactsForEProducerAccountId(eProducerAccountId:number)
    : IPromise<Person[]> {

        return this.$http
            .get<A3ApiResponse<Person[]>>(
                this.systemSettings.apiBaseUrl + 
                    "EProducerActions/GetPeopleContactsForEProducerAccountId?eProducerAccountId=" +
                    eProducerAccountId
            )
            .then((response) => response.data.value);
    }

    public saveEproducerAccountContact(contact: Person): IPromise<number> {
        return this.$http
            .post<A3ApiResponse<number>>(
                this.systemSettings.apiBaseUrl + "EProducerActions/AddPeopleContactsForEProducerAccount",
                contact
            )
            .then((response) => response.data.value);
    }

    public getPaymentTransactionsByEProducerAccountId(
        eProducerAccountId: number,
        onlyApprovedPayments: boolean
    ): IPromise<PaymentTransaction[]> {
        let paymentStatus: PaymentStatus = null;

        if (onlyApprovedPayments) {
            paymentStatus = PaymentStatus.Approved;
        }

        return this.$http
            .get<A3ApiResponse<PaymentTransaction[]>>(
                this.systemSettings.apiBaseUrl +
                    "EProducerActions/GetPaymentTransactionsByEProducerAccountId?eProducerAccountId=" +
                    eProducerAccountId +
                    "&paymentStatus=" +
                    paymentStatus
            )
            .then((response) => response.data.value);
    }

    public approveAccount(accountId: number): IPromise<void> {
        return this.$http
            .post(
                this.systemSettings.apiBaseUrl +
                    "EProducerActions/ApproveEProducerAccount",
                { id: accountId } as ApproveEProducerAccountDto
            )
            .then(() => {});
    }

    public assignCustomerToAccount(
        customerId: number,
        accountId: number
    ): IPromise<void> {
        const request = {
            customerId: customerId,
            accountId: accountId
        } as AssignCustomerToAccountDto;

        return this.$http
            .post(
                this.systemSettings.apiBaseUrl +
                    "EProducerActions/AssignCustomerToEProducerAccount",
                request
            )
            .then(() => {});
    }

    public assignCustomerToIndividualAccount(
        request: GrantCustomerEProducerAccessRequest
    ): IPromise<void> {
        return this.$http
            .post(
                this.systemSettings.apiBaseUrl +
                    "EProducerActions/AssignCustomerToIndividualAccount",
                request
            )
            .then(() => {})
            .catch((response) => {
                throw new Error(response.data.Message);
            });
    }

    public deleteAccount(account: EProducerAccountListItem): IPromise<void> {

        const request = { accountId: account.id } as DeleteAccountDto;

        return this.$http
            .post(
                this.systemSettings.apiBaseUrl +
                    "EProducerActions/DeleteAccount",
                request
            )
            .then(() => {});
    }

    public deleteContact(id: number): IPromise<void> {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/DeleteContact?id=${id}`;

        return this.$http
            .delete(url)
            .then(() => { });
    }

    public saveFavoriteBondTypeConfiguration(
        favoriteConfiguration: EProducerAccountFavoriteBondTypeConfiguration
    ): IPromise<void> {
        if (!favoriteConfiguration) {
            throw new Error("favoriteConfiguration is not valid");
        }

        return this.$http
            .post(
                this.systemSettings.apiBaseUrl +
                    "EProducerActions/SaveFavoriteBondTypeConfiguration",
                favoriteConfiguration
            )
            .then(() => {});
    }

    public getFavoriteBondTypeConfiguration(
        eProducerAccountId: number
    ): IPromise<EProducerAccountFavoriteBondTypeConfiguration> {
        const svc =
            this.odata.getService<EProducerAccountFavoriteBondTypeConfiguration>(
                ODataEndpoint.EProducerAccount
            );

        svc.query.filter(`id eq ${eProducerAccountId}`);
        svc.query.expand(
            "eProducerAccountFavoriteBondTypes($expand=bondType($select=name,id))"
        );
        svc.query.select(
            "id,favoriteBondTypeFilteringEnabled,eProducerAccountFavoriteBondTypes"
        );

        return svc.get().then((response) => response.data.value[0]);
    }

    public saveBranding(
        brandingInfo: EProducerBrandingSettings
    ): IPromise<void> {
        if (!brandingInfo) {
            throw new Error("brandingInfo is not valid");
        }

        const svc = this.odata.getService<EProducerBrandingSettings>(
            ODataEndpoint.EProducerAccount
        );

        return svc.patch(brandingInfo.id, brandingInfo).then(() => {});
    }

    public getBranding(
        eProducerAccountId: number
    ): IPromise<EProducerBrandingSettings> {
        const svc = this.odata.getService<EProducerBrandingSettings>(
            ODataEndpoint.EProducerAccount
        );

        svc.query.filter(`id eq ${eProducerAccountId}`);
        svc.query.select(
            "id,brandingEnabled,brandingCompanyName,brandingPhone,brandingEmail,brandingMailAddress,brandingMailCity,brandingMailState,brandingMailSuiteAptNumber,brandingMailZip,brandingPhysicalAddress,brandingPhysicalCity,brandingPhysicalState,brandingPhysicalSuiteAptNumber,brandingPhysicalZip,brandingLargeLogoFileId,brandingSmallLogoFileId"
        );

        return svc.get().then((response) => response.data.value[0]);
    }

    // TODO: Remove method getAccountByCustomerId if not used.
    public getAccountByCustomerId(
        customerId: number
    ): IPromise<EProducerAccount> {
        const svc = this.odata.getService<EProducerAccount>(
            ODataEndpoint.EProducerAccount
        );
        svc.query.filter(`customerId eq ${customerId}`);

        return svc.get().then((response) => response.data.value[0]);
    }

    public createBrokerAccount(
        account: EProducerAccount
    ): IPromise<EProducerAccount> {
        if (!account) {
            throw new Error("account is not valid");
        }

        account.eProducerAccountType = EProducerAccountType.Broker;

        const svc = this.odata.getService<EProducerAccount>(
            ODataEndpoint.EProducerAccount
        );

        return svc
            .post<EProducerAccount>(account)
            .then((response) => {
                return response.data;
            });
    }

    public deleteUser(userId: number): IPromise<void> {
        const svc = this.odata.getService<EProducerUser>(
            ODataEndpoint.EProducerUser
        );

        return svc.delete(userId).then(() => {});
    }

    public getAccountUsers(accountId: number): IPromise<EProducerUser[]> {
        const svc = this.odata.getService<EProducerUser>(
            ODataEndpoint.EProducerUser
        );
        svc.query.filter(`eProducerAccountId eq ${accountId}`);

        return svc.get().then((response) => response.data.value);
    }

    public getAttachments(accountId: number): IPromise<Attachment[]> {
        const svc = this.odata.getService<Attachment>(ODataEndpoint.Attachment);

        svc.query.filter(`eProducerAccountId eq ${accountId}`);

        return svc.get().then((response) => response.data.value);
    }

    public getEProducerAccount(id: number): IPromise<EProducerAccount> {
        const svc = this.odata.getService<EProducerAccount>(
            ODataEndpoint.EProducerAccount
        );

        svc.query.filter(`id eq ${id}`);
        svc.query.expand("attachments($select=id)");

        return svc.get().then((response) => response.data.value[0]);
    }

    public saveEProducerSummary(summary: eProducerSummary): IPromise<void> {
        return this.$http
            .put(
                this.systemSettings.apiBaseUrl + "EProducerActions/SaveSummary",
                summary
            )
            .then(() => {});
    }

    public getBrokerCateogryTypeOptions(): IPromise<SelectOption<number>[]> {
        return this.$http
            .get<A3ApiResponse<EProducerAccountBrokerCategory[]>>(`${this.systemSettings.apiBaseUrl}EProducerActions/GetEProducerAccountBrokerCategories`)
            .then((response) => {
                return response.data.value.map((category) => {
                    return { 
                        label: category.name,
                        value: category.id
                    }
                });
            });
    }

    public getInvoices(
        accountId: number,
        searchPhrase: string,
        onlyOpenInvoices: boolean
    ): IPromise<Invoice[]> {
        const svc = this.odata.getService<EProducerAccount>(
            ODataEndpoint.EProducerAccount
        );

        const searchNumber = parseInt(searchPhrase, 10);
        let invoiceFilter = "";

        if (onlyOpenInvoices) {
            invoiceFilter = "status eq 'Open' and";
        }

        if (searchPhrase) {
            invoiceFilter += " (";

            if (!isNaN(searchNumber)) {
                invoiceFilter += `invoiceNumber eq ${searchPhrase} or `;
            }

            invoiceFilter += `contains(comments, '${searchPhrase}') or lineItems/any(l: contains(l/description, '${searchPhrase}'))`;

            invoiceFilter += ") and";
        }

        if (invoiceFilter) {
            invoiceFilter =
                "$filter=" + invoiceFilter.substr(0, invoiceFilter.length - 4);
        }

        svc.query.expand(
            `invoices($expand=lineItems;$orderBy=createdDateTime desc;${invoiceFilter})`
        );
        svc.query.filter(`id eq ${accountId}`);

        svc.query.select("invoices");

        return svc.get().then((response) => {
            return response.data.value[0].invoices;
        });
    }

    public getOpenApplications(accountId: number): IPromise<Application[]> {
        const svc = this.odata.getService<Application>(
            ODataEndpoint.Application
        );

        svc.query.filter(
            `eProducerAccountId eq ${accountId} and (status eq 'Submitted' or status eq 'NotSubmitted')`
        );
        svc.query.expand("quotes,bondType($select=name)");

        return svc.get().then((response) => response.data.value);
    }

    public getUser(userId: number): IPromise<EProducerUser> {
        const svc = this.odata.getService<EProducerUser>(
            ODataEndpoint.EProducerUser
        );
        svc.query.filter(`id eq ${userId}`);

        return svc.get().then((response) => response.data.value[0]);
    }

    public insertUser(user: EProducerUser): IPromise<EProducerUser> {
        const svc = this.odata.getService<EProducerUser>(
            ODataEndpoint.EProducerUser
        );

        return svc
            .post<A3ApiResponse<EProducerUser>>(user)
            .then((response) => response.data.value);
    }

    public lockAccount(accountId: number): IPromise<EProducerAccount> {
        const svc = this.odata.getService<EProducerAccount>(
            ODataEndpoint.EProducerAccount
        );

        return svc
            .patch<A3ApiResponse<EProducerAccount>>(accountId, {
                isLocked: true
            } as EProducerAccount)
            .then((response) => response.data.value);
    }

    public resetUserPassword(email: string): IPromise<void> {
        if (!email) {
            throw new Error("email is not valid");
        }

        const request = { email: email } as RequestPasswordResetDto;

        return this.$http
            .post(
                this.systemSettings.apiBaseUrl +
                    "EProducerActions/RequestPasswordReset",
                request
            )
            .then(() => {})
            .catch((response) => {
                throw new Error(response.data.Message);
            });
    }

    public sendBrokerInvite(emailAddress: string): IPromise<void> {
        if (!emailAddress) {
            throw new Error("emailAddress is not valid");
        }

        return this.$http
            .get(
                this.systemSettings.apiBaseUrl +
                    "EProducerActions/SendBrokerInvite?emailAddress=" +
                    emailAddress
            )
            .then(() => {});
    }

    public searchEProducerAccounts(
        search: string
    ): IPromise<EProducerAccount[]> {
        search = search || "";

        const svc = this.odata.getService<EProducerAccount>(
            ODataEndpoint.EProducerAccount
        );

        svc.query.filter(`(contains(name,'${search}'))`);
        svc.query.orderby("name");
        svc.query.top(25);

        return svc.get().then((response) => response.data.value);
    }

    private buildInvoiceReportQueryString(
        tableQueryOptions: EProducerDropdownFilterOptions
    ) {
        if (!tableQueryOptions) {
            tableQueryOptions = {
                pageNumber: 1,
                recordsPerPage: 10,
                orderBy: "",
                searchPhrase: ""
            } as EProducerDropdownFilterOptions;
        }

        if (!tableQueryOptions.searchPhrase) {
            tableQueryOptions.searchPhrase = "";
        }

        if (!tableQueryOptions.orderBy) {
            tableQueryOptions.orderBy = "EProducerAccounts.Id desc";
        }

        if (!tableQueryOptions.pageNumber) {
            tableQueryOptions.pageNumber = 1;
        }

        if (!tableQueryOptions.recordsPerPage) {
            tableQueryOptions.recordsPerPage = 10;
        }

        let queryString = "?";

        queryString += `&pageNumber=${tableQueryOptions.pageNumber}`;
        queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
        queryString += `&orderBy=${tableQueryOptions.orderBy}`;
        queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;        

        if (tableQueryOptions.brokerCategoryId) {
            queryString += `&brokerCategoryId=${tableQueryOptions.brokerCategoryId}`
        }
        
        return queryString;
    }

    public getEproducerBrokerAccountsList(tableQueryOptions: EProducerDropdownFilterOptions): IPromise<PageResponse<EProducerAccountListItem>> {

        const queryString = this.buildInvoiceReportQueryString(tableQueryOptions);
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/GetEproducerBrokerAccountsList?searchPhrase${queryString}`;

        return this.$http.get<A3ApiResponse<PageResponse<EProducerAccountListItem>>>(url)
            .then((response) => response.data.value);
    }

    public searchEProducerBrokers(
        search: string
    ): IPromise<EProducerAccount[]> {
        if (!search) {
            return this.$q.when([] as EProducerAccount[]);
        }

        return this.$http
            .get<A3ApiResponse<EProducerAccount[]>>(
                this.systemSettings.apiBaseUrl +
                    "EProducerActions/SearchEProducerBrokers?search=" +
                    search
            )
            .then((response) => response.data.value);
    }

    // TODO: Remove method searchEProducerPrincipals if not used.
    public searchEProducerPrincipals(
        search: string
    ): IPromise<EProducerUser[]> {
        search = search || "";

        const svc = this.odata.getService<EProducerUser>(
            ODataEndpoint.EProducerUser
        );

        svc.query.filter(`(contains(email,'${search}'))`);
        svc.query.and("eproducerAccount/eproducerAccountType eq 'Applicant'");
        svc.query.orderby("firstName,lastName");
        svc.query.top(25);

        return svc.get().then((response) => response.data.value);
    }

    public unLockAccount(accountId: number): IPromise<EProducerAccount> {
        const svc = this.odata.getService<EProducerAccount>(
            ODataEndpoint.EProducerAccount
        );

        return svc
            .patch<A3ApiResponse<EProducerAccount>>(accountId, {
                isLocked: false
            } as EProducerAccount)
            .then((response) => response.data.value);
    }

    public updateEProducerAccount(
        account: EProducerAccount
    ): IPromise<EProducerAccount> {
        if (!account) {
            throw new Error("account is not valid");
        }

        const svc = this.odata.getService<EProducerAccount>(
            ODataEndpoint.EProducerAccount
        );

        return svc
            .put<A3ApiResponse<EProducerAccount>>(account.id, account)
            .then((response) => response.data.value);
    }

    public updateUser(user: EProducerUser): IPromise<EProducerUser> {
        if (!user) {
            throw new Error("user is not valid");
        }

        const svc = this.odata.getService<EProducerUser>(
            ODataEndpoint.EProducerUser
        );

        return svc
            .put<A3ApiResponse<EProducerUser>>(user.id, user)
            .then((response) => response.data.value);
    }

    public getSmallLogo(id: number): IPromise<string> {
        return this.getLogo(id, EProducerAccountLogoSize.Small);
    }

    public getLargeLogo(id: number): IPromise<string> {
        return this.getLogo(id, EProducerAccountLogoSize.Large);
    }

    private getLogo(id: number, size: EProducerAccountLogoSize): IPromise<string> {
        const url = `${this.systemSettings.apiBaseUrl}Download/EProducerAccountLogo?size=${size}&eProducerAccountId=${id}`;

        return this.cacheStore.getData(url, () => {
            return this.$http
                .get(url, { cache: this.cacheStore })
                .then((img) => img.data as string);
        });
    }
}

enum EProducerAccountLogoSize {
    Small = "sm",
    Large = "lg"
}

app.service(Injectables.EProducerService, EProducerService);
