import { BondTypeService } from "../../../../../api/bondTypeService";
import { BusyIndicator } from "../../../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../../../configuration/injectables";
import { DashboardService } from "../../dashboardService";
import { DashboardWidget } from "../../types/dashboardWidget";
import app from "../../../../../main";
import { AmChartService } from "../../../../../utilities/amCharts/amChartService";
import DropdownOption from "../../../../../api/types/dropdownOption";
import { BondTypeFilterType } from "../../../../../api/types/bondTypeFilterType";

class DashboardWidgetBondTypeConfigurationsByStateController {

    public static $inject = [
        Injectables.BondTypeService,
        Injectables.DashboardService,
        Injectables.AmChartService
    ];

    constructor(
        private readonly bondTypeService: BondTypeService,
        private readonly dashboardService: DashboardService,
        private readonly amChartService: AmChartService
    ) {}

    public widget: DashboardWidget;
    public busyIndicator: BusyIndicator;
    public widgetIndex: number;
    public statusOptions: DropdownOption<BondTypeFilterType>[];

    public statusSelected = (option: DropdownOption) => {
        this.widget.options.filters.type = option;
        this.loadChart();
        this.dashboardService.save();
    }

    private loadChart = () => {
        this.busyIndicator.promise = this.bondTypeService.getCountsByState(this.widget.options.filters.type.value)
            .then((data: any) => {
                this.amChartService.makeUsaMapChart("mapDiv"+this.widgetIndex, data);
            });
    };

    public $onInit = () => {
        this.busyIndicator = {
            message: 'Loading...',
        };

        this.statusOptions = [{
                label: 'Active Bond Types',
                value: BondTypeFilterType.Active
            },{
                label: 'Inactive Bond Types',
                value: BondTypeFilterType.Inactive
            },{
                label: 'Requires Review',
                value: BondTypeFilterType.Review
            }
        ];
        
        this.dashboardService.refreshFunctions.push(this.loadChart);
        
        this.loadChart();
    }
}

const dashboardWidgetBondTypeConfigurationsByStateComponent = {
    bindings: {
        widget: '=',
        widgetIndex: '<'
    },
    templateUrl: 'app/states/common/dashboard/widgets/bondTypesByState/dashboardWidgetBondTypeConfigurationsByState.html',
    controller: DashboardWidgetBondTypeConfigurationsByStateController,
    controllerAs: 'vm'
};

app.component('dashboardWidgetBondTypeConfigurationsByState', dashboardWidgetBondTypeConfigurationsByStateComponent);
