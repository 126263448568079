import { DocumentRating } from "./model/documentRating";
import { WritingCompany } from "./model/writingCompany";

export interface writingCompany extends WritingCompany {
    $minimumPremiumInheritedText: string;
    $index: number;
    powerOfAttorneyDocument: PowerOfAttorneyDocument;
}

export interface PowerOfAttorneyDocument extends Document {
    rating: DocumentRating;
    documentCategoryName: string;
    modifiedDateTime: Date;
}