export enum InternalNotificationType {    
    BondPurchasedThroughEProducer = "BondPurchasedThroughEProducer",
    ApplicationSubmittedThroughEProducer = "ApplicationSubmittedThroughEProducer",
    BrokerAccountRequested = "BrokerAccountRequested",
    PaymentReceivedThroughEProducerPaymentPortal = "PaymentReceivedThroughEProducerPaymentPortal",
    IndemnitySignatureReceived = "IndemnitySignatureReceived",
    ReleaseNotification = "ReleaseNotification",
    ApplicationFollowUp = "ApplicationFollowUp",
    SupportTicket = "SupportTicket",
    CancellationRequest = "CancellationRequest",
    ReinstatementRequest = "ReinstatementRequest",
    CancellationRequestApproved = "CancellationRequestApproved",
    AttachmentUploadedInEProducerBroker = "AttachmentUploadedInEProducerBroker",
    AttachmentUploadedInEProducerNonBroker = "AttachmentUploadedInEProducerNonBroker",
    RiderRequest = "RiderRequest",
    ReinstatementApprovedDeclined = "ReinstatementApprovedDeclined",
    RiderApprovedDeclined = "RiderApprovedDeclined",
    DocumentCorrectionRequestCompleted = "DocumentCorrectionRequestCompleted",
    BondTypeRequestCompleted = "BondTypeRequestCompleted",
    BondTypeRequestCompletedCarrier = "BondTypeRequestCompletedCarrier",
    CarrierCreatedRider = "CarrierCreatedRider",
    CarrierCreatedCancellation = "CarrierCreatedCancellation",
    CarrierCreatedReinstatement = "CarrierCreatedReinstatement"
}