import { IPromise } from "angular";
import { ActivityService } from "../../../api/activityService";
import { ActivitySettings } from "../../../api/types/activities/activitySettings";
import { ActivityTypeSetting } from "../../../api/types/activities/activityTypeSetting";
import { AttachmentTypeSetting } from "../../../api/types/activities/attachmentTypeSetting";
import { Injectables } from "../../../configuration/injectables";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";

export class ActivitySettingsController {

    public static $inject = [
        Injectables.ActivityService,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly activityService: ActivityService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {}

    public activitySettings: ActivitySettings;
    public processingPromise: IPromise<any>;
    public newActivityType: string;
    public newAttachmentType: string;

    private loadActivitySettings() {
        return this.activityService.getActivitySettings()
            .then((activitySettings) => {
                this.activitySettings = activitySettings;
            });
    }

    public addActivityType() {
        if (!this.newActivityType) {
            return;
        }

        this.activitySettings.activityTypes.push({
            name: this.newActivityType
        } as ActivityTypeSetting);

        this.newActivityType = '';
    }

    public deleteActivityType(activityType: ActivityTypeSetting, index: number) {
        if (!activityType.id) {
            this.activitySettings.activityTypes.splice(index, 1);
            return;
        }

        if (activityType.isRemoved) {
            activityType.isRemoved = false;
        } else {
            activityType.isRemoved = true;
        }
    }

    public addAttachmentType() {
        if (!this.newAttachmentType) {
            return;
        }

        this.activitySettings.attachmentTypes.push({
            name: this.newAttachmentType
        });

        this.newAttachmentType = '';
    }

    public deleteAttachmentType(attachmentType: AttachmentTypeSetting, index: number) {
        if (!attachmentType.id) {
            this.activitySettings.attachmentTypes.splice(index, 1);
            return;
        }

        attachmentType.isRemoved = attachmentType.isRemoved !== true;
    }

    public save() {
        this.processingPromise = this.activityService.saveActivitySettings(this.activitySettings)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Activity settings saved successfully');
                return this.loadActivitySettings();
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to save activity settings');
            })
    }

    public $onInit(): void {
        this.processingPromise = this.loadActivitySettings();
    }
}