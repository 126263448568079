import { InquirySSNDateIssuedRange } from "./inquirySSNDateIssuedRange";

export interface USIdentificationSSN { 
    mdbSubjectAge: string;
    mdbSubjectSSN: string;
    mdbSubjectSSNConfirmed: string;
    ssnMatch: string;
    inquirySubjectSSN: string;
    inquirySSNDateIssued: string;
    inquirySSNDateIssuedRange: InquirySSNDateIssuedRange;
    inquirySSNStateIssued: string;
    inquirySSNDateOfDeath: string;
    inquirySSNStateOfDeath: string;
    inquirySSNByteToByteMatch: string;
}