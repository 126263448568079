import { BondType } from "./bondType";
import { IBaseEntity } from "./iBaseEntity";

    export interface Obligee extends IBaseEntity { 
        bondTypes: BondType[];
        comments: string;
        contact1: string;
        contact2: string;
        contact3: string;
        email: string;
        fax: string;
        isActive: boolean;
        isGeneric: boolean;
        mailAddress: string;
        mailCity: string;
        mailCounty: string;
        mailState: string;
        mailSuiteAptNumber: string;
        mailZip: string;
        masterObligee: Obligee;
        masterObligeeId: number;
        name: string;
        phone: string;
        physicalAddress: string;
        physicalCity: string;
        physicalCounty: string;
        physicalState: string;
        physicalSuiteAptNumber: string;
        physicalZip: string;
        requisitioningDepartment: string;
        requisitioningState: string;
        websiteURL: string;
        id: number;
        createdDateTime: Date;
    }