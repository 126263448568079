import DocumentTableQueryOptions from "./documentFilterDropdown/documentTableQueryOptions";

export type DocumentLibrarySettings = {
    filters: DocumentTableQueryOptions;
    search: string;
    orderby: string;
    recordsPerPage: number;
    currentPage: number;
    theme: string;
};
