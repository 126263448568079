import { AlertType } from "./alertType";
import { City } from "./city";
import { DateEffective } from "./dateEffective";
import { DateReported } from "./dateReported";
import { PhoneNumbers } from "./phoneNumbers";

export interface USAlertContact { 
    alertType: AlertType;
    dateReported: DateReported;
    dateEffective: DateEffective;
    addressLine1: string;
    addressLine2: string;
    city: City;
    state: string;
    postalCode: string;
    countryCode: string;
    phoneNumbers: PhoneNumbers;
    freeformInformation: string;   
}