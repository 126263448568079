import { SelectOption } from "../../../../api/types/selectOption";
import { Injectables } from "../../../../configuration/injectables";
import app from "../../../../main";
import { TagService } from "../../../../api/tagService";
import { Table } from "../../../../utilities/tables/table";
import { ApplicationReportItem } from "../applicationReportItem";
import { ApplicationReportDropdownOptions } from "./applicationReportFilterDropdownOptions";

class ApplicationReportFilterDropdownController {
    
    public static $inject = [
        Injectables.TagService   
    ];

    constructor(
            private readonly tagService: TagService
        ) {
        this.modifiedFilters = {} as ApplicationReportDropdownOptions;
    }

    public isOpen: boolean;
    public filterCount = 0;
    public modifiedFilters: ApplicationReportDropdownOptions;
    public tagOptions: SelectOption<string>[];

    public table: Table<ApplicationReportItem, ApplicationReportDropdownOptions>;
    
    public clearFilters() {
        this.modifiedFilters = {
            applicationType: null,
            minimumBondAmount: null,
            maximumBondAmount: null,
            minimumEffectiveDate: null,
            maximumEffectiveDate: null,
            minimumSubmissionDate: null,
            maximumSubmissionDate: null,
            minimumCreatedDate: null,
            maximumCreatedDate: null,
            openClosedStatus: null,
            status: null,
            tag: null
        } as ApplicationReportDropdownOptions;

        this.table.applyFilters({ ...this.modifiedFilters });

        this.setFilterCount();
        this.isOpen = false;
    }

    public cancel() {
        this.isOpen = false;
        this.modifiedFilters = { ...this.table.queryOptions };
    }

    private setModifiedFilters = () => {
        this.modifiedFilters.applicationType = this.table.queryOptions.applicationType;
        this.modifiedFilters.minimumEffectiveDate = this.table.queryOptions.minimumEffectiveDate;
        this.modifiedFilters.maximumEffectiveDate = this.table.queryOptions.maximumEffectiveDate;
        this.modifiedFilters.minimumSubmissionDate = this.table.queryOptions.minimumSubmissionDate;
        this.modifiedFilters.maximumSubmissionDate = this.table.queryOptions.maximumSubmissionDate
        this.modifiedFilters.minimumCreatedDate = this.table.queryOptions.minimumCreatedDate;
        this.modifiedFilters.maximumCreatedDate = this.table.queryOptions.maximumCreatedDate;
        this.modifiedFilters.minimumBondAmount = this.table.queryOptions.minimumBondAmount;
        this.modifiedFilters.maximumBondAmount = this.table.queryOptions.maximumBondAmount;
        this.modifiedFilters.openClosedStatus = this.table.queryOptions.openClosedStatus;
        this.modifiedFilters.status = this.table.queryOptions.status;
        this.modifiedFilters.tag =  this.table.queryOptions.tag; 

        this.setFilterCount();
    }

    public applyFilters() {
        this.isOpen = false;
        this.table.applyFilters({ ...this.modifiedFilters });
        this.setFilterCount();
    }

    public setFilterCount() {
        this.filterCount = 0;

        if (this.table.queryOptions.applicationType ){
            this.filterCount++;
        }

        if (
            this.table.queryOptions.minimumEffectiveDate ||
            this.table.queryOptions.maximumEffectiveDate
        ) {
            this.filterCount++;
        }

        if (
            this.table.queryOptions.minimumSubmissionDate ||
            this.table.queryOptions.maximumSubmissionDate
        ) {
            this.filterCount++;
        }

        if (
            this.table.queryOptions.minimumCreatedDate ||
            this.table.queryOptions.maximumCreatedDate
        ) {
            this.filterCount++;
        }

        if ( 
            this.table.queryOptions.maximumBondAmount ||
            this.table.queryOptions.minimumBondAmount
        ) {
            this.filterCount++;
        }

        if (this.table.queryOptions.openClosedStatus) {
            this.filterCount++;
        }

        if (this.table.queryOptions.status ){
            this.filterCount++;
        }

        if (this.table.queryOptions.tag) {
            this.filterCount++;
        }

    }

    private async loadTagOptions() {
        this.tagOptions = await this.tagService.loadTagOptions();
    }

    public $onInit() {
        this.loadTagOptions();
        this.setModifiedFilters();
    }
}

const applicationReportFilterDropdownComponent = {
    bindings: {
        table: "<"
    },
    controller: ApplicationReportFilterDropdownController,
    controllerAs: "vm",
    templateUrl: "app/states/agentSpecific/applicationReport/applicationReportFilterDropdown/applicationReportFilterDropdown.html"
};

app.component(
    "applicationReportFilterDropdown",
    applicationReportFilterDropdownComponent
);
