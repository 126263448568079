import { TableQueryOptions } from "../../api/types/tableQuery";

export interface CustomerTableQueryOptions extends TableQueryOptions {
    contactFirstName?: string;
    contactLastName?: string;
    contactEmail?: string;
    contactPhone?: string;
    isActive?: boolean;
    tag?: string;
    brokerId?: number;
    parentCustomerId?: number;
}