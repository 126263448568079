import { IStateProvider } from "../../typeDefinitions/uiRouter";
import app from "../main";

app.config(($stateProvider: IStateProvider) => {
    $stateProvider.decorator('parent', (internalStateObj, parentFn) => {
        // This fn is called by StateBuilder each time a state is registered

        // The first arg is the internal state. Capture it and add an accessor to public state object.
        (internalStateObj as any).self.$$state = () => { return internalStateObj; };

        // pass through to default .parent() function
        return parentFn(internalStateObj);
    });
});