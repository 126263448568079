import { IQService } from "angular";
import { SystemAccountService } from "../../../api/systemAccountService";
import { A3RootScope } from "../../../api/types/a3RootScope";
import { UserGroupService } from "../../../api/userGroupService";
import { UserService } from "../../../api/userService";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../configuration/injectables";
import { SystemSettings } from "../../../configuration/settings/systemSettings";
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { State } from "../../state";
import { UserDetail } from "./userDetail";
import { UserDetailStateParams } from "./userDetailStateParams";
import { UserGroupOption } from "./userGroupOption";

export class UsersDetailController {
    static $inject = [
        Injectables.$q,
        Injectables.$stateParams,
        Injectables.$state,
        Injectables.CurrentUserResolver,
        Injectables.SystemSettings,
        Injectables.SystemAccountService,
        Injectables.UserService,
        Injectables.UserGroupService,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly $q: IQService,
        private readonly $stateParams: UserDetailStateParams,
        private readonly $state: State,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly systemSettings: SystemSettings,
        private readonly systemAccountService: SystemAccountService,
        private readonly userService: UserService,
        private readonly userGroupService: UserGroupService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {}

    public get isCarrier(): boolean {
        return this.currentUserResolver.getCurrentUser().systemAccount.isCarrier;
    }

    public user: UserDetail;
    public waitCount: number;
    public signatureDropzone: Dropzone;
    public emailExists: boolean;
    public signatureImageRequired: boolean;
    public isEpicIntegrationEnabled: boolean;
    public busyIndicator: BusyIndicator;
    public signatureImageFilename: string;
    public signatureImagePath: string; 
    public userGroupOptions: UserGroupOption[];

    public dzProcessing(file) {
        this.signatureDropzone.options.url = this.systemSettings.apiBaseUrl + "upload/UploadUserSignature";
    }

    public dzAddedFile(file) {
        this.signatureImageFilename = file.filename;
        if (this.signatureDropzone.files.length > 1) {
            this.signatureDropzone.files[0] = this.signatureDropzone.files[1];
            this.signatureDropzone.files.splice(1, 1);
        }
    }

    public dzError(file, response, xhr) {
        if (!response.Message) {
            this.signatureImageFilename = null;
        }
    }

    public dzSuccess(file, response) {
        this.signatureDropzone.removeAllFiles();
        this.user.signatureImageFileId = response;
        this.save();
    }

    public loadUserGroups() {
        return this.userGroupService.getUserGroupOptions()
            .then((groups) => {
                this.userGroupOptions = groups;
            });
    }

    public loadData() {
        return this.userService.getUserDetail(this.$stateParams.id)
            .then((user) => {
                this.user = user;
                
                for(let i = 0; i<this.user.userGroupIds.length; i++) {
                    const userGroup = this.userGroupOptions.find((group) => group.id === this.user.userGroupIds[i]);
                    userGroup.isSelected = true;
                }

                if (this.user.signatureImageFileId) {
                    return this.getSignatureImage();
                }
            });
    }

    public save_click() {
        if (this.signatureDropzone.files.length > 0) {
            this.waitCount++;
            this.signatureDropzone.processQueue();
        }

        this.save();
    }

    public save() {
        if (this.waitCount > 0) {
            this.waitCount--;
            return;
        }

        this.waitCount = 0;

        if (this.user.isAttorneyInFact && !this.user.signatureImageFileId) {
            this.signatureImageRequired = true;
            return;
        }

        this.emailExists = false;

        this.user.userGroupIds = this.userGroupOptions
            .filter((group) => group.isSelected === true)
            .map((group) => group.id);

        this.busyIndicator.message = 'Saving...';
        this.busyIndicator.promise = this.userService.saveUser(this.user)
            .then(() => {
                this.$state.go('main.usersGrid');
                this.toastMessageCreator.createSuccessMessage('User saved successfully');
            }).catch(function (e) {
                if (e.error.innererror.message.indexOf("EmailExists") === 0) {
                    this.emailExists = true;
                    this.toastMessageCreator.createErrorMessage('This email address is already used by a user');
                } else
                    this.toastMessageCreator.createErrorMessage('An error occurred trying to save this user');
            });
    }

    public getSignatureImage() {
        return this.userService.getSignatureImage(this.user.id)
            .then(function (img) {
                this.signatureImagePath = img.data;
            });
    }

    public loadEpicData() {
        return this.systemAccountService.isEpicIntegrationEnabled()
            .then((isEpicIntegrationEnabled) => {
                this.isEpicIntegrationEnabled = isEpicIntegrationEnabled;
            });
    }

    public $onInit() {
        this.waitCount = 0;
        this.busyIndicator = {
            message: 'Loading...'
        };
        
        var promises = [];
        
        promises.push(this.loadUserGroups());
        promises.push(this.loadEpicData());

        this.busyIndicator.promise = this.$q.all(promises)
            .then(() => {
                if (this.$stateParams.id) {
                    return this.loadData();
                } else {
                    this.user = {} as UserDetail;
                }
            });
    }
}