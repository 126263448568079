import { BondCorrectionBond } from "../api/types/bondCorrectionBond";
import { CancellationRequestBond } from "../api/types/cancellationRequestBond";
import { ODataFilterCollection } from "../api/odata";
import { ReinstatementRequestBond } from "../api/types/reinstatementRequestBond";
import { RiderRequestBond } from "../api/types/riderRequestBond";
import { UiApplication } from "../api/types/uiApplication";
import { ApplicationFollowUp } from "../api/types/model/applicationFollowUp";
import { BondChange } from "../api/types/model/bondChange";
import { BondTransaction } from "../api/types/model/bondTransaction";
import { DocumentCategory } from "../api/types/model/documentCategory";
import { DocumentCorrectionRequest } from "../api/types/model/documentCorrectionRequest";
import { Rate } from "../api/types/model/rate";
import { SystemAccountContact } from "../api/types/model/systemAccountContact";
import { WritingCompany } from "../api/types/model/writingCompany";
import { Quote } from "../api/types/model/quote";
import { ModalCreator } from "../components/modals/modalCreator";
import { ModalResult } from "../components/modals/modalResult";
import { ModalSize } from "../components/modals/modalSizes";
import { Injectables } from "../configuration/injectables";
import { ActivityModalOptions } from "./activityModal/activityModalOptions";
import { ActivityModalResult } from "./activityModal/activityModalResult";
import { ApplicationAccessOptions } from "./applicationAccessModal/applicationAccess";
import { ApplicationDetailOptions } from "./applicationDetailModal/applicationDetail";
import { AttachmentModalOptions } from "./attachmentModal/attachmentModalOptions";
import { AttachmentModalResult } from "./attachmentModal/attachmentModalResult";
import { BondAccountAssignmentModalOptions } from "./bondAccountAssignmentModal/bondAccountAssignmentModalOptions";
import { BondAccountAssignmentModalResult } from "./bondAccountAssignmentModal/bondAccountAssignmentModalResult";
import { BondAccountModalOptions } from "./bondAccountModal/bondAccountModalOptions";
import { BondAccountModalResult } from "./bondAccountModal/bondAccountModalResult";
import { BondBulkDownloadModalOptions } from "./bondBuilkDownloadModal/bondBulkDownloadModalOptions";
import { BondBulkDownloadModalResult } from "./bondBuilkDownloadModal/bondBulkDownloadModalResult";
import { BondCorrectionOptions } from "./bondCorrectionModal/bondCorrection";
import { BondCorrectionResult } from "./bondCorrectionModal/BondCorrectionResult";
import { BondDetailOptions } from "./bondDetailModal/bondDetailModalOptions";
import { BondDetailResult } from "./bondDetailModal/BondDetailResult";
import { BrokerAssignmentModalOptions } from "./brokerAssignmentModal/BrokerAssignmentModalOptions";
import { BrokerAssignmentModalResult } from "./brokerAssignmentModal/BrokerAssignmentModalResult";
import { RequestCancellationOptions } from "./cancellationRequestModal/cancellationRequestModalOptions";
import { RequestCancellationResult } from "./cancellationRequestModal/RequestCancellationResult";
import { CloseApplicationOptions } from "./closeApplicationModal/closeApplicationModalOptions";
import { CloseApplicationResult } from "./closeApplicationModal/CloseApplicationResult";
import { ConfirmationModalOptions } from "./confirmationModal/ConfirmationModalOptions";
import { CustomerLookupOptions } from "./customerLookupModal/customerLookupModalOptions";
import { CustomerLookupResult } from "./customerLookupModal/CustomerLookupResult";
import { DocumentCorrectionModalOptions } from "./documentCorrectionModal/DocumentCorrectionModalOptions";
import { DocumentLibraryUploadCategorySelectionOptions } from "./documentLibrarySelectCategoryModal/DocumentLibraryUploadCategorySelectionOptions";
import { EpicCustomerConnectionModalResult } from "./epicCustomerConnectionModal/EpicCustomerConnectionModalResult";
import { IssueBondModalResult } from "./issueBondModal/IssueBondModalResult";
import { LeadModalOptions } from "./leadModal/leadModalOptions";
import { LeadModalResult } from "./leadModal/LeadModalResult";
import { MarkBondForReviewModalResult } from "./markBondForReviewModal/MarkBondForReviewModalResult";
import { NewCustomerResult } from "./newCustomerModal/NewCustomerResult";
import { NewInvoiceModalResult } from "./newInvoiceModal/newInvoiceModalResult";
import { ObligeeResult } from "./obligeeModal/obligeeModalResult";
import { OdataFiltersModalOptions } from "./odataFiltersModal/OdataFiltersModalOptions";
import { OdataFiltersModalResult } from "./odataFiltersModal/OdataFiltersModalResult";
import { PaymentAssignmentModalResult } from "./paymentAssignmentModal/paymentAssignmentModalResult";
import { PaymentModalOptions } from "./paymentModal/paymentModalOptions";
import { PaymentModalResult } from "./paymentModal/paymentModalResult";
import { QuoteDetailModalOptions } from "./quoteDetailModal/quoteDetailModalOptions";
import { ReconsiderApplicationOptions } from "./reconsiderApplicationModal/reconsiderApplicationModalOptions";
import { ReconsiderApplicationResult } from "./reconsiderApplicationModal/ReconsiderApplicationResult";
import { RequestReinstatementOptions } from "./reinstatementRequestModal/reinstatementRequestModalOptions";
import { RequestReinstatementResult } from "./reinstatementRequestModal/RequestReinstatementResult";
import { RelatedCustomersModalResult } from "./relatedCustomersModal/RelatedCustomersModalResult";
import { RenewalModalOptions } from "./renewalModal/renewalModalOptions";
import { RenewalModalResult } from "./renewalModal/renewalModalResult";
import { RequestRiderOptions } from "./riderRequestModal/riderRequestModalOptions";
import { RequestRiderResult } from "./riderRequestModal/RequestRiderResult";
import { SystemAccountContactOptions } from "./systemAccountContactModal/systemAccountContactModalOptions";
import { WritingCompanyModalController } from "./writingCompanyModal/writingCompanyModalController";
import { WritingCompanyOptions } from "./writingCompanyModal/writingCompanyModalOptions";
import app from "../main";
import { SendEpicBillingReportModalResult } from "./sendBillingReportModal/sendBillingReportModalResult";
import { ShippingModalInfoModalOptions } from "./shippingInfoModal/shippingModalInfoOptions";
import { CancellationRequestModalController } from "./cancellationRequestModal/cancellationRequestModalController";
import { PurchaseQuoteResult } from "./purchaseQuoteModal/PurchaseQuoteResult";
import { InvoiceModalController } from "./invoiceModal/invoiceModalController";
import { QuoteDocumentsModalController, QuoteDocumentsModalResult } from "./quoteDocumentsModal/quoteDocumentsModal";
import { AttachmentViewerModalController } from "./attachmentViewerModal/attachmentViewerModal";
import { BondTypeSelectionModalController, BondTypeSelectionModalResult } from "./bondTypeSelectionModal/bondTypeSelectionModal";
import { BondTypeHistoryModalController } from "./bondTypeHistoryModal/bondTypeHistoryModalController";


export class ModalOpener {
    
    public static $inject = [
        Injectables.ModalCreator
    ];

    constructor(
        private readonly modalCreator: ModalCreator) { 
    }

    public closeApplicationModal(applicationId: number): ModalResult<object> {
        return this.modalCreator.createModal<CloseApplicationResult, CloseApplicationOptions>(
            'app/modals/closeApplicationModal/closeApplicationModal.html', 
            'CloseApplicationModalController', 
            ModalSize.Small, 
            { applicationId: applicationId }
        );
    }

    public declineApplicationModal(applicationId: number): ModalResult<object> {
        return this.modalCreator.createModal(
            'app/modals/declineApplicationModal/declineApplicationModal.html', 
            'DeclineApplicationModalController', 
            ModalSize.Small, 
            { applicationId: applicationId }
        );
    }

    public reconsiderApplicationModal(applicationId: number): ModalResult<object> {
        return this.modalCreator.createModal<ReconsiderApplicationResult, ReconsiderApplicationOptions>(
            'app/modals/reconsiderApplicationModal/reconsiderApplicationModal.html', 
            'ReconsiderApplicationModalController', 
            ModalSize.Small, 
            { applicationId: applicationId }
        );
    }

    public customerLookupModal(): ModalResult<CustomerLookupResult> {
        return this.modalCreator.createModal<CustomerLookupResult, CustomerLookupOptions>(
            'app/modals/customerLookupModal/customerLookupModal.html', 
            'CustomerLookupModalController', 
            ModalSize.Large);
    }

    // Remove AMS360 integration
    public exportToAms360Modal(bondTransactionId: number): ModalResult<object> {
        return this.modalCreator.createModal(
            'app/ams360/partials/customerAms360ExportModal.html', 
            'customerAms360ExportModalController', 
            ModalSize.XLarge, 
            { bondTransactionId: bondTransactionId }
        );
    }

    // Remove AMS360 integration
    public showLoadAms360CustomerModal(): ModalResult<object> {
        return this.modalCreator.createModal(
            'app/application/partials/modalLoadAms360Customer.html', 
            'ModalLoadAms360CustomerController',
            ModalSize.Large
        );
    }

    public newPaymentModal(
        customerId: number, 
        eProducerAccountId: number
    ): ModalResult<object> {
        return this.modalCreator.createModal<PaymentModalResult, PaymentModalOptions>(
            'app/modals/paymentModal/paymentModal.html', 
            'PaymentModalController', 
            ModalSize.Large, 
            {
                customerId: customerId, 
                eProducerAccountId: eProducerAccountId
            }
        );
    }

    public showCancellationRequestModal(bond: CancellationRequestBond): ModalResult<object> {
        return this.modalCreator.createModal<RequestCancellationResult, RequestCancellationOptions>(
            'app/modals/cancellationRequestModal/cancellationRequestModal.html', 
            CancellationRequestModalController, 
            ModalSize.Large, 
            { bond: bond }
        );
    }

    public showDocumentLibrarySelectCategoryModal(
        proceed: () => void, 
        dropzone: Dropzone, 
        setUploadCategory: (category: DocumentCategory) => void, 
        documentCategories: DocumentCategory[]
    ): ModalResult<object> {
        return this.modalCreator.createModal<object, DocumentLibraryUploadCategorySelectionOptions>(
            'app/modals/documentLibrarySelectCategoryModal/documentLibrarySelectCategoryModal.html', 
            'DocumentLibrarySelectCategoryModalController', 
            ModalSize.Large, 
            { 
                proceed: proceed, 
                dropzone: dropzone, 
                setUploadCategory: setUploadCategory, 
                documentCategories: documentCategories 
            }
        );
    }

    public showReinstatementRequestModal(bond: ReinstatementRequestBond): ModalResult<object> {
        return this.modalCreator.createModal<RequestReinstatementResult, RequestReinstatementOptions>(
            'app/modals/reinstatementRequestModal/reinstatementRequestModal.html', 
            'ReinstatementRequestModalController', 
            ModalSize.Large, 
            { bond: bond }
        );
    }

    public creditReportModal(creditReportId: number): ModalResult<object> {
        return this.modalCreator.createModal(
            'app/modals/creditReportModal/creditReportModal.html', 
            'CreditReportModalController', 
            ModalSize.Large, 
            { creditReportId: creditReportId }
        );
    }

    public deleteModal(name: any, id: number): ModalResult<object> {
        return this.modalCreator.createModal(
            'app/modals/gridConfirmDeleteModal/gridConfirmDeleteModal.html', 
            'GridConfirmDeleteModalController', 
            ModalSize.Small, 
            { 
                name: name, 
                id: id 
            }
        );
    }

    public downloadDocumentsModal(transactionId: number): ModalResult<object> {
        return this.modalCreator.createModal(
            'app/modals/downloadTransactionDocumentsModal/downloadTransactionDocumentsModal.html', 
            'DownloadTransactionDocumentsModalController', 
            ModalSize.Large, 
            { transactionId: transactionId }
        );
    }

    public showObligeeHistoryModal(obligeeId: number) : ModalResult<object> {
        return this.modalCreator.createModal(
            'app/modals/obligeeHistoryModal/obligeeHistoryModal.html', 
            'ObligeeHistoryModalController', 
            ModalSize.Large, 
            { obligeeId: obligeeId}
        );
    }

    public showBondTransactionHistoryModal(bondTransactionId: number) : ModalResult<object> {
        return this.modalCreator.createModal(
            'app/modals/bondTransactionHistoryModal/bondTransactionHistoryModal.html', 
            'BondTransactionHistoryModalController', 
            ModalSize.Large, 
            { bondTransactionId: bondTransactionId}
        );
    }

    public showBondTypeHistoryModal(bondTypeId: number) : ModalResult<object> {
        return this.modalCreator.createModal(
            'app/modals/bondTypeHistoryModal/bondTypeHistoryModal.html', 
            BondTypeHistoryModalController, 
            ModalSize.Large, 
            { bondTypeId: bondTypeId}
        );
    }

    public showRiderRequestModal(
        bond: RiderRequestBond, 
        changes: BondChange[]
    ): ModalResult<object> {
        return this.modalCreator.createModal<RequestRiderResult, RequestRiderOptions>(
            'app/modals/riderRequestModal/riderRequestModal.html', 
            'RiderRequestModalController', 
            ModalSize.Large, 
            { bond: bond, changes: changes }
        );
    }

    public showBondCorrectionModal(
        bond: BondCorrectionBond, 
        changes: BondChange[]
    ): ModalResult<BondCorrectionResult> {
        return this.modalCreator.createModal<BondCorrectionResult, BondCorrectionOptions>(
            'app/modals/bondCorrectionModal/bondCorrectionModal.html', 
            'BondCorrectionModalController', 
            ModalSize.Large, 
            { bond: bond, changes: changes }
        );
    }

    public uploadModal(uploadingFiles: any): ModalResult<object> {
        return this.modalCreator.createModal(
            'app/modals/uploadModal/uploadModal.html', 
            'UploadModalController', 
            ModalSize.Large, 
            { files: uploadingFiles }
        );
    }

    public showObligeeModal(obligee: any): ModalResult<ObligeeResult> {
        return this.modalCreator.createModal(
            'app/modals/obligeeModal/modalObligee.html', 
            'ModalObligeeController', 
            ModalSize.Large, 
            { obligeeId: obligee ? obligee.id : null }
        );
    }

    public showRateModal(rate?: any): ModalResult<Rate> {
        return this.modalCreator.createModal(
            'app/modals/rateModal/rateModal.html', 
            'RateModalController', 
            ModalSize.Large, 
            { rateId: rate ? rate.id : null }
        );
    }

    public showDownloadModal(): ModalResult<object> {
        return this.modalCreator.createModal(
            'app/modals/exportFormsModal/exportFormsModal.html', 
            'ExportFormsModalController', 
            ModalSize.Large 
        );
    }

    public showLeadDetailsModal(id: number): ModalResult<LeadModalResult> {
        return this.modalCreator.createModal<LeadModalOptions, LeadModalResult>(
            'app/modals/leadModal/leadModal.html', 
            'LeadModalController', 
            ModalSize.Large, 
            { leadId: id }
        );
    }

    public showFiltersModal(filterCollection: ODataFilterCollection): ModalResult<OdataFiltersModalResult> {
        return this.modalCreator.createModal<OdataFiltersModalResult, OdataFiltersModalOptions>(
            'app/modals/odataFiltersModal/odataFiltersModal.html',
            'OdataFiltersModalController', 
            ModalSize.Large, 
            { filterCollection: filterCollection }
        );
    }

    public showShippingModal(bondTransaction: BondTransaction): ModalResult<void> {
        return this.modalCreator.createModal<void, ShippingModalInfoModalOptions>(
            'app/modals/shippingInfoModal/shippingModalInfo.html',
            'ShippingInfoModalController', 
            ModalSize.Large, 
            { bondTransaction: bondTransaction }
        );
    }

    public changePasswordModal(): ModalResult<object> {
        return this.modalCreator.createModal(
            'app/modals/changePasswordModal/changePasswordModal.html', 
            'ChangePasswordModalController', 
            ModalSize.Large
        );
    }

    public contactModal(contact: SystemAccountContact): ModalResult<object> {
        return this.modalCreator.createModal<SystemAccountContact, SystemAccountContactOptions>(
            'app/modals/systemAccountContactModal/contactModal.html',
            'ContactModalController', 
            ModalSize.Large, 
            { contact: contact }
        );
    }

    public showWritingCompanyModal(company: WritingCompany): ModalResult<object> {
        return this.modalCreator.createModal<WritingCompany, WritingCompanyOptions>(
            'app/modals/writingCompanyModal/writingCompanyModal.html', 
            WritingCompanyModalController, 
            ModalSize.Large, 
            { company: company }
        );
    }

    public applicationDetailModal(applicationId: number): ModalResult<object> {
        return this.modalCreator.createModal<UiApplication, ApplicationDetailOptions>(
            'app/modals/applicationDetailModal/applicationDetailModal.html', 
            'ApplicationDetailModalController', 
            ModalSize.XLarge, 
            { applicationId: applicationId }
        );
    }

    public emailApplicationModal(
        applicationId?: number, 
        bondTypeId?: number, 
        bondAmount?: number
    ): ModalResult<object>{
        return this.modalCreator.createModal<UiApplication, ApplicationAccessOptions>(
            'app/modals/applicationAccessModal/applicationAccessModal.html', 
            'ApplicationAccessModalController', 
            ModalSize.Large, 
            { 
                applicationId: applicationId, 
                bondTypeId: bondTypeId, 
                bondAmount: bondAmount 
            }
        );
    }

    public editQuoteModal(
        applicationId: number, 
        quoteId: number
    ): ModalResult<object> {
        return this.modalCreator.createModal<Quote, QuoteDetailModalOptions>(
            'app/modals/quoteDetailModal/quoteDetailModal.html', 
            'QuoteDetailModalController', 
            ModalSize.Large, 
            { 
                applicationId: applicationId, 
                quoteId: quoteId
            }
        );
    }

    public deleteConfirmationModal(
        headerMessage: string, 
        message: string, 
        key: any,
        endpoint: any,
        deleteFunction: any, 
        successMessage: string, 
        errorMessage: string
    ): ModalResult<object> {
        return this.modalCreator.createModal(
            'app/modals/deleteConfirmationModal/deleteConfirmationModal.html', 
            'DeleteConfirmationModalController', 
            ModalSize.Small, 
            { 
                header: headerMessage, 
                message: message, 
                key: key, 
                endpoint: endpoint, 
                deleteFunction: deleteFunction, 
                successMessage: successMessage, 
                errorMessage: errorMessage 
            }
        );
    }

    public confirmModal(
        headerMessage: string, 
        message: string, 
        confirmButtonText?: string, 
        cancelButtonText?: string
    ): ModalResult<object> {
        return this.modalCreator.createModal<null, ConfirmationModalOptions>(
            'app/modals/confirmationModal/confirmationModal.html', 
            'ConfirmationModalController', 
            ModalSize.Small, 
            {
                header: headerMessage, 
                message: message, 
                confirmButtonText: confirmButtonText, 
                cancelButtonText: cancelButtonText
            }
        );
    }

    // this uses the same controller and html as gridConfirmDeleteModal
    public removeDashboardModal(name: any): ModalResult<object> {
        return this.modalCreator.createModal(
            'app/modals/gridConfirmDeleteModal/gridConfirmDeleteModal.html', 
            'GridConfirmDeleteModalController', 
            ModalSize.Small, 
            { name: name }
        );
    }

    public purchaseQuoteModal(quoteId: number): ModalResult<PurchaseQuoteResult> {
        return this.modalCreator.createModal(
            'app/modals/purchaseQuoteModal/purchaseQuoteModal.html', 
            'PurchaseQuoteModalController', 
            ModalSize.Large, 
            { quoteId: quoteId }
        );
    }

    public bondDetailModal(bondId: number): ModalResult<object> {
        return this.modalCreator.createModal<BondDetailResult, BondDetailOptions>(
            'app/modals/bondDetailModal/bondDetailModal.html', 
            'BondDetailModalController', 
            ModalSize.XLarge, 
            { bondId: bondId }
        );
    }

    public showWidgetModal(): ModalResult<object> {
        return this.modalCreator.createModal(
            'app/modals/addWidgetModal/addWidgetModal.html', 
            'AddWidgetModalController', 
            ModalSize.Large
        );
    }

    public indemnityModal(quoteId: number): ModalResult<object> {
        return this.modalCreator.createModal(
            'app/modals/indemnityModal/indemnityModal.html', 
            'IndemnityModalController', 
            ModalSize.XLarge, 
            { quoteId: quoteId }
        );
    }

    public showEproducerUserModal(userId?: number, eProducerAccountId?: number): ModalResult<object>{
        // either userId or eProduerAccountId is required
        // either we are editing an existing user or creating a new for the eProducerAccountId
        if (!eProducerAccountId && !userId) {
            throw new Error('either userId or eProducerAccountId is required');
        }

        return this.modalCreator.createModal(
            'app/modals/eProducerUserModal/eProducerUserModal.html', 
            'EProducerUserModalController', 
            ModalSize.Large, 
            { 
                userId: userId, 
                eProducerAccountId: eProducerAccountId 
            }
        );
    }

    public showFollowUpModal(followUp: ApplicationFollowUp): ModalResult<object> {
        return this.modalCreator.createModal(
            'app/modals/followUpModal/followUpModal.html', 
            'FollowUpModalController', 
            ModalSize.Large, 
            { followUp: followUp }
        );
    }

    public showInviteBrokerModal(): ModalResult<object> {
        return this.modalCreator.createModal(
            'app/modals/inviteBrokerModal/inviteBrokerModal.html', 
            'InviteBrokerModalController', 
            ModalSize.Large
        );
    }

    public showEProducerBrokerModal(): ModalResult<object> {
        return this.modalCreator.createModal(
            'app/modals/eProducerBrokerModal/eProducerBrokerModal.html', 
            'EProducerBrokerModalController', 
            ModalSize.Large
        );
    }

    public showBrokerAssignmentModal(customerId: number): ModalResult<BrokerAssignmentModalResult> {
        return this.modalCreator.createModal<BrokerAssignmentModalResult, BrokerAssignmentModalOptions>(
            'app/modals/brokerAssignmentModal/brokerAssignmentModal.html', 
            'BrokerAssignmentModalController', 
            ModalSize.Large, 
            { customerId: customerId }
        );
    }

    public showAttachmentModal(
        attachmentId: number, 
        applicationId: number, 
        customerId: number, 
        bondId: number, 
        eProducerAccountId: number
    ): ModalResult<object> {
        return this.modalCreator.createModal<AttachmentModalResult, AttachmentModalOptions>(
            'app/modals/attachmentModal/attachmentModal.html', 
            'AttachmentModalController', 
            ModalSize.Small, 
            { 
                attachmentId: attachmentId, 
                applicationId: applicationId, 
                customerId: customerId, 
                eProducerAccountId: eProducerAccountId, 
                bondId: bondId 
            }
        );
    }

    public showDocumentCorrectionModal(
        documentId: number,
        documentName: string, 
        bondId: number, 
        bondTransactionDocumentVersionId: number
    ): ModalResult<DocumentCorrectionRequest> {
        return this.modalCreator.createModal<DocumentCorrectionRequest, DocumentCorrectionModalOptions>(
            'app/modals/documentCorrectionModal/documentCorrectionModal.html', 
            'DocumentCorrectionModalController', 
            ModalSize.Large, 
            { 
                documentId: documentId,
                documentName: documentName, 
                bondId: bondId, 
                bondTransactionDocumentVersionId: bondTransactionDocumentVersionId 
            }
        );
    }

    public showBondTypeRequestModal(): ModalResult<object> {
        return this.modalCreator.createModal(
            'app/modals/bondTypeRequestModal/bondTypeRequestModal.html', 
            'BondTypeRequestModalController', 
            ModalSize.Large
        );
    }

    public showExportEpicModal(bondTransactionId: number): ModalResult<object> {
        return this.modalCreator.createModal(
            'app/modals/epicBondTransactionExportModal/epicBondTransactionExportModal.html', 
            'EpicExportBondTransactionModalController', 
            ModalSize.XLarge, 
            { bondTransactionId: bondTransactionId }
        );
    }

    public showNewCustomerModal(): ModalResult<NewCustomerResult> {
        return this.modalCreator.createModal(
            'app/modals/newCustomerModal/newCustomerModal.html', 
            'NewCustomerModalController', 
            ModalSize.Large
        );
    }

    public showPaymentAssignmentModal(paymentTransactionId: number): ModalResult<PaymentAssignmentModalResult> {
        return this.modalCreator.createModal(
            'app/modals/paymentAssignmentModal/paymentAssignmentModal.html', 
            'PaymentAssignmentModalController', 
            ModalSize.XLarge, 
            { paymentTransactionId: paymentTransactionId }
        );
    }

    public showPacketBulkDownloadModal(bondIds: number[]): ModalResult<BondBulkDownloadModalResult> {
        return this.modalCreator.createModal<BondBulkDownloadModalResult, BondBulkDownloadModalOptions>(
            'app/modals/bondBulkDownloadModal/bondBulkDownloadModal.html', 
            'BondBulkDownloadModalController', 
            ModalSize.Large,
            { bondIds: bondIds } 
        );
    }

    public showReassignBondModal(bondId: number): ModalResult<object> {
        return this.modalCreator.createModal(
            'app/modals/reassignBondModal/reassignBondModal.html', 
            'ReassignBondModalController', 
            ModalSize.Large, 
            { bondId: bondId }
        );
    }

    public showMarkBondForReviewModal(bondId: number): ModalResult<MarkBondForReviewModalResult> {
        return this.modalCreator.createModal(
            'app/modals/markBondForReviewModal/markBondForReviewModal.html', 
            'MarkBondForReviewModalController', 
            ModalSize.Small, 
            { bondId: bondId }
        );
    }

    public showIssueBondModal(customerId: number): ModalResult<IssueBondModalResult> {
        return this.modalCreator.createModal(
            'app/modals/issueBondModal/issueBondModal.html', 
            'IssueBondModalController', 
            ModalSize.Large, 
            { customerId: customerId }
        );
    }

    public showEpicCustomerConnectionModal(customerId: number): ModalResult<EpicCustomerConnectionModalResult> {
        return this.modalCreator.createModal(
            'app/modals/epicCustomerConnectionModal/epicCustomerConnectionModal.html', 
            'EpicCustomerConnectionModalController', 
            ModalSize.Small, 
            { customerId: customerId }
        );
    }

    public showAssignPrincipalModal(customerId: number, bondIds: number[]): ModalResult<object> {
        return this.modalCreator.createModal(
            'app/modals/assignPrincipalModal/assignPrincipalModal.html', 
            'AssignPrincipalModalController', 
            ModalSize.Large, 
            { 
                customerId: customerId, 
                bondIds: bondIds 
            }
        );
    }

    public showActivityModal(
        activityId?: number, 
        customerId?: number, 
        bondId?: number, 
        bondAccountId?: number, 
        eProducerAccountId?: number, 
        applicationId?: number, 
        defaultOptions?: string
    ): ModalResult<ActivityModalResult> {
        return this.modalCreator.createModal(
            'app/modals/activityModal/activityModal.html', 
            'ActivityModalController', 
            ModalSize.Large, 
            { 
                activityId: activityId, 
                customerId: customerId, 
                bondId: bondId, 
                bondAccountId: bondAccountId, 
                eProducerAccountId: eProducerAccountId, 
                applicationId: applicationId, 
                defaultOptions: defaultOptions 
            } as ActivityModalOptions
        );
    }

    public showSendBillingReportModal(
        billingEntryIds: number[], 
        bondTransactionIds: number[],
        hasIncompleteEntries: boolean, 
        hasDuplicateEntries: boolean
    ): ModalResult<SendEpicBillingReportModalResult> {
        return this.modalCreator.createModal(
            'app/modals/sendBillingReportModal/sendBillingReportModal.html', 
            'SendBillingReportModal',
            ModalSize.Small, 
            {
                billingEntryIds: billingEntryIds, 
                bondTransactionIds: bondTransactionIds,
                hasIncompleteEntries: hasIncompleteEntries,
                hasDuplicateEntries: hasDuplicateEntries
            }
        );
    }

    public showNewInvoiceModal(customerId: number, eProducerAccountId: number): ModalResult<NewInvoiceModalResult> {
        return this.modalCreator.createModal(
            'app/modals/newInvoiceModal/newInvoiceModal.html', 
            'NewInvoiceModalController', 
            ModalSize.Large, 
            { 
                customerId: customerId,
                eProducerAccountId: eProducerAccountId 
            }
        );
    }

    public showBondAccountModal(customerId: number, bondAccountId?: number): ModalResult<BondAccountModalResult> {
        return this.modalCreator.createModal(
            'app/modals/bondAccountModal/bondAccountModal.html', 
            'BondAccountModalController', 
            ModalSize.Large, 
            { 
                customerId: customerId, 
                bondAccountId: bondAccountId 
            } as BondAccountModalOptions
        );
    }

    public showBondAccountAssignmentModal(bondIds: number[]): ModalResult<BondAccountAssignmentModalResult> {
        return this.modalCreator.createModal(
            'app/modals/bondAccountAssignmentModal/bondAccountAssignmentModal.html', 
            'BondAccountAssignmentModalController', 
            ModalSize.Small, 
            { bondIds: bondIds } as BondAccountAssignmentModalOptions
        )
    }

    public showRenewalModal(bondIds: number[]): ModalResult<RenewalModalResult> {
        return this.modalCreator.createModal(
            'app/modals/renewalModal/renewalModal.html', 
            'RenewalModalController', 
            ModalSize.Large, 
            { bondIds: bondIds } as RenewalModalOptions
        );
    }

    public showGrantCustomerEproducerAccessModal(customerId: number): ModalResult<object> {
        return this.modalCreator.createModal(
            'app/modals/grantCustomerEproducerAccessModal/grantCustomerEproducerAccessModal.html', 
            'GrantCustomerEproducerAccessModalController', 
            ModalSize.Large, 
            { customerId: customerId }
        );
    }

    public showCustomerRelaionshipsModal(customerId: number): ModalResult<RelatedCustomersModalResult> {
        return this.modalCreator.createModal(
            'app/modals/relatedCustomersModal/relatedCustomersModal.html', 
            'RelatedCustomersModalController', 
            ModalSize.Large, 
            { customerId: customerId }
        );
    }

    public showInvoiceDetailModal(invoiceId: number): ModalResult<any> {
        return this.modalCreator.createModal(
            'app/modals/invoiceModal/invoiceModal.html',
            InvoiceModalController,
            ModalSize.Large,
            { invoiceId: invoiceId }
        );
    }

    public showQuoteDocumentsModal(quoteId: number): ModalResult<QuoteDocumentsModalResult> {
        return this.modalCreator.createModal(
            'app/modals/quoteDocumentsModal/quoteDocumentsModal.html',
            QuoteDocumentsModalController,
            ModalSize.XLarge,
            { quoteId: quoteId },
            'full-screen'
        );
    }
    
    public showAttachmentViewerModal(attachmentId: number): ModalResult<AttachmentModalResult> {
        return this.modalCreator.createModal(
            'app/modals/attachmentViewerModal/attachmentViewerModal.html',
            AttachmentViewerModalController,
            ModalSize.XLarge,
            { attachmentId: attachmentId },
            'full-screen'
        );
    }

    public showBondTypeSelectionModal(): ModalResult<BondTypeSelectionModalResult> {
        return this.modalCreator.createModal(
            'app/modals/bondTypeSelectionModal/bondTypeSelectionModal.html',
            BondTypeSelectionModalController,
            ModalSize.Large
        );
    }
}

app.service(Injectables.ModalOpener, ModalOpener);
 