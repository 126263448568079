import { IAttributes, IController, IScope } from "angular";
import * as moment from "moment";
import { ApplicationFollowUp } from "../../api/types/model/applicationFollowUp";
import app from "../../main";
import isDefined from "../../utilities/angularUtilities/isDefined";

class FollowUpStatusController {

    public static $inject = [];

    constructor() { }

    public followUp: ApplicationFollowUp;

    public get sendDate(): string {
        if (!this.followUp || !this.followUp.sendDateTime) {
            return '';
        }

        let dateTime;

        if (typeof this.followUp.sendDateTime === 'string') {
            dateTime = moment.utc(this.followUp.sendDateTime, moment.ISO_8601);
        } else {
            dateTime = moment.utc(this.followUp.sendDateTime.toISOString(), moment.ISO_8601);
        }

        return dateTime.format('ddd MMM DD [at] h:mm A [(UTC)]');
    }

    public $onInit(): void { }
}

const followUpListDirective = () => {
    return {
        restrict: 'E',
        templateUrl: 'app/components/followUpStatus/followUpStatus.html',
        scope: {},
        transclude: true,
        bindToController: { followUp: '=' },
        link: (
            scope: IScope, 
            elm: JQuery, 
            attrs: IAttributes, 
            ctrl: IController
        ) => {
            if (isDefined(attrs.statusOnly)) {
                ctrl['statusOnly'] = true;
            }
        },
        controller: FollowUpStatusController,
        controllerAs: 'vm'
    };
};

app.directive('followUpStatus', followUpListDirective);
