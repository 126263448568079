import { EProducerAccount } from "./eProducerAccount";
import { IBaseEntity } from "./iBaseEntity";

export interface EProducerUser extends IBaseEntity { 
    eProducerAccountId: number;
    email: string;
    eProducerAccount: EProducerAccount;
    fax: string;
    firstName: string;
    jobTitle: string;
    lastLoginDate: Date;
    lastName: string;
    password: string;
    passwordHash: string;
    passwordResetKey: string;
    phone: string;
    phoneExtension: string;
    secondaryPhone: string;
    secondaryPhoneExtension: string;
    additionalComments: string;
    id: number;
    createdDateTime: Date;
}


export interface IEventTrackable extends IBaseEntity { 
}
