import { DocumentCategory } from "../../api/types/model/documentCategory";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { DocumentLibraryUploadCategorySelectionOptions } from "./DocumentLibraryUploadCategorySelectionOptions";

export class DocumentLibrarySelectCategoryModalController {

    public static $onInit = [
        Injectables.$uibModalInstance,
        Injectables.Options
    ];

    constructor(
        private readonly $uibModalInstance: Modal<null>,
        private readonly options: DocumentLibraryUploadCategorySelectionOptions
    ) {}

    public selectedUploadCategory: DocumentCategory;
    public setUploadCategory: (category: DocumentCategory) => void;

    public selectCategory = (category: DocumentCategory) => {
        this.selectedUploadCategory = category;
    }

    public upload = () => {
        this.options.proceed();
        this.setUploadCategory(this.selectedUploadCategory);
        this.options.dropzone.processQueue();
        this.$uibModalInstance.close(null);
    };

    public cancel = () => {
        this.$uibModalInstance.dismiss('cancel');
        this.options.dropzone.removeAllFiles();
    };

    public $onInit = () => {
        this.selectedUploadCategory = this.options.documentCategories[0];
        this.setUploadCategory = this.options.setUploadCategory;
    }
}

app.controller('DocumentLibrarySelectCategoryModalController', DocumentLibrarySelectCategoryModalController);
