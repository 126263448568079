import { IJQueryService } from "../../../utilities/jquery/iJQueryService";
import fieldGroupWrapper from "../fieldGroupWrapper";

export default (element: JQuery, jQueryProvider: IJQueryService) => {
    element.attr('type', 'checkbox');
    
    var fieldGroupClass = element.attr('aa-fg-class');
    var ngshow = element.attr('ng-show') || '';

    if (ngshow.length > 0) {
        ngshow = 'ng-show="' + ngshow + '"';
    }

    element.attr('style', 'display:none;');

    element.attr('aa-val-msg', 'termsCheckValidation');
    element.attr('aa-label-strategy', 'checkBoxLabel');

    var wrapper = '<div class="field-wrapper placeholder checkbox ' + fieldGroupClass + '" ' + ngshow + '"><label><input/><i class="fa fa-check" ng-class="{\'on\': ' + element.attr('aa-field') + ' }"></i><span></span></label></div>';
    
    fieldGroupWrapper(element, wrapper, jQueryProvider);
};