import { CommissionType } from "../../api/types/model/commissionType";
import { Fee } from "../../api/types/model/fee";

export interface RenewalModalSubmission {
    bondId: number;
    effectiveDate: Date;
    expirationDate: Date;
    isBondAccountPremium: boolean;
    premium: number;
    commissionPercent: number;
    commission: number;
    commissionType: CommissionType;
    fees: Fee[];
}