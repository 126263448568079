import * as angular from "angular";
import { IPromise, IRootScopeService, IScope } from "angular";
import { AuthService } from "../../../api/authService";
import { CurrentUser } from "../../../api/types/authentication/currentUser";
import { User } from "../../../api/types/model/user";
import { UserService } from "../../../api/userService";
import { constants } from "../../../configuration/constants";
import { Injectables } from "../../../configuration/injectables";
import { ModalOpener } from "../../../modals/modalOpener";
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";
import { JQueryService } from "../../../utilities/jquery/jQueryService";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";

export class UserProfileController {

    public static $inject = [
        Injectables.$scope,
        Injectables.$rootScope,
        Injectables.CurrentUserResolver,
        Injectables.AuthService,
        Injectables.ModalOpener,
        Injectables.UserService,
        Injectables.ToastMessageCreator,
        Injectables.JQueryService
    ];

    constructor(
        private readonly $scope: IScope,
        private readonly $rootScope: IRootScopeService,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly authService: AuthService,
        private readonly modalOpener: ModalOpener,
        private readonly userService: UserService,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly jQueryService: JQueryService
    ) {}

    public croppedImage: string;
    public currentFile: File;
    public currentUser: CurrentUser;
    public hasImage: boolean;
    public isProfilePictureChanged: boolean;
    public isProfilePictureRemoved: boolean;
    public loadingMaskDefer: any;
    public processingPromise: IPromise<any>;
    public unknownProfilePicture: string;
    public user:  User;
    public userProfilePicture: string;

    public downloadUserProfileImage(): IPromise<void> {
        return this.userService.downloadUserProfileImage()
            .then((image) => {
                this.userProfilePicture = image;
                this.hasImage = !!image;
            });
    }

    public handleFileSelect(evt: any): void {
        this.isProfilePictureChanged = true;
        this.currentFile = evt.currentTarget.files[0];

        const reader = new FileReader();
        reader.onload = (event: any) => {

            this.$scope.$apply(() => {
                this.userProfilePicture = event.target.result;
            });
        };

        if (this.currentFile != null) {
            reader.readAsDataURL(this.currentFile);
        }
    }

    public loadUserData(): void {
        this.processingPromise = this.userService
            .getUser(this.currentUserResolver.getCurrentUser().user.userId)
            .then((user) => {
                this.user = user;
                this.currentUser.user.theme = this.currentUser.user.theme || 'a3';
                this.user.theme = this.currentUser.user.theme;
            })
            .catch((error) => {
                this.toastMessageCreator.createErrorMessage('Error Loading User');
            });
    }

    public promptChangePassword(): void {
        this.modalOpener.changePasswordModal()
            .result
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Your password has been updated successfully');
            })
            .catch(() => {});
    }

    public promptRemoveProfileImage(): void {
        this.modalOpener.confirmModal('Are you sure?', 'Are you sure you want to remove your profile picture?', 'Yes', 'No')
            .result
            .then(() => {
                this.removeProfileImage();
            })
            .catch(() => {
                if (this.loadingMaskDefer) {
                    this.loadingMaskDefer.resolve();
                }
            });
    }

    public removeProfileImage(): void {
        this.processingPromise = this.userService.deleteCurrentUserProfilePicture()
            .then(() => {
                this.user.profilePictureFileId = null;
                this.hasImage = false;
                this.userProfilePicture = null;
                this.isProfilePictureChanged = false;
                this.isProfilePictureRemoved = true;
                this.$rootScope.$broadcast('profileImageUpdated');
            });
    }

    public save(): void {
        // Update user information & profile image
        this.processingPromise = this.userService.saveOwnUserProfile(this.user)
            .then(() => {
                this.currentUserResolver.getCurrentUser().user.firstName = this.user.firstName;
                this.currentUserResolver.getCurrentUser().user.lastName = this.user.lastName;
                this.currentUserResolver.getCurrentUser().user.fullName = this.user.firstName + ' ' + this.user.lastName;
                this.currentUserResolver.getCurrentUser().user.theme = this.user.theme;
                this.authService.setTheme(this.user.theme);

                this.user.fullName = this.currentUserResolver.getCurrentUser().user.fullName;

                if (this.isProfilePictureChanged) {
                    this.processingPromise = this.userService.uploadUserProfileImage(this.croppedImage, (this.jQueryService.getElement('#fileInput').get(0) as any).files[0])
                        .then((response) => {
                            this.isProfilePictureChanged = false;
                            this.processingPromise = this.downloadUserProfileImage();
                            this.$rootScope.$broadcast('profileImageUpdated', response);
                        })
                        .catch((response) => {
                            this.toastMessageCreator.createErrorMessage(response);
                        });
                }

                angular.element(document.querySelector('#fileInput')).val(null);
                this.toastMessageCreator.createSuccessMessage('User saved successfully');
            });
    }

    public $onInit(): void {
        // wire file input change event to angular controller function
        angular.element(document.querySelector('#fileInput')).on('change', this.handleFileSelect.bind(this));

        this.isProfilePictureChanged = false;
        this.unknownProfilePicture = constants.unknownProfilePicture;
        this.isProfilePictureRemoved = false;
        this.currentUser = this.currentUserResolver.getCurrentUser();

        this.loadUserData();

        this.downloadUserProfileImage();
    }
}