import { GridsterOptionsDraggable } from "./gridsterOptionsDraggable";
import { GridsterOptionsResizable } from "./gristerOptionsResizable";

export type GridsterOptions = {
    minRows?: number;
    maxRows?: number;
    columns?: number;
    colWidth?: string;
    margins?: number[];
    defaultSizeX: number;
    defaultSizeY: number;
    mobileBreakPoint: number;
    resizable: GridsterOptionsResizable;
    draggable: GridsterOptionsDraggable;
}