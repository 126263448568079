import { SelectOption } from "../../../../api/types/selectOption";
import app from "../../../../main";
import { InvoiceReportItem } from "../invoiceReportItem";
import isUndefinedOrNull from "../../../../utilities/angularUtilities/isUndefinedOrNull";
import { Table } from "../../../../utilities/tables/table";
import { InvoiceReportFilterOptions } from "./invoiceReportFilterOptions";

class InvoiceReportFilterDropdownController {
    public static $inject = [];

    constructor() {
        this.modifiedFilters = {} as InvoiceReportFilterOptions;
    }

    public isOpen: boolean;
    public filterCount = 0;
    public carrierOptions: SelectOption<number>[];
    public modifiedFilters: InvoiceReportFilterOptions;

    public table: Table<InvoiceReportItem, InvoiceReportFilterOptions>;

    public clearFilters() {
        this.modifiedFilters.status = null;
        this.modifiedFilters.minimumAmount = null;
        this.modifiedFilters.maximumAmount = null;
        this.modifiedFilters.startDate = null;
        this.modifiedFilters.endDate = null;
        
        this.table.applyFilters({ ...this.modifiedFilters });

        this.setFilterCount();
        this.isOpen = false;
    }

    public cancel() {
        this.isOpen = false;
        this.modifiedFilters = { ...this.table.queryOptions };
    }

    public applyFilters() {
        this.isOpen = false;
        this.table.applyFilters({ ...this.modifiedFilters });
        this.setFilterCount();
    }

    private setModifiedFilters = () => {
        this.modifiedFilters.status = this.table.queryOptions.status;
        this.modifiedFilters.minimumAmount = this.table.queryOptions.minimumAmount;
        this.modifiedFilters.maximumAmount = this.table.queryOptions.maximumAmount;
        this.modifiedFilters.startDate = this.table.queryOptions.startDate;
        this.modifiedFilters.endDate = this.table.queryOptions.endDate;

        this.setFilterCount();
    };

    public setFilterCount() {
        this.filterCount = 0;

        if (!isUndefinedOrNull(this.table.queryOptions.status)) {
            this.filterCount++;
        }

        if (
            this.table.queryOptions.minimumAmount ||
            this.table.queryOptions.maximumAmount
        ) {
            this.filterCount++;
        }

        if (
            this.table.queryOptions.startDate ||
            this.table.queryOptions.endDate
        ) {
            this.filterCount++;
        }
    }

    public $onInit() {
        this.setModifiedFilters();
    }
}

const invoiceReportFilterDropdownComponent = {
    bindings: {
        table: "<"
    },
    controller: InvoiceReportFilterDropdownController,
    controllerAs: "vm",
    templateUrl:
        "app/states/agentSpecific/invoices/invoiceReportFilterDropdown/invoiceReportFilterDropdown.html"
};

app.component(
    "invoiceReportFilterDropdown",
    invoiceReportFilterDropdownComponent
);
