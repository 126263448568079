import { ISCEService, IScope, IAttributes } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";

const contentEditableDirective = ($sce: ISCEService) =>({
    restrict: 'A',
    require: '?ngModel',
    link: (scope: IScope, element: JQuery, attrs: IAttributes, ctrl: any) => {
        if (!ctrl) {
            return;
        }

        ctrl.$render = function() {
            element.html($sce.getTrustedHtml(ctrl.$viewValue || ''));
        }

        element. on('blur keyup change', () => {
            scope.$evalAsync(read);
            read();
        });
        

        function read() {
            var html = element.html();

            ctrl.$setViewValue(html);
        }
    }
});

contentEditableDirective.$inject = [Injectables.$sce];

app.directive('contenteditable', contentEditableDirective);