import { ActivityStatus } from "../../api/types/activities/activityStatus";
import { TableQueryOptions } from "../../api/types/tableQuery";

export interface ActivityTableQueryOptions extends TableQueryOptions {
    status?: ActivityStatus;
    lookupCode?: string;
    createdByUserId?: number;
    assignedToUserId?: number;
    isFollowed?: boolean;
    activityTypeId?: number;
    daysTillDue?: number;
    lowPriorityLevel?: number;
    highPriorityLevel?: number;
}