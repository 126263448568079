export interface PermissionsLocalStorage {
    canDeleteFromDocumentLibrary: boolean;
    canEditBondTypes: boolean;
    canEditDocumentLibrary: boolean;
    canEditObligees: boolean;
    canEditSystemAccounts: boolean;
    canEditUserGroups: boolean;
    canEditUsers: boolean;
    createdDateTime: Date;
    canEditActivitySettings: boolean;
    canEditApiConfiguration: boolean;
    canReturnPayments: boolean;
    canEditBillingEntriesReport: boolean;
}