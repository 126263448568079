import { ApplicationDocumentVersion } from "./applicationDocmentVersion";
import { BondTransactionDocumentVersion } from "./bondTransactionDocumentVersion";
import { IBaseEntity } from "./iBaseEntity";
import { QuoteDocumentVersion } from "./quoteDocumentVersion";
import { SystemAccount } from "./systemAccount";
import { User } from "./user";

export interface DocumentVersion extends IBaseEntity { 
    applicationDocumentVersions: ApplicationDocumentVersion[];
    bondTransactionDocumentVersions: BondTransactionDocumentVersion[];
    document: Document;
    documentId: number;
    file: File;
    fileId: number;
    fileSize: number;
    initialVersion: boolean;
    quoteDocumentVersions: QuoteDocumentVersion[];
    systemAccount: SystemAccount;
    systemAccountId: number;
    thumbnailFile: File;
    thumbnailFileId: number;
    user: User;
    userId: number;
    id: number;
    createdDateTime: Date;
}