import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { State } from "../../states/state";
import { ModalOpener } from "../modalOpener";
import { ApplicationDetailOptions } from "./applicationDetail";
import { ApplicationDetailResult } from "./ApplicationDetailResult";
import app from "../../main";
import { ApplicationService } from "../../api/applicationService";
import { ActivityService } from "../../api/activityService";
import { AttachmentService } from "../../api/attachmentService";
import { CreditReportService } from "../../api/creditReportService";
import { FollowUpService } from "../../api/followUpService";
import { ObligeeService } from "../../api/obligeeService";
import { SystemAccountService } from "../../api/systemAccountService";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";
import { DebouceDelayer } from "../../utilities/debounceDelayer/debouceDelayer";
import { Obligee } from "../../api/types/model/obligee";
import { Customer } from "../../api/types/model/customer";
import { ApplicationFollowUp } from "../../api/types/model/applicationFollowUp";
import { ApplicationDetail, ApplicationStatus, ApplicationType } from "../../api/types/applicationDetail";
import { IPromise } from "angular";
import { constants } from "../../configuration/constants";
import QuoteListItem from "../../components/quoteList/quoteListItem";
import { Person } from "../../api/types/model/person";
import { Memo } from "../../api/types/model/memo";
import { Company } from "../../api/types/model/company";
import { CreditReport } from "../../api/types/model/creditReport";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { ActivityListItem } from "../../components/activityList/activityListItem";
import { AttachmentTableListItem } from "../../components/attachmentList/attachmentTableListItem";
import { Table } from "../../utilities/tables/table";
import { AttachmentTableQueryOptions } from "../../components/attachmentList/attachmentTableQueryOptions";
import { ActivityTableQueryOptions } from "../../components/activityList/activityTableQueryOptions";
import { MemoService } from "../../api/memoService";
import DropdownOption from "../../api/types/dropdownOption";
import MemoPageRequest from "../../api/types/memoPageRequest";

export class ApplicationDetailModalController {
    
    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.ModalOpener,
        Injectables.$state,
        Injectables.ApplicationService,
        Injectables.SystemAccountService,
        Injectables.CurrentUserResolver,
        Injectables.FollowUpService,
        Injectables.MemoService,
        Injectables.CreditReportService,
        Injectables.IDebouceDelayer,
        Injectables.AttachmentService,
        Injectables.ActivityService,
        Injectables.ObligeeService
    ];

    constructor(
        private readonly $uibModalInstance: Modal<ApplicationDetailResult>,
        private readonly options: ApplicationDetailOptions,
        private readonly modalOpener: ModalOpener,
        private readonly $state: State,
        private readonly applicationService: ApplicationService,
        private readonly systemAccountService: SystemAccountService,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly followUpService: FollowUpService,
        private readonly memoService: MemoService,
        private readonly creditReportService: CreditReportService,
        debounceDelayer: DebouceDelayer,
        private readonly attachmentService: AttachmentService,
        private readonly activityService: ActivityService,
        private readonly obligeeService: ObligeeService
    ) {

        this.memosTable = new Table(debounceDelayer);
        this.memosTable.onChange = this.loadMemos;

        this.attachmentsTable = new Table(debounceDelayer);
        this.attachmentsTable.onChange = this.loadAttachments;

        this.activitiesTable = new Table(debounceDelayer);
        this.activitiesTable.onChange = this.loadActivities;
    }

    public applicationId: number;
    public customerId: number;

    public memosTable: Table<Memo, MemoPageRequest>;
    public attachmentsTable: Table<AttachmentTableListItem, AttachmentTableQueryOptions>;
    public activitiesTable: Table<ActivityListItem, ActivityTableQueryOptions>;

    public busyIndicator: BusyIndicator;
    public principalPromise: IPromise<any>;
    public followUpPromise: IPromise<any>;
    public memoPromise: IPromise<any>;
    public applicationDetail: ApplicationDetail;
    public isCarrier: boolean;
    public isDeclinedAdverseAction: boolean;
    public isDeclined: boolean;
    public isDisabled: boolean;
    public activeTab: number;
    public followUpsLoaded: boolean;
    public quotesPromise: IPromise<any>;
    public quotesLoaded: boolean;
    public creditReports: CreditReport[];
    public creditReportsPromise: IPromise<any>;
    public logoImg: string;
    public attachmentsLoaded: boolean;
    public attachmentsPromise: IPromise<any>;
    public activitiesLoaded: boolean;
    public activityPromise: IPromise<any>;
    public quotes: QuoteListItem[];
    public obligeeLoaded: boolean;
    public obligee: Obligee;
    public people: Person[];
    public companies: Company[];
    public applicationFollowUps: ApplicationFollowUp[];
    private principalLoaded: boolean;
    private creditReportsLoaded: boolean;
    private memosLoaded: boolean;
    public actionDropdownOptions: DropdownOption[];

    public quotePurchased(bondId: number): void {
        this.$uibModalInstance.close(bondId);
        this.modalOpener.bondDetailModal(bondId)
            .result
            .then(function(){})
            .catch(function(){});

        if (this.$state.current.name === 'main.customerDetail') {
            this.$state.go(
                'main.customerDetail', 
                { 
                    customerId: this.customerId, 
                    sectionView: 'bonds',
                    applicationId: null
                }
            );    
        } else {
            this.$state.reload();
        }
    }

    public cancel(): void{
        this.$uibModalInstance.dismiss();
    }

    public goToCustomerDetail(): void{
        this.$state.go(
            'main.customerDetail', 
            { 
                customerId: this.customerId, 
                sectionView: 'applications' 
            }
        );
        this.$uibModalInstance.close(null);
    }
    
    public get canBeClosed(): boolean {
        return this.applicationDetail && 
            this.applicationDetail.applicationType === ApplicationType.SingleBond &&
            this.applicationDetail.status != ApplicationStatus.Closed &&
            this.applicationDetail.status != ApplicationStatus.Completed;
    }

    public get canBeReOpened(): boolean {
        return this.applicationDetail &&
            this.applicationDetail.applicationType === ApplicationType.SingleBond &&
            this.applicationDetail.status == ApplicationStatus.Closed
    }

    public downloadApplicationDocument = () => {
        this.busyIndicator.message = 'Downloading...';
        this.busyIndicator.promise = this.applicationService.downloadApplicationDocument(this.applicationId);
    }

    public downloadAdverseActionNotificationDocument = () => {
        this.busyIndicator.message = 'Downloading...';
        this.busyIndicator.promise = this.applicationService.downloadAdverseActionNotificationDocument([this.applicationId]);
    }

    public setStatusNew(application: ApplicationDetail, applicationStatus?: ApplicationStatus): void {
        if (applicationStatus) {
            application.status = applicationStatus;
        }

        this.isDisabled = false;
        this.isDeclined = false;
        this.isDeclinedAdverseAction = false;

        if (this.isCarrier) {
            this.isDisabled = true;
            if ( application.isDeclinedByCurrentCarrier || application.isAdverseActionByCurrentCarrier) {
                this.isDeclined = true;
                if ( application.isAdverseActionByCurrentCarrier)
                {
                    this.isDeclinedAdverseAction = true;
                }
            }   
        } 

        if (application.status === constants.applicationStatus.closed ||
            application.status === constants.applicationStatus.completed) {
            this.isDisabled = true;
        }
    }    

    public refresh = (): void => {
        this.principalLoaded = false;
        this.obligeeLoaded = false;
        this.creditReportsLoaded = false;
        this.attachmentsLoaded = false;
        this.quotesLoaded = false;
        this.followUpsLoaded = false;
        this.memosLoaded = false;
        this.activitiesLoaded = false;
        
        this.loadApplication();
        
        switch(this.activeTab) {
            case 1:
                this.loadPrincipal();
                break;
            case 2:
                this.loadObligee();
                break;
            case 3:
                this.loadCreditReports();
                break;
            case 4:
                this.loadAttachments();
                break;
            case 5:
                this.loadQuotes();
                break;
            case 6:
                this.loadFollowUps();
                break;
            case 7:
                this.loadMemos();
                break;
            case 8:
                this.loadActivities();
                break;
        }
    }

    public showEmailModal = () => {
        this.modalOpener.emailApplicationModal(this.applicationId)
            .result
            .then(function(){})
            .catch(function(){});
    }

    public showOpenConfirmation = () => {
        this.busyIndicator.message = 'Processing...';
        this.busyIndicator.promise = this.modalOpener.confirmModal('Open Application', 'Are you sure you want to reopen this application?', 'Reopen', 'Cancel')
            .result
            .then(() => this.handleOpenApplicationResponse())
            .catch(() => {});
    }

    public handleOpenApplicationResponse(): IPromise<any> {
        return this.applicationService.openApplication(this.applicationId)
            .then(() => {
                this.setStatusNew(this.applicationDetail, ApplicationStatus.NotSubmitted);
                this.createActionMenuOptions();
            });
    }

    public showCloseConfirmation = () => {
        this.busyIndicator.message = 'Processing...';
        this.busyIndicator.promise = this.modalOpener.closeApplicationModal(this.applicationId)
            .result
            .then(() => this.handleCloseApplicationResponse())
            .catch(() => {});
    }

    public showDeclineConfirmation = () => {
        this.busyIndicator.message = 'Processing...';
        this.busyIndicator.promise = this.modalOpener.declineApplicationModal(this.applicationId)
            .result
            .then(() => this.handleDeclineApplicationResponse())
            .catch(() => {});
    }

    public showReconsiderConfirmation = () => {
        this.busyIndicator.message = 'Processing...';
        this.busyIndicator.promise = this.modalOpener.reconsiderApplicationModal(this.applicationId)
            .result
            .then(() => this.handleReconsiderApplicationResponse())
            .catch(() => {});
    }

    public handleCloseApplicationResponse(): void {
        this.setStatusNew(this.applicationDetail, ApplicationStatus.Closed);
        this.createActionMenuOptions();
    }

    public handleDeclineApplicationResponse(): void {
        this.loadApplication();
    }

    public handleReconsiderApplicationResponse(): void {
        this.loadApplication();
    }

    public quotesUpdated(): void {
        this.setStatusNew(this.applicationDetail);

        this.quotesLoaded = false;
        this.loadQuotes();
    }

    public loadObligee(): void {
        if(this.obligeeLoaded) {
            return;
        }
        
        this.obligeeService.getObligeeDetail(this.applicationDetail.obligeeId)
            .then((obligee:  Obligee) => {
                this.obligee = obligee;
                this.obligeeLoaded = true;
            });
    }

    public loadPrincipal(): void {
        if (this.principalLoaded) {
            return;
        }

        this.principalPromise = this.applicationService
            .getPrincipal(this.applicationId)
            .then((principal: Customer) => {
                this.principalLoaded = true;
                this.people = principal.people;
                this.companies = principal.companies;
            });
    }

    public loadFollowUps(): void {
        if (this.followUpsLoaded) {
            return;
        }

        this.followUpPromise = this.followUpService.getByApplicationId(this.applicationId)
            .then( (followUps: ApplicationFollowUp[]) => {
                this.applicationFollowUps = followUps;
                this.followUpsLoaded = true;
            });
    }

    public loadMemos = () => {
        this.memosTable.queryOptions.applicationId = this.options.applicationId;

        this.memoPromise = this.memoService.getMemos(this.memosTable.queryOptions)
            .then((response) => {
                this.memosTable.setData(response.items, response.totalRecordCount);
                this.memosLoaded = true;
            });
    }

    public memosTabSelected = () => {
        if (this.memosLoaded) {
            return;
        }

        this.loadMemos();
    }

    public loadQuotes(): void {
        if (this.quotesLoaded) {
            return;
        }

        this.quotesPromise = this.applicationService.getQuotes(this.applicationId)
            .then((quotes) => {
                this.quotes = quotes;
                this.quotesLoaded = true;
        });
    }

    public loadCreditReports(): void {
        if (this.creditReportsLoaded) {
            return;
        }

        this.creditReportsPromise = this.creditReportService.getCreditReportsByApplicationIdNew(this.applicationId)
            .then((response) => {
                this.creditReportsLoaded = true;
                this.applicationDetail.creditReportPersonReport = response; 
            }); 
    }

    public loadApplication(): void {
        this.busyIndicator = {
            message: 'Loading...',
            promise: this.applicationService.getSingleApplicationDetail(this.applicationId)
            .then((application: ApplicationDetail) => {
                this.applicationDetail = application;
                this.setStatusNew(application);

                // AGENCY USER
                if (!this.isCarrier) {
                    this.customerId = this.applicationDetail.customerId;
                } else {
                    // CARRIER User
                    this.systemAccountService.getSmallLogo(this.applicationDetail.systemAccountId).then((img: string) => {
                        this.logoImg = img;
                    });
                }

                this.createActionMenuOptions();
            })
        } ;
    }

    public attachmentsTabSelected(): void {
        if (this.attachmentsLoaded) {
            return;
        }

        this.loadAttachments();
    }

    public loadAttachments = (): void  => {
        this.attachmentsPromise = this.attachmentService.getApplicationAttachments(
                this.applicationId,
                this.attachmentsTable.queryOptions
            )
            .then((response) => {
                this.attachmentsTable.setData(response.items, response.totalRecordCount);
                this.attachmentsLoaded = true;
            });
    }

    public activityTabSelected(): void {
        if (this.activitiesLoaded) {
            return;
        }

        this.loadActivities();
    }

    public loadActivities = (): void => {
        this.activityPromise = this.activityService.getApplicationActivities(
            this.applicationId,
            this.activitiesTable.queryOptions
        )
        .then((response) => {
            this.activitiesTable.setData(response.items, response.totalRecordCount);
            this.activitiesLoaded = true;
        });
    }

    private editApplicationClicked = () => {
        this.$state.go('main.application', {
            applicationId: this.applicationId,
            appType: this.applicationDetail.applicationType, 
            customerId: this.applicationDetail.customerId
        });

        this.$uibModalInstance.dismiss();
    }

    private createActionMenuOptions = () => {
        this.actionDropdownOptions = [
            // Agency user actions
            {
                label: 'Download Application',
                onClick: this.downloadApplicationDocument,
                isHidden: this.isCarrier,
            },
            {
                label: 'Edit Application',
                onClick: this.editApplicationClicked,
                isDisabled: this.applicationDetail.applicationType != ApplicationType.SingleBond || this.applicationDetail.status == ApplicationStatus.Closed || this.applicationDetail.status == ApplicationStatus.Completed,
                isHidden: this.isCarrier,
            },
            {
                label: 'Send Email',
                onClick: this.showEmailModal,
                isDisabled: this.applicationDetail.status == ApplicationStatus.Closed || this.applicationDetail.status == ApplicationStatus.Completed,
                isHidden: this.isCarrier
            },
            {
                label: 'Close Application',
                onClick: this.showCloseConfirmation,
                isHidden: this.isCarrier || !this.canBeClosed
            },
            {
                label: 'Re-open Application',
                onClick: this.showOpenConfirmation,
                isHidden: this.isCarrier || !this.canBeReOpened
            },
            // Carrier user actions
            {
                label: 'Decline Application',
                onClick: this.showDeclineConfirmation,
                isHidden: !this.isCarrier || this.isDeclined
            },
            {
                label: 'Reconsider Application',
                onClick: this.showReconsiderConfirmation,
                isHidden: !this.isCarrier || !this.isDeclined
            },
            {
                label: 'Advers Action PDF',
                onClick: this.downloadAdverseActionNotificationDocument,
                isDisabled: !this.isDeclinedAdverseAction,
                isHidden: !this.isCarrier
            },
        ];
    }

    public $onInit(): void {
        this.applicationId = this.options.applicationId;
        
        this.busyIndicator = {};

        this.isCarrier = this.currentUserResolver.getCurrentUser().systemAccount.isCarrier;
        this.isDisabled = true;

        this.loadApplication();
    }
}

app.controller('ApplicationDetailModalController', ApplicationDetailModalController);
