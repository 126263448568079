import * as angular from "angular";
import { ICompileService, IAttributes } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";


const boundGridDetailsTemplateCompiler = ($compile: ICompileService) => {

    const link = (
        $scope: any, 
        $element: JQuery, 
        $attrs: IAttributes) => {
        
            if ($scope.detailTemplate) {

            $scope.detailTemplate = $scope.detailTemplate.replace(/a3-/g, '');

            var el = angular.element('<span>' + $scope.detailTemplate + '</span>');
            $compile(el)($scope);
            $element.append(el);
        }
    }

    const controller = ($scope: any) => {
        $scope.callFunction = function(fn, value) {
            return this.$parent.$parent.$parent.$parent.$parent[fn](value);
        }
    }

    controller.$inject = [Injectables.$scope];

    return {
        link: link,
        controller: controller
    }
}

boundGridDetailsTemplateCompiler.$inject = [Injectables.$compile];

app.directive('boundGridDetailsTemplateCompiler', boundGridDetailsTemplateCompiler);