import { IJQueryService } from "../../../utilities/jquery/iJQueryService";
import defaultWrapper from "../defaultWrapper";
import fieldGroupWrapper from "../fieldGroupWrapper";

export default (element: JQuery, jQueryProvider: IJQueryService) => {
    var stateOptions = '<option value="PT0H">12:00 AM</option>' +
        '<option value="PT1H">1:00 AM</option>' +
        '<option value="PT2H">2:00 AM</option>' +
        '<option value="PT3H">3:00 AM</option>' +
        '<option value="PT4H">4:00 AM</option>' +
        '<option value="PT5H">5:00 AM</option>' +
        '<option value="PT6H">6:00 AM</option>' +
        '<option value="PT7H">7:00 AM</option>' +
        '<option value="PT8H">8:00 AM</option>' +
        '<option value="PT9H">9:00 AM</option>' +
        '<option value="PT10H">10:00 AM</option>' +
        '<option value="PT11H">11:00 AM</option>' +
        '<option value="PT12H">12:00 PM</option>' +
        '<option value="PT13H">1:00 PM</option>' +
        '<option value="PT14H">2:00 PM</option>' +
        '<option value="PT15H">3:00 PM</option>' +
        '<option value="PT16H">4:00 PM</option>' +
        '<option value="PT17H">5:00 PM</option>' +
        '<option value="PT18H">6:00 PM</option>' +
        '<option value="PT19H">7:00 PM</option>' +
        '<option value="PT20H">8:00 PM</option>' +
        '<option value="PT21H">9:00 PM</option>' +
        '<option value="PT22H">10:00 PM</option>' +
        '<option value="PT23H">11:00 PM</option>';

    element.append(stateOptions);

    var aaFgClass = element.attr('aa-fg-class') ? element.attr('aa-fg-class') + ' select' : 'select';
    element.attr('aa-fg-class', aaFgClass);

    fieldGroupWrapper(element, defaultWrapper(element), jQueryProvider);
};