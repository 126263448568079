import { InvoiceService } from "../../../api/invoiceService";
import { InvoiceStatus } from "../../../api/types/model/invoiceStatus";
import { Injectables } from "../../../configuration/injectables";
import app from "../../../main";
import { InvoiceReportItem } from "./invoiceReportItem";
import { IDebounceDelayer } from "../../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../../utilities/tables/table";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { InvoiceReportFilterOptions } from "./invoiceReportFilterDropdown/invoiceReportFilterOptions";

export class ReportInvoiceController {
    public static $inject = [
        Injectables.InvoiceService,
        Injectables.IDebouceDelayer
    ];

    constructor(
        private readonly invoiceService: InvoiceService,
        debouceDelayer: IDebounceDelayer
    ) {
        this.table = new Table(debouceDelayer);
        this.table.queryOptions.status = InvoiceStatus.Open;
        this.table.onChange = this.loadData;
    }

    public readonly table: Table<InvoiceReportItem, InvoiceReportFilterOptions>;
    public readonly filters: InvoiceReportFilterOptions;

    public exportInvoices() {
        this.busyIndicator = {
            message: "Loading...",
            promise: this.invoiceService.downloadInvoiceExcelReport(this.table.queryOptions)
        };
    }

    public getInvoiceDownloadUrl(id: number) {
        return this.invoiceService.getInvoiceDocumentUrl(id);
    }

    public readonly onTableChanged: (param: {
        table: Table<InvoiceReportItem, InvoiceReportFilterOptions>;
    }) => void;

    public readonly onTableReady: (param: {
        table: Table<InvoiceReportItem, InvoiceReportFilterOptions>;
    }) => void;

    public busyIndicator: BusyIndicator;

    public refresh = () => {
        this.loadData();
    };

    public loadData = () => {
        this.busyIndicator.message = "Loading Invoices...";
        this.busyIndicator.promise = this.invoiceService
            .getInvoiceReportData(this.table.queryOptions)
            .then((response) => {
                this.table.setData(response.items, response.totalRecordCount);
            });
    };

    public $onInit(): void {
        this.busyIndicator = {};

        if (this.filters) {
            this.table.applyFilters(this.filters);
        }

        this.loadData();
        this.table.onChange = () => {
            this.loadData();

            if (this.onTableChanged instanceof Function) {
                this.onTableChanged({ table: this.table });
            }
        };

        if (this.onTableReady instanceof Function) {
            this.onTableReady({ table: this.table });
        }
    }
}

const invoiceReportComponent = {
    bindings: {
        filters: "=?",
        onTableChanged: "&?",
        onTableReady: "&?"
    },
    controller: ReportInvoiceController,
    templateUrl: "app/states/agentSpecific/invoices/invoiceReport.html",
    controllerAs: "vm"
};

app.component("invoiceReport", invoiceReportComponent);
