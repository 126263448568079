import { IWindowService } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { JQueryService } from "../jquery/jQueryService";

export type SlabTextSettings = {
    maxFontSize?: number;
    minCharsPerLine?: number;
    viewportBreakpoint?: number;
    headerBreakpoint?: number;
    fontRatio?: number;
    forceNewCharCount?: boolean;
    wrapAmpersand?: boolean;
    biewpointBreakpoint?: number;
    noResizeEvent?: boolean;
    resizeThrottleTime?: number;
    maxFotSize?: number;
    postTweek?: boolean,
    precision?: number;
    postTweak?: boolean;
    onRender?: () => void;
}

export class SlabTextService {

    public static $inject = [
        Injectables.JQueryService,
        Injectables.$window
    ];

    constructor(
        private readonly jQueryService: JQueryService,
        private readonly $window: IWindowService
    ) {}

    private readonly defaultSettings = {
        // The ratio used when calculating the characters per line
        // (parent width / (font-size * fontRatio)).
        fontRatio: 0.78,
        // Always recalculate the characters per line, not just when the
        // font-size changes? Defaults to true (CPU intensive)
        forceNewCharCount: true,
        // Do we wrap ampersands in <span class="amp">
        wrapAmpersand: true,
        // Under what pixel width do we remove the slabtext styling?
        headerBreakpoint: null,
        viewportBreakpoint: null,
        // Don't attach a resize event
        noResizeEvent: false,
        // By many milliseconds do we throttle the resize event
        resizeThrottleTime: 300,
        // The maximum pixel font size the script can set
        maxFontSize: 999,
        // Do we try to tweak the letter-spacing or word-spacing?
        postTweak: true,
        // Decimal precision to use when setting CSS values
        precision: 3,
        // The min num of chars a line has to contain
        minCharsPerLine: 0,
        // Callback function fired after the headline is redrawn
        onRender: null
    };

    public setupSlabText = (element: JQuery, settings: SlabTextSettings) => {

        settings = { ...this.defaultSettings, ...settings };

        // Add the slabtexted classname to the body to initiate the styling of
        // the injected spans
        this.jQueryService.getElement("body").addClass("slabtexted");

        element.each(() => {

            const keepSpans = this.jQueryService.getElement("span.slabtext", element).length;

            const words = keepSpans ? [] : element
                .text()
                .trim()
                .replace(/\s{2,}/g, " ")
                .split(" ");

            let origFontSize = null;
            const idealCharPerLine = null;
            let resizeThrottle = null;
            let viewportWidth = this.$window.visualViewport.width;
            const headLink = element.find("a:first").attr("href") || element.attr("href");
            const linkTitle = headLink ? element.find("a:first").attr("title") : "";

            if (!keepSpans && settings.minCharsPerLine && words.join(" ").length < settings.minCharsPerLine) {
                return;
            };

            // Most of this function is a (very) stripped down AS3 to JS port of
            // the slabtype algorithm by Eric Loyer with the original comments
            // left intact
            // http://erikloyer.com/index.php/blog/the_slabtype_algorithm_part_1_background/
            var resizeSlabs = resizeSlabs = () => {

                // Cache the parent containers width
                let parentWidth = element.width();
                let fontSize: number;

                // Sanity check to prevent infinite loop
                if (parentWidth == 0) {
                    return;
                };

                // Remove the slabtextdone and slabtextinactive classnames to enable the inline-block shrink-wrap effect
                element.removeClass("slabtextdone slabtextinactive");

                if (
                        (
                             settings.viewportBreakpoint && 
                             settings.viewportBreakpoint > viewportWidth
                        ) ||
                        (
                            settings.headerBreakpoint && 
                            settings.headerBreakpoint > parentWidth
                        )
                    ) {

                    // Add the slabtextinactive classname to set the spans as inline
                    // and to reset the font-size to 1em (inherit won't work in IE6/7)
                    element.addClass("slabtextinactive");
                    return;
                };

                fontSize = this.grabPixelFontSize(element);
                // If the parent containers font-size has changed or the "forceNewCharCount" option is true (the default),
                // then recalculate the "characters per line" count and re-render the inner spans
                // Setting "forceNewCharCount" to false will save CPU cycles...
                if (!keepSpans && (settings.forceNewCharCount || fontSize != origFontSize)) {

                    const origFontSize = fontSize;

                    var newCharPerLine = Math.min(60, Math.floor(parentWidth / (origFontSize * settings.fontRatio))),
                        wordIndex = 0,
                        lineText = [],
                        counter = 0,
                        preText = "",
                        postText = "",
                        finalText = "",
                        lineLength,
                        slice,
                        preDiff,
                        postDiff;

                    if (newCharPerLine != 0 && newCharPerLine != idealCharPerLine) {
                        
                        const idealCharPerLine = newCharPerLine;

                        while (wordIndex < words.length) {

                            postText = "";

                            // build two strings (preText and postText) word by word, with one
                            // string always one word behind the other, until
                            // the length of one string is less than the ideal number of characters
                            // per line, while the length of the other is greater than that ideal
                            while (postText.length < idealCharPerLine) {
                                preText = postText;
                                postText += words[wordIndex] + " ";
                                if (++wordIndex >= words.length) {
                                    break;
                                };
                            };

                            // This bit hacks in a minimum characters per line test
                            // on the last line
                            if (settings.minCharsPerLine) {
                                slice = words.slice(wordIndex).join(" ");
                                if (slice.length < settings.minCharsPerLine) {
                                    postText += slice;
                                    preText = postText;
                                    wordIndex = words.length + 2;
                                };
                            };

                            // calculate the character difference between the two strings and the
                            // ideal number of characters per line
                            preDiff = idealCharPerLine - preText.length;
                            postDiff = postText.length - idealCharPerLine;

                            // if the smaller string is closer to the length of the ideal than
                            // the longer string, and doesn’t contain less than minCharsPerLine
                            // characters, then use that one for the line
                            if ((preDiff < postDiff) && (preText.length >= (settings.minCharsPerLine || 2))) {
                                finalText = preText;
                                wordIndex--;
                                // otherwise, use the longer string for the line
                            } else {
                                finalText = postText;
                            };

                            lineLength = finalText.trim().length;

                            // HTML-escape the text
                            finalText = this.jQueryService
                                .getElement('<div/>')
                                .text(finalText) 
                                .html();

                            // Wrap ampersands in spans with class `amp` for specific styling
                            if (settings.wrapAmpersand) {
                                finalText = finalText.replace(/&amp;/g, '<span class="amp">&amp;</span>');
                            };

                            finalText = finalText.trim();

                            lineText.push('<span class="slabtext slabtext-linesize-' + Math.floor(lineLength / 10) + ' slabtext-linelength-' + lineLength + '">' + finalText + "</span>");
                        };

                        element.html(lineText.join(" "));

                        // If we have a headLink, add it back just inside our target, around all the slabText spans
                        if (headLink) {
                            element.wrapInner('<a href="' + headLink + '" ' + (linkTitle ? 'title="' + linkTitle + '" ' : '') + '/>');
                        };
                    };
                } else {
                    // We only need the font-size for the resize-to-fit functionality
                    // if not injecting the spans
                    origFontSize = fontSize;
                };

                this.jQueryService.getElement("span.slabtext", element).each(() => {
                    const $span = element;
                    const innerText = $span.text();
                    const wordSpacing = innerText.split(" ").length > 1;
                    let diff: number | boolean;
                    let ratio: number;
                    let fontSize: number;

                    if (settings.postTweak) {
                        $span.css({
                            "word-spacing": 0,
                            "letter-spacing": 0
                        });
                    };

                    ratio = parentWidth / $span.width();
                    fontSize = parseFloat(element.css('fontSize')) || origFontSize;

                    $span.css("font-size", Math.min(parseFloat((fontSize * ratio).toFixed(settings.precision)), settings.maxFontSize) + "px");

                    // Do we still have space to try to fill or crop
                    diff = !!settings.postTweak ? parentWidth - $span.width() : false;

                    // A "dumb" tweak in the blind hope that the browser will
                    // resize the text to better fit the available space.
                    // Better "dumb" and fast...
                    if (diff) {
                        $span.css((wordSpacing ? 'word' : 'letter') + '-spacing', (diff / (wordSpacing ? innerText.split(" ").length - 1 : innerText.length)).toFixed(settings.precision) + "px");
                    };
                });

                // Add the class slabtextdone to set a display:block on the child spans
                // and avoid styling & layout issues associated with inline-block
                element.addClass("slabtextdone");

                // Fire the callback if required
                if (settings.onRender instanceof Function) {
                    settings.onRender.call(element);
                };
            };

            // Immediate resize
            resizeSlabs();

            if (!settings.noResizeEvent) {
                this.$window.addEventListener('resize', () => {
                    // Only run the resize code if the viewport width has changed.
                    // we ignore the viewport height as it will be constantly changing.
                    if (this.$window.visualViewport.width == viewportWidth) {
                        return;
                    };

                    viewportWidth = this.$window.visualViewport.width;

                    clearTimeout(resizeThrottle);
                    resizeThrottle = setTimeout(resizeSlabs, settings.resizeThrottleTime);
                });
            };
        });
    }
    
    // Calculates the pixel equivalent of 1em within the current header
    private grabPixelFontSize = (element: JQuery): number => {
        var dummy = this.jQueryService
            .getElement('<div style="display:none;font-size:1em;margin:0;padding:0;height:auto;line-height:1;border:0;">&nbsp;</div>')
            .appendTo(element);

        let emH = dummy.height();
        dummy.remove();
        
        return emH;
    }    
}

app.service(Injectables.SlabTextService, SlabTextService);
