import { HistoryYear } from "./historyYear";

export interface CreditAccountsDisplay {
    lastReported: string;
    creditorName: string;
    accountType: string;
    accountStatus: string;
    openedDate: string;
    closedDate: string;
    limit: string;
    term: string;
    montlyPayment: string;
    responsibility: string;
    balance: string;
    highestBalance: string;
    paymentStatus: string;
    dateOfLastPayment: string;
    paymentHistory?: HistoryYear[];
    creditUtilization: string;
    creditorInformation: string;
    originalPaymentHistory: string;
}