import { DocumentTag } from "./documentTag";
import { IBaseEntity } from "./iBaseEntity";

export interface DocumentDocumentTag extends IBaseEntity { 
    document: Document;
    documentId: number;
    documentTag: DocumentTag;
    documentTagId: number;
    id: number;
    createdDateTime: Date;
}