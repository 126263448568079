import { QuoteApi } from "../../api/quoteApi";
import { CommissionOptionFetcherParameters } from "../../api/types/commissionOptionFetcherParameters";
import { SelectOption } from "../../api/types/selectOption";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { CommissionType } from "../../api/types/model/commissionType";
import { IPromise, IQService } from "angular";

export class CommissionDropdownController {
    public static $inject = [
        Injectables.QuoteApi,
        Injectables.$q
    ];

    constructor(
        private readonly quoteApi: QuoteApi,
        private readonly $q: IQService
    ) { }

    public controller: CommissionDropdownController;

    public bondTypeId: number;
    public writingCompanyId: number;
    public bondId: number;
    public agencySystemAccountId: number;
    public carrierSystemAccountId: number;

    public commissionOptions: SelectOption<string>[];
    public commissionPercentOption: string;
    public otherCommissionPercent: number;
    public commissionPercent: number;
    public commissionFixedAmount: number;
    public commissionType: CommissionType;
    public ngDisabled: boolean;

    public commissionPercentOptionChanged() {
        if (this.commissionPercentOption === CommissionType.Other) {
            this.commissionPercent = this.otherCommissionPercent * 0.01;
            this.commissionType = CommissionType.Other;
        } else if (this.commissionPercentOption === CommissionType.Fixed) {
            this.commissionType = CommissionType.Fixed;
        } else {
            this.commissionPercent = parseFloat(this.commissionPercentOption);
            this.commissionType = CommissionType.Default;
        }
    }

    public commissionFixedAmountChanged() {
        if (this.commissionPercentOption === CommissionType.Other) {
            this.commissionPercent = this.otherCommissionPercent * 0.01;
            this.commissionType = CommissionType.Other;
        } else if (this.commissionPercentOption === CommissionType.Fixed) {
            this.commissionType = CommissionType.Fixed;
        } else {
            this.commissionPercent = parseFloat(this.commissionPercentOption);
            this.commissionType = CommissionType.Default;
        }
    }

    public otherCommissionPercentChanged() {
        if (this.commissionPercentOption === CommissionType.Other) {
            this.commissionPercent = this.otherCommissionPercent * 0.01;
            this.commissionType = CommissionType.Other;
        } else if (this.commissionPercentOption === CommissionType.Fixed) {
            this.commissionType = CommissionType.Fixed;
        } else {
            this.commissionPercent = parseFloat(this.commissionPercentOption);
            this.commissionType = CommissionType.Default;
        }
    }

    public otherCommissionFixedAmountChanged() {
        if (this.commissionPercentOption === CommissionType.Other) {
            this.commissionPercent = this.otherCommissionPercent * 0.01;
            this.commissionType = CommissionType.Other;
        } else if (this.commissionPercentOption === CommissionType.Fixed) {
            this.commissionType = CommissionType.Fixed;
        } else {
            this.commissionPercent = parseFloat(this.commissionPercentOption);
            this.commissionType = CommissionType.Default;
        }
    }

    public getCommissionOptions(updatedParameters?: CommissionOptionFetcherParameters): IPromise<void> {
        let proceed = true;

        if (updatedParameters) {
            proceed = false;

            if (this.bondId != updatedParameters.bondId) {
                this.bondId = updatedParameters.bondId;
                proceed = true;
            }
            
            if (this.writingCompanyId != updatedParameters.writingCompanyId) {
                this.writingCompanyId = updatedParameters.writingCompanyId;
                proceed = true;
            }

            if (this.bondTypeId != updatedParameters.bondTypeId) {
                this.bondTypeId = updatedParameters.bondTypeId;
                proceed = true;
            }
            
            if (this.carrierSystemAccountId != updatedParameters.carrierSystemAccountId) {
                this.carrierSystemAccountId = updatedParameters.carrierSystemAccountId;
                proceed = true;
            }

            if (this.agencySystemAccountId != updatedParameters.agencySystemAccountId) {
                this.agencySystemAccountId = updatedParameters.agencySystemAccountId;
                proceed = true;
            }
        }

        if (!proceed) {
            return this.$q.when();
        }

        return this.quoteApi
            .getCommissionOptions({
                bondId: this.bondId,
                writingCompanyId: this.writingCompanyId,
                bondTypeId: this.bondTypeId,
                carrierSystemAccountId: this.carrierSystemAccountId,
                agencySystemAccountId: this.agencySystemAccountId
            })
            .then((commissionOptions) => {

                this.commissionOptions = commissionOptions.map((option) => ({
                    label: option.label,
                    value: option.commissionPercent.toString()
                }));

                this.commissionOptions.push({ label: "Fixed Amount", value: CommissionType.Fixed });
                this.commissionOptions.push({ label: "Other Percent", value: CommissionType.Other });

                this.setCommissionOptionDropdown();
            });
    }

    private setCommissionOptionDropdown() {
        this.commissionPercentOption = null;

        if (this.commissionType == CommissionType.Fixed) {
            this.commissionPercentOption = CommissionType.Fixed;
        } else if (this.commissionType == CommissionType.Other) {
            this.otherCommissionPercent = this.getPercentForPresentation(this.commissionPercent);
            this.commissionPercentOption = CommissionType.Other;
        } else {
            for (let i = 0; i < this.commissionOptions.length; i++) {
                if (
                    this.commissionPercent &&
                    this.commissionOptions[i].value == this.commissionPercent.toString()
                ) {
                    this.commissionPercentOption = this.commissionOptions[i].value;
                }
            }
        }
    }

    private getPercentForPresentation(percentAmount: number) {
        if (!percentAmount) {
            return;
        }

        if (percentAmount < 1) {
            return percentAmount * 100;
        }

        return percentAmount;
    }

    public $onInit() {
        this.controller = this;
        this.getCommissionOptions();
    }
}

const commissionDropdownComponent = {
    bindings: {
        controller: "=?",
        bondTypeId: "<?",
        writingCompanyId: "<?",
        bondId: "<?",
        carrierSystemAccountId: "<?",
        agencySystemAccountId: "<?",
        commissionPercent: "=",
        commissionFixedAmount: "=",
        commissionType: "=",
        aaLabelStrategy: "@?",
        dropdownClass: "@?",
        otherClass: "@?",
        ngDisabled: "<"
    },
    templateUrl: "app/components/commissionDropdown/commissionDropdown.html",
    controller: CommissionDropdownController,
    controllerAs: "vm"
};

app.component("commissionDropdown", commissionDropdownComponent);