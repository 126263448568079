import { BondTransaction } from "./bondTransaction";
import { IBaseEntity } from "./iBaseEntity";
import { MasterApplicationQuestion } from "./masterApplicationQuestion";
import { AdditionalQuestionResponseType } from "./questionResponse";

export interface BondTransactionAdditionalQuestionResponse extends IBaseEntity { 
    bondTransaction: BondTransaction;
    bondTransactionId: number;
    
    responseText: string;
    questionText: string;
    masterApplicationQuestion: MasterApplicationQuestion;
    masterApplicationQuestionId: number;
    originType: AdditionalQuestionResponseType;
    
    id: number;
    createdDateTime: Date;
}