import { CustomersService } from "../../api/customerService";
import { EpicCustomerConnect } from "../../api/types/epicCustomerSearchResult";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { EpicCustomerConnectionModalOptions } from "./epicCustomerConnectionModalOptions";
import { EpicCustomerConnectionModalResult } from "./EpicCustomerConnectionModalResult";
import app from "../../main";
import { IPromise } from "angular";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";

export class EpicCustomerConnectionModalController {

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.CustomersService,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly $uibModalInstance: Modal<EpicCustomerConnectionModalResult>,
        private readonly modalOptions: EpicCustomerConnectionModalOptions,
        private readonly customerService: CustomersService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {
    }

    public isLoaded: boolean;
    public lookupCode: string;
    public lookupCodeSearch: string;
    public busyIndicator: BusyIndicator;
    public customerId: number;
    public noMatchesFound: boolean;
    
    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }
    
    public searchEpicByLookupCode() {
        if (!this.lookupCodeSearch) {
            return;
        }

        this.busyIndicator.promise = this.customerService.getClientByLookupCode(this.lookupCodeSearch)
            .then((matchingEpicClient) => {
                if (matchingEpicClient) {
                    const epicClient: EpicCustomerConnect = {
                        clientLookupCode: matchingEpicClient.clientLookupCode,
                        name: matchingEpicClient.name,
                        clientId: matchingEpicClient.clientId,
                        customerId: this.customerId
                    };
        
                    return this.customerService.setCustomerEpicCodes(epicClient)
                        .then(() => {
                            this.$uibModalInstance.close({} as EpicCustomerConnectionModalResult);
                            this.toastMessageCreator.createSuccessMessage('Customer connected with Epic client');
                        });
                } else {
                    this.noMatchesFound = true;
                }
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to search Epic by lookup code');
            });
    }

    public exportToEpic() {            
        this.busyIndicator.promise = this.customerService.exportToEpic(this.customerId)
            .then((lookupCode) => {
                this.$uibModalInstance.close({} as EpicCustomerConnectionModalResult);
                this.toastMessageCreator.createSuccessMessage('Customer record successfully exported to Epic');
            })
            .catch((err) => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to export the customer to epic. Check the Epic integration logs for more details');
            });
    }

    public loadCustomer(): IPromise<void> {
        return this.customerService.getCustomersLookupCode(this.customerId)
            .then((lookupCode) => {
                this.lookupCode = lookupCode;
                this.isLoaded = true;
            });
    }

    public disconnectFromEpic() {
        this.busyIndicator.promise = this.customerService.disconnectFromEpic(this.customerId)
            .then(() => {
                this.$uibModalInstance.close({} as EpicCustomerConnectionModalResult);
                this.toastMessageCreator.createSuccessMessage('Customer has been disconnected from Epic');
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to disconnect the customer from epic');
            });
    }

    public $onInit(): void {
        this.customerId = this.modalOptions.customerId;
        this.busyIndicator = {
            promise: this.loadCustomer()
        }
    }
}

app.controller('EpicCustomerConnectionModalController', EpicCustomerConnectionModalController);
