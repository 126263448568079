enum ODataTimeFrameFilterType {
    Last180ToLast90Days,
    Last90Days,
    LastMonth,
    LastWeek,
    LastYear,
    Mtd,
    ThisWeek,
    TwoMonthsAgo,
    Ytd
};

export default ODataTimeFrameFilterType;