import { BondChange } from "./model/bondChange";
import { Fee } from "./model/fee";

export interface RiderRequestForEditing {
    id: number;
    attorneyInFactUserId: number;
    requestedEffectiveDate: Date;
    fees: Fee[];
    requestedByUser: string;
    requestedByEmail: string;
    requestedByPhone: string;
    agencyComments: string;
    changes: BondChange[];
}