import { BondStatus, RenewalStatus } from "./model/bond";
import { BondAdditionalQuestionResponse } from "./model/bondAdditionalQuestionResponse";
import { CancellationStatus } from "./model/cancellationRequest";
import { CustomerType } from "./model/customer";
import { EProducerAccountType } from "./model/eProducerAccount";
import { ReinstatementStatus } from "./model/reinstatementRequest";
import { RiderStatus } from "./model/riderRequest";

export interface BondDetail {
    id: number;
    reviewRequired?: boolean;
    reviewComments: string;
    bondTypeName: string;
    bondTypeId: number;
    bondNumber: string;
    bondAmount: number;
    effectiveDate: Date;
    expirationDate: Date;
    nameOnBond: string;
    carrierName: string;
    carrierId: number;
    writingCompanyName: string;
    writingCompanyId: number;
    agencyName: string;
    agencyId: number;
    sfaaCode: string;
    sfaaDescription: string;
    status: BondStatus;
    renewalStatus: RenewalStatus;
    importComments: string;
    bondAdditionalQuestionResponses: BondAdditionalQuestionResponse[];
    customerId: number;
    customerType: CustomerType;
    eProducerAccountId?: number;
    eProducerAccountType?: EProducerAccountType;
    eProducerAccountName?: string;
    eProducerAccountBrokerCode?: string;
    isRenewable: boolean;
    riderStatus: RiderStatus;
    cancellationStatus: CancellationStatus;
    cancellationDate: Date;
    reinstatementStatus: ReinstatementStatus;
    allowPrincipalAssignment?: boolean;
    bondAccountId?: number;
    isElectronic: boolean;
}