import app from "../../main";

export class BrokerCardController {

    public onRemoveClick: () => void;

    public get showRemoveButton(): boolean {
        return typeof this.onRemoveClick === 'function';
    }

    public $onInit() {}
}

const brokerCardComponent = {
    bindings: {
        broker: '<',
        onRemoveClick: '&?'
    },
    templateUrl: 'app/components/brokerInfoCard/brokerCard.html',
    controller: BrokerCardController,
    controllerAs: 'vm'
}

app.component('brokerCard', brokerCardComponent);