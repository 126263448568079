import { ApplicationService } from "../../../api/applicationService";
import { Injectables } from "../../../configuration/injectables";
import { Table } from "../../../utilities/tables/table";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { CarrierOpenApplicationsReportItem } from "./carrierOpenApplicationsReportItem";
import { CarrierOpenApplicationsReportFilterOptions } from "./carrierOpenApplicationsReportFilterOptions";
import { IDebounceDelayer } from "../../../utilities/debounceDelayer/iDebouceDelayer";
import { ApplicationStatus } from "../../../api/types/model/application";

export class CarrierOpenApplicationsController {
    public static $inject = [
        Injectables.ApplicationService,
        Injectables.IDebouceDelayer
    ];

    constructor(
        private readonly applicationService: ApplicationService,
        debouceDelayer: IDebounceDelayer
    ) {
        this.table = new Table(debouceDelayer);
        this.table.queryOptions.status = ApplicationStatus.Submitted ;
        this.table.onChange = this.loadData;
    }

    public readonly table: Table<CarrierOpenApplicationsReportItem, CarrierOpenApplicationsReportFilterOptions>;
    public busyIndicator: BusyIndicator;

    public exportOpenApplicationsToExcel = () => {
        this.busyIndicator = {
            message: "Preparing Report...",
            promise: this.applicationService.getCarrierOpenApplicationExcelReport(
                this.table.queryOptions
            )
        };
    }

    public loadData = () => {
        this.busyIndicator ={
            message:  "Loading Open Applications...",
            promise: this.applicationService
                .getCarrierOpenApplicationReportData(this.table.queryOptions)
                .then((response)=> {
                    this.table.setData(response.items,response.totalRecordCount)
            })
        };
    };

    public $onInit(): void {
        this.loadData();
    }
}