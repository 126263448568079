import { IBaseEntity } from "./iBaseEntity";
import { SystemAccount } from "./systemAccount";

export interface SystemAccountContact extends IBaseEntity { 
    name: string;
    email: string;
    phone: string;
    extension: string;
    fax: string;
    department: string;
    systemAccountId: number;
    systemAccount: SystemAccount;
    $index?: number;
    id: number;
    createdDateTime: Date;
}