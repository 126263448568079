import { FollowUpService } from "../../api/followUpService";
import { ApplicationFollowUp } from "../../api/types/model/applicationFollowUp";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import app from "../../main";
import { IPromise } from "angular";
import { FollowUpModalOptions } from "./FollowUpModalOptions";

export class FollowUpModalController {

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.FollowUpService,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly $uibModalInstance: Modal<ApplicationFollowUp>,
        private readonly options: FollowUpModalOptions,
        private readonly followUpService: FollowUpService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) { }

    public processingPromise: IPromise<ApplicationFollowUp>;
    public followUp: ApplicationFollowUp;

    public save(): void {
        if (this.followUp.id) {
            this.processingPromise = this.followUpService.update(this.followUp);
        } else  {
            this.processingPromise = this.followUpService.insert(this.followUp);
        }

        this.processingPromise
                .then((updatedFollowUp) => {
                    this.$uibModalInstance.close(updatedFollowUp);
                    this.toastMessageCreator.createSuccessMessage('Follow up saved successfully');
                })
                .catch(() => {
                    this.toastMessageCreator.createErrorMessage('An error occurred trying to save this follow up');
                });
    }

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }

    public $onInit(): void {
        if (this.options.followUp) {
            this.followUp = this.options.followUp;
        }
    }
}

app.controller('FollowUpModalController', FollowUpModalController);
