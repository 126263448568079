import { WidgetType } from "../widgetBases/widgetTypes";
import DashboardWidgetOptions from "./dashboardWidgetOptions";

export type DashboardWidget = {
    id?: string;
    name?: string;
    description?: string;
    thumbnailClass?: string;
    resizable?: boolean;
    image?: string;
    sizeX?: number;
    sizeY?: number;
    maxSizeX?: number;
    maxSizeY?: number;
    minSizeX?: number;
    minSizeY?: number;
    directive?: WidgetType;
    options: DashboardWidgetOptions;
    tags?: string[];
    carriers?: boolean;
    agents?: boolean;
    mgas?: boolean;
    key?: string;
    row?: number;
    col?: number;
}