import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { ConfirmationModalOptions } from "./ConfirmationModalOptions";
    
export class ConfirmationModalController {

    public static $inject = [
        Injectables.$uibModalInstance, 
        Injectables.Options
    ];

    public confirmButtonText: string;
    public cancelButtonText: string;
    public header: string;
    public message: string;

    constructor(
        private readonly $uibModalInstance: Modal<null>,
        private readonly options: ConfirmationModalOptions) { 
    }

    public cancel = () => {
        this.$uibModalInstance.dismiss('cancel');
    }

    public confirmClicked = () => {
        this.$uibModalInstance.close(null);
    }

    public $onInit = () => {
        this.confirmButtonText = this.options.confirmButtonText || 'Confirm';
        this.cancelButtonText = this.options.cancelButtonText || 'Cancel';

        this.header = this.options.header;
        this.message = this.options.message;
    }
}

app.controller('ConfirmationModalController', ConfirmationModalController);