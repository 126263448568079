import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { IDebounceDelayer } from "../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../utilities/tables/table";
import { BondTypeHistoryListItem } from "./bondTypeHistoryListItem";
import { BondTypeHistoryTableQueryOptions } from "./bondTypeHistoryTableQueryOptions";
import app from "../../main";
import { BondTypeService } from "../../api/bondTypeService";

export class BondTypeHistoryModalController {
    
    public static $inject = [
        Injectables.Options,
        Injectables.$uibModalInstance,
        Injectables.BondTypeService,
        Injectables.IDebouceDelayer
    ];

    constructor(
        private readonly options: BondTypeHistoryTableQueryOptions,
        private readonly $uibModalInstance: Modal<void>,        
        private readonly bondTypeService: BondTypeService,
        debouceDelayer: IDebounceDelayer         
    ) {
        this.bondTypeHistoryTable = new Table(debouceDelayer);
        this.bondTypeHistoryTable.onChange = this.loadHistory;
    }

    public readonly bondTypeHistoryTable: Table<BondTypeHistoryListItem, BondTypeHistoryTableQueryOptions>;
    public busyIndicator: BusyIndicator;  

    private loadHistory = () => {
        this.busyIndicator.promise = this.bondTypeService.getBondTypeHistoryListItems(this.bondTypeHistoryTable.queryOptions)
            .then((response) => {
                this.bondTypeHistoryTable.setData(response.items, response.totalRecordCount);
            });
    }

    public close(): void {
        this.$uibModalInstance.dismiss('close');
    }

    public $onInit() { 
        this.bondTypeHistoryTable.queryOptions.bondTypeId = this.options.bondTypeId; 
        this.busyIndicator = {
            message: "Loading History..."
        };
        this.loadHistory();        
    }
}

app.controller('BondTypeHistoryModalController', BondTypeHistoryModalController);