import { IPromise } from "angular";
import { Injectables } from "../../../configuration/injectables";
import { AutoFeeService } from "../../../api/autoFeeService";
import { AutoFee } from "../../../api/types/model/autoFee";
import { IDebounceDelayer } from "../../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../../utilities/tables/table";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";;
import { AutoFeesDropdownFilterOptions } from "./autoFeesFilterDropdown/autoFeesDropdownFilterOptions";
import { ModalOpener } from "../../../modals/modalOpener";

export class AutoFeesController {

    public static $inject = [
        Injectables.AutoFeeService,
        Injectables.IDebouceDelayer,
        Injectables.ModalOpener
    ];

    public processingPromise: IPromise<void>;
    public autoFee: AutoFee;

    constructor(
        private readonly autoFeeService:AutoFeeService,
        debouceDelayer: IDebounceDelayer,
        private readonly modalOpener: ModalOpener
    ) {
        this.table = new Table(debouceDelayer);
        this.table.onChange = this.loadData;
    }

    public readonly table: Table<AutoFee, AutoFeesDropdownFilterOptions>;
    public busyIndicator: BusyIndicator;

    public promptToDelete = (autoFee: AutoFee) => {
        this.busyIndicator = {
            message: 'Deleting...',
            promise: this.modalOpener.confirmModal('Delete Fee', 'Are you sure you want to delete this fee?', 'Delete', 'Cancel')
            .result
            .then(() => {
                return this.autoFeeService.deleteById(autoFee.id);
            })
            .then(this.loadData)
        };
    }

    public loadData = () => {
        this.busyIndicator = {
            message: 'Loading...',
            promise: this.autoFeeService
            .load(this.table.queryOptions)
            .then((response) => { 
                this.table.setData(response.items, response.totalRecordCount)
            })
        };
    }

    public $onInit(): void {
        this.loadData();
    }
}