export interface PublicSystemAccount { 
    id: number;
    allowACHPaymentsThroughEProducer: boolean;
    allowACHPaymentsThroughPaymentForm: boolean;
    allowCCPaymentsThroughEProducer: boolean;
    allowCCPaymentsThroughPaymentForm: boolean;
    websiteURL: string;
    thumbnailLogo: File;
    thumbnailLogoFileId: number;
    stateOfIncorporation: string;
    noBondTypeRedirectUrl: string;
    phone: string;
    physicalAddress: string;
    physicalCity: string;
    physicalCounty: string;
    physicalState: string;
    physicalSuiteAptNumber: string;
    physicalZip: string;
    legalName: string;
    mailAddress: string;
    mailCity: string;
    mailCounty: string;
    mailState: string;
    mailSuiteAptNumber: string;
    mailZip: string;
    largeLogo: File;
    largeLogoFileId: number;
    eProducerGoogleAnalyticsCode: string;
    eProducerPortalURL: string;
    expeditedProcessingFee: number;
    email: string;
    description: string;
    companyName: string;
    analyticsTrackingId: string;
    analyticsIntegrationIsActive: boolean;
    adWordsIntegrationIsActive: boolean;
    adWordsPurchaseBondConversionId: string;
    adWordsPurchaseBondConversionLabel: string;
    adWordsSubmitApplicationConversionId: string;
    adWordsSubmitApplicationConversionLabel: string;
    adWordsVisitApplicationConversionId: string;
    adWordsVisitApplicationConversionLabel: string;
}