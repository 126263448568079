import { IBaseEntity } from "./iBaseEntity";
import { SystemAccount } from "./systemAccount";

export interface WritingCompany extends IBaseEntity { 
    name: string;
    physicalAddress: string;
    physicalSuiteAptNumber: string;
    physicalState: string;
    physicalCity: string;
    physicalZip: string;
    physicalCounty: string;
    mailAddress: string;
    mailCounty: string;
    mailSuiteAptNumber: string;
    mailCity: string;
    mailState: string;
    mailZip: string;
    stateOfIncorporation: string;
    primaryPhone: string;
    primaryFax: string;
    priority: number;
    backdateMaxDays: number;
    predateMaxDays: number;
    fein: string;
    amBestNumber: string;
    amBestRating: string;
    amBestFinancialStrengthRating: string;
    amBestOutlook: string;
    naicNumber: string;
    corporateSealFileId: number;
    defaultMinimumPremium: number;
    corporateSealFile: File;
    systemAccountId: number;
    powerOfAttorneyDocumentId: number;
    systemAccount: SystemAccount;
    powerOfAttorneyDocument: Document;
    $index?: number;
    id: number;
    createdDateTime: Date;
    $minimumPremiumInheritedText:string; //added for system account services ln 368 on 11/10/22 for error fix after namespace change projecct
}