export interface ActivityAttachment {
    id: number;
    createdDateTime: Date;
    fileId: number;
    comments: string;
    filename: string;
    fileSize: number;
    activityId: number;
    visibleToAllCarriers: boolean;
    visibleToAgent: boolean;
    visibleInEProducer: boolean;
    uploadedThroughEProducer: boolean;
    uploadedByUserId: number;
    uploadedByUserFullName: string;
    shareWithCarrier?: number;
    isRemoved: boolean;
    isModified: boolean;
    displayName: string;
    uploadedByEProducerUserId?: number;
    
    $url: string;
    $isExpanded?: boolean;
}