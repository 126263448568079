import { IRootScopeService, IAngularEvent } from "angular";
import { Injectables } from "../configuration/injectables";
import app from "../main";
import { CurrentState } from "./CurrentState";
import { StateChangedEventHandler } from "./stateChangedEventHandler";
import { StateChangingEventHandler } from "./StateChangingEventHandler";

export class StateChangeListener {
    
    static $inject = [
        Injectables.$rootScope
    ];

    constructor(
        private readonly $rootScope:IRootScopeService
    ) {}

    public onStateChanging = (eventHandler: StateChangingEventHandler) => {
        this.$rootScope.$on("$stateChangeStart", function (event, toState, params) {

            eventHandler(event, toState, params);
        });
    }

    public onStateChanged = (eventHandler: StateChangedEventHandler) => {
        this.$rootScope.$on('$stateChangeSuccess', (
            event: IAngularEvent, 
            toState: CurrentState, 
            toParams: any, 
            prevState: CurrentState, 
            prevStateParams: any) => {

            eventHandler(toState, toParams, prevState, prevStateParams);
        });
    }
}

app.service(Injectables.StateChangeListener, StateChangeListener);