import { Application } from "./application";
import { Tag } from "../tag";
import { PrincipalType } from "./application";
import { ApplicationFollowUp } from "./applicationFollowUp";
import { BondType } from "./bondType";
import { Company } from "./company";
import { EProducerAccount } from "./eProducerAccount";
import { IBaseEntity } from "./iBaseEntity";
import { LeadQuestionResponse } from "./leadQuestionResponse";
import { Person } from "./person";
import { SystemAccount } from "./systemAccount";

export interface Lead extends IBaseEntity { 
    systemAccountId: number;
    agencyComments: string;
    applicantComments: string;
    application: Application;
    applicationId: number;
    bondAmount: number;
    bondType: BondType;
    bondTypeId: number;
    carrierCount: number;
    companies: Company[];
    desiredEffectiveDate: Date;
    followUps: ApplicationFollowUp[];
    highestPremium: number;
    leadQuestionResponses: LeadQuestionResponse[];
    leadType: LeadType;
    lowestPremium: number;
    nameOnBond: string;
    people: Person[];
    prequalifiedQuoteCount: number;
    systemAccount: SystemAccount;
    tags: Tag[];
    principalType: PrincipalType;
    ipAddress: string;
    eProducerAccountId: number;
    eProducerAccount: EProducerAccount;
    id: number;
    createdDateTime: Date;
}

export enum LeadType {	
    Inquiry = "Inquiry",
    Prospect = "Prospect",
    Submission = "Submission"
}