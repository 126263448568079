import { DownloadBatchType } from "./downloadBatchType";

export interface DownloadBatchDto   { 
    startDate: Date;
    endDate: Date;
    includeManifest: boolean;
    includeCoverLetters: boolean;
    includeApplicationForms: boolean;
    includeBondForms: boolean;
    includeOther: boolean;
    type: DownloadBatchType;
}