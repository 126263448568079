import {
    ODataFactory,
    ODataFilterCollection,
    ODataFilterDataType,
    ODataEndpoint
} from "../../../../../api/odata";
import { SfaaCodeService } from "../../../../../api/sfaaCodeService";
import { SystemAccountService } from "../../../../../api/systemAccountService";
import { TagService } from "../../../../../api/tagService";
import { Bond } from "../../../../../api/types/bond";
import { Table } from "../../../../../utilities/tables/table";
import { SelectOption } from "../../../../../api/types/selectOption";
import { BusyIndicator } from "../../../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../../../configuration/injectables";
import { ModalOpener } from "../../../../../modals/modalOpener";
import { DashboardService } from "../../dashboardService";
import { DashboardWidget } from "../../types/dashboardWidget";
import app from "../../../../../main";
import A3ApiResponse from "../../../../../api/types/a3ApiResponse";
import * as angular from "angular";
import { IDebounceDelayer } from "../../../../../utilities/debounceDelayer/iDebouceDelayer";
import { CurrentUserResolver } from "../../../../../utilities/currentUserResolver/currentUserResolver";

class DashboardWidgetBondsToRenewAgentController {
    public static $inject = [
        Injectables.CurrentUserResolver,
        Injectables.ODataFactory,
        Injectables.DashboardService,
        Injectables.ModalOpener,
        Injectables.TagService,
        Injectables.SfaaCodeService,
        Injectables.SystemAccountService,
        Injectables.IDebouceDelayer
    ];

    constructor(
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly odata: ODataFactory,
        private readonly dashboardService: DashboardService,
        private readonly modalOpener: ModalOpener,
        private readonly tagService: TagService,
        private readonly sfaaCodeService: SfaaCodeService,
        private readonly systemAccountService: SystemAccountService,
        debounceDelayer: IDebounceDelayer
    ) {
        this.table = new Table(debounceDelayer);
    }

    public widget: DashboardWidget;
    public busyIndicator: BusyIndicator;
    public filterCollection: ODataFilterCollection;
    public table: Table<Bond, null>;
    public errorMessage: string;
    public sfaaCodeOptions: SelectOption<string>[];
    public tagOptions: SelectOption<string>[];
    public carrierOptions: SelectOption<number>[];

    public loadSfaaCodes = () => {
        return this.sfaaCodeService.getAllSfaaCodeOptions().then((options) => {
            this.sfaaCodeOptions = options;
        });
    };

    public loadTagOptions = () => {
        return this.tagService.loadTagOptions().then((tagOptions) => {
            this.tagOptions = tagOptions;
        });
    };

    public loadCarriers = () => {
        return this.systemAccountService
            .getAppointedCarrierOptions()
            .then((options) => {
                this.carrierOptions = options;
            });
    };

    public setFilters = () => {
        this.filterCollection = this.odata.getFilterCollection([
            this.odata.getFilter(
                "status",
                ODataFilterDataType.select,
                "Status",
                null,
                null,
                true,
                [
                    { label: "Active", value: "'Active'" },
                    {
                        label: "Pending Cancellation",
                        value: "'PendingCancellation'"
                    },
                    {
                        label: "Cancelled",
                        value: "'Cancelled'"
                    },
                    {
                        label: "Pending Reinstatement",
                        value: "'PendingReinstatement'"
                    }
                ],
                false
            ),
            this.odata.getFilter(
                "renewalStatus",
                ODataFilterDataType.select,
                "Renewal Status",
                null,
                null,
                true,
                [
                    {
                        label: "Not Started",
                        value: "'None'"
                    },
                    {
                        label: "Started",
                        value: "'ApplicationSubmitted'"
                    }
                ],
                false
            ),
            this.odata.getFilter(
                "bondAmount",
                ODataFilterDataType.money,
                "Bond Amount"
            ),
            this.odata.getFilter(
                "renewalStatus",
                ODataFilterDataType.select,
                "Renewal Application Status",
                null,
                null,
                true,
                [
                    {
                        label: "No Renewal Application",
                        value: "'None'"
                    },
                    {
                        label: "Renewal Application Submitted",
                        value: "'ApplicationSubmitted'"
                    }
                ],
                false
            ),
            this.odata.getFilter(
                "expirationDate",
                ODataFilterDataType.daysTill,
                "Expiration Date"
            ),
            this.odata.getFilter(
                "effectiveDate",
                ODataFilterDataType.date,
                "Effective Date"
            ),
            this.odata.getFilter(
                "expirationDate",
                ODataFilterDataType.date,
                "Expiration Date"
            ),
            this.odata.getFilter(
                "bondType/name",
                ODataFilterDataType.string,
                "Bond Type"
            ),
            this.odata.getFilter(
                "bondType/obligee/requisitioningState",
                ODataFilterDataType.state,
                "Requisitioning State"
            ),
            this.odata.getFilter(
                "quote/carrierSystemAccount/companyName",
                ODataFilterDataType.select,
                "Carrier",
                null,
                null,
                !this.currentUserResolver.getCurrentUser().systemAccount
                    .isCarrier,
                this.carrierOptions
            ),
            this.odata.getFilter(
                "bondType/sfaaCode/code",
                ODataFilterDataType.select,
                "SFAA Code",
                null,
                null,
                true,
                this.sfaaCodeOptions
            ),
            this.odata.getFilter(
                "Tags/any(t:contains(t/name,'{0}'))",
                ODataFilterDataType.select,
                "Tagged ",
                null,
                null,
                true,
                this.tagOptions,
                true,
                "with "
            ),
            this.odata.getFilter(
                "customer/eProducerAccount/name",
                ODataFilterDataType.string,
                "Broker Name",
                null,
                null,
                !this.currentUserResolver.getCurrentUser().systemAccount
                    .isCarrier
            ),
            this.odata.getFilter(
                "customer/eProducerAccount/agencyCode",
                ODataFilterDataType.string,
                "Broker Agency Code",
                null,
                null,
                !this.currentUserResolver.getCurrentUser().systemAccount
                    .isCarrier
            ),
            this.odata.getFilter(
                "customer/eProducerAccount/agencySubCode",
                ODataFilterDataType.string,
                "Broker Sub Code",
                null,
                null,
                !this.currentUserResolver.getCurrentUser().systemAccount
                    .isCarrier
            )
        ]);
    };

    public saveRecordsPerPage = () => {
        this.widget.options.recordsPerPage = this.table.pager.recordsPerPage;
        this.dashboardService.save();
    };

    public saveOrderby = () => {
        this.widget.options.orderby = this.table.sorter.sortBy;
        this.dashboardService.save();
    };

    public setDateOfCancellationNotice = (bond) => {
        const expirationDate = new Date(bond.expirationDate);
        bond.dateOfCancellationNotice = new Date(expirationDate.getTime());
        bond.dateOfCancellationNotice.setDate(
            bond.dateOfCancellationNotice.getDate() -
                bond.bondType.cancellationProvisionDays
        );
    };

    public buildQuery = () => {
        const query = this.odata
            .getQuery()
            .orderby(this.table.sorter.sortBy)
            .skip(
                (this.table.pager.currentPage - 1) *
                    this.table.pager.recordsPerPage
            )
            .top(this.table.pager.recordsPerPage)
            .expand(
                "quote(" +
                    "$expand=application($select=id,bondAmount);" +
                    "$select=application,carrierSystemAccount" +
                    ")," +
                    "bondType($select=id,name,cancellationProvisionDays)," +
                    "bondTransactions(" +
                    "$filter=transactionType eq 'NewBusiness'" +
                    ")," +
                    "carrierSystemAccount($select=companyName)"
            )
            .select(
                "id,effectiveDate,renewalStatus,expirationDate,customerId,bondNumber,quoteId,bondAmount,nameOnBond,cancellationDate,reinstatementDate,status"
            );

        const filterExpr = this.filterCollection.getFilterExpression();
        if (filterExpr) {
            query.filter(filterExpr);
        }

        return query;
    };

    public loadGrid = () => {
        if (this.table.sorter.sortBy !== this.widget.options.orderby) {
            this.saveOrderby();
        }

        if (
            this.table.pager.recordsPerPage !=
            this.widget.options.recordsPerPage
        ) {
            this.saveRecordsPerPage();
        }

        const query = this.buildQuery();

        const dataSvc = this.odata.getService(ODataEndpoint.Bond);

        this.busyIndicator.promise = dataSvc
            .get<A3ApiResponse<Bond[]>>(query)
            .then((response) => {
                this.table.setData(
                    response.data.value,
                    response.data["@odata.count"]
                );

                for (let i = 0; i < this.table.data.length; i++) {
                    this.setDateOfCancellationNotice(this.table.data[i]);
                }
            })
            .catch(() => {
                this.errorMessage =
                    "An error occurred trying to get the widget data";
            });
    };

    public showShippingModal = (bondTransaction) => {
        this.modalOpener
            .showShippingModal(bondTransaction)
            .result.then(() => {})
            .catch(() => {});
    };

    public $onInit = () => {
        this.busyIndicator = {
            message: "Loading..."
        };

        this.setFilters();

        if (angular.isDefined(this.widget.options.filters)) {
            this.filterCollection.mergeFilters(this.widget.options.filters);
        }

        this.loadGrid();

        this.dashboardService.refreshFunctions.push(this.loadGrid);

        this.table.onChange = this.loadGrid;
    };
}

const dashboardWidgetBondsToRenewAgentComponent = {
    bindings: {
        widget: "="
    },
    templateUrl:
        "app/states/common/dashboard/widgets/bondRenewAgent/dashboardWidgetBondsToRenewAgent.html",
    controller: DashboardWidgetBondsToRenewAgentController,
    controllerAs: "vm"
};

app.component(
    "dashboardWidgetBondsToRenewAgent",
    dashboardWidgetBondsToRenewAgentComponent
);
