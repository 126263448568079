import { UserService } from "../../../api/userService";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../configuration/injectables";
import { IDebounceDelayer } from "../../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../../utilities/tables/table";
import { UserTableItem } from "./userTableItem";
import { UserTableQueryOptions } from "./userTableQueryOptions";

export class UsersGridController {

    static $inject = [
        Injectables.UserService,
        Injectables.IDebouceDelayer
    ];

    constructor(
        private readonly userService: UserService,
        debouceDelayer: IDebounceDelayer
    ) {
        this.table = new Table(debouceDelayer);
        this.table.onChange = this.loadData;
    }

    
    public table: Table<UserTableItem, UserTableQueryOptions>;
    public busyIndicator: BusyIndicator;

    private loadData = () => {
        this.busyIndicator.promise = this.userService.getUsers(this.table.queryOptions)
            .then((response) => {
                this.table.setData(response.items, response.totalRecordCount);
            });
    }

    public exportUsersExcelReport = () =>{
        this.busyIndicator = {
            message: "Loading...",
            promise: this.userService.downloadUsersEntriesExcelReport(this.table.queryOptions as UserTableQueryOptions)
        };
    }

    public $onInit() {
        this.busyIndicator = {
            message: 'Loading...'
        };

        this.loadData();
    }
}