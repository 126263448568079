import { Quote } from "../quote";
import { IBaseEntity } from "./iBaseEntity";

export interface QuoteRateTier extends IBaseEntity { 
    ratePerThousand: number;
    thresholdAmount: number;
    quote: Quote;
    quoteId: number;
    id: number;
    createdDateTime: Date;
}