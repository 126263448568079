import { SystemAccountService } from "../../../api/systemAccountService";
import { PaymentMethod } from "../../../api/types/model/paymentTransaction";
import { SelectOption } from "../../../api/types/selectOption";
import { Injectables } from "../../../configuration/injectables";
import app from "../../../main";
import { PaymentReportItem } from "../../../states/agentSpecific/payments/paymentReportItem";
import isUndefinedOrNull from "../../../utilities/angularUtilities/isUndefinedOrNull";
import { Table } from "../../../utilities/tables/table";
import { BondLineReportFilterOptions } from "./bondLinesReportFilterOptions";
import { IPromise } from "angular";
import { BondAccount } from "../../../api/types/bondAccounts/bondAccount";

class BondLineReportFilterDropdownController {
    public static $inject = [
        Injectables.SystemAccountService,
        Injectables.CurrentUserResolver
    ];

    constructor(private readonly systemAccountService: SystemAccountService) {
        this.modifiedFilters = {} as BondLineReportFilterOptions;
        this.filterCount = 0;
    }

    public isOpen: boolean;
    public filterCount: number;
    public modifiedFilters: BondLineReportFilterOptions;

    public processingPromise: IPromise<any>;
    public processingMessage: string;
    public carrierOptions: SelectOption<number>[];
    public bondAccount: BondAccount;

    public table: Table<PaymentReportItem, BondLineReportFilterOptions>;
    public paymentMethods: SelectOption<PaymentMethod>[];

    private loadCarrierOptions() {
        return this.systemAccountService
            .getAppointedCarrierOptions()
            .then((carrierOptions) => {
                this.carrierOptions = carrierOptions;
            });
    }

    public clearFilters() {
        this.modifiedFilters.status = null;
        this.modifiedFilters.companyId = null;
        this.modifiedFilters.minimumAmount = null;
        this.modifiedFilters.maximumAmount = null;
        this.modifiedFilters.minimumAggregateAmount = null;
        this.modifiedFilters.maximumAggregateAmount = null;
        this.modifiedFilters.startExpirationDate = null;
        this.modifiedFilters.endExpirationDate = null;

        this.table.applyFilters({ ...this.modifiedFilters });

        this.setFilterCount();
        this.isOpen = false;
    }

    public cancel() {
        this.isOpen = false;
        this.modifiedFilters = { ...this.table.queryOptions };
    }

    public applyFilters() {
        this.isOpen = false;
        this.table.applyFilters({ ...this.modifiedFilters });
        this.setFilterCount();
    }

    private setModifiedFilters = () => {
        this.modifiedFilters.status = this.table.queryOptions.status;
        this.modifiedFilters.companyId = this.table.queryOptions.companyId;
        this.modifiedFilters.minimumAmount =
            this.table.queryOptions.minimumAmount;
        this.modifiedFilters.maximumAmount =
            this.table.queryOptions.maximumAmount;
        this.modifiedFilters.minimumAggregateAmount =
            this.table.queryOptions.minimumAggregateAmount;
        this.modifiedFilters.maximumAggregateAmount =
            this.table.queryOptions.maximumAggregateAmount;
        this.modifiedFilters.startExpirationDate =
            this.table.queryOptions.startExpirationDate;
        this.modifiedFilters.endExpirationDate =
            this.table.queryOptions.endExpirationDate;

        this.setFilterCount();
    };

    public setFilterCount() {
        this.filterCount = 0;

        if (!isUndefinedOrNull(this.table.queryOptions.status)) {
            this.filterCount++;
        }

        if (!isUndefinedOrNull(this.table.queryOptions.companyId)) {
            this.filterCount++;
        }

        if (
            this.table.queryOptions.minimumAmount ||
            this.table.queryOptions.maximumAmount
        ) {
            this.filterCount++;
        }

        if (
            this.table.queryOptions.minimumAggregateAmount ||
            this.table.queryOptions.maximumAggregateAmount
        ) {
            this.filterCount++;
        }

        if (
            this.table.queryOptions.startExpirationDate ||
            this.table.queryOptions.endExpirationDate
        ) {
            this.filterCount++;
        }
    }

    public $onInit() {
        this.loadCarrierOptions();
        this.setModifiedFilters();
    }
}

const bondLineReportFilterDropdownComponent = {
    bindings: {
        table: "<"
    },
    controller: BondLineReportFilterDropdownController,
    controllerAs: "vm",
    templateUrl:
        "app/states/agentSpecific/bondLines/bondLinesReportFilterDropdown.html"
};

app.component(
    "bondLineReportFilterDropdown",
    bondLineReportFilterDropdownComponent
);
