import { ODataFactory, ODataFilterCollection, ODataEndpoint, ODataFilterDataType } from "../../../../../api/odata";
import { SfaaCodeService } from "../../../../../api/sfaaCodeService";
import isDefined from "../../../../../utilities/angularUtilities/isDefined";
import { SelectOption } from "../../../../../api/types/selectOption";
import { BusyIndicator } from "../../../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../../../configuration/injectables";
import { DashboardService } from "../../dashboardService";
import { DashboardWidget } from "../../types/dashboardWidget";
import app from "../../../../../main";
import { Table } from "../../../../../utilities/tables/table";
import A3ApiResponse from "../../../../../api/types/a3ApiResponse";
import { ITimeoutService } from "angular";
import { BondTransaction } from "../../../../../api/types/model/bondTransaction";
import { TagService } from "../../../../../api/tagService";
import { IDebounceDelayer } from "../../../../../utilities/debounceDelayer/iDebouceDelayer";

class DashboardWidgetBondTransactionsCarrierController  {

    public static $inject = [
        Injectables.ODataFactory,
        Injectables.DashboardService,
        Injectables.$timeout,
        Injectables.TagService,
        Injectables.SfaaCodeService,
        Injectables.IDebouceDelayer
    ];

    constructor(
        private readonly odata: ODataFactory,
        private readonly dashboardService: DashboardService,
        private readonly $timeout: ITimeoutService,
        private readonly tagService: TagService,
        private readonly sfaaCodeService: SfaaCodeService,
        private readonly debouceDelayer: IDebounceDelayer       
    ) {
        this.table = new Table<BondTransaction, null>(debouceDelayer);
    }
    
    public widget: DashboardWidget;
    public busyIndicator: BusyIndicator;
    public table: Table<BondTransaction, null>;
    public filterCollection: ODataFilterCollection;
    public errorMessage: string;
    public sfaaCodeOptions: SelectOption<string>[];
    public tagOptions: SelectOption<string>[];    
    
    public setFilters = () => {
        this.filterCollection  = this.odata.getFilterCollection([
            this.odata.getFilter('transactionType', ODataFilterDataType.select, 'Transaction Type', null, null, true, [{ label: 'New Business', value: "'NewBusiness'" }, { label: 'Renewal', value: "'Renewal'" }, { label: 'Cancellation', value: "'Cancellation'" }, { label: 'Rider', value: "'Rider'" }, { label: 'Reinstatement', value: "'Reinstatement'" }], false),
            this.odata.getFilter('bond/bondType/name', ODataFilterDataType.string, 'Bond Type'),
            this.odata.getFilter('premium', ODataFilterDataType.money, 'Premium'),
            this.odata.getFilter('refundedPremium', ODataFilterDataType.money, 'Refund'),
            this.odata.getFilter('commission', ODataFilterDataType.money, 'Commission'),
            this.odata.getFilter('createdDateTime', ODataFilterDataType.date, 'Transaction'),
            this.odata.getFilter('effectiveDate', ODataFilterDataType.date, 'Effective Date'),
            this.odata.getFilter('bond/bondType/obligee/requisitioningState', ODataFilterDataType.state, 'Requisitioning State'),
            this.odata.getFilter('bond/bondType/sfaaCode/code', ODataFilterDataType.select, 'SFAA Code', null, null, true, this.sfaaCodeOptions),
            this.odata.getFilter("bond/tags/any(t:contains(t/name,'{0}'))", ODataFilterDataType.select, 'Tagged ', null, null, true, this.tagOptions, true, 'with ')
        ]);
    }

    public saveRecordsPerPage = () => {
        this.widget.options.recordsPerPage = this.table.pager.recordsPerPage;
        this.dashboardService.save();
    }

    public saveOrderby = () => {
        this.widget.options.orderby = this.table.sorter.sortBy;
        this.dashboardService.save();
    }

    public buildQuery = () => {

        const query = this.odata.getQuery()
            .orderby(this.table.sorter.sortBy)
            .skip((this.table.pager.currentPage - 1) * this.table.pager.recordsPerPage)
            .top(this.table.pager.recordsPerPage)
            .expand('bond($expand=quote($expand=application))');

        let filterExpr = this.filterCollection.getFilterExpression();
        if (filterExpr) {
            query.filter(filterExpr);
        }

        return query;
    }
    
    public loadGrid = () => {
        if (this.table.sorter.sortBy !== this.widget.options.orderby) {
            this.saveOrderby();
        }

        if (
            this.table.pager.recordsPerPage != 
            this.widget.options.recordsPerPage
        ) {
            this.saveRecordsPerPage();
        }

        const query  = this.buildQuery();

        const dataSvc = this.odata.getService(ODataEndpoint.BondTransaction);

        this.busyIndicator.promise = dataSvc.get<A3ApiResponse<BondTransaction[]>>(query)
            .then((response) => {
                this.table.setData(response.data.value, response.data['@odata.count']);
            })
            .catch(() => {
                this.errorMessage = "An error occurred trying to get the widget data";
            });
    }
    
    public loadSfaaCodes = () => {
        return this.sfaaCodeService.getAllSfaaCodeOptions()
            .then((options) => {
                this.sfaaCodeOptions = options;
            });
    }

    public loadTagOptions = () => {
        return this.tagService.loadTagOptions()
            .then((tagOptions) => {
                this.tagOptions = tagOptions;
            });
    }

    public $onInit = () => {
        this.busyIndicator = {
            message: 'Loading...'
        };

        this.sfaaCodeOptions = [];
        this.tagOptions = [];

        this.loadTagOptions();
        this.loadSfaaCodes();

        if (isDefined(this.widget.options.filters)) {
            this.filterCollection.mergeFilters(this.widget.options.filters);
        }

        if (isDefined(this.widget.options.orderby)) {
            this.table.sorter.sort(this.widget.options.orderby);
        }

        if (isDefined(this.widget.options.recordsPerPage)) {
            this.table.pager.setRecordPerPage({
                value: this.widget.options.recordsPerPage
            });
        }
        
        this.setFilters();

        this.$timeout(() => {
            this.loadGrid();
        });

        this.table.onChange = this.loadGrid;

        this.dashboardService.refreshFunctions.push(this.loadGrid);
    }
}

const dashboardWidgetBondTransactionsCarrierComponent = {
    bindings: { 
        widget: '=' 
    },
    templateUrl: 'app/states/common/dashboard/widgets/bondTransactionCarrier/dashboardWidgetBondTransactionsCarrier.html',
    controller: DashboardWidgetBondTransactionsCarrierController, 
    controllerAs: 'vm'
}

app.component('dashboardWidgetBondTransactionsCarrier', dashboardWidgetBondTransactionsCarrierComponent);