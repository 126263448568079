import { City } from "./city";
import { DateOfBirth } from "./dateOfBirth";
import { IDAdvisorWarnings } from "./iDAdvisorWarnings";
import { RejectCode } from "./rejectCode";
import { Telephone } from "./telephone";
import { Warnings } from "./warnings";

export interface USFraudAdvisor { 
    fraudAdvisorVersion: string;
    fraudAdvisorIndex: string;
    warnings: Warnings;
    rejectCode: RejectCode;
    firstNameCounter: string;
    lastNameCounter: string;
    businessNameCounter: string;
    streetAddressCounter: string;
    ssnCounter: string;
    homePhoneCounter: string;
    workPhoneCounter: string;
    dateOfBirthConfirmed: string;
    driversLicenseConfirmed: string;
    eMailAddressConfirmed: string;
    ssnVerifiedLevel: string;
    numberVerifiedFieldsGTZero: string;
    sumOfVerifiedCounters: string;
    idAdvisorIndex: string;
    idAdvisorWarnings: IDAdvisorWarnings;
    firstName: string;
    lastName: string;
    addressLine1: string;
    addressLine2: string;
    city: City;
    state: string;
    postalCode: string;
    socialSecurityNumber: string;
    dateOfBirth: DateOfBirth;
    telephone: Telephone;
}