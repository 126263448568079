import { IHttpService, IPromise } from "angular";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/settings/systemSettings";
import app from "../main";
import { EpicBillingReportItem } from "../states/agentSpecific/epicBillingReport/epicBillingReportItem";
import { FileDownloader } from "./fileDownloader";
import A3ApiResponse from "./types/a3ApiResponse";
import { EpicBillingReportFilter } from "./types/epicBillingReportFilter";
import { EpicIntegrationLog } from "./types/epicIntegrationLog";
import { UpdateSentToBillingTeamRequest } from "./types/updateSentToBillingTeamRequest";
import { PageResponse } from "./types/pageResponse";

export class EpicIntegrationService{
    public static $inject = [
        Injectables.$http, 
        Injectables.FileDownloader,
        Injectables.SystemSettings
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly fileDownloader: FileDownloader,
        private readonly systemSettings: SystemSettings) {
    }

    public sendBillingReport(billingEntryIds: number[], bondTransactionIds: number[], recipients: string[]): IPromise<any> {
        return this.$http.post(this.systemSettings.apiBaseUrl + 'EpicActions/SendBillingReport', { billingEntryIds: billingEntryIds, bondTransactionIds: bondTransactionIds, recipients: recipients })
            .then(() => {});
    }

    public getBillingReportRecipientAddresses(): IPromise<string[]> {
        return this.$http.get<A3ApiResponse<string[]>>(this.systemSettings.apiBaseUrl + 'EpicActions/GetBillingReportRecipientAddresses')
            .then((response) => response.data.value);
    }

    public updateSentToBillingTeam(requests: UpdateSentToBillingTeamRequest[]): IPromise<any> {
        return this.$http.post(this.systemSettings.apiBaseUrl + 'EpicActions/UpdateSentToBillingTeam', { billingEntries: requests })
            .then(() => {});
    }

    public enteredInEpicChanged(bondTransactionId: number, enteredInEpic: boolean): IPromise<any> {
        return this.$http.post(this.systemSettings.apiBaseUrl + 'EpicActions/UpdateEnteredInEpic', { bondTransactionId: bondTransactionId, enteredInEpic: enteredInEpic})
            .then(() => {});
    }

    public getBillingReportEntries(startDate: Date, endDate: Date, onlyNotEnteredInEpic: boolean, onlyUnsentItems: boolean): IPromise<PageResponse<EpicBillingReportItem>> {
        const filter: EpicBillingReportFilter = {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            onlyNotEnteredInEpic: onlyNotEnteredInEpic,
            onlyUnsentItems: onlyUnsentItems
        } as EpicBillingReportFilter;
        
        return this.$http.post<A3ApiResponse<PageResponse<EpicBillingReportItem>>>(this.systemSettings.apiBaseUrl + 'EpicActions/GetBillingReport', filter)
            .then((response) => response.data.value);
    }

    public downloadExcelBillingReport(billingEntryIds: number[], bondTransactionIds: number[]): IPromise<void> {
        const filter: EpicBillingReportFilter = {
            bondTransactionIds: bondTransactionIds,
            billingEntryIds: billingEntryIds
        } as EpicBillingReportFilter;
        
        return this.fileDownloader.downloadFile(this.systemSettings.apiBaseUrl + 'EpicActions/GetExcelBillingReport', filter);
    }

    public getIntegrationLogs(startDate: Date, endDate: Date): IPromise<EpicIntegrationLog[]> {
        const url = this.systemSettings.apiBaseUrl + 'EpicActions/Logs?startDate=' + startDate.toISOString() + '&endDate=' +endDate.toISOString();

        return this.$http.get<A3ApiResponse<EpicIntegrationLog[]>>(url)
            .then((response) => response.data.value);
    }
}

app.service(Injectables.EpicIntegrationService, EpicIntegrationService);
