import isDefined from "../../utilities/angularUtilities/isDefined";
import { IJQueryService } from "../../utilities/jquery/iJQueryService";

export default (
    elms: JQuery, 
    wrapper: string,
    jQueryService: IJQueryService
) => {

    const wrapperDiv = document.createElement('div');
    wrapperDiv.innerHTML = wrapper;

    if (!elms.length) {
        elms = [elms as unknown] as unknown as JQuery;
    }

    for (let i = elms.length - 1; i >= 0; i--) {
        const el = elms[i];

        const child = wrapperDiv.firstElementChild.cloneNode(true);
        
        const inputElement = jQueryService.getElement(child).find('input');
        
        if (!isDefined(inputElement) || inputElement.length === 0) {
            throw Error('>> input element required for field group strategy.');
        }

        const parent = el.parentNode;
        const sibling = el.nextSibling;

        inputElement.replaceWith(el);

        if (sibling) {
            parent.insertBefore(child, sibling);
        } else {
            parent.appendChild(child);
        }
    }
};