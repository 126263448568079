import { EProducerService } from "../../api/eProducerService";
import { EProducerUser } from "../../api/types/model/eProducerUser";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import app from "../../main";
import { EProducerUserModalOptions } from "./EProducerUserModalOptions";

export class EProducerUserModalController {

    public static $inject = [
        Injectables.EProducerService,
        Injectables.Options,
        Injectables.$uibModalInstance,
        Injectables.ToastMessageCreator
    ];

    constructor (
        private readonly eProducerService: EProducerService,
        private readonly options: EProducerUserModalOptions,
        private readonly $uibModalInstance: Modal<void>,        
        private readonly toastMessageCreator: ToastMessageCreator
    ) {}  

    public user: EProducerUser;
    public eProducerAccountId: number;
    public userExists: boolean;
    public busyIndicator: BusyIndicator;

    public loadUser = () => {
        return this.eProducerService.getUser(this.options.userId)
            .then((user) => {
                this.user = user;
            });
    }

    public save = () => {
        this.userExists = false;
        this.user.eProducerAccountId = this.eProducerAccountId;

        this.busyIndicator.message = 'Saving...';

        if (this.user.id) {
            this.busyIndicator.promise = this.eProducerService.updateUser(this.user);
        } else {
            this.busyIndicator.promise = this.eProducerService.insertUser(this.user);
        }

        this.busyIndicator.promise
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('User has been saved');
                this.$uibModalInstance.close();
            })
            .catch(() => {
                this.userExists = true;
            });
    }

    public cancel = () => {
        this.$uibModalInstance.dismiss('cancel');
    };

    public $onInit = () => {
        this.busyIndicator = {
            message: 'Loading...'
        };

        if (!this.options.eProducerAccountId && !this.options.userId) {
            throw new Error('eProducerAccountId is not valid');
        }

        if (this.options.userId) {
            this.busyIndicator.promise = this.loadUser();
        }

        this.eProducerAccountId = this.options.eProducerAccountId;            
    }
}

app.controller('EProducerUserModalController', EProducerUserModalController);