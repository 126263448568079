import { ISCEService, IScope } from "angular";
import { DocumentService } from "../../api/documentService";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";

class DocumentViewerController {

    public static $inject = [
        Injectables.DocumentService,
        Injectables.$sce,
        Injectables.$scope
    ];
    
    constructor(
        private readonly documentService: DocumentService,
        private readonly $sce: ISCEService,
        private readonly $scope: IScope) {}

    public url: string;
    public objectUrl: string; 

    public trustUrl = (url: string) => {
        return this.$sce.trustAsResourceUrl(url);
    }

    public $onInit() {

        this.$scope.$watch('vm.url', () => {

            this.documentService.downloadDocumentForViewing(this.url)
                .then((objectUrl: string) => {
                    this.objectUrl = objectUrl;
                });
        });
    }
}

const documentViewerComponent = {
    bindings: {
        url: '=',
        class: '@?'
    },
    controller: DocumentViewerController,
    controllerAs: 'vm',
    templateUrl: 'app/components/documentViewer/documentViewer.html',
};

app.component('documentViewer', documentViewerComponent);