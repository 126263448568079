import { SupportService } from "../../api/supportService";
import { BondTypeRequest } from "../../api/types/model/bondTypeRequest";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import app from "../../main";
import { IDeferred, IPromise, IQService } from "angular";
import { SystemSettings } from "../../configuration/settings/systemSettings";

export class BondTypeRequestModalController {

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.ToastMessageCreator,
        Injectables.SupportService,
        Injectables.$q,
        Injectables.SystemSettings
    ];

    constructor(
        private readonly $uibModalInstance: Modal<void>,
        private readonly toastMessageCreator: ToastMessageCreator, 
        private readonly supportService: SupportService, 
        private readonly $q: IQService, 
        private readonly systemSettings: SystemSettings ) {
    }

    public processingDefer: IDeferred<unknown>;
    public request: BondTypeRequest;
    public formDropZone: Dropzone;
    public bondFormName: string;
    public submissionPromise: IPromise<any>;
    public busyIndicator: BusyIndicator;

    public  cancel() {
        this.$uibModalInstance.dismiss('cancel');
    }

    public dropzoneSuccess = (file, response) => {
        this.request.bondFormFileId = response;
        this.processingDefer.resolve();
    }

    public fileAdded = (file) => {
        this.bondFormName = file.name;
    }

    public dropzoneProcessing = (file) => {
        this.formDropZone.options.url = this.systemSettings.apiBaseUrl + "upload/UploadFiles";
    }

    public submit_click = () => {
        this.processingDefer = this.$q.defer();
        this.submissionPromise = this.processingDefer.promise;

        if (this.formDropZone.files.length) {
            this.formDropZone.processQueue();
        } else {
            this.processingDefer.resolve();
        }

        this.busyIndicator.promise = this.submissionPromise
            .then(() => {
                return this.submit();
            });
    }

    public submit = () => {
            return this.supportService.submitBondTypeRequest(this.request)
            .then(() => {
                this.$uibModalInstance.close();
                this.toastMessageCreator.createSuccessMessage('We have submitted your request for a new bond type. We\'ll get back to you a soon as we can.');
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to submit your request.');
            });
    }

    public $onInit = () => {
        this.busyIndicator = { 
            message: 'Submitting'
        };
    }
}

app.controller('BondTypeRequestModalController', BondTypeRequestModalController);