import { Application } from "./application";
import { CompanyContact } from "./companyContact";
import { CompanyLicense } from "./companyLicense";
import { Customer } from "./customer";
import { IBaseEntity } from "./iBaseEntity";
import { IndemnitySignature } from "./indemnitySignature";
import { Lead } from "./lead";

export interface Company extends IBaseEntity { 
    ams360CompanyCode: string;
    application: Application;
    applicationId: number;
    bondId: number;
    bondTransactionId: number;
    companyContacts: CompanyContact[];
    companyLicenses: CompanyLicense[];
    companyType: CompanyType;
    customer: Customer;
    customerId: number;
    dateFormed: Date;
    dba: string;
    email: string;
    fax: string;
    fein: string;
    indemnitySignatures: IndemnitySignature[];
    lead: Lead;
    leadId: number;
    mailAddress: string;
    mailCity: string;
    mailCounty: string;
    mailState: string;
    mailSuiteAptNumber: string;
    mailZip: string;
    name: string;
    numberOfEmployees: number;
    numberOfOwners: number;
    phone: string;
    physicalAddress: string;
    physicalCity: string;
    physicalCounty: string;
    physicalState: string;
    physicalSuiteAptNumber: string;
    physicalZip: string;
    stateOfIncorporation: string;
    uniqueId: string;
    id: number;
    createdDateTime: Date;
    nmlsNumber: string ;
    websiteUrl: string ;
}

export enum CompanyType {	
    Proprietorship = "Proprietorship",
    SCorp = "SCorp",
    CCorp = "CCorp",
    LLC = "LLC",
    Partnership = "Partnership"
}