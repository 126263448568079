import { IPromise } from "angular";
import { CreditReportService } from "../../api/creditReportService";
import { Person } from "../../api/types/model/person";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { ModalOpener } from "../../modals/modalOpener";
import { CreditReportPersonReport } from "../../api/types/model/creditReportPersonReport";

class CreditReportListController {

    public static $inject = [
        Injectables.ModalOpener,
        Injectables.CreditReportService
    ];

    constructor(
        private readonly modalOpener: ModalOpener,
        private readonly creditReportService: CreditReportService) {
    }

    public errorMessage: string;
    public people: CreditReportPersonReport[];
    public processingPromise: IPromise<void>;

    public get notPulledStatus(): CreditReportStatus {
        return CreditReportStatus.NotPulled;
    }

    public get foundStatus(): CreditReportStatus {
        return CreditReportStatus.Found;
    }

    public get notFoundStatus(): CreditReportStatus {
        return CreditReportStatus.NotFound;
    }

    public getCreditReportStatus(person: Person): CreditReportStatus {
        if (!person.creditReports || !person.creditReports.length) {
            return CreditReportStatus.NotPulled;
        }

        if (!person.creditReports[0].reportNotFound) {
            return CreditReportStatus.Found;
        }

        if (person.creditReports[0].reportNotFound) {
            return CreditReportStatus.NotFound;
        }
    }

    public viewReport(person) {
        if (!person.creditReports || !person.creditReports[0]) {
            return;
        }

        this.modalOpener.creditReportModal(person.creditReports[0].id)
            .result
            .then(function(){})
            .catch(function(){});
    }

    public pullCreditReport(person: Person) {
        if (person.creditReports && person.creditReports[0]) {
            return;
        }

        this.processingPromise = this.creditReportService.pullCreditReport(person.id)
            .then((creditReport) => {
                this.errorMessage = '';
                person.creditReports = [];
                person.creditReports.push(creditReport);
            })
            .catch(() => {
                this.errorMessage = 'An error occurred trying to pull the credit report';
            });
    }

    public $onInit(): void {
    }
}

export enum CreditReportStatus {
    NotPulled = 1,
    Found = 2,
    NotFound = 3
}

const creditReportListComponent = {
    templateUrl: 'app/components/creditReportList/creditReportList.html',
    controllerAs: 'vm',
    bindings: {
        people: '='
    },
    controller: CreditReportListController
};

app.component('creditReportList', creditReportListComponent);