import { BondTypeService } from "../../api/bondTypeService";
import { RiderRequestBond } from "../../api/types/riderRequestBond";
import { BondChangeType } from "../../api/types/model/bondChange";
import { BondType } from "../../api/types/model/bondType";
import { BondChangeSelectOption, BondChangeSelectOptions } from "../../api/types/bondChangeType";
import { RiderRequestBondChange } from "../../api/types/riderRequestBondChange";
import { OptionGroup, SelectOption } from "../../api/types/selectOption";
import { Injectables } from "../../configuration/injectables";
import { SearchControl } from "../../utilities/searchControl";
import app from "../../main";
import { IFilterService, ISCEService, IScope, IPromise } from "angular";
import { IMailingAddressFilter } from "../../filters/mailingAddressFilter/mailingAddressFilterType";
import { IPersonNameFilter } from "../../filters/personName/personNameFilterType";
import { FormatType } from "../../filters/physicalAddressFilter/formatType";
import { IPhysicalAddressFilter } from "../../filters/physicalAddressFilter/physicalAddressFilterType";
import toCamel from "../../utilities/stringUtilities/toCamel";
import { SystemAccountService } from "../../api/systemAccountService";

class BondChangeManagerController {
    public bond: RiderRequestBond;
    public changes: RiderRequestBondChange[];
    public changeTypes: BondChangeSelectOption[] = [];
    
    public peopleOptions: SelectOption[];
    public companyOptions: SelectOption[];
    public bondAdditionalQuestionResponsesOptions: SelectOption[];
    public writingCompanyOptionGroups: OptionGroup<number>[];
    
    public bondTypeSearch: SearchControl<BondType>;
    
    public get hasChanges(): boolean {
        return Array.isArray(this.changes) && this.changes.length > 0;
    }

    public static $inject = [
        Injectables.$filter,
        Injectables.$sce,
        Injectables.$scope,
        Injectables.BondTypeService,
        Injectables.SystemAccountService,
    ];

    constructor(
        private readonly $filter: IFilterService,
        private readonly $sce: ISCEService,
        private readonly $scope: IScope,
        private readonly bondTypeService: BondTypeService,
        private readonly systemAccountService: SystemAccountService,
    ) { }

    public $onInit(): void {
        this.bondTypeSearch = new SearchControl();

        this.peopleOptions = [];
        this.companyOptions = [];
        this.bondAdditionalQuestionResponsesOptions = [];

        this.loadWritingCompanyOptionGroups();
        this.loadChangeTypes();
        this.loadChanges();
    }

    private loadChangeTypes(): void {
        this.changeTypes = BondChangeSelectOptions;
    }

    public searchBondTypes(searchPhrase: string): IPromise<void> {
        return this.bondTypeService.dropdownSearch(searchPhrase)
            .then((bondTypes) => {
                this.bondTypeSearch.matches = bondTypes;
            });
    }

    private loadChanges(): void {
        // Although the changes are passed in through a controller binding,
        // they need to be mutated before they can be bound to the 

        this.$scope.$watch('vm.changes', () => {
            if (!this.changes || !this.bond) {
                return; 
            }

            if (!this.peopleOptions.length && this.bond.people) {
                for(let i = 0; i < this.bond.people.length; i++) {
                    this.peopleOptions.push({ value: this.bond.people[i].id, label: this.bond.people[i].firstName + ' ' + this.bond.people[i].lastName});
                }
            }

            if (!this.companyOptions.length && this.bond.companies) {
                for(let i = 0; i < this.bond.companies.length; i++) {
                    this.companyOptions.push({ value: this.bond.companies[i].id, label: this.bond.companies[i].name});
                }
            }

            if (!this.bondAdditionalQuestionResponsesOptions.length && this.bond.bondAdditionalQuestionResponses) {
                for(let i = 0; i < this.bond.bondAdditionalQuestionResponses.length; i++) {
                    this.bondAdditionalQuestionResponsesOptions.push({ value: this.bond.bondAdditionalQuestionResponses[i].id, label: this.bond.bondAdditionalQuestionResponses[i].questionText});
                }
            }

            for (const change of this.changes) {
                this.setTypeFlags(change);
                this.parseBindingsFromNewValue(change);
            }
        });
    }
    
    private parseBindingsFromNewValue(change: RiderRequestBondChange): void {
        const newValueString = typeof change.newValue === 'object'
            ? this.$sce.getTrustedHtml(change.newValue)
            : change.newValue;

        switch (change.changeType) {
            case BondChangeType.IndividualName: {
                const parts: string[] = newValueString.split('{|}');
                change['$newValue_individualPrefix'] = parts[0];
                change['$newValue_individualFirstName'] = parts[1];
                change['$newValue_individualMiddleName'] = parts[2];
                change['$newValue_individualLastName'] = parts[3];
                change['$newValue_individualSuffix'] = parts[4];
                break;
            }
            case BondChangeType.IndividualSpouseName: {
                const parts: string[] = newValueString.split('{|}');
                change['$newValue_individualSpouseFirstName'] = parts[0];
                change['$newValue_individualSpouseMiddleName'] = parts[1];
                change['$newValue_individualSpouseLastName'] = parts[2];
                break;
            }
            case BondChangeType.IndividualPhysicalAddress: {
                const parts: string[] = newValueString.split('{|}');
                change['$newValue_individualAddress'] = parts[0];
                change['$newValue_individualApptNumber'] = parts[1];
                change['$newValue_individualCity'] = parts[2];
                change['$newValue_individualState'] = parts[3];
                change['$newValue_individualZip'] = parts[4];
                change['$newValue_individualCounty'] = parts[5];
                break;
            }
            case BondChangeType.IndividualMailingAddress: {
                const parts: string[] = newValueString.split('{|}');
                change['$newValue_individualMailAddress'] = parts[0];
                change['$newValue_individualMailApptNumber'] = parts[1];
                change['$newValue_individualMailCity'] = parts[2];
                change['$newValue_individualMailState'] = parts[3];
                change['$newValue_individualMailZip'] = parts[4];
                change['$newValue_individualMailCounty'] = parts[5];
                break;
            }
            case BondChangeType.IndividualEmployerAddress: {
                const parts: string[] = newValueString.split('{|}');
                change['$newValue_individualEmployerAddress'] = parts[0];
                change['$newValue_individualEmployerApptNumber'] = parts[1];
                change['$newValue_individualEmployerCity'] = parts[2];
                change['$newValue_individualEmployerState'] = parts[3];
                change['$newValue_individualEmployerZip'] = parts[4];
                change['$newValue_individualEmployerCounty'] = parts[5];
                break;
            }
            case BondChangeType.CompanyPhysicalAddress: {
                const parts: string[] = newValueString.split('{|}');
                change['$newValue_companyAddress'] = parts[0];
                change['$newValue_companyApptNumber'] = parts[1];
                change['$newValue_companyCity'] = parts[2];
                change['$newValue_companyState'] = parts[3];
                change['$newValue_companyZip'] = parts[4];
                change['$newValue_companyCounty'] = parts[5];
                break;
            }
            case BondChangeType.CompanyMailingAddress: {
                const parts: string[] = newValueString.split('{|}');
                change['$newValue_companyMailAddress'] = parts[0];
                change['$newValue_companyMailApptNumber'] = parts[1];
                change['$newValue_companyMailCity'] = parts[2];
                change['$newValue_companyMailState'] = parts[3];
                change['$newValue_companyMailZip'] = parts[4];
                change['$newValue_companyMailCounty'] = parts[5];
                break;
            }
            case BondChangeType.ObligeePhysicalAddress: {
                const parts: string[] = newValueString.split('{|}');
                change['$newValue_obligeePhysicalAddress'] = parts[0];
                change['$newValue_obligeePhysicalApptNumber'] = parts[1];
                change['$newValue_obligeePhysicalCity'] = parts[2];
                change['$newValue_obligeePhysicalState'] = parts[3];
                change['$newValue_obligeePhysicalZip'] = parts[4];
                change['$newValue_obligeePhysicalCounty'] = parts[5];
                break;
            }
            case BondChangeType.ObligeeMailAddress: {
                const parts: string[] = newValueString.split('{|}');
                change['$newValue_obligeeMailAddress'] = parts[0];
                change['$newValue_obligeeMailApptNumber'] = parts[1];
                change['$newValue_obligeeMailCity'] = parts[2];
                change['$newValue_obligeeMailState'] = parts[3];
                change['$newValue_obligeeMailZip'] = parts[4];
                change['$newValue_obligeeMailCounty'] = parts[5];
                break;
            }
            case BondChangeType.BondTypeId: {
                change['$newValue_bondType'] = { 
                    id: change.newValue, 
                    name: change.newValueText 
                };

                break;
            }
            
            default:
                change['$newValue_' + toCamel(change.changeType.toString())] = change.newValue;
                break;
        }
    }

    public changeTypeSelected(change: RiderRequestBondChange): void {
        this.setTypeFlags(change);

        if (change.$isPerson && change.personId && change.personId > 0) {
            this.personSelected(change);
        } else if (change.$isCompany && change.companyId && change.companyId > 0) {
            this.companySelected(change);
        } else if (change.$isQuestion && change.bondAdditionalQuestionResponseId && change.bondAdditionalQuestionResponseId > 0) {
            this.questionSelected(change);
        } else if (change.$isObligee) {
            this.setupObligeeChoice(change);
        } else if (change.changeType === BondChangeType.BondTypeId) {
            change.originalValue = this.bond.bondTypeName;
        } else if (change.changeType === BondChangeType.WritingCompanyId) {
            change.originalValue = this.bond.writingCompanyName;
        } else {
            change.originalValue = this.bond[toCamel(change.changeType.toString())];
        }
    }
    
    private setTypeFlags(change: RiderRequestBondChange): void {
        change.$isQuestion = change.changeType === BondChangeType.BondFormInformation;
        change.$isPerson = change.changeType.toString().startsWith('Individual');
        change.$isCompany = change.changeType.toString().startsWith('Company');
        change.$isObligee = change.changeType.toString().startsWith('Obligee');
    }

    public personSelected(change: RiderRequestBondChange): void {
        const selectedPerson = this.bond.people.find((p) => p.id === change.personId);

        if (selectedPerson !== undefined) {
            change.$selectedPerson = { ...selectedPerson };
            this.setupPersonChoice(change);
        }
    }

    private setupPersonChoice(change: RiderRequestBondChange): void {
        const person = change.$selectedPerson;

        if (change.changeType === BondChangeType.IndividualName) {
            change.originalValue = this.$filter<IPersonNameFilter>('personName')(person);
        } else if (change.changeType === BondChangeType.IndividualPhysicalAddress) {
            change.originalValue = this.$filter<IPhysicalAddressFilter>('physicalAddress')(person, FormatType.TwoLine);
        } else if (change.changeType === BondChangeType.IndividualMailingAddress) {
            change.originalValue = this.$filter<IMailingAddressFilter>('mailingAddress')(person, FormatType.TwoLine);
        } else {
            change.originalValue = person[toCamel(change.changeType.toString().replace('Individual', ''))];
        }
    }

    public companySelected(change: RiderRequestBondChange): void {
        const selectedCompany = this.bond.companies.find((c) => c.id === change.companyId);

        if (selectedCompany !== undefined) {
            change.$selectedCompany = { ...selectedCompany };
            this.setupCompanyChoice(change);
        }
    }

    private setupCompanyChoice(change: RiderRequestBondChange): void {
        const company = change.$selectedCompany;

        if (change.changeType === BondChangeType.CompanyPhysicalAddress) {
            change.originalValue = this.$filter<IPhysicalAddressFilter>('physicalAddress')(company, FormatType.TwoLine);
        } else if (change.changeType === BondChangeType.CompanyMailingAddress) {
            change.originalValue = this.$filter<IMailingAddressFilter>('mailingAddress')(company, FormatType.TwoLine);
        } else {
            change.originalValue = company[toCamel(change.changeType.toString().replace('Company', ''))];
        }
    }

    public questionSelected(change: RiderRequestBondChange): void {
        const question = this.bond.bondAdditionalQuestionResponses.find((q) => q.id === change.bondAdditionalQuestionResponseId);

        if (question !== undefined) {
            change.$selectedQuestion = { ...question } as any;
            this.setupQuestionChoice(change);
        }
    }

    private setupQuestionChoice(change: RiderRequestBondChange): void {
        const questionResponse = change.$selectedQuestion;

        change.originalValue = questionResponse.responseText;
    }

    public setupObligeeChoice(change: RiderRequestBondChange): void {
        const obligee = this.bond.obligee;

        switch (change.changeType) {
            case BondChangeType.ObligeeMailAddress: {
                change.originalValue = this.$filter<IMailingAddressFilter>('mailingAddress')(obligee, FormatType.TwoLine);
                break;
            }

            case BondChangeType.ObligeePhysicalAddress: {
                change.originalValue = this.$filter<IPhysicalAddressFilter>('physicalAddress')(obligee, FormatType.TwoLine);
                break;
            }

            default: {
                change.originalValue = this.bond.obligee[toCamel(change.changeType.toString().replace('Obligee', ''))];
                break;
            }
        }
    }

    public loadWritingCompanyOptionGroups(): IPromise<void> {
        return this.systemAccountService.getWritingCompanyOptionGroupsByCarrierId(this.bond.carrierId)
            .then((optionGroups) => {
                this.writingCompanyOptionGroups = optionGroups;
            });
    }

    public renderChangeTypeAsDate(changeType: BondChangeType): boolean {
        return changeType === BondChangeType.CompanyDateFormed
            || changeType === BondChangeType.EffectiveDate
            || changeType === BondChangeType.ExpirationDate
            || changeType === BondChangeType.IndividualDateOfBirth
            || changeType === BondChangeType.IndividualSpouseDateOfBirth
            || changeType === BondChangeType.IndividualDateMovedToResidence;
    }

    public renderChangeTypeAsCurrency(changeType: BondChangeType): boolean {
        return changeType === BondChangeType.BondAmount;
    }

    public renderChangeTypeAsExpression(changeType: BondChangeType): boolean {
        return changeType === BondChangeType.BondFormInformation;
    }

    public renderChangeTypeAsHtml(changeType: BondChangeType): boolean {
        const changeTypeHasRenderer = this.renderChangeTypeAsCurrency(changeType)
            || this.renderChangeTypeAsDate(changeType)
            || this.renderChangeTypeAsExpression(changeType);

        return !changeTypeHasRenderer;
    }

    public addChange(): void {
        this.changes.unshift({ originalValue: '', newValue: '' } as RiderRequestBondChange);
    }

    public removeChange(index: number): void {
        this.changes.splice(index, 1);
    }
}

const bondChangeManagerComponent = {
    bindings: {
        bond: '=',
        changes: '=?'
    },
    controller: BondChangeManagerController,
    controllerAs: 'vm',
    templateUrl: 'app/components/bondChangeManager/bondChangeManager.html'
};

app.component('bondChangeManager', bondChangeManagerComponent);