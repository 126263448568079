import { EProducerService } from "../../api/eProducerService";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import app from "../../main";

export class InviteBrokerModalController {

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.EProducerService,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly $uibModalInstance: Modal<any>,
        private readonly eProducerService: EProducerService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {}

    public email: string;
    public busyIndicator: BusyIndicator; 
    
    public cancel() {
        this.$uibModalInstance.dismiss();
    }

    public send() {
        this.busyIndicator.message = 'Sending Invite...';
        this.busyIndicator.promise = this.eProducerService.sendBrokerInvite(this.email)
            .then(() => {
                this.$uibModalInstance.close({});
                this.toastMessageCreator.createSuccessMessage('Broker invite was sent successfully');
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to send the broker invite');
            });
    }

    public $onInit() {
        this.busyIndicator = {};
    }
}

app.controller('InviteBrokerModalController', InviteBrokerModalController);


