import { AnalyticsService } from "../../../../../api/analyticsService";
import { CacheFactory } from "../../../../../api/types/cacheFactory";
import { CacheStore } from "../../../../../api/types/cacheStore";
import { SelectOption } from "../../../../../api/types/selectOption";
import { BusyIndicator } from "../../../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../../../configuration/injectables";
import { DashboardService } from "../../dashboardService";
import { DashboardWidget } from "../../types/dashboardWidget";
import app from "../../../../../main";
import * as angular from "angular";
import { IScope } from "angular";
import * as moment from "moment";
import { JQueryService } from "../../../../../utilities/jquery/jQueryService";
import { AmChartService, AgentActivityTrackerChartOptions } from "../../../../../utilities/amCharts/amChartService";
import { constants } from "../../../../../configuration/constants";
import DropdownOption from "../../../../../api/types/dropdownOption";


export type StartEndDate = {
    startDate: moment.Moment;
    endDate: moment.Moment
}

type DashboardWidgetActivityTrackerFilterOptions = {
    date: StartEndDate;
    transactionTypes: SelectOption<string>[];
};

class DashboardWidgetActivityTrackerController {

    static $inject = [
        Injectables.AnalyticsService,
        Injectables.DashboardService,
        Injectables.AmChartService
    ];

    constructor(
        private readonly analyticsService: AnalyticsService,
        private readonly dashboardService: DashboardService,
        private readonly amChartService: AmChartService
    ) {}

    public widget: DashboardWidget;
    public busyIndicator: BusyIndicator;
    public widgetIndex: number;
    public transactionTypeOptions: DropdownOption<string>[];
    public dateFilter: { startDate: Date, endDate: Date };

    public transactionTypeSelected = (selectedOption: DropdownOption<string>) => {
        this.widget.options.filters.transactionType = selectedOption;
        this.loadChart();
        this.dashboardService.save();
    }

    public loadChart = () => {
        this.busyIndicator.promise = this.analyticsService.getActivities()
            .then((data) => {
                
                const chartOptions: AgentActivityTrackerChartOptions = {
                    inquiriesHidden: this.widget.options.inquiryCountIsHidden,
                    prospectsHidden:this.widget.options.proscpectsIsHidden,
                    submissionsHidden: this.widget.options.submissionsIsHidden,
                    newBusinessHidden: this.widget.options.newBusineessIsHidden,
                    onDataVisiblityChanged: this.toggleDataVisiblity
                };

                this.amChartService.makeAgentActivityTrackerChart("chartdiv" + this.widgetIndex, data, chartOptions);
            });
    };

    public toggleDataVisiblity = (evt) => {
        this.widget.options[evt.dataItem.valueField + 'IsHidden'] = evt.dataItem.hidden;
        this.dashboardService.save();
    };

    public loadTransactionTypes = () => {
        this.transactionTypeOptions = constants.transactionTypeDropdownOptions;
        this.transactionTypeOptions.unshift(
            { label: 'All Transaction Types', value: null }, 
            { isDivider: true }
        );
        this.transactionTypeOptions.push(
            { label: 'Inquiries', value: 'Inquiries' },
            { label: 'Prospects', value: 'Prospects' },
            { label: 'Close Ratio', value: 'Close Ratio' }
        )
    }

    public $onInit = () => {
        this.busyIndicator = {
            message: 'Loading...'
        };
        
        this.dateFilter = {
            startDate: moment().subtract(1, 'years').toDate(),
            endDate: moment().subtract(1, 'days').toDate()
        }
        
        this.loadTransactionTypes();
       
        this.loadChart();
        this.dashboardService.refreshFunctions.push(this.loadChart);
    }
}

const dashboardWidgetActivityTrackerComponent = {
    bindings: { 
        widget: '=', 
        widgetIndex: '<',
    },
    templateUrl: 'app/states/common/dashboard/widgets/activityTrackerAgent/dashboardWidgetActivityTracker.html',
    controller: DashboardWidgetActivityTrackerController,
    controllerAs: 'vm'
}

app.component('dashboardWidgetActivityTracker', dashboardWidgetActivityTrackerComponent);