import { AnalyticsService } from "../../../../../api/analyticsService";
import { BusyIndicator } from "../../../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../../../configuration/injectables";
import { DashboardService } from "../../dashboardService";
import { DashboardWidget } from "../../types/dashboardWidget";
import app from "../../../../../main";
import { AmChartService } from "../../../../../utilities/amCharts/amChartService";
import { ITimeoutService } from "angular";

class DashboardWidgetBondsByCarrierBreakdownAgentController {
    public static $inject = [
        Injectables.AnalyticsService,
        Injectables.DashboardService,
        Injectables.AmChartService,
        Injectables.$timeout
    ];

    constructor(
        private readonly analyticsService: AnalyticsService,
        private readonly dashboardService: DashboardService,
        private readonly amChartService: AmChartService,
        private readonly $timeout: ITimeoutService
    ) {}

    public widget: DashboardWidget;
    public busyIndicator: BusyIndicator;
    public widgetIndex: number;
    public amCharts: any;

    public loadChart = () => {
        this.busyIndicator.promise = this.analyticsService
            .getActiveBondCarrierBreakdown()
            .then((data) => {
                this.$timeout(() => {
                    this.amChartService.makePieChart(
                        "pieChartDiv" + this.widgetIndex,
                        data
                    );
                });
            });
    };

    public $onInit = () => {
        this.amCharts = (window as any).AmCharts;

        this.busyIndicator = {
            message: "Loading..."
        };

        this.dashboardService.refreshFunctions.push(this.loadChart);
        this.loadChart();
    };
}

const dashboardWidgetBondsByCarrierBreakdownAgentComponent = {
    bindings: {
        widget: "=",
        widgetIndex: "<"
    },
    templateUrl:
        "app/states/common/dashboard/widgets/bondsByCarrier/dashboardWidgetBondsByCarrierBreakdownAgent.html",
    controller: DashboardWidgetBondsByCarrierBreakdownAgentController,
    controllerAs: "vm"
};

app.component(
    "dashboardWidgetBondsByCarrierBreakdownAgent",
    dashboardWidgetBondsByCarrierBreakdownAgentComponent
);
