import { IFilterService } from "angular";
import { SelectOption } from "../types/selectOption";

type ODataFilterParameters = {
    navigationExpression: string;
    dataType: string;
    label: string;
    value?: string;
    maxValue?: string;
    isVisible?: boolean;
    selectOptions?: SelectOption<any>[];
    isString?: boolean;
    comparisonText?: string;
    $filter: IFilterService;
};

export default ODataFilterParameters;
