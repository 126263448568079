import { Bond } from "../bond";
import { Fee } from "./fee";
import { IBaseEntity } from "./iBaseEntity";
import { SystemAccount } from "./systemAccount";
import { User } from "./user";

export interface ReinstatementRequest extends IBaseEntity { 
    agencySystemAccountId: number;
    carrierSystemAccountId: number;
    agencyComments: string;
    agencySystemAccount: SystemAccount;
    attorneyInFactUserId: number;
    bond: Bond;
    bondId: number;
    carrierComments: string;
    carrierSystemAccount: SystemAccount;
    commission: number;
    effectiveDate: Date;
    fees: Fee[];
    premiumIncrease: number;
    reasonCode: string;
    reasonDescription: string;
    requestedEffectiveDate: Date;
    status: ReinstatementStatus;
    user: User;
    userId: number;
    id: number;
    createdDateTime: Date;
}

export enum ReinstatementStatus {	
    None = "None",
    Requested = "Requested",
    Approved = "Approved",
    Declined = "Declined"
}