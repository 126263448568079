import { IndemnityStatus, IndemnityType } from "./model/indemnitySignature";

export interface IndemnityDto {
    clientIp?: string;
    companyId?: number;
    printedName: string;
    emailedToAddress: string;
    emailSentDateTime?: string;
    indemnityType: IndemnityType;
    personId: number;
    quoteId: number;
    signature?: string;
    signatureDateTime?: Date;
    status: IndemnityStatus;
    uniqueId?: string;
    id?: number;
    isRemoved?: boolean;
    send?: boolean;
}
