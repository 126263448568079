import { ODataEndpoint } from "../../api/odata";
import { IPromise } from "angular";



export type DeleteConfirmationModalOptions = {
    errorMessage?: string;
    header: string;
    deleteFunction?: (key: any) => IPromise<any>;
    successMessage?: string;
    message: string;
    key: any;
    endpoint: ODataEndpoint;
};
