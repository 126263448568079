import { Injectables } from "../configuration/injectables";
import { UtilityService } from "../utilities/utilityService";
import { ODataFactory, ODataEndpoint } from "./odata";
import { AccountsByAccountType } from "./types/accountsByAccountType";
import { UiApplication } from "./types/uiApplication";
import { BankruptcyDisplay } from "./types/bankruptcyDisplay";
import { Bond } from "./types/model/bond";
import { CollectionsDisplay } from "./types/collectionsDisplay";
import { CreditAccountsDisplay } from "./types/creditAccountsDisplay";
import { EquifaxCreditReport } from "./types/creditReport";
import { CreditReportServiceMonth } from "./types/creditReportServiceMonth";
import { BalanceAmount } from "./types/equifax/balanceAmount";
import { CreditLimit } from "./types/equifax/creditLimit";
import { Narrative } from "./types/equifax/narrative";
import { USConsumerCreditReport } from "./types/equifax/uSConsumerCreditReport";
import { HistoryByYear } from "./types/historyByYear";
import { HistoryYear } from "./types/historyYear";
import { CreditReport, Bureau } from "./types/model/creditReport";
import { Person } from "./types/model/person";
import { PaymentHistory } from "./types/paymentHistory";
import { TaxLienDisplay } from "./types/taxLienDisplay";
import { History } from "./types/history";
import A3ApiResponse from "./types/a3ApiResponse";
import app from "../main";
import { IQService, IHttpService, IPromise } from "angular";
import * as moment from "moment";
import { SystemSettings } from "../configuration/settings/systemSettings";
import { CreditReportPersonReport } from "./types/model/creditReportPersonReport";

export class CreditReportService {

    public static $inject = [
        Injectables.$q,
        Injectables.$http,
        Injectables.UtilityService,
        Injectables.ODataFactory,
        Injectables.SystemSettings
    ];

    constructor(
        readonly $q: IQService,
        private readonly $http: IHttpService,
        private readonly utilityService: UtilityService,
        private readonly odata: ODataFactory, 
        private readonly systemSettings: SystemSettings
        ) {
    }

    public pullCreditReport(personId: number): IPromise<CreditReport> {
        return this.$http
            .post<A3ApiResponse<CreditReport>>(this.systemSettings.apiBaseUrl + 'CreditReportActions/PullCreditReport', { personId: personId })
            .then((response) => response.data.value);
    }

    public buildAccountInformation(rpt: USConsumerCreditReport): AccountsByAccountType {
        if (!rpt.usTrades) { return null; }
        if (!rpt.usTrades.usTrade) { return null; }

        const accts: CreditAccountsDisplay[] = [];
        const trades = rpt.usTrades.usTrade;
        const noval = '--';

        for (const acct of trades) {
            accts.push({
                accountStatus         : acct.activityDesignator ? acct.activityDesignator.description : noval,
                accountType           : acct.accountType ? acct.accountType.description : noval,
                balance               : acct.balanceAmount ? this.utilityService.formatCurrency(parseInt(acct.balanceAmount.text, 10)) : noval,
                closedDate            : acct.dateClosed ? this.utilityService.getDateFromStringMMDDYYYY(acct.dateClosed.text).formattedDate : noval,
                creditUtilization     : this.getCreditUtilization(acct.creditLimit, acct.balanceAmount, noval),
                creditorInformation   : acct.creditorId.name,
                creditorName          : acct.creditorId ? acct.creditorId.name : noval,
                dateOfLastPayment     : acct.dateOfLastPayment ? this.utilityService.getDateFromStringMMDDYYYY(acct.dateOfLastPayment.text).formattedDate : noval,
                highestBalance        : acct.highCreditAmount ? this.utilityService.formatCurrency(parseInt(acct.highCreditAmount.text, 10)) : noval,
                lastReported          : acct.dateReported ? this.utilityService.getDateFromStringMMDDYYYY(acct.dateReported.text).formattedDate : noval,
                limit                 : acct.creditLimit ? this.utilityService.formatCurrency(parseInt(acct.creditLimit.text, 10)) : noval,
                montlyPayment         : acct.scheduledPaymentAmount ? this.utilityService.formatCurrency(parseInt(acct.scheduledPaymentAmount.text, 10)) : noval,
                openedDate            : acct.dateOpened ? this.utilityService.getDateFromStringMMDDYYYY(acct.dateOpened.text).formattedDate : noval,
                originalPaymentHistory: acct.paymentHistory,
                paymentHistory        : acct.paymentHistory ? this.buildPaymentHistory(acct.paymentHistory, acct.dateReported.text) : null,
                paymentStatus         : acct.status ? acct.status.description : noval,
                responsibility        : acct.accountDesignator ? acct.accountDesignator.description : noval,
                term                  : acct.termsFrequency ? acct.termsFrequency.description : noval,
            });
        }

        return this.utilityService.groupBy<CreditAccountsDisplay>(accts, 'accountType') as AccountsByAccountType;
    }

    public buildDescriptionList(itemArray: Narrative[]): string[] {
        if (!itemArray || itemArray.length === 0) { return null; }

        const descriptions: string[] = [];

        for (const item of itemArray) {
            descriptions.push(item.description);
        }
        return descriptions;
    }

    public getCreditReportsByApplicationIdNew(applicationId: number): IPromise<CreditReportPersonReport[]> {
        const url = this.systemSettings.apiBaseUrl + 'CreditReportActions/GetCreditReportsByApplicationId?applicationId=' + applicationId;

        return this.$http
            .get<A3ApiResponse<CreditReportPersonReport[]>>(url)
            .then((response) => response.data.value);
    }

    public getCreditReportsByApplicationId(applicationId: number): IPromise<Person[]> {
        const svc = this.odata.getService<UiApplication>(ODataEndpoint.Application);

        svc.query.expand('people($expand=creditReports;$select=id,firstName,lastName,creditReports)');
        svc.query.filter(`id eq ${applicationId}`);
        svc.query.setAdditionalParameters('includeDeclined=true');
        svc.query.select('people');

        return svc.get().then((response) => response.data.value[0].people);
    }

    public getCreditReportsByBondId(bondId: number): IPromise<Person[]> {
        const svc = this.odata.getService<Bond>(ODataEndpoint.Bond);

        svc.query.expand('quote($expand=application($expand=people($expand=creditReports;$select=id,firstName,lastName,creditReports)))');
        svc.query.filter(`id eq ${bondId}`);
        svc.query.setAdditionalParameters('includeDeclined=true');
        svc.query.select('quote');

        return svc.get().then((response) => response.data.value[0].quote.application.people);
    }


    public buildBankruptcyInformation(rpt: USConsumerCreditReport): BankruptcyDisplay[] {
        if (!rpt.usBankruptcies) { return null; }
        if (!rpt.usBankruptcies.usBankruptcy) { return null; }

        const cases: BankruptcyDisplay[] = [];
        const bankruptcies = rpt.usBankruptcies.usBankruptcy;
        const noValueIndicator: string = '--';

        for (const bankruptcy of bankruptcies) {
            cases.push({
                assetAmount: bankruptcy.assetAmount ? this.utilityService.formatCurrency(parseInt(bankruptcy.assetAmount.text, 10)) : noValueIndicator,
                caseNumber: bankruptcy.caseNumber ? bankruptcy.caseNumber : noValueIndicator,
                courtName: (bankruptcy.courtId && bankruptcy.courtId.name) ? bankruptcy.courtId.name : noValueIndicator,
                customerNumber: (bankruptcy.courtId && bankruptcy.courtId.customerNumber) ? bankruptcy.courtId.customerNumber : noValueIndicator,
                dateFiled: bankruptcy.dateFiled ? bankruptcy.dateFiled.text : noValueIndicator,
                dateReported: bankruptcy.dateReported ? bankruptcy.dateReported.text : noValueIndicator,
                disposition: bankruptcy.disposition ? (bankruptcy.disposition.description || noValueIndicator) + ' - ' + (bankruptcy.disposition.date ? bankruptcy.disposition.date.text : 'No Disposition Date') : noValueIndicator,
                exemptAmount: bankruptcy.exemptAmount ? this.utilityService.formatCurrency(parseInt(bankruptcy.exemptAmount.text, 10)) : noValueIndicator,
                filer: bankruptcy.filer ? bankruptcy.filer.description : noValueIndicator,
                industry: (bankruptcy.courtId && bankruptcy.courtId.industry) ? bankruptcy.courtId.industry.description : noValueIndicator,
                liabilityAmount: bankruptcy.liabilityAmount
                    ? this.utilityService.formatCurrency(parseInt(bankruptcy.liabilityAmount.text, 10))
                    : noValueIndicator,
                narratives: bankruptcy.narratives ? this.buildDescriptionList(bankruptcy.narratives.narrative) : null,
                priorIntentCode: bankruptcy.priorIntentCode ? bankruptcy.priorIntentCode.description : noValueIndicator,
                type: bankruptcy.type ? bankruptcy.type.description : noValueIndicator,
                verificationDate: bankruptcy.verificationDate ? bankruptcy.verificationDate.text : noValueIndicator,
            });
        }
        return cases;
    }

    public buildCollectionInformation(rpt: USConsumerCreditReport): CollectionsDisplay[] {
        if (!rpt.usCollections) { return null; }
        if (!rpt.usCollections.usCollection) { return null; }

        const cases: CollectionsDisplay[] = [];
        const collections = rpt.usCollections.usCollection;
        const noval: string = '--';

        for (const c of collections) {
            cases.push({
                accountDesignator: c.accountDesignator ? c.accountDesignator.description : noval,
                accountNumber: c.accountNumber ? c.accountNumber : noval,
                assignedDate: c.assignedDate ? c.assignedDate.text : noval,
                balanceAmount: c.balanceAmount ? this.utilityService.formatCurrency(parseInt(c.balanceAmount.text, 10)) : noval,
                balanceDate: c.balanceDate ? c.balanceDate.text : noval,
                clientNameNumber: c.clientNameNumber ? c.clientNameNumber : noval,
                courtName: (c.customerId && c.customerId.name) ? c.customerId.name : noval,
                creditorClassification: c.creditorClassification ? c.creditorClassification.description : noval,
                customerNumber: (c.customerId && c.customerId.customerNumber) ? c.customerId.customerNumber : noval,
                dateOfFirstDelinquency: c.dateOfFirstDelinquency ? c.dateOfFirstDelinquency.text : noval,
                dateOfLastPayment: c.dateOfLastPayment ? c.dateOfLastPayment.text : noval,
                dateReported: c.dateReported ? c.dateReported.text : noval,
                industry: (c.customerId && c.customerId.industry) ? c.customerId.industry.description : noval,
                narratives: c.narratives ? this.buildDescriptionList(c.narratives.narrative) : null,
                originalAmount: c.originalAmount ? this.utilityService.formatCurrency(parseInt(c.originalAmount.text, 10)) : noval,
                status: c.status ? c.status.description : noval,
                statusDate: c.statusDate ? c.statusDate.text : noval,
                updateIndicator: c.updateIndicator ? c.updateIndicator.description : noval,
            });
        }
        return cases;
    }

    public buildPaymentHistory(text: string, dateReported: string): HistoryYear[] {
        if (!text) {
            return null;
        }

        // adjust text if shorter than 25
        text = this.padText(text);

        const momentDate = this.utilityService.getDateFromStringMMDDYYYY(dateReported).momentDate;
        const monthArray: CreditReportServiceMonth[] = [];

        for (let i = 0; i < 24; i++) {
            const monthsAgo = i + 1;
            const current = moment(momentDate).subtract(monthsAgo, 'month');
            monthArray.push({
                date: current,
                index: 23 - i,
                year: current.year(),
            });
        }

        const history: History[] = [];

        for (let j = 0; j < 24; j++) {

            history.push({
                month: this.utilityService.getMonthNameFromNumber(this.getByIndex(monthArray, j).date.month() + 1, 'M'),
                monthNumber: this.getByIndex(monthArray, j).date.month() + 1,
                value: this.formatPaymentHistory(text[j < 12 ? j : j + 1]),
                year: this.getByIndex(monthArray, j).date.year(),
            });
        }
        return this.formatHistory(history);
    }

    public buildTaxLienInformation(rpt: USConsumerCreditReport): TaxLienDisplay[] {
        if (!rpt.usTaxLiens) { return null; }
        if (!rpt.usTaxLiens.usTaxLien) { return null; }

        const cases: TaxLienDisplay[] = [];
        const taxLiens = rpt.usTaxLiens.usTaxLien;
        const noval: string = '--';

        for (const tl of taxLiens) {
            cases.push({
                amount        : tl.amount ? this.utilityService.formatCurrency(parseInt(tl.amount.text, 10)) : noval,
                caseNumber    : tl.caseNumber ? tl.caseNumber : noval,
                courtName     : (tl.courtId && tl.courtId.name) ? tl.courtId.name : noval,
                customerNumber: (tl.courtId && tl.courtId.customerNumber) ? tl.courtId.customerNumber : noval,
                dateFiled     : tl.dateFiled ? tl.dateFiled.text : noval,
                dateReported  : tl.dateReported ? tl.dateReported.text : noval,
                industry      : (tl.courtId && tl.courtId.industry) ? tl.courtId.industry.description : noval,
                lienClass     : tl.lienClass ? tl.lienClass.description : noval,
                lienStatus    : tl.lienStatus ? tl.lienStatus.description : noval,
                narratives    : tl.narratives ? this.buildDescriptionList(tl.narratives.narrative) : null,
                releaseDate   : tl.releaseDate ? tl.releaseDate.text : noval,
            });
        }
        return cases;
    }

    public formatHistory(history: History[]): HistoryYear[] {
        const historyByYear: HistoryByYear = this.utilityService.groupBy<History>(history, 'year') as HistoryByYear;
        const historyYears: HistoryYear[] = [];

        for (const property in historyByYear) {
            if (historyByYear.hasOwnProperty(property)) {
                historyYears.push({
                    history: historyByYear[property],
                    year: property,
                });
            }
        }

        return this.padHistory(historyYears);
    }

    public formatPaymentHistory(val: string): PaymentHistory {
        let formattedPaymentHistory: PaymentHistory;
        switch (val) {
            case '*':
                formattedPaymentHistory = { css: 'payment-history-na', text: ' ' };
                break;
            case '2':
                formattedPaymentHistory = { css: 'payment-history-two', text: '2' };
                break;
            case '3':
                formattedPaymentHistory = { css: 'payment-history-three', text: '3' };
                break;
            case '4':
                formattedPaymentHistory = { css: 'payment-history-four', text: '4' };
                break;
            case '5':
                formattedPaymentHistory = { css: 'payment-history-greater-than-four', text: '>4' };
                break;
            case '6':
                formattedPaymentHistory = { css: 'payment-history-collection', text: 'C' };
                break;
            case '7':
                formattedPaymentHistory = { css: 'payment-history-thirteen', text: '13' };
                break;
            case '8':
                formattedPaymentHistory = { css: 'payment-history-repossession', text: 'R' };
                break;
            case '9':
                formattedPaymentHistory = { css: 'payment-history-charge-off', text: 'CH' };
                break;
            case 'E':
                formattedPaymentHistory = { css: 'payment-history-ok', text: 'OK' };
                break;
            case '1':
                formattedPaymentHistory = { css: 'payment-history-ok', text: 'OK' };
                break;

            default:
                formattedPaymentHistory = { css: '', text: ' ' };
                break;
        }
        return formattedPaymentHistory;
    }

    public getById(id: number): IPromise<USConsumerCreditReport | CreditReport> {
        return this.$http
            .get<A3ApiResponse<EquifaxCreditReport>>(this.systemSettings.apiBaseUrl + 'CreditReportActions/Get?id=' + id)
            .then((response) => {

                const creditReport = response.data.value;
                let rptObject: USConsumerCreditReport = null;

                if (creditReport.reportNotFound) {
                    return creditReport;
                }

                if (creditReport.bureau === Bureau.Experian) {
                    return creditReport;
                }

                if (creditReport.bureau === Bureau.EquifaxV6) {
                    rptObject = creditReport.reportObject.efxReport.usConsumerCreditReports.usConsumerCreditReport[0] as USConsumerCreditReport;

                    // build customized account information
                    rptObject.$bankruptcies = this.buildBankruptcyInformation(rptObject);
                    rptObject.$taxLiens = this.buildTaxLienInformation(rptObject);
                    rptObject.$collections = this.buildCollectionInformation(rptObject);
                    rptObject.$accts = this.buildAccountInformation(rptObject);

                    // set bureau
                    rptObject.bureau = creditReport.bureau;

                    // parse scores
                    if (rptObject.usModelScores && rptObject.usModelScores.usModelScore) {
                        rptObject.$scoreResult = parseInt(rptObject.usModelScores.usModelScore[0].scoreResult, 10);
                    }
                }
                return rptObject;
            });
    }

    public getCreditUtilization(creditLimit: CreditLimit, bal: BalanceAmount, noValIndicator: string): string {
        if (!!creditLimit && !!bal) {
            const limitVal = parseInt(creditLimit.text, 10);
            const balVal = parseInt(bal.text, 10);
            if (limitVal === 0 && balVal === 0) {
                return noValIndicator;
            } else {
                const retVal = (balVal / limitVal) * 100;
                if (isNaN(retVal)) {
                    return noValIndicator;
                }
                else {
                    return ((balVal / limitVal) * 100) + '';
                }
            }
        }
        return noValIndicator;
    }

    public getByIndex(monthArray: CreditReportServiceMonth[], index: number): CreditReportServiceMonth {
        for (const month of monthArray) {
            if (month.index === index) {
                return month;
            }
        }
        return null;
    }

    public monthFound(history: History[], monthNumber: number): boolean {
        for (const h of history) {
            if (h.monthNumber === monthNumber) {
                return true;
            }
        }
        return false;
    }

    public padHistory(historyYears: HistoryYear[]): HistoryYear[] {
        for (const currentHistoryYear of historyYears) {

            if (currentHistoryYear.history.length < 12) {

                const missingMonths: number[] = [];

                for (let j = 1; j <= 12; j++) {
                    if (!this.monthFound(currentHistoryYear.history, j)) {
                        missingMonths.push(j);
                    }
                }

                for (const missingMonth of missingMonths) {
                    currentHistoryYear.history.push({
                        month: this.utilityService.getMonthNameFromNumber(missingMonth, 'M'),
                        monthNumber: missingMonth,
                        value: this.formatPaymentHistory(' '),
                        year: parseInt(currentHistoryYear.year, 10),
                    });
                }

                this.utilityService.sortArrayBy<History>(currentHistoryYear.history, 'monthNumber');
            }
        }

        this.utilityService.sortArrayBy<HistoryYear>(historyYears, 'year');
        return historyYears;
    }

    public padText(text: string): string {
        // pads the paymentHistory string with extra characters
        // so it can be represented in a calendar, ensuring that
        // there is always a 25 character string to loop through
        // to generate a 'last 24 months' time frame from the
        // last payment going backwards.  * is a character representing
        // unknown activity and '/'  separates the two years

        let newText = text;
        const len = text.length;
        const endPosition = 25 - len;

        if (len < 25) {

            if (len < 13) {
                for (let i = 0; i < endPosition; i++) {
                    newText = (i === (12 - len) ? '/' : '*') + newText;

                }
            } else if (len > 12) {
                for (let i = 0; i < endPosition; i++) {
                    newText = `*${newText}`;

                }
            } else if (len === 12) {
                for (let i = 0; i < 12; i++) {
                    newText = `************/${text}`;
                }
            }
        }
        return newText;
    }
}

app.service(Injectables.CreditReportService, CreditReportService);

