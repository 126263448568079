import { Application } from "./application";
import { Bond } from "./bond";
import { Customer } from "./customer";
import { EProducerAccount } from "./eProducerAccount";
import { IBaseEntity } from "./iBaseEntity";
import { SystemAccount } from "./systemAccount";
import { User } from "./user";

export interface Attachment extends IBaseEntity { 
    file: File;
    fileId: number;
    comments: string;
    filename: string;
    fileSize: number;
    applicationId: number;
    application: Application;
    customerId: number;
    customer: Customer;
    bondId: number;
    bond: Bond;
    eProducerAccount: EProducerAccount;
    eProducerAccountId: number;
    visibleToAllCarriers: boolean;
    visibleToAgent: boolean;
    visibleInEProducer: boolean;
    visibleToSystemAccountId: number;
    visibleToSystemAccount: SystemAccount;
    uploadedThroughDocumentState: boolean;
    uploadedByUserId: number;
    uploadedByUser: User;
    id: number;
    createdDateTime: Date;
}