import { IBaseEntity } from "./iBaseEntity";
import { Memo } from "./memo";
import { SystemAccount } from "./systemAccount";

export interface CarrierAppointment extends IBaseEntity { 
    agencySystemAccountId: number;
    carrierSystemAccountId: number;
    agencyCanAddQuote: boolean;
    agencyCanEditQuote: boolean;
    agencyCanSetIrpm: boolean;
    agencyCanSetPoa: boolean;
    agencyCode: string;
    agencySystemAccount: SystemAccount;
    memos: Memo[];
    carrierSystemAccount: SystemAccount;
    defaultCommissionPercent: number;
    isActive: boolean;
    renewalCommissionPercent: number;
    territory: string;
    id: number;
    createdDateTime: Date;
}