import { IJQueryService } from "../../../utilities/jquery/iJQueryService";
import defaultWrapper from "../defaultWrapper";
import fieldGroupWrapper from "../fieldGroupWrapper";

export default (element: JQuery, jQueryProvider: IJQueryService) => {

    var value = element.attr('aa-option-value') ? element.attr('aa-option-value') : 'value';
    var text = element.attr('aa-option-text') ? element.attr('aa-option-text') : 'label';
    var field = element.attr('aa-field');
    var groups = element.attr('groups');

    element.attr('is-select', '');

    var unselectedText = element.attr('aa-unselected-text') ? element.attr('aa-unselected-text') : '';
    if (unselectedText) {
        var required = element.attr('ng-required') || element.attr('required');
        element.append('<option ng-selected="!' + field + ' || ' + field + ' == undefined" value="" ' + (required ? 'disabled' : '') + ' >' + unselectedText + '</option>');
    }

    element.attr('ng-class', "{'unselected' : !" + element.attr('aa-field') + "}");

    
    element.append(
                    '<optgroup ' +
                        'ng-repeat="grp in ' + groups + '" ' +
                        'label="{{grp.label}}" >' +
                            '<option ' + 
                                'ng-selected="' + field + ' == opt.' + value + '" ' +
                                'value="{{opt.' + value + '}}" ' + 
                                'ng-repeat="opt in grp.options" ' +
                                'ng-disabled="opt.isDisabled">' +
                                    '{{opt.' + text + '}}' +
                            '</option> ' +
                    '</optgroup>'
    );

    var aaFgClass = element.attr('aa-fg-class') ? element.attr('aa-fg-class') + ' select' : 'select';
    element.attr('aa-fg-class', aaFgClass);

    fieldGroupWrapper(element, defaultWrapper(element), jQueryProvider);
};