import { ApplicationService } from "../../api/applicationService";
import { PaymentService } from "../../api/paymentService";
import { SystemAccountService } from "../../api/systemAccountService";
import { PaymentMethod } from "../../api/types/model/paymentTransaction";
import { Payment } from "../../api/types/payment";
import { CreditCardFeeRequest } from "../../api/types/payments/creditCardFeeRequest";
import { SelectOption } from "../../api/types/selectOption";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { UtilityService } from "../../utilities/utilityService";
import { PurchaseQuoteOptions } from "./purchaseQuoteModalOptions";
import { PurchaseQuoteResult } from "./PurchaseQuoteResult";
import app from "../../main";
import { IQService, IPromise } from "angular";
import { SystemSettings } from "../../configuration/settings/systemSettings";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";
import { QuoteForPurchaseModal } from "../../api/types/quoteForPurchase";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { QuotePurchaseRequest } from "../../api/types/quotePurchaseRequest";
import { ShippingInfo } from "../../api/types/shippingInfo";

export class PurchaseQuoteModalController {

    public static $inject = [
        Injectables.PaymentService,
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.SystemAccountService,
        Injectables.ApplicationService,
        Injectables.CurrentUserResolver,
        Injectables.$q,
        Injectables.UtilityService,
        Injectables.SystemSettings,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly paymentService: PaymentService,
        private readonly $uibModalInstance: Modal<PurchaseQuoteResult>,
        private readonly options: PurchaseQuoteOptions,
        private readonly systemAccountService: SystemAccountService,
        private readonly applicationService: ApplicationService,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly $q: IQService,
        private readonly utilityService: UtilityService,
        private readonly systemSettings: SystemSettings,
        private readonly toastMessageCreator: ToastMessageCreator
    ){}

    public quote: QuoteForPurchaseModal;
    public originalTotalAmountDue: number;
    public totalPaymentAmount: number;
    public expeditedFee: number;
    public activeTab: number;
    public payment: Payment;
    public busyIndicator: BusyIndicator;
    public processPayment: boolean;
    public errorMessage: string;
    public carrierLogoImg: string;
    public creditCardExpirationMonth: SelectOption<string>[];
    public creditCardExpirationYear: SelectOption<string>[];
    public paymentMethods: SelectOption<PaymentMethod>[];
    public shippingInfo: ShippingInfo;

    public $onInit(): void {
        if (!this.options.quoteId) {
            throw new Error('options.quoteId is not valid');
        }

        this.busyIndicator = {
            message: 'Loading...'
        };

        this.activeTab = 0;
        this.processPayment = true;
        this.expeditedFee = 0;

        this.creditCardExpirationMonth = this.utilityService.creditCardMonthOptions;
        this.creditCardExpirationYear = this.utilityService.creditCardYearOptions;

        this.busyIndicator.promise = this.$q.all([
                    this.getPaymentMethods(),
                    this.getExpeditedProcessingFee(),
                    this.getQuote(this.options.quoteId)
                ])
                .then((results) => {
                    this.paymentMethods = results[0];

                    this.payment = {
                        paymentMethod: this.paymentMethods.length > 0 ? this.paymentMethods[0].value : null,
                        processingFee: null,
                        systemAccountId: this.currentUserResolver.getCurrentUser().user.systemAccountId,
                        amount: this.quote.totalPremium
                    } as Payment;

                    if (this.systemSettings.environment === 'Dev') {
                        this.payment.paymentMethod =  PaymentMethod.CreditCard,
                        this.payment.cardNumber = '4111111111111111',
                        this.payment.expirationMonth = '12',
                        this.payment.expirationYear = '2031',
                        this.payment.securityCode = '123'
                    }

                    this.expeditedFee = results[1];

                    this.shippingInfo = {} as ShippingInfo;
                });
    }

    public getPaymentMethods(): IPromise<SelectOption< PaymentMethod>[]> {
        return this.systemAccountService
            .getPaymentMethods(this.currentUserResolver.getCurrentUser().user.systemAccountId);
    }

    public getExpeditedProcessingFee(): IPromise<number>  {
        return this.systemAccountService
            .getExpeditedProcessingFee(this.currentUserResolver.getCurrentUser().user.systemAccountId);
    }

    public getQuote(quoteId: number): IPromise<void> {
        return this.applicationService
            .getQuoteForPurchase(quoteId)
            .then((quote) => {

                this.quote = quote;
                this.originalTotalAmountDue = this.quote.totalAmountDue;
                
                return this.systemAccountService
                    .getSmallLogo(quote.carrierSystemAccountId)
                    .then((img) => {
                        this.carrierLogoImg = img;
                    });
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to get information about this quote');
            });
    }

    public expeditedChanged(): void {
        if (this.shippingInfo.expedited) {
            this.quote.totalAmountDue = this.originalTotalAmountDue + this.expeditedFee;
        } else {
            this.quote.totalAmountDue = this.originalTotalAmountDue;
        }
    }

    public next(): void {
        if (this.activeTab < 2) {
            this.activeTab++;

            if (this.activeTab === 2) {
                this.payment.amount = this.quote.totalAmountDue;
                this.calculateTotal();
                this.checkForPaymentTransactionFees();
            }

            return;
        }

        this.purchase();
    }

    private purchase(): void {
        
        const quotePurchaseRequest = {
            quoteId: this.quote.id,
            shippingInfo: this.shippingInfo,
            quoteType: this.quote.quoteType,
            payment: this.processPayment ? this.payment : null
        } as QuotePurchaseRequest;

        this.busyIndicator.message = 'Processing Purchase...';
        this.busyIndicator.promise = this.applicationService
            .purchaseQuote(quotePurchaseRequest)
                .then((response) => {
                    this.toastMessageCreator.createSuccessMessage('Bond was processed successfully');
                    this.$uibModalInstance.close(new PurchaseQuoteResult(response.bondId));
                })
                .catch((response) => {
                    if (!response.message) {
                        this.toastMessageCreator.createErrorMessage('An error occurred trying to purchase this quote');
                    } else {
                        this.errorMessage = response.message;
                    }
                });
    }

    public previous(): void {
        if (this.activeTab === 0) {
            return;
        }

        this.activeTab--;
    }

    private calculateTotal(): void {
        this.totalPaymentAmount = this.payment.amount + this.payment.processingFee;
    }

    public checkForPaymentTransactionFees(): void {
        if (!this.payment || !this.payment.amount || !this.processPayment) {
            return;
        }

        const request: CreditCardFeeRequest = {
            payment: this.payment
        };

        this.paymentService.getPaymentTransactionFees(request)
            .then((response) => {
                if (response.success) {
                    this.payment.processingFee = response.fee;
                    this.calculateTotal();
                } else {
                    this.errorMessage = response.message;
                }
            });
    }

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }
}

app.controller('PurchaseQuoteModalController', PurchaseQuoteModalController);
