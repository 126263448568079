import { ObligeeService } from "../../../api/obligeeService";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../configuration/injectables";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { State } from "../../state";
import { ObligeeDetail } from "./obligeeDetails";
import { ObligeeDetailStateParams } from "./obligeeDetailStateParams";

export class ObligeeDetailController {
    public static $inject = [
        Injectables.$stateParams,
        Injectables.$state,
        Injectables.ObligeeService,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly $stateParams: ObligeeDetailStateParams,
        private readonly $state: State,
        private readonly obligeeService: ObligeeService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) { }

    public obligee: ObligeeDetail;
    public busyIndicator: BusyIndicator;

    public $onInit(): void {
        this.busyIndicator = {};
        
        if (this.$stateParams.id) {
            this.loadObligee(this.$stateParams.id);
            } else {
            this.createObligee();
        }
    }

    private loadObligee(obligeeId: number) 
    {
        this.busyIndicator.message = 'Loading...' ;
        this.busyIndicator.promise = this.obligeeService.getObligeeDetail(obligeeId)
            .then((obligee) => {
                this.obligee = obligee;
            }) ;
    }

    private createObligee(): void {
        this.obligee = { isActive: true } as ObligeeDetail;
    }

    public save(): void {
        this.busyIndicator.message = 'Saving...' ;
        this.busyIndicator.promise = this.obligeeService.saveObligee(this.obligee)
            .then((response) => {
                this.obligee.id = response;
                this.$state.go('main.obligeesGrid');
                this.toastMessageCreator.createSuccessMessage('Obligee saved successfully');
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to save the obligee');
            });
    }

    public cancel(): void {
        this.$state.go('main.obligeesGrid');
    }
}