import { Injectables } from "../../../configuration/injectables";
import { IDebounceDelayer } from "../../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../../utilities/tables/table";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { ApplicationService } from "../../../api/applicationService";
import { ApplicationReportItem } from "./applicationReportItem";
import { ApplicationReportDropdownOptions } from "./applicationReportFilterDropdown/applicationReportFilterDropdownOptions";
import ApplicationOpenClosedStatus from "../../../api/types/applicationOpenClosedStatus";

export class ApplicationReportController {

    public static $inject = [
        Injectables.ApplicationService,
        Injectables.IDebouceDelayer
    ];

    constructor(
        private readonly applicationService: ApplicationService,
        debouceDelayer: IDebounceDelayer
    ) {
        this.table = new Table(debouceDelayer);
        this.table.onChange = this.loadData;
        this.table.queryOptions.openClosedStatus = ApplicationOpenClosedStatus.Open;
    }

    public readonly table: Table<ApplicationReportItem, ApplicationReportDropdownOptions>;
    public busyIndicator: BusyIndicator;

    public exportApplicationReport = () =>{
        this.busyIndicator = {
            message: "Loading...",
            promise: this.applicationService.getApplicationExcelReport(this.table.queryOptions)
        };
    }

    public loadData = () => {
        this.busyIndicator = {
            message: "Loading Applications...",
            promise: this.applicationService
                .getApplicationReportData(this.table.queryOptions)
                .then((response) => {
                    this.table.setData(response.items, response.totalRecordCount);
                })
        }; 
    };

    public $onInit(): void {
        this.loadData();
    }
}