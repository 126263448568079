import { BrowserNotificationService } from "../../api/browserNotificationService";
import { BrowserNotification } from "../../api/types/browserNotification";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";
import { BusyIndicator } from "../busyIndicator/busyIndicator";

export class BrowserNotificationDropdownController {
    
    public static $inject = [
        Injectables.BrowserNotificationService,
        Injectables.CurrentUserResolver
    ];

    constructor(
        private readonly browserNotificationService: BrowserNotificationService,
        private readonly currentUserResolver: CurrentUserResolver
    ) {}

    public notifications: BrowserNotification[];
    public notificationRecordsPerPage: number;
    public moreNotificationPages: boolean;
    public currentNotificationPage: number;
    public newNotificationCount: number;
    public totalNotificationCount: number;
    public notificationDropdownOpen: boolean;
    public busyIndicator: BusyIndicator;
    
    public onNotificationScroll(scrollTop: number, scrollHeight: number) {
        if (this.moreNotificationPages && scrollTop > scrollHeight - 10) {
            this.loadNotifications();
        }                
    }

    public adjustNotificationCount(notification: BrowserNotification) {
        if (notification.read) {
            this.newNotificationCount--;
        } else {
            this.newNotificationCount++;
        }
    }

    public handleNotificationClick(notification: BrowserNotification) {
        if (!notification.read) {
            notification.read = true;
            this.browserNotificationService.setReadStatus(notification);
            this.adjustNotificationCount(notification);
        }

        this.notificationDropdownOpen = false;
        this.browserNotificationService.navigateTo(notification);
    }

    public addNotification(notification: BrowserNotification) {
        this.notifications.unshift(notification);
        this.adjustNotificationCount(notification);
    }

    public getUnreadNotificationCount() {
        this.browserNotificationService.getUserUnreadBrowserNotificationCount()
            .then((unreadNotificationCount) => {
                this.newNotificationCount = unreadNotificationCount;
            });
    }

    public dropdownToggled(open: boolean) {
        if (open && !this.notifications.length) {
            this.loadNotifications();
        }
    }

    public loadNotifications() {
        // load a max of 200 notifications
        if (this.notifications.length >= 200) {
            return;
        }

        this.busyIndicator.promise = this.browserNotificationService.getBrowserNotificationsByUserId(this.currentNotificationPage, this.notificationRecordsPerPage)
            .then((notificationResponse) => {

                // if any of the notifications that we receive from server are already in array then don't add them
                for (var i = 0; i < notificationResponse.notifications.length; i++) {
                    if (!this.notifications.some((notification) => notification.id === notificationResponse.notifications[i].id))
                        this.notifications.push(notificationResponse.notifications[i]);
                }

                this.currentNotificationPage++;
                this.totalNotificationCount = notificationResponse.totalNotificationCount;
                this.moreNotificationPages = (this.notifications.length < this.totalNotificationCount);
                this.newNotificationCount = notificationResponse.unreadNotificationCount;
            });
    }

    public markAllNotificationsAsRead() {
        this.browserNotificationService.markAllAsRead(this.currentUserResolver.getCurrentUser().user.userId)
                .then(() => {});
        
            for (var i = 0; i < this.notifications.length; i++) {
            this.notifications[i].read = true;
        }

        this.newNotificationCount = 0;

    } 
    
    public toggleReadStatus(notification) {
        notification.read = !notification.read;

        this.adjustNotificationCount(notification);
        this.browserNotificationService.setReadStatus(notification);
    }

    public $onInit = () => {
        
        this.busyIndicator = {
            message: 'Loading messages...',
            wrapperClass: 'notification-loading-mask',
            backdrop: false
        }

        this.currentNotificationPage = 1;
        this.notificationRecordsPerPage = 10;
        this.notifications = [];
        this.newNotificationCount = 0;
        this.browserNotificationService.addNotificationListener(this.addNotification);
        this.browserNotificationService.addNotificationClickListener(this.adjustNotificationCount);
        
        this.getUnreadNotificationCount();
    }
}

const browserNotificationDropdownComponent = {
    bindings: {},
    templateUrl: 'app/components/browserNotificationDropdown/browserNotificationDropdown.html',
    controller: BrowserNotificationDropdownController,
    controllerAs: 'vm'
};

app.component('browserNotificationDropdown', browserNotificationDropdownComponent);
