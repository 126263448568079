import { AuthService } from "../api/authService";
import { A3RootScope } from "../api/types/a3RootScope";
import { State } from "../states/state";
import { Injectables } from "./injectables";
import app from "../main";
import { StateChangeListener } from "../states/stateChangeListener";
import { CurrentUserResolver } from "../utilities/currentUserResolver/currentUserResolver";
import { ToastMessageCreator } from "../utilities/toastMessages/toastMessageCreator";

const routingRunner = (
    stateChangeListener: StateChangeListener,
    $state: State,
    authService: AuthService,
    currentUserResolver: CurrentUserResolver,
    toastMessageCreator: ToastMessageCreator
) => {

    stateChangeListener.onStateChanging((event, toState, params) => {
        authService.fillAuthData();

        if (toState.requiredPermissions) {
            const currentUser = currentUserResolver.getCurrentUser();

            if (currentUser.permissions[toState.requiredPermissions] !== true) {
                event.preventDefault();
                toastMessageCreator.createErrorMessage("Your account does not have the permissions required to access this page");
                return;
            }
        }

        // add route config for changePassword and forgotPassword
        if (toState.name == 'changePassword' || toState.name == 'forgotPassword' || toState.name == 'userSelection') { // If going to changePassword and canChangePassword (has been verified on Server)
        } else {
            var isLoggedIn = currentUserResolver.getCurrentUser()?.isLoggedIn();

            if (toState.name != 'login' && !isLoggedIn) { // If not going to login and not logged in 
                event.preventDefault();
                authService.setRedirectUrl(toState.name, params);
                $state.go('login');
            } else if (toState.name == 'login' && isLoggedIn) { 
                $state.go('main.dashboard');
            }
        }
    });

    stateChangeListener.onStateChanged((toState, toParams, fromState, previousStateParams) => {
        if ((fromState.name === 'login' && toState.name === 'main.bondDetail') ||
            (fromState.name === 'login' && toState.name === 'main.bondAccountDetail') ||
            (fromState.name === 'login' && toState.name === 'main.activityDetail')) {

            toParams.fromLogin = true;
        }
    });
}

routingRunner.$inject = [
    Injectables.StateChangeListener,
    Injectables.$state,
    Injectables.AuthService,
    Injectables.CurrentUserResolver,
    Injectables.ToastMessageCreator
];

app.run(routingRunner);
