import * as angular from "angular";
import { IScope, IAugmentedJQuery, IAttributes, IController, IDirectiveFactory, INgModelController } from "angular";
import app from "../../main";

const isZipCodeDirective = () => {

    const link = (
        scope: IScope, 
        elm:IAugmentedJQuery, 
        attrs: IAttributes, 
        ctrl: INgModelController
    ) => {
        
        // $parsers call when input value changes
        ctrl.$parsers.unshift((inputValue: string) => {
            if (!inputValue) {
                return inputValue;
            }

            inputValue = inputValue.replace(/_/g, '');

            const zerosToAdd = 5 - inputValue.length;

            for(let i = 0; i < zerosToAdd; i++)
            {
                inputValue = '0' + inputValue;
            }

            return inputValue;
        });

        // $formatters call when model is changed
        ctrl.$formatters.unshift((modelValue: string) => {
            if (!modelValue) {
                return modelValue;
            }

            modelValue = modelValue.replace(/_/g, '');

            const zerosToAdd = 5 - modelValue.length;

            for(let i = 0; i < zerosToAdd; i++)
            {
                modelValue = '0' + modelValue;
            }

            return modelValue;
        });
    }

    return {
        require: 'ngModel',
        link: link
    };
}

app.directive('isZipCode', isZipCodeDirective);