import { BondChangeType } from "./model/bondChange";
import { SelectOption } from "./selectOption";

export interface BondChangeSelectOption extends SelectOption<string> {
    enumValue: BondChangeType;
}

export const BondChangeSelectOptions: BondChangeSelectOption[] = [
    {
        label: 'Name on bond (principal)',
        value: 'nameOnBond',
        enumValue: BondChangeType.NameOnBond
    },
    {
        label: 'Bond Amount',
        value: 'bondAmount',
        enumValue: BondChangeType.BondAmount
    },
    {
        label: 'Bond Number',
        value: 'bondNumber',
        enumValue: BondChangeType.BondNumber
    },
    {
        label: 'Effective Date',
        value: 'effectiveDate',
        enumValue: BondChangeType.EffectiveDate
    },
    {
        label: 'Expiration Date',
        value: 'expirationDate',
        enumValue: BondChangeType.ExpirationDate
    },
    {
        label: 'Bond Type',
        value: 'bondType',
        enumValue: BondChangeType.BondTypeId
    },
    {
        label: 'Bond Form Information',
        value: 'bondFormInformation',
        enumValue: BondChangeType.BondFormInformation
    },
    {
        label: 'Company Name',
        value: 'companyName',
        enumValue: BondChangeType.CompanyName
    },
    {
        label: 'Company DBA',
        value: 'companyDba',
        enumValue: BondChangeType.CompanyDba
    },
    {
        label: 'Company Type',
        value: 'companyType',
        enumValue: BondChangeType.CompanyType
    },
    {
        label: 'Company Phone',
        value: 'companyPhone',
        enumValue: BondChangeType.CompanyPhone
    },
    {
        label: 'Company Fax',
        value: 'companyFax',
        enumValue: BondChangeType.CompanyFax
    },
    {
        label: 'Company Email',
        value: 'companyEmail',
        enumValue: BondChangeType.CompanyEmail
    },
    {
        label: 'Company Physical Address',
        value: 'companyPhysicalAddress',
        enumValue: BondChangeType.CompanyPhysicalAddress
    },
    {
        label: 'Company Mailing Address',
        value: 'companyMailingAddress',
        enumValue: BondChangeType.CompanyMailingAddress
    },
    {
        label: 'Company Date Formed',
        value: 'companyDateFormed',
        enumValue: BondChangeType.CompanyDateFormed
    },
    {
        label: 'Company Number of Owners',
        value: 'companyNumberOfOwners',
        enumValue: BondChangeType.CompanyNumberOfOwners
    },
    {
        label: 'Company State of Incorporation',
        value: 'companyStateOfIncorporation',
        enumValue: BondChangeType.CompanyStateOfIncorporation
    },
    {
        label: 'Company FEIN',
        value: 'companyFein',
        enumValue: BondChangeType.CompanyFein
    },
    {
        label: 'Company Number Of Employees',
        value: 'companyNumberOfEmployees',
        enumValue: BondChangeType.CompanyNumberOfEmployees
    },
    {
        label: 'Individuals Name',
        value: 'individualName',
        enumValue: BondChangeType.IndividualName
    },
    {
        label: 'Individuals SSN',
        value: 'individualSsn',
        enumValue: BondChangeType.IndividualSsn
    },
    {
        label: 'Individuals DOB',
        value: 'individualDateOfBirth',
        enumValue: BondChangeType.IndividualDateOfBirth
    },
    {
        label: 'Individuals Drivers License Number',
        value: 'individualDriversLicenseNumber',
        enumValue: BondChangeType.IndividualDriversLicenseNumber
    },
    {
        label: 'Individuals Drivers License State',
        value: 'individualDriversLicenseState',
        enumValue: BondChangeType.IndividualDriversLicenseState
    },
    {
        label: 'Individuals Home Phone',
        value: 'individualHomePhone',
        enumValue: BondChangeType.IndividualHomePhone
    },
    {
        label: 'Individuals Cell Phone',
        value: 'individualCellPhone',
        enumValue: BondChangeType.IndividualCellPhone
    },
    {
        label: 'Individuals Fax Number',
        value: 'individualFax',
        enumValue: BondChangeType.IndividualFax
    },
    {
        label: 'Individuals Email',
        value: 'individualEmail',
        enumValue: BondChangeType.IndividualEmail
    },
    {
        label: 'Individuals Physical Address',
        value: 'individualPhysicalAddress',
        enumValue: BondChangeType.IndividualPhysicalAddress
    },
    {
        label: 'Individuals Mailing Address',
        value: 'individualMailingAddress',
        enumValue: BondChangeType.IndividualMailingAddress
    },
    {
        label: 'Individuals Job Title',
        value: 'individualJobTitle',
        enumValue: BondChangeType.IndividualJobTitle
    },
    {
        label: 'Individual Years Experience',
        value: 'individualYearsExperience',
        enumValue: BondChangeType.IndividualYearsExperience
    },
    {
        label: 'Individuals Gender',
        value: 'individualGender',
        enumValue: BondChangeType.IndividualGender
    },
    {
        label: 'Individuals Residence Type',
        value: 'individualResidenceType',
        enumValue: BondChangeType.IndividualResidenceType
    },
    {
        label: 'Individuals Last Move Date',
        value: 'individualDateMovedToResidence',
        enumValue: BondChangeType.IndividualDateMovedToResidence
    },
    {
        label: 'Individuals Marital Status',
        value: 'individualMaritalStatus',
        enumValue: BondChangeType.IndividualMaritalStatus
    },
    {
        label: 'Individuals Spouse Name',
        value: 'individualSpouseName',
        enumValue: BondChangeType.IndividualSpouseName
    },
    {
        label: 'Individuals Spouse SSN',
        value: 'individualSpouseSsn',
        enumValue: BondChangeType.IndividualSpouseSsn
    },
    {
        label: 'Individuals Spouse Email',
        value: 'individualSpouseEmail',
        enumValue: BondChangeType.IndividualSpouseEmail
    },
    {
        label: 'Individuals Spouse Phone',
        value: 'individualSpouseCellPhone',
        enumValue: BondChangeType.IndividualSpouseCellPhone
    },
    {
        label: 'Individuals Spouse DOB',
        value: 'individualSpouseDateOfBirth',
        enumValue: BondChangeType.IndividualSpouseDateOfBirth
    },
    {
        label: 'Individuals Spouse Gender',
        value: 'individualSpouseGender',
        enumValue: BondChangeType.IndividualSpouseGender
    },
    {
        label: 'Individuals Spouse Gender',
        value: 'individualSpouseGender',
        enumValue: BondChangeType.IndividualSpouseGender
    },
    {
        label: 'Individuals Employer Name',
        value: 'individualEmployerName',
        enumValue: BondChangeType.IndividualEmployerName
    },
    {
        label: 'Individuals Employer Address',
        value: 'individualEmployerAddress',
        enumValue: BondChangeType.IndividualEmployerAddress
    },
    {
        label: 'Obligee Contact 1',
        value: 'obligeeContact1',
        enumValue: BondChangeType.ObligeeContact1
    },
    {
        label: 'Obligee Contact 2',
        value: 'obligeeContact2',
        enumValue: BondChangeType.ObligeeContact2
    },
    {
        label: 'Obligee Contact 3',
        value: 'obligeeContact3',
        enumValue: BondChangeType.ObligeeContact3
    },
    {
        label: 'Obligee E-mail',
        value: 'obligeeEmail',
        enumValue: BondChangeType.ObligeeEmail
    },
    {
        label: 'Obligee Fax',
        value: 'obligeeFax',
        enumValue: BondChangeType.ObligeeFax
    },
    {
        label: 'Obligee Mailing Address',
        value: 'obligeeMailAddress',
        enumValue: BondChangeType.ObligeeMailAddress
    },
    {
        label: 'Obligee Name',
        value: 'obligeeName',
        enumValue: BondChangeType.ObligeeName
    },
    {
        label: 'Obligee Phone',
        value: 'obligeePhone',
        enumValue: BondChangeType.ObligeePhone
    },
    {
        label: 'Obligee Physical Address',
        value: 'obligeePhysicalAddress',
        enumValue: BondChangeType.ObligeePhysicalAddress
    },
    {
        label: 'Obligee Website URL',
        value: 'obligeeWebsiteURL',
        enumValue: BondChangeType.ObligeeWebsiteURL
    },
    {
        label: 'Writing Company',
        value: 'writingCompanyId',
        enumValue: BondChangeType.WritingCompanyId
    }
];