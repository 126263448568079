import { LeadService } from "../../api/leadService";
import { Lead } from "../../api/types/model/lead";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { LeadModalOptions } from "./leadModalOptions";

export class LeadModalController {    

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.LeadService 
    ];

    constructor(
        private readonly $uibModalInstance: Modal<void>,
        private readonly options: LeadModalOptions,
        private readonly leadService: LeadService ) {
    }
    
    public busyIndicator: BusyIndicator;
    public dynamicTabs: any
    public lead: Lead;
    public leadId: number;

    public cancel = () => {
        this.$uibModalInstance.dismiss('cancel');
    }

    public buildDynamicTabs = (lead: Lead)  => {
        this.dynamicTabs = [];

        for (let i = 0; i < lead.companies.length; i++) {
            this.dynamicTabs.push({ 
                heading: lead.companies[i].name, 
                active: this.dynamicTabs.length === 0, 
                type: 'Company', index: i 
            });
        }

        for (let i = 0; i < lead.people.length; i++) {
            this.dynamicTabs.push({ 
                heading: lead.people[i].fullName, 
                active: this.dynamicTabs.length === 0, 
                type: 'Person', 
                index: i 
            });
        }

        this.dynamicTabs.push({ 
            heading: 'Application Questions', 
            type: 'AdditionalQuestions' 
        });
    }

    public loadLead = (leadId: number) => {
        return this.leadService.getLeadDetails(leadId)
            .then((lead: Lead) => {
                this.lead = lead;
                this.buildDynamicTabs(lead);
            });
    }

    public $onInit = () =>  {
        this.leadId = this.options.leadId;

        this.busyIndicator = {
            message: 'Loading...',
            promise: this.loadLead(this.options.leadId)
        };
    }
}

app.controller('LeadModalController', LeadModalController);