import { ITimeoutService, IScope, IDirectiveLinkFn, IAttributes, IDirectiveFactory } from "angular";
import { SfaaCodeService } from "../../api/sfaaCodeService";
import { SfaaCode } from "../../api/types/model/sfaaCode";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { BusyIndicator } from "../busyIndicator/busyIndicator";

class SfaaCodeSelectionController {

    public static $inject = [
        Injectables.SfaaCodeService,
        Injectables.$timeout,
        Injectables.$scope
    ];
    
    constructor( 
        private readonly sfaaCodeService: SfaaCodeService,
        private readonly $timeout: ITimeoutService,
        private readonly $scope: IScope
    ) {}

    public sfaaCodeSearch: any;
    public sfaaCode: SfaaCode;
    public sfaaCodeId: number;
    public sfaaCodeCode: string;
    public showLabel: boolean;
    public label: string;
    public busyIndicator: BusyIndicator;
    public change: () => void

    public selectSfaaCode = (sfaaCode: SfaaCode) => {     
        this.sfaaCode = sfaaCode;
        this.sfaaCodeId = sfaaCode.id;
        this.sfaaCodeCode = sfaaCode.code;

        if (this.change instanceof Function) {
            this.$timeout(() => {
                this.change();
            });
        }
    }

    public controlInit = ($select) => {
        if (this.showLabel) {
            $select.label = this.label;
        }
    }

    public searchSfaaCodes = (search?: string) => {
        return this.sfaaCodeService.searchSfaaCodes(search)
            .then((codes) => {
                this.sfaaCodeSearch = codes;
            });
    }

    public $onInit = () => {
        this.busyIndicator = {
            message: 'Loading...',
            promise: this.searchSfaaCodes()
                .then(() => {
                    this.$timeout(() => {
                        if (this.sfaaCode) {
                            this.sfaaCodeSearch.selected = this.sfaaCode;
                        } else if (this.sfaaCodeId) {
                            this.sfaaCodeSearch.selected.id = this.sfaaCodeId;
                        } else if (this.sfaaCodeCode) {
                            this.sfaaCodeSearch.selected.code = this.sfaaCodeCode;
                        }
                    });
                })
        }
    
        this.$scope.$watch('vm.sfaaCode', (newCode) => {
            if(this.sfaaCodeSearch && newCode) {
                this.sfaaCodeSearch.selected = newCode;
            }
        });

        this.$scope.$watch('vm.sfaaCodeId', (id) => {
            if (this.sfaaCodeSearch && !id)
                this.sfaaCodeSearch.selected = null;
        });
    }
}

const sfaaCodeSelectionDirective = () => {

    const link: IDirectiveLinkFn = (
        $scope: IScope, 
        element: JQuery, 
        attrs: IAttributes, 
        controller:  any) => {
            controller.label = attrs.label || "Search SFAA Codes";
        }

        return {
            restrict: 'E',
            templateUrl: 'app/components/sfaaCodeSelector/sfaaCodeSelection.html',
            replace: true,
            scope: {},
            bindToController: {
                sfaaCode: '=?',
                sfaaCodeId: '=?',
                sfaaCodeCode: '=?',
                change: '&?',
                showLabel: '=?'
            },
            controllerAs: 'vm',
            controller: SfaaCodeSelectionController,
            link: link 
        }
}

app.directive('sfaaCodeSelection', sfaaCodeSelectionDirective as IDirectiveFactory);
