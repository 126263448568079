import { BondChange } from "../model/bondChange";

export type RiderRequestForCreating = {
    bondId: number
    effectiveDate: Date;
    carrierComments: string;
    attorneyInFactUserId: number;
    changes: BondChange[];
    premiumChange: number;
    commissionChange: number;
    reasonId: number;
}