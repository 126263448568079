import { IJQueryService } from "../../../utilities/jquery/iJQueryService";
import defaultWrapper from "../defaultWrapper";
import fieldGroupWrapper from "../fieldGroupWrapper";

export default (element: JQuery, jQueryProvider: IJQueryService) => {
    var stateOptions = '<option value="BillClient">Bill Client</option>' +
        '<option value="BillBrokerNoCommission">Bill Broker (No Commission)</option>' +
        '<option value="BillBrokerNet">Bill Broker (Net)</option>' +
        '<option value="BillBrokerGross">Bill Broker (Gross)</option>';

    element.append(stateOptions);

    var aaFgClass = element.attr('aa-fg-class') ? element.attr('aa-fg-class') + ' select' : 'select';
    element.attr('aa-fg-class', aaFgClass);

    fieldGroupWrapper(element, defaultWrapper(element), jQueryProvider);
};