import { City } from "./city";
import { Telephone } from "./telephone";

export interface USConsumerReferral { 
    bureauId: string;
    name: string;
    addressLine1: string;
    addressLine2: string;
    city: City;
    state: string;
    postalCode: string;
    telephone: Telephone;
}