import BillingEntryType from "../billingEntries/billingEntryType";

export interface UnbilledBondTransactionBillingEntry {
    billingEntryId: number;
    feeId?: number;
    bondTransactionId?: number;
    description: string;
    amountDue: number;
    billingEntryType: BillingEntryType;
}
