import { Injectable } from "angular";

export type StateDefinition = {
    name?: string;
    title?: string;
    embeddedTitleBar?: boolean;
    requiredPermissions?: string;
    abstract?: boolean;
    url?: string;
    templateUrl?: string;
    controller?: string |Injectable<ng.IControllerConstructor>;
    controllerAs?: string;
    template?: string;
    params?: any;
    views?: {
        [name: string]: StateDefinition
    };
    onEnter?: any,
    onExit?: any,
    reloadOnSearch?: any,
}