import { Table } from "../../utilities/tables/table";
import { ActivityListItem } from "./activityListItem";
import { ActivityTableQueryOptions } from "./activityTableQueryOptions";
import app from "../../main";
import { PriorityLevelOptions } from "../../api/types/activities/activityPriorityLevelOptions";
import { SelectOption } from "../../api/types/selectOption";

class ActivityListController {

    public static $inject = [];

    public table: Table<ActivityListItem, ActivityTableQueryOptions>;
    public customerId: number;
    public bondId: number;
    public bondAccountId: number;
    public eproducerAccountId: number;
    public applicationId: number;
    public applicationNumber: number;
    public onActivitiesModified: () => void;
    public priorityLevelOptions: SelectOption<number>[];
            
    constructor() {}

    public getPriorityLevelString(activity: ActivityListItem): string
    {
        if ( activity.priorityLevel){
            return this.priorityLevelOptions[activity.priorityLevel - 1].label;
        }

        return null;
    }
    
    public $onInit() {
        this.priorityLevelOptions = PriorityLevelOptions;
    }
}

const activityListComponent = {
    bindings: {
        table: '<',
        customerId: '<?',
        bondId: '<?',
        bondAccountId: '<?',
        eproducerAccountId: '<?',
        applicationId: '<?',
        onActivitiesModified: '&',
    },
    controller: ActivityListController,
    controllerAs: 'vm',
    templateUrl: 'app/components/activityList/activityList.html',
};

app.component('activityList', activityListComponent);