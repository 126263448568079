import { IScope, IAttributes, IController } from "angular";
import { ApplicationFollowUp } from "../../api/types/model/applicationFollowUp";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import isDefined from "../../utilities/angularUtilities/isDefined";
import { UtilityService } from "../../utilities/utilityService";

class FollowUpController {

    public static $inject = [
        Injectables.UtilityService
    ];

    constructor(
        private utilityService: UtilityService
    ) { }

    public followUp: ApplicationFollowUp;
    public isDisabled: boolean;
    public timeOfDay: string;

    public $onInit(): void {
        if (this.followUp.sent) {
            this.isDisabled = true;
        }

        this.setTimeOfDay();
    }

    public setTimeOfDay(): void {
        this.timeOfDay = this.utilityService.getDateTimeOfDayHours(this.followUp.sendDateTime);
    }

    public updateTimeOfDay(): void {
        this.followUp.sendDateTime = this.utilityService.setDateTimeOfDay(this.followUp.sendDateTime, this.timeOfDay);
    }
}

const link = (
    scope: IScope, 
    el: JQuery, 
    attrs: IAttributes, 
    ctrl:IController) => {

    if (isDefined(attrs.disabled)) {
        ctrl['isDisabled'] = true;
    }

    if (attrs.ngDisabled) {
        ctrl['isDisabled'] = scope.$parent.$eval(attrs.ngDisabled);
    }
};

const followUpDirective = () => ({
    restrict: 'E',
    templateUrl: 'app/components/followUp/followUp.html',
    replace: true,
    scope: {},
    bindToController: {
        followUp: '=',
    },
    controllerAs: 'vm',
    controller: FollowUpController,
    link: link
});

app.directive('followUp', followUpDirective);
