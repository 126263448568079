import { ApplicationQuestionResponse } from "./applicationQuestionResponse";
import { BondTypeMasterApplicationQuestion } from "./bondTypeMasterApplicationQuestions";
import { IBaseEntity } from "./iBaseEntity";
import { MasterApplicationQuestionChoice } from "./masterApplicationQuestionChoice";
import { AdditionalQuestionResponseType } from "./questionResponse";
import { UnderwritingQuestion } from "./underwritingQuestion";

export interface MasterApplicationQuestion extends IBaseEntity { 
    type: QuestionType;
    question: string;
    isActive: boolean;
    requiredText: string;
    parentQuestionId: number;
    showWhenParentValueEquals: string;
    isAccountApplicationQuestion: boolean;
    sampleResponse: string;
    required: boolean;
    isSubQuestion: boolean;
    name: string;
    parentQuestion: MasterApplicationQuestion;
    subQuestions: MasterApplicationQuestion[]; 
    applicationQuestionResponses: ApplicationQuestionResponse[];
    masterApplicationQuestionChoices: MasterApplicationQuestionChoice[];
    bondTypeMasterApplicationQuestions: BondTypeMasterApplicationQuestion[];
    underwritingQuestions: UnderwritingQuestion[];
    originType: AdditionalQuestionResponseType;
    id: number;
    createdDateTime: Date;
}

export enum QuestionType {	
    TextBox = "TextBox",
    TextArea = "TextArea",
    DropDown = "DropDown",
    Radio = "Radio"
}