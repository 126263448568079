import { IHttpService, IPromise } from "angular";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/settings/systemSettings";
import app from "../main";
import { UserGroupOption } from "../states/common/userDetail/userGroupOption";
import { UserGroupDetail } from "../states/common/userGroupDetail/userGroupDetail";
import { UserGroupTableItem } from "../states/common/userGroups/userGroupTableItem";
import { UserGroupTableQueryOptions } from "../states/common/userGroups/userGroupTableQuery";
import A3ApiResponse from "./types/a3ApiResponse";
import { PageResponse } from "./types/pageResponse";
import { SelectOption } from "./types/selectOption";
import { CacheStore } from "./cacheStore";

export class UserGroupService {

    public static $inject = [
        Injectables.$http,
        Injectables.SystemSettings,
        Injectables.CacheStore
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly systemSettings: SystemSettings,
        private readonly cacheStore: CacheStore
    ) {
    }

    public getUserGroupOptions(): IPromise<UserGroupOption[]> {
        const url = `${this.systemSettings.apiBaseUrl}UserGroupActions/GetUserGroupOptions`;

        return this.cacheStore.getData(url, () => {
            return this.$http.get<A3ApiResponse<UserGroupOption[]>>(url)
                .then((response) => response.data.value);
        });
    }
    
    public getUserGroups(tableQueryOptions: UserGroupTableQueryOptions): IPromise<PageResponse<UserGroupTableItem>> {
        if (!tableQueryOptions) {
            tableQueryOptions = {
                pageNumber: 1,
                recordsPerPage: 10,
                orderBy: '',
                searchPhrase: ''
            };
        }

        if (!tableQueryOptions.searchPhrase) {
            tableQueryOptions.searchPhrase = '';
        }

        if (!tableQueryOptions.orderBy) {
            tableQueryOptions.orderBy = 'UserGroups.CreatedDateTime';
        }

        let queryString = `?`;

        queryString += `&pageNumber=${tableQueryOptions.pageNumber}`;
        queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
        queryString += `&orderBy=${tableQueryOptions.orderBy}`;
        queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;

        return this.$http
            .get<A3ApiResponse<PageResponse<UserGroupTableItem>>>(this.systemSettings.apiBaseUrl + 'UserGroupActions/GetUserGroups' + queryString)
            .then(response => response.data.value);
    }

    public getUserGroupDetail(id: number): IPromise<UserGroupDetail> {
        return this.$http.get<A3ApiResponse<UserGroupDetail>>(this.systemSettings.apiBaseUrl + 'UserGroupActions/GetUserGroupDetail?userGroupId=' + id)
            .then((response) => response.data.value);
    }

    public saveUserGroup(userGroup: UserGroupDetail): IPromise<void> {
        return this.$http.post(this.systemSettings.apiBaseUrl + 'UserGroupActions/SaveUserGroup', userGroup)
            .then(() => {});
    }

    public getUserGroupSelectOptions(): IPromise<SelectOption[]> {
        return this.getUserGroupOptions()
            .then((userGroupOptions) => {
                return userGroupOptions.map((group) => ({
                        label: group.name,
                        value: group.id
                }))
            });
    }

    public deleteUserGroup(userGroupId: number): IPromise<void> {
        return this.$http.post(this.systemSettings.apiBaseUrl + 'UserGroupActions/Delete?userGroupId=' + userGroupId, null)
            .then(() => {});
    }
}

app.service(Injectables.UserGroupService, UserGroupService);