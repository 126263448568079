import { Injectables } from "../configuration/injectables";
import { ODataFactory, ODataEndpoint } from "./odata";
import A3ApiResponse from "./types/a3ApiResponse";
import app from "../main";
import { IHttpService, IPromise } from "angular";
import { SystemSettings } from "../configuration/settings/systemSettings";
import { UpdateReinstatementRequestDto } from "./types/updateReinstatementRequestDto";
import { RequestReinstatementDto } from "./types/requestReinstatementDto";
import { RescindReinstatementRequestDto } from "./types/rescindReinstatementRequestDto";
import { ReinstatementRequest } from "./types/model/reinstatementRequest";
import { DeclineReinstatementRequestDto } from "./types/declineReinstatementRequestDto";
import { ApproveReinstatementRequestDto } from "./types/approveReinstatementRequestDto";
import { Fee } from "./types/model/fee";

export class ReinstatementRequestService {
    public static $inject = [
        Injectables.$http,
        Injectables.ODataFactory,
        Injectables.SystemSettings
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly odata: ODataFactory,
        private readonly systemSettings: SystemSettings
    ) {}

    
    public approveReinstatementRequestByCarrier(
        reinstatementRequestId: number,
        effectiveDate: Date,
        premiumIncrease: number,
        commission: number,
        reinstatementReasonId: number,
        carrierComments: string
    ): IPromise<void> {
        const request: ApproveReinstatementRequestDto = {
            carrierComments: carrierComments,
            commission: commission,
            effectiveDate: effectiveDate,
            id: reinstatementRequestId,
            premiumIncrease: premiumIncrease,
            reinstatementReasonId: reinstatementReasonId
        };

        return this.$http
            .post(
                this.systemSettings.apiBaseUrl + "ReinstatementRequestActions/ApproveReinstatement",
                request
            )
            .then(() => {});
    }

    public declineReinstatementByCarrier(
        reinstatementRequestId: number,
        carrierComments: string
    ): IPromise<void> {
        const request: DeclineReinstatementRequestDto = {
            carrierComments: carrierComments,
            id: reinstatementRequestId
        };

        return this.$http
            .post(
                this.systemSettings.apiBaseUrl + "ReinstatementRequestActions/DeclineReinstatement",
                request
            )
            .then(() => {});
    }

    public getReinstatementRequest(
        bondId: number
    ): IPromise<ReinstatementRequest> {
        const svc = this.odata.getService<ReinstatementRequest>(
            ODataEndpoint.ReinstatementRequest
        );

        svc.query.filter(`bondId eq ${bondId}`);
        svc.query.top(1);
        svc.query.expand(
            "user($expand=systemAccount($select=phone,companyName))"
        );
        svc.query.orderby("createdDateTime desc");

        return svc.get().then((response) => response.data.value[0]);
    }

    public rescindReinstatementRequest(requestId: number): IPromise<void> {
        const request: RescindReinstatementRequestDto = {
            id: requestId
        };

        return this.$http
            .post(
                this.systemSettings.apiBaseUrl + "ReinstatementRequestActions/RescindReinstatementRequest",
                request
            )
            .then(() => {});
    }

    public requestReinstatement(
        bondId: number,
        requestedEffectiveDate: Date,
        agencyComments: string,
        attorneyInFactUserId: number,
        fees: Fee[]
    ): IPromise<number> {
        if (!bondId || !requestedEffectiveDate) {
            throw new Error("bondId and requestedEffective date not valid");
        }

        const request: RequestReinstatementDto = {
            agencyComments: agencyComments,
            attorneyInFactUserId: attorneyInFactUserId,
            bondId: bondId,
            fees: fees,
            requestedEffectiveDate: requestedEffectiveDate
        } as RequestReinstatementDto;

        return this.$http
            .post<A3ApiResponse<number>>(
                this.systemSettings.apiBaseUrl + "ReinstatementRequestActions/RequestReinstatement",
                request
            )
            .then((response) => response.data.value);
    }

    public updateReinstatementRequest(
        requestId: number,
        requestedEffectiveDate: Date,
        agencyComments: string,
        attorneyInFactUserId: number,
        fees: Fee[]
    ): IPromise<void> {
        const request: UpdateReinstatementRequestDto = {
            agencyComments: agencyComments,
            attorneyInFactUserId: attorneyInFactUserId,
            fees: fees,
            id: requestId,
            requestedEffectiveDate: requestedEffectiveDate
        };

        return this.$http
            .post<A3ApiResponse<number>>(
                this.systemSettings.apiBaseUrl + "ReinstatementRequestActions/UpdateReinstatementRequest",
                request
            )
            .then(() => {});
    }

}

app.service(Injectables.ReinstatementRequestService, ReinstatementRequestService);
