import { IScope, IAttributes } from "angular";
import app from "../../main";

type SortByScope = IScope & {
    onClick: () => void
    onSortChange: () => void
};

const sortBy = () => {

    const link = (scope: SortByScope) => {
        scope.onClick = () => {
            scope.onSortChange();
        }
    }
    
    return {
        restrict: 'A',
        transclude: true,
        scope: { 
            sortBy: '=', 
            currentSort: '=', 
            onSortChange:'&' 
        },
        templateUrl: 'app/components/sortBy/sortBy.html',
        link: link
    };
}

app.directive('sortBy', sortBy)