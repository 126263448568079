import * as angular from "angular";
import { IDirectiveFactory, ICompileService, IDirectiveLinkFn, IScope, IAttributes } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";

type BoundGridDetailsTemplateCompilerScope = IScope & {
    col?: any;
    evalProperty?: any;
    callFunction?: any;
}

const boundGridDetailsTemplateCompiler: IDirectiveFactory = ($compile: ICompileService) => {

    const link: IDirectiveLinkFn = (
        $scope: BoundGridDetailsTemplateCompilerScope, 
        $element: JQuery, 
        $attrs: IAttributes) => {
        
        if ($scope.col && $scope.col.templateContent) {
            const el = angular.element('<span>' + $scope.col.templateContent + '</span>');
            $compile(el)($scope);
            $element.append(el);
        }
    }

    const controller = ($scope: BoundGridDetailsTemplateCompilerScope) => {
        $scope.evalProperty = function(property, value) {
            if (property.indexOf('vm.') === 0) {
                return this.$parent.$parent.$parent.$parent.$parent.vm[property.replace('vm.', '')];
            } else {
                return this.$parent.$parent.$parent.$parent.$parent[property](value);
            }
        };

        $scope.callFunction = function(fn, value) {
            if (fn.indexOf('vm.') === 0) {
                return this.$parent.$parent.$parent.$parent.$parent.vm[fn.replace('vm.', '')](value);
            } else {
                return this.$parent.$parent.$parent.$parent.$parent[fn](value);
            }
        };
    }
    
    return {
        link: link,
        controller: controller,
    }
}

boundGridDetailsTemplateCompiler.$inject = [Injectables.$compile];

app.directive('boundGridTemplateColumnCompiler', boundGridDetailsTemplateCompiler);