import { IDirectiveFactory, IDirectiveLinkFn, IAttributes, IScope } from "angular";
import app from "../../main";

type ButtonColumnScope = IScope & {
    ngShow?: boolean;
    showAuditButton?: boolean | string;
    showEditButton?: boolean | string;
    showDeleteButton?: boolean | string;
    auditButtonOptions?: any;
    cssClass?: string;
    headerCssClass?: string;
    editButtonSref?: string;
}

const buttonColumn: IDirectiveFactory = () => {

    const link: IDirectiveLinkFn = (
        scope: ButtonColumnScope, 
        element: JQuery, 
        attrs: IAttributes, 
        parentController: any) => {
        
        scope.ngShow = scope.ngShow != undefined ? scope.ngShow : true;
        scope.showAuditButton = scope.showAuditButton === 'false' ? false : scope.showAuditButton;
        scope.showEditButton = scope.showEditButton === 'false' ? false : scope.showEditButton;
        scope.showDeleteButton = scope.showDeleteButton === 'false' ? false : scope.showDeleteButton;

        (scope.$parent.$parent as any).addColumn({
            columnType: 'buttonColumn',
            showAuditButton: scope.showAuditButton,
            auditButtonOptions: scope.auditButtonOptions,
            cssClass: scope.cssClass,
            headerCssClass: scope.headerCssClass,
            showEditButton: scope.showEditButton,
            showDeleteButton: scope.showDeleteButton,
            editButtonSref: scope.editButtonSref,
            show: scope.ngShow
        });
    }

    return {
        restrict: 'E',
        replace: true,
        scope: {
            showAuditButton: '@',
            showEditButton: '@',
            showDeleteButton: '@',
            editButtonSref: '@',
            auditButtonOptions: '@',
            cssClass: '@',
            headerCssClass: '@',
            ngShow: '=?'
        },
        require: '^boundGrid',
        link: link,
        template: '<div></div>'
    };
}

app.directive('buttonColumn', buttonColumn);
