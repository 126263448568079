import { BeaconIndicator } from "./beaconIndicator";
import { RejectCode } from "./rejectCode";
import { ScoreReasons } from "./scoreReasons";

export interface USBeacon { 
    beaconScore: string;
    scoreReasons: ScoreReasons;
    rejectCode: RejectCode;
    riskBasedPricing: string;
    doddFrank: string;
    beaconIndicator: BeaconIndicator;
    factActInquiriesAreKeyFactor: string;
}