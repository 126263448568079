import * as angular from "angular";
import { IDirectiveFactory, IDirectiveLinkFn, IScope, IAttributes, IController } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { ModalOpener } from "../../modals/modalOpener";

const showApplicationDetailModalButtonDirective: IDirectiveFactory = (modalOpener: ModalOpener) => {
    
    const link: IDirectiveLinkFn = (
        scope: IScope,
        element: JQuery,
        attributes: IAttributes,
        controller: IController
    ) => {
        scope.$watch(attributes.showApplicationDetailModal, () => {
            var id = 0;

            if (angular.isNumber(attributes.showApplicationDetailModal)) {
                id = attributes.showApplicationDetailModal;
            } else {
                id = scope.$eval(attributes.showApplicationDetailModal);
            }
            
            if (!angular.isNumber(id) || id <= 0) {
                return;
            }

            element.bind('click', (e: Event) => {
                modalOpener.applicationDetailModal(id)
                    .result
                    .then(() => {})
                    .catch(() => {});

                e.stopPropagation();
            });
        });
    }

    return {
        restrict: 'A',
        link: link
    };
}

showApplicationDetailModalButtonDirective.$inject = [Injectables.ModalOpener];

app.directive('showApplicationDetailModal', showApplicationDetailModalButtonDirective);
