import { Injectables } from "../../configuration/injectables";
import app from "../../main";

export type AgentActivityTrackerChartOptions = {
    inquiriesHidden?: boolean;
    prospectsHidden?: boolean;
    submissionsHidden?: boolean;
    newBusinessHidden?: boolean;

    onDataVisiblityChanged: (event: any) => void;
};

export class AmChartService {
    public $inject = [];

    constructor() {}

    private get amCharts() {
        return (window as any).AmCharts;
    }

    public makeAgentActivityTrackerChart = (
        elementId: string,
        data: any,
        options: AgentActivityTrackerChartOptions
    ) => {
        for (let i = 0; i < data.length; i++) {
            data[i].dateTime = this.amCharts.stringToDate(
                data[i].dateTime,
                "YYYY-MM-DD"
            );
        }

        const chart = new this.amCharts.AmStockChart();
        chart.pathToImages = "img/amcharts/";
        chart.export = { enabled: true };

        const dataset = new this.amCharts.DataSet();
        dataset.dataProvider = data;
        dataset.categoryField = "dateTime";
        dataset.fieldMappings = [
            {
                fromField: "inquiryCount",
                toField: "inquiryCount"
            },
            {
                fromField: "prospectCount",
                toField: "prospectCount"
            },
            {
                fromField: "submissionCount",
                toField: "submissionCount"
            },
            {
                fromField: "newBusinessCount",
                toField: "newBusinessCount"
            }
        ];
        chart.dataSets = [dataset];

        const periodSelector = new this.amCharts.PeriodSelector();
        periodSelector.dateFormat = "MM/DD/YYYY";
        periodSelector.position = "top";
        periodSelector.width = 100;
        periodSelector.periods = [
            { period: "DD", count: 5, label: "5 days" },
            { period: "MM", selected: true, count: 1, label: "1 month" },
            { period: "YYYY", count: 1, label: "1 year" },
            { period: "YTD", label: "YTD" },
            { period: "MAX", label: "All" }
        ];
        chart.periodSelector = periodSelector;

        const stockPanel = new this.amCharts.StockPanel();
        chart.panels = [stockPanel];

        const panelsSettings = new this.amCharts.PanelsSettings();
        chart.panelsSettings = panelsSettings;

        const categoryAxesSettings = new this.amCharts.CategoryAxesSettings();
        categoryAxesSettings.minPeriod = "DD";
        categoryAxesSettings.equalSpacing = false;
        categoryAxesSettings.dateFormats = [
            { period: "fff", format: "JJ:NN:SS" },
            { period: "ss", format: "JJ:NN:SS" },
            { period: "mm", format: "JJ:NN" },
            { period: "hh", format: "JJ:NN" },
            { period: "DD", format: "EEE MMM DD" },
            { period: "WW", format: "MMM DD" },
            { period: "MM", format: "MMM" },
            { period: "YYYY", format: "YYYY" }
        ];
        //categoryAxesSettings.dashLength = 10;
        chart.categoryAxesSettings = categoryAxesSettings;

        const valueAxesSettings = new this.amCharts.ValueAxesSettings();
        //valueAxesSettings.dashLength = 5;
        chart.valueAxesSettings = valueAxesSettings;

        const inquiryGraph = new this.amCharts.StockGraph();
        inquiryGraph.valueField = "inquiryCount";
        inquiryGraph.type = "line";
        inquiryGraph.fillAlphas = 0;
        inquiryGraph.lineThickness = 3;
        inquiryGraph.lineColor = "#F8CD95";
        inquiryGraph.useDataSetColors = false;
        (inquiryGraph.balloonText = "[[title]]:<b>[[value]]</b>"),
            (inquiryGraph.compareGraphBalloonText =
                "[[title]]:<b>[[value]]</b>"),
            (inquiryGraph.title = "Inquiries");
        inquiryGraph.hidden = options.inquiriesHidden; //  this.widget.options[inquiryGraph.valueField + 'IsHidden'];
        stockPanel.addStockGraph(inquiryGraph);

        const prospectGraph = new this.amCharts.StockGraph();
        prospectGraph.valueField = "prospectCount";
        prospectGraph.type = "line";
        prospectGraph.fillAlphas = 0;
        prospectGraph.lineThickness = 3;
        prospectGraph.lineColor = "#4B88A2";
        prospectGraph.useDataSetColors = false;
        (prospectGraph.balloonText = "[[title]]:<b>[[value]]</b>"),
            (prospectGraph.compareGraphBalloonText =
                "[[title]]:<b>[[value]]</b>"),
            (prospectGraph.title = "Prospects");
        prospectGraph.hidden = options.prospectsHidden; //this.widget.options[prospectGraph.valueField + 'IsHidden'];
        stockPanel.addStockGraph(prospectGraph);

        const submissionGraph = new this.amCharts.StockGraph();
        submissionGraph.valueField = "submissionCount";
        submissionGraph.type = "line";
        submissionGraph.fillAlphas = 0;
        submissionGraph.lineThickness = 3;
        submissionGraph.lineColor = "#333C48";
        submissionGraph.useDataSetColors = false;
        (submissionGraph.balloonText = "[[title]]:<b>[[value]]</b>"),
            (submissionGraph.compareGraphBalloonText =
                "[[title]]:<b>[[value]]</b>"),
            (submissionGraph.title = "Submissions");
        submissionGraph.hidden = options.submissionsHidden; //this.widget.options[submissionGraph.valueField + 'IsHidden'];
        stockPanel.addStockGraph(submissionGraph);

        const newBusinessGraph = new this.amCharts.StockGraph();
        newBusinessGraph.valueField = "newBusinessCount";
        newBusinessGraph.type = "line";
        newBusinessGraph.fillAlphas = 0;
        newBusinessGraph.lineThickness = 3;
        newBusinessGraph.lineColor = "#FFA400";
        newBusinessGraph.useDataSetColors = false;
        (newBusinessGraph.balloonText = "[[title]]:<b>[[value]]</b>"),
            (newBusinessGraph.compareGraphBalloonText =
                "[[title]]:<b>[[value]]</b>"),
            (newBusinessGraph.title = "New Business");
        newBusinessGraph.hidden = options.newBusinessHidden; //this.widget.options[newBusinessGraph.valueField + 'IsHidden'];
        stockPanel.addStockGraph(newBusinessGraph);

        const chartScrollbarSettings =
            new this.amCharts.ChartScrollbarSettings();
        chartScrollbarSettings.graph = newBusinessGraph;
        chartScrollbarSettings.graphType = "column";
        chart.chartScrollbarSettings = chartScrollbarSettings;

        const stockLegend = new this.amCharts.StockLegend();
        stockLegend.position = "absolute";
        stockLegend.top = 0;
        stockLegend.left = 45;
        stockLegend.autoMargins = false;
        stockLegend.labelWidth = 85;
        stockLegend.valueWidth = 25;
        stockLegend.spacing = 10;
        stockPanel.addLegend(stockLegend);

        const chartCursorSettings = new this.amCharts.ChartCursorSettings();
        chartCursorSettings.valueBalloonsEnabled = true;
        chartCursorSettings.fullWidth = true;
        chartCursorSettings.cursorAlpha = 0.1;
        chartCursorSettings.valueLineBalloonEnabled = true;
        chartCursorSettings.valueLineEnabled = true;
        chartCursorSettings.valueLineAlpha = 0.5;
        chartCursorSettings.color = "#DDD";
        chartCursorSettings.cursorColor = "#353130";
        chartCursorSettings.categoryBalloonDateFormats = [
            { period: "YYYY", format: "YYYY" },
            { period: "MM", format: "MMM, YYYY" },
            { period: "WW", format: "EEE MMM DD, YYYY" },
            { period: "DD", format: "EEE MMM DD, YYYY" },
            { period: "hh", format: "JJ:NN" },
            { period: "mm", format: "JJ:NN" },
            { period: "ss", format: "JJ:NN:SS" },
            { period: "fff", format: "JJ:NN:SS" }
        ];
        chart.chartCursorSettings = chartCursorSettings;

        chart.write(elementId);

        if (options.onDataVisiblityChanged instanceof Function) {
            chart.panels[0].legend.addListener(
                "hideItem",
                options.onDataVisiblityChanged
            );
            chart.panels[0].legend.addListener(
                "showItem",
                options.onDataVisiblityChanged
            );
        }
    };

    public makePieChart = (elementId: string, data: any) => {
        if (!this.amCharts) {
            return;
        }

        const chart = this.amCharts.makeChart(elementId, {
            type: "pie",
            theme: "a3",
            titleField: "companyName",
            valueField: "count",
            labelRadius: -40,
            labelText: "[[companyName]]",
            innerRadius: 80,
            pullOutRadius: 15,
            marginTop: 30,
            addClassNames: true,
            allLabels: [
                {
                    y: "51%",
                    align: "center",
                    size: 25,
                    bold: true,
                    text: data.totalCount,
                    color: "#555"
                },
                {
                    y: "46%",
                    align: "center",
                    size: 15,
                    text: "Total Bonds",
                    color: "#555"
                }
            ],
            defs: {
                filter: [
                    {
                        id: "shadow",
                        width: "200%",
                        height: "200%",
                        feOffset: {
                            result: "offOut",
                            in: "SourceAlpha",
                            dx: 0,
                            dy: 0
                        },
                        feGaussianBlur: {
                            result: "blurOut",
                            in: "offOut",
                            stdDeviation: 5
                        },
                        feBlend: {
                            in: "SourceGraphic",
                            in2: "blurOut",
                            mode: "normal"
                        }
                    }
                ]
            },
            dataProvider: data.carrierBreakdowns,
            balloon: {
                fixedPosition: true
            },
            startDuration: 0,
            export: {
                enabled: false
            }
        });

        const handleRollOver = (e) => {
            const wedge = e.dataItem.wedge.node;
            wedge.parentNode.appendChild(wedge);
        };

        chart.addListener("rollOverSlice", (e) => {
            handleRollOver(e);
        });
    };

    public makeUsaMapChart = (elementId: string, data: any) => {
        if (!this.amCharts) {
            return;
        }

        this.amCharts.makeChart(elementId, {
            type: "map",
            theme: "a3",
            colorSteps: 10,
            dataProvider: {
                map: "usaHigh",
                areas: data
            },
            areasSettings: {
                autoZoom: true,
                balloonText: "[[title]]: <strong>[[value]]</strong>"
            },
            valueLegend: {
                right: 10,
                minValue: "none",
                maxValue: "a lot!"
            },
            export: {
                enabled: false
            }
        });
    };
}

app.service(Injectables.AmChartService, AmChartService);
