import { IHttpService, IQService, IPromise } from "angular";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/settings/systemSettings";
import app from "../main";
import { ODataFactory, ODataEndpoint } from "./odata";
import A3ApiResponse from "./types/a3ApiResponse";
import { ApplicationFollowUp } from "./types/model/applicationFollowUp";


export class FollowUpService {

    public static $inject = [
        Injectables.$http,
        Injectables.ODataFactory,
        Injectables.SystemSettings
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly odata: ODataFactory,
        private readonly systemSettings: SystemSettings) {
    }

    public copyFollowUp(followUp: ApplicationFollowUp): IPromise<ApplicationFollowUp> {
        this.validateFollowup(followUp);

        return this.$http
            .get<A3ApiResponse<ApplicationFollowUp>>(this.systemSettings.apiBaseUrl + 'ApplicationFollowUpActions/Copy?id=' + followUp.id)
            .then((response) => response.data.value);
    }

    public getByApplicationId(applicationId: number): IPromise<ApplicationFollowUp[]> {
        if (!applicationId) {
            throw new Error('applicationId is not valid');
        }

        const svc = this.odata.getService<ApplicationFollowUp>(ODataEndpoint.ApplicationFollowUp);

        svc.query.filter(`applicationId eq ${applicationId}`);

        return svc.get().then((response) => response.data.value);
    }

    public insert(followUp: ApplicationFollowUp): IPromise<ApplicationFollowUp> {
        this.validateFollowupWithLeadAndApplication(followUp);

        const svc = this.odata.getService<ApplicationFollowUp>(ODataEndpoint.ApplicationFollowUp);

        return svc.post<A3ApiResponse<ApplicationFollowUp>>(followUp)
            .then((response) => response.data.value);
    }

    public send(followUp: ApplicationFollowUp): IPromise<ApplicationFollowUp> {
        this.validateFollowup(followUp);

        return this.$http
            .get(this.systemSettings.apiBaseUrl + 'ApplicationFollowUpActions/Send?id=' + followUp.id)
            .then(() => {
                followUp.sent = true;
                followUp.sendDateTime = new Date();
                return followUp;
            });
    }

    public subscribe(followUp: ApplicationFollowUp): IPromise<ApplicationFollowUp> {
        this.validateFollowup(followUp);

        return this.$http
            .get(this.systemSettings.apiBaseUrl + 'ApplicationFollowUpActions/Subscribe?id=' + followUp.id)
            .then(() => {
                followUp.unsubscribed = false;
                return followUp;
            });
    }

    public unsubscribe(followUp: ApplicationFollowUp): IPromise<ApplicationFollowUp> {
        this.validateFollowup(followUp);

        return this.$http
            .get(this.systemSettings.apiBaseUrl + 'ApplicationFollowUpActions/Unsubscribe?id=' + followUp.id)
            .then(() => {
                followUp.unsubscribed = true;
                return followUp;
            });
    }

    public update(followUp: ApplicationFollowUp): IPromise<ApplicationFollowUp> {
        this.validateFollowupWithLeadAndApplication(followUp);

        const svc = this.odata.getService<ApplicationFollowUp>(ODataEndpoint.ApplicationFollowUp);

        return svc.put<A3ApiResponse<ApplicationFollowUp>>(followUp.id, followUp)
            .then((response) => {
                return response.data.value;
            });
    }

    public validateFollowup(followUp: ApplicationFollowUp): void {
        if (!followUp) {
            throw new Error('followUp is not valid');
        }
    }

    public validateFollowupWithLeadAndApplication(followUp: ApplicationFollowUp): void {
        if (!followUp) {
            throw new Error('followUp is not valid');
        }
        if (!followUp.leadId && !followUp.applicationId) {
            throw new Error('either leadId or applicationId is required for saving follow ups');
        }
    }
}

app.service(Injectables.FollowUpService, FollowUpService);
