import { SystemAccountService } from "../../../../api/systemAccountService";
import { SelectOption } from "../../../../api/types/selectOption";
import { Injectables } from "../../../../configuration/injectables";
import app from "../../../../main";
import isUndefinedOrNull from "../../../../utilities/angularUtilities/isUndefinedOrNull";
import { Table } from "../../../../utilities/tables/table";
import { PurchasedBondsReportDropdownFilterOptions } from "./purchasedBondsReportDropdownFilterOptions";
import { PurchasedBondItem } from "../purchasedBondItem";
import { CurrentUserResolver } from "../../../../utilities/currentUserResolver/currentUserResolver";


class PurchasedBondsReportDropdownFilterController {
    
    public static $inject = [
        Injectables.SystemAccountService,
        Injectables.CurrentUserResolver
    ];

    constructor(
        private readonly systemAccountService: SystemAccountService,
        private readonly currentUserResolver: CurrentUserResolver
    ) {
        this.modifiedFilters = {} as PurchasedBondsReportDropdownFilterOptions;
        this.filterCount = 0;
    }

    public isOpen: boolean;
    public filterCount = 0;
    public modifiedFilters: PurchasedBondsReportDropdownFilterOptions;
    public carrierOptions: SelectOption<number>[];
    public agencyOptions: SelectOption<number>[];
    public isCarrier: boolean;
    public table: Table<PurchasedBondItem, PurchasedBondsReportDropdownFilterOptions>;

    public clearFilters() {
        this.modifiedFilters = {
            status: null,
            carrierSystemAccountId: null,
            agencySystemAccountId: null,
            minimumAmount: null,
            maximumAmount: null,
            effectiveDate: null,
            expirationDate: null
        } as PurchasedBondsReportDropdownFilterOptions;
        this.table.applyFilters({ ...this.modifiedFilters });
        
        this.setFilterCount();
        this.isOpen = false;
    }

    private loadCarrierOptions() {
        return this.systemAccountService.getAppointedCarrierOptions()
            .then((carrierOptions) => {
                this.carrierOptions = carrierOptions;
                this.carrierOptions.unshift({ label: 'Any', value: null });
            });
    }

    private loadAgencyOptions() {
        return this.systemAccountService.getAppointedAgencyOptions()
            .then((agencyOptions) => {
                this.agencyOptions = agencyOptions;
                this.agencyOptions.unshift({ label: 'Any', value: null});
            });
    }

    public cancel() {
        this.isOpen = false;
        this.modifiedFilters = { ...this.table.queryOptions };
    }

    public applyFilters() {
        this.isOpen = false;
        this.table.applyFilters({ ...this.modifiedFilters });
        this.setFilterCount();
    }

    private setModifiedFilters = () => {
        this.modifiedFilters.status = this.table.queryOptions.status;
        this.modifiedFilters.carrierSystemAccountId = this.table.queryOptions.carrierSystemAccountId;
        this.modifiedFilters.agencySystemAccountId = this.table.queryOptions.agencySystemAccountId;
        this.modifiedFilters.minimumAmount = this.table.queryOptions.minimumAmount;
        this.modifiedFilters.maximumAmount = this.table.queryOptions.maximumAmount;
        this.modifiedFilters.effectiveDate = this.table.queryOptions.effectiveDate;
        this.modifiedFilters.expirationDate = this.table.queryOptions.expirationDate;

        this.setFilterCount();
    };

    public setFilterCount() {
        this.filterCount = 0;

        if (!isUndefinedOrNull(this.table.queryOptions.status)) {
            this.filterCount++;
        }

        if (!isUndefinedOrNull(this.table.queryOptions.carrierSystemAccountId)) {
            this.filterCount++;
        }

        if (!isUndefinedOrNull(this.table.queryOptions.agencySystemAccountId)) {
            this.filterCount++;
        }

        if (
            this.table.queryOptions.minimumAmount ||
            this.table.queryOptions.maximumAmount
        ) {
            this.filterCount++;
        }
       
        if (
            this.table.queryOptions.effectiveDate || 
            this.table.queryOptions.expirationDate
        ) {
            this.filterCount++;
        }
    }

    public $onInit() {
        this.isCarrier = this.currentUserResolver.getCurrentUser().systemAccount.isCarrier;
        
        if (this.isCarrier) {
            this.loadAgencyOptions();
        } else {
            this.loadCarrierOptions();
        }

        this.setModifiedFilters();
    }
}

const purchasedBondsReportDropdownFilterComponent = {
    bindings: {
        table: "<"
    },
    controller: PurchasedBondsReportDropdownFilterController,
    templateUrl:"app/states/common/purchasedBonds/purchasedBondsReportDropdown/purchasedBondsReportDropdownFilter.html",
	controllerAs: "vm",
};

app.component(
    "purchasedBondsReportDropdownFilter",
    purchasedBondsReportDropdownFilterComponent
);
