export interface ApplicationAccessFollowUpOptions 
{
    emailAddress: string;
    subject: string;
    message: string;
    applicationId?: number;
    bondTypeId?: number;
    bondAmount?: number;
    emailTemplateId?: number;
    ctaUrl?: string;
}