import { Company } from "./company";
import { IBaseEntity } from "./iBaseEntity";
import { MasterLicense } from "./masterLicense";

export interface CompanyLicense extends IBaseEntity { 
    number: string;
    masterLicenseId: number;
    expirationDate: Date;
    companyId: number;
    company: Company;
    masterLicense: MasterLicense;
    id: number;
    createdDateTime: Date;
}