import { SelectOption } from "../../api/types/selectOption";
import { UserService } from "../../api/userService";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import parseBoolean from "../../utilities/booleans/parseBoolean";
import { UserForDropdownRequestOptions } from "./userForDropdownRequestOptions";

export class UserDropdownController {

    public static $inject = [
        Injectables.UserService
    ];

    constructor(
        private readonly userService: UserService
    ) {}

    public allUsers: string;
    public allowUnselected: string;
    public unselectedText: string;
    public isProducer: string;
    public isClientServiceManager: string;
    public isClientServiceAgent: string;
    public isClientServiceExecutive: string;
    public isAttorneyInFact: string;
    public ngRequired: boolean;
    public ngChange: () => void;
    public ngModel: number;

    public userOptions: SelectOption<number>[];
    public userDropDownRequstOptions: UserForDropdownRequestOptions

    private loadUserOptions() {
        
        const options = {
            allUsers: parseBoolean(this.allUsers),
            allowUnselected: parseBoolean(this.allowUnselected),
            unselectedText: this.unselectedText,
            isProducer: parseBoolean(this.isProducer),
            isAttorneyInFact: parseBoolean(this.isAttorneyInFact),
            isClientServiceAgent: parseBoolean(this.isClientServiceAgent),
            isClientServiceManager: parseBoolean(this.isClientServiceManager),
            isClientServiceExecutive: parseBoolean(this.isClientServiceExecutive)
        };

        return this.userService.getUsersForDropdown(options)
            .then((SelectOption) => {
                this.userOptions = SelectOption;
            });
    }

    public changed = () => {
        if (!this.ngModel) {
            this.ngModel = null;
        }

        if (this.ngChange instanceof Function) {
            this.ngChange();
        }
    }

    public $onInit() {
        this.loadUserOptions();
    }
}

const userDropdownComponent = {
    bindings: {
        label: '@',
        class: '@?',
        ngModel: '=',
        ngChange: '&?',
        allUsers: '@?',
        allowUnselected: '@?',
        unselectedText: '@?',
        isProducer: '@?',
        isClientServiceManager: '@?',
        isClientServiceAgent: '@?',
        isClientServiceExecutive: '@?',
        isAttorneyInFact: '@?',
        ngRequired: '<?'
    },
    controller: UserDropdownController,
    controllerAs: 'vm',
    templateUrl: 'app/components/userDropdown/userDropdown.html'
};

app.component('userDropdown', userDropdownComponent);