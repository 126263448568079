import { ApplicationService } from "../../api/applicationService";
import { IndemnityDto } from "../../api/types/modalIndemnityDto";
import { IndemnityType, IndemnityStatus } from "../../api/types/model/indemnitySignature";
import { PeopleAndCompanyOptions } from "../../api/types/peopleAndCompanyOptions";
import { SelectOption } from "../../api/types/selectOption";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { IndemnityOptions } from "./indemnityModalOptions";
import { IndemnityResult } from "./IndemnityResult";
import app from "../../main";
import { IPromise } from "angular";

export class IndemnityModalController {

    public static $inject = [
        Injectables.$uibModalInstance, 
        Injectables.Options, 
        Injectables.ApplicationService, 
        Injectables.ToastMessageCreator
    ];

    constructor(

        private readonly $uibModalInstance: Modal<IndemnityResult>,
        private readonly options: IndemnityOptions,
        private readonly applicationService: ApplicationService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {}

    public indemnitySignatures:  IndemnityDto[];
    public processingPromise: IPromise<any>;
    public showAddNewIndemnityPane: boolean;
    public indemnityTypeOptions: SelectOption< IndemnityType>[];
    public peopleAndCompanyOptions:  PeopleAndCompanyOptions;

    public saveAndSend(): void {
        this.processingPromise = this.applicationService.saveAndSendIndemnitySignatures(this.indemnitySignatures)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Indemnity signature notifications have been sent');
                this.$uibModalInstance.close(null);
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to send the emails to these individuals');
            });
    }

    public save(): void {
        for (const indemnitySignature of this.indemnitySignatures) {
            indemnitySignature.send = false;
        }

        this.processingPromise = this.applicationService.saveIndemnities(this.indemnitySignatures)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Indemnity signature notifications have been saved');
                this.$uibModalInstance.close(null);
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to save these indemnity records');
            });
    }

    public saveIndemnities(): void {
        this.processingPromise = this.applicationService.saveIndemnities(this.indemnitySignatures)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Indemnity signatures have been saved');
                this.$uibModalInstance.close(null);
                return;
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred while trying to save');
            });
    }

    public toggleRemoveFlag(indemnity:  IndemnityDto, index: number): void {
        if (indemnity.isRemoved && indemnity.id) {
            indemnity.isRemoved = false;
        } else if (indemnity.isRemoved && !indemnity.id) {
            this.indemnitySignatures = this.indemnitySignatures.splice(index, 1);
        } else {
            indemnity.isRemoved = true;
            indemnity.send = false;
        }
    }

    public addIndemnity(): void {    
        var personId: number = null;

        if (this.peopleAndCompanyOptions.people.length == 1) {
            personId = this.peopleAndCompanyOptions.people[0].id;
        }

        this.indemnitySignatures.unshift({
            quoteId: this.options.quoteId,
            indemnityType:  IndemnityType.Principal,
            personId: personId
        } as  IndemnityDto);
    }

    public getIndemnitySignatures(quoteId: number): void {
        if (!quoteId) {
            throw new Error('quoteId is not valid');
        }

        this.processingPromise = this.applicationService.getIndemnitySignatures(quoteId)
            .then((indemnitySignatures) => {
                this.indemnitySignatures = indemnitySignatures as  IndemnityDto[];

                for (const indemnitySignature of this.indemnitySignatures) {
                    indemnitySignature.send = indemnitySignature.status ===  IndemnityStatus.NotSent;
                }

                return this.applicationService.getPeopleAndCompanyOptionsByQuoteId(quoteId);
            })
            .then((peopleAndCompanyOptions) => {
                this.peopleAndCompanyOptions = peopleAndCompanyOptions;

                var indemnityTypesToKeep: SelectOption< IndemnityType>[] = [];
                var allIndemnityTypeOptions = this.applicationService.getIndemnityTypeOptions();
                
                for(var i = 0; i < allIndemnityTypeOptions.length; i++) {

                    if (allIndemnityTypeOptions[i].value !=  IndemnityType.Corporate ||
                        this.peopleAndCompanyOptions.companies.length) {
                            
                        indemnityTypesToKeep.push(allIndemnityTypeOptions[i]);
                    }
                }   
                
                this.indemnityTypeOptions = indemnityTypesToKeep;
            });
    }

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }

    public $onInit(): void {
        if (!this.options.quoteId) {
            throw new Error('options.quoteId is not valid');
        }
        
        this.getIndemnitySignatures(this.options.quoteId);
    }
}

app.controller('IndemnityModalController', IndemnityModalController);
