import * as angular from "angular";
import { IDirectiveFactory, IDirectiveLinkFn, IScope, IAttributes, IController } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { ModalOpener } from "../../modals/modalOpener";


const showBondDetailModalButtonDirective: IDirectiveFactory = (modalOpener: ModalOpener) => {

    const link: IDirectiveLinkFn = (
        scope: IScope,
        element: JQuery,
        attributes: IAttributes,
        controller:IController
    ) => {
        scope.$watch(attributes.showBondDetailModal, () => {
            var id = 0;

            if (angular.isNumber(attributes.showBondDetailModal)) {
                id = attributes.showBondDetailModal;
            } else {
                id = scope.$eval(attributes.showBondDetailModal);
            }

            if (!angular.isNumber(id) || id <= 0) {                
                return;
            }  

            element.bind('click', (e: Event) => {
                modalOpener.bondDetailModal(id)
                    .result
                    .then(() => { })
                    .catch(() => { });

                e.stopPropagation();
            });
        });
    }
    return {
        restrict: 'A',
        link: link
    };

}

showBondDetailModalButtonDirective.$inject = [Injectables.ModalOpener];

app.directive('showBondDetailModal', showBondDetailModalButtonDirective);