import { IDirectiveFactory, IDirectiveLinkFn, IScope, IAttributes } from "angular";
import { Company } from "../../api/types/model/company";
import { RequiredFieldSet } from "../../api/types/model/requiredFieldSet";
import { SelectOption } from "../../api/types/selectOption";
import { constants } from "../../configuration/constants";
import app from "../../main";
import isDefined from "../../utilities/angularUtilities/isDefined";

type CompanyChange = {
    company: Company;
    changedProperty: string;
}

class CompanyDirectiveController {
    
    public static $inject = [];

    constructor() {}

    public isDisabled: boolean;
    public requiredFields: RequiredFieldSet;
    public companyTypes: SelectOption<string>[];
    public company: Company;
    public useDefaultHeading: boolean;
    public hideOptionalFields: boolean;
    public heading: string;
    public changed: (change: CompanyChange) => void;

    public addressChanged = (company: Company, changedProperty: string) => {
        this.localChange(changedProperty);
    }

    public localChange = (property: string) => {
        this.changed({ company: this.company, changedProperty: property });
    }

    public $onInit = () => {
        if (!this.requiredFields) {
            this.requiredFields = {} as RequiredFieldSet;
        }

        this.companyTypes = constants.companyTypeOptions;
    }
}

const companyDirective: IDirectiveFactory = () => {

    const link: IDirectiveLinkFn = (
        scope: IScope, 
        elment: JQuery, 
        attrs: IAttributes, 
        ctrl: CompanyDirectiveController) => {

        if (isDefined(attrs.disabled)) {
            ctrl.isDisabled = true;
        }

        if (isDefined(attrs.ngDisabled)) {
            ctrl.isDisabled = scope.$parent.$eval(attrs.ngDisabled);
        }
    }

    return {
        restrict: 'E',
        templateUrl: 'app/components/companyForm/company.html',
        scope: {},
        transclude: {
            heading: '?companyHeading'
        },
        bindToController: { 
            company: '=', 
            changed: '&', 
            requiredFields: '=?', 
            hideOptionalFields: '=', 
            heading: '=', 
            isDisabled: '=?' 
        },
        link: link,
        controller: CompanyDirectiveController,
        controllerAs: 'vm'
    };
}

app.directive('company', companyDirective);

