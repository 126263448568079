import { IBaseEntity } from "./iBaseEntity";
import { Rate } from "./rate";
import { RateOverrideCondition } from "./rateOverrideCondition";

export interface RateOverride extends IBaseEntity {
    underWritingConfigurationId: number;
    rate: Rate;
    rateId: number;
    sequence: number;
    conditions: RateOverrideCondition[];
}
