import { IDirectiveFactory, IDirectiveLinkFn, IScope } from "angular";
import app from "../../main";
import Inputmask from "inputmask";

const moneyMaskDirective: IDirectiveFactory = () => {

    const link: IDirectiveLinkFn = (
        scope: IScope,
        elem: JQuery
    ) => {
        const mask = new Inputmask('numeric', { 
            groupSeparator: ',', 
            digitsOptional: false,
            allowMinus: false, 
            digits: 2, 
            rightAlign: false,
            placeholder: '0',
            prefix: '$',
            positionCaretOnClick: "select",
            clearMaskOnLostFocus: true,
            enforceDigitsOnBlur: false,
            inputType: 'number',
        });

        mask.mask(elem);
    }

    return {
        link: link
    }
}

app.directive('maskMoney', moneyMaskDirective);
