import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { IQueryStringBuilder } from "./iQueryStringBuilder";

export class QueryStringBuilder implements IQueryStringBuilder {

    constructor() {}

    public buildQueryString(request: {}): string {
        let queryString = '';

        for (const prop of Object.getOwnPropertyNames(request)) {
            queryString += `&${prop}=${request[prop]}`;
        }

        if (queryString) {
            queryString = `?${queryString.replace(/^&/, '')}`;
        }

        return queryString;
    };
}

app.service(Injectables.QueryStringBuilder, QueryStringBuilder);