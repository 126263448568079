import { A3RootScope } from "../../api/types/a3RootScope";
import { UserService } from "../../api/userService";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";

class UserAvatarController {

    static $inject = [
        Injectables.$rootScope,
        Injectables.UserService
    ];

    constructor(
        private readonly $rootScope: A3RootScope,
        private readonly userService: UserService
    ) {}
    
    public userId: number;
    public userProfilePicturePath: string;

    public downloadUserProfileImage = () => {
        this.userService.downloadUserProfileImage(this.userId)
            .then((file) => {
                if (file) {
                    this.userProfilePicturePath = file;
                } else {
                    this.userProfilePicturePath = '/img/unknown.profile.png';
                }                
            })
            .catch(() => {
                this.userProfilePicturePath = '/img/unknown.profile.png';
            });
    }

    public $onInit = () => {
        this.downloadUserProfileImage();
        this.$rootScope.$on('profileImageUpdated', this.downloadUserProfileImage);
    }
}

const userAvatarComponent = {
    bindings: {
        userId: '='
    },
    templateUrl: 'app/components/userAvatar/userAvatar.html',
    controller: UserAvatarController,
    controllerAs: 'vm'
};

app.component('userAvatar', userAvatarComponent);