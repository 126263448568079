import * as angular from "angular";
import { IDirectiveFactory, IDirectiveLinkFn, IScope, IAttributes, IController } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { ModalOpener } from "../../modals/modalOpener";

const showPaymentAssignmentModalDirective: IDirectiveFactory = (modalOpener: ModalOpener) => {

    const link: IDirectiveLinkFn = (
        scope: IScope,
        element: JQuery,
        attributes: IAttributes,
        controller:IController
    ) => {
        scope.$watch(attributes.showPaymentAssignmentModal, () => {
            var id = 0;

            if (angular.isNumber(attributes.showPaymentAssignmentModal)) {
                id = attributes.showPaymentAssignmentModal;
            } else {
                id = scope.$eval(attributes.showPaymentAssignmentModal);
            }

            if (!angular.isNumber(id) || id <= 0) {
                return;
            }

            element.bind('click', (e: Event) => {
                modalOpener.showPaymentAssignmentModal(id)
                    .result
                    .then(() => { })
                    .catch(() => { });

                e.stopPropagation();
            });
        });
    }

    return {
        restrict: 'A',
        link: link
    };
}

showPaymentAssignmentModalDirective.$inject = [Injectables.ModalOpener];

app.directive('showPaymentAssignmentModal', showPaymentAssignmentModalDirective);
