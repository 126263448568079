import { IJQueryService } from "../../../utilities/jquery/iJQueryService";
import defaultWrapper from "../defaultWrapper";
import fieldGroupWrapper from "../fieldGroupWrapper";

export default (element: JQuery, jQueryProvider: IJQueryService) => {
    element.attr('type', 'text');
    element.attr('mask-percent', '');
    element.attr('is-float', '');

    fieldGroupWrapper(element, defaultWrapper(element), jQueryProvider);
};