import { IScope, IQService, ITimeoutService, IPromise, IIntervalService } from "angular";
import { FileUploadMetadata } from "../../components/documentPicker/documentPicker";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { ModalUploadOptions } from "./modalUploadOptions";
import { ModalUploadProgress } from "./modalUploadProgress";
import { Modal } from "../../components/modals/modal";

export class UploadModalController {
    
    public static $inject = [
        Injectables.$uibModalInstance, 
        Injectables.Options, 
        Injectables.$interval
    ];

    constructor(
        private readonly $uibModalInstance: Modal<void>, 
        private readonly options: ModalUploadOptions,
        private readonly $interval: IIntervalService) {
    }

    public stopInterval: IPromise<void>;
    public files: FileUploadMetadata[];
    public closeDelayCounter = 0;

    public get total(): number {
        let _total = 0;
        
        for(let i = 0; i < this.files.length; i++) {
            _total += this.files[i].total;
        }

        return _total;
    }

    public get sent(): number {
        let _sent = 0;
        
        for(let i = 0; i < this.files.length; i++) {
            _sent += this.files[i].sent;
        }

        return _sent;
    }

    public cancel = () => {
        this.$uibModalInstance.dismiss('cancel');
        this.$interval.cancel(this.stopInterval);
    }

    public updateProgress = () => {
       
        if (this.sent >= this.total) {
            this.closeDelayCounter++;
        }

        if (this.closeDelayCounter >= 5) {
            this.$uibModalInstance.close();
            this.$interval.cancel(this.stopInterval);
        }
    };

    public $onInit = () => {
        this.files = this.options.files;
        this.stopInterval = this.$interval(this.updateProgress, 100);
    }
}

app.controller('UploadModalController', UploadModalController);