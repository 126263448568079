import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { DashboardService } from "../../states/common/dashboard/dashboardService";
import { Dashboard } from "../../states/common/dashboard/types/dashboard";
import { DashboardWidget } from "../../states/common/dashboard/types/dashboardWidget";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";
import app from "../../main";

export class AddWidgetModalController {

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.DashboardService,
        Injectables.CurrentUserResolver
    ];

    constructor(
        private readonly $uibModalInstance: Modal<void>,
        private readonly dashboardService: DashboardService,
        private readonly currentUserResolver: CurrentUserResolver
    ){
    }

    public widgetCategories: Dashboard[];
    public search: string;
    public displayWidgetType: DashboardWidget;

    public addWidget = (widgetType: DashboardWidget) => {
        this.dashboardService.addWidgetToCurrentDashboard(widgetType);
        this.$uibModalInstance.close();
    };

    public cancel = () => {
        this.$uibModalInstance.close();
    };

    public showWidgetType = (widgetType: DashboardWidget) => {
        this.displayWidgetType = widgetType;
    };

    public searchWidgetTypes = (widgetType: DashboardWidget) => {
        return this.dashboardService.doesWidgetMatchSearch(widgetType, this.search);
    };

    public searchTags = (tagName: string) => {
        this.search = '#' + tagName;
    }

    public $onInit = () => {
        this.widgetCategories = this.dashboardService.getWidgetTypes(this.currentUserResolver.getCurrentUser());
        
        // Setting first widget category (and type) to display by default for user
        this.widgetCategories[0]['open'] = true;
        this.displayWidgetType = this.widgetCategories[0].widgetTypes[0];
    }
}

app.controller('AddWidgetModalController', AddWidgetModalController);