import { IHttpService, IPromise } from "angular";
import { AttachmentTableListItem } from "../components/attachmentList/attachmentTableListItem";
import { AttachmentTableQueryOptions } from "../components/attachmentList/attachmentTableQueryOptions";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/settings/systemSettings";
import app from "../main";
import { AttachmentModalData } from "../modals/attachmentModal/attachmentModalData";
import A3ApiResponse from "./types/a3ApiResponse";
import { PageResponse } from "./types/pageResponse";

export class AttachmentService {

    public static $inject = [
        Injectables.$http,
        Injectables.SystemSettings
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly systemSettings: SystemSettings) {
    }

    public deleteAttachment(attachmentId: number): IPromise<void> {
        return this.$http.post<void>(`${this.systemSettings.apiBaseUrl}AttachmentActions/DeleteAttachment?attachmentId=${attachmentId}`, null)
            .then(() => {});
    }

    public getAttachmentDownloadUrl(attachmentId: number): string {
        return this.systemSettings.apiBaseUrl + 'Download/Attachment?id=' + attachmentId;
    }

    public getAttachmentModalData(attachmentId: number): IPromise<AttachmentModalData> {
        return this.$http.get<A3ApiResponse<AttachmentModalData>>(`${this.systemSettings.apiBaseUrl}AttachmentActions/GetAttachmentModalData?attachmentId=${attachmentId}`)
            .then((response) => {
                return response.data.value;
            });
    }

    public save(attachment: AttachmentModalData): IPromise<void> {
        return this.$http.post<void>(`${this.systemSettings.apiBaseUrl}AttachmentActions/SaveAttachmentModalData`, attachment)
            .then(() => {});
    }

    public getCustomerAttachments(customerId: number, tableQueryOptions: AttachmentTableQueryOptions): IPromise<PageResponse<AttachmentTableListItem>> {
        return this.getAttachments(customerId, null, null, null, tableQueryOptions);
    }

    public getEProducerAttachments(eproducerAccountId: number, tableQueryOptions: AttachmentTableQueryOptions): IPromise<PageResponse<AttachmentTableListItem>> {
        return this.getAttachments(null, eproducerAccountId, null, null, tableQueryOptions);
    }

    public getBondAttachments(bondId: number, tableQueryOptions: AttachmentTableQueryOptions): IPromise<PageResponse<AttachmentTableListItem>> {
        return this.getAttachments(null, null, bondId, null, tableQueryOptions);
    }

    public getApplicationAttachments(applicationId: number, tableQueryOptions: AttachmentTableQueryOptions): IPromise<PageResponse<AttachmentTableListItem>> {
        return this.getAttachments(null, null, null, applicationId, tableQueryOptions);
    }

    private getAttachments(
        customerId?: number, 
        eProducerAccountId?: number, 
        bondId?: number, 
        applicationId?: number,
        tableQueryOptions?: AttachmentTableQueryOptions
    ): IPromise<PageResponse<AttachmentTableListItem>> {

        if (!tableQueryOptions) {
            tableQueryOptions = {
                pageNumber: 1,
                recordsPerPage: 10,
                orderBy: '',
                searchPhrase: ''
            };
        }
        
        if (!tableQueryOptions.searchPhrase) {
            tableQueryOptions.searchPhrase = '';
        }

        if (!tableQueryOptions.orderBy) {
            tableQueryOptions.orderBy = 'createdDateTime desc';
        }

        let queryString = `?`;

        if (customerId) {
            queryString += `&customerId=${customerId}`;
        }

        if (eProducerAccountId) {
            queryString += `&eProducerAccountId=${eProducerAccountId}`;
        }

        if (bondId) {
            queryString += `&bondId=${bondId}`;
        }

        if (applicationId) {
            queryString += `&applicationId=${applicationId}`;
        }

        if (tableQueryOptions.attachmentTypeId) {
            queryString += `&attachmentTypeId=${tableQueryOptions.attachmentTypeId}`;
        }

        if (tableQueryOptions.isSharedWithCarrier) {
            queryString += `&isSharedWithCarrier=${tableQueryOptions.isSharedWithCarrier}`;
        }

        if (tableQueryOptions.isSharedWithAgent) {
            queryString += `&isSharedWithAgent=${tableQueryOptions.isSharedWithAgent}`;
        }

        if (tableQueryOptions.isSharedThroughEProducer) {
            queryString += `&isSharedThroughEProducer=${tableQueryOptions.isSharedThroughEProducer}`;
        }

        queryString += `&pageNumber=${tableQueryOptions.pageNumber}`;
        queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
        queryString += `&orderBy=${tableQueryOptions.orderBy}`;
        queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;

        return this.$http
            .get<A3ApiResponse<PageResponse<AttachmentTableListItem>>>(this.systemSettings.apiBaseUrl + 'AttachmentActions/GetAttachments' + queryString)
            .then((response) => response.data.value);
    }
}

app.service(Injectables.AttachmentService, AttachmentService);
