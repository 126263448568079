import { ActivityAttachment } from "../../../components/activityAttachmentList/activityAttachment";
import { ActivityConversationMessage } from "./activityConversationMessage";
import { ActivityStatus } from "./activityStatus";
import { ActivityTypeAssignment } from "./activityTypeAssignment";

export interface ActivityDetail {
    id: number;
    customerId?: number;
    bondId?: number;
    bondAccountId?: number;
    eProducerAccountId?: number;
    applicationId?: number;
    description: string;
    status: ActivityStatus;
    createdDateTime: Date;
    lastUpdateDateTime: Date;
    createdByUserFullName: string;
    createdByUserId: number;
    activityTypeAssignments: ActivityTypeAssignment[];
    attachments: ActivityAttachment[];
    conversationMessages: ActivityConversationMessage[];
    newConversationMessage: string;
    assignedToUserId?: number;
    assignedToFirstName?: string;
    assignedToLastName?: string;
    dueDate?: Date;
    priorityLevel?: number;
    isFollowed?: boolean;
}