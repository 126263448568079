import { InvoiceSearchResultLineItem } from "./invoiceSearchResultLineItem";

export interface InvoiceSearchResult {
    id: number;
    totalAmountDue: number;
    originalAmount: number;
    totalAmountReceived: number;
    comments: string;
    invoiceNumber: number;
    amountDue: number;
    invoiceLineItems: InvoiceSearchResultLineItem[];
}