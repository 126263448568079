
import { ODataFilterCollection } from "../../api/odata";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { OdataFiltersModalOptions } from "./OdataFiltersModalOptions";
import { OdataFiltersModalResult } from "./OdataFiltersModalResult";

export class OdataFiltersModalController {

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options
    ];

    constructor(
        private readonly $uibModalInstance: Modal<OdataFiltersModalResult>,
        private readonly options: OdataFiltersModalOptions) {
    }

    public filterCollection: ODataFilterCollection;

    public cancel = () => {
        this.$uibModalInstance.dismiss('cancel');
    }

    public submit = () =>  {
        this.$uibModalInstance.close({filterCollection: this.filterCollection});
    } 

    public $onInit() { 
        this.filterCollection = this.options.filterCollection;
    }
}

app.controller('OdataFiltersModalController', OdataFiltersModalController);
