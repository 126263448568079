import app from "../../main";

export const navigateObjectFilter = () => {

    return (obj: object, str: string) => {

        if (!str || !obj) {
            return;
        }

        str = str
            .replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
            .replace(/^\./, '');  // strip a leading dot
        
        const a = str.split('.');

        for (let i = 0; i < a.length; i++) {
            const k = a[i];

            if (!obj) {
                return;
            }

            if (k in obj) {
                obj = obj[k];
            } else {
                return;
            }
        }

        return obj;
    }
}

app.filter('navigateObject', navigateObjectFilter);