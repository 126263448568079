import { BondTypeService } from "../../../api/bondTypeService";
import { Injectables } from "../../../configuration/injectables";
import app from "../../../main";
import { BondTypesItem } from "./bondTypesItem";
import { IDebounceDelayer } from "../../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../../utilities/tables/table";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { BondTypeReportDropdownFilterOptions } from "./bondTypesReportFilterDropdown/bondTypesReportDropdownFilterOptions";
import { ModalOpener } from "../../../modals/modalOpener";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";

export class BondTypesReportController {

    public static $inject = [
        Injectables.BondTypeService,
        Injectables.ModalOpener,
        Injectables.IDebouceDelayer,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly bondTypeService: BondTypeService,
        private readonly modalOpener: ModalOpener,
        debouceDelayer: IDebounceDelayer,
        private readonly toastMessageCreator: ToastMessageCreator 
    ) {
        this.table = new Table(debouceDelayer);
        this.table.onChange = this.loadData;
        this.modifiedFilters = {} as BondTypeReportDropdownFilterOptions;
    }

    public modifiedFilters: BondTypeReportDropdownFilterOptions;
    public readonly table: Table<BondTypesItem, BondTypeReportDropdownFilterOptions>;
    public busyIndicator: BusyIndicator;
    public tableQueryOptions: BondTypeReportDropdownFilterOptions;
    public isOpen: boolean;
    public filterCount = 0;

    public requestNewBondType(): void {
        this.modalOpener.showBondTypeRequestModal()
            .result
            .then(function(){})
            .catch(function(){});
    }

    public promptToDelete(bondType: BondTypesItem) {
        this.modalOpener.confirmModal(
                'Delete Bond Type',
                'Are you sure you want to delete the user Bond Type named ' + bondType.name + '?',
                'Delete',
                'Cancel')
            .result
            .then(() => {
                this.busyIndicator.message = "Deleting...";
                this.busyIndicator.promise = this.bondTypeService.deleteBondType(bondType.id)
                    .then(() => {
                        this.loadData();
                        this.toastMessageCreator.createSuccessMessage('Bond Type was deleted successfully');
                    })
                    .catch(() => {
                        this.toastMessageCreator.createErrorMessage('An Error occurred deleting the bond type');
                    })
            })
            .catch(() => {});
    }

    public exportExcelReport(){
        this.busyIndicator = {
            message: "Loading...",
            promise: this.bondTypeService.downloadBondTypesExcelReport(this.tableQueryOptions)
        };
    }

    public loadData = () => {
        this.busyIndicator.message = "Loading Bond Types...";
        this.busyIndicator.promise = this.bondTypeService
            .getBondTypeEntriesReportData(this.table.queryOptions)
            .then((response) => {
                this.table.setData(response.items, response.totalRecordCount);
            }); 
    }
    
    public $onInit(): void {
        this.busyIndicator = {};
        this.loadData();
    }
}