import { SystemAccount } from "./model/systemAccount";

export interface Attachment {
    file: File;
    fileId: number;
    comments: string;
    filename: string;
    fileSize: number;
    applicationId: number;
    customerId: number;
    eProducerAccountId: number;
    bondId: number;
    visibleToAllCarriers: boolean;
    visibleToAgent: boolean;
    visibleInEProducer: boolean;
    visibleToSystemAccountId: number;
    visibleToSystemAccount: SystemAccount;
    uploadedThroughDocumentState: boolean;
    uploadedByUserId: number;

    id: number;
    createdDateTime: Date;

}