import { BondType } from "./bondType";
import { IBaseEntity } from "./iBaseEntity";
import { SfaaCode } from "./sfaaCode";
import { SystemAccount } from "./systemAccount";
import { User } from "./user";

export interface BondTypeRequest extends IBaseEntity { 
    status: BondTypeRequestStatus;
    systemAccountId: number;
    systemAccount: SystemAccount;
    bondFormFileId: number;
    bondFormFile: File;
    sfaaCodeId: number;
    sfaaCode: SfaaCode;
    bondTypeId: number;
    bondType: BondType;
    userId: number;
    user: User;
    name: string;
    bondAmount: number;
    obligeeName: string;
    obligeePhone: string;
    obligeeContact1: string;
    obligeeContact2: string;
    obligeeContact3: string;
    obligeeEmail: string;
    obligeeFax: string;
    obligeeMailAddress: string;
    obligeeMailCity: string;
    obligeeMailCounty: string;
    obligeeMailState: string;
    obligeeMailSuiteAptNumber: string;
    obligeeMailZip: string;
    obligeePhysicalAddress: string;
    obligeePhysicalCity: string;
    obligeePhysicalCounty: string;
    obligeePhysicalState: string;
    obligeePhysicalSuiteAptNumber: string;
    obligeePhysicalZip: string;
    obligeeRequisitioningDepartment: string;
    obligeeRequisitioningState: string;
    obligeeWebsiteURL: string;
    id: number;
    createdDateTime: Date;
}

export enum BondTypeRequestStatus {	
    Requested = "Requested",
    Completed = "Completed"
}