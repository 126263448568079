export default (
    element: JQuery, 
    labelText: number, 
    isRequired: boolean
) => {
        
    // if ng-required is used then add ng-class to toggle the * accordingly in the label
    const ngRequiredHandler = element.attr('ng-required') ? `ng-show="${element.attr('ng-required')}"` : '';

    const requiredIndicator = (isRequired || ngRequiredHandler) ? ' <span class="validation-indicator" ' + ngRequiredHandler + '>*</span>' : '';
    const id = element.prop('id');

    const labelClass = element.attr('aa-label-class') ? element.attr('aa-label-class') : '';

    element
        .parent()
        .parent()
        .addClass("inline-label-strategy");
    
    element
        .parent()
        .parent()
        .prepend('<label class="' + labelClass + '" for="' + id + '">' + labelText + '</label>' + requiredIndicator);
};