import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../configuration/injectables";
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";
import { RiderRequestService } from "../../../api/riderRequestService";
import { RiderReportListItem } from "./riderReportItem";
import { RiderReportTableQueryOptions } from "./riderReportTableQueryOptions";
import { Table } from "../../../utilities/tables/table";
import { IDebounceDelayer } from "../../../utilities/debounceDelayer/iDebouceDelayer";
    
export class riderReportController {

    public static $inject = [
        Injectables.CurrentUserResolver,
        Injectables.RiderRequestService,
        Injectables.IDebouceDelayer
    ];

    constructor(
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly riderRequestService: RiderRequestService,
        debouceDelayer: IDebounceDelayer,
    ){
        this.table = new Table(debouceDelayer);
        this.table.queryOptions.orderBy = "BondTransactions.CreatedDateTime DESC" ;
        this.table.onChange = this.loadData;
    }

    public readonly table: Table<RiderReportListItem,RiderReportTableQueryOptions> ;
    public isCarrier: boolean;
    public busyIndicator: BusyIndicator;
  
    public refreshReport = () => {
        this.loadData();
    }
   
     public exportExcelReport = () => {
        this.busyIndicator = {
            message: "Preparing Report...",
            promise: this.riderRequestService.getRiderReportExcelReport(
                this.table.queryOptions
            )
        };
    }

    public loadData = () => {
        this.busyIndicator.promise = this.riderRequestService
            .getRiderReportItems(this.table.queryOptions)
            .then((response) => { 
                this.table.setData(response.items, response.totalRecordCount)
            });
    }

    public $onInit = () => {
        this.isCarrier = this.currentUserResolver.getCurrentUser().systemAccount.isCarrier;
        this.busyIndicator = {};
        
        this.loadData();
    }
}