import { Application } from "./application";
import { ApplicationFollowUpStrategy } from "./applicationFollowUpStrategy";
import { EmailTemplate } from "./emailTemplate";
import { IBaseEntity } from "./iBaseEntity";
import { Lead } from "./lead";
import { SystemAccount } from "./systemAccount";

export interface ApplicationFollowUp extends IBaseEntity { 
    applicationFollowUpType: ApplicationFollowUpType;
    sent: boolean;
    unsubscribed: boolean;
    htmlBody: string;
    subject: string;
    clickCount: number;
    opened: boolean;
    bounced: boolean;
    device: string;
    sendDateTime: Date;
    emailTemplateId: number;
    applicationFollowUpStrategyId: number;
    emailTemplate: EmailTemplate;
    applicationFollowUpStrategy: ApplicationFollowUpStrategy;
    applicationId: number;
    application: Application;
    leadId: number;
    lead: Lead;
    emailAddress: string;
    replyToEmailAddress: string;
    senderName: string;
    systemAccount: SystemAccount;
    systemAccountId: number;
    uniqueId: string;
    id: number;
    createdDateTime: Date;
}

export enum ApplicationFollowUpType {	
    Submission = "Submission",
    FollowUp = "FollowUp",
    Purchase = "Purchase",
    ProspectFollowUp = "ProspectFollowUp",
    ProspectCreation = "ProspectCreation",
    BrokerInvite = "BrokerInvite"
}