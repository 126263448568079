import { CustomerRelationshipType } from "./customerRelationshipType";

export interface CustomerRelationshipChildCustomer {
    id: number;
    customerId: number;
    customerName: string;
    lookupCode: string;
    comments: string;
    isRemoved?: boolean;
    relationshipType: CustomerRelationshipType;
}