import { EntityChangedEvent } from "../../api/types/entityChangedEvent";
import { Obligee } from "../../api/types/model/obligee";
import app from "../../main";
import { BusyIndicator } from "../busyIndicator/busyIndicator";

class ObligeeFormController {
    public static $inject = [];

    constructor() { }

    public obligee: Obligee;
    public busyIndicator: BusyIndicator;
    public entryMode: 'application' | 'master';
    public isDisabled: boolean = false;

    public get isMasterEntryMode(): boolean {
        return this.entryMode === 'master';
    }

    public get isGenericObligee(): boolean {
        return this.obligee && (this.obligee.isGeneric || this.obligee.masterObligeeId !== null);
    }

    // TODO: Refactor changed callback so that consumers can bind to a single event property.
    public changed: (event: EntityChangedEvent<Obligee>) => void;
    public onSave: (params: { obligee: Obligee }) => void;
    public onCancel: () => void;

    public $onInit(): void {
    }

    public save(): void {
        if (this.onSave !== undefined) {
            this.onSave({ obligee: this.obligee });
        }
    }

    public cancel(): void {
        if (this.onCancel !== undefined) {
            this.onCancel();
        }
    }

    public addressChange(object: any, changedProperty: string): void {
        this.obligeeChange(changedProperty);
    }

    public obligeeChange(property: string): void {
        if (this.changed !== undefined) {
            this.changed({ entity: this.obligee, changedProperty: property });
        }
    }
}

const obligeeFormComponent = {
    bindings: {
        onSave: '&?',
        onCancel: '&?',
        entryMode: '@?',
        changed: '&?',
        isDisabled: '<?',
        obligee: '=?',
        busyIndicator: '=?'
    },
    controller: ObligeeFormController,
    controllerAs: 'vm',
    templateUrl: 'app/components/obligeeForm/obligeeForm.html'
};

app.component('obligeeForm', obligeeFormComponent);
