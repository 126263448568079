import { Fee } from "./model/fee";
import { QuoteType, QuoteStatus, IndemnityRequirements } from "./model/quote";
import { RateType } from "./model/rate";

export interface DefaultQuote {
    quoteType?: QuoteType;
    rateType: RateType;
    status: QuoteStatus;
    term: number;
    indemnityRequirements: IndemnityRequirements;
    carrierSystemAccountId?: number;
    applicationId: number;
    fees?: Fee[];
    fixedRate?: number;
    writingCompanyId?: number;
    bondNumberGroupId?: number;
    carrierAppointmentId?: number;
}