
export interface AssignPrincipalBond {
    bondNumber: string;
    id: number;
    allowPrincipalAssignment: boolean;
    bondAmount: number;
    bondType: string;
    expirationDate: Date;
    effectiveDate: Date;
    nameOnBond: string;
}
