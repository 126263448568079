import { IHttpService, IQService, IPromise } from "angular";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/settings/systemSettings";
import app from "../main";
import { NewlyActiveBondTypeWidgetData } from "../states/common/dashboard/widgets/newlyActiveBondTypes/newlyActivatedBondTypeWidgetData";
import { NewlyActiveBondTypeWidgetDataItem } from "../states/common/dashboard/widgets/newlyActiveBondTypes/newlyActiveBondTypeWidgetDataItem";
import {
    ODataFactory,
    ODataFilterCollection,
    ODataEndpoint,
    ODataTimeFrameFilterType
} from "./odata";
import A3ApiResponse from "./types/a3ApiResponse";
import { BondTypeFilterType } from "./types/bondTypeFilterType";
import { BondTypeStatusDescription } from "./types/bondTypeStatusDescription";
import { BondTypeTransactionType } from "./types/bondTypeTransactionType";
import { GetBondTypeLeaderBoardDto } from "./types/getBondTypeLeaderBoard";
import { GetBondTypeProgressDto } from "./types/getBondTypeProgressDto";
import { AuditLog } from "./types/model/auditLog";
import { BondType } from "./types/model/bondType";
import { BondTypesItem } from "../states/common/bondTypes/bondTypesItem";
import { BondTypeReportDropdownFilterOptions } from "../states/common/bondTypes/bondTypesReportFilterDropdown/bondTypesReportDropdownFilterOptions";
import { OpposingQueryDefinition } from "./types/opposingQueryDefinition";
import { QueryOptions } from "./types/queryOptions";
import { StateActivityCount } from "./types/stateActivityCount";
import { SystemAccountFavoriteDto } from "./types/systemAccountFavoriteDto";
import { UnderwritingQuestions } from "./types/underwritingQuestions";
import { TableQueryOptions } from "./types/tableQuery";
import { FileDownloader } from "./fileDownloader";
import { BondTypeSelectionItem } from "../components/bondTypeSelectionForm/bondTypeSelectionItem"
import { PageResponse } from "./types/pageResponse";
import BondTypesTableQueryOptions from "../components/bondTypeSelectionForm/bondTypeQueryOptions";
import { BondTypeForApplication } from "./types/bondTypeForApplication";
import { BondTypeDetail } from "../states/common/bondTypeDetail/bondTypeDetailController";
import { BondTypeHistoryTableQueryOptions } from "../modals/bondTypeHistoryModal/bondTypeHistoryTableQueryOptions";
import { BondTypeHistoryListItem } from "../modals/bondTypeHistoryModal/bondTypeHistoryListItem";

export class BondTypeService {
    public static $inject = [
        Injectables.$http,
        Injectables.ODataFactory,
        Injectables.$q,
        Injectables.SystemSettings,
        Injectables.FileDownloader
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly odata: ODataFactory,
        readonly $q: IQService,
        private readonly systemSettings: SystemSettings,
        private readonly fileDownloader: FileDownloader

    ) {}

    public getBondTypeHistoryListItems(tableQueryOptions: BondTypeHistoryTableQueryOptions) {
        if (!tableQueryOptions.pageNumber) {
            tableQueryOptions.pageNumber = 1;
    }

    if (!tableQueryOptions.recordsPerPage) {
        tableQueryOptions.recordsPerPage = 10;
    }

    if (!tableQueryOptions.searchPhrase) {
        tableQueryOptions.searchPhrase = '';
    } 

    if (!tableQueryOptions.orderBy) {
        tableQueryOptions.orderBy = 'CreatedDateTime';
    }

    let queryString = `bondTypeId=${tableQueryOptions.bondTypeId}`;
    queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
    queryString += `&pageNumber=${tableQueryOptions.pageNumber}`;       
    queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;
    queryString += `&orderBy=${tableQueryOptions.orderBy}`;

    return this.$http
        .get<A3ApiResponse<PageResponse<BondTypeHistoryListItem>>>(`${this.systemSettings.apiBaseUrl}BondTypeActions/GetBondTypeHistoryListItems?${queryString}`)
        .then((response) => response.data.value);         
    }

    public getNewlyActiveBondTypeWidgetData(
        orderby: string,
        currentPage: number,
        recordsPerPage: number,
        filterCollection: ODataFilterCollection
    ): IPromise<NewlyActiveBondTypeWidgetData> {
        const query = this.odata.getQuery();

        query
            .orderby(orderby)
            .skip((currentPage - 1) * recordsPerPage)
            .top(recordsPerPage)
            .expand("")
            .filter(
                "typeFullName eq 'A3.Model.BondType' and logDetails/any(a:a/newValue eq 'True' and a/propertyName eq 'IsActive')"
            );

        const filterExpr = filterCollection.getFilterExpression();
        if (filterExpr) {
            query.and(filterCollection.getFilterExpression());
        }

        const odataService = this.odata.getService(ODataEndpoint.AuditLog);

        return odataService
            .get<A3ApiResponse<AuditLog[]>>(query)
            .then((response) => {
                const widgetData: NewlyActiveBondTypeWidgetData = {
                    records: response.data
                        .value as NewlyActiveBondTypeWidgetDataItem[],
                    totalRecords: response.data["@odata.count"]
                };

                if (!widgetData.records.length) {
                    return widgetData;
                }

                let bondTypeIdFilter = "";
                for (let i = 0; i < widgetData.records.length; i++) {
                    bondTypeIdFilter +=
                        "id eq " + widgetData.records[i].recordId;

                    if (i + 1 < widgetData.records.length) {
                        bondTypeIdFilter += " or ";
                    }
                }

                const bondTypeSvc = this.odata.getService(
                    ODataEndpoint.BondType
                );
                const bondTypeQuery = this.odata.getQuery();
                bondTypeQuery.filter(bondTypeIdFilter);
                bondTypeQuery.select("id,name");

                return bondTypeSvc
                    .get<A3ApiResponse<BondType[]>>(bondTypeQuery)
                    .then((response) => {
                        for (let j = 0; j < response.data.value.length; j++) {
                            for (
                                let k = 0;
                                k < widgetData.records.length;
                                k++
                            ) {
                                if (
                                    response.data.value[j].id ===
                                    widgetData.records[k].recordId
                                ) {
                                    widgetData.records[k].bondType =
                                        response.data.value[j];
                                }
                            }
                        }

                        return widgetData;
                    });
            });
    }

    public getBondTypeDetail(id: number) {
        const url = `${this.systemSettings.apiBaseUrl}BondTypeActions/GetBondTypeDetail?id=${id}`;

        return this.$http.get<A3ApiResponse<BondTypeDetail>>(url)
            .then((response) => {
                return response.data.value;
            });
    }

    public saveBondType(bondType: BondTypeDetail): IPromise<void> {
        const url = `${this.systemSettings.apiBaseUrl}BondTypeActions/SaveBondType`;

        return this.$http.post(url, bondType)
            .then(() => {});
    }

    public currentCount(type: BondTypeStatusDescription): IPromise<number> {
        const svc = this.odata.getService<BondType>(ODataEndpoint.BondType);

        switch (type) {
            case BondTypeStatusDescription.Active:
                svc.query.filter("isActive eq true");
                break;
            case BondTypeStatusDescription.Inactive:
                svc.query.filter("isActive eq false");
                break;
            case BondTypeStatusDescription.NeedingReview:
                svc.query.filter("flaggedForReview eq true");
                break;
            case BondTypeStatusDescription.InactiveNoReviewNeeded:
                svc.query.filter(
                    "flaggedForReview eq false and isActive eq false"
                );
                break;
        }
        return svc.count().then((response) => response.data);
    }

    public dropdownSearch(search: string): IPromise<BondType[]> {
        const svc = this.odata.getService<BondType>(ODataEndpoint.BondType);

        svc.query.filter(`isActive eq true and (contains(name,'${search}'))`);
        svc.query.orderby("name");
        svc.query.top(25);
        svc.query.select("name,id");

        return svc.get().then((response) => response.data.value);
    }

    public getBondTypeQuestions(
        bondTypeId: number
    ): IPromise<UnderwritingQuestions[]> {
        return this.$http
            .get<A3ApiResponse<UnderwritingQuestions[]>>(
                this.systemSettings.apiBaseUrl +
                    "BondTypeActions/GetBondTypeQuestions?bondTypeId=" +
                    bondTypeId
            )
            .then((response) => response.data.value);
    }

    public filterQueryByTransactionType(
        type: BondTypeTransactionType
    ): OpposingQueryDefinition {
        const queryDefinitions: OpposingQueryDefinition = {
            query: "",
            opposite: ""
        };

        if (type === BondTypeTransactionType.New) {
            queryDefinitions.query =
                "typeFullName eq 'A3.Model.BondType' and eventType eq Repository.Pattern.Infrastructure.ObjectState'Added'";
            queryDefinitions.opposite =
                "typeFullName eq 'A3.Model.BondType' and eventType eq Repository.Pattern.Infrastructure.ObjectState'Deleted'";
        } else if (type === BondTypeTransactionType.MadeActive) {
            queryDefinitions.query =
                "typeFullName eq 'A3.Model.BondType' and eventType eq Repository.Pattern.Infrastructure.ObjectState'Modified' and logDetails/any(d: d/propertyName eq 'IsActive' and d/originalValue eq 'False' and d/newValue eq 'True')";
            queryDefinitions.opposite =
                "typeFullName eq 'A3.Model.BondType' and eventType eq Repository.Pattern.Infrastructure.ObjectState'Modified' and logDetails/any(d: d/propertyName eq 'IsActive' and d/originalValue eq 'True' and d/newValue eq 'False')";
        } else if (type === BondTypeTransactionType.Updated) {
            queryDefinitions.query =
                "typeFullName eq 'A3.Model.BondType' and eventType eq Repository.Pattern.Infrastructure.ObjectState'Modified'";
            queryDefinitions.opposite = "id eq -1"; // so query returns zero kin
        }
        return queryDefinitions;
    }

    public getBondTypeForApplication(bondTypeId: number)
    : IPromise<BondTypeForApplication> {
        const url = `${this.systemSettings.apiBaseUrl}BondTypeActions/GetBondTypeForApplication?bondTypeId=${bondTypeId}`;

        return this.$http
            .get<A3ApiResponse<BondTypeForApplication>>(url)
            .then((response)=> response.data.value);

    }

    public getNewBondTypeMatchesQuery(queryOptions: BondTypesTableQueryOptions)
    : IPromise<PageResponse<BondTypeSelectionItem>> {
        const queryString = this.buildBondTypeMatchQueryString(queryOptions);
        const url = `${this.systemSettings.apiBaseUrl}BondTypeActions/GetBondTypeMatches${queryString}`;

        return this.$http
            .get<A3ApiResponse<PageResponse<BondTypeSelectionItem>>>(url)
            .then((response)=> response.data.value);
    }

    private buildBondTypeMatchQueryString(tableQueryOptions: BondTypesTableQueryOptions): string {
        if (!tableQueryOptions) {
            tableQueryOptions = {
                pageNumber: 1,
                recordsPerPage: 10,
                orderBy: "",
                searchPhrase: ""
            } as BondTypesTableQueryOptions;
        }

        if (!tableQueryOptions.searchPhrase) {
            tableQueryOptions.searchPhrase = "";
        }

        if (!tableQueryOptions.orderBy) {
            tableQueryOptions.orderBy = "BondTypes.Name Desc";
        }

        if (!tableQueryOptions.pageNumber) {
            tableQueryOptions.pageNumber = 1;
        }

        if (!tableQueryOptions.recordsPerPage) {
            tableQueryOptions.recordsPerPage = 10;
        }

        if (!tableQueryOptions.recordsPerPage) {
            tableQueryOptions.isActiveOnly = false;
        }

        let queryString = "?";

        queryString += `&pageNumber=${tableQueryOptions.pageNumber}`;
        queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
        queryString += `&orderBy=${tableQueryOptions.orderBy}`;
        queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;
        queryString += `&isActiveOnly=${tableQueryOptions.isActiveOnly}`;

        if (tableQueryOptions.id){
            queryString += `&id=${tableQueryOptions.id}`;
        }
        else{
            if(tableQueryOptions.requisitioningState){
                queryString += `&requisitioningState=${tableQueryOptions.requisitioningState}`;
            }
            if(tableQueryOptions.sfaaCodeId)
            {
                queryString += `&sfaaCodeId=${tableQueryOptions.sfaaCodeId}`;
            }
        }

        if (tableQueryOptions.favorites){
            queryString += `&favorites=true`;
        }

        return queryString;
    }

    public buildBondTypeReportQueryString(tableQueryOptions: BondTypeReportDropdownFilterOptions ){
        if (!tableQueryOptions) {
            tableQueryOptions = {
                pageNumber: 1,
                recordsPerPage: 10,
                orderBy: "",
                searchPhrase: ""
            };
        }

        if (!tableQueryOptions.searchPhrase) {
            tableQueryOptions.searchPhrase = "";
        }

        if (!tableQueryOptions.orderBy) {
            tableQueryOptions.orderBy = "BondTypes.CreatedDateTime DESC";
        }

        if (!tableQueryOptions.pageNumber) {
            tableQueryOptions.pageNumber = 1;
        }

        if (!tableQueryOptions.recordsPerPage) {
            tableQueryOptions.recordsPerPage = 10;
        }

        let queryString = "?";

        queryString += `&pageNumber=${tableQueryOptions.pageNumber}`;
        queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
        queryString += `&orderBy=${tableQueryOptions.orderBy}`;
        queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;

       if (tableQueryOptions.requisitioningState) {
            queryString += `&requisitioningState=${tableQueryOptions.requisitioningState}`;
        }

        if (tableQueryOptions.isActive) {
            queryString += `&isActive=${tableQueryOptions.isActive}`;
        }

        if ( tableQueryOptions.startDate )
        {
            queryString +=  `&startDate=${tableQueryOptions.startDate}`;
        }

        if (tableQueryOptions.endDate) {
            queryString += `&endDate=${tableQueryOptions.endDate}`;
        }

        if (tableQueryOptions.sfaaCodeId) {
            queryString += `&sfaaCodeId=${tableQueryOptions.sfaaCodeId }`;
        }

        return queryString;
    }
    
    public getBondTypeEntriesReportData (tableQueryOptions: BondTypeReportDropdownFilterOptions ) {
        const queryString = this.buildBondTypeReportQueryString(tableQueryOptions);
        const url = `${this.systemSettings.apiBaseUrl}BondTypeActions/GetBondTypesReport${queryString}`;

        return this.$http.get<A3ApiResponse<PageResponse<BondTypesItem>>>(url)
            .then((response) => response.data.value);
    }

    public downloadBondTypesExcelReport( tableQueryOptions: BondTypeReportDropdownFilterOptions ) {
        const queryString = this.buildBondTypeReportQueryString(tableQueryOptions);
        const url = `${this.systemSettings.apiBaseUrl}BondTypeActions/GetBondTypesExcelReport${queryString}`;

        return this.fileDownloader.downloadFile(url,null);
    }

    public deleteBondType(BondTypeId: number): IPromise<void> {
        return this.$http.post(this.systemSettings.apiBaseUrl + 'BondTypeActions/Delete?bondTypeId=' + BondTypeId, null)
            .then(() => {});
    }

    public getByIdWithObligee(id: number): IPromise<BondType> {
        // This is used by the <bond-type directive which is in turn on the <bond-type-info-modal directive
        // which is used on the applicationDetail and bondDetail in links that are currently hidden and not used nor working if unhidden.
        const svc = this.odata.getService<BondType>(ODataEndpoint.BondType);

        svc.query.filter(`id eq ${id}`);
        svc.query.expand("obligee,bondTypeVariableBondAmounts,sfaaCode");

        return svc.get().then((response) => response.data.value[0]);
    }

    public getCount(
        transactionType: BondTypeTransactionType,
        aggregateType: ODataTimeFrameFilterType
    ): IPromise<number> {
        const svc = this.odata.getService<AuditLog>(ODataEndpoint.AuditLog);
        const oppositeSvc = this.odata.getService<AuditLog>(
            ODataEndpoint.AuditLog
        );

        svc.query.filterByTimeFrame(aggregateType, "createdDateTime");
        oppositeSvc.query.filterByTimeFrame(aggregateType, "createdDateTime");

        if (transactionType !== BondTypeTransactionType.All) {
            // TODO: Refactor getCount method so it checks for a null transactionType when it cant' be matched.  This will have to be when the calling controllers are refactored into TS.  Right now, JS passes back 'All' and it's not type checked at run time.
            const queryDefinition =
                this.filterQueryByTransactionType(transactionType);

            svc.query.and(queryDefinition.query);
            oppositeSvc.query.and(queryDefinition.opposite);
        }

        return this.$q
            .all([svc.count(), oppositeSvc.count()])
            .then((responses) => responses[0].data - responses[1].data);
    }

    public getCountsByState(
        type: BondTypeFilterType
    ): IPromise<StateActivityCount[]> {
        return this.$http
            .get<A3ApiResponse<StateActivityCount[]>>(
                this.systemSettings.apiBaseUrl +
                    "Analytics/GetBondTypesByState?filterType=" +
                    type
            )
            .then((response) => response.data.value);
    }

    public getFavorites(): IPromise<number[]> {
        return this.$http
            .get<A3ApiResponse<number[]>>(
                this.systemSettings.apiBaseUrl +
                    "SystemAccountFavorites/GetBondTypeFavoriteIds"
            )
            .then((response) => response.data.value);
    }

    public getLeaderboardData(
        options: QueryOptions
    ): IPromise<GetBondTypeLeaderBoardDto> {
        const url =
            this.systemSettings.apiBaseUrl + "Analytics/GetBondTypeLeaderBoard";

        return this.$http
            .post<A3ApiResponse<GetBondTypeLeaderBoardDto>>(url, options)
            .then((response) => response.data.value);
    }

    public getProgressCounts(): IPromise<GetBondTypeProgressDto[]> {
        return this.$http
            .get<A3ApiResponse<GetBondTypeProgressDto[]>>(
                this.systemSettings.apiBaseUrl + "Analytics/GetBondTypeProgress"
            )
            .then((response) => response.data.value);
    }

    public last180ToLast90DaysChangeCount(
        type: BondTypeTransactionType
    ): IPromise<number> {
        return this.getCount(
            type,
            ODataTimeFrameFilterType.Last180ToLast90Days
        );
    }

    public last90DaysChangeCount(
        type: BondTypeTransactionType
    ): IPromise<number> {
        return this.getCount(type, ODataTimeFrameFilterType.Last90Days);
    }

    public lastMonthChangeCount(
        type: BondTypeTransactionType
    ): IPromise<number> {
        return this.getCount(type, ODataTimeFrameFilterType.LastMonth);
    }

    public lastWeekChangeCount(
        type: BondTypeTransactionType
    ): IPromise<number> {
        return this.getCount(type, ODataTimeFrameFilterType.LastWeek);
    }

    public lastYearChangeCount(
        type: BondTypeTransactionType
    ): IPromise<number> {
        return this.getCount(type, ODataTimeFrameFilterType.LastYear);
    }

    public markFavorite(bondTypeId: number): IPromise<void> {
        const favorite: SystemAccountFavoriteDto = { id: bondTypeId };

        return this.$http
            .post(
                this.systemSettings.apiBaseUrl +
                    "SystemAccountFavorites/BondTypeMarkAsFavorite",
                favorite
            )
            .then(() => {});
    }

    public mtdChangeCount(type: BondTypeTransactionType): IPromise<number> {
        return this.getCount(type, ODataTimeFrameFilterType.Mtd);
    }

    public thisWeekChangeCount(
        type: BondTypeTransactionType
    ): IPromise<number> {
        return this.getCount(type, ODataTimeFrameFilterType.ThisWeek);
    }

    public twoMonthsAgoChangeCount(
        type: BondTypeTransactionType
    ): IPromise<number> {
        return this.getCount(type, ODataTimeFrameFilterType.TwoMonthsAgo);
    }

    public unmarkFavorite(bondTypeId: number): IPromise<void> {
        const favorite: SystemAccountFavoriteDto = { id: bondTypeId };

        return this.$http
            .post(
                this.systemSettings.apiBaseUrl +
                    "SystemAccountFavorites/BondTypeUnmarkAsFavorite",
                favorite
            )
            .then(() => {});
    }

    public ytdChangeCount(type: BondTypeTransactionType): IPromise<number> {
        return this.getCount(type, ODataTimeFrameFilterType.Ytd);
    }
}

app.service(Injectables.BondTypeService, BondTypeService);