import { Quote } from "../quote";
import { IBaseEntity } from "./iBaseEntity";
import { Invoice } from "./invoice";
import { PaymentTransaction } from "./paymentTransaction";

export interface InvoiceLineItem extends IBaseEntity { 
    amountDue: number;
    amountReceived: number;
    description: string;
    invoice: Invoice;
    invoiceId: number;
    paymentTransaction: PaymentTransaction;
    paymentTransactionFee: number;
    paymentTransactionId: number;
    quote: Quote;
    quoteId?: number;
    feeId?: number;
    bondTransactionId?: number;
    isRemoved: boolean;
    id: number;
    createdDateTime: Date;
}