import { ODataFactory, ODataQuery } from "../../../api/odata";
import { UnderwritingConfiguration } from "../../../api/types/model/underwritingConfiguration";
import { UnderwritingConfigurationService } from "../../../api/underwritingConfigurationService";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../configuration/injectables";
import { ModalOpener } from "../../../modals/modalOpener";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { State } from "../../state";

export class UnderwritingConfigurationGridController {

    public static $inject = [        
        Injectables.ToastMessageCreator,
        Injectables.ODataFactory,
        Injectables.UnderwritingConfigurationService,
        Injectables.$state,
        Injectables.ModalOpener
    ];
        
    constructor( 
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly odata: ODataFactory,
        private readonly underwritingConfigurationService: UnderwritingConfigurationService,
        private readonly $state: State,
        private readonly modalOpener: ModalOpener
    ) {}

    public underwritingQuery: ODataQuery;
    public busyIndicator: BusyIndicator;

    public copy = (config: UnderwritingConfiguration) => {
        this.modalOpener.confirmModal(
            'Copy Underwriting',
            'Are you sure you want to copy this underwriting configuration?',
            'Copy', 
            'Cancel')
        .result
        .then(() => {
            this.busyIndicator.promise = this.underwritingConfigurationService.copyConfiguration(config.id)
                .then((newConfig: UnderwritingConfiguration) => {
                    this.toastMessageCreator.createSuccessMessage('The configuration has been copied successfully');
                    this.$state.go('main.underwritingConfigurationDetail', { id: newConfig.id });
                });
        })
        .catch(() => {});
    }

    public $onInit = () => {
        this.busyIndicator = {
            message: 'Coping...'
        };
        
        this.underwritingQuery = this.odata.getQuery();
        this.underwritingQuery.expand('bondType,rate,writingCompany,bondNumberGroup');
        this.underwritingQuery.orderby('createdDateTime desc');
    }
}