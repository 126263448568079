import app from "../../main";
import { Table } from "../../utilities/tables/table";
import { CustomerListItem } from "./customerListItem";
import { CustomerTableQueryOptions } from "./customerTableQueryOptions";

class CustomerListController
{
    public static $inject = [];

    constructor() {}

    public table: Table<CustomerListItem, CustomerTableQueryOptions>;

    public $onInit(): void {}
}

const customerListComponent = {
    templateUrl: 'app/components/customerList/customerList.html',
    bindings: {
        table: '<'
    },
    controllerAs: 'vm',
    controller: CustomerListController
};

app.component('customerList', customerListComponent);