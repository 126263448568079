import { ApplicationFollowUpStrategy } from "./applicationFollowUpStrategy";
import { BondType } from "./bondType";
import { IBaseEntity } from "./iBaseEntity";

export interface ApplicationFollowUpStrategiesBondTypes extends IBaseEntity { 
    bondTypeId: number;
    applicationFollowUpStrategyId: number;
    bondType: BondType;
    applicationFollowUpStrategy: ApplicationFollowUpStrategy;
    id: number;
    createdDateTime: Date;
}