import { CreditReportService } from "../../api/creditReportService";
import { USConsumerCreditReport } from "../../api/types/equifax/uSConsumerCreditReport";
import { CreditReport } from "../../api/types/model/creditReport";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { CreditReportOptions } from "./creditReportModalOptions";
import { CreditReportResult } from "./CreditReportResult";
import app from "../../main";
import { IPromise } from "angular";

    
export class CreditReportModalController {
    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.CreditReportService
    ];

    constructor(
        private readonly $uibModalInstance: Modal<CreditReportResult>,
        private readonly options: CreditReportOptions,
        private readonly creditReportService: CreditReportService
    ){}

    public processingPromise: IPromise<any>;
    public creditReport: CreditReport | USConsumerCreditReport;

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }

    public $onInit(): void {
        this.processingPromise = this.creditReportService
            .getById(this.options.creditReportId)
            .then((report) => {
                this.creditReport = report;
        });
    }
}

app.controller('CreditReportModalController', CreditReportModalController);
