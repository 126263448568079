import { IAttributes, ICompileService, IDirectiveFactory, IDirectiveLinkFn, ISCEService, IScope } from "angular";
import { BondType } from "../../api/types/model/bondType";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";

type ConfigurationProgressIndicatorScope = IScope & {
    bondType?: BondType;
}


const configurationProgressIndicatorDirective =  (
    $sce: ISCEService, 
    $compile: ICompileService
) => {

    const link: IDirectiveLinkFn = (
        scope: ConfigurationProgressIndicatorScope,
        element: JQuery,
        attributes: IAttributes
        ) => {

            if (!scope.bondType) { return; }

            let reviewCoverLettersComments = scope.bondType.reviewCoverLettersComments ? scope.bondType.reviewCoverLettersComments: 'Cover letter section is incomplete or requires review.';
            let reviewApplicationFormsComments = scope.bondType.reviewApplicationFormsComments ? scope.bondType.reviewApplicationFormsComments: 'Application form section is incomplete or requires review';
            let reviewObligeeComments = scope.bondType.reviewObligeeComments ? scope.bondType.reviewObligeeComments: 'Obligee section is incomplete or requires review';
            let reviewSfaaCodeComments = scope.bondType.reviewSfaaCodeComments ? scope.bondType.reviewSfaaCodeComments: 'SFAA Code section is incomplete or requires review';
            let reviewCancellationComments = scope.bondType.reviewCancellationComments ? scope.bondType.reviewCancellationComments: 'Cancellation section is incomplete or requires review';
            let reviewRenewalComments = scope.bondType.reviewRenewalComments ? scope.bondType.reviewRenewalComments: 'Renewal section is incomplete or requires review';
            let reviewExpirationComments = scope.bondType.reviewExpirationComments ? scope.bondType.reviewExpirationComments: 'Expiration section is incomplete or requires review';
            let reviewBondAmountComments = scope.bondType.reviewBondAmountComments ? scope.bondType.reviewBondAmountComments: 'Bond Amount section is incomplete or requires review';
            let reviewAppQuestionsComments = scope.bondType.reviewAppQuestionsComments ? scope.bondType.reviewAppQuestionsComments: 'Bond Form section is incomplete or requires review';
            let reviewAdditionalInformationComments = scope.bondType.reviewAdditionalInformationComments ? scope.bondType.reviewAdditionalInformationComments: 'Additional Information section is incomplete or requires review';
            let reviewApplicationSettingsComments = scope.bondType.reviewApplicationSettingsComments ? scope.bondType.reviewApplicationSettingsComments: 'Application Settings section is incomplete or requires review';

            let html = scope.bondType.reviewBondForms === true ? '<i class="fa fa-exclamation-triangle red" uib-tooltip="Either no bond form is set or there are assigned forms that need review"></i> -' : '<i class="fa fa-check green"></i> -';
            html += scope.bondType.reviewCoverLetters === true ? ' <i class="fa fa-exclamation-triangle red" uib-tooltip="' + reviewCoverLettersComments + '"></i> -' : ' <i class="fa fa-check green"></i> -';
            html += scope.bondType.reviewApplicationForms === true ? ' <i class="fa fa-exclamation-triangle red" uib-tooltip="' + reviewApplicationFormsComments + '"></i> -' : ' <i class="fa fa-check green"></i> -';
            html += scope.bondType.reviewObligee === true ? ' <i class="fa fa-exclamation-triangle red" uib-tooltip="' + reviewObligeeComments + '"></i> -' : ' <i class="fa fa-check green"></i> -';
            html += scope.bondType.reviewSfaaCode === true ? ' <i class="fa fa-exclamation-triangle red" uib-tooltip="' + reviewSfaaCodeComments + '"></i> -' : ' <i class="fa fa-check green"></i> -';
            html += scope.bondType.reviewCancellation === true ? ' <i class="fa fa-exclamation-triangle red" uib-tooltip="' + reviewCancellationComments + '"></i> -' : ' <i class="fa fa-check green"></i> -';
            html += scope.bondType.reviewRenewal === true ? ' <i class="fa fa-exclamation-triangle red" uib-tooltip="' + reviewRenewalComments + '"></i> -' : ' <i class="fa fa-check green"></i> -';
            html += scope.bondType.reviewExpiration === true ? ' <i class="fa fa-exclamation-triangle red" uib-tooltip="' + reviewExpirationComments + '"></i> -' : ' <i class="fa fa-check green"></i> -';
            html += scope.bondType.reviewBondAmount === true ? ' <i class="fa fa-exclamation-triangle red" uib-tooltip="' + reviewBondAmountComments + '"></i> -' : ' <i class="fa fa-check green"></i> -';
            html += scope.bondType.reviewAppQuestions === true ? ' <i class="fa fa-exclamation-triangle red" uib-tooltip="' + reviewAppQuestionsComments + '"></i> -' : ' <i class="fa fa-check green"></i> -';
            html += scope.bondType.reviewAdditionalInformation === true ? ' <i class="fa fa-exclamation-triangle red" uib-tooltip="' + reviewAdditionalInformationComments + '"></i> -' : ' <i class="fa fa-check green"></i> -';
            html += scope.bondType.reviewApplicationSettings === true ? ' <i class="fa fa-exclamation-triangle red" uib-tooltip="' + reviewApplicationSettingsComments + '"></i>' : ' <i class="fa fa-check green"></i>';
            
            element.append(html);

            $sce.trustAsHtml(html);
            $compile(element.contents().html())(scope);
        }

    return {
        restrict: 'E',
        scope: { 'bondType': '=' },
        template: '<span></span>',
        link: link
    }

}

configurationProgressIndicatorDirective.$inject = [
    Injectables.$sce, 
    Injectables.$compile
];

app.directive('configurationProgressIndicator', configurationProgressIndicatorDirective as IDirectiveFactory)