import { Injectables } from "../../../../../configuration/injectables";
import { DashboardService } from "../../dashboardService";
import { DashboardWidget } from "../../types/dashboardWidget";
import app from "../../../../../main";
import { IScope } from "angular";
import { PaymentReportItem } from "../../../../agentSpecific/payments/paymentReportItem";
import { PaymentReportFilterOptions } from "../../../../../components/paymentsReportFilterDropdown/paymentReportFilterOptions";
import { Table } from "../../../../../utilities/tables/table";

class DashboardWidgetPaymentsController {

    public static $inject = [
        Injectables.DashboardService
    ];

    constructor(
        private readonly dashboardService: DashboardService
    ){}

    public widget: DashboardWidget;
    
    public saveRecordsPerPage = (recordsPerPage: number) => {
        this.widget.options.recordsPerPage = recordsPerPage;
        this.dashboardService.save();
    }

    public saveOrderby = (orderby: string) => {
        this.widget.options.orderby = orderby;
        this.dashboardService.save();
    }

    public saveFilters = (filters: PaymentReportFilterOptions) => {
        this.widget.options.filters = filters;
        this.dashboardService.save();
    }

    public tableReady = (table: Table<PaymentReportItem, PaymentReportFilterOptions>) => {
        this.dashboardService.refreshFunctions.push(table.refresh);
    }

    public tableChanged = (table: Table<PaymentReportItem, PaymentReportFilterOptions>) => {
        if (this.widget.options.filters !== table.queryOptions) {
            this.saveFilters(table.queryOptions);
        }

        if (this.widget.options.recordsPerPage !== table.pager.recordsPerPage) {
            this.saveRecordsPerPage(table.pager.recordsPerPage);
        }

        if (this.widget.options.orderby !== table.sorter.sortBy) {
            this.saveOrderby(table.sorter.sortBy);
        }
    }

    public $onInit = () => {}
}

const dashboardWidgetPaymentsComponent = {
    templateUrl: 'app/states/common/dashboard/widgets/payments/dashboardWidgetPayments.html',
    bindings: {
        widget: '='
    },
    controllerAs: 'vm',
    controller: DashboardWidgetPaymentsController
}

app.component('dashboardWidgetPayments', dashboardWidgetPaymentsComponent);