import { IPromise } from "angular";
import { ODataFactory, ODataEndpoint } from "../../../api/odata";
import A3ApiResponse from "../../../api/types/a3ApiResponse";
import { RequiredFieldSet } from "../../../api/types/model/requiredFieldSet";
import { Injectables } from "../../../configuration/injectables";
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { State } from "../../state";
import { RequiredFieldSetDetailStateParams } from "./requiredFieldSetDetailStateParams";

export class RequiredFieldSetDetailController {
    public static $inject = [
        Injectables.$stateParams,
        Injectables.ODataFactory,
        Injectables.CurrentUserResolver,
        Injectables.$state,
        Injectables.ToastMessageCreator
    ];

    public constructor(
        private readonly $stateParams: RequiredFieldSetDetailStateParams,
        private readonly odata: ODataFactory,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly $state: State,
        private readonly toastMessageCreator: ToastMessageCreator
    ) { }

    public item: RequiredFieldSet = {} as RequiredFieldSet;
    public processingPromise: IPromise<any>;

    public $onInit(): void {
        const svc = this.odata.getService<RequiredFieldSet>(ODataEndpoint.RequiredFieldSet);

        if (this.$stateParams.id) {
            svc.query.filter('id eq ' + this.$stateParams.id);

            svc
                .get<A3ApiResponse<RequiredFieldSet>>()
                .then((response) => {
                    this.item = response.data.value[0];
                });
        }
    }

    public save(): void {
        const svc = this.odata.getService<RequiredFieldSet>(ODataEndpoint.RequiredFieldSet);

        if (this.$stateParams.id) {
            this.processingPromise = svc.put(this.item.id, this.item);
        } else {
            this.item.systemAccountId = this.currentUserResolver.getCurrentUser().user.systemAccountId;
            this.processingPromise = svc.post(this.item);
        }

        this.processingPromise
            .then(() => {
                this.$state.go('main.requiredFieldSetGrid');
                this.toastMessageCreator.createSuccessMessage('Required field set saved successfully.');
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred while trying to save the required field set.');
            });
    }
}