import { IBaseEntity } from "./iBaseEntity";
import { MasterApplicationQuestion } from "./masterApplicationQuestion";

export interface UnderwritingConfigurationRateQuestion extends IBaseEntity {
    underwritingConfigurationRateId: number;
    masterApplicationQuestionId: number;
    masterApplicationQuestion: MasterApplicationQuestion;
    requiredValue: string;
}

