import * as angular from "angular";
import { IJQueryService } from "../../../utilities/jquery/iJQueryService";
import fieldGroupWrapper from "../fieldGroupWrapper";

export default (element: JQuery, jQueryProvider: IJQueryService) => {
    element.attr('type', 'radio');

    var wrapperClass = element.attr('aa-wrapper-class') || 'field-wrapper';
    var aaFgClass = element.attr('aa-fg-class');
    var ngshow = element.attr('ng-show') || '';

    if (ngshow.length > 0) {
        ngshow = 'ng-show="' + ngshow + '"';
    }

    element.attr('style', 'display:none;');


    var field = angular.element('<p ng-repeat="opt in ' + element.attr('options') + '" class="a-radio ' + aaFgClass + '">' +
        '<input name="TEST" type="radio" ng-model="' + element.attr('aa-field') + '" style="display:none;" />' +
        '<span ng-class="{\'on\': ' + element.attr('aa-field') + ' == opt.value }></span>' +
        '{{opt.label}}' +
        '</p>');
    
    fieldGroupWrapper(field, '<div class="' + wrapperClass + '" ' + ngshow + '><input /></div>', jQueryProvider);
};