import { SelectOption } from "../../api/types/selectOption";
import app from "../../main";

class PremiumDropdownController {

    public static $inject = [];

    constructor(){}

    public bondId: number;

    public premiumOptions: SelectOption<string>[];
    public selectedPremiumOption: string;
    public otherPremium: number;
    public premium: number;
    public isBondAccountPremium: boolean;

    public premiumOptionChanged() {
        if (this.selectedPremiumOption === 'BondAccount') {
            this.premium = null;
            this.isBondAccountPremium = true;
        } else if (this.selectedPremiumOption !== 'other') {
            this.premium = parseFloat(this.selectedPremiumOption);
            this.isBondAccountPremium = false;
        }
    }

    public otherPremiumChanged() {
        if (this.selectedPremiumOption === 'other') {
            this.premium = this.otherPremium;
            this.isBondAccountPremium = false;
        }
    }

    public $onInit() {}
}

const premiumDropdownComponent = {
    bindings: {
        premiumOptions: '<',
        premium: '=',
        isBondAccountPremium: '=',
        aaLabelStrategy: '@?',
        dropdownClass: '@?',
        otherClass: '@?'
    },
    templateUrl: 'app/components/premiumDropdown/premiumDropdown.html',
    controller: PremiumDropdownController,
    controllerAs: 'vm'
};

app.component('premiumDropdown', premiumDropdownComponent);