import { Injectables } from "../../configuration/injectables";
import app from "../../main";

export class PerfectScrollbarService {

    public updatePerfectScrollBar = (element: JQuery) => {
        (element as any).perfectScrollbar('update');
    }
}

app.service(Injectables.PerfectScrollbarService, PerfectScrollbarService);
