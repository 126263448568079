import { AddressIndicator } from "./addressIndicator";
import { AddressSource } from "./addressSource";
import { AddressVariance } from "./addressVariance";
import { City } from "./city";
import { DateAddressFirstReported } from "./dateAddressFirstReported";
import { DateAddressLastReported } from "./dateAddressLastReported";
import { DateTelephoneReported } from "./dateTelephoneReported";
import { RentOwnBuy } from "./rentOwnBuy";
import { StandardizationIndicator } from "./standardizationIndicator";
import { Telephone } from "./telephone";
import { TelephoneSource } from "./telephoneSource";
import { Verification } from "./verification";

export interface USAddress { 
    streetPreDirection: string;
    streetNumber: string;
    streetName: string;
    streetType: string;
    streetPostDirection: string;
    unitDesignator: string;
    unitNumber: string;
    urbanizationCondo: string;
    city: City;
    state: string;
    postalCode: string;
    dateAddressFirstReported: DateAddressFirstReported;
    rentOwnBuy: RentOwnBuy;
    addressSource: AddressSource;
    dateAddressLastReported: DateAddressLastReported;
    telephone: Telephone;
    telephoneSource: TelephoneSource;
    dateTelephoneReported: DateTelephoneReported;
    standardizationIndicator: StandardizationIndicator;
    verification: Verification;
    countryCode: string;
    addressIndicator: AddressIndicator;
    addressVariance: AddressVariance;
    code: string;
    description: string;
}