import { PaymentMethod } from "../../api/types/model/paymentTransaction";
import { PaymentStatus } from "../../api/types/payments/paymentStatus";

export interface PaymentListItem {
    id: number;
    paymentStatus: PaymentStatus;
    message: string;
    createdDateTime: Date;
    paymentMethod: PaymentMethod;
    transactionId: string;
    isNotFullyApplied: boolean;
    appliedAmount: number;
    unappliedAmount: number;
    accountNumber: string;
    comments: string;
    amount: number;
}