import { IDirectiveFactory } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";


const boundGridTableColumn: IDirectiveFactory = () => {

    const controller = ($scope: any) => {
        $scope.editMode = false;
        $scope.cellHovered = false;
        $scope.originalValue = $scope.item[$scope.col.dataitem];

        $scope.cancelInlineEdit = function() {
            $scope.item[$scope.col.dataitem] = $scope.originalValue;
            $scope.editMode = false;
        };

        $scope.submitInlineEdit = function () {
            var updateFunction = $scope.updateItem();
            if (updateFunction) updateFunction($scope.item);
            $scope.editMode = false;
            $scope.originalValue = $scope.item[$scope.col.dataitem];
        };

        $scope.enableInlineEditMode = function () {
            if (!$scope.col.enableInlineEdit) return;
            $scope.editMode = true;
            $scope.inputSelect = true;
        }

        $scope.parseDataItem = function(o,s) {}
    }

    controller.$inject = [Injectables.$scope];

    return {
        restrict: 'E',
        replace: true,
        scope: {
            col: '=column',
            item: '=item',
            updateItem: '&',
            deleteItem: '&',
            keyField: '@',
            labelField: '@',
        },
        templateUrl: 'app/components/boundGrid/boundGrid.tablecolumn.html',
        controller: controller
    };
}

app.directive('boundGridTableColumn', boundGridTableColumn);
