import { BillToType } from "../billToType";
import { CommissionCalculationType } from "./eProducerAccount";

export interface EpicNewBusinessExportRequest {
    bondTransactionId: number;
    commissionPercent: number;
    brokerLookupCode: string;
    epicBillBrokerNet: boolean;
    epicProfitCenterCode: string;
    epicPremiumPayableCode: string;
    epicIssuingCompanyCode: string;
    epicLineOfBusinessCode: string;
    producerUserId: number;
    epicProducerCode: string;
    clientServiceManagerUserId: number;
    epicClientServiceManagerCode: string;
    issuingLocationCode: string;
    billToType: BillToType;
    commissionBreakdowns: CommissionBreakdown[];
    premiumPayableContractId: number;
}

export interface CommissionBreakdown {
    id: number;
    bondTransactionId: number;
    commissionBreakdownType: CommissionBreakdownType;
    commission: number;
    commissionCreditPercent: number;
    producerUserId: number;
    epicProducerCode: string;
    eProducerAccountId: number;
    brokerLookupCode: string;
    commissionCalculationType: CommissionCalculationType;
    epicBrokerPayableContractId: number;
}

export enum CommissionBreakdownType {
    ProducerPay = 'Producer Pay',
    BrokerPay = 'Broker Pay',
    BrokerReceivable = 'BrokerReceivable'
}