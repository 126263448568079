import { SelectOption } from "../../../api/types/selectOption";
import app from "../../../main";
import isUndefinedOrNull from "../../../utilities/angularUtilities/isUndefinedOrNull";
import { Table } from "../../../utilities/tables/table";
import { CarrierOpenApplicationsReportFilterOptions } from "./carrierOpenApplicationsReportFilterOptions";
import { SystemAccountService } from "../../../api/systemAccountService";
import { Injectables } from "../../../configuration/injectables";
import { CarrierOpenApplicationsReportItem } from "./carrierOpenApplicationsReportItem";
import { SfaaCodeService } from "../../../api/sfaaCodeService";
import { IPromise } from "angular";
import { ConstantsState } from "../../../configuration/constants";

class CarrierOpenApplicationsReportFilterDropdownController {

    public static $inject = [
        Injectables.SystemAccountService,
        Injectables.SfaaCodeService,
    ];

    constructor(
        private readonly systemAccountService: SystemAccountService,
        private readonly sfaaCodeService: SfaaCodeService,
    ) {
        this.modifiedFilters = {} as CarrierOpenApplicationsReportFilterOptions;
    }

    public isOpen: boolean;
    public filterCount = 0;
    public agencyOptions: SelectOption<number>[];
    public sfaaCodeOptions: SelectOption<number>[] = [];
    public states: ConstantsState[];
    public physicalState: string;
    public modifiedFilters: CarrierOpenApplicationsReportFilterOptions;

    public table: Table<CarrierOpenApplicationsReportItem, CarrierOpenApplicationsReportFilterOptions>;

    private loadAgencyOptions() {
        return this.systemAccountService.getAppointedAgencyOptions()
            .then((agencyOptions) => {
                this.agencyOptions = agencyOptions;
                this.agencyOptions.unshift({ label: 'Any', value: null});
            });
    }

    public loadSfaaCodes(): IPromise<void> {
        return this.sfaaCodeService
            .getNewAllSfaaCodeOptions()
            .then((options) => {
                this.sfaaCodeOptions = options
            });
    }

    public clearFilters() {
        this.modifiedFilters.agencySystemAccountId = null;
        this.modifiedFilters.status = null;
        this.modifiedFilters.requisitioningState = null;
        this.modifiedFilters.minimumAmount = null;
        this.modifiedFilters.maximumAmount = null;
        this.modifiedFilters.startEffectiveDate = null;
        this.modifiedFilters.endEffectiveDate = null;
        this.modifiedFilters.sfaaCodeId = null;
        this.modifiedFilters.startSubmissionDate = null;
        this.modifiedFilters.endSubmissionDate = null;

        this.table.applyFilters({ ...this.modifiedFilters });

        this.setFilterCount();
        this.isOpen = false;
    }

    public cancel() {
        this.isOpen = false;
        this.modifiedFilters = { ...this.table.queryOptions };
    }

    public applyFilters() {
        this.isOpen = false;
        this.table.applyFilters({ ...this.modifiedFilters });
        this.setFilterCount();
    }

    private setModifiedFilters = () => {
        this.modifiedFilters.agencySystemAccountId = this.table.queryOptions.agencySystemAccountId;
        this.modifiedFilters.status = this.table.queryOptions.status;
        this.modifiedFilters.minimumAmount = this.table.queryOptions.minimumAmount;
        this.modifiedFilters.maximumAmount = this.table.queryOptions.maximumAmount;
        this.modifiedFilters.requisitioningState = this.table.queryOptions.requisitioningState;
        this.modifiedFilters.startEffectiveDate = this.table.queryOptions.startEffectiveDate;
        this.modifiedFilters.endEffectiveDate = this.table.queryOptions.endEffectiveDate;
        this.modifiedFilters.sfaaCodeId = this.table.queryOptions.sfaaCodeId;
        this.modifiedFilters.startSubmissionDate = this.table.queryOptions.startSubmissionDate;
        this.modifiedFilters.endSubmissionDate = this.table.queryOptions.endSubmissionDate;

        this.setFilterCount();
    };

    public setFilterCount() {
        this.filterCount = 0;

        if (!isUndefinedOrNull(this.table.queryOptions.agencySystemAccountId)){
            this.filterCount++;
        }

        if (!isUndefinedOrNull(this.table.queryOptions.status)) {
            this.filterCount++;
        }

        if (
            this.table.queryOptions.minimumAmount ||
            this.table.queryOptions.maximumAmount
        ) {
            this.filterCount++;
        }

        if (!isUndefinedOrNull(this.table.queryOptions.requisitioningState)){
            this.filterCount++;
        }

        if (
            this.table.queryOptions.startEffectiveDate ||
            this.table.queryOptions.endEffectiveDate
        ) {
            this.filterCount++;
        }

        if (!isUndefinedOrNull(this.table.queryOptions.sfaaCodeId)){
            this.filterCount++;
        }

        if (
            this.table.queryOptions.startSubmissionDate ||
            this.table.queryOptions.endSubmissionDate
        ) {
            this.filterCount++;
        }
    }

    public $onInit() {
        this.loadAgencyOptions() ;
        this.loadSfaaCodes() ;
        this.setModifiedFilters();
    }
}

const carrierOpenApplicationsReportFilterDropdownComponent = {
    bindings: {
        table: "<"
    },
    controller: CarrierOpenApplicationsReportFilterDropdownController,
    controllerAs: "vm",
    templateUrl:
        "app/states/carrierSpecific/applications/carrierOpenApplicationsReportFilterDropdown.html"
};

app.component(
    "carrierOpenApplicationsReportFilterDropdown",
    carrierOpenApplicationsReportFilterDropdownComponent
);