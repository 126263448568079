import { ODataFactory, ODataFilterCollection, ODataEndpoint, ODataFilterDataType } from "../../../../../api/odata";
import { SfaaCodeService } from "../../../../../api/sfaaCodeService";
import { Table } from "../../../../../utilities/tables/table";
import { TagService } from "../../../../../api/tagService";
import isDefined from "../../../../../utilities/angularUtilities/isDefined";
import { BondType } from "../../../../../api/types/model/bondType";
import { SelectOption } from "../../../../../api/types/selectOption";
import { BusyIndicator } from "../../../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../../../configuration/injectables";
import { DashboardService } from "../../dashboardService";
import { DashboardWidget } from "../../types/dashboardWidget";
import app from "../../../../../main";
import A3ApiResponse from "../../../../../api/types/a3ApiResponse";
import { ITimeoutService } from "angular";
import { IDebounceDelayer } from "../../../../../utilities/debounceDelayer/iDebouceDelayer";

class DashboardWidgetBondTypeConfigurationListController {

    public static $inject = [
        Injectables.ODataFactory,
        Injectables.DashboardService,
        Injectables.$timeout,
        Injectables.TagService,
        Injectables.SfaaCodeService,
        Injectables.IDebouceDelayer
    ];

    constructor(
        private readonly odata: ODataFactory,
        private readonly dashboardService: DashboardService,
        private readonly $timeout: ITimeoutService,
        private readonly tagService: TagService,
        private readonly sfaaCodeService: SfaaCodeService,
        debounceDelayer: IDebounceDelayer
    ) {
        this.table = new Table(debounceDelayer);
    }

    public widget: DashboardWidget;
    public busyIndicator: BusyIndicator;
    public table: Table<BondType, null>;
    public filterCollection: ODataFilterCollection;
    public errorMessage: string;
    public sfaaCodeOptions: SelectOption<string>[];
    public tagOptions: SelectOption<string>[];

    public setFilters = () => {
        this.filterCollection = this.odata.getFilterCollection([
            this.odata.getFilter('name', ODataFilterDataType.string, 'Bond Type'),
            this.odata.getFilter('obligee/requisitioningState', ODataFilterDataType.state, 'Requisitioning State'),
            this.odata.getFilter('flaggedForReview', ODataFilterDataType.bool, 'Review Status', null, null, true, [{ label: 'Flagged for review', value: "true" }, { label: 'Not flagged for review', value: "false" }], false),
            this.odata.getFilter('isActive', ODataFilterDataType.bool, 'Active', null, null, true, [{ label: 'Active', value: "true" }, { label: 'Inactive', value: "false" }], false),
            this.odata.getFilter('bondAmount', ODataFilterDataType.money, 'Bond Amount'),
            this.odata.getFilter('createdDateTime', ODataFilterDataType.date, 'Submitted'),
            this.odata.getFilter('sfaaCode/code', ODataFilterDataType.select, 'SFAA Code', null, null, true, this.sfaaCodeOptions),
            this.odata.getFilter('reviewBondForms', ODataFilterDataType.bool, 'Form Status', null, null, true, [{ label: 'Completed', value: "false" }, { label: 'Requires Review', value: "true" }], false),
            this.odata.getFilter('reviewObligee', ODataFilterDataType.bool, 'Obligee Status', null, null, true, [{ label: 'Completed', value: "false" }, { label: 'Requires Review', value: "true" }], false),
            this.odata.getFilter('reviewSfaaCode', ODataFilterDataType.bool, 'Sfaa Status', null, null, true, [{ label: 'Completed', value: "false" }, { label: 'Requires Review', value: "true" }], false),
            this.odata.getFilter('reviewCoverLetters', ODataFilterDataType.bool, 'Cover Letter Status', null, null, true, [{ label: 'Completed', value: "false" }, { label: 'Requires Review', value: "true" }], false),
            this.odata.getFilter('reviewApplicationForms', ODataFilterDataType.bool, 'App Form Status', null, null, true, [{ label: 'Completed', value: "false" }, { label: 'Requires Review', value: "true" }], false),
            this.odata.getFilter('reviewCancellation', ODataFilterDataType.bool, 'Cancellation Status', null, null, true, [{ label: 'Completed', value: "false" }, { label: 'Requires Review', value: "true" }], false),
            this.odata.getFilter('reviewRenewal', ODataFilterDataType.bool, 'Renewal Status', null, null, true, [{ label: 'Completed', value: "false" }, { label: 'Requires Review', value: "true" }], false),
            this.odata.getFilter('reviewExpiration', ODataFilterDataType.bool, 'Expiration Status', null, null, true, [{ label: 'Completed', value: "false" }, { label: 'Requires Review', value: "true" }], false),
            this.odata.getFilter('reviewBondAmount', ODataFilterDataType.bool, 'Bond Amount Status', null, null, true, [{ label: 'Completed', value: "false" }, { label: 'Requires Review', value: "true" }], false),
            this.odata.getFilter('reviewAppQuestions', ODataFilterDataType.bool, 'App Questions Status', null, null, true, [{ label: 'Completed', value: "false" }, { label: 'Requires Review', value: "true" }], false),
            this.odata.getFilter('reviewAdditionalInformation', ODataFilterDataType.bool, 'Additional Info Status', null, null, true, [{ label: 'Completed', value: "false" }, { label: 'Requires Review', value: "true" }], false),
            this.odata.getFilter('reviewApplicationSettings', ODataFilterDataType.bool, 'App Settings Status', null, null, true, [{ label: 'Completed', value: "false" }, { label: 'Requires Review', value: "true" }], false),
        ]);
    }

    public saveRecordsPerPage = () => {
        this.widget.options.recordsPerPage = this.table.pager.recordsPerPage;
        this.dashboardService.save();
    }

    public saveOrderby = () => {
        this.widget.options.orderby = this.table.sorter.sortBy;
        this.dashboardService.save();
    }

    public buildQuery = () => {

        const query = this.odata.getQuery()
            .orderby(this.table.sorter.sortBy)
            .skip((this.table.pager.currentPage - 1) * this.table.pager.recordsPerPage)
            .top(this.table.pager.recordsPerPage)
            .expand('sfaaCode');

        let filterExpr = this.filterCollection.getFilterExpression();
        if (filterExpr) {
            query.filter(filterExpr);
        }

        return query;
    };

    public loadGrid = () => {
        if (this.table.sorter.sortBy !== this.widget.options.orderby) {
            this.saveOrderby();
        }

        if (this.table.pager.recordsPerPage != this.widget.options.recordsPerPage) {
            this.saveRecordsPerPage();
        }
        const query = this.buildQuery();
        const dataSvc = this.odata.getService(ODataEndpoint.BondType);

        this.busyIndicator.promise = dataSvc.get<A3ApiResponse<BondType[]>>(query)
            .then((response) => {
                this.table.setData(response.data.value, response.data['@odata.count']);
            })
            .catch(() => {
                this.errorMessage = "An error occurred trying to get the widget data";
            });           
    };

    public loadSfaaCodes = () => {
        return this.sfaaCodeService.getAllSfaaCodeOptions()
            .then((options) => {
                this.sfaaCodeOptions = options;
            });
    };
    
    public loadTagOptions = () => {
        return this.tagService.loadTagOptions()
            .then((tagOptions) => {
                this.tagOptions = tagOptions;
            });
    }

    public $onInit = () => {
        this.busyIndicator = {
            message: 'Loading...'
        };
    
        this.sfaaCodeOptions = [];
        this.tagOptions = [];

        this.loadSfaaCodes();
        this.loadTagOptions();

        this.setFilters();

        if (isDefined(this.widget.options.filters)) {
            this.filterCollection.mergeFilters(this.widget.options.filters);
        }

        this.$timeout(() => {
            this.loadGrid();
        });

        this.dashboardService.refreshFunctions.push(this.loadGrid);
        
        this.table.onChange = this.loadGrid;
    }
}

const dashboardWidgetBondTypeConfigurationListComponent = {
    bindings: {
        widget: '=', 
        removeWidget: '&', 
        cloneWidget: '&' 
    },
    templateUrl: 'app/states/common/dashboard/widgets/bondTypeConfigurationList/dashboardWidgetBondTypeConfigurationList.html',
    controller: DashboardWidgetBondTypeConfigurationListController,
    controllerAs: 'vm'
};

app.component('dashboardWidgetBondTypeConfigurationList', dashboardWidgetBondTypeConfigurationListComponent);