import {
    IStateProvider,
    IUrlRouterProvider
} from "../../typeDefinitions/uiRouter";
import { Injectables } from "../configuration/injectables";
import app from "../main";
import { ModalOpener } from "../modals/modalOpener";
import { ActivitySettingsController } from "./agentSpecific/activitySettings/activitySettingsController";
import { AgencyProfileController } from "./agentSpecific/agentCompanyProfile/agencyProfileController";
import { ApplicationController } from "./agentSpecific/application/applicationController";
import { ApplicationFollowUpStrategiesGridController } from "./agentSpecific/applicationFollowUpStrategies/applicationFollowUpStrategiesGridController";
import { ApplicationFollowupStrategyDetailController } from "./agentSpecific/applicationFollowUpStrategyDetail/applicationFollowUpStrategyDetail";
import { AutoFeesDetailController } from "./agentSpecific/autoFeeDetail/autoFeesDetailController";
import { AutoFeesController } from "./agentSpecific/autoFees/autoFeesController";
import { ApplicationBondTypeSelectionController } from "./agentSpecific/bondTypeSelection/applicationBondTypeSelectionController";
import { CustomerDetailController } from "./agentSpecific/customerDetail/customerDetailController";
import { CustomerGridController } from "./agentSpecific/customers/customerGridController";
import { CustomerSelectionController } from "./agentSpecific/customerSelection/customerSelectionController";
import { EmailTemplatesDetailController } from "./agentSpecific/emailTemplateDetail/emailTemplatesDetailController";
import { EmailTemplatesGridController } from "./agentSpecific/emailTemplates/emailTemplatesGridController";
import { ReportEpicBillingController } from "./agentSpecific/epicBillingReport/reportEpicBillingController";
import { ReportEpicIntegrationLogsController } from "./agentSpecific/epicIntegrationLogs/reportEpicIntegrationLogsController";
import { EProducerAccountsDetailController } from "./agentSpecific/eProducerBrokerDetail/eProducerAccountsDetailController";
import { EProducerAccountsController } from "./agentSpecific/eProducerBrokers/eProducerAccountsGridController";
import { ReportLeadsEntryController } from "./agentSpecific/leads/leadsEntryReportController";
import { ReportMonthEndController } from "./agentSpecific/monthEndAccounting/monthEndReportController";
import { ReportPaymentsController } from "./agentSpecific/payments/paymentsReportController";
import { ReportInvoiceController } from  "./agentSpecific/invoices/invoiceReportController";
import { ReportBillingEntryController } from "./agentSpecific/billingEntry/billingEntryReportController";
import { ReportAdverseActionsController } from "./carrierSpecific/adverseActions/reportAdverseActionsController";
import { AgencyAppointmentsDetailController } from "./carrierSpecific/agencyAppointmentDetail/agencyAppointementsDetailController";
import { AgencyAppointmentsController } from "./carrierSpecific/agencyAppointments/agencyAppointementsController";
import { CarrierOpenApplicationsController } from "./carrierSpecific/applications/carrierOpenApplicationsController";
import { BondNumberDetailController } from "./carrierSpecific/bondNumberDetail/bondNumberDetailController";
import { BondNumberGridController } from "./carrierSpecific/bondNumbers/bondNumberGridController";
import { CarrierApiConfigurationController } from "./carrierSpecific/carrierApi/carrierApiConfigurationController";
import { CarrierProfileController } from "./carrierSpecific/carrierCompanyProfile/carrierProfileController";
import { ReportDeclinedApplicationsController } from "./carrierSpecific/declinedApplications/reportDeclinedApplicationsController";
import { RateDetailController } from "./carrierSpecific/rateDetail/rateDetailController";
import { RateGridController } from "./carrierSpecific/rates/rateGridController";
import { RequiredFieldSetDetailController } from "./carrierSpecific/requiredFieldSetDetail/requiredFieldSetDetailController";
import { RequiredFieldSetGridController } from "./carrierSpecific/requiredFieldSets/requiredFieldSetGridController";
import { UnderwritingConfigurationDetailController } from "./carrierSpecific/underwritingConfigurationDetail/underwritingConfigurationDetailController";
import { UnderwritingConfigurationGridController } from "./carrierSpecific/underwritingConfigurations/underwritingConfigurationGridController";
import { A3BillingStatementsGridController } from "./common/a3Statements/a3BillingStatementsGridController";
import { ApplicationQuestionDetailController } from "./common/applicationQuestionDetail/applicationQuestionsDetailController";
import { ApplicationQuestionsGridController } from "./common/applicationQuestions/applicationQuestionsGridController";
import { BondTransactionReportController as transactionReportController } from "./common/bondTransactions/bondTransactionReportController";
import { BondTypeDetailController } from "./common/bondTypeDetail/bondTypeDetailController";
import { BondTypesReportController } from "./common/bondTypes/bondTypesReportController";
import { ChangePasswordController } from "./common/changePassword/changePasswordController";
import { DashboardController } from "./common/dashboard/dashboardController";
import { DocumentEditorController } from "./common/documentEditor/documentEditorController";
import { DocumentLibraryController } from "./common/documentLibrary/documentLibraryController";
import { ForgotPasswordController } from "./common/forgotPassword/forgotPasswordController";
import { LoginController } from "./common/login/loginController";
import { ObligeeDetailController } from "./common/obligeeDetail/obligeeDetailController";
import { ObligeeGridController } from "./common/obligees/obligeeGridController";
import { ReportPurchasedBondsController } from "./common/purchasedBonds/reportPurchasedBondsController";
import { riderReportController } from "./common/riders/riderReportController";
import { TermsConditionsController } from "./common/termsAndConditions/termsConditionsController";
import { UsersDetailController } from "./common/userDetail/usersDetailController";
import { GroupsDetailController } from "./common/userGroupDetail/groupsDetailController";
import { GroupsGridController } from "./common/userGroups/groupGridController";
import { UserProfileController } from "./common/userProfile/userProfileController";
import { UsersGridController } from "./common/users/usersGridController";
import { ContentController } from "./mainStructure/content/contentController";
import { FooterController } from "./mainStructure/footer/footerController";
import { HeaderController } from "./mainStructure/header/headerController";
import { MainController } from "./mainStructure/main/mainController";
import { SidebarController } from "./mainStructure/sidebarNav/sidebarController";
import { State } from "./state";
import { StateDefinition } from "./stateDefinition";
import { BondLinesReportController } from "./agentSpecific/bondLines/bondLinesReportController";
import { ApplicationReportController } from "./agentSpecific/applicationReport/applicationReportController";
import { ITimeoutService } from "angular";
import { UserSelectionController } from "./common/login/userSelectionController";

const stateConfiguration = (
    $urlRouterProvider: IUrlRouterProvider,
    $stateProvider: IStateProvider
) => {
    $urlRouterProvider.otherwise("/");

    const states: StateDefinition[] = [
        {
            name: "main",
            abstract: true,
            views: {
                "": {
                    templateUrl: "app/states/mainStructure/main/main.html",
                    controller: MainController,
                    controllerAs: "vm"
                },
                "header@main": {
                    templateUrl: "app/states/mainStructure/header/header.html",
                    controller: HeaderController,
                    controllerAs: "vm"
                },
                "sidebar@main": {
                    templateUrl:
                        "app/states/mainStructure/sidebarNav/sidebar.html",
                    controller: SidebarController,
                    controllerAs: "vm"
                },
                "content@main": {
                    templateUrl:
                        "app/states/mainStructure/content/content.html",
                    controller: ContentController,
                    controllerAs: "vm"
                },
                "footer@main": {
                    templateUrl: "app/states/mainStructure/footer/footer.html",
                    controller: FooterController,
                    controllerAs: "vm"
                }
            },
        },
        {
            name: "main.dashboard",
            url: "/",
            templateUrl: "app/states/common/dashboard/dashboard.html",
            controller: DashboardController,
            controllerAs: "vm",
            title: "Dashboard",
            embeddedTitleBar: true,
        },
        {
            name: "main.termsConditions",
            url: "/termsAndConditions",
            templateUrl:
                "app/states/common/termsAndConditions/termsConditions.html",
            controller: TermsConditionsController,
            title: "Terms and Conditions"
        },
        {
            name: "main.bondDetail",
            url: "/bondDetail/:id",
            onEnter: (
                modalOpener: ModalOpener,
                $state: State,
                $stateParams: any,
                $timeout:ITimeoutService
            ) => {
                $timeout(() => {
                    modalOpener
                        .bondDetailModal($stateParams.id)
                        .result.then(() => { })
                        .catch(() => { })
                        .finally(() => {
                            if ($stateParams.fromLogin) {
                                $state.go("main.dashboard");
                            } else {
                                window.history.back();
                            }
                        });
                });
            },
            params: { fromLogin: false }
        },
        {
            name: "main.bondAccountDetail",
            url: "/bondLine/:id",
            onEnter: (
                modalOpener: ModalOpener,
                $state: State,
                $stateParams: any,
                $timeout: ITimeoutService
            ) => {
                $timeout(() => {
                    modalOpener
                        .showBondAccountModal($stateParams.id)
                        .result.then(() => { })
                        .catch(() => { })
                        .finally(() => {
                            if ($stateParams.fromLogin) {
                                $state.go("main.dashboard");
                            } else {
                                window.history.back();
                            }
                        });
                });
            },
            params: { fromLogin: false }
        },
        {
            name: "main.activityDetail",
            url: "/activityDetail/:id",
            onEnter: (
                modalOpener: ModalOpener,
                $state: State,
                $stateParams: any,
                $timeout: ITimeoutService
            ) => {
                $timeout(() => {
                    modalOpener
                        .showActivityModal($stateParams.id)
                        .result.then(() => { })
                        .catch(() => { })
                        .finally(() => {
                            if ($stateParams.fromLogin) {
                                $state.go("main.dashboard");
                            } else {
                                window.history.back();
                            }
                        });
                });
            },
            params: { fromLogin: false }
        },
        {
            name: "main.billingEntryReport",
            url: "/reports/BillingEntries",
            templateUrl: "app/states/agentSpecific/billingEntry/billingEntryReport.html",
            controller: ReportBillingEntryController,
            controllerAs: "vm",
            embeddedTitleBar: true,
            title: "Billing Entries"
        },
        {
            name: "main.purchasedBondsReport",
            url: "/reports/purchasedBonds",
            templateUrl: "app/states/common/purchasedBonds/purchasedBonds.html",
            controller: ReportPurchasedBondsController,
            controllerAs: "vm",
            title: "Bonds"
        },
        {
            name: "main.purchasedBondLinesReport",
            url: "/reports/BondLinesReport",
            templateUrl:
                "app/states/agentSpecific/bondLines/bondLinesReport.html",
            controller: BondLinesReportController,
            controllerAs: "vm",
            title: "Bond Lines"
        },
        {
            name: "main.transactionsReport",
            url: "/reports/transactions",
            templateUrl: "app/states/common/bondTransactions/bondTransactionsReport.html",
            controller: transactionReportController,
            controllerAs: "vm",
            title: "Transactions"
        },
        {
            name: "main.ridersReport",
            url: "/reports/riders",
            templateUrl: "app/states/common/riders/riderReport.html",
            controller: riderReportController,
            controllerAs: "vm",
            title: "Riders"
        },
        {
            name: "main.paymentReport",
            url: "/reports/payments",
            templateUrl:
                "app/states/agentSpecific/payments/paymentsReport.html",
            controller: ReportPaymentsController,
            controllerAs: "vm",
            title: "Payments"
        },
        {
            name: "main.invoiceReport",
            url: "/reports/invoices",
            templateUrl: "app/states/agentSpecific/invoices/invoiceReport.html",
            controller: ReportInvoiceController,
            controllerAs: "vm",
            title: "Invoices"
        },
        {
            name: "main.leadReport",
            url: "/reports/leads",
            templateUrl: "app/states/agentSpecific/leads/leadsEntryReport.html",
            controller: ReportLeadsEntryController,
            controllerAs: "vm",
            title: "Leads"
        },
        {
            name: "main.monthEndReport",
            url: "/reports/monthEnd",
            templateUrl: "app/states/agentSpecific/monthEndAccounting/monthEndReport.html",
            controller:  ReportMonthEndController,
            controllerAs: "vm",
            title: "Month End Accounting"
        },
        {
            name: "main.declinedApplicationsReport",
            url: "/reports/declinedApplications",
            templateUrl:
                "app/states/carrierSpecific/declinedApplications/declinedApplications.html",
            controller: ReportDeclinedApplicationsController,
            controllerAs: "vm",
            title: "Declined Applications"
        },
        {
            name: "main.adverseActionsReport",
            url: "/reports/adverseActions",
            templateUrl:
                "app/states/carrierSpecific/adverseActions/adverseActions.html",
            controller: ReportAdverseActionsController,
            controllerAs: "vm",
            title: "Adverse Actions"
        },
        {
            name: "main.epicIntegrationLogs",
            url: "/reports/epicIntegration",
            templateUrl:
                "app/states/agentSpecific/epicIntegrationLogs/epicIntegrationLogs.html",
            controller: ReportEpicIntegrationLogsController,
            controllerAs: "vm",
            title: "Epic Integration Logs"
        },
        {
            name: "main.epicBillingReport",
            url: "/reports/epicBillingReport",
            templateUrl:
                "app/states/agentSpecific/epicBillingReport/epicBillingReport.html",
            controller: ReportEpicBillingController,
            controllerAs: "vm",
            embeddedTitleBar: true,
            title: "Epic Billing Report"
        },
        {
            name: "forgotPassword",
            url: "/forgotpassword/:email",
            templateUrl: "app/states/common/forgotPassword/forgotPassword.html",
            controllerAs: "vm",
            controller: ForgotPasswordController
        },
        {
            name: "changePassword",
            url: "/changepassword/:email/:passwordResetKey",
            templateUrl: "app/states/common/changePassword/changePassword.html",
            controllerAs: "vm",
            controller: ChangePasswordController
        },
        {
            name: "login",
            url: "/login/:email/:message",
            templateUrl: "app/states/common/login/login.html",
            controllerAs: "vm",
            controller: LoginController
        },
        {
            name: "userSelection",
            url: "/userSelection",
            templateUrl: "app/states/common/login/userSelection.html",
            controllerAs: "vm",
            controller: UserSelectionController
        },
        {
            name: "main.autoFees",
            url: "/fees",
            templateUrl: "app/states/agentSpecific/autoFees/autoFees.html",
            controllerAs: "vm",
            controller: AutoFeesController,
            title: "Fees"
        },
        {
            name: "main.autoFeeDetail",
            url: "/fees/:id",
            templateUrl:
                "app/states/agentSpecific/autoFeeDetail/autoFeesDetail.html",
            controller: AutoFeesDetailController,
            controllerAs: "vm",
            title: "Fees"
        },
        {
            name: "main.emailTemplates",
            url: "/emailTemplates",
            templateUrl:
                "app/states/agentSpecific/emailTemplates/emailTemplatesGrid.html",
            controller: EmailTemplatesGridController,
            controllerAs: "vm",
            title: "Email Templates"
        },
        {
            name: "main.emailTemplateDetail",
            url: "/emailTemplates/:id",
            templateUrl:
                "app/states/agentSpecific/emailTemplateDetail/emailTemplatesDetail.html",
            controller: EmailTemplatesDetailController,
            controllerAs: "vm",
            title: "Email Templates"
        },
        {
            name: "main.applicationFollowUpStrategies",
            url: "/followUpStrategies",
            templateUrl:
                "app/states/agentSpecific/applicationFollowUpStrategies/applicationFollowUpStrategiesGrid.html",
            controller: ApplicationFollowUpStrategiesGridController,
            controllerAs: "vm",
            title: "Follow-Up Strategies"
        },
        {
            name: "main.applicationFollowUpStrategyDetail",
            url: "/followUpStrategies/:id",
            templateUrl:
                "app/states/agentSpecific/applicationFollowUpStrategyDetail/applicationFollowUpStrategyDetail.html",
            controller: ApplicationFollowupStrategyDetailController,
            controllerAs: "vm",
            title: "Follow-Up Strategies"
        },
        {
            name: "main.usersGrid",
            url: "/settings/users",
            title: "Users",
            templateUrl: "app/states/common/users/usersGrid.html",
            controller: UsersGridController,
            controllerAs: "vm",
            requiredPermissions: "canEditUsers"
        },
        {
            name: "main.usersDetail",
            url: "/settings/users/:id",
            title: "Users",
            templateUrl: "app/states/common/userDetail/usersDetail.html",
            controller: UsersDetailController,
            controllerAs: "vm",
            embeddedTitleBar: true,
            requiredPermissions: "canEditUsers"
        },
        {
            name: "main.groupsGrid",
            url: "/settings/groups",
            title: "Groups",
            templateUrl: "app/states/common/userGroups/groupsGrid.html",
            controller: GroupsGridController,
            controllerAs: "vm",
            requiredPermissions: "canEditUserGroups"
        },
        {
            name: "main.groupsDetail",
            url: "/settings/groups/:id",
            title: "User Group",
            templateUrl: "app/states/common/userGroupDetail/groupsDetail.html",
            controller: GroupsDetailController,
            controllerAs: "vm",
            requiredPermissions: "canEditUserGroups"
        },
        {
            name: "main.obligeesGrid",
            url: "/settings/obligees",
            title: "Obligees",
            templateUrl: "app/states/common/obligees/obligeesGrid.html",
            controller: ObligeeGridController,
            controllerAs: "vm",
            requiredPermissions: "canEditObligees"
        },
        {
            name: "main.obligeesDetail",
            url: "/settings/obligees/:id",
            title: "Obligees",
            templateUrl: "app/states/common/obligeeDetail/obligeesDetail.html",
            controller: ObligeeDetailController,
            controllerAs: "vm",
            requiredPermissions: "canEditObligees"
        },
        {
            name: "main.bondTypes",
            url: "/settings/bondTypes",
            title: "Bond Types",
            embeddedTitleBar: false,
            templateUrl: "app/states/common/bondTypes/bondTypesReport.html",
            controller: BondTypesReportController,
            controllerAs: "vm"
        },
        {
            name: "main.bondTypesDetail",
            url: "/settings/bondTypes/:id",
            title: "Bond Types",
            embeddedTitleBar: true,
            templateUrl: "app/states/common/bondTypeDetail/bondTypeDetail.html",
            controller: BondTypeDetailController,
            controllerAs: "vm",
            requiredPermissions: "canEditBondTypes"
        },
        {
            name: "main.applicationQuestionsGrid",
            url: "/settings/applicationQuestions/",
            title: "Application Questions",
            templateUrl:
                "app/states/common/applicationQuestions/applicationQuestionsGrid.html",
            controller: ApplicationQuestionsGridController,
            controllerAs: "vm"
        },
        {
            name: "main.applicationQuestionsDetail",
            url: "/settings/applicationQuestions/:id",
            title: "Application Questions",
            templateUrl:
                "app/states/common/applicationQuestionDetail/applicationQuestionsDetail.html",
            controller: ApplicationQuestionDetailController,
            controllerAs: "vm"
        },
        {
            name: "main.a3BillingStatements",
            url: "/a3/statements/",
            title: "A3 Billing Statements",
            templateUrl:
                "app/states/common/a3Statements/a3BillingStatementsGrid.html",
            controller: A3BillingStatementsGridController,
            controllerAs: "vm"
        },
        {
            name: "main.activitySettings",
            url: "/settings/activities",
            title: "Activity Settings",
            embeddedTitleBar: true,
            templateUrl:
                "app/states/agentSpecific/activitySettings/activitySettings.html",
            controller: ActivitySettingsController,
            controllerAs: "vm"
        },
        {
            name: "main.documentLibrary",
            url: "/documents",
            params: { reloadDocumentLibrarySettings: false },
            templateUrl:
                "app/states/common/documentLibrary/documentLibrary.html",
            controller: DocumentLibraryController,
            controllerAs: "vm"
        },
        {
            name: "documentEditor",
            url: "/documentEditor/:id",
            title: "Document Editor",
            templateUrl: "app/states/common/documentEditor/documentEditor.html",
            controller: DocumentEditorController,
            controllerAs: "vm",
            params: { settings: null }
        },
        {
            name: "main.customers",
            url: "/customers",
            templateUrl: "app/states/agentSpecific/customers/customerGrid.html",
            controller: CustomerGridController,
            controllerAs: "vm",
            title: "Customers"
        },
        {
            name: "main.customerDetail",
            url: "/customers/:customerId/:sectionView",
            templateUrl:
                "app/states/agentSpecific/customerDetail/customerDetail.html",
            controller: CustomerDetailController,
            controllerAs: "vm",
            title: "Customer Detail",
            embeddedTitleBar: true,
            params: { applicationId: null }
        },
        {
            name: "main.ratesGrid",
            url: "/carrierConfigurations/Rates",
            templateUrl: "app/states/carrierSpecific/rates/rateGrid.html",
            controller: RateGridController,
            controllerAs: "vm",
            title: "Rates Configuration"
        },
        {
            name: "main.ratesDetail",
            url: "/carrierConfigurations/Rates/:id",
            templateUrl:
                "app/states/carrierSpecific/rateDetail/rateDetail.html",
            controller: RateDetailController,
            controllerAs: "vm",
            title: "Rates Configuration"
        },
        {
            name: "main.bondNumbersGrid",
            url: "/carrierConfigurations/BondNumbers",
            templateUrl:
                "app/states/carrierSpecific/bondNumbers/bondNumberGrid.html",
            controller: BondNumberGridController,
            controllerAs: "vm",
            title: "Bond Number Groups"
        },
        {
            name: "main.bondNumbersDetail",
            url: "/carrierConfigurations/BondNumbers/:id",
            templateUrl:
                "app/states/carrierSpecific/bondNumberDetail/bondNumberDetail.html",
            controller: BondNumberDetailController,
            controllerAs: "vm",
            title: "Bond Number Group Configuration"
        },
        {
            name: "main.underwritingConfigurationGrid",
            url: "/carrierConfigurations/underwritingConfigurationGrid",
            templateUrl:
                "app/states/carrierSpecific/underwritingConfigurations/underwritingConfigurationGrid.html",
            controller: UnderwritingConfigurationGridController,
            controllerAs: "vm",
            title: "Underwriting Configuration"
        },
        {
            name: "main.underwritingConfigurationDetail",
            url: "/carrierConfigurations/underwritingConfigurationDetail/:id",
            templateUrl:
                "app/states/carrierSpecific/underwritingConfigurationDetail/underwritingConfigurationDetail.html",
            controller: UnderwritingConfigurationDetailController,
            controllerAs: "vm",
            embeddedTitleBar: true,
            title: "Underwriting Configuration"
        },
        {
            name: "main.requiredFieldSetGrid",
            url: "/carrierConfigurations/requiredFieldSets",
            templateUrl:
                "app/states/carrierSpecific/requiredFieldSets/requiredFieldSetGrid.html",
            controller: RequiredFieldSetGridController,
            controllerAs: "vm",
            title: "Required Field Set"
        },
        {
            name: "main.requiredFieldSetDetail",
            url: "/carrierConfigurations/requiredFieldSets/:id",
            templateUrl:
                "app/states/carrierSpecific/requiredFieldSetDetail/requiredFieldSetDetail.html",
            controller: RequiredFieldSetDetailController,
            controllerAs: "vm",
            title: "Required Field Set"
        },
        {
            name: "main.carrierApiConfiguration",
            url: "/carrierConfigurations/api",
            templateUrl:
                "app/states/carrierSpecific/carrierApi/carrierApiConfigurationPage.html",
            controller: CarrierApiConfigurationController,
            controllerAs: "vm",
            title: "Api Configuration"
        },
        {
            name: "main.agencyAppointments",
            url: "/agencyAppointments",
            templateUrl:
                "app/states/carrierSpecific/agencyAppointments/agencyAppointments.html",
            controller: AgencyAppointmentsController,
            controllerAs: "vm",
            title: "Agency Appointments"
        },
        {
            name: "main.agencyAppointmentsDetail",
            url: "/agencyAppointments/:id",
            templateUrl:
                "app/states/carrierSpecific/agencyAppointmentDetail/agencyAppointmentsDetail.html",
            controller: AgencyAppointmentsDetailController,
            controllerAs: "vm",
            title: "Agency Appointments"
        },
        {
            name: "main.customerSelection",
            url: "/customerSelection/:appType/:customerType",
            templateUrl:
                "app/states/carrierSpecific/customerSelection/customerSelection.html",
            controller: CustomerSelectionController,
            controllerAs: "vm",
            title: "Customer Selection"
        },
        {
            name: "main.application",
            url: "/application/:customerId/:applicationId/:bondId/:appType",
            templateUrl:
                "app/states/agentSpecific/application/application.html",
            controller: ApplicationController,
            title: "New Application",
            embeddedTitleBar: true,
            controllerAs: "vm",
            params: {
                customerId: null,
                bondTypeId: null,
                eProducerAccountId: null
            }
        },
        {
            name: "main.bondTypeSelection",
            url: "/bondTypeSelection/:bondTypeId",
            templateUrl:
                "app/states/agentSpecific/bondTypeSelection/bondTypeSelection.html",
            controller: ApplicationBondTypeSelectionController,
            title: "New Application",
            controllerAs: "vm",
            params: {
                customerId: null,
                eProducerAccountId: null,
                bondTypeId: null
            }
        },
        {
            name: "main.carrierApplications",
            url: "/carrier/applications",
            templateUrl:
                "app/states/carrierSpecific/applications/carrierOpenApplications.html",
            controller: CarrierOpenApplicationsController,
            controllerAs: "vm",
            title: "Open Applications"
        },
        {
            name: "main.userProfile",
            url: "/profile",
            templateUrl: "app/states/common/userProfile/userProfile.html",
            controller: UserProfileController,
            title: "User Profile",
            controllerAs: "vm"
        },
        {
            name: "main.carrierProfile",
            url: "/carrierProfile",
            templateUrl:
                "app/states/carrierSpecific/carrierCompanyProfile/carrierProfile.html",
            controller: CarrierProfileController,
            title: "Carrier Account Settings",
            controllerAs: "vm"
            //todo permissions
        },
        {
            name: "main.agencyProfile",
            url: "/agencyProfile",
            templateUrl:
                "app/states/agentSpecific/agentCompanyProfile/agencyProfile.html",
            controller: AgencyProfileController,
            controllerAs: "vm",
            title: "Agency Profile Settings"
        },
        {
            name: "main.eProducerAccounts",
            url: "/eProducerAccounts",
            templateUrl:
                "app/states/agentSpecific/eProducerBrokers/eProducerAccountsGrid.html",
            controller: EProducerAccountsController,
            controllerAs: "vm",
            title: "EProducer Broker Accounts"
        },
        {
            name: "main.eProducerAccountDetail",
            url: "/eProducerAccounts/:id",
            templateUrl:
                "app/states/agentSpecific/eProducerBrokerDetail/eProducerAccountsDetail.html",
            controller: EProducerAccountsDetailController,
            controllerAs: "vm",
            embeddedTitleBar: true,
            title: "EProducer Broker Account",
            params: { id: null }
        },
        {
            name: "main.applicationReport",
            url: "/reports/applications",
            templateUrl: "app/states/agentSpecific/applicationReport/applicationReport.html",
            controller: ApplicationReportController,
            controllerAs: "vm",
            title: "Application Report"
        }
    ];

    for (let i = 0; i < states.length; i++) {
        $stateProvider.state(states[i].name, states[i]);
    }
};

stateConfiguration.$inject = [
    Injectables.$urlRouterProvider,
    Injectables.$stateProvider
];

app.config(stateConfiguration);
