import { BondChange } from "./model/bondChange";
import { Fee } from "./model/fee";

export interface RiderRequestSubmission   { 
    bondId: number;
    agencyComments: string;
    requestedEffectiveDate: Date;
    attorneyInFactUserId: number;
    changes: BondChange[];
    fees: Fee[];
}