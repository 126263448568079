import { ApplicationService } from "../../api/applicationService";
import { SystemAccountService } from "../../api/systemAccountService";
import { ApplicationType } from "../../api/types/model/application";
import { BondNumberGroup } from "../../api/types/model/bondNumberGroup";
import { SystemAccount } from "../../api/types/model/systemAccount";
import { WritingCompany } from "../../api/types/model/writingCompany";
import { CommissionDropdownController } from "../../components/commissionDropdown/commissionDropdown";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { QuoteDetailModalOptions } from "./quoteDetailModalOptions";
import { QuoteDetailModalResult } from "./QuoteDetailModalResult";
import app from "../../main";
import { IPromise, IQService } from "angular";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";
import QuoteDetail from "../../api/types/quoteDetail";
import { RateTier } from "../../api/types/model/rateTier";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { SelectOption } from "../../api/types/selectOption";
import { DefaultQuote } from "../../api/types/model/defaultQuote";
import { IndemnityRequirements, QuoteStatus } from "../../api/types/model/quote";
import { RateType } from "../../api/types/model/rate";

export class QuoteDetailModalController {
    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.ApplicationService,
        Injectables.SystemAccountService,
        Injectables.CurrentUserResolver,
        Injectables.$q,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly $uibModalInstance: Modal<QuoteDetailModalResult>,
        private readonly options: QuoteDetailModalOptions,
        private readonly applicationService: ApplicationService,
        private readonly systemAccountService: SystemAccountService,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly $q: IQService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {}

    public quote: QuoteDetail;
    public defaultMinimumPremium: number;
    public tierCountInvalid: boolean;
    public busyIndicator: BusyIndicator;
    public carrierOptions: SelectOption[];
    public isCarrier: boolean;
    public writingCompanies: WritingCompany[];
    public rateLowerThanPremium: boolean;
    public bondNumberGroups: BondNumberGroup[];
    public applicationType: ApplicationType;
    public canEdit: boolean;
    public tierAdding: boolean;
    public newThreshold: number;
    public newTierRate: number;
    public brokerCommissionCalculationTypeOptions: SelectOption<string>[];

    public commissionDropdownController: CommissionDropdownController;

    private loadExistingQuote = (quoteId: number) => {
        return this.applicationService
            .getQuoteDetail(quoteId)
            .then((quote) => {
               
                this.quote = quote;
                    
                if((
                    this.isCarrier &&
                    this.quote.carrierSystemAccountId === this.currentUserResolver.getCurrentUser().systemAccount.id
                ) || (
                    this.quote.agencySystemAccountId === this.currentUserResolver.getCurrentUser().systemAccount.id &&
                    this.quote.agencyCanEditQuote
                )) {
                    this.canEdit = true;
                }
                
                return this.loadCarrierSettings();
            });
    }

    public getDefaultQuote(applicationId: number): IPromise<void> {
        
        return this.applicationService.getDefaultQuote(applicationId)
            .then((defaultQuote: DefaultQuote) => {

                this.quote = {
                    quoteType: defaultQuote.quoteType,
                    applicationId: defaultQuote.applicationId,
                    carrierSystemAccountId: defaultQuote.carrierSystemAccountId,
                    bondAmount: defaultQuote.bondAmount,
                    bondType: defaultQuote.bondType,
                    nameOnBond: defaultQuote.nameOnBond,
                    carrierCompanyName: defaultQuote.carrierCompanyName,
                    desiredEffectiveDate: defaultQuote.desiredEffectiveDate,
                    appointmentId: defaultQuote.carrierAppointmentId,
                    writingCompanyId: defaultQuote.writingCompanyId,
                    indemnityRequirements: IndemnityRequirements.None,
                    rateType: RateType.Fixed,
                    fixedRate: defaultQuote.premium,
                    status: QuoteStatus.Approved,
                    term: defaultQuote.term,
                    bondNumberGroupId: defaultQuote.bondNumberGroupId,
                    brokerEProducerAccountName: defaultQuote.brokerEProducerAccountName,
                    brokerEProducerAccountId: defaultQuote.brokerEProducerAccountId,
                    brokerEProducerAccountLookupCode: defaultQuote.brokerEProducerAccountLookupCode,
                    billToType: defaultQuote.billToType,
                    brokerCommissionCalculationType: defaultQuote.brokerCommissionCalculationType,
                    brokerCommissionPercent: defaultQuote.brokerCommissionPercent,
                    fees: defaultQuote.fees,
                    commissionType: defaultQuote.commissionType,
                    commissionPercent: defaultQuote.commissionPercent,
                    commission: defaultQuote.commission,
                    bondTypeId: defaultQuote.bondTypeId
                } as QuoteDetail;

                this.canEdit = true;

                // if no carrier is set then get carrier options
                if (!this.quote.carrierSystemAccountId) {
                    return this.getCarrierOptions();
                }

                return this.loadCarrierSettings();
            });
    }

    public cancel(): void {
        this.$uibModalInstance.dismiss("cancel");
    }

    public loadCarrierSettings(): IPromise<any> {
        const promises: IPromise<any>[] = [];

        promises.push(this.loadWritingCompanies());
        promises.push(this.loadBondNumberGroups());
        
        if (!this.quote.carrierCompanyName) {
            this.quote.carrierCompanyName = this.carrierOptions
                .find((carrier) => carrier.value == this.quote.carrierSystemAccountId)
                .label;
        }

        return this.$q.all(promises);
    }

    public save(): void {
        // invalid when tier and not tiers assigned
        if ((!this.quote.rateTiers || !this.quote.rateTiers.length) && this.quote.rateType === "Tiered") {
            this.tierCountInvalid = true;
            return;
        }

        this.busyIndicator.message = 'Saving...';
        this.busyIndicator.promise = this.applicationService
            .saveQuote(this.quote)
            .then(() => {
                this.$uibModalInstance.close(this.quote);
                this.toastMessageCreator.createSuccessMessage("Quote has been saved!");
                return;
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage("We encountered a problem saving this quote");
                return;
            });
    }
    
    public getCarrierOptions(): IPromise<void> {
        return this.systemAccountService.getCarriersThatAllowEditingQuotes()
            .then((carriers) => {
                this.carrierOptions = carriers;
                return;
            });
    }

    public loadWritingCompanies(): IPromise<void> {

        return this.systemAccountService
            .getWritingCompanies(this.quote.carrierSystemAccountId)
            .then((writingCompanies) => {
                this.writingCompanies = writingCompanies;

                // if only one exists then set to first
                if (!this.quote.writingCompanyId && writingCompanies.length === 1) {
                    this.quote.writingCompanyId = writingCompanies[0].id;
                }

                // build writing company object to return to list
                this.setWritingCompany(this.quote.writingCompanyId);
            });
    }

    public loadBondNumberGroups(): IPromise<void> {

        return this.systemAccountService
            .getBondNumberGroups(this.quote.carrierSystemAccountId)
            .then((bondNumberGroups) => {
                this.bondNumberGroups = bondNumberGroups;

                // if only one exists then set to first
                if (bondNumberGroups.length === 1) {
                    this.quote.bondNumberGroupId = bondNumberGroups[0].id;
                }
            });
    }

    public addNewTierRate(): void {

        this.tierAdding = true;

        if (!this.quote.rateTiers) {
            this.quote.rateTiers = [];
        }

        if (!this.newThreshold || !this.newTierRate) {
            return;
        }

        // insert in order of threshold
        const newTierIndex = this.getNewTierIndex(this.quote.rateTiers, this.newThreshold);

        const newTier = {
            ratePerThousand: this.newTierRate,
            thresholdAmount: this.newThreshold,
        } as RateTier;

        this.quote.rateTiers.splice(newTierIndex, 0, newTier);

        this.newTierRate = undefined;
        this.newThreshold = undefined;
        this.tierAdding = false;
    }

    public getNewTierIndex(tiers: RateTier[], newThreshold: number): number {
        let low = 0;
        let high = tiers.length;

        while (low < high) {
            // tslint:disable-next-line:no-bitwise
            const mid = (low + high) >>> 1;

            if (tiers[mid].thresholdAmount < newThreshold) {
                low = mid + 1;
            } else {
                high = mid;
            }
        }
        return low;
    }

    public removeTierRate(index: number): void {
        this.quote.rateTiers.splice(index, 1);
    }

    public setWritingCompany(writingCompanyId: number): void {

        if (!writingCompanyId || !this.writingCompanies) {
            return;
        }

        const selectedWritingCompany = this.writingCompanies.find((writingCompany) => writingCompany.id == writingCompanyId);

        if (selectedWritingCompany) {
            this.defaultMinimumPremium = selectedWritingCompany.defaultMinimumPremium;

            // set minimum premium if adding new quote
            if (!this.quote.id) {
                this.quote.minimumPremium = selectedWritingCompany.defaultMinimumPremium;
            }

            this.commissionDropdownController.getCommissionOptions({
                writingCompanyId: this.quote.writingCompanyId,
                carrierSystemAccountId: this.quote.carrierSystemAccountId,
                agencySystemAccountId: this.quote.agencySystemAccountId,
                bondTypeId: this.quote.bondTypeId
            });
        }
    }

    public rateChange(): void {
        this.rateLowerThanPremium = this.quote.fixedRate < this.defaultMinimumPremium;
        this.quote.minimumPremium = this.quote.fixedRate;
    }

    public $onInit(): void {
        this.busyIndicator = {
            message: 'Loading...'
        };
        this.isCarrier = this.currentUserResolver.getCurrentUser().systemAccount.isCarrier;
        this.applicationType = this.applicationType || ApplicationType.SingleBond;

        this.brokerCommissionCalculationTypeOptions = [
            { label: 'Percent of Commission', value: 'PercentOfCommission' },
            { label: 'Percent of Premium', value: 'PercentOfPremium' },
            { label: 'Fixed Amount', value: 'FixedAmount'}
        ];

        if (this.options.quoteId) {
            this.busyIndicator.promise = this.loadExistingQuote(this.options.quoteId);
        } else {
            this.busyIndicator.promise = this.getDefaultQuote(this.options.applicationId);
        }
    }
}

app.controller("QuoteDetailModalController", QuoteDetailModalController);
