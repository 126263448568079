import app from "../../main";

class RecordCounterController {

    static $inject = [];

    constructor () {}

    recordCount: number;

    public $onInit() {

    }
}

const recordCounterComponent = {
    bindings: {
        recordCount: '<',
        class: '@?'
    },
    controller: RecordCounterController,
    controllerAs: 'vm',
    templateUrl: 'app/components/recordCounter/recordCounter.html'
};

app.component('recordCounter', recordCounterComponent);