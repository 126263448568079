import { InvoiceService } from "../../api/invoiceService";
import { NewInvoiceRequest } from "../../api/types/invoices/newInvoiceRequest";
import { UnbilledBondTransaction } from "../../api/types/invoices/unbilledBondTransaction";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { NewInvoiceModalOptions } from "./newInvoiceModalOptions";
import { NewInvoiceModalResult } from "./newInvoiceModalResult";
import app from "../../main";
import { IPromise } from "angular";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";

export class NewInvoiceModalController {

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.InvoiceService,
        Injectables.ToastMessageCreator
    ];
    
    constructor(
        private readonly $uibModalInstance: Modal<NewInvoiceModalResult>,
        private readonly modalOptions: NewInvoiceModalOptions,
        private readonly invoiceService: InvoiceService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {
    }

    public customerId: number;
    public eProducerAccountId: number;
    public busyIndicator: BusyIndicator;
    public bondTransactions: UnbilledBondTransaction[];
    public selectAll: boolean;

    private getBillableItemsForNewInvoice() {
        this.busyIndicator.message = 'Loading Transactions...';
        this.busyIndicator.promise = this.invoiceService.getBillableItemsForNewInvoice(this.customerId, this.eProducerAccountId)
            .then((linkableTransactions) => {
                this.bondTransactions = linkableTransactions;
                this.handleSelectAll();
            });
    }

    public handleSelectAll() {
        for(let i = 0; i < this.bondTransactions.length; i++) {
            this.bondTransactions[i].isSelected = this.selectAll;
        }
    }

    public submit() {
        const billingEntryIds = [];
        
        const selectedBondTransactions = this.bondTransactions
            .filter((bondTransaction) => bondTransaction.isSelected);

        for(let i = 0; i < selectedBondTransactions.length; i++) {
            for(let j = 0; j < selectedBondTransactions[i].billingEntries.length; j++) {
                billingEntryIds.push(selectedBondTransactions[i].billingEntries[j].billingEntryId);
            }
        }
        
        if (!billingEntryIds.length) {
            return;
        }

        const newInvoice = {
            billingEntryIds: billingEntryIds
        } as NewInvoiceRequest;

        this.busyIndicator.message = 'Saving Invoice...'
        this.busyIndicator.promise = this.invoiceService.createInvoice(newInvoice)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Invoice was created successfully');
                this.$uibModalInstance.close(new NewInvoiceModalResult());
            })
            .catch(() =>
            {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to create this invoice');
            });
    }

    public cancel() {
        this.$uibModalInstance.dismiss();
    }

    public $onInit(): void {
        this.busyIndicator = {};
        this.selectAll = true;
        this.customerId = this.modalOptions.customerId;
        this.eProducerAccountId = this.modalOptions.eProducerAccountId;

        this.getBillableItemsForNewInvoice();
    }
}

app.controller('NewInvoiceModalController', NewInvoiceModalController);
