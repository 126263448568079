import * as angular from "angular";
import { IRootElementService, IQService, IFilterService } from "angular";
import { ApplicationService } from "../../../../../api/applicationService";
import DropdownOption from "../../../../../api/types/dropdownOption";
import OpenApplicationCounterFilterType from "../../../../../api/types/openApplicationCounterFilterType";
import { BusyIndicator } from "../../../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../../../configuration/injectables";
import app from "../../../../../main";
import { JQueryService } from "../../../../../utilities/jquery/jQueryService";
import { SlabTextService } from "../../../../../utilities/slabText/slabTextService";
import { DashboardService } from "../../dashboardService";
import { DashboardWidget } from "../../types/dashboardWidget";

class DashboardWidgetOpenApplicationsCountAgentController {

    public static $inject = [
        Injectables.$element,
        Injectables.ApplicationService,
        Injectables.DashboardService,
        Injectables.$q, 
        Injectables.$filter,
        Injectables.SlabTextService,
        Injectables.JQueryService
    ];

    constructor(
        private readonly $element:IRootElementService, 
        private readonly applicationService: ApplicationService, 
        private readonly dashboardService: DashboardService, 
        private readonly $q: IQService, 
        private readonly $filter: IFilterService,
        private readonly slabTextService: SlabTextService,
        private readonly jQueryService: JQueryService
    ) {}

    public widget: DashboardWidget;
    public busyIndicator: BusyIndicator;
    public value: string;
    public dateReferenceOptions: DropdownOption<string>[];
    public statusOptions: DropdownOption<OpenApplicationCounterFilterType>[];

    public dateReferenceOptionSelected = (option: DropdownOption<string>) => {
        this.widget.options.dateReference = option;
        this.loadValue();
        this.dashboardService.save();
    };

    public statusOptionSelection = (option: DropdownOption<string>) => {
        this.widget.options.status = option;
        this.loadValue();
        this.dashboardService.save();
    }

    public loadValue = () => {
        var promises = [];

        if (this.widget.options.dateReference.value === 'Right Now') {
            promises.push(this.applicationService.openCount(this.widget.options.status.value));
        } else {
            return;
        }

        this.busyIndicator.promise = this.$q.all(promises)
            .then((values: number[]) => {
                this.value = this.$filter('number')(values[0]);

                this.updateValueSize(this.value);
            });
    }

    public updateValueSize = (value) => {
        this.slabTextService.setupSlabText(
            this.jQueryService.getElement('.value', this.$element)
                .html(value),
            { maxFontSize: 40 }
        )
    }

    public $onInit = () => {

        this.busyIndicator = {
            message: 'Loading...'
        };
       
        this.dateReferenceOptions = [
            {
                label: 'Right Now',
                value: 'Right Now'
            }
        ];

        this.statusOptions = [
            {
                label: 'All',
                value: OpenApplicationCounterFilterType.All
            },
            {
                isDivider: true
            },
            {
                label: 'Approved',
                value: OpenApplicationCounterFilterType.Approved
            },{
                label: 'Manual Review',
                value: OpenApplicationCounterFilterType.ManualReview
            }
        ];
        this.loadValue();

        this.dashboardService.refreshFunctions.push(this.loadValue);
    }
}

const dashboardWidgetOpenApplicationsCountAgentComponent = {
    bindings: {
        widget: '='
    },
    templateUrl: 'app/states/common/dashboard/widgets/openApplicationCountAgent/dashboardWidgetOpenApplicationsCountAgent.html',
    controller: DashboardWidgetOpenApplicationsCountAgentController,
    controllerAs: 'vm'
};

app.component('dashboardWidgetOpenApplicationsCountAgent', dashboardWidgetOpenApplicationsCountAgentComponent);   
