import * as angular from "angular";
import { ITimeoutService } from "angular";
import { ODataFilterCollection, ODataFilter } from "../../../../api/odata";
import { Injectables } from "../../../../configuration/injectables";
import app from "../../../../main";
import { ModalOpener } from "../../../../modals/modalOpener";
import { JQueryService } from "../../../../utilities/jquery/jQueryService";
import { DashboardService } from "../dashboardService";
import { DashboardWidget } from "../types/dashboardWidget";

export class DashboardWidgetFilterController {

    public static $inject = [
        Injectables.DashboardService,
        Injectables.$timeout,
        Injectables.ModalOpener,
        Injectables.JQueryService
    ]; 

    constructor(
        private readonly dashboardService: DashboardService,
        private readonly $timeout: ITimeoutService,
        private readonly modalOpener: ModalOpener,
        private readonly jQueryService: JQueryService
    ) {
    }

    public widget: DashboardWidget;
    public filtersChanged?: () => void;
    public filterCollection: ODataFilterCollection;

    public toggleCheckboxDropdown = ($event) => {
        var $this = this.jQueryService.getElement($event.target);
        var dropdownContainer = $this.parents('.dropdown');
        dropdownContainer.toggleClass('open');
    };

    public removeFilter  = (filter: ODataFilter) => {
        filter.reset();

        this.$timeout(() => {
            this.filtersChanged();
        });

        this.setEnabledFiltersToOptions();
    };

    public showFiltersModal = () => {

        this.modalOpener.showFiltersModal(angular.copy(this.filterCollection))
            .result
            .then((result) => {
                this.filterCollection = result.filterCollection;

                this.$timeout(() => {
                    this.filtersChanged();
                });
            
                this.setEnabledFiltersToOptions();
            })
            .catch(() => {});
    };

    public resetAllFilters = () => {
        this.filterCollection.resetAllFilters();
        
        this.$timeout(() => {
            this.filtersChanged();
        });

        this.setEnabledFiltersToOptions();
    };

    public setEnabledFiltersToOptions = () => {
        this.widget.options.filters = this.filterCollection.getEnabledFilters();
        this.dashboardService.save();
    }

    public $onInit = () => {
    }
}

const dashboardWidgetFilterComponent = {
    bindings: {
        filterCollection: '=',
        widget: '=',
        filtersChanged: '&'
    },
    templateUrl: 'app/states/common/dashboard/widgetFilter/dashboardWidgetFilter.html',
    controller: DashboardWidgetFilterController,
    controllerAs: 'vm'
};

app.component('dashboardWidgetFilter', dashboardWidgetFilterComponent);