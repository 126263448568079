import { IStateService } from "../../../typeDefinitions/uiRouter";
import { CustomersService } from "../../api/customerService";
import { CurrentUser } from "../../api/types/authentication/currentUser";
import { SearchResultMatch } from "../../api/types/searchResultMatch";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { ModalOpener } from "../../modals/modalOpener";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";

export class GlobalSearchController {
    public static $inject = [
        Injectables.CurrentUserResolver,
        Injectables.CustomersService,
        Injectables.$state,
        Injectables.ModalOpener
    ];

    constructor(
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly customersService: CustomersService,
        private readonly $state: IStateService,
        private readonly modalOpener: ModalOpener
    ) {
    }

    public searchPhrase: string;
    public showSearchResults: boolean;
    public searchResults: SearchResultMatch[];
    public isSearching: boolean;
    public isError: boolean;

    public get currentUser(): CurrentUser {
        return this.currentUserResolver.getCurrentUser();
    }

    public get isSearchDropdownOpen(): boolean {
        return this.isError ||
                this.isSearching ||
                this.showSearchResults;
    }

    public clearSearch() {
        this.searchPhrase = '';
        this.searchResults = null;
        this.closeSearch();
    }

    public closeSearch() {
        this.isSearching = false;
        this.isError = false;
        this.showSearchResults = false;
    }

    
    private _hasCustomerSearchResults : boolean;
    public get hasCustomerSearchResults() : boolean {
        return this._hasCustomerSearchResults;
    }

    private _hasBondSearchResults: boolean;
    public get hasBondSearchResults(): boolean {
        return this._hasBondSearchResults;
    }

    private _hasApplicationSearchResults: boolean;
    public get hasApplicationSearchResults(): boolean {
        return this._hasApplicationSearchResults;
    }

    private _hasInvoiceSearchResults: boolean;
    public get hasInvoiceSearchResults(): boolean {
        return this._hasInvoiceSearchResults;
    }

    private _hasQuoteSearchResults: boolean;
    public get hasQuoteSearchResults(): boolean {
        return this._hasQuoteSearchResults;
    }

    private _hasActivitySearchResults: boolean;
    public get hasActivitySearchResults(): boolean {
        return this._hasActivitySearchResults;
    }

    public filterCustomerSearchResultType(result: SearchResultMatch) {
        return !result.bondId && !result.invoiceNumber && !result.applicationNumber && !result.quoteNumber && !result.activityId;
    }

    public filterBondSearchResultType(result: SearchResultMatch) {
        return !!result.bondId
    }

    public filterInvoiceSearchResultType(result: SearchResultMatch) {
        return !!result.invoiceNumber;
    }

    public filterApplicationSearchResultType(result: SearchResultMatch) {
        return !!result.applicationNumber;
    }

    public filterQuoteSearchResultType(result: SearchResultMatch) {
        return !!result.quoteNumber;
    }

    public filterActivitySearchResultType(result: SearchResultMatch) {
        return !!result.activityId;
    }

    public search() {
        if (!this.searchPhrase) {
            this.closeSearch();
            return;
        }

        this.isSearching = true;
        this.searchResults = null;
        this.isError = false;
        this._hasBondSearchResults = false;
        this._hasApplicationSearchResults = false;
        this._hasInvoiceSearchResults = false;
        this._hasQuoteSearchResults = false;
        this._hasCustomerSearchResults = false;
        this._hasActivitySearchResults = false;

        this.customersService
            .search(this.searchPhrase)
            .then((results) => {
                this.searchResults = results;    
                this.showSearchResults = true;
                this.isSearching = false;

                for(let i = 0; i<this.searchResults.length; i++){
                    if (this.filterBondSearchResultType(this.searchResults[i])) {
                        this._hasBondSearchResults = true;
                    }
                    if (this.filterApplicationSearchResultType(this.searchResults[i])) {
                        this._hasApplicationSearchResults = true;
                    }
                    if (this.filterInvoiceSearchResultType(this.searchResults[i])) {
                        this._hasInvoiceSearchResults = true;
                    }
                    if (this.filterQuoteSearchResultType(this.searchResults[i])) {
                        this._hasQuoteSearchResults = true;
                    }
                    if (this.filterCustomerSearchResultType(this.searchResults[i])) {
                        this._hasCustomerSearchResults = true;
                    }
                    if (this.filterActivitySearchResultType(this.searchResults[i])) {
                        this._hasActivitySearchResults = true;
                    }
                }
            })
            .catch(() => {
                this.searchResults = null;    
                this.showSearchResults = true;
                this.isSearching = false;
                this.isError = true;
            });
    }

    public handleResultClick(result: SearchResultMatch) {
        if (result.invoiceId) {
            this.$state.go('main.customerDetail', { customerId: result.customerId, sectionView: 'invoices' });
        } else if (result.applicationNumber) {
            this.$state.go('main.customerDetail', { customerId: result.customerId, sectionView: 'applications' });
        } else if (result.quoteNumber) {
            this.$state.go('main.customerDetail', { customerId: result.customerId, sectionView: 'applications' });
        } else if (result.bondNumber) {
            this.$state.go('main.customerDetail', { customerId: result.customerId, sectionView: 'bonds' });
        } else if (result.activityId) {
            this.modalOpener.showActivityModal(result.activityId);
        } else if (result.customerId) {
            this.$state.go('main.customerDetail', { customerId: result.customerId, sectionView: '' });
        }

        this.clearSearch();
    }

    public $onInit(): void {
    }
}

const globalSearchComponent = {
    bindings: {},
    controller: GlobalSearchController,
    controllerAs: 'vm',
    templateUrl: 'app/components/globalSearch/globalSearch.html'
};

app.component('globalSearch', globalSearchComponent);
