import { ActivityService } from "../../api/activityService";
import { ActivityType } from "../../api/types/activities/activityType";
import { SelectOption } from "../../api/types/selectOption";
import { Injectables } from "../../configuration/injectables";
import { Table } from "../../utilities/tables/table";
import { ActivityListItem } from "../activityList/activityListItem";
import { ActivityTableQueryOptions } from "../activityList/activityTableQueryOptions";
import { PriorityLevelOptions } from "../../api/types/activities/activityPriorityLevelOptions";
import app from "../../main";

class ActivityListFilterDropdownController {
    
    public static $inject = [
        Injectables.ActivityService
    ];

    constructor(private readonly activityService: ActivityService) {
        this.modifiedFilters = {} as ActivityTableQueryOptions;
    }

    public isOpen: boolean;
    public filterCount: number = 0;
    public modifiedFilters: ActivityTableQueryOptions;
    public activityTypeOptions: SelectOption[];
    public daysTillDue: number;
    public lowPriorityLevel: number;
    public highPriorityLevel: number;
    public priorityLevelOptions: SelectOption<number>[];
    
    public table: Table<ActivityListItem, ActivityTableQueryOptions>;

    public clearFilters() {            
        this.modifiedFilters.createdByUserId = null;
        this.modifiedFilters.assignedToUserId = null;
        this.modifiedFilters.status = null;
        this.modifiedFilters.lookupCode = null;
        this.modifiedFilters.isFollowed = null;
        this.modifiedFilters.activityTypeId = null;
        this.modifiedFilters.daysTillDue = null;
        this.modifiedFilters.lowPriorityLevel = null;
        this.modifiedFilters.highPriorityLevel = null;

        this.table.applyFilters({...this.modifiedFilters});
        
        this.setFilterCount();
        this.isOpen = false;
    }

    public cancel() {
        this.isOpen = false;
        this.modifiedFilters = { ...this.table.queryOptions };
    }

    public applyFilters() {
        this.isOpen = false;
        
        this.table.applyFilters({ ...this.modifiedFilters });
        
        this.setFilterCount();
    }

    public setFilterCount() {
        this.filterCount = 0;

        if (this.table.queryOptions.status) {
            this.filterCount++;
        }

        if (this.table.queryOptions.lookupCode) {
            this.filterCount++;
        }

        if (this.table.queryOptions.createdByUserId) {
            this.filterCount++;
        }

        if (this.table.queryOptions.assignedToUserId) {
            this.filterCount++;
        }

        if (this.table.queryOptions.isFollowed || this.table.queryOptions.isFollowed === false) {
            this.filterCount++;
        }

        if (this.table.queryOptions.activityTypeId) {
            this.filterCount++;
        }

        if (this.table.queryOptions.daysTillDue){
            this.filterCount++;
        }

        if (this.table.queryOptions.lowPriorityLevel || this.table.queryOptions.highPriorityLevel){
            this.filterCount++;
        }

    }

    private getActivityTypeOptions() {
        this.activityService.getActivityTypes()
            .then((activityTypes: ActivityType[])=> {
                this.activityTypeOptions = [];

                this.activityTypeOptions.push({ label: 'Any', value: null});

                for(let i = 0; i < activityTypes.length; i++) {
                    this.activityTypeOptions.push({ label: activityTypes[i].name, value: activityTypes[i].id });
                }
            });
    }

    public $onInit() {
        this.priorityLevelOptions = PriorityLevelOptions;
        this.modifiedFilters = { ...this.table.queryOptions };
        this.setFilterCount();
        this.getActivityTypeOptions();
    }
}

const activityListFilterDropdownComponent = {
    bindings: {
        table: '<'
    },
    controller: ActivityListFilterDropdownController,
    controllerAs: 'vm',
    templateUrl: 'app/components/activityListFilterDropdown/activityListFilterDropdown.html'
};

app.component('activityListFilterDropdown', activityListFilterDropdownComponent);