import { Application } from "./application";
import { BondTypeDocument } from "./bondTypeDocument";
import { BondTypeMasterApplicationQuestion } from "./bondTypeMasterApplicationQuestions";
import { BondTypeVariableBondAmount } from "./bondTypeVariableBondAmount";
import { IBaseEntity } from "./iBaseEntity";
import { Obligee } from "./obligee";
import { SfaaCode } from "./sfaaCode";
import { SystemAccountBondTypeFavorite } from "./systemAccountBondTypeFavorite";
import { UnderwritingConfiguration } from "./underwritingConfiguration";

export interface BondType extends IBaseEntity { 
    agencyNotaryAcknowledgementRequired: boolean;
    allowsElectronicSignature: boolean;
    allowsPrincipalElectronicSignature: boolean;
    applications: Application[];
    bondAmount: number;
    bondAmountType: BondAmountTypes;
    bondTypeCode: string;
    systemAccountBondTypeFavorites: SystemAccountBondTypeFavorite[];
    bondTypeDocuments: BondTypeDocument[];
    bondTypeMasterApplicationQuestions: BondTypeMasterApplicationQuestion[];
    bondTypeVariableBondAmounts: BondTypeVariableBondAmount[];
    cancellationNotificationMethodTypes: CancellationNotificationMethodTypes;
    cancellationNotificationRecipientType: CancellationNotificationRecipientTypes;
    cancellationProvisionDays: number;
    companyAddressFieldStatus: FieldStatusType;
    companyCountyFieldStatus: FieldStatusType;
    companyDateFormedFieldStatus: FieldStatusType;
    companyDbaFieldStatus: FieldStatusType;
    companyEmailFieldStatus: FieldStatusType;
    companyFaxFieldStatus: FieldStatusType;
    companyFEINFieldStatus: FieldStatusType;
    companyNameFieldStatus: FieldStatusType;
    companyInformationIsApplicable: boolean;
    companyNumberOfEmployeesFieldStatus: FieldStatusType;
    companyNumberOfOwnersFieldStatus: FieldStatusType;
    companyPhoneFieldStatus: FieldStatusType;
    companyStateOfIncorporationFieldStatus: FieldStatusType;
    companyTypeFieldStatus: FieldStatusType;
    personEmployerNameFieldStatus: FieldStatusType;
    personEmployerPhoneFieldStatus: FieldStatusType;
    personEmployerAddressFieldStatus: FieldStatusType;
    personEmployerCountyFieldStatus: FieldStatusType;
    personEmployerCityFieldStatus: FieldStatusType;
    personEmployerZipFieldStatus: FieldStatusType;
    personEmployerStateFieldStatus: FieldStatusType;
    description: string;
    signatureDateOnBondForm: boolean;
    flaggedForReview: boolean;
    isActive: boolean;
    isCancellable: boolean;
    isPolicy: boolean;
    isRenewable: boolean;
	isElectronic: boolean;
    maximumBondAmount: number;
    minimumBondAmount: number;
    multiplePeopleAllowed: boolean;
    name: string;
    nameOnBondAllowCombinePrincipals: boolean;
    nameOnBondAllowCompanyDBA: boolean;
    nameOnBondAllowCompanyName: boolean;
    nameOnBondAllowFirstLast: boolean;
    nameOnBondAllowFirstMiddleLast: boolean;
    nameOnBondAllowOtherA3: boolean;
    nameOnBondAllowOtherEProducer: boolean;
    nameOnBondInstructions: string;
    obligee: Obligee;
    obligeeCode: string;
    obligeeId: number;
    personAddressFieldStatus: FieldStatusType;
    personCountyFieldStatus: FieldStatusType;
    personCellPhoneFieldStatus: FieldStatusType;
    personDateMovedToResidenceFieldStatus: FieldStatusType;
    personDateOfBirthFieldStatus: FieldStatusType;
    personDriversLicenseNumberFieldStatus: FieldStatusType;
    personDriversLicenseStateFieldStatus: FieldStatusType;
    personFaxFieldStatus: FieldStatusType;
    personGenderFieldStatus: FieldStatusType;
    personHomePhoneFieldStatus: FieldStatusType;
    personJobTitleFieldStatus: FieldStatusType;
    personMaritalStatusFieldStatus: FieldStatusType;
    personMiddleNameFieldStatus: FieldStatusType;
    personResidenceTypeFieldStatus: FieldStatusType;
    personSpouseCellPhoneFieldStatus: FieldStatusType;
    personSpouseDateOfBirthFieldStatus: FieldStatusType;
    personSpouseEmailFieldStatus: FieldStatusType;
    personSpouseGenderFieldStatus: FieldStatusType;
    personSpouseNameFieldStatus: FieldStatusType;
    personSpouseSsnFieldStatus: FieldStatusType;
    personSSNFieldStatus: FieldStatusType;
    personSuffixFieldStatus: FieldStatusType;
    personPrefixFieldStatus: FieldStatusType;
    personYearsExperienceFieldStatus: FieldStatusType;
    principalCorporateSealRequired: boolean;
    principalNotaryAcknowledgementRequired: boolean;
    principalRetainsOriginal: boolean;
    principalSignatureLocationRequired: boolean;
    principalWitnessPrintedNameRequired: boolean;
    principalWitnessSignatureRequired: boolean;
    requiresComplianceCertificate: boolean;
    requiresContinuationCertificate: boolean;
    requiresModifiedEffectiveDate: boolean;
    requiresNewBondUponExpiration: boolean;
    requiresObligeeSpecificContinuationCertificate: boolean;
    requiresSuretyAcknowledgement: boolean;
    requiresVerificationCertificate: boolean;
    reviewAdditionalInformation: boolean;
    reviewAdditionalInformationComments: string;
    reviewApplicationForms: boolean;
    reviewApplicationSettings: boolean;
    reviewApplicationSettingsComments: string;
    reviewAppQuestions: boolean;
    reviewAppQuestionsComments: string;
    reviewBondAmount: boolean;
    reviewBondAmountComments: string;
    reviewBondForms: boolean;
    reviewCancellation: boolean;
    reviewCancellationComments: string;
    reviewComments: string;
    reviewCoverLetters: boolean;
    reviewExpiration: boolean;
    reviewExpirationComments: string;
    reviewObligee: boolean;
    reviewObligeeComments: string;
    reviewApplicationFormsComments: string;
    reviewCoverLettersComments: string;
    reviewRenewal: boolean;
    reviewRenewalComments: string;
    reviewSfaaCode: boolean;
    reviewSfaaCodeComments: string;
    sfaaCode: SfaaCode;
    sfaaCodeId: number;
    statutoryExpirationDateDay: number;
    statutoryExpirationDateMonth: number;
    statutoryExpirationDateType: StatutoryExpirationDateType;
    expirationYearType: ExpirationYearType;
    underwritingConfigurations: UnderwritingConfiguration[];
    id: number;
    createdDateTime: Date;
    $isFavorite: boolean;
}

export enum StatutoryExpirationDateType {	
    None = "None",
    Continuous = "Continuous",
    FixedDate = "FixedDate",
    Birthday = "Birthday"
}
export enum ExpirationYearType {	
    Annual = "Annual",
    EvenYears = "EvenYears",
    OddYears = "OddYears",
    Every2Years = "Every2Years",
    Every3Years = "Every3Years",
    Every4Years = "Every4Years",
    Every5Years = "Every5Years"
}
export enum BondAmountTypes {	
    Fixed = "Fixed",
    Variable = "Variable",
    UserDefined = "UserDefined"
}
export enum CancellationNotificationMethodTypes {	
    StandardMail = "StandardMail",
    CertifiedMail = "CertifiedMail",
    RegisteredMail = "RegisteredMail",
    WrittenNotice = "WrittenNotice"
}
export enum CancellationNotificationRecipientTypes {	
    Obligee = "Obligee",
    ObligeeAndPrincipal = "ObligeeAndPrincipal"
}
export enum FieldStatusType {	
    Hidden = "Hidden",
    Visible = "Visible",
    Required = "Required"
}