import { A3RootScope } from "../../../api/types/a3RootScope";
import { UserGroupService } from "../../../api/userGroupService";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../configuration/injectables";
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { State } from "../../state";
import { GroupsDetailParams } from "./groupsDetailParams";
import { UserGroupDetail } from "./userGroupDetail";

export class GroupsDetailController {
    public static $inject = [
        Injectables.$stateParams,
        Injectables.$state,
        Injectables.CurrentUserResolver,
        Injectables.UserGroupService,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly $stateParams: GroupsDetailParams,
        private readonly $state: State,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly userGroupService: UserGroupService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) { }

    public group: UserGroupDetail;
    public busyIndicator: BusyIndicator;

    public get isCarrier(): boolean {
        return this.currentUserResolver.getCurrentUser().systemAccount.isCarrier;
    }

    public loadData() {
        this.busyIndicator.message = 'Loading...';
        this.busyIndicator.promise = this.userGroupService.getUserGroupDetail(this.$stateParams.id)
            .then((group) => {
                this.group = group;
            });
    }

    public save(): void {
        this.busyIndicator.message = 'Saving...';
        this.busyIndicator.promise = this.userGroupService.saveUserGroup(this.group)
            .then(() => {
                this.$state.go('main.groupsGrid');
                this.toastMessageCreator.createSuccessMessage('User group saved successfully');
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('A group already exists with this name');
            });
    }

    public $onInit(): void {
        this.busyIndicator = {};

        if (this.$stateParams.id) {
            this.loadData();
        } else {
            this.group = { } as UserGroupDetail;
        }
    }
}
