import { IScope, IAttributes, INgModelController, IDirectiveFactory } from "angular";
import * as moment from "moment";
import app from "../../main";

const isDateDirective = () => {

    const link = (
    scope: IScope, 
    element: JQuery, 
    attrs: IAttributes, 
    controller:INgModelController) => { 
        
        const formatStringValue = (date) => {
            return moment.utc(date, moment.ISO_8601);
        }

        const formatDateValue = (date) => {
            return formatStringValue(date.toISOString());
        }
    
        var inputFormat = 'MM/DD/YYYY';
        var inputFormatRegex = /^\d{2}\/\d{2}\/\d{4}$/;

        var serverDateOnlyFormat = 'YYYY-MM-DD';
        var serverDateOnlyFormatRegex = /^\d{4}-\d{2}-\d{2}$/;

        const parse = (viewValue: string) => {
            if (viewValue === '' || !viewValue.match(inputFormatRegex)) {
                controller.$setValidity('validDate', true);
                return null;
            }

            var date = moment.utc(viewValue, inputFormat);

            if (date && date.isValid()) {
                controller.$setValidity('validDate', true);
                return date.toDate();
            } else {
                controller.$setValidity('validDate', false);
                return viewValue;
            }
        }

        controller.$parsers.push(parse);
        
        const format = (modelValue: string) => {
            if (!modelValue) {
                return modelValue;
            }

            let date;

            // When working with the model from the client, date objects are used. However,
            // when loading objects from the API, the dates are converted into JSON strings
            // and never parsed into actual JavaScript date objects. Therefore, the parser
            // must be able to handle either scenario.

            if (typeof modelValue === 'string') {
                date = formatStringValue(modelValue);
            } else {
                date = formatDateValue(modelValue);
            }

            if (date.isValid()) {
                return date.format(inputFormat);
            } else {
                return modelValue;
            }
        }

        controller.$formatters.push(format);
    };

    return {
        require: 'ngModel',
        link: link
    };
}   

app.directive('isDate', isDateDirective as IDirectiveFactory);  