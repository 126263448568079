import { ODataFactory, ODataQuery } from "../../../api/odata";
import { Injectables } from "../../../configuration/injectables";

export class ApplicationQuestionsGridController {

    public static $inject = [
        Injectables.ODataFactory
    ];

    constructor(
        private readonly odataService: ODataFactory) {
    }

    public search: string;
    public gridQuery: ODataQuery;
    public filterFocused: boolean;
    
    public $onInit = () => {
        this.filterFocused = true;
    
        this.gridQuery = this.odataService.getQuery();
        this.gridQuery.filter('isSubQuestion eq false');
    }
}
