import { City } from "./city";
import { DateEmployed } from "./dateEmployed";
import { DateEmploymentEnded } from "./dateEmploymentEnded";
import { VerificationDate } from "./verificationDate";

export interface USEmployment { 
    occupation: string;
    employer: string;
    city: City;
    state: string;
    dateEmployed: DateEmployed;
    verificationDate: VerificationDate;
    dateEmploymentEnded: DateEmploymentEnded;
    code: string;
    description: string;
}