import { IBaseEntity } from "./iBaseEntity";
import { ReleaseNote } from "./releaseNote";

    export interface Release extends IBaseEntity { 
        description: string;
        releaseDateTime: Date;
        releaseNotes: ReleaseNote[];
        usersNotified: boolean;
        version: string;
        id: number;
        createdDateTime: Date;
    }
	
	export enum ReleaseNoteType {	
		Feature = "Feature",
		BugFix = "BugFix"
	}