import { EProducerFormField } from "./eProducerFormField";

export interface EProducerFormStep { 
	stepType: EProducerStepType;
	header: string;
	fields: EProducerFormField[];
}

export enum EProducerStepType {	
	Preliminary = "Preliminary",
	People = "People",
	Company = "Company",
	AdditionalQuestions = "AdditionalQuestions",
	BondInfo = "BondInfo",
	ObligeeInfo = "ObligeeInfo"
}
