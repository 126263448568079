import { IBaseEntity } from "./iBaseEntity";
import { MasterApplicationQuestion } from "./masterApplicationQuestion";

export interface MasterApplicationQuestionChoice extends IBaseEntity { 
    text: string;
    masterApplicationQuestionId: number;
    masterApplicationQuestion: MasterApplicationQuestion;
    sequence: number;
    id: number;
    createdDateTime: Date;
}