import { Document } from "../../api/types/model/document";


export class NewBondTransactionDocument {
    constructor(file: globalThis.File | Document) {
        if (file instanceof globalThis.File) {
            this.file = file;
            this.fileName = file.name;
            this.documentCategoryId = null;
        } else {
            this.document = file;
            this.fileName = file.name;
            this.documentCategoryId = file.documentCategoryId;
        }
    }

    public document: Document;
    public file: globalThis.File;
    public fileName: string;
    public documentCategoryId: number;
}
