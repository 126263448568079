import { IScope, IAttributes, INgModelController, IDirectiveFactory } from "angular";
import app from "../../main";

const isSelectDirective = () => {

    const link = (
        scope: IScope, 
        elm: JQuery, 
        attrs: IAttributes, 
        ctrl: INgModelController) => {

        if ('isString' in attrs) {
            return;
        }

        ctrl.$parsers.unshift(function(viewValue) {
            var numericValue = parseInt(viewValue.replace(/\,/g, ''));

            if (isNaN(numericValue))
                return viewValue;
            else
                return numericValue;

        });
        
        ctrl.$formatters.unshift(function (modelValue) {
            return modelValue != null ? '' + modelValue : null;
        });
    } 
    
    return {
        require: 'ngModel',
        link: link
    };
}

app.directive('isSelect', isSelectDirective as IDirectiveFactory);