import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { ModalOpener } from "../../modals/modalOpener";
import { RelatedCustomerCardData } from "./relatedCustomerCardData";

class RelatedCustomerCardController {

    public static $inject = [
        Injectables.ModalOpener
    ];

    constructor(private readonly modalOpener: ModalOpener) {}

    private customer: RelatedCustomerCardData;
    private onChange: () => void;

    public showCustomerRelationshipDetails() {
        this.modalOpener.showCustomerRelaionshipsModal(this.customer.id)
            .result
            .then((relationshipChanged) => {
                if (relationshipChanged && typeof this.onChange === 'function') {
                    this.onChange();
                }
            })
            .catch(() => {});
    }

    public $onInit() {}
}

const relatedCustomerCard = {
    bindings: {
        customer: '<',
        onChange: '&?'
    },
    templateUrl: 'app/components/relatedCustomerCard/relatedCustomerCard.html',
    controller: RelatedCustomerCardController,
    controllerAs: 'vm'
};

app.component('relatedCustomerCard', relatedCustomerCard);