import { Company } from "./company";
import { IBaseEntity } from "./iBaseEntity";
import { Person } from "./person";
import { QuestionResponse } from "./questionResponse";

    export interface BondProfileBase extends IBaseEntity { 
        people: Person[];
        companies: Company[];
        questionResponses: QuestionResponse[];
        bondNumber: string;
        nameOnBond: string;
        bondAmount: number;
        expirationDate: Date;
        effectiveDate: Date;
        amountAsText: string;
        bondAmountAsText: string;
        amountAsTextNumbersOnly: string;
        amount: number;
        id: number;
        createdDateTime: Date;
    }