import { Injectables } from "../configuration/injectables";
import { ODataFactory, ODataEndpoint } from "./odata";
import { CancelBondDto } from "./types/cancelBondDto";
import { GetRefundAmountDto } from "./types/getRefundAmountDto";
import { CancellationRequest } from "./types/model/cancellationRequest";
import { ProratedRefund } from "./types/proratedRefund";
import { ProrationMethod } from "./types/prorationMethod";
import { RefundType } from "./types/refundType";
import { RequestCancellationDto } from "./types/requestCancellationDto";
import { SelectOption } from "./types/selectOption";
import { UpdateCancellationRequestDto } from "./types/updateCancellationRequestDto";
import A3ApiResponse from "./types/a3ApiResponse";
import app from "../main";
import { IHttpService, IQService, IPromise } from "angular";
import { SystemSettings } from "../configuration/settings/systemSettings";
import { Fee } from "./types/model/fee";

export class CancellationRequestService {
    public static $inject = [
        Injectables.$http,
        Injectables.ODataFactory,
        Injectables.$q,
        Injectables.SystemSettings
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly odata: ODataFactory,
        private readonly $q: IQService,
        private readonly systemSettings: SystemSettings
    ) {}


    public getRefundTypeOptions(): SelectOption<RefundType>[] {
        return [
            {
                value: RefundType.None,
                label: "No Returned Premium"
            } as SelectOption<RefundType>,
            {
                value: RefundType.ProRata,
                label: "Pro Rata"
            } as SelectOption<RefundType>,
            {
                value: RefundType.Manual,
                label: "Manual Calculation"
            } as SelectOption<RefundType>,
            {
                value: RefundType.Full,
                label: "Flat Cancel (Return Full Premium)"
            } as SelectOption<RefundType>
        ];
    }

    public cancelBondByCarrier(
        request: CancellationRequest,
        cancellationReasonId: number
    ): IPromise<void> {

        const requestDto: CancelBondDto = {
            cancellationReasonId: cancellationReasonId,
            carrierComments: request.carrierComments,
            commissionReduction: request.commissionReduction,
            effectiveDate: request.effectiveDate,
            id: request.id,
            refundAmount: request.premiumRefund
        };

        return this.$http
            .post(
                this.systemSettings.apiBaseUrl + "CancellationRequestActions/CancelBond",
                requestDto
            )
            .then(() => {});
    }

    public getCancellationRefundAmounts(
        bondId: number,
        cancellationDate: Date,
        refundType: RefundType
    ): IPromise<ProratedRefund> {
        if (
            refundType === RefundType.Manual ||
            refundType === RefundType.None
        ) {
            return this.$q.when({
                premium: 0.0,
                commission: 0.0
            } as ProratedRefund);
        }

        const getRefundAmountDto: GetRefundAmountDto = {
            bondId: bondId,
            cancellationDate: cancellationDate,
            prorationMethod:
                refundType === RefundType.Full
                    ? ProrationMethod.Full
                    : ProrationMethod.ProRata
        };

        return this.$http
            .post<A3ApiResponse<ProratedRefund>>(
                this.systemSettings.apiBaseUrl + "CancellationRequestActions/GetRefundAmount",
                getRefundAmountDto
            )
            .then((response) => response.data.value);
    }

    public getCancellationRequest(bondId: number): IPromise<CancellationRequest> {

        const svc = this.odata.getService<CancellationRequest>(ODataEndpoint.CancellationRequest);

        svc.query
            .filter(`bondId eq ${bondId}`)
            .top(1)
            .expand("user($expand=systemAccount($select=phone,companyName)),fees")
            .orderby("createdDateTime desc");

        return svc.get()
            .then((response) => {
                return response.data.value[0];
            });
    }

    public requestCancellation(
        bondId: number,
        effectiveDate: Date,
        comments: string,
        attorneyInFactUserId: number,
        fees: Fee[]
    ): IPromise<number> {
        
        const request: RequestCancellationDto = {
            attorneyInFactUserId: attorneyInFactUserId,
            bondId: bondId,
            comments: comments,
            effectiveDate: effectiveDate,
            fees: fees
        };

        return this.$http
            .post<A3ApiResponse<number>>(
                this.systemSettings.apiBaseUrl + "CancellationRequestActions/RequestCancellation", 
                request
            )
            .then((response) => {
                return response.data.value;
            });
    }

    public rescindCancellationRequest(requestId: number): IPromise<void> {

        return this.$http
            .post(
                this.systemSettings.apiBaseUrl + "CancellationRequestActions/RescindCancellationRequest",
                { id: requestId }
            )
            .then(() => {});
    }

    public updateCancellationRequest(
        requestId: number,
        requestedCancellationDate: Date,
        comments: string,
        attorneyInFactUserId: number,
        fees: Fee[]
    ): IPromise<void> {

        const request: UpdateCancellationRequestDto = {
            attorneyInFactUserId: attorneyInFactUserId,
            comments: comments,
            requestedCancellationDate: requestedCancellationDate,
            fees: fees,
            id: requestId
        };

        return this.$http
            .post(
                this.systemSettings.apiBaseUrl + "CancellationRequestActions/UpdateCancellationRequest",
                request
            )
            .then(() => {});
    }
}

app.service(Injectables.CancellationRequestService, CancellationRequestService);
