import { IQService } from "angular";
import app from "../../main";
import { LocalStorageService } from "../../utilities/localStorage/localStorageService";
import { Injectables } from "../injectables";
import { SystemSettings } from "../settings/systemSettings";

// http://bitoftech.net/2014/06/09/angularjs-token-authentication-using-asp-net-web-api-2-owin-asp-net-identity/
const authInterceptorService = (
        $q: IQService, 
        $injector: any, 
        localStorageService: LocalStorageService,
        systemSettings: SystemSettings
    ) => {
    
    return {
        request: function(config) {
            config.headers = config.headers || {};

            const authData = localStorageService.getAuthenticationData();

            if (authData) {
                config.headers.Authorization = 'Bearer ' + authData.token;
            }

            return config;
        },
        responseError: function(rejection) {
            const authData = localStorageService.getAuthenticationData();
            const isSingleSignOn = !!authData && authData.isSingleSignOn;

            if (rejection.status === 401) {
                localStorageService.removeAuthenticationData();
                
                let state = $injector.get('$state');
                let stateParams = $injector.get('$stateParams');

                if (!isSingleSignOn) {
                    if (state.current.name !== 'login') {
                        localStorageService.setA3RedirectData({ stateName: state.current.name, params: stateParams });
                    }
                    
                    state.go('login');

                } else {
                    window.location = systemSettings.ajgSsoLoginUrl as Location | (string & Location);
                }
            }
            
            return $q.reject(rejection);
        }
    };
}

authInterceptorService.$inject = [
    Injectables.$q, 
    Injectables.$injector,
    Injectables.LocalStorageService,
    Injectables.SystemSettings
];

app.factory('authInterceptorService', authInterceptorService);

app.config(['$httpProvider', ($httpProvider) => {
    $httpProvider.interceptors.push('authInterceptorService');
}]);