import { IFilterService } from "angular";
import { ODataFilter } from ".";

export default class ODataFilterCollection {

    constructor(
        public filters: ODataFilter[], 
        private $filter: IFilterService) { 
    }

    public push(filter: ODataFilter): void {
        this.filters.push(filter);
    }

    public mergeFilters(newFilters: ODataFilter[]): void {
        for (const newFilter of newFilters) {
            this.mergeFilter(newFilter);
        }
    }

    public mergeFilter(filterData: ODataFilter): void {

        const newFilter = new ODataFilter(
            filterData.navigationExpression, 
            filterData.dataType, 
            filterData.label, 
            filterData.value, 
            filterData.maxValue, 
            filterData.isVisible, 
            filterData.selectOptions, 
            filterData.isString, 
            filterData.comparisonText, 
            this.$filter
        );

        // if filter with matching expression already exists then replace it
        for (let i = 0; i < this.filters.length; i++) {
            if (newFilter.navigationExpression === this.filters[i].navigationExpression &&
                newFilter.dataType === this.filters[i].dataType) {
                this.filters[i] = newFilter;
                return;
            }
        }

        // if filter isn't already in colletion then add it as a new
        this.filters.push(newFilter);
    }

    public splice(index: number, count: number): void {
        this.filters.splice(index, count);
    }

    public resetFilter(index: number): void {
        this.filters[index].reset();
    }

    public resetAllFilters(): void {
        for(let i = 0; i < this.filters.length; i++) {
            this.filters[i].reset();
        }
    }

    public getEnabledFilters(): any[] {
        const enabledFilters = [];

        for(let i = 0; i < this.filters.length; i++) {
            if (this.filters[i].getIsEnabled()) {
                enabledFilters.push(this.filters[i]);
            }
        }

        return enabledFilters;
    }

    public getFilterExpression(): string {
        let filterString = '';

        for(let i = 0; i < this.filters.length; i++) {
            if (this.filters[i].getIsEnabled()) {
                filterString += `(${this.filters[i].getFilterExpression()}) and `;
            }
        }
        
        return filterString.substring(0, filterString.lastIndexOf(' and '));
    }

    public toString(): string {
        let filterString = '';

        for(let i = 0; i < this.filters.length; i++) {
            if (this.filters[i].getIsEnabled()) {
                filterString += `(${this.filters[i].toString()}) and `;
            }
        }

        return filterString.substring(0, filterString.lastIndexOf(' and '));
    }

    public getIsEnabled(): boolean {
        let enabled = false;

        for(let i = 0; i < this.filters.length; i++) {
            if (this.filters[i].getIsEnabled()) { 
                enabled = true; 
            }
        }
        
        return enabled;
    }

    public getActiveFilterCount(): number {
        let count = 0;

        for(let i = 0; i < this.filters.length; i++) {
            if (this.filters[i].getIsEnabled()) { 
                count++; 
            }
        }

        return count;
    }
}