import { IPromise, ITimeoutService } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { IDebounceDelayer } from "./iDebouceDelayer";

    
export class DebouceDelayer implements IDebounceDelayer {
    
    public static $inject = [
        Injectables.$timeout
    ];

    constructor(
        private readonly $timeout: ITimeoutService
    ) {}

    private timeoutPromise: IPromise<void>;

    public delay = (callback: () => void, delayMilliseconds: number) => {
        if (this.timeoutPromise) {
            this.$timeout.cancel(this.timeoutPromise);
        }

        this.timeoutPromise = this.$timeout(callback, delayMilliseconds);
    }
}

app.service(Injectables.IDebouceDelayer, DebouceDelayer);