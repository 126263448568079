import * as luxon from "luxon";
import app from "../../main";

export const localDateTimeFilter = () => {

    return (dateTimeString: string, format: string) => {
        if (!dateTimeString) { 
            return ''; 
        }

        const dateTime = luxon.DateTime.fromISO(dateTimeString);

        if (!dateTime.isValid) {
            console.error('Unable to parse local date time filter because', dateTime.invalidExplanation);
            return '';
        }
        
        if (dateTime.toUTC().year == 1) {
            return '';
        }

        if (format === 'twoLine') {
            const dateFormatOptions: Intl.DateTimeFormatOptions = {...luxon.DateTime.DATE_SHORT};
            const timeFormatOptions: Intl.DateTimeFormatOptions = {...luxon.DateTime.TIME_WITH_SHORT_OFFSET};
    
            return `
                ${dateTime.toLocaleString(dateFormatOptions)}
                <br/>
                ${dateTime.toLocaleString(timeFormatOptions)}
            `;
            
        } else {

            const dateTimeFormatOptions: Intl.DateTimeFormatOptions = {
                ...luxon.DateTime.DATE_SHORT,
                ...luxon.DateTime.TIME_WITH_SHORT_OFFSET
            };
    
            return dateTime.toLocaleString(dateTimeFormatOptions);
        }
    };
}

app.filter('localDateTime', localDateTimeFilter);