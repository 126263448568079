import { Application } from "./application";
import { Attachment } from "./attachment";
import { BillToType } from "../billToType";
import { Bond } from "./bond";
import { Tag } from "./tag";
import { CompanyType } from "./company";
import { Customer } from "./customer";
import { EProducerUser } from "./eProducerUser";
import { IBaseEntity } from "./iBaseEntity";
import { Invoice } from "./invoice";
import { Lead } from "./lead";
import { SystemAccount } from "./systemAccount";
import { Person } from "./person";

export interface EProducerAccount extends IBaseEntity { 
    allowDeferredPayment: boolean;
    applications: Application[];
    bonds: Bond[];
    companyType: CompanyType;
    contact1: string;
    contact2: string;
    contact3: string;
    customers: Customer[];
    eoPolicyExpirationDate: Date;
    eoPolicyNumber: string;
    eProducerAccountType: EProducerAccountType;
    attachments: Attachment[];
    eProducerUsers: EProducerUser[];
    fax: string;
    isApproved: boolean;
    isLLC: boolean;
    isLocked: boolean;
    mailAddress: string;
    mailCity: string;
    mailState: string;
    mailSuiteAptNumber: string;
    mailZip: string;
    name: string;
    phone: string;
    physicalAddress: string;
    physicalCity: string;
    physicalState: string;
    physicalSuiteAptNumber: string;
    physicalZip: string;
    referredBy: string;
    requires1099: boolean;
    agencyCode: string;
    agencySubCode: string;
    isBrokerVisibleToCarrier: boolean;
    systemAccount: SystemAccount;
    systemAccountId: number;
    tags: Tag[];
    leads: Lead[];
    invoices: Invoice[];
    people: Person[];
    id: number;
    createdDateTime: Date;
    lookupCode: string;
    commissionCalculationType: CommissionCalculationType;
    commission: number;
    billToType: BillToType;
    eProducerAccountBrokerCategoryId : number ;
}

export enum EProducerAccountType {	
    Applicant = "Applicant",
    Broker = "Broker"
}

export enum CommissionCalculationType {
    PercentOfCommission = 1,
    PercentOfPremium = 2
}

export interface eProducerSummary {
    id: number;
    lookupCode: string;
    commissionCalculationType: CommissionCalculationType;
    commission: number;
    billToType: BillToType;
}