import { BondStatus, RenewalStatus } from "../../api/types/model/bond";
import app from "../../main";
import { Table } from "../../utilities/tables/table";
import { BondListTableQueryOptions } from "../bondList/bondListTableQueryOptions";
import { BondListItem } from "./bondListItem";


class BondListFilterDropdownController {
    public static $inject = [];

    constructor() {
        this.modifiedFilters = {} as BondListTableQueryOptions;
    }

    public isOpen: boolean;
    public filterCount: number = 0;
    public bondStatusFilter: string;
    public modifiedFilters: BondListTableQueryOptions;
    
    public table: Table<BondListItem, BondListTableQueryOptions>;

    public clearFilters() {
        this.bondStatusFilter = null;
        this.modifiedFilters.effectiveStartDate = null;
        this.modifiedFilters.effectiveEndDate = null;
        this.modifiedFilters.expirationStartDate = null;
        this.modifiedFilters.expirationEndDate = null;
        this.modifiedFilters.status = null;
        this.modifiedFilters.renewalStatus = null;

        this.table.applyFilters({...this.modifiedFilters});
        
        this.setFilterCount();
        this.isOpen = false;
    }

    public cancel() {
        this.isOpen = false;
        this.modifiedFilters = { ...this.table.queryOptions };
    }

    public applyFilters() {
        this.isOpen = false;

        if (this.bondStatusFilter === BondStatus.Active) {
            this.modifiedFilters.status = BondStatus.Active;
            this.modifiedFilters.renewalStatus = null;
        } else if (this.bondStatusFilter === BondStatus.Active + '|' + RenewalStatus.None) {
            this.modifiedFilters.status = BondStatus.Active;
            this.modifiedFilters.renewalStatus = RenewalStatus.None;
        } else if (this.bondStatusFilter === BondStatus.Active + '|' + RenewalStatus.ApplicationSubmitted) {
            this.modifiedFilters.status = BondStatus.Active;
            this.modifiedFilters.renewalStatus = RenewalStatus.ApplicationSubmitted;
        } else if (this.bondStatusFilter === BondStatus.PendingCancellation) {
            this.modifiedFilters.status = BondStatus.PendingCancellation;
            this.modifiedFilters.renewalStatus = null;
        } else if (this.bondStatusFilter === BondStatus.PendingReinstatement) {
            this.modifiedFilters.status = BondStatus.PendingReinstatement;
            this.modifiedFilters.renewalStatus = null;
        } else if (this.bondStatusFilter === BondStatus.Cancelled) {
            this.modifiedFilters.status = BondStatus.Cancelled;
            this.modifiedFilters.renewalStatus = null;
        } else if (this.bondStatusFilter === BondStatus.Expired) {
            this.modifiedFilters.status = BondStatus.Expired;
            this.modifiedFilters.renewalStatus = null;
        } else {
            this.modifiedFilters.status = null;
            this.modifiedFilters.renewalStatus = null;
        }

        this.table.applyFilters({ ...this.modifiedFilters });
        
        this.setFilterCount();
    }

    public setFilterCount() {
        this.filterCount = 0;

        if (this.table.queryOptions.effectiveStartDate || this.table.queryOptions.effectiveEndDate) {
            this.filterCount++;
        }

        if (this.table.queryOptions.expirationStartDate || this.table.queryOptions.expirationEndDate) {
            this.filterCount++;
        }

        if (this.table.queryOptions.status || this.table.queryOptions.renewalStatus) {
            this.filterCount++;
        }
    }

    private setBondStatusFilter() {
        if (this.modifiedFilters.status === BondStatus.Active && 
            (this.modifiedFilters.renewalStatus === null || this.modifiedFilters.renewalStatus === undefined)){

            this.bondStatusFilter = BondStatus.Active;

        } else if (this.modifiedFilters.status === BondStatus.Active && 
                    this.modifiedFilters.renewalStatus === RenewalStatus.None) {

            this.bondStatusFilter = BondStatus.Active + '|' + RenewalStatus.None;

        } else if (this.modifiedFilters.status === BondStatus.Active && 
                    this.modifiedFilters.renewalStatus === RenewalStatus.ApplicationSubmitted) {

            this.bondStatusFilter = BondStatus.Active + '|' + RenewalStatus.ApplicationSubmitted;

        } else if (this.modifiedFilters.status === BondStatus.PendingCancellation) {
            this.bondStatusFilter = BondStatus.PendingCancellation;
        } else if (this.modifiedFilters.status === BondStatus.PendingReinstatement) {
            this.bondStatusFilter = BondStatus.PendingReinstatement;
        } else if (this.modifiedFilters.status === BondStatus.Cancelled) {
            this.bondStatusFilter = BondStatus.Cancelled;
        } else if (this.modifiedFilters.status === BondStatus.Expired) {
            this.bondStatusFilter = BondStatus.Expired;
        } else {
            this.bondStatusFilter = null;
        }
    }

    public $onInit() {
        this.modifiedFilters = { ...this.table.queryOptions };
        this.setBondStatusFilter();
        this.setFilterCount();
    }
}

const bondListFilterComponent = {
    bindings: {
        table: '<'
    },
    controller: BondListFilterDropdownController,
    controllerAs: 'vm',
    templateUrl: 'app/components/bondListFilterDropdown/bondListFilterDropdown.html'
};

app.component('bondListFilterDropdown', bondListFilterComponent);