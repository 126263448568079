import { RateType } from "./rate";
import { RateTier } from "./rateTier";
import { WritingCompany } from "./writingCompany";
import { Company } from "./company";
import { Fee } from "./fee";
import { Obligee } from "./obligee";
import { Person } from "./person";
import { QuestionResponse } from "./questionResponse";
import { CommissionType } from "./commissionType";

export interface IssuingBond {
    id: number ;
    obligee: IssuingBondObligee;
    writingCompanyId: number;
    commissionPercent: number;
    commissionFixedAmount?: number;
    bondTypeId: number;
    bondAmount: number;
    effectiveDate: Date;
    expirationDate: Date;
    nameOnBond: string;
    bondNumberGroupId: number;
    attorneyInFactUserId: number;
    customerId: number;
    company: Company;
    people: Person[];
    fees: Fee[];
    premium: number;
    questionResponses: QuestionResponse[];
    producerUserId: number;
    clientServiceExecutiveUserId: number;
    clientServiceManagerUserId: number;
    clientServiceAgentUserId: number;
    bondAccountId?: number;
    fixedRate: number;
    ratePerThousand: number;
    rateType: RateType;
    minimumPremium: number;
    rateTiers: RateTier[];
    $newThreshold: number;
    $tierAdding: boolean;
    $newTierRate: number;
    $index: number;
    commissionType: CommissionType;
}

export interface IssuingBondObligee {
    comments: string;
    contact1: string;
    contact2: string;
    contact3: string;
    email: string;
    fax: string;
    isGeneric: boolean;
    mailAddress: string;
    mailCity: string;
    mailCounty: string;
    mailState: string;
    mailSuiteAptNumber: string;
    mailZip: string;
    masterObligee: Obligee;
    masterObligeeId: number;
    name: string;
    phone: string;
    physicalAddress: string;
    physicalCity: string;
    physicalCounty: string;
    physicalState: string;
    physicalSuiteAptNumber: string;
    physicalZip: string;
    requisitioningDepartment: string;
    requisitioningState: string;
    websiteURL: string;
}
