export default (
    element: JQuery, 
    labelText: string, 
    isRequired: boolean) => {
        
    var dropdownCaret = element.prop('tagName') === 'SELECT' ? '<i class="fa fa-caret-down"></i>' : '';

    var requiredIndicator = isRequired ? ' <span class="validation-indicator">*</span>' : '';
    var placeholderRequiredIndicator = isRequired ? ' *' : '';

    element
        .parent()
        .parent()
        .append('<div class="label">' + labelText + ' ' + dropdownCaret + ' ' + requiredIndicator + '</div>');

    element.attr('placeholder', labelText + placeholderRequiredIndicator);
};