import { IDirectiveFactory, IDirectiveLinkFn, IScope } from "angular";
import app from "../../main";
import Inputmask from "inputmask";

const integerMaskDirective: IDirectiveFactory = () => {

    const link: IDirectiveLinkFn = (
        scope: IScope,
        elem: JQuery
    ) => {
        const mask = new Inputmask('decimal', { 
            radixPoint: '.',  
            groupSeparator: ',',  
            allowMinus: false, 
            digits: 0, 
            rightAlign: false 
        });

        mask.mask(elem);
    }

    return {
        link: link
    }
}

app.directive('maskInteger', integerMaskDirective);
