import { SelectOption } from "../../api/types/selectOption";
import { Injectables } from "../../configuration/injectables";
import { Table } from "../../utilities/tables/table";
import { AttachmentTableListItem } from "../attachmentList/attachmentTableListItem";
import { AttachmentTableQueryOptions } from "../attachmentList/attachmentTableQueryOptions";
import app from "../../main";

class AttachmentListFilterDropdownController {
    
    public static $inject = [
        Injectables.ActivityService
    ];

    constructor(private readonly activityService) {
        this.modifiedFilters = {} as AttachmentTableQueryOptions;
    }

    public isOpen: boolean;
    public filterCount: number = 0;
    public modifiedFilters: AttachmentTableQueryOptions;
    public attachmentTypeOptions: SelectOption[];
    
    public table: Table<AttachmentTableListItem, AttachmentTableQueryOptions>;

    public clearFilters() {            
        this.modifiedFilters.attachmentTypeId = null;
        this.modifiedFilters.isSharedWithCarrier = null;
        this.modifiedFilters.isSharedWithAgent = null;
        this.modifiedFilters.isSharedThroughEProducer = null;

        this.table.applyFilters({...this.modifiedFilters});
        
        this.setFilterCount();
        this.isOpen = false;
    }

    public cancel() {
        this.isOpen = false;
        this.modifiedFilters = { ...this.table.queryOptions };
    }

    public applyFilters() {
        this.isOpen = false;
        
        this.table.applyFilters({ ...this.modifiedFilters });
        
        this.setFilterCount();
    }

    public getAttachmentTypeOptions() {
        this.activityService.getAttachmentTypes()
            .then((attachmentTypes) => {
                this.attachmentTypeOptions = [];

                for(var i = 0; i < attachmentTypes.length; i++) {
                    this.attachmentTypeOptions.push({ label: attachmentTypes[i].name, value: attachmentTypes[i].id });
                }
            });
    }

    public setFilterCount() {
        this.filterCount = 0;

        if (this.table.queryOptions.attachmentTypeId) {
            this.filterCount++;
        }
        
        if (this.table.queryOptions.isSharedWithCarrier || this.table.queryOptions.isSharedWithCarrier === false) {
            this.filterCount++;
        }
        
        if (this.table.queryOptions.isSharedWithAgent || this.table.queryOptions.isSharedWithAgent === false) {
            this.filterCount++;
        }
        
        if (this.table.queryOptions.isSharedThroughEProducer || this.table.queryOptions.isSharedThroughEProducer === false) {
            this.filterCount++;
        }
    }

    public $onInit() {
        this.modifiedFilters = { ...this.table.queryOptions };
        this.setFilterCount();
        this.getAttachmentTypeOptions();
    }
}

const attachmentListFilterDropdownComponent = {
    bindings: {
        table: '<'
    },
    controller: AttachmentListFilterDropdownController,
    controllerAs: 'vm',
    templateUrl: 'app/components/attachmentListFilterDropdown/attachmentListFilterDropdown.html'
};

app.component('attachmentListFilterDropdown', attachmentListFilterDropdownComponent);