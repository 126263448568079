import * as angular from "angular";
import { IScope, IAttributes, ITemplateLinkingFunction, IDirectiveFactory } from "angular";
import app from "../../main";

const detailsTemplate = () => {

    const link = (
        scope: IScope, 
        element: JQuery, 
        attrs: IAttributes, 
        parentController: any, 
        linker:ITemplateLinkingFunction) => {

        linker(scope, (clone) => {

            var tempEl = angular.element('<span></span>');
            tempEl.append(clone);

            (scope.$parent as any).addDetailTemplate(tempEl.html());
        });
    }

    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        require: '^boundGrid',
        link: link,
        template: '<span></span>'
    }
}

app.directive('detailsTemplate', detailsTemplate as IDirectiveFactory);