import { Attachment } from "../attachment";
import { Quote } from "./quote";
import { RiderRequest } from "./riderRequest";
import { Tag } from "./tag";
import { SystemType } from "./application";
import { BondAccount } from "./bondAccount";
import { BondAdditionalQuestionResponse } from "./bondAdditionalQuestionResponse";
import { BondCorrection } from "./bondCorrection";
import { BondTransaction } from "./bondTransaction";
import { BondType } from "./bondType";
import { CancellationRequest, CancellationStatus } from "./cancellationRequest";
import { Company } from "./company";
import { Customer } from "./customer";
import { EProducerAccount } from "./eProducerAccount";
import { IBaseEntity } from "./iBaseEntity";
import { Memo } from "./memo";
import { Obligee } from "./obligee";
import { Person } from "./person";
import { QuestionResponse } from "./questionResponse";
import { ReinstatementRequest, ReinstatementStatus } from "./reinstatementRequest";
import { RiderStatus } from "./riderRequest";
import { SystemAccount } from "./systemAccount";
import { WritingCompany } from "./writingCompany";

export interface Bond extends IBaseEntity { 
    carrierSystemAccountId: number;
    eProducerAccountId: number;
    eProducerAccount: EProducerAccount;
    ams360PolicyId: string;
    bondAccount: BondAccount;
    bondAccountId: number;
    bondAdditionalQuestionResponses: BondAdditionalQuestionResponse[];
    bondTransactions: BondTransaction[];
    attachments: Attachment[];
    bondType: BondType;
    bondTypeId: number;
    cancellationDate: Date;
    cancellationRequests: CancellationRequest[];
    cancellationStatus: CancellationStatus;
    carrierSystemAccount: SystemAccount;
    customer: Customer;
    customerId: number;
    originSystem: SystemType;
    quote: Quote;
    quoteId: number;
    reinstatementDate: Date;
    reinstatementRequests: ReinstatementRequest[];
    memos: Memo[];
    reinstatementStatus: ReinstatementStatus;
    riderRequests: RiderRequest[];
    bondCorrections: BondCorrection[];
    riderStatus: RiderStatus;
    status: BondStatus;
    writingCompany: WritingCompany;
    writingCompanyId: number;
    tags: Tag[];
    obligeeId: number;
    obligee: Obligee;
    renewalStatus: RenewalStatus;
    
    people: Person[];
    companies: Company[];
    questionResponses: QuestionResponse[];
    bondNumber: string;
    nameOnBond: string;
    bondAmount: number;
    expirationDate: Date;
    effectiveDate: Date;
    amountAsText: string;
    bondAmountAsText: string;
    amountAsTextNumbersOnly: string;
    amount: number;
    
    id: number;
    createdDateTime: Date;
}

export enum BondStatus {	
    Active = "Active",
    Cancelled = "Cancelled",
    PendingCancellation = "PendingCancellation",
    PendingReinstatement = "PendingReinstatement",
    Expired = "Expired"
}
export enum RenewalStatus {	
    None = "None",
    ApplicationSubmitted = "ApplicationSubmitted"
}