import { ExportToEpicStatus } from "../../api/types/customerDetail";
import { TransactionType } from "../../api/types/model/bondTransaction";
import BondTransactionListItemDetails from "./bondTransactionListItemDetails";

export interface BondTransactionListItem {
    id: number;
    bondId: number;
    isExportedToAms360: boolean;
    createdDateTime: Date;
    transactionType: TransactionType;
    transactionEffectiveDate: Date;
    expirationDate: Date;
    premium: number;
    refundedPremium: number;
    fees: number;
    commission: number;
    exportToEpicStatus: ExportToEpicStatus;
    details: BondTransactionListItemDetails;
}