import { Application } from "./application";
import { IBaseEntity } from "./iBaseEntity";
import { SystemAccount } from "./systemAccount";

export interface DeclinedApplication extends IBaseEntity { 
    carrierSystemAccountId: number;
    applicationId: number;
    comments: string;
    systemAccount: SystemAccount;
    application: Application;
    adverseActionRequired: boolean;
    adverseActionDownloadedDate: Date;
    adverseActionDownloadedByUserId: number;
    hasAdverseActionBeenDownloaded: boolean;
    id: number;
    createdDateTime: Date;
}