import { Tag } from "./tag";
import { ApplicationQuestionResponse } from "./model/applicationQuestionResponse";
import { IBaseEntity } from "./model/iBaseEntity";
import { CreditReportPersonReport } from "./model/creditReportPersonReport";

export interface ApplicationDetail extends IBaseEntity { 
    id: number;
    applicationType: ApplicationType;
    applicationNumber: number;
    obligeeId: number;
    bondId: number;
    bondTypeId: number;
    bondTypeName: string;
    sfaaCode: string;
    sfaaCodeDescription: string;
    status: ApplicationStatus;
    customerId: number;
    nameOnBond: string;
    comments: string;
    bondAmount: number;
    desiredEffectiveDate: Date;
    tags: Tag[];
    createdDateTime: Date;
    declinedApplicationComment: string;
    approvedQuoteCount: number;
    pendingQuoteCount: number;
    isAdverseActionByCurrentCarrier: number;
    isDeclinedByCurrentCarrier: number;
    systemAccountId: number;
    agencyCompanyName: string;
    agencyPhysicalAddress: string;
    agencyPhysicalSuiteAptNumber: string;
    agencyPhysicalState: string;
    agencyPhysicalCity: string;
    agencyPhysicalCounty: string;
    agencyPhysicalZip: string;
    applicationQuestionResponses: ApplicationQuestionResponse[];
    creditReportPersonReport: CreditReportPersonReport[] ;
}

export enum ApplicationFilterStatus {
    NotSubmitted = "NotSubmitted",
    Submitted = "Submitted",
    Approved = "Approved",
    ManualReview = "ManualReview"
}

export enum ApplicationType {	
    BondAccount = "BondAccount",
    SingleBond = "SingleBond",
    SingleBondRenewal = "SingleBondRenewal"
}
export enum ApplicationStatus {	
    NotSubmitted = "NotSubmitted",
    Submitted = "Submitted",
    Closed = "Closed",
    Completed = "Completed"
}
export enum SystemType {	
    Unknown = "Unknown",
    EProducer = "EProducer",
    A3Dashboard = "A3Dashboard"
}