import { Bond } from "./bond";
import { BondChange } from "./model/bondChange";
import { Fee } from "./model/fee";
import { RiderStatus } from "./model/riderRequest";
import { SystemAccount } from "./model/systemAccount";

export class RiderRequest {

    agencySystemAccountId: number;
    carrierSystemAccountId: number;
    agencyComments: string;
    agencySystemAccount: SystemAccount;
    attorneyInFactUserId: number;
    bond: Bond;
    bondId: number;
    carrierComments: string;
    carrierSystemAccount: SystemAccount;
    commissionChange: number;
    effectiveDate: Date;
    fees: Fee[];
    premiumChange: number;
    reasonCode: string;
    reasonDescription: string;
    requestedEffectiveDate: Date;
    riderRequestChanges: BondChange[];
    changes: BondChange[];
    status: RiderStatus;
    id: number;
    createdDateTime: Date;
    requestedByEmail: string;
    requestedByUser: string;
    requestedByPhone: string;
    reasonId?: number;
}