import { Injectables } from "../../configuration/injectables";
import app from "../../main";

    
export class GuidCreator {

    public static $inject = [] as string[];

    constructor(){}

    /**
     * Creates a new GUID as a string
     */
    public create(): string {
        return this.guidPart() + 
            this.guidPart() + '-' + 
            this.guidPart() + '-' + 
            this.guidPart() + '-' + 
            this.guidPart() + '-' + 
            this.guidPart() + 
            this.guidPart() + 
            this.guidPart();
    }

    public guidPart(): string {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
}

app.service(Injectables.GuidCreator, GuidCreator);
