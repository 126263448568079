import { UserService } from "../../api/userService";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { SearchControl } from "../../utilities/searchControl";
import { UserSearchResult } from "./userSearchResult";

export class UserSearchDropdownController {

    public static $inject = [
        Injectables.UserService
    ];
    
    constructor(
        private readonly userService: UserService
    ) {}
    
    private previousSearchPhrase: string = null;
    
    public label: string;
    public searchControl: SearchControl<UserSearchResult>;
    public onSelect: () => void;

    public search = (searchPhrase) => {
        if (this.previousSearchPhrase === searchPhrase) {
            return;
        }

        this.previousSearchPhrase = searchPhrase;

        return this.userService.searchUsers(searchPhrase)
            .then((users) => {
                this.searchControl.matches = users;

                return users;
            });
    }

    public handleOnSelect = (item?: UserSearchResult) => {
        this.searchControl.selected = item;

        if (typeof this.onSelect === 'function') {
            this.onSelect();
        }
    }

    public clear() {
        this.searchControl.selected = null;
        this.handleOnSelect();
    }

    public $onInit() {
        this.searchControl = new SearchControl()
        this.searchControl.label = this.label;
        this.searchControl.emptyStateMessage = 'No matching users found';
        this.searchControl.placeholder = 'Search Users...';
        this.searchControl.name = 'userSearch'
        this.searchControl.onSearch = this.search;
    }
}

const userSearchDropdownComponent = {
    bindings: {
        label: '@?',
        searchControl: '=?',
        onSelect: '&?'
    },
    controller: UserSearchDropdownController,
    controllerAs: 'vm',
    templateUrl: 'app/components/userSearchDropdown/userSearchDropdown.html',
};

app.component('userSearchDropdown', userSearchDropdownComponent);
