import { PaymentMethod } from "../../../api/types/model/paymentTransaction";
import { PaymentStatus } from "../../../api/types/payments/paymentStatus";

export interface PaymentReportItem {
    id: number;
    createdDateTime: Date;
    amount: number;
    eProducerAccountId?: number;
    customerId?: number;
    paymentStatus: PaymentStatus;
    transactionId: string;
    referenceNumber: string;
    referenceNumbers: string[];
    paymentTransactionFee: number;
    firstName: string;
    lastName: string;
    companyName: string;
    streetAddress: string;
    city: string;
    state: string;
    zipCode: string;
    phone: string;
    email: string;
    paymentMethod: PaymentMethod;
    accountNumber: string;
    responseCode: string;
    message: string;
    isNotFullyApplied?: boolean;
    paymentInstitutionName: string;
    customerName: string;
    customerLookupCode: string;
    eProducerAccountName: string;
    brokerLookupCode: string;
    bonds: PaymentReportBond[];
    invoices: PaymentReportInvoice[];
}

export interface PaymentReportBond
{
    id: number;
    bondNumber: string;
}

export interface PaymentReportInvoice
{
    id: number;
    invoiceNumber: string;
}