import { SelectOption } from "../../api/types/selectOption";
import app from "../../main";
import isDefined from "../../utilities/angularUtilities/isDefined";

class FeeCodeDropdownController {
    
    public static $inject: string[] = [];

    constructor() { }

    public isDisabled: boolean;
    public feeCode: string;
    public isRequired: boolean;
    public options: SelectOption<string>[];
    public id: string;
    public aaLabelStrategy: string;
    public aaFgClass: string;
    
    public $onInit(): void {
        this.options = [
            { label: 'Agency Fee', value: 'AFEE' },
            { label: 'Carrier Fee', value: 'CFEE' },
            { label: 'Tax/Surcharge (Carrier paid)', value: 'SRGX' },
        ];

        if (!this.aaLabelStrategy) {
            this.aaLabelStrategy = 'default'
        }

        if (!this.aaFgClass) {
            this.aaFgClass = ''
        }
    }
}

const feeCodeDropdownDirective = () => {
    return {
        restrict: 'E',
        templateUrl: 'app/components/feeCodeDropdown/feeCodeDropdown.html',
        scope: {},
        transclude: true,
        bindToController: {
            feeCode: '=',
            aaLabelStrategy: '@?',
            aaFgClass: '@?'
        },
        link: (scope, elm, attrs, ctrl: any) => {
            if (isDefined(attrs.required)) {
                ctrl.isRequired = true;
            }

            if (isDefined(attrs.ngRequired)) {
                ctrl.isRequired = scope.$parent.$eval(attrs.ngRequired);
            }

            if (isDefined(attrs.id)) {
                ctrl.id = attrs.id;
                elm.removeAttr('id');
            }

            if (isDefined(attrs.disabled)) {
                ctrl.isDisabled = true;
            }
        },
        controller: FeeCodeDropdownController,
        controllerAs: 'vm'
    };
};

app.directive('feeCodeDropdown', feeCodeDropdownDirective);