export interface ActivityHistoryListItem {
    activityId: number;
    createdDateTime: Date;
    createdByUserId: number;
    createdByUserFullName: string;
    notes: ActivityHistoryListItemNote[];
}

export interface ActivityHistoryListItemNote {
    id: number;
    note: string;
}