import { AutoFee } from "./autoFee";
import { BondType } from "./bondType";
import { IBaseEntity } from "./iBaseEntity";

export interface AutoFeeBondType extends IBaseEntity { 
    bondTypeId: number;
    autoFeeId: number;
    autoFee: AutoFee;
    bondType: BondType;
    id: number;
    createdDateTime: Date;
}