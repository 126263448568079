import { Injectables } from "../configuration/injectables";
import { ODataFactory, ODataEndpoint } from "./odata";
import { AddBondTransactionDocumentVersionDto } from "./types/applicationAccessEmail/addBondTransactionDocumentVersionDto";
import { DocumentStatus } from "./types/model/document";
import { DocumentCategory } from "./types/model/documentCategory";
import { PageResponse } from "./types/pageResponse";
import { RemoveBondTransactionDocumentVersionDto } from "./types/removeBondTransactionDocumentVersionDto";
import { SelectOption } from "./types/selectOption";
import { Document } from "./types/model/document";
import A3ApiResponse from "./types/a3ApiResponse";
import app from "../main";
import { IHttpService, IPromise } from "angular";
import { SystemSettings } from "../configuration/settings/systemSettings";
import DocumentTableQueryOptions from "../states/common/documentLibrary/documentFilterDropdown/documentTableQueryOptions";
import DocumentListItem from "../states/common/documentLibrary/documentListItem";
import { QuoteDocumentOption } from "../modals/quoteDocumentsModal/quoteDocumentsModal";
import DocumentDetails from "./types/documents/documentDetails";
import DocumentSearchResult from "./types/documents/documentSearchResult";
import { UtilityService } from "../utilities/utilityService";
import { CacheStore } from "./cacheStore";

export class DocumentService {
    public static $inject = [
        Injectables.$http,
        Injectables.ODataFactory,
        Injectables.SystemSettings,
        Injectables.UtilityService,
        Injectables.CacheStore
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly odata: ODataFactory,
        private readonly systemSettings: SystemSettings,
        private readonly utilityService: UtilityService,
        private readonly cacheStore: CacheStore
    ) {}

    public downloadDocumentForViewing = (url: string) => {
        return this.$http.get(url, { responseType: 'blob' })
            .then((response) => {
                return URL.createObjectURL(response.data as Blob)
            }) 
    }

    public loadQuoteDocumentOptions(): IPromise<QuoteDocumentOption[]> {
        const url = `${this.systemSettings.apiBaseUrl}DocumentActions/GetQuoteDocumentOptions`;

        return this.$http
            .get<A3ApiResponse<QuoteDocumentOption[]>>(url)
            .then((response) => response.data.value);
    }

    public getDocumentThumbnails(documentId: number) {
        const url = `${this.systemSettings.apiBaseUrl}documentLibrary/GetDocumentThumbnail?documentId=${documentId}`;
        return this.$http
            .get<string>(url)
            .then((response) => response.data);
    }

    public getDocumentUrl(documentId: number) {
        return `${this.systemSettings.apiBaseUrl}documentLibrary/DownloadDocument?documentId=${documentId}`;
    }

    public getSampleBondDocumentUrl(documentId: number) {
        return `${this.systemSettings.apiBaseUrl}DataDocument/GetSampleBondDocument?documentId=${documentId}`;
    }

    public rateDocument(documentId: number, rating: number) {
        const documentOdataService = this.odata.getService<Document>(
            ODataEndpoint.Document
        );

        documentOdataService
            .entityAction(
                documentId,
                "A3.Rate",
                JSON.stringify({ rating: rating })
            )
            .then(() => {});
    }

    public getDocuments = (tableQueryOptions: DocumentTableQueryOptions): IPromise<PageResponse<DocumentListItem>> => {

        if (!tableQueryOptions) {
            tableQueryOptions = {
                pageNumber: 1,
                recordsPerPage: 10,
                orderBy: "",
                searchPhrase: ""
            } as DocumentTableQueryOptions;
        }

        if (!tableQueryOptions.searchPhrase) {
            tableQueryOptions.searchPhrase = "";
        }

        if (!tableQueryOptions.orderBy) {
            tableQueryOptions.orderBy = "id desc";
        }

        if (!tableQueryOptions.pageNumber) {
            tableQueryOptions.pageNumber = 1;
        }

        if (!tableQueryOptions.recordsPerPage) {
            tableQueryOptions.recordsPerPage = 10;
        }

        let queryString = "?";

        queryString += `&pageNumber=${tableQueryOptions.pageNumber}`;
        queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
        queryString += `&orderBy=${tableQueryOptions.orderBy}`;
        queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;

        if (tableQueryOptions.status) {
            queryString += `&status=${tableQueryOptions.status}`;
        }

        if (tableQueryOptions.minimumCreatedDateTime) {
            queryString += `&minimumCreatedDateTime=${tableQueryOptions.minimumCreatedDateTime}`;
        }

        if (tableQueryOptions.maximumCreatedDateTime) {
            queryString += `&maximumCreatedDateTime=${tableQueryOptions.maximumCreatedDateTime}`;
        }

        if (tableQueryOptions.minimumLastModifiedDateTime) {
            queryString += `&minimumLastModifiedDateTime=${tableQueryOptions.minimumLastModifiedDateTime}`;
        }

        if (tableQueryOptions.maximumLastModifiedDateTime) {
            queryString += `&maximumLastModifiedDateTime=${tableQueryOptions.maximumLastModifiedDateTime}`;
        }

        if (tableQueryOptions.bondType) {
            queryString += `&bondType=${tableQueryOptions.bondType}`;
        }

        if (tableQueryOptions.documentCategoryId) {
            queryString += `&documentCategoryId=${tableQueryOptions.documentCategoryId}`;
        }

        return this.$http.get<A3ApiResponse<PageResponse<DocumentListItem>>>(
                this.systemSettings.apiBaseUrl +
                "DocumentActions/GetDocumentListPage" + queryString
            )
            .then((response) => response.data.value);
    }

    public deleteDocument = (id: number) => {
        const url = `${this.systemSettings.apiBaseUrl}documentLibrary/DeleteDocument?documentId=${id}`;
        return this.$http.delete(url);
    };

    public saveDocumentDetails(documentDetails: DocumentDetails): IPromise<void> {
        const url = `${this.systemSettings.apiBaseUrl}documentActions/SaveDocumentDetails`;
    
        return this.$http.post(url, documentDetails)
            .then(() => {});
    }

    public downloadQuoteDocuments(quoteId: number, selectedDocumentVersionIds: number[], addAttachment: boolean) {
        
        let url = `${this.systemSettings.apiBaseUrl}DataDocument/GetQuoteDocument?`;
        url += `quoteId=${quoteId}`;
        url += `&addAttachment=${addAttachment ? 'true' : 'false'}`;

        for (let i = 0; i < selectedDocumentVersionIds.length; i++) {
            url += `&documentVersionIds=${selectedDocumentVersionIds[i]}`;
        }

        return this.$http
            .get(url, { responseType: "arraybuffer" })
            .then((response) => {
                this.utilityService.processDocumentDownloadResponse(response);
            });
    }

    public getDocumentDetails(documentId: number): IPromise<DocumentDetails> {
        const url = `${this.systemSettings.apiBaseUrl}documentActions/GetDocumentDetails?documentId=${documentId}`;
            
        return this.$http
            .get<A3ApiResponse<DocumentDetails>>(url)
            .then((response) => {
                return response.data.value;
            });
    }

    public addNewDocumentToTransactionPacket(
        bondTransactionId: number,
        documentId: number
    ): IPromise<void> {
        const dto = {
            bondTransactionId: bondTransactionId,
            documentId: documentId
        } as AddBondTransactionDocumentVersionDto;

        return this.$http
            .post(
                this.systemSettings.apiBaseUrl +
                    "BondTransactionActions/AddBondTransactionDocumentVersion",
                dto
            )
            .then(() => {});
    }

    public currentBondFormCount(type: DocumentStatus): IPromise<number> {
        const svc = this.odata.getService<Document>(ODataEndpoint.Document);

        svc.query.filter("documentCategory/name eq 'Bond Form'");

        if (type && (type as string) !== "All") {
            svc.query.and(
                `documentCategory/name eq 'Bond Form' and status eq '${type}'`
            );
        }

        return svc.count().then((response) => response.data);
    }

    public searchDocuments(keyword = "", categoryId?: number): IPromise<DocumentSearchResult[]> {
        let url = `${this.systemSettings.apiBaseUrl}DocumentActions/SearchDocuments?name=${keyword}&documentCategoryId=${categoryId})`;
        
        return this.$http
            .get<A3ApiResponse<DocumentSearchResult[]>>(url)
            .then((response) => response.data.value);
    }

    public getCategoryOptions(): IPromise<SelectOption<number>[]> {
        return this.getDocumentCategories().then(
            (categories) => {
                const options: SelectOption[] = [];

                for (const category of categories) {
                    options.push({
                        label: category.name,
                        value: category.id
                    });
                }

                return options;
            }
        );
    }

    public getDocumentCategories(): IPromise<DocumentCategory[]> {
        const url = `${this.systemSettings.apiBaseUrl}DocumentActions/GetDocumentCategories`;

        return this.cacheStore.getData(url, () => {
            return this.$http.get<A3ApiResponse<DocumentCategory>>(url)
                .then((response) => response.data.value);
        });
    }

    public removeDocumentFromTransactionPacket(btdvId: number): IPromise<void> {
        const dto = {
            bondTransactionDocumentVersionId: btdvId
        } as RemoveBondTransactionDocumentVersionDto;

        return this.$http
            .post(
                this.systemSettings.apiBaseUrl +
                    "BondTransactionActions/RemoveBondTransactionDocumentVersion",
                dto
            )
            .then(() => {});
    }
}

app.service(Injectables.DocumentService, DocumentService);
