import { IScope, IAttributes } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { JQueryService } from "../../utilities/jquery/jQueryService";

class FileSelectorController {
    public static $inject = [];
    
    constructor(
    ) {}

    public buttonText: string;
    public fileInput: HTMLInputElement;
    private fileReader: FileReader;
    private onFileSelected: (file: File) => void;
    private onFileDialogOpened: () => void;
    private onFileRead: (fileDataUrl: string) => void;

    public buttonClicked() {
        this.fileInput.value = '';
        this.fileInput.click();
        
        if (typeof this.onFileDialogOpened === 'function') {
            this.onFileDialogOpened();
        }
    }

    public fileInputChanged = (ev: Event) => {
        var file = ev.target['files'][0];
        this.fileReader = new FileReader();
        this.fileReader.onload = this.fileReadComplete;
        this.fileReader.readAsDataURL(file);
        
        if (typeof this.onFileSelected === 'function') {
            this.onFileSelected(file);
        }
    }

    private fileReadComplete = (ev: Event) => {
        if (typeof this.onFileRead === 'function') {
            this.onFileRead(this.fileReader.result as string);
        }
    }

    public $onInit() {}
}

const fileSelectorDirective = () => {
 
    const link = (
        scope: IScope, 
        elem: JQuery, 
        attr: IAttributes, 
        ctrl: FileSelectorController) => {

        ctrl.fileInput = elem.find('input')['0'] as HTMLInputElement;
        ctrl.fileInput.onchange = ctrl.fileInputChanged;
    };

    return {
        bindToController: {
            onFileSelected: '<?',
            onFileDialogOpened: '<?',
            onFileRead: '<?',
            fileTypes: '@?',
            class: '@',
        },
        transclude: true,
        controller: FileSelectorController,
        controllerAs: 'vm',
        replace: true,
        restrict: 'E',
        scope: {},
        link: link,
        template: '<span class="file-selector">' +
                    '<input type="file" style="display: none;" accept="{{vm.fileTypes}}"/>' +
                    '<button ' +
                    '   class="{{vm.class}}" ' +
                    '   ng-click="vm.buttonClicked()">' +
                    '' +
                    '   <ng-transclude></ng-transclude>' + 
                    '</button>' +
                '</span>'
    }
};

app.directive('fileSelector', fileSelectorDirective);