import { ObligeeService } from "../../api/obligeeService";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { IDebounceDelayer } from "../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../utilities/tables/table";
import { ObligeeHistoryListItem } from "./obligeeHistoryListItem";
import { ObligeeHistoryTableQueryOptions } from "./obligeeHistoryTableQueryOptions";
import app from "../../main";

export class ObligeeHistoryModalController {
    
    public static $inject = [
        Injectables.Options,
        Injectables.$uibModalInstance,
        Injectables.ObligeeService,
        Injectables.IDebouceDelayer
    ];

    constructor(
        private readonly options:ObligeeHistoryTableQueryOptions,
        private readonly $uibModalInstance: Modal<void>,        
        private readonly obligeeService: ObligeeService,
        debouceDelayer: IDebounceDelayer         
    ) {
        this.obligeeHistoryTable = new Table(debouceDelayer);
        this.obligeeHistoryTable.onChange = this.loadHistory;
    }

    public readonly obligeeHistoryTable: Table<ObligeeHistoryListItem, ObligeeHistoryTableQueryOptions>;
    public busyIndicator: BusyIndicator;  

    private loadHistory = () => {
        this.busyIndicator.promise = this.obligeeService.getObligeeHistoryListItems(this.obligeeHistoryTable.queryOptions)
            .then((response) => {
                this.obligeeHistoryTable.setData(response.items, response.totalRecordCount);
            });
    }

    public close(): void {
        this.$uibModalInstance.dismiss('close');
    }

    public $onInit() { 
        this.obligeeHistoryTable.queryOptions.obligeeId = this.options.obligeeId; 
        this.busyIndicator = {
            message: "Loading History..."
        };
        this.loadHistory();        
    }
}

app.controller('ObligeeHistoryModalController', ObligeeHistoryModalController);