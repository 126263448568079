export interface EpicCustomerSearchResult {
    clientId: number;
    clientLookupCode: string;
    name: string;
}

export interface EpicCustomerConnect {
    clientId: number;
    clientLookupCode: string;
    name: string;
    customerId: number;
} 