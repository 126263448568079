import { RenewalStatus, BondStatus } from "../../api/types/model/bond";
import { CancellationStatus } from "../../api/types/model/cancellationRequest";
import { ReinstatementStatus } from "../../api/types/model/reinstatementRequest";
import { RiderStatus } from "../../api/types/model/riderRequest";
import { Injectables } from "../../configuration/injectables";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";
import { DateOnlyFilter } from "../dateOnly/dateOnlyFilterType";
import app from "../../main";
import { ISCEService, IFilterService } from "angular";
import { BondStatusFilterable } from "./bondStatusFilterable";

export const bondStatusFilter = (
    $sce: ISCEService, 
    $filter: IFilterService, 
    currentUserResolver: CurrentUserResolver) => {

    return (object: BondStatusFilterable) => {
        if (!object) {
            return '';
        }

        let status = '';
        
        if (object.status === BondStatus.Active) {
            status = `
                <span 
                    id="bond-status-active" 
                    class="green">
                    
                    Active
                </span>`;
        }

        if (object.status === BondStatus.Cancelled) {
            status = `
                <span 
                    id="bond-status-cancelled" 
                    class="red">
                    
                    Cancelled ${$filter<DateOnlyFilter>('dateOnly')(object.cancellationDate)}
                </span>`;
        }

        if (object.status === BondStatus.PendingCancellation) {
            status = `
                <span 
                    id="bond-status-pending-cancellation" 
                    class="red">
                    
                    Cancelling on ${$filter<DateOnlyFilter>('dateOnly')(object.cancellationDate)}
                </span>`;
        }

        if (object.status === BondStatus.PendingReinstatement) {
            status = `
                <span 
                    id="bond-status-pending-reinstatement" 
                    class="orange">
                    
                    Will reinstate on ${$filter<DateOnlyFilter>('dateOnly')(object.reinstatementDate)}
                </span>`;
        }

        if (object.cancellationStatus === CancellationStatus.Requested) {
            status += `
                <span 
                    id="bond-status-canceallation-requested" 
                    class="red">
                    
                    - Cancellation Requested
                </span>`;
        }

        if (object.riderStatus === RiderStatus.Requested) {
            status += `
                <span 
                    id="bond-status-rider-requested" 
                    class="red">
                    
                    - Rider Requested
                </span>`;
        }

        if (object.reinstatementStatus === ReinstatementStatus.Requested) {
            status += `
                <span 
                    id="bond-status-reinstatement-requested" 
                    class="red">
                    
                    - Reinstatement Requested
                </span>`;
        }

        if (object.status === BondStatus.Expired) {
            status += `
                <span 
                    id="bond-status-expired" 
                    class="orange">
                    
                    Expired
                </span>`;
        }

        if (object.renewalStatus === RenewalStatus.ApplicationSubmitted) {
            status += `
                <b 
                    id="bond-status-renewal-application-submitted" 
                    class="red">
                    
                    - Renewal Started
                </b>`;
        }

        if (object.allowPrincipalAssignment && !currentUserResolver.getCurrentUser().systemAccount.isCarrier) {
            status += `
                <i 
                    id="bond-status-allows-principal-assignment" 
                    class="red fa fa-exclamation-triangle left-margin" 
                    uib-tooltip="Unlocked to allow principal assignment">
                </i>`;
        }

        return $sce.trustAsHtml(status);
    };
}

bondStatusFilter.$inject = [
    Injectables.$sce, 
    Injectables.$filter, 
    Injectables.CurrentUserResolver
];

app.filter('bondStatus', bondStatusFilter);