import { BondType } from "./bondType";
import { IBaseEntity } from "./iBaseEntity";
import { MasterApplicationQuestion } from "./masterApplicationQuestion";

export interface BondTypeMasterApplicationQuestion extends IBaseEntity { 
    bondTypeId: number;
    masterApplicationQuestionId: number;
    isRequired: boolean;
    bondType: BondType;
    masterApplicationQuestion: MasterApplicationQuestion;
    id: number;
    createdDateTime: Date;
}