import { IScope } from "angular";
import { Injectables } from "../../../../../configuration/injectables";
import app from "../../../../../main";
import { DashboardService } from "../../dashboardService";
import { Dashboard } from "../../types/dashboard";
import { DashboardWidget } from "../../types/dashboardWidget";

export class ValueWidgetController {

    public static $inject = [
        Injectables.DashboardService,
        Injectables.$scope
    ];

    constructor(
        private readonly dashboardService: DashboardService,
        $scope: IScope
    ) {
        this.widgetIndex = ($scope.$parent as any).$widgetIndex;
    }

    public widgetTitle: string;
    public widgetIndex: number;
    public widget: DashboardWidget;
    public editMode: boolean;

    public get dashboards(): Dashboard[] {
        return this.dashboardService.dashboardConfiguration.dashboards;
    }

    public toggleWidgetTitleFormBg = () => {
        this.editMode = (this.editMode) ? false : true;
    }
    
    public moveWidget(target: number) {
        this.dashboardService.moveWidgetToTargetDashboard(this.widgetIndex, target);
    }

    public setTitle(title: string) {
        this.widget.options.title = title;
        this.dashboardService.save();
    }
    

    public $onInit(): void {
        this.editMode = false;
        this.widgetTitle = this.widget.options.title;
    }
}

const valueWidgetDirective = () => {
    return {
        bindToController: {
            removeWidget: '&', 
            widget: '=', 
            cloneWidget: '&'
        },
        controller: ValueWidgetController,
        controllerAs: 'vm',
        replace: true,
        restrict: 'E',
        transclude: true,
        scope: {},
        templateUrl: 'app/states/common/dashboard/widgetBases/valueWidget/valueWidget.html',
    };
}

app.directive('valueWidget', valueWidgetDirective);