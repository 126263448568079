import { Injectables } from "../../../configuration/injectables";
import { BillingEntryService } from "../../../api/billingEntryService";
import app from "../../../main";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";

export class ReportMonthEndController {

    public static $inject = [
        Injectables.BillingEntries,
       
    ];
 
    public startDate: Date;
    public endDate: Date;

    public busyIndicator: BusyIndicator;
    
    constructor(
        private readonly billingEntriesService: BillingEntryService,

    ) { }

    public exportMonthEndEntries(){
        this.busyIndicator = {
            message: "Loading...",
            promise: this.billingEntriesService.downloadExcelMonthEndReport(this.startDate, this.endDate)
        };
    }
    public $onInit(): void {
        const now = new Date();
        this.busyIndicator = {};

        this.startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        this.endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    }
   
}
    