import { ApplicationFollowUpStrategy } from "./applicationFollowUpStrategy";
import { IBaseEntity } from "./iBaseEntity";
import { SfaaCode } from "./sfaaCode";

export interface ApplicationFollowUpStrategiesSfaaCodes extends IBaseEntity { 
    sfaaCodeId: number;
    applicationFollowUpStrategyId: number;
    sfaaCode: SfaaCode;
    applicationFollowUpStrategy: ApplicationFollowUpStrategy;
    id: number;
    createdDateTime: Date;
}