import { ITimeoutService } from "angular";
import {
    ODataFactory,
    ODataFilterCollection,
    ODataFilterDataType,
    ODataEndpoint
} from "../../../../../api/odata";
import { TagService } from "../../../../../api/tagService";
import isDefined from "../../../../../utilities/angularUtilities/isDefined";
import { Lead } from "../../../../../api/types/model/lead";
import { SelectOption } from "../../../../../api/types/selectOption";
import { BusyIndicator } from "../../../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../../../configuration/injectables";
import app from "../../../../../main";
import { ModalOpener } from "../../../../../modals/modalOpener";
import { DashboardService } from "../../dashboardService";
import { DashboardWidget } from "../../types/dashboardWidget";
import { Table } from "../../../../../utilities/tables/table";
import { SfaaCodeService } from "../../../../../api/sfaaCodeService";
import A3ApiResponse from "../../../../../api/types/a3ApiResponse";
import { IDebounceDelayer } from "../../../../../utilities/debounceDelayer/iDebouceDelayer";

class DashboardWidgetLeadsController {
    public static $inject = [
        Injectables.ODataFactory,
        Injectables.DashboardService,
        Injectables.$timeout,
        Injectables.ModalOpener,
        Injectables.TagService,
        Injectables.SfaaCodeService,
        Injectables.IDebouceDelayer
    ];

    constructor(
        private readonly odata: ODataFactory,
        private readonly dashboardService: DashboardService,
        private readonly $timeout: ITimeoutService,
        private readonly modalOpener: ModalOpener,
        private readonly tagService: TagService,
        private readonly sfaaCodeService: SfaaCodeService,
        debounceDelayer: IDebounceDelayer
    ) {
        this.table = new Table(debounceDelayer);
    }

    public widget: DashboardWidget;
    public busyIndicator: BusyIndicator;
    public table: Table<Lead, null>;
    public filterCollection: ODataFilterCollection;
    public errorMessage: string;
    public sfaaCodeOptions: SelectOption<string>[];
    public tagOptions: SelectOption<string>[];
    public carrierOptions: SelectOption<number>[];

    public saveRecordsPerPage = () => {
        this.widget.options.recordsPerPage = this.table.pager.recordsPerPage;
        this.dashboardService.save();
    };

    public saveOrderby = () => {
        this.widget.options.orderby = this.table.sorter.sortBy;
        this.dashboardService.save();
    };

    public loadSfaaCodes = () => {
        return this.sfaaCodeService.getAllSfaaCodeOptions().then((options) => {
            this.sfaaCodeOptions = options;
        });
    };

    public loadTagOptions = () => {
        return this.tagService.loadTagOptions().then((tagOptions) => {
            this.tagOptions = tagOptions;
        });
    };

    public setFilters = () => {
        this.filterCollection = this.odata.getFilterCollection([
            this.odata.getFilter(
                "leadType",
                ODataFilterDataType.select,
                "Lead Type",
                null,
                null,
                true,
                [
                    { label: "Inquiry", value: "'Inquiry'" },
                    { label: "Prospect", value: "'Prospect'" },
                    { label: "Submission", value: "'Submission'" }
                ],
                false
            ),
            this.odata.getFilter(
                "bondAmount",
                ODataFilterDataType.money,
                "Bond Amount"
            ),
            this.odata.getFilter(
                "lowestPremium",
                ODataFilterDataType.money,
                "Lowest Quote"
            ),
            this.odata.getFilter(
                "highestPremium",
                ODataFilterDataType.money,
                "Highest Quote"
            ),
            this.odata.getFilter(
                "createdDateTime",
                ODataFilterDataType.date,
                "Submitted"
            ),
            this.odata.getFilter(
                "bondType/name",
                ODataFilterDataType.string,
                "Bond Type"
            ),
            this.odata.getFilter(
                "people/any(p:contains(concat(concat(p/firstName, ' '),p/lastName),'{0}')) or contains(nameOnBond,'{0}')",
                ODataFilterDataType.string,
                "Name"
            ),
            this.odata.getFilter(
                "Tags/any(t:contains(t/name,'{0}'))",
                ODataFilterDataType.select,
                "Tagged ",
                null,
                null,
                true,
                this.tagOptions,
                true,
                "with "
            )
        ]);
    };

    public buildQuery = () => {
        const query = this.odata
            .getQuery()
            .orderby(this.table.sorter.sortBy)
            .skip(
                (this.table.pager.currentPage - 1) *
                    this.table.pager.recordsPerPage
            )
            .top(this.table.pager.recordsPerPage)
            .expand(
                "bondType($select=id,name),people($select=id,homePhone,cellPhone,email,firstName,lastName)"
            )
            .select(
                "id,leadType,lowestPremium,highestPremium,bondAmount,carrierCount,prequalifiedQuoteCount,createdDateTime"
            );

        const filterExpr = this.filterCollection.getFilterExpression();
        if (filterExpr) {
            query.filter(filterExpr);
        }

        return query;
    };

    public loadGrid = () => {
        if (this.table.sorter.sortBy !== this.widget.options.orderby) {
            this.saveOrderby();
        }

        if (
            this.table.pager.recordsPerPage !=
            this.widget.options.recordsPerPage
        ) {
            this.saveRecordsPerPage();
        }

        const query = this.buildQuery();
        const dataSvc = this.odata.getService(ODataEndpoint.Lead);

        this.busyIndicator.promise = dataSvc
            .get<A3ApiResponse<Lead[]>>(query)
            .then((response) => {
                this.table.setData(
                    response.data.value,
                    response.data["@odata.count"]
                );
            })
            .catch(() => {
                this.errorMessage =
                    "An error occurred trying to get the widget data";
            });
    };

    public showLeadDetails = (id: number) => {
        this.modalOpener
            .showLeadDetailsModal(id)
            .result.then(() => {})
            .catch(() => {});
    };

    public $onInit = () => {
        this.busyIndicator = {
            message: "Loading..."
        };

        this.sfaaCodeOptions = [];
        this.tagOptions = [];

        this.loadSfaaCodes();
        this.loadTagOptions();

        this.setFilters();

        if (isDefined(this.widget.options.filters)) {
            this.filterCollection.mergeFilters(this.widget.options.filters);
        }

        this.loadGrid();

        this.dashboardService.refreshFunctions.push(this.loadGrid);

        this.table.onChange = this.loadGrid;
    };
}

const dashboardWidgetLeadsComponent = {
    templateUrl:
        "app/states/common/dashboard/widgets/leads/dashboardWidgetLeads.html",
    bindings: {
        widget: "="
    },
    controllerAs: "vm",
    controller: DashboardWidgetLeadsController
};

app.component("dashboardWidgetLeads", dashboardWidgetLeadsComponent);
