import { IDirectiveFactory, IDirectiveLinkFn, IScope } from "angular";
import app from "../../main";
import Inputmask from "inputmask";

const negativeMoneyMaskDirective: IDirectiveFactory = () => {
    const link: IDirectiveLinkFn = (
        scope: IScope,
        elem: JQuery
    ) => {
        // const mask = new Inputmask('numeric', { 
        //     groupSeparator: ',', 
        //     digitsOptional: false,
        //     allowMinus: true, 
        //     digits: 2, 
        //     rightAlign: false,
        //     placeholder: '0',
        //     prefix: '$',
        //     positionCaretOnClick: "select",
        //     clearMaskOnLostFocus: true,
        //     enforceDigitsOnBlur: false,
        //     inputType: 'number',
        // });

        const mask = new Inputmask('numeric', {
            alias: "numeric",
            groupSeparator: ",",
            digits: 2,
            digitsOptional: !1,
            prefix: "$",
            placeholder: "0"
        })

        mask.mask(elem);
    }

    return {
        link: link
    };
} 

app.directive('maskMoneyNegative', negativeMoneyMaskDirective);