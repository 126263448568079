import { IQService, IWindowService, IHttpService, IPromise } from "angular";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/settings/systemSettings";
import app from "../main";
import { ODataFactory, ODataEndpoint } from "./odata";
import A3ApiResponse from "./types/a3ApiResponse";
import { BrowserNotification } from "./types/browserNotification";
import { BrowserNotificationResponse } from "./types/browserNotificationResponse";

export class BrowserNotificationService {

    public static $inject = [
        Injectables.$q,
        Injectables.$window,
        Injectables.ODataFactory,
        Injectables.$http,
        Injectables.SystemSettings
    ];

    constructor(
        readonly $q: IQService,
        private readonly $window: IWindowService,
        private readonly odata: ODataFactory,
        private readonly $http: IHttpService,
        private readonly systemSettings: SystemSettings
    ) {
    }

    public defaultNotificationIcon: string = '/img/a3_notification_icon.png';
    public onNotificationReceived: ((notification: BrowserNotification) => void)[]  = [];
    public onNotificationClicked: ((notification: BrowserNotification) => void)[]  = [];

    public addNotificationClickListener(listener: (notification: BrowserNotification) => void): void {
        if (typeof listener === 'function') {
            this.onNotificationClicked.push(listener);
        }
    }

    public addNotificationListener(notificationListener: (notification: BrowserNotification) => void): void {
        if (typeof notificationListener === 'function') {
            this.onNotificationReceived.push(notificationListener);
        }
    }

    public getBrowserNotificationsByUserId(currentPage: number, recordsPerPage: number): IPromise<BrowserNotificationResponse> {
        const url = `${this.systemSettings.apiBaseUrl}BrowserNotificationActions/GetUserBrowserNotifications?currentPage=${currentPage}&recordsPerPage=${recordsPerPage}`;

        return this.$http.get<A3ApiResponse<BrowserNotificationResponse>>(url)
            .then((response) => response.data.value);
    }

    public getUserUnreadBrowserNotificationCount() {
        const url = `${this.systemSettings.apiBaseUrl}BrowserNotificationActions/GetUserUnreadBrowserNotificationCount`;

        return this.$http.get<A3ApiResponse<number>>(url)
            .then((response) => response.data.value);
    }

    public getWebNotificationPermissionStatus(): string {
        return (Notification as any).permission as string;
    }

    public handleBrowserNotification = (notification: BrowserNotification) => {
        this.showWebNotification(notification)
            .then(() => {
                this.handleClick(notification);
            });

        for (const received of this.onNotificationReceived) {
            received(notification);
        }
    }

    public handleClick = (notification: BrowserNotification) => {
        if (!notification) {
            throw new Error('notification is not valid');
        }

        notification.read = true;
        this.setReadStatus(notification)
            .then(() => {
                this.navigateTo(notification);
            });

        for (const clicked of this.onNotificationClicked) {
            clicked(notification);
        }
    }

    public markAllAsRead(userId: number): IPromise<void> {
        return this.$http
            .post(this.systemSettings.apiBaseUrl + 'BrowserNotificationActions/MarkAllAsRead', userId)
            .then(() => {});
    }

    public navigateTo(notification: BrowserNotification): void {
        if (!notification) {
            throw new Error('notification is not valid');
        }

        if (notification.returnUrl) {
            this.$window.location.href = notification.returnUrl;
        }
    }

    public requestWebNotificationPermission(): IPromise<void> {
        const deffered = this.$q.defer();

        // if not already granted then prompt for permission
        if (this.getWebNotificationPermissionStatus() !== 'granted') {
            (window as any).Notification.requestPermission()
                .then((result) => {
                    if (result === 'denied' || result === 'default') {
                        deffered.reject(() => {});
                    } else {
                        deffered.resolve(() => {});
                    }
                });
        } else {
            deffered.resolve(() => {});
        }

        return deffered.promise.then(() => {});
    }

    public setReadStatus(notification: BrowserNotification): IPromise<void> {
        return this.$http
            .post(this.systemSettings.apiBaseUrl + 'BrowserNotificationActions/' + (notification.read ? 'Mark' : 'Unmark') + 'AsRead', notification.id)
            .then(() => {});
    }

    public showWebNotification(serverNotification: BrowserNotification): IPromise<void> {
        const deferred = this.$q.defer();

        this.requestWebNotificationPermission()
            .then(() => {

                // notification options
                const options: NotificationOptions = {
                    body: serverNotification.message,
                    icon: this.defaultNotificationIcon,
                    tag: serverNotification.id.toString()
                };

                // create notification
                const notification = new Notification(serverNotification.title, options);

                // handle onclick
                notification.onclick = () => {
                    deferred.resolve(() => {});
                    notification.close();
                };
            })
            .catch(() => {
                deferred.reject(() => {});
            });

        return deferred.promise.then(() => {});
    }
}

app.service(Injectables.BrowserNotificationService, BrowserNotificationService);
