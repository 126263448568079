enum ODataFilterDataTypes {
    daysTill = 'daysTill',
    string = 'string',
    date = 'date',
    money = 'money',
    state = 'state',
    select = 'select',
    bool = 'bool',
    stringNotContains = 'stringNotContains',
    sfaa = 'sfaa'
};

export default ODataFilterDataTypes;