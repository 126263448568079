import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";
import { Table } from "../../utilities/tables/table";
import ApplicationListItem from "./applicationListItem";
import ApplicationListTableQueryOptions from "./applicationListQueryOptions";

class ApplicationListController {
    
    public static $inject = [ 
        Injectables.CurrentUserResolver
    ];

    constructor(
        private readonly currentUserResolver: CurrentUserResolver) { 
    }

    public isCarrier: boolean;
    public table: Table<ApplicationListItem, ApplicationListTableQueryOptions>[];

    public $onInit(): void {
        this.isCarrier = this.currentUserResolver.getCurrentUser().systemAccount.isCarrier;
    }
}

const applicationListComponent = {
    templateUrl: 'app/components/applicationList/applicationList.html',
    bindings: { 
        table: '<' 
    },
    controller: ApplicationListController,
    controllerAs: 'vm'
};

app.component('applicationList', applicationListComponent);