import { EProducerService } from "../../../api/eProducerService";
import {EProducerDropdownFilterOptions} from "./eProducerAccountsDropdownFilterOptions";
import {EProducerAccountListItem} from "./eProducerAccountListItem";
import { Table } from "../../../utilities/tables/table";
import { IDebounceDelayer } from "../../../utilities/debounceDelayer/iDebouceDelayer";
import { Injectables } from "../../../configuration/injectables";
import { ModalOpener } from "../../../modals/modalOpener";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
export class EProducerAccountsController {
    public static $inject = [
        Injectables.EProducerService,
        Injectables.ModalOpener,
        Injectables.IDebouceDelayer,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly eProducerService: EProducerService,
        private readonly modalOpener: ModalOpener,
        debouceDelayer: IDebounceDelayer,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {
        this.table = new Table(debouceDelayer);
        this.table.onChange = this.loadData;
    }

    public readonly table: Table<EProducerAccountListItem, EProducerDropdownFilterOptions>;
    public readonly filters: EProducerDropdownFilterOptions;
    public busyIndicator: BusyIndicator;

    public deleteBroker = (account: EProducerAccountListItem) => {
        if (account.isDeletable)
            this.modalOpener
                .confirmModal(
                    "Delete Broker",
                    `Are you sure you want to delete the broker account <br><b>${account.name}</b>`,
                    "Delete",
                    "Cancel"
                )
                .result.then(() => {
                    return this.eProducerService
                        .deleteAccount(account)
                        .catch(() => {
                            this.toastMessageCreator.createErrorMessage(
                                "An error occurred while trying to delete this broker account"
                            );
                        });
                })
                .then(() => {
                    this.toastMessageCreator.createSuccessMessage(
                        "Broker account has been deleted"
                    );
                    this.table.refresh();
                })
                .catch(() => {});
    };

    public addNewBroker = () => {
        this.modalOpener
            .showEProducerBrokerModal()
            .result.then(() => {})
            .catch(() => {});
    };

    public showInviteBrokerModal = () => {
        this.modalOpener
            .showInviteBrokerModal()
            .result.then(() => {})
            .catch(() => {});
    };

    public loadData = () => {
        this.busyIndicator.message = "Loading Brokers...";
        this.busyIndicator.promise = this.eProducerService
            .getEproducerBrokerAccountsList(this.table.queryOptions)
            .then((response) => {
                this.table.setData(response.items, response.totalRecordCount);
            }); 
    };

    public $onInit = () => {
        this.busyIndicator = {};
        this.loadData();
    };
}
