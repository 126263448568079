import { IDirectiveFactory, IDirectiveLinkFn, IScope } from "angular";
import app from "../../main";
import Inputmask from "inputmask";

const maskPhoneDirective: IDirectiveFactory = () => {
    
    const link: IDirectiveLinkFn = (
        scope: IScope,
        elem: JQuery
    ) => {
        const mask = new Inputmask("(999) 999-9999");
        mask.mask(elem);
    }
    
    return {
        link: link
    };
}

app.directive('maskPhone', maskPhoneDirective);
