import { EnhancedDASIndicator } from "./enhancedDASIndicator";
import { RegionalIndicator } from "./regionalIndicator";
import { RejectCode } from "./rejectCode";
import { ScoreReasons } from "./scoreReasons";

export interface USEDAS { 
    edasScore: string;
    scoreReasons: ScoreReasons;
    rejectCode: RejectCode;
    regionalIndicator: RegionalIndicator;
    enhancedDASIndicator: EnhancedDASIndicator;
    factActInquiriesAreKeyFactor: string;
}