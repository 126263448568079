import { City } from "./city";
import { CustomerId } from "./customerId";
import { Telephone } from "./telephone";

    export interface USOnlineDirectory { 
        customerId: CustomerId;
        telephone: Telephone;
        addressLine1: string;
        addressLine2: string;
        city: City;
        state: string;
        postalCode: string;
    }