import * as angular from "angular";
import { Injectables } from "../configuration/injectables";
import app from "../main";

// this requires a global reference to xslx.core.min.js
export class DataExport {

    public exportToCsv(data: any, fileName: string, includeHeaders: boolean): void {
        if (!data) {
            return;
        }

        // If JSONData is not an object then JSON.parse will parse the JSON string in an Object
        const arrData = typeof data !== 'object' ? JSON.parse(data) : data;

        const csvData: any = [];

        for(let i = 0; i < arrData.length; i++) {
            csvData.push(this.flatten(arrData[i]));
        }

        // default fileName if not set
        fileName = !fileName ? 'A3_DataExport' : fileName;

        // default includeHeaders to true if not set
        includeHeaders = !angular.isDefined(includeHeaders) ? true : includeHeaders;

        let CSV = '';

        // This condition will generate the Label/Header
        if (includeHeaders) {
            let rowString = '';

            // This loop will extract the label from 1st index of on array
            const csv = csvData[0];
            for (const index in csv) {
                if (csv.hasOwnProperty(index)) {

                    // Now convert each value to string and comma-seprated
                    rowString += index + ',';
                }
            }

            rowString = rowString.slice(0, -1);

            // append Label row with line break
            CSV += rowString + '\r\n';
        }

        // 1st loop is to extract each row
        for (const csv of csvData) {
            let row = '';

            // 2nd loop will extract each column and convert it in string comma-seprated
            for (const index in csv) {
                if (csv.hasOwnProperty(index)) {
                    row += `"${csv[index]}",`;
                }
            }

            row.slice(0, row.length - 1);

            // add a line break after each row
            CSV += row + '\r\n';
        }

        if (!CSV) {
            throw new Error('Invalid Data');
        }

        // this will remove the blank-spaces from the title and replace it with an underscore
        fileName = fileName.replace(/ /g, '_');

        CSV = CSV.replace(/\#/g, '%23');

        // Initialize file format you want csv or xls
        const uri = `data:text/csv;charset=utf-8,${encodeURI(CSV)}`;

        // Now the little tricky part.
        // you can use either>> window.open(uri);
        // but this will not work in some browsers
        // or you will not get the correct file extension

        // this trick will generate a temp <a /> tag
        const link = document.createElement('a');
        link.href = uri;

        // set the visibility hidden so it will not effect on your web-layout
        link.style.setProperty('visibility', 'hidden');
        link.download = fileName + '.csv';

        // this part will append the anchor tag and remove it after automatic click
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    }

    public saveData(data: any, fileName: string): void {
        const a = document.createElement('a') as any;
        document.body.appendChild(a);
        a.style = 'display: none';

        const json = JSON.stringify(data),
            blob = new Blob([json], { type: 'octet/stream' }),
            url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }

    public flatten(data: any): any {
        const result = {};

        // tslint:disable-next-line:only-arrow-functions
        function recurse(cur: any, prop: any): void;
        // tslint:disable-next-line:only-arrow-functions
        function recurse(cur: any, prop: any): void {
            if (Object(cur) !== cur) {
                result[prop] = cur;
            } else if (Array.isArray(cur)) {
                // tslint:disable-next-line:no-var-keyword
                for (var i = 0, l = cur.length; i < l; i++) {
                    recurse(cur[i], prop + '[' + i + ']');
                }
                if (l === 0) {
                    result[prop] = [];
                }
            } else {
                let isEmpty = true;
                for (const p in cur) {
                    if (cur.hasOwnProperty(p)) {
                        isEmpty = false;
                        recurse(cur[p], prop ? prop + '.' + p : p);
                    }
                }
                if (isEmpty && prop) {
                    result[prop] = {};
                }
            }
        }

        recurse(data, '');
        return result;
    }
}

app.service(Injectables.DataExport, DataExport);
