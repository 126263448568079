import { DateWrapper } from "../api/types/dateWrapper";
import { GroupByAccumulator } from "../api/types/groupByAccumulator";
import { SelectOption } from "../api/types/selectOption";
import { Injectables } from "../configuration/injectables";
import app from "../main";
import { IFilterService, IHttpPromiseCallbackArg } from "angular";
import * as saveAs from "file-saver";
import * as luxon from "luxon";
import * as moment from "moment";

export interface HttpResponseMessage{

    version?: Version;

    content?: HttpContent;

    statusCode?: HttpStatusCode;

    reasonPhrase?: string;

    headers?: HttpResponseHeaders;

    requestMessage?: HttpRequestMessage;

    isSuccessStatusCode?: boolean;

}

export interface HttpResponseHeaders {

    acceptRanges?: any;

    age?: any;

    eTag?: any;

    location?: Uri;

    proxyAuthenticate?: any;

    retryAfter?: any;

    server?: any;

    vary?:any;

    wwwAuthenticate?: any;

    cacheControl?: any;

    connection?: any;

    connectionClose?: boolean;

    date?: Date;

    pragma?: any;

    trailer?: any;

    transferEncoding?: any;

    transferEncodingChunked?: boolean;

    upgrade?: any;

    via?: any;

    warning?: any;
}

export enum HttpStatusCode {
    Continue = 100,

    SwitchingProtocols = 101,

    OK = 200,

    Created = 201,

    Accepted = 202,

    NonAuthoritativeInformation = 203,

    NoContent = 204,

    ResetContent = 205,

    PartialContent = 206,

    Ambiguous = 300,

    MultipleChoices = 300,

    Moved = 301,

    MovedPermanently = 301,

    Found = 302,

    Redirect = 302,

    RedirectMethod = 303,

    SeeOther = 303,

    NotModified = 304,

    UseProxy = 305,

    Unused = 306,

    RedirectKeepVerb = 307,

    TemporaryRedirect = 307,

    BadRequest = 400,

    Unauthorized = 401,

    PaymentRequired = 402,

    Forbidden = 403,

    NotFound = 404,

    MethodNotAllowed = 405,

    NotAcceptable = 406,

    ProxyAuthenticationRequired = 407,

    RequestTimeout = 408,

    Conflict = 409,

    Gone = 410,

    LengthRequired = 411,

    PreconditionFailed = 412,

    RequestEntityTooLarge = 413,

    RequestUriTooLong = 414,

    UnsupportedMediaType = 415,

    RequestedRangeNotSatisfiable = 416,

    ExpectationFailed = 417,

    UpgradeRequired = 426,

    InternalServerError = 500,

    NotImplemented = 501,

    BadGateway = 502,

    ServiceUnavailable = 503,

    GatewayTimeout = 504,

    HttpVersionNotSupported = 505
}
export interface HttpRequestMessage {

    version?: Version;

    content?: HttpContent;

    method?: HttpMethod;

    requestUri?: Uri;

    headers?: HttpHeader[];

    properties?: HttpProperty[];

}

export interface Version {

    major?: number;

    minor?: number;

    build?: number;

    revision?: number;

    majorRevision?: number;

    minorRevision?: number;
}

export interface HttpContent {

    headers?: HttpContentHeaders;

}
export interface HttpContentHeaders {

    allow?: string[];

    contentDisposition?: ContentDispositionHeaderValue;

    contentEncoding?: string[];

    contentLanguage?: string[];

    contentLength?: number;

    contentLocation?: Uri;

    contentMD5?: number[];

    contentRange?: ContentRangeHeaderValue;

    contentType?: MediaTypeHeaderValue;

    expires?: Date;

    lastModified?: Date;
}
export interface ContentDispositionHeaderValue {

    dispositionType?: string;

    parameters?: NameValueHeaderValue[];

    name?: string;

    fileName?: string;

    fileNameStar?: string;

    creationDate?: Date;

    modificationDate?: Date;

    readDate?: Date;

    size?: number;

}

export interface NameValueHeaderValue {

    name?: string;

    value?: string;

}

export interface Uri {

    absolutePath?: string;

    absoluteUri?: string;

    localPath?: string;

    authority?: string;

    hostNameType?: UriHostNameType;

    isDefaultPort?: boolean;

    isFile?: boolean;

    isLoopback?: boolean;

    pathAndQuery?: string;

    segments?: string[];

    isUnc?: boolean;

    host?: string;

    port?: number;

    query?: string;

    fragment?: string;

    scheme?: string;

    originalString?: string;

    dnsSafeHost?: string;

    idnHost?: string;

    isAbsoluteUri?: boolean;

    userEscaped?: boolean;

    userInfo?: string;

}

export enum UriHostNameType {
    Unknown,
    Basic,
    Dns,
    IPv4,
    IPv6
}

export interface ContentRangeHeaderValue {

    unit?: string;

    from?: number;

    to?: number;

    length?: number;

    hasLength?: boolean;

    hasRange?: boolean;
}

export interface MediaTypeHeaderValue {

    charSet?: string;

    parameters?: NameValueHeaderValue[];

    mediaType?: string;

}

export interface HttpMethod {

    get?: HttpMethod;

    put?: HttpMethod;

    post?: HttpMethod;

    delete?: HttpMethod;

    head?: HttpMethod;

    options?: HttpMethod;

    trace?: HttpMethod;

    method?: string;

}

export interface HttpHeader {
    [action: string]: string
}


export interface HttpProperty {
    [property: string]: any
}
export interface MediaTypeWithQualityHeaderValue {

    quality?: number;
}

export class UtilityService {

    public static $inject = [
        Injectables.$filter
    ];

    constructor(
        private readonly $filter: IFilterService) { }

    get creditCardMonthOptions(): SelectOption<string>[] {
        return this.getCreditCardMonths();
    }

    get creditCardYearOptions(): SelectOption<string>[] {
        return this.getCreditCardYears();
    }

    public convertToCamelCase(str: string): string {
        return str.charAt(0).toLowerCase() + str.slice(1);
    }

    public convertToPascalCase(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    public dataURItoBlob(dataURI: string): Blob {
        // convert base64/URLEncoded data component to raw binary data held in a string
        let byteString: string;

        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        }
        else {
            byteString = decodeURI(dataURI.split(',')[1]);
        }

        // separate out the mime component
        const mimeString: string = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        const ia = new Uint8Array(byteString.length);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
    }

    public getMonthNameFromNumber(num: number, format: string): string {
        if (num < 1 || num > 12) {
            return null;
        }

        if (format) {
            if (format.toUpperCase() !== 'M' && format.toUpperCase() !== 'MM' && format.toUpperCase() !== 'MMM' && format.toUpperCase() !== 'MMMM') {
                return null;
            }
        }

        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        if (!format || format.length === 4) {
            return monthNames[num - 1];
        } else {
            return monthNames[num - 1].substring(0, format.length);
        }
    }

    public processDocumentDownloadResponse(response:IHttpPromiseCallbackArg<HttpResponseMessage>): void {
        const headers = response.headers();
        const dispositionHeader = headers['content-disposition'].replace(/['"]+/g, '');
        const filename = dispositionHeader.substring(dispositionHeader.indexOf('filename=') + 9);

        const blob = new Blob([response.data] as string[], { type: headers['content-type'] });

        // uses FileSave.js
        // http://purl.eligrey.com/github/FileSaver.js/blob/master/FileSaver.js
        saveAs(blob, filename);

    }

    public getDateTimeOfDayHours(d: Date | string): string {
        let date;

        if (d === undefined) {
            date = moment.utc().startOf('day');
        } else if (typeof d === 'string') {
            date = moment.utc(d, moment.ISO_8601);
        } else {
            date = moment.utc(d.toISOString(), moment.ISO_8601);
        }

        const hours = date.format('H');

        const iso8601duration = `PT${hours}H`;

        return iso8601duration;
    }

    public getDateFromStringMMDDYYYY(dateString: string): DateWrapper {

        if (dateString.indexOf('/') !== -1) {
            dateString = dateString.replace(/\//g, '');
        }

        if (dateString.indexOf('-') !== -1) {
            dateString = dateString.replace(/\-/g, '');
        }

        if (dateString.length !== 8) { return null; }

        const month = dateString.substring(0, 2);
        const date = dateString.substring(2, 4);
        const year = dateString.substring(4, 8);
        const isZeroDate = date === '00';
        const adjustedDate = isZeroDate ? '01' : date;
        const returnDate = month + '/' + adjustedDate + '/' + year;
        const formattedDate = isZeroDate ? moment(returnDate).format('MMMM YYYY') : moment(returnDate).format('MMMM D, YYYY');

        const momentWrapper: DateWrapper = {
            date: adjustedDate,
            formattedDate: formattedDate,
            isZeroDate: isZeroDate,
            momentDate: moment(returnDate),
            month: month,
            year: year,
        };

        return momentWrapper;
    }

    public setDateTimeOfDay(d: Date | string, timeOfDay: string): Date {
        let date;

        if (typeof d === 'string') {
            date = moment.utc(d, moment.ISO_8601);
        } else {
            date = moment.utc(d.toISOString(), moment.ISO_8601);
        }

        const duration = moment.duration(timeOfDay);

        date.startOf('day').add(duration);

        return date.toDate();
    }

    public enumConstantToDropdownArray(enumConst): SelectOption<number>[] {
        const arr: SelectOption[] = [];

        for (const prop in enumConst) {
            if (!enumConst.hasOwnProperty(prop)) { continue; }

            const value = enumConst[prop];
            let label = enumConst[prop];

            switch (enumConst[prop]) {
                case 'GreaterThan':
                    label = '>';
                    break;
                case 'GreaterThanOrEqualTo':
                    label = '>=';
                    break;
                case 'LessThan':
                    label = '<';
                    break;
                case 'LessThanOrEqualTo':
                    label = '<=';
                    break;
                case 'EqualTo':
                    label = '=';
                    break;
                case 'NotEqualTo':
                    label = "Doesn't equal";
                    break;
                case 'FICO':
                    label = 'FICO';
                    break;
                case 'NRS':
                    label = 'NRS';
                    break;
                default:
                    label =
                        label
                            // insert a space before all caps
                            .replace(/([A-Z])/g, ' $1')
                            // uppercase the first character
                            .replace(/^./, (str) => str.toUpperCase());
            }

            arr.push({ label: label, value: value });
        }

        return arr;
    }

    public capitalizeFirstLetter(text: string): string {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    public formatCurrency(amount: number): string {
        return this.$filter('currency')(amount, '$');
    }

    public getCurrencyValue(amountStr: string): number {
        return parseFloat(amountStr.toString().replace(/,/g, '').replace(/\$/g, ''));
    }

    public getCreditCardYears(): SelectOption<string>[] {
        const date = new Date().getFullYear();
        const creditCardYearOptions: SelectOption<string>[] = [];
        const length = date + 13;

        for (let i = date; i < length; i++) {
            creditCardYearOptions.push({ value: `${i}`, label: `${i}` });
        }

        return creditCardYearOptions;
    }

    public getDropdownMonths(): SelectOption<number>[] {
        const months: SelectOption[] = [
            { label: 'January', value: 1 },
            { label: 'February', value: 2 },
            { label: 'March', value: 3 },
            { label: 'April', value: 4 },
            { label: 'May', value: 5 },
            { label: 'June', value: 6 },
            { label: 'July', value: 7 },
            { label: 'August', value: 8 },
            { label: 'September', value: 9 },
            { label: 'October', value: 10 },
            { label: 'November', value: 11 },
            { label: 'December', value: 12 }
        ];
        return months;
    }

    public getDropdownDaysProper(): SelectOption<number>[] {
        const days: SelectOption[] = [
            { label: '1st', value: 1 },
            { label: '2nd', value: 2 },
            { label: '3rd', value: 3 },
            { label: '4th', value: 4 },
            { label: '5th', value: 5 },
            { label: '6th', value: 6 },
            { label: '7th', value: 7 },
            { label: '8th', value: 8 },
            { label: '9th', value: 9 },
            { label: '10th', value: 10 },
            { label: '11th', value: 11 },
            { label: '12th', value: 12 },
            { label: '13th', value: 13 },
            { label: '14th', value: 14 },
            { label: '15th', value: 15 },
            { label: '16th', value: 16 },
            { label: '17th', value: 17 },
            { label: '18th', value: 18 },
            { label: '19th', value: 19 },
            { label: '20th', value: 20 },
            { label: '21st', value: 21 },
            { label: '22nd', value: 22 },
            { label: '23rd', value: 23 },
            { label: '24th', value: 24 },
            { label: '25th', value: 25 },
            { label: '26th', value: 26 },
            { label: '27th', value: 27 },
            { label: '28th', value: 28 },
            { label: '29th', value: 29 },
            { label: '30th', value: 30 },
            { label: '31st', value: 31 }
        ];
        return days;
    }

    public getDropdownYearDurations(): SelectOption<string>[] {
        const durations: SelectOption<string>[] = [
            { label: 'Every Year', value: 'Annual' },
            { label: 'Even Years', value: 'EvenYears' },
            { label: 'Odd Years', value: 'OddYears' },
            { label: 'Every 2 Years', value: 'Every2Years' },
            { label: 'Every 3 Years', value: 'Every3Years' },
            { label: 'Every 4 Years', value: 'Every4Years' },
            { label: 'Every 5 Years', value: 'Every5Years' }
        ];
        return durations;
    }

    public getCreditCardMonths(): SelectOption<string>[] {
        const months: SelectOption<string>[] = [
            { value: '01', label: '01 - January' },
            { value: '02', label: '02 - February' },
            { value: '03', label: '03 - March' },
            { value: '04', label: '04 - April' },
            { value: '05', label: '05 - May' },
            { value: '06', label: '06 - June' },
            { value: '07', label: '07 - July' },
            { value: '08', label: '08 - August' },
            { value: '09', label: '09 - September' },
            { value: '10', label: '10 - October' },
            { value: '11', label: '11 - November' },
            { value: '12', label: '12 - December' }
        ];
        return months;
    }

    public momentRandom(end = moment(), start): moment.Moment {
        const endMoment = moment(end);
        const randomNumber = (to, from = 0) =>
        Math.floor(Math.random() * (to - from) + from);

        if (start) {
            const startMoment = moment(start);
            if (startMoment.unix() > endMoment.unix()) {
                throw new Error('End date is before start date!');
            }
            return moment.unix(randomNumber(endMoment.unix(), startMoment.unix()));
        } else {
            return moment.unix(randomNumber(endMoment.unix()));
        }
    }

    public groupBy<T>(array: T[], key: string): GroupByAccumulator<T> {
        return array.reduce((accumulator, currentValue) => {
            ((accumulator[currentValue[key]] = accumulator[currentValue[key]] || []) as T[]).push(currentValue);
            return accumulator;
        }, {}) as GroupByAccumulator<T>;
    }

    public sortArrayBy<T>(array: T[], property: string): void {
        array.sort((a: T, b: T) => {
            const compareA = a[property];
            const compareB = b[property];

            let comparison = 0;

            if (compareA > compareB) {
                comparison = 1;
            }

            if (compareA < compareB) {
                comparison = -1;
            }

            return comparison;
        });
    }

    public getCurrentDate() {
        return luxon.DateTime.now().toJSDate();
    }
}

app.service(Injectables.UtilityService, UtilityService);
