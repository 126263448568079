import { BondTypeService } from "../../../../../api/bondTypeService";
import { CacheFactory } from "../../../../../api/types/cacheFactory";
import { CacheStore } from "../../../../../api/types/cacheStore";
import { BusyIndicator } from "../../../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../../../configuration/injectables";
import { DashboardService } from "../../dashboardService";
import { DashboardWidget } from "../../types/dashboardWidget";
import app from "../../../../../main";
import * as angular from "angular";
import { AmChartService, AgentActivityTrackerChartOptions } from "../../../../../utilities/amCharts/amChartService";

class DashboardWidgetBondTypeProgressController {

    public static $inject = [ 
        Injectables.BondTypeService, 
        Injectables.DashboardService, 
        Injectables.CacheFactory,
        Injectables.AmChartService
    ];

    constructor(
        private readonly bondTypeService: BondTypeService, 
        private readonly dashboardService: DashboardService, 
        private readonly cacheFactory: CacheFactory,
        private readonly amChartService: AmChartService
    ) {
    }

    public widget: DashboardWidget;
    public busyIndicator: BusyIndicator;
    public amCharts: any;
    public widgetIndex: number;
    public cacheStore: CacheStore;

    public loadChart = () => {
        this.busyIndicator.promise = this.bondTypeService.getProgressCounts()
            .then((data) => {

                const chartOptions: AgentActivityTrackerChartOptions = {
                    inquiriesHidden: this.widget.options.inquiryCountIsHidden,
                    prospectsHidden:this.widget.options.proscpectsIsHidden,
                    onDataVisiblityChanged: this.toggleDataVisiblity
                };

                this.amChartService.makeAgentActivityTrackerChart("chartdiv" + this.widgetIndex, data, chartOptions);

            });
    };

    public toggleDataVisiblity = (evt) => {
        this.widget.options[evt.dataItem.valueField + 'IsHidden'] = evt.dataItem.hidden;
        this.dashboardService.save();
    };

    public $onInit = () => {
        this.busyIndicator = {
            message: 'Loading...'
        };

        this.cacheStore = this.cacheFactory.get('dashboard');
        if (!this.cacheStore) {
            this.cacheStore = this.cacheFactory('dashboard', {});
        }

        if (!angular.isDefined(this.widget.options.title)) {
            this.widget.options.title = 'Bond Type Progress';
        }
        
        this.loadChart();
        this.dashboardService.refreshFunctions.push(this.loadChart);
    }
}

const dashboardWidgetBondTypeProgressComponent = {
    bindings: {
        removeWidget: '&', 
        widget: '=', 
        cloneWidget: '&',
        widgetIndex: '<'
    },
    templateUrl: 'app/states/common/dashboard/widgets/bondTypeProgress/dashboardWidgetBondTypeProgress.html',
    controller: DashboardWidgetBondTypeProgressController,
    controllerAs: 'vm'
};

app.component('dashboardWidgetBondTypeProgress', dashboardWidgetBondTypeProgressComponent);