import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { GridDeleteConfirmModalOptions } from "./GridDeleteConfirmModalOptions";

export class GridConfirmDeleteModalController {

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options
    ];

    constructor(
        private readonly $uibModalInstance: Modal<number>,
        private readonly options: GridDeleteConfirmModalOptions
    ) {}

    public getLabel = () => {
        return this.options.name
            ? '<strong>'+ this.options.name.substring(0,40) + (this.options.name.length > 40 ? '...': '') + '</strong>'
            : ' this record';
    }

    public delete = () => {
        this.$uibModalInstance.close(this.options.id);
    }

    public cancel = () => {
        this.$uibModalInstance.dismiss();
    }

    public $onInit = () => {}
}

app.controller('GridConfirmDeleteModalController', GridConfirmDeleteModalController);
