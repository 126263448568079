import A3ApiResponse from "../../../api/types/a3ApiResponse";
import { CurrentUser } from "../../../api/types/authentication/currentUser";
import { Injectables } from "../../../configuration/injectables";
import { GuidCreator } from "../../../utilities/guids/guidCreator";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { Dashboard } from "./types/dashboard";
import { DashboardConfiguration } from "./types/dashboardConfiguration";
import { DashboardWidget } from "./types/dashboardWidget";
import { DateReference } from "./types/dateReference";
import { GridsterOptions } from "./types/gridsterOptions";
import { WidgetColor } from "./widgetColorSelector/widgetColors";
import app from "../../../main";
import * as angular from "angular";
import {
    IHttpService,
    ITimeoutService,
    IIntervalService,
    IPromise
} from "angular";
import { SlabTextService } from "../../../utilities/slabText/slabTextService";
import { PerfectScrollbarService } from "../../../utilities/perfectScrollbar/perfectScrollbarService";
import dashboardWidgetDefinitions from "./dashboardWidgetDefinitions";
import * as moment from "moment";
import { JQueryService } from "../../../utilities/jquery/jQueryService";
import { SystemSettings } from "../../../configuration/settings/systemSettings";
import agencyDefaultDashboard from "./defaultDashboardConfigurations/agentDefaultDashboardConfiguration";
import carrierDefaultDashboard from "./defaultDashboardConfigurations/carrierDefaultDashboard";
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";
import deepCopy from "../../../utilities/immutable/deepCopy";

export class DashboardService {
    public static $inject = [
        Injectables.GuidCreator,
        Injectables.$http,
        Injectables.CurrentUserResolver,
        Injectables.$timeout,
        Injectables.$interval,
        Injectables.ToastMessageCreator,
        Injectables.SlabTextService,
        Injectables.PerfectScrollbarService,
        Injectables.JQueryService,
        Injectables.SystemSettings
    ];

    constructor(
        private readonly guidCreator: GuidCreator,
        private readonly $http: IHttpService,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly $timeout: ITimeoutService,
        private readonly $interval: IIntervalService,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly slabTextService: SlabTextService,
        private readonly perfectScrollbarService: PerfectScrollbarService,
        private readonly jQueryService: JQueryService,
        private readonly systemSettings: SystemSettings
    ) {}

    public currentDashboard = {
        widgets: [] as DashboardWidget[],
        widgetTypes: [] as DashboardWidget[]
    } as Dashboard;

    public currentDashboardIndex: number;

    // IMPORTANT: Items should be placed in the grid in the order in which they should appear.
    // In most cases the sorting should be by row ASC, col ASC

    // these map directly to gridsterItem directive options
    public dashboardConfiguration = {
        dashboards: [],
        options: {}
    } as DashboardConfiguration;

    public gridsterOptions = {
        minRows: 2, // the minimum height of the grid, in rows
        maxRows: 100,
        columns: 6, // the width of the grid, in columns
        colWidth: "auto", // can be an integer or 'auto'.  'auto' uses the pixel width of the element divided by 'columns'
        rowHeight: "match", // can be an integer or 'match'.  Match uses the colWidth, giving you square widgets.
        margins: [10, 10], // the pixel distance between each widget
        defaultSizeX: 2, // the default width of a gridster item, if not specifed
        defaultSizeY: 1, // the default height of a gridster item, if not specified
        mobileBreakPoint: 600, // if the screen is not wider that this, remove the grid layout and stack the items
        resizable: {
            enabled: true,
            start(event, uiWidget, $element): void {}, // optional callback fired when resize is started,
            resize: (event, uiWidget, $element) => {
                this.$timeout(() => {
                    this.slabTextService.setupSlabText(
                        this.jQueryService.getElement(".value", uiWidget),
                        { maxFontSize: 40 }
                    );
                });
            }, // optional callback fired when item is resized,
            stop: (event, uiWidget, $element) => {
                // optional callback fired when item is finished resizing
                this.$timeout(() => {
                    this.perfectScrollbarService.updatePerfectScrollBar(
                        this.jQueryService.getElement(".scroller", uiWidget)
                    );
                });
                this.save();
            }
        },
        draggable: {
            handle: ".dashboard-widget-header",
            stop: () => {
                this.save();
            }
        }
    } as GridsterOptions;

    public lastRefreshTime: string;

    public onRefreshed: any = [];
    public refreshRate: any;
    public refreshFunctions: any = [];
    public saveTimeout: any = null;

    public widgetColors: WidgetColor[] = [
        { value: "none", label: "None" },
        { value: "red", label: "Red", cssClass: "has-border has-border-red" },
        {
            value: "green",
            label: "Green",
            cssClass: "has-border has-border-green"
        },
        {
            value: "blue",
            label: "Blue",
            cssClass: "has-border has-border-blue"
        },
        {
            value: "orange",
            label: "Orange",
            cssClass: "has-border has-border-orange"
        },
        {
            value: "peach",
            label: "Peach",
            cssClass: "has-border has-border-peach"
        },
        {
            value: "charcoal",
            label: "Charcoal Gray",
            cssClass: "has-border has-border-charcoal"
        }
    ];

    public addWidgetToCurrentDashboard = (widgetType: DashboardWidget) => {
        const widget = angular.copy(widgetType);

        this.initializeWidget(widget);
        this.currentDashboard.widgets.push(widget);
        this.save();
    };

    public addDashboard = () => {
        this.dashboardConfiguration.dashboards.push({
            name: "New Dashboard",
            widgets: []
        });
        this.selectDashboard(this.dashboardConfiguration.dashboards.length - 1);
        this.save();
    };

    public autoRefresh = () => {
        this.setRefreshTime();
        for (const refreshFunction of this.refreshFunctions) {
            refreshFunction();
        }

        // call to listening onRefreshed event
        for (const onRefreshed of this.onRefreshed) {
            onRefreshed();
        }
    };

    public calculateComparisonText(
        currValue: number,
        prevValue: number,
        timePeriod: string,
        percentIntoTimePeriod: number
    ): string {
        const adjustedPrevValue = percentIntoTimePeriod * prevValue;

        if (adjustedPrevValue <= 0) {
            return `Can't compare to ${timePeriod}`;
        }

        const percentDiff = currValue / adjustedPrevValue;

        if (percentDiff >= 0.98 && percentDiff <= 1.02) {
            return `Nearly the same as ${timePeriod}`;
        } else if (percentDiff > 1) {
            return `<p class="success"><i class="fa fa-arrow-up"></i> <strong>${Math.round(
                (percentDiff - 1) * 100
            )}%</strong> over this time ${timePeriod}</p>`;
        } else {
            return `<p class="failed"><i class="fa fa-arrow-down"></i> <strong>${Math.round(
                (1 - percentDiff) * 100
            )}%</strong> under this time ${timePeriod}</p>`;
        }
    }

    public cloneDashboard(): void {
        this.dashboardConfiguration.dashboards.push(
            angular.copy(this.currentDashboard)
        );
        this.selectDashboard(this.dashboardConfiguration.dashboards.length - 1);
        this.save();
    }

    public cloneWidgetToCurrentDashboard(index: number): void {
        const widget = angular.copy(this.currentDashboard.widgets[index]);

        this.initializeWidget(widget, true);

        // clear position data
        widget.col = null;
        widget.row = null;

        this.currentDashboard.widgets.push(widget);
        this.save();
        this.toastMessageCreator.createSuccessMessage(
            "Widget successfully cloned to the dashboard."
        );
    }

    public doesWidgetMatchSearch(
        widgetType: DashboardWidget,
        search: string
    ): boolean {
        if (!search) {
            return true;
        }

        search = search.toLowerCase();

        for (let i = 0; widgetType.tags.length > i; i++) {
            const tagSearch =
                search.indexOf("#") === 0 ? search.replace("#", "") : search;
            if (widgetType.tags[i].toLowerCase() === tagSearch) {
                return true;
            }
        }

        if (search.indexOf("#") === 0) {
            return false;
        }

        if (widgetType.name.toLowerCase().indexOf(search) > -1) {
            return true;
        }
        if (widgetType.description.toLowerCase().indexOf(search) > -1) {
            return true;
        }

        return false;
    }

    public getDefaultDashboard(currentUser: CurrentUser): Dashboard[] {
        if (currentUser.systemAccount.isCarrier) {
            return carrierDefaultDashboard;
        } else {
            return agencyDefaultDashboard;
        }
    }

    public getPercentIntoTimePeriod(dateReference: string): number {
        let percent = 1;
        const now = moment();

        if (dateReference === "YTD") {
            percent = now.dayOfYear() / 356;
        } else if (dateReference === "Last 90 Days") {
            percent = 1;
        } else if (dateReference === "This Month") {
            percent = parseInt(now.format("DD"), 10) / 31;
        } else if (dateReference === "Last Month") {
            percent = 1;
        } else if (dateReference === "This Week") {
            percent = (now.day() + 1) / 7;
        }
        return percent;
    }

    public getTimePeriod(dateReference: DateReference): string {
        let timePeriod = "";

        if (dateReference === DateReference.YTD) {
            timePeriod = "last year";
        } else if (dateReference === DateReference.Last90Days) {
            timePeriod = "previous 90 days";
        } else if (dateReference === DateReference.ThisMonth) {
            timePeriod = "last month";
        } else if (dateReference === DateReference.LastMonth) {
            timePeriod = "two months ago";
        } else if (dateReference === DateReference.ThisWeek) {
            timePeriod = "last week";
        }
        return timePeriod;
    }

    public getWidgetTypes(currentUser: CurrentUser): Dashboard[] {
        const savedDashboardWidgetDefinitions = deepCopy(
            dashboardWidgetDefinitions
        );
        return savedDashboardWidgetDefinitions.map((widgetCategory) => {
            widgetCategory.widgetTypes = widgetCategory.widgetTypes.filter(
                (widgetType) => {
                    if (currentUser.systemAccount.isCarrier) {
                        return widgetType.carriers;
                    }

                    if (currentUser.systemAccount.isAgency) {
                        return widgetType.agents;
                    }
                }
            );
            return widgetCategory;
        });
    }

    public initAutoRefresh(interval: number): void {
        this.stopAutoRefresh();

        if (interval > 0) {
            this.refreshRate = this.$interval(this.autoRefresh, interval);
        }
    }

    public initializeDashboardsCollection(): void {
        if (!this.dashboardConfiguration.options) {
            this.dashboardConfiguration.options = {};
        }

        if (
            angular.isUndefined(
                this.dashboardConfiguration.options.refreshInterval
            )
        ) {
            this.dashboardConfiguration.options.refreshInterval = -1;
            this.dashboardConfiguration.options.selectedRefreshOption = {
                label: "Never Refresh",
                value: -1
            };
        }

        this.initAutoRefresh(
            this.dashboardConfiguration.options.refreshInterval
        );

        this.setRefreshTime();
    }

    public initializeWidget(widget: DashboardWidget, needsKey?: boolean): void {
        if (needsKey === undefined) {
            needsKey = false;
        }

        if (!widget.key || needsKey) {
            widget.key = this.guidCreator.create();
        }

        if (angular.isUndefined(widget.options)) {
            widget.options = {
                title: ""
            };
        }
    }

    public load(): IPromise<void> {
        return this.$http
            .get<A3ApiResponse<string>>(
                this.systemSettings.apiBaseUrl +
                    "UserActions/GetDashboardConfiguration"
            )
            .then((response) => {
                if (response.data.value) {
                    this.dashboardConfiguration = JSON.parse(
                        response.data.value
                    );
                } else {
                    this.dashboardConfiguration.dashboards =
                        this.getDefaultDashboard(
                            this.currentUserResolver.getCurrentUser()
                        );
                }

                this.initializeDashboardsCollection();

                for (const dashboard of this.dashboardConfiguration
                    .dashboards) {
                    for (const widget of dashboard.widgets) {
                        this.initializeWidget(widget);
                    }
                }

                this.selectDashboard(0);
            });
    }

    public moveDashboard(oldIndex: number, newIndex: number): void {
        // ng-sortable handles the sorting of this.dashboardConfiguration.dashboards
        this.save();
    }

    public moveWidgetToTargetDashboard(index: number, target: number): void {
        if (this.currentDashboardIndex === target) {
            return;
        }

        const widget = angular.copy(this.currentDashboard.widgets[index]);

        // clear position data
        widget.col = null;
        widget.row = null;

        this.dashboardConfiguration.dashboards[target].widgets.push(widget);
        this.removeWidgetFromCurrentDashboard(index);

        // TODO: Change view to target dashboard?
        this.save();

        const msg = `Widget has been moved to ${this.dashboardConfiguration.dashboards[target].name}.`;
        this.toastMessageCreator.createSuccessMessage(msg);
    }

    public refresh(): void {
        this.autoRefresh();
    }

    public removeDashboard(): void {
        if (this.dashboardConfiguration.dashboards.length <= 1) {
            return;
        }

        this.dashboardConfiguration.dashboards.splice(
            this.currentDashboardIndex,
            1
        );

        if (this.currentDashboardIndex === 0) {
            this.selectDashboard(0);
        } else {
            this.selectDashboard(this.currentDashboardIndex - 1);
        }

        this.save();
    }

    public removeWidgetFromCurrentDashboard(index: number): void {
        this.currentDashboard.widgets.splice(index, 1);
        this.save();
    }

    public renameDashboard(name: string): void {
        this.currentDashboard.name = name;
        this.save();
    }

    public save = () => {
        if (this.saveTimeout) {
            this.$timeout.cancel(this.saveTimeout);
        }

        this.saveTimeout = this.$timeout(() => {
            return this.$http
                .post(
                    this.systemSettings.apiBaseUrl +
                        "UserActions/SaveDashboardConfiguration",
                    {
                        dashboardConfiguration: JSON.stringify(
                            this.dashboardConfiguration
                        )
                    }
                )
                .catch(() => {
                    this.toastMessageCreator.createErrorMessage(
                        "An error occurred trying to save your dashboard configuration"
                    );
                });
        }, 4000);
    };

    public selectDashboard(index: number): void {
        this.currentDashboard = this.dashboardConfiguration.dashboards[index];
        this.currentDashboardIndex = index;
        this.refreshFunctions = [];
    }

    public setAutoRefresh(interval: number): void {
        switch (interval) {
            case 300000:
                this.dashboardConfiguration.options.refreshInterval = interval;
                this.dashboardConfiguration.options.selectedRefreshOption = {
                    label: "5 mins",
                    value: 300000
                };
                break;

            case 900000:
                this.dashboardConfiguration.options.refreshInterval = interval;
                this.dashboardConfiguration.options.selectedRefreshOption = {
                    label: "15 mins",
                    value: 900000
                };
                break;

            case 1800000:
                this.dashboardConfiguration.options.refreshInterval = interval;
                this.dashboardConfiguration.options.selectedRefreshOption = {
                    label: "30 mins",
                    value: 1800000
                };
                break;

            case 3600000:
                this.dashboardConfiguration.options.refreshInterval = interval;
                this.dashboardConfiguration.options.selectedRefreshOption = {
                    label: "1 hour",
                    value: 3600000
                };
                break;

            default:
                this.dashboardConfiguration.options.refreshInterval = -1;
                this.dashboardConfiguration.options.selectedRefreshOption = {
                    label: "Never Refresh",
                    value: -1
                };
        }
        this.save();
        this.initAutoRefresh(interval);
    }

    public setRefreshTime(): void {
        this.lastRefreshTime = moment().format("h:mm a");
    }

    public stopAutoRefresh(): void {
        if (angular.isDefined(this.refreshRate)) {
            this.$interval.cancel(this.refreshRate);
        }
        this.refreshRate = undefined;
    }
}

app.service(Injectables.DashboardService, DashboardService);
