import { IDirectiveFactory, IDirectiveLinkFn, IScope } from "angular";
import app from "../../main";
import Inputmask from "inputmask";

const timeMaskDirective: IDirectiveFactory = () => {

    const link: IDirectiveLinkFn = (
        scope: IScope,
        elem: JQuery
    ) => {
        const mask = new Inputmask("hh:mm t");
        mask.mask(elem);
    }

    return {
        link: link
    };
}

app.directive('maskTime', timeMaskDirective);