import { Amount } from "./amount";
import { CourtId } from "./courtId";
import { DateFiled } from "./dateFiled";
import { DateReported } from "./dateReported";
import { DateSatisfied } from "./dateSatisfied";
import { Narratives } from "./narratives";
import { Status } from "./status";
import { VerificationDate } from "./verificationDate";

export interface USLegalItem { 
    dateFiled: DateFiled;
    courtId: CourtId;
    caseNumber: string;
    amount: Amount;
    dateSatisfied: DateSatisfied;
    status: Status;
    verificationDate: VerificationDate;
    defendant: string;
    plaintiff: string;
    dateReported: DateReported;
    narratives: Narratives;
    code: string;
    description: string;
}