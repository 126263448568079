import { IPromise } from "angular";
import { A3RootScope } from "../../api/types/a3RootScope";
import { UserService } from "../../api/userService";
import { Injectables } from "../../configuration/injectables";
import { SystemSettings } from "../../configuration/settings/systemSettings";
import app from "../../main";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";

class NotificationSelectionController {

    public static $inject = [
        Injectables.CurrentUserResolver,
        Injectables.UserService,
        Injectables.ToastMessageCreator,
        Injectables.SystemSettings
    ];

    constructor(
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly userService: UserService,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly systemSettings: SystemSettings
    ) {}

    public receivesDocumentCorrectionCompletionBrowserNotifications: boolean;
    public isCarrier: boolean;
    public isAgent: boolean;
    public isDevelopmentEnvironment: boolean;
    public processingPromise: IPromise<any>;

    public sendTestNotification(): void {
        this.processingPromise =  this.userService.sendTestNotification()
            .then(() => this.toastMessageCreator.createSuccessMessage('Test Notification Sent Successfully'))
            .catch((err) => this.toastMessageCreator.createErrorMessage('There was an error sending the Test Notification:' + err));
    }

    public $onInit(): void {
        this.isCarrier = this.currentUserResolver.getCurrentUser().systemAccount.isCarrier;
        this.isAgent = !this.currentUserResolver.getCurrentUser().systemAccount.isCarrier;

        this.isDevelopmentEnvironment = this.systemSettings.environment === 'Dev';

        this.receivesDocumentCorrectionCompletionBrowserNotifications = true;
    }
}

const notificationSelectionComponent = {
    templateUrl: 'app/components/notificationSelectionForm/notificationSelection.html',
    bindings: { user: '=' },
    controller: NotificationSelectionController,
    controllerAs: 'vm'
};

app.component('notificationSelection', notificationSelectionComponent);
