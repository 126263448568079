const deepFreeze = (obj: object) => {
    // Retrieve the property names defined on object
    const propNames = Reflect.ownKeys(obj);
  
    // Freeze properties before freezing self
    for (const name of propNames) {
      const value = obj[name];
  
      if ((value && typeof value === "object") || typeof value === "function") {
        deepFreeze(value);
      }
    }
  
    return Object.freeze(obj);
}

export default deepFreeze;