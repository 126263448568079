import { EpicIntegrationService } from "../../api/epicIntegrationService";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { SendEpicBillingReportModalOptions } from "./sendEpicBillingReportModalOptions";
import { SendEpicBillingReportModalResult } from "./sendBillingReportModalResult";
import app from "../../main";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";

export class SendBillingReportModal {

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.EpicIntegrationService,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly $uibModalInstance: Modal<SendEpicBillingReportModalResult>,
        private readonly modalOptions: SendEpicBillingReportModalOptions,
        private readonly epicIntegrationService: EpicIntegrationService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {
    }

    public busyIndicator: BusyIndicator;
    public billingEntryIds: number[];
    public bondTransactionIds: number[];
    public hasDuplicateEntries: boolean;
    public hasIncompleteEntries: boolean;
    public recipientEmailAddresses: string[];

    public addRecipient() {
        this.recipientEmailAddresses.push('');
    }

    public removeRecipient(index: number) {
        this.recipientEmailAddresses.splice(index, 1);
    }

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }
    
    public send() {
        if (!this.recipientEmailAddresses.length) {
            return;
        }
        
        this.busyIndicator.message = 'Sending Report...';
        this.busyIndicator.promise = this.epicIntegrationService.sendBillingReport(this.billingEntryIds, this.bondTransactionIds, this.recipientEmailAddresses)
            .then(() => {
                this.$uibModalInstance.close({} as SendEpicBillingReportModalResult);
                this.toastMessageCreator.createSuccessMessage('Report sent successfully');
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to send the report.');
            });
    }

    public loadEmailAddresses(): void {
        this.busyIndicator.message = 'Loading...';
        this.busyIndicator.promise = this.epicIntegrationService.getBillingReportRecipientAddresses()
            .then((emailAddresses) => {
                this.recipientEmailAddresses = emailAddresses;
            });
    }

    public $onInit(): void {
        this.billingEntryIds = this.modalOptions.billingEntryIds;
        this.bondTransactionIds = this.modalOptions.bondTransactionIds;
        this.hasDuplicateEntries = this.modalOptions.hasDuplicateEntries;
        this.hasIncompleteEntries = this.modalOptions.hasIncompleteEntries;

        this.busyIndicator = {};
        
        this.loadEmailAddresses();
    }
}

app.controller('SendBillingReportModal', SendBillingReportModal);
