import { IJQueryService } from "../../../utilities/jquery/iJQueryService";
import defaultWrapper from "../defaultWrapper";
import fieldGroupWrapper from "../fieldGroupWrapper";

export default (element: JQuery, jQueryProvider: IJQueryService) => {
    element.attr('mask-zip', '');
    element.attr('type', 'text');
    element.attr('zip-city-state', element.attr('aa-field'));
    element.attr('is-zip-code', '');
    
    fieldGroupWrapper(element, defaultWrapper(element), jQueryProvider);
};