import { BusyIndicator } from "../../../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../../../configuration/injectables";
import { DashboardService } from "../../dashboardService";
import { DashboardWidget } from "../../types/dashboardWidget";
import app from "../../../../../main";
import { IRootElementService, IQService, IFilterService } from "angular";
import { SlabTextService } from "../../../../../utilities/slabText/slabTextService";
import { JQueryService } from "../../../../../utilities/jquery/jQueryService";
import DropdownOption from "../../../../../api/types/dropdownOption";
import { BondTypeService } from "../../../../../api/bondTypeService";

class DashboardWidgetBondTypeChangesCountController {
    public static $inject = [
        Injectables.$element,
        Injectables.DashboardService,
        Injectables.BondTypeService,
        Injectables.$q,
        Injectables.$filter,
        Injectables.SlabTextService,
        Injectables.JQueryService
    ];

    constructor(
        private readonly $element: IRootElementService,
        private readonly dashboardService: DashboardService,
        private readonly bondTypeService: BondTypeService,
        private readonly $q: IQService,
        private readonly $filter: IFilterService,
        private readonly slabTextService: SlabTextService,
        private readonly jQueryService: JQueryService
    ) {}

    public widget: DashboardWidget;
    public busyIndicator: BusyIndicator;
    public value: string;
    public overComparison: string;
    public dateReferenceOptions: DropdownOption<string>[];
    public typeOptions: DropdownOption<string>[];

    public dateReferenceSelected = (option: string) => {
        this.widget.options.dateReference = option;
        this.loadValue();
        this.dashboardService.save();
    };

    public typeSelected = (option: DropdownOption) => {
        this.widget.options.type = option;
        this.loadValue();
        this.dashboardService.save();
    };

    public loadValue = () => {
        const promises = [];

        if (this.widget.options.dateReference.value === "YTD") {
            promises.push(
                this.bondTypeService.ytdChangeCount(
                    this.widget.options.type.value
                )
            );
            promises.push(
                this.bondTypeService.lastYearChangeCount(
                    this.widget.options.type.value
                )
            );
        } else if (this.widget.options.dateReference.value === "Last 90 Days") {
            promises.push(
                this.bondTypeService.last90DaysChangeCount(
                    this.widget.options.type.value
                )
            );
            promises.push(
                this.bondTypeService.last180ToLast90DaysChangeCount(
                    this.widget.options.type.value
                )
            );
        } else if (this.widget.options.dateReference.value === "This Month") {
            promises.push(
                this.bondTypeService.mtdChangeCount(
                    this.widget.options.type.value
                )
            );
            promises.push(
                this.bondTypeService.lastMonthChangeCount(
                    this.widget.options.type.value
                )
            );
        } else if (this.widget.options.dateReference.value === "Last Month") {
            promises.push(
                this.bondTypeService.lastMonthChangeCount(
                    this.widget.options.type.value
                )
            );
            promises.push(
                this.bondTypeService.twoMonthsAgoChangeCount(
                    this.widget.options.type.value
                )
            );
        } else if (this.widget.options.dateReference.value === "This Week") {
            promises.push(
                this.bondTypeService.thisWeekChangeCount(
                    this.widget.options.type.value
                )
            );
            promises.push(
                this.bondTypeService.lastWeekChangeCount(
                    this.widget.options.type.value
                )
            );
        } else {
            return;
        }

        this.busyIndicator.promise = this.$q
            .all(promises)
            .then((values: number[]) => {
                this.value = this.$filter("number")(values[0]);

                const timePeriod = this.dashboardService.getTimePeriod(
                    this.widget.options.dateReference.value
                );
                const percentIntoTimePeriod =
                    this.dashboardService.getPercentIntoTimePeriod(
                        this.widget.options.dateReference.value
                    );

                this.overComparison =
                    this.dashboardService.calculateComparisonText(
                        values[0],
                        values[1],
                        timePeriod,
                        percentIntoTimePeriod
                    );

                this.updateValueSize(this.value);
            });
    };

    public updateValueSize = (value) => {
        this.slabTextService.setupSlabText(
            this.jQueryService.getElement(".value", this.$element).html(value),
            { maxFontSize: 40 }
        );
    };

    public $onInit = () => {
        this.busyIndicator = {
            message: "Loading..."
        };

        this.dateReferenceOptions = [
            {
                label: "YTD",
                value: "YTD"
            },
            {
                label: "Last 90 Days",
                value: "Last 90 Days"
            },
            {
                label: "This Month",
                value: "This Month"
            },
            {
                label: "This Week",
                value: "This Week"
            }
        ];

        this.typeOptions = [
            {
                label: "All",
                value: "All"
            },
            {
                label: "New Bond Types",
                value: "New"
            },
            {
                label: "Bond Types Made Active",
                value: "Made Active"
            },
            {
                label: "Bond Type Updates",
                value: "Updated"
            }
        ];

        this.loadValue();

        this.dashboardService.refreshFunctions.push(this.loadValue);
    };
}

const dashboardWidgetBondTypeChangesCountComponent = {
    bindings: {
        widget: "="
    },
    templateUrl:
        "app/states/common/dashboard/widgets/bondTypeChangesCount/dashboardWidgetBondTypeChangesCount.html",
    controller: DashboardWidgetBondTypeChangesCountController,
    controllerAs: "vm"
};

app.component(
    "dashboardWidgetBondTypeChangesCount",
    dashboardWidgetBondTypeChangesCountComponent
);
