export interface CustomerListItem {
    id: number;
    name: string;
    physicalAddress: string;
    physicalSuiteAptNumber: string;
    physicalCity: string;
    physicalState: string;
    physicalZip: string;
    lookupCode: string;
    isActive: boolean;
}