import { IQService } from "angular";
import { Rate } from "../../api/types/model/rate";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { RateModalOptions } from "./RateModalOptions";

export class RateModalController {

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.$q
    ]

    constructor (
        private readonly $uibModalInstance: Modal<ng.IPromise<Rate>>,
        private readonly options: RateModalOptions,
        private readonly $q: IQService ) {
    }

    public rateId: number;
    
    public cancel = () => {
        this.$uibModalInstance.dismiss('cancel');
    }

    public save = (rate: Rate) => {
        this.$uibModalInstance.close(this.$q.when(rate));
    }

    public  $onInit = () => {
        this.rateId = this.options.rateId;
    }
}

app.controller('RateModalController', RateModalController);