import { Bond } from "../bond";
import { IBaseEntity } from "./iBaseEntity";
import { MasterApplicationQuestion } from "./masterApplicationQuestion";
import { AdditionalQuestionResponseType } from "./questionResponse";

export interface BondAdditionalQuestionResponse extends IBaseEntity { 
    bond: Bond;
    bondId: number;
    responseText: string;
    questionText: string;
    masterApplicationQuestion: MasterApplicationQuestion;
    masterApplicationQuestionId: number;
    originType: AdditionalQuestionResponseType;
    id: number;
    createdDateTime: Date;
}