import { IBaseEntity } from "./iBaseEntity";
import { SupportTicket } from "./supportTicket";

export interface SupportTicketMessage extends IBaseEntity { 
    a3SupportMessage: boolean;
    author: string;
    message: string;
    supportTicket: SupportTicket;
    supportTicketId: number;
    userId: number;
    id: number;
    createdDateTime: Date;
}