import { ActivityService } from "../../api/activityService";
import { SystemAccountService } from "../../api/systemAccountService";
import { BondAccountRateTier } from "../../api/types/bondAccounts/bondAccountRateTier";
import { BondAccountRateType } from "../../api/types/bondAccounts/bondAccountRateType";
import { BondAccountService } from "../../api/bondAccountService";
import { BondAccountStatus } from "../../api/types/bondAccounts/bondAccountStatus";
import { BondAccount } from "../../api/types/bondAccounts/bondAccount";
import { SelectOption } from "../../api/types/selectOption";
import { ActivityListItem } from "../../components/activityList/activityListItem";
import { ActivityTableQueryOptions } from "../../components/activityList/activityTableQueryOptions";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { IDebounceDelayer } from "../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../utilities/tables/table";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { BondAccountModalOptions } from "./bondAccountModalOptions";
import { BondAccountModalResult } from "./bondAccountModalResult";
import app from "../../main";
import { IPromise } from "angular";

export class BondAccountModalController {
    
    public static $inject = [
        Injectables.Options,
        Injectables.$uibModalInstance,
        Injectables.SystemAccountService,
        Injectables.BondAccountService,
        Injectables.ActivityService,
        Injectables.IDebouceDelayer,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly options: BondAccountModalOptions,
        private readonly $uibModalInstance: Modal<BondAccountModalResult>,
        private readonly systemAccountService: SystemAccountService,
        private readonly bondAccountService: BondAccountService,
        private readonly activityService: ActivityService,
        debouceDelayer: IDebounceDelayer,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {
        this.activitiesTable = new Table(debouceDelayer);
        this.activitiesTable.onChange = this.loadActivities;
    }

    public readonly activitiesTable: Table<ActivityListItem, ActivityTableQueryOptions>;

    public processingPromise: IPromise<any>;
    public processingMessage: string;
    public carrierOptions: SelectOption<Number>[];
    public bondAccount: BondAccount;

    public activityPromise: IPromise<any>;
    public activities: ActivityListItem[];
    public totalActivityCount: number;
    public activitiesLoaded: boolean;
    public newTierRate: number;
    public newTierThreshold: number;
    public bondAccountRateTiersInvalid: boolean;
    public rateTypeOptions: SelectOption<BondAccountRateType>[];

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }

    public save(): void {
        if (this.bondAccountRateTiersInvalid) {
            return;
        }

        this.processingMessage = "Saving...";

        if (!this.bondAccount.id) {
            this.createBondAccount();
        } else {
            this.updateBondAccount();
        }
    }

    private createBondAccount() {
        this.processingPromise = this.bondAccountService.createNewBondAccount(this.bondAccount)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage("Bond account was created successfully");
                this.$uibModalInstance.close({} as BondAccountModalResult);
            });
    }

    private updateBondAccount() {
        this.processingPromise = this.bondAccountService.updateBondAccount(this.bondAccount)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage("Bond account was updated successfully");
                this.$uibModalInstance.close({} as BondAccountModalResult);
            });
    }

    private loadCarrierOptions() {
        return this.systemAccountService.getAppointedCarrierOptions()
            .then((carrierOptions) => {
                this.carrierOptions = carrierOptions;
            });
    }

    private loadBondAccount() {
        return this.bondAccountService.getBondAccountById(this.options.bondAccountId)
            .then((bondAccount) => {
                this.bondAccount = bondAccount;
                this.checkForInvalidRateTiers();
            });
    }

    public activitiesSelected(): void {
        if (this.activitiesLoaded) {
            return;
        }

        this.loadActivities();
    }

    public removeTierRate(rate: BondAccountRateTier, index: number) {
        if (rate.id) {
            rate.isRemoved = rate.isRemoved !== true;
        } else {
            this.bondAccount.rateTiers.splice(index, 1);
        }

        this.checkForInvalidRateTiers();
    }

    private checkForInvalidRateTiers() {
        this.bondAccountRateTiersInvalid = this.bondAccount.rateType == BondAccountRateType.Tiered &&
                                            !this.bondAccount.rateTiers.some((rateTier) => !rateTier.isRemoved);
    }

    public addNewTierRate() {
        if (!this.newTierRate || !this.newTierThreshold) {
            return;
        }

        this.bondAccount.rateTiers.push({
            thresholdAmount: this.newTierThreshold,
            ratePerThousand: this.newTierRate
        } as BondAccountRateTier);

        this.newTierRate = null;
        this.newTierThreshold = null;
        this.checkForInvalidRateTiers();
    }

    public rateTypeChanged() {
        this.checkForInvalidRateTiers();
    }

    private loadActivities = (): void => {
        this.activityPromise = this.activityService.getBondAccountActivities(
                this.options.bondAccountId, 
                this.activitiesTable.queryOptions)
            .then((response) => {
                this.activitiesTable.setData(response.items, response.totalRecordCount);
                this.activitiesLoaded = true;
            });
    }

    public $onInit() { 
        this.rateTypeOptions = [
            { label: 'Rate Per Thousand', value: BondAccountRateType.RatePerThousand },
            { label: 'Tiered', value: BondAccountRateType.Tiered },
        ];

        this.processingMessage = "Loading...";
        this.processingPromise = this.loadCarrierOptions()
            .then(() => {

                if (this.options.bondAccountId) {
                    return this.loadBondAccount();
                } else {
                    this.bondAccount = {
                        customerId: this.options.customerId,
                        status: BondAccountStatus.Active,
                        rateType: BondAccountRateType.RatePerThousand,
                        rateTiers: []
                    } as BondAccount;
                }
            });
    }
}

app.controller('BondAccountModalController', BondAccountModalController);