import { BillingEntryService } from "../../../api/billingEntryService";
import { Injectables } from "../../../configuration/injectables";
import app from "../../../main";
import { BillingEntryReportItem } from "./billingEntryReportItem";
import { IDebounceDelayer } from "../../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../../utilities/tables/table";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { BillingReportDropdownFilterOptions } from "./billingEntryReportFilterDropdown/billingReportDropdownFilterOptions";
import { InvoiceService } from "../../../api/invoiceService";
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";

export class ReportBillingEntryController {
    public static $inject = [
        Injectables.BillingEntries,
        Injectables.InvoiceService,
        Injectables.IDebouceDelayer,
        Injectables.CurrentUserResolver,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly billingEntriesService: BillingEntryService,
        private readonly invoiceService: InvoiceService,
        debouceDelayer: IDebounceDelayer,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {
        this.table = new Table(debouceDelayer);
        this.table.onChange = this.loadData;
    }

    public readonly table: Table<BillingEntryReportItem, BillingReportDropdownFilterOptions>;
    public readonly filters: BillingReportDropdownFilterOptions;

    public busyIndicator: BusyIndicator;

    public save = () => {
        const request = {
            items: this.table.data.map((billingEntry) => {
                return {
                    id: billingEntry.id,
                    producerUserId: billingEntry.producerUserId,
                    productionCredit: billingEntry.productionCredit
                }
            })
        };

        this.busyIndicator = {
            message: 'Saving...',
            promise: this.billingEntriesService.saveBillingEntryReport(request)
                .then(() => {
                    this.toastMessageCreator.createSuccessMessage('Billing entries saved successfully');
                })
                .catch(() => {
                    this.toastMessageCreator.createErrorMessage('An error occurred saving billing entries');
                })
        }
    }

    public refresh = () => {
        this.loadData();
    };

    public get canEditBillingEntries(): boolean {
        return this.currentUserResolver.getCurrentUser().permissions.canEditBillingEntriesReport;
    }

    public exportBillingEntries(){
        this.busyIndicator = {
            message: "Loading...",
            promise: this.billingEntriesService.downloadBillingEntriesExcelReport(this.table.queryOptions)
        };
    }
    
    public getInvoiceDownloadUrl(invoiceId: number): string {
        return this.invoiceService.getInvoiceDocumentUrl(invoiceId);
    }

    public loadData = () => {
        this.busyIndicator.message = "Loading Billing Entries...";
        this.busyIndicator.promise = this.billingEntriesService
            .getBillingEntriesReportData(this.table.queryOptions)
            .then((response) => {
                this.table.setData(response.items, response.totalRecordCount);
            }); 
    };

    public $onInit(): void {
        this.busyIndicator = {};

        if (this.filters) {
            this.table.applyFilters(this.filters);
        }

        this.loadData();
    }
}

const billingEntryReportComponent = {
    bindings: {
        filters: "=?",
    },
    controller: ReportBillingEntryController,
    templateUrl: "app/states/agentSpecific/billingEntry/billingEntryReport.html",
    controllerAs: "vm"
};

app.component("billingEntryReport", billingEntryReportComponent);
