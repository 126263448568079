import { CurrentUser } from "../../api/types/authentication/currentUser";
import { CurrentUserSystemAccount } from "../../api/types/authentication/currentUserSystemAccount";
import { Memo } from "../../api/types/model/memo";
import { Injectables } from "../../configuration/injectables";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";
import { BusyIndicator } from "../busyIndicator/busyIndicator";
import { MemoService } from "../../api/memoService";
import app from "../../main";
import { Table } from "../../utilities/tables/table";
import MemoPageRequest from "../../api/types/memoPageRequest";

class MemoListController { 

    public static $inject = [
        Injectables.MemoService,
        Injectables.CurrentUserResolver
    ];

    constructor(
        private readonly MemoService: MemoService,
        private readonly currentUserResolver: CurrentUserResolver) {
    }

    public table: Table<Memo, MemoPageRequest>;
    public newMemo: string;
    public bondId?: number;
    public applicationId?: number;
    public busyIndicator: BusyIndicator;

    public get isCarrier(): boolean {
        return this.currentUserResolver.getCurrentUser().systemAccount.isCarrier;
    }

    public get user(): CurrentUser {
        return this.currentUserResolver.getCurrentUser();
    }

    public get systemAccount(): CurrentUserSystemAccount {
        return this.currentUserResolver.getCurrentUser().systemAccount;
    }
         
    public addNewMemo = () => {
        if (!this.newMemo) {
            return;
        }

        var memo = {
            memoText: this.newMemo,
            userId: this.user.user.userId,
            systemAccountId: this.systemAccount.id,
            applicationId: this.applicationId,
            bondId: this.bondId
        } as Memo;

        this.busyIndicator.message = 'Saving...';
        this.busyIndicator.promise = this.MemoService.saveMemo(memo)
            .then(() => {
                this.newMemo = '';
                return this.table.refresh();
            });
    }

    public $onInit() {
        this.busyIndicator = {};
        this.table.queryOptions.bondId = this.bondId;
        this.table.queryOptions.applicationId = this.applicationId;
    }
}

const memoListComponent = {
    templateUrl: 'app/components/memoList/memoList.html',
    bindings: { 
        table: '<',
        applicationId: '<?', 
        bondId: '<?'
    },
    controller: MemoListController,
    controllerAs: 'vm'
};
    
app.component('memoList', memoListComponent);