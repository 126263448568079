import { SupportService } from "../../api/supportService";
import { DocumentCorrectionRequest } from "../../api/types/model/documentCorrectionRequest";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { Document } from "../../api/types/model/document";
import app from "../../main";
import { IPromise } from "angular";
import { DocumentCorrectionModalOptions } from "./DocumentCorrectionModalOptions";

export class DocumentCorrectionModalController {

    public static $inject = [
        Injectables.$uibModalInstance, 
        Injectables.Options, 
        Injectables.SupportService,  
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly $uibModalInstance: Modal<DocumentCorrectionRequest>,
        private readonly options: DocumentCorrectionModalOptions,
        private readonly supportService: SupportService,
        private readonly toastMessageCreator: ToastMessageCreator) {
    }

    public documentCorrectionRequest: DocumentCorrectionRequest;
    public documentId: number;
    public documentName: string;
    public bondId: number;
    public bondTransactionDocumentVersionId: number;
    public comments: string;
    public processingPromise: IPromise<any>;

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }

    public done(): void {
        this.$uibModalInstance.close(this.documentCorrectionRequest);
    }

    public submit(): void {
        this.processingPromise = this.supportService.requestDocumentCorrection(this.documentId, this.bondId, this.bondTransactionDocumentVersionId, this.comments)
            .then((documentCorrectionRequest) => {
                this.documentCorrectionRequest = documentCorrectionRequest;
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to submit your request. Please close this window and try again');
            });
    }

    public $onInit(): void {
        this.documentName = this.options.documentName;
        this.documentId = this.options.documentId;
        this.bondId = this.options.bondId;
        this.bondTransactionDocumentVersionId = this.options.bondTransactionDocumentVersionId;
    }
}

app.controller('DocumentCorrectionModalController', DocumentCorrectionModalController);
