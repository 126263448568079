import { IBaseEntity } from "./iBaseEntity";
import { UnderwritingConfiguration } from "./underwritingConfiguration";
import { UnderwritingConfigurationBondFormAttachmentCondition } from "./underwritingConfigurationBondFormAttachmentCondition";
import { Document } from "./document";

export interface UnderwritingConfigurationBondFormAttachment extends IBaseEntity {
    underwritingConfigurationId: number;
    documentId: number;
    underwritingConfiguration: UnderwritingConfiguration;
    document: Document;
    id: number;
    createdDateTime: Date;
    conditions: UnderwritingConfigurationBondFormAttachmentCondition[];
}