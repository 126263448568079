import { IHttpService, IPromise } from "angular";
import app from "../main";
import A3ApiResponse from "./types/a3ApiResponse";
import { FileDownloader } from "./fileDownloader";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/settings/systemSettings";
import { LeadReportItem } from "../states/agentSpecific/leads/leadReportItem";
import { LeadsReportDropdownFilterOptions } from "../states/agentSpecific/leads/leadsReportFilterDropdown/leadsReportDropdownFilterOptions";
import { PageResponse } from "./types/pageResponse";

export class LeadReportService {

    public static $inject = [
        Injectables.$http,
        Injectables.SystemSettings,
        Injectables.FileDownloader
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly systemSettings: SystemSettings,
        private readonly fileDownloader: FileDownloader
    ) {
    }

    public getLeadEntriesReportData(tableQueryOptions: LeadsReportDropdownFilterOptions): IPromise<PageResponse<LeadReportItem>> {
        const queryString = this.buildLeadReportQueryString(tableQueryOptions);
        const url = `${this.systemSettings.apiBaseUrl}LeadActions/GetLeadsEntryReport${queryString}`;
        
        return this.$http.get<A3ApiResponse<PageResponse<LeadReportItem>>>(url)
            .then((response) => response.data.value);
    }

    public downloadLeadEntriesExcelReport( tableQueryOptions: LeadsReportDropdownFilterOptions): IPromise<PageResponse<LeadReportItem>> {
        const queryString = this.buildLeadReportQueryString(tableQueryOptions);
        const url = `${this.systemSettings.apiBaseUrl}LeadActions/GetLeadsEntryExcelReport${queryString}`;

        return this.fileDownloader.downloadFile(url,null);
    }

    private buildLeadReportQueryString(tableQueryOptions: LeadsReportDropdownFilterOptions) {
        if (!tableQueryOptions) {
            tableQueryOptions = {
                pageNumber: 1,
                recordsPerPage: 10,
                orderBy: "",
                searchPhrase: ""
            } as LeadsReportDropdownFilterOptions;
        }

        if (!tableQueryOptions.searchPhrase) {
            tableQueryOptions.searchPhrase = "";
        }

        if (!tableQueryOptions.orderBy) {
            tableQueryOptions.orderBy = "Leads.CreatedDateTime desc";
        }

        if (!tableQueryOptions.pageNumber) {
            tableQueryOptions.pageNumber = 1;
        }

        if (!tableQueryOptions.recordsPerPage) {
            tableQueryOptions.recordsPerPage = 10;
        }

        let queryString = "?";

        queryString += `&pageNumber=${tableQueryOptions.pageNumber}`;
        queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
        queryString += `&orderBy=${tableQueryOptions.orderBy}`;
        queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;

        if (tableQueryOptions.leadType) {
            queryString += `&leadType=${tableQueryOptions.leadType}`;
        }
        if (tableQueryOptions.minBondAmount) {
            queryString += `&minBondAmount=${tableQueryOptions.minBondAmount}`;
        }

        if (tableQueryOptions.maxBondAmount) {
            queryString += `&maxBondAmount=${tableQueryOptions.maxBondAmount}`;
        }

        if (tableQueryOptions.startDate) {
            queryString += `&startDate=${tableQueryOptions.startDate}`;
        }

        if (tableQueryOptions.endDate) {
            queryString += `&endDate=${tableQueryOptions.endDate}`;
        }

        return queryString;
    }
}

app.service(Injectables.LeadReportService, LeadReportService);
