import { IHttpService, IPromise, IQService } from "angular";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/settings/systemSettings";
import app from "../main";
import { ObligeeHistoryListItem } from "../modals/obligeeHistoryModal/obligeeHistoryListItem";
import { ObligeeHistoryTableQueryOptions } from "../modals/obligeeHistoryModal/obligeeHistoryTableQueryOptions";
import { ObligeeDetail } from "../states/common/obligeeDetail/obligeeDetails";
import { ObligeeTableItem } from "../states/common/obligees/obligeeTableItem";
import { ObligeeTableQueryOptions } from "../states/common/obligees/obligeeTableQueryOptions";
import { ODataFactory, ODataQuery, ODataService, ODataEndpoint } from "./odata";
import A3ApiResponse from "./types/a3ApiResponse";
import { Obligee } from "./types/model/obligee";
import { PageResponse } from "./types/pageResponse";

export class ObligeeService {

    public static $inject = [
        Injectables.ODataFactory, 
        Injectables.$q,
        Injectables.$http,
        Injectables.SystemSettings
    ];

    constructor(
        private readonly odata: ODataFactory,
        private readonly $q: IQService,
        private readonly $http: IHttpService,
        private readonly systemSettings: SystemSettings) {
    }

    public getObligeeDetail(id:number) : IPromise<ObligeeDetail> {
        return this.$http.get<A3ApiResponse<ObligeeDetail>>(this.systemSettings.apiBaseUrl + 'ObligeeActions/GetObligeeDetail?obligeeId=' + id)
            .then((response) => response.data.value);
    }

    public getObligeeById(obligeeId: number): IPromise<Obligee> {
        const svc = this.odata.getService<Obligee>(ODataEndpoint.Obligee);
        
        return svc.get(this.buildODataQuery(obligeeId))
            .then((response) => response.data.value[0]);
    }

    private buildODataQuery(obligeeId: number): ODataQuery {
        const query = this.odata.getQuery();

        query.filter('id eq ' + obligeeId);
        query.expand('bondTypes($select=name,id)');

        return query;
    }

    public saveObligee(obligee: ObligeeDetail): IPromise<number> {
        return this.$http
            .post<A3ApiResponse<number>>(this.systemSettings.apiBaseUrl + 'ObligeeActions/SaveObligee', obligee)
            .then((response) => {
                if (response.data.value) {
                    return response.data.value;
                } else {
                    return this.$q.reject(response.data.value);
                }
            });
    }

    public getObligeeHistoryListItems(tableQueryOptions: ObligeeHistoryTableQueryOptions): IPromise<PageResponse<ObligeeHistoryListItem>> {
        if (!tableQueryOptions.pageNumber) {
                tableQueryOptions.pageNumber = 1;
        }

        if (!tableQueryOptions.recordsPerPage) {
            tableQueryOptions.recordsPerPage = 10;
        }

        if (!tableQueryOptions.searchPhrase) {
            tableQueryOptions.searchPhrase = '';
        } 

        if (!tableQueryOptions.orderBy) {
            tableQueryOptions.orderBy = 'CreatedDateTime';
        }

        let queryString = `obligeeId=${tableQueryOptions.obligeeId}`;
        queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
        queryString += `&pageNumber=${tableQueryOptions.pageNumber}`;       
        queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;
        queryString += `&orderBy=${tableQueryOptions.orderBy}`;
    
        return this.$http
            .get<A3ApiResponse<PageResponse<ObligeeHistoryListItem>>>(`${this.systemSettings.apiBaseUrl}ObligeeActions/GetObligeeHistoryListItems?${queryString}`)
            .then((response) => response.data.value);         
    }

    public getObligees(tableQueryOptions: ObligeeTableQueryOptions) {
        if (!tableQueryOptions) {
            tableQueryOptions = {
                pageNumber: 1,
                recordsPerPage: 10,
                orderBy: '',
                searchPhrase: ''
            };
        }

        if (!tableQueryOptions.searchPhrase) {
            tableQueryOptions.searchPhrase = '';
        }

        if (!tableQueryOptions.orderBy) {
            tableQueryOptions.orderBy = 'Obligees.CreatedDateTime';
        }

        let queryString = `?`;

        queryString += `&pageNumber=${tableQueryOptions.pageNumber}`;
        queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
        queryString += `&orderBy=${tableQueryOptions.orderBy}`;
        queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;

        return this.$http
            .get<A3ApiResponse<PageResponse<ObligeeTableItem>>>(this.systemSettings.apiBaseUrl + 'ObligeeActions/GetObligees' + queryString)
            .then(response => response.data.value);
    }

    public deleteById(id: number): IPromise<void> {
        return this.$http.post(this.systemSettings.apiBaseUrl + 'ObligeeActions/deleteById?Id=' + id, null)
            .then(() => {});
    }
}

app.service(Injectables.ObligeeService, ObligeeService);