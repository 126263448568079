import { IJQueryService } from "../../../utilities/jquery/iJQueryService";
import defaultWrapper from "../defaultWrapper";
import fieldGroupWrapper from "../fieldGroupWrapper";

export default (element: JQuery, jQueryProvider: IJQueryService) => {
    element.attr('mask-money', '');
    element.attr('is-float', '');
    element.attr('type', 'text');
    element.attr('ondblclick', 'this.setSelectionRange(0, this.value.length)');
    
    fieldGroupWrapper(element, defaultWrapper(element), jQueryProvider);
};