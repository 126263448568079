import { BillToType } from "../billToType";
import { CommissionType } from "./commissionType";
import { CommissionCalculationType } from "./eProducerAccount";
import { Fee } from "./fee";
import { QuoteType } from "./quote";

export interface DefaultQuote {
    applicationId: number;
    carrierSystemAccountId?: number;
    writingCompanyId?: number;
    bondNumberGroupId?: number;
    carrierAppointmentId?: number;
    term: number;
    premium: number;
    minimumPremium: number;
    brokerEProducerAccountId?: number;
    brokerEProducerAccountName?: string;
    brokerEProducerAccountLookupCode?: string;
    brokerCommission?: number;
    billToType?: BillToType;
    brokerCommissionCalculationType?: CommissionCalculationType;
    brokerCommissionPercent?: number;
    quoteType: QuoteType;
    carrierCompanyName?: string;
    desiredEffectiveDate?: Date;
    agencyCompanyName?: string;
    bondAmount?: number;
    bondType?: string;
    nameOnBond?: string;
    fees?: Fee[];
    commission?: number;
    commissionType?: CommissionType;
    commissionPercent?: number;
    bondTypeId?: number;
}