import { BondService } from "../../../api/bondService";
import { CurrentUser } from "../../../api/types/authentication/currentUser";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../configuration/injectables";
import { Table } from "../../../utilities/tables/table";
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";
import { IDebounceDelayer } from "../../../utilities/debounceDelayer/iDebouceDelayer";
import { PurchasedBondsReportDropdownFilterOptions } from "./purchasedBondsReportDropdown/purchasedBondsReportDropdownFilterOptions";
import { PurchasedBondItem } from "./purchasedBondItem";

export class ReportPurchasedBondsController {

    public static $inject = [
        Injectables.CurrentUserResolver,
        Injectables.BondService,
        Injectables.IDebouceDelayer,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly bondService: BondService,
        debouceDelayer: IDebounceDelayer,
    ) {
        this.table = new Table(debouceDelayer);
        this.table.onChange = this.loadData;
    }

    public readonly table: Table<PurchasedBondItem, PurchasedBondsReportDropdownFilterOptions>;

    get isCarrier(): boolean {
        return this.currentUser.systemAccount.isCarrier;
    }

    public busyIndicator: BusyIndicator;
    public currentUser: CurrentUser;

    public loadData = () => {
        this.busyIndicator = {
            message: "Loading Purchased Bonds...",
            promise: this.bondService
                .getPurchasedBondReport(this.table.queryOptions)
                .then((response) => {
                    this.table.setData(response.items, response.totalRecordCount);
                })
        };
    };

    public exportPurchasedBondReport = () =>{
        this.busyIndicator = {
            message: "Loading...",
            promise: this.bondService.downloadPurchasedBondEntriesExcelReport(this.table.queryOptions)
        };
    }

    public $onInit(): void {
        this.currentUser = this.currentUserResolver.getCurrentUser();
        
        this.loadData();
    }
}
