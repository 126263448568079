// tslint:disable:object-literal-key-quotes

import { IPromise } from "angular";
import { AuthService } from "../../../api/authService";
import { Injectables } from "../../../configuration/injectables";
import { JQueryService } from "../../../utilities/jquery/jQueryService";
import { State } from "../../state";
import { ForgotPasswordStateParamse } from "./ForgotPasswordStateParamse";

export class ForgotPasswordController {
    public static $inject = [
        Injectables.$state,
        Injectables.$stateParams,
        Injectables.AuthService,
        Injectables.JQueryService
    ];

    constructor(
        private readonly $state: State,
        private readonly $stateParams: ForgotPasswordStateParamse,
        private readonly authService: AuthService,
        private readonly jQueryService: JQueryService
    ) {}

    public email: string;
    public emailFocus: boolean;
    public formSubmitted: boolean;
    public emailValidated: boolean;
    public incorrectPassword: boolean;
    public message: string;
    public authPromise: IPromise<any>;

    public $onInit(): void {
        this.email = null;
        this.emailFocus = true;
        this.formSubmitted = false;
        this.emailValidated = false;
        this.incorrectPassword = false;
        this.message = "";

        if (this.$stateParams.email) {
            this.email = this.$stateParams.email;
        }
    }

    public cancel(): void {
        this.$state.go("login", { email: this.email });
    }

    public sendResetRequest(isEmailInvalid): void {
        this.jQueryService.getElement("#mainControls").addClass("a-btn-ar");

        this.message = "";
        this.emailValidated = true;
        this.formSubmitted = true;

        if (!isEmailInvalid) {
            this.authPromise = this.authService
                .forgotPassword(this.email)
                .finally(() => {
                    this.$state.go("login", {
                        message:
                            "If a matching email was found, you will be sent instructions to reset your password.",
                        email: this.email
                    });
                });
        } else {
            this.jQueryService
                .getElement("#mainControls")
                .removeClass("a-btn-ar");
        }
    }
}
