import { SelectOption } from "../../api/types/selectOption";
import app from "../../main";
import isDefined from "../../utilities/angularUtilities/isDefined";

class TransactionTypeCodeDropdownController {
    
    public static $inject: string[] = [];

    constructor() { }

    public isDisabled: boolean;
    public code: string;
    public isRequired: boolean;
    public options: SelectOption<string>[];
    public id: string;
    public aaLabelStrategy: string;
    public aaFgClass: string;
    
    public $onInit(): void {
        this.options = [
            { label: 'New Business', value: 'NEWB' },
            { label: 'Renewal', value: 'RENB' },
            { label: 'Cancellation', value: 'CANC' },
            { label: 'Reinstatement', value: 'REIN' },
            { label: 'Rider', value: 'ENDT' },
        ];

        if (!this.aaLabelStrategy) {
            this.aaLabelStrategy = 'default'
        }

        if (!this.aaFgClass) {
            this.aaFgClass = ''
        }
    }
}

const transactionTypeCodeDropdownDirective = () => {
    return {
        restrict: 'E',
        templateUrl: 'app/components/transactionTypeCodeDropdown/transactionTypeCodeDropdown.html',
        scope: {},
        transclude: true,
        bindToController: {
            code: '=',
            aaLabelStrategy: '@?',
            aaFgClass: '@?'
        },
        link: (scope, elm, attrs, ctrl: any) => {
            if (isDefined(attrs.required)) {
                ctrl.isRequired = true;
            }

            if (isDefined(attrs.ngRequired)) {
                ctrl.isRequired = scope.$parent.$eval(attrs.ngRequired);
            }

            if (isDefined(attrs.id)) {
                ctrl.id = attrs.id;
                elm.removeAttr('id');
            }

            if (isDefined(attrs.disabled)) {
                ctrl.isDisabled = true;
            }
        },
        controller: TransactionTypeCodeDropdownController,
        controllerAs: 'vm'
    };
};

app.directive('transactionTypeCodeDropdown', transactionTypeCodeDropdownDirective);