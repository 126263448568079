
//https://github.com/dpiccone/ng-pageslide
import * as angular from "angular";
import { IDocumentService, ITimeoutService, IScope } from "angular";
import { A3RootScope } from "../../api/types/a3RootScope";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { StateChangeListener } from "../../states/stateChangeListener";

type PageSlideScope = IScope & {
    psSide?: string;
    psSpeed?: string;
    psSize?: string;
    psZindex?: string;
    psClassName?: string;
    psPush?: string;
    psContainer?: string | boolean;
    psKeyListener?: string;
    psBodyClass?: string | boolean;
    psClickOutside?: string;
    psClass?: string;
    psOpen?: boolean;
    onopen?: () => {};
    onclose?: () => {};
    psAutoClose?: boolean;
};

type pageSlideOptions = {
    side?: string;
    speed?: string;
    size?: string;
    zindex?: string | number;
    className?: string;
    push?: boolean;
    container?: string | boolean;
    keyListener?: boolean;
    bodyClass?: string | boolean;
    clickOutside?: boolean;
};

const pageSlideDirective = (
    $document: IDocumentService,
    $timeout: ITimeoutService,
    stateChangeListener: StateChangeListener
) => {

    const link = ($scope: PageSlideScope, el: JQuery) => {

        var param: pageSlideOptions = {};

        param.side = $scope.psSide || 'right';
        param.speed = $scope.psSpeed || '0.5';
        param.size = $scope.psSize || '350px';
        param.zindex = $scope.psZindex || 10;
        param.className = $scope.psClass || 'ng-pageslide';
        param.push = $scope.psPush === 'true';
        param.container = $scope.psContainer || false;
        param.keyListener = $scope.psKeyListener === 'true';
        param.bodyClass = $scope.psBodyClass || false;
        param.clickOutside = $scope.psClickOutside !== 'false';

        param.push = param.push && !param.container;

        el.addClass(param.className);

        /* DOM manipulation */

        var content, slider, body, isOpen = false;

        if (param.container) {
            body = document.getElementById(param.container as string);
        } else {
            body = document.body;
        }

        const onBodyClick = (e) => {
            if(isOpen && !slider.contains(e.target)) {
                isOpen = false;
                $scope.psOpen = false;
                $scope.$apply();
            }

            if($scope.psOpen) {
                isOpen = true;
            }
        }

        const setBodyClass = (value) => {
            if (param.bodyClass) {
                var bodyClass = param.className + '-body';
                var bodyClassRe = new RegExp(' ' + bodyClass + '-closed| ' + bodyClass + '-open');
                body.className = body.className.replace(bodyClassRe, '');
                body.className += ' ' + bodyClass + '-' + value;
            }
        }

        setBodyClass('closed');

        slider = el[0];

        if (slider.tagName.toLowerCase() !== 'div' &&
            slider.tagName.toLowerCase() !== 'page-slide') {
            throw new Error('Pageslide can only be applied to <div> or <page-slide> elements');
        }

        if (slider.children.length === 0) {
            throw new Error('You need to have content inside the <page-slide>');
        }

        content = angular.element(slider.children);

        body.appendChild(slider);

        slider.style.zIndex = param.zindex;
        slider.style.position = 'fixed';
        slider.style.transitionDuration = param.speed + 's';
        slider.style.webkitTransitionDuration = param.speed + 's';
        slider.style.height = param.size;
        slider.style.transitionProperty = 'top, bottom, left, right';

        if (param.push) {
            body.style.position = 'absolute';
            body.style.transitionDuration = param.speed + 's';
            body.style.webkitTransitionDuration = param.speed + 's';
            body.style.transitionProperty = 'top, bottom, left, right';
        }

        if (param.container) {
            slider.style.position = 'absolute';
            body.style.position = 'relative';
            body.style.overflow = 'hidden';
        }

        const onTransitionEnd = () => {
            if ($scope.psOpen) {
                if (typeof $scope.onopen === 'function') {
                    $scope.onopen();
                }
            } else {
                if (typeof $scope.onclose === 'function') {
                    $scope.onclose();
                }
            }
        }

        slider.addEventListener('transitionend', onTransitionEnd);

        const initSlider = () => {
            switch (param.side) {
                case 'right':
                    slider.style.width = param.size;
                    slider.style.height = '100%';
                    slider.style.top = '2px';
                    slider.style.bottom = '0px';
                    slider.style.right = '0px';

                    if (param.container === false) {
                        slider.style.top = '104px';
                        slider.style.bottom = '40px';
                    }

                    break;
                case 'left':
                    slider.style.width = param.size;
                    slider.style.height = '100%';
                    slider.style.top = '0px';
                    slider.style.bottom = '40px';
                    slider.style.left = '0px';
                    break;
                case 'top':
                    slider.style.height = param.size;
                    slider.style.width = '100%';
                    slider.style.left = '0px';
                    slider.style.top = '0px';
                    slider.style.right = '0px';
                    break;
                case 'bottom':
                    slider.style.height = param.size;
                    slider.style.width = '100%';
                    slider.style.bottom = '0px';
                    slider.style.left = '0px';
                    slider.style.right = '0px';
                    break;
            }
        }

        initSlider();

        const psClose = (slider, param) => {
            switch (param.side) {
                case 'right':
                    slider.style.right = "-" + param.size;
                    if (param.push) {
                        body.style.right = '0px';
                        body.style.left = '0px';
                    }
                    break;
                case 'left':
                    slider.style.left = "-" + param.size;
                    if (param.push) {
                        body.style.left = '0px';
                        body.style.right = '0px';
                    }
                    break;
                case 'top':
                    slider.style.top = "-" + param.size;
                    if (param.push) {
                        body.style.top = '0px';
                        body.style.bottom = '0px';
                    }
                    break;
                case 'bottom':
                    slider.style.bottom = "-" + param.size;
                    if (param.push) {
                        body.style.bottom = '0px';
                        body.style.top = '0px';
                    }
                    break;
            }

            if (param.keyListener) {
                $document.off('keydown', handleKeyDown);
            }

            if (param.clickOutside) {
                $document.off('click', onBodyClick);
            }
            isOpen = false;
            setBodyClass('closed');
            $scope.psOpen = false;
        }

        const psOpen = (slider, param) => {
            switch (param.side) {
                case 'right':
                    slider.style.right = "0px";
                    if (param.push) {
                        body.style.right = param.size;
                        body.style.left = '-' + param.size;
                    }
                    break;
                case 'left':
                    slider.style.left = "0px";
                    if (param.push) {
                        body.style.left = param.size;
                        body.style.right = '-' + param.size;
                    }
                    break;
                case 'top':
                    slider.style.top = "0px";
                    if (param.push) {
                        body.style.top = param.size;
                        body.style.bottom = '-' + param.size;
                    }
                    break;
                case 'bottom':
                    slider.style.bottom = "0px";
                    if (param.push) {
                        body.style.bottom = param.size;
                        body.style.top = '-' + param.size;
                    }
                    break;
            }

            $scope.psOpen = true;

            if (param.keyListener) {
                $document.on('keydown', handleKeyDown);
            }

            if (param.clickOutside) {
                $document.on('click', onBodyClick);
            }
            setBodyClass('open');
        }

        const handleKeyDown = (e) => {
            var ESC_KEY = 27;
            var key = e.keyCode || e.which;

            if (key === ESC_KEY) {
                psClose(slider, param);

                // FIXME check with tests
                // http://stackoverflow.com/questions/12729122/angularjs-prevent-error-digest-already-in-progress-when-calling-scope-apply

                $timeout(function () {
                    $scope.$apply();
                });
            }
        }

        // Watchers
        $scope.$watch('psOpen', (value) => {
            if (!!value) {
                psOpen(slider, param);
            } else {
                psClose(slider, param);
            }
        });

        $scope.$watch('psSize', (newValue: string, oldValue: string) => {
            if (oldValue !== newValue) {
                param.size = newValue;
                initSlider();
            }
        });

        stateChangeListener.onStateChanging(() => {
            psClose(slider, param);
        })

        // Events
        $scope.$on('$destroy', () => {
            if (slider.parentNode === body) {
                if (param.clickOutside) {
                    $document.off('click', onBodyClick);
                }
                body.removeChild(slider);
            }

            slider.removeEventListener('transitionend', onTransitionEnd);
        });
    }

    return {
        restrict: 'EA',
        transclude: false,
        scope: {
            psOpen: '=?',
            psAutoClose: '@',
            psSide: '@',
            psSpeed: '@',
            psClass: '@',
            psSize: '@',
            psZindex: '@',
            psPush: '@',
            psContainer: '@',
            psKeyListener: '@',
            psBodyClass: '@',
            psClickOutside: '@',
            onopen: '=?',
            onclose: '=?'
        },
        link: link
    };
}

pageSlideDirective.$inject = [
    Injectables.$document,
    Injectables.$timeout,
    Injectables.StateChangeListener
];

app.directive('pageSlide', pageSlideDirective);