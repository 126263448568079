import { BondStatus, RenewalStatus } from "../../api/types/model/bond";
import { TableQueryOptions } from "../../api/types/tableQuery";

export interface BondListTableQueryOptions extends TableQueryOptions {
    effectiveStartDate?: Date;
    effectiveEndDate?: Date;
    expirationStartDate?: Date;
    expirationEndDate?: Date;
    status?: BondStatus;
    renewalStatus?: RenewalStatus;
}