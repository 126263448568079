import { IBaseEntity } from "./iBaseEntity";
import { MasterLicense } from "./masterLicense";
import { Person } from "./person";

export interface PeopleLicense extends IBaseEntity { 
    number: string;
    masterLicenseId: number;
    expirationDate: Date;
    personId: number;
    masterLicense: MasterLicense;
    person: Person;
    id: number;
    createdDateTime: Date;
}