import { Injectables } from "../configuration/injectables";
import { ODataFactory, ODataEndpoint } from "./odata";
import { WritingCompany } from "./types/model/writingCompany";
import { PageResponse } from "./types/pageResponse";
import { CacheFactory } from "./types/cacheFactory";
import app from "../main";
import A3ApiResponse from "./types/a3ApiResponse";
import { IHttpService, IPromise } from "angular";
import { SystemSettings } from "../configuration/settings/systemSettings";
import DocumentSearchResult from "./types/documents/documentSearchResult";

export class WritingCompanyService {

    public static $inject = [
        Injectables.$http, 
        Injectables.ODataFactory, 
        Injectables.CacheFactory,
        Injectables.SystemSettings
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly odata: ODataFactory,
        private readonly CacheFactory: CacheFactory,
        private readonly systemSettings: SystemSettings ) { }

    // create cache to be used for odata when specified
    // https://github.com/jmdobry/angular-cache
    public cacheStore = this.CacheFactory('writingCompany', {});

    public saveWritingCompany(writingCompanyId: number, writingCompany: WritingCompany): IPromise<WritingCompany> {
        const svc = this.odata.getService<WritingCompany>(ODataEndpoint.WritingCompany);
        return svc
            .save<A3ApiResponse<WritingCompany>>(writingCompany)
            .then((response) => response.data.value);
    }

    public getSealPicture(writingCompanyId: number): IPromise<File> {
        return this.$http
            .get<File>(this.systemSettings.apiBaseUrl + 'Download/WritingCompanySeal?writingCompanyId=' + writingCompanyId)
            .then((img) => img.data );
    }

    public searchDocuments(search: string): IPromise<DocumentSearchResult[]> {
        const url = this.systemSettings.apiBaseUrl + "documentLibrary/GetDocuments?$filter=contains(name, '" + search + "' )&$top=20";
        // '&documentCategoryId=' + $scope.filter.category.id +
        // '&searchType=' + $scope.filter.searchOption +
        // '&filterType=' + $scope.filter.filter +
        // '&skip=0'// + ($scope.currentPage - 1) * $scope.recordsPerPage +
        // '&sort=' + $scope.filter.sort;
        // + $scope.recordsPerPage;

        return this.$http
            .get<PageResponse<DocumentSearchResult>>(url)
            .then((response) => response.data.items);
    }
}

app.service(Injectables.WritingCompanyService, WritingCompanyService);
