import * as angular from "angular";
import { IDirectiveFactory, IDirectiveLinkFn, IScope, IAttributes, IController } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { ModalOpener } from "../../modals/modalOpener";


const showReassignBondModalDirective: IDirectiveFactory = (modalOpener: ModalOpener) => {

    const link: IDirectiveLinkFn = (
        scope: IScope,
        element: JQuery,
        attributes: IAttributes,
        controller:IController
    ) => {
        scope.$watch(attributes.showReassignBondModal, () => {
            var id = 0;

            if (angular.isNumber(attributes.showReassignBondModal)) {
                id = attributes.showReassignBondModal;
            } else {
                id = scope.$eval(attributes.showReassignBondModal);
            }

            if (!angular.isNumber(id) || id <= 0) {
                return;
            }

            element.bind('click', function (e) {
                modalOpener.showReassignBondModal(id)
                    .result
                    .then(() => {})
                    .catch(() => {});

                e.stopPropagation();
            });
        });
    }
    
    return {
        restrict: 'A',
        link: link
    };
}

showReassignBondModalDirective.$inject = [Injectables.ModalOpener];

app.directive('showReassignBondModal', showReassignBondModalDirective);