import { IParseService, IScope, IAttributes, INgModelController, IDirectiveFactory } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";

const directiveItem = ($parse: IParseService) => {
    
    const link  = (
        scope: IScope,
        element: JQuery,
        attributes: IAttributes,
        controller:INgModelController
    ) => {

        let firstValue = $parse(attributes.ngMatch);
        let validator = (value: any) => {

            // try to convert values to string for compairson.
            // number type inputs will have value as string and temp as number
            let temp = firstValue(scope) ? firstValue(scope).toString() : '';
            let valueStr = value ? value.toString() : '';

            let valid = valueStr === temp;
            
            controller.$setValidity('match', valid);
            return value;
        }

        controller.$parsers.unshift(validator);
        controller.$formatters.push(validator);

        attributes.$observe('ngMatch', () => {
            validator(controller.$viewValue);
        });

    }
    
    return  {
        link: link,
        restrict: 'A',
        require: '?ngModel'
    };   
}

directiveItem.$inject = [Injectables.$parse];

app.directive('ngMatch',  directiveItem as IDirectiveFactory); 