import { SystemAccountService } from "../../../api/systemAccountService";
import { Injectables } from "../../../configuration/injectables";
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";
    
export class SidebarController {

    static $inject = [
        Injectables.CurrentUserResolver,
        Injectables.SystemAccountService
    ];

    constructor(
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly systemAccountService: SystemAccountService
    ) {}

    public showSubMenu: boolean;
    public subMenu: string;
    public isEpicIntegrationEnabled: boolean;

    public setSubMenu = (menu: string) => {
        if (this.currentUserResolver.getCurrentUser().user.sideMenuIsCollapsed)
            this.showSubMenu = true;

        this.subMenu = menu;
    }

    public checkEpicIntegration = () => {
        this.systemAccountService.isEpicIntegrationEnabled()
            .then((isEpicIntegrationEnabled) => {
                this.isEpicIntegrationEnabled = isEpicIntegrationEnabled;
            });
    }

    public $onInit = () => {
        this.checkEpicIntegration();
    }
}