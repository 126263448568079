import { IFilterService } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { ICurrencyFormatter } from "./iCurrencyFormatter";

export class CurrencyFormatter implements ICurrencyFormatter {
    public static $inject = [
        Injectables.$filter
    ];

    constructor(
        private readonly $filter: IFilterService) {
    }

    public format(amount: number): string {
        return this.$filter('currency')(amount, '$');
    }
}

app.service(Injectables.CurrencyFormatter, CurrencyFormatter);