import * as angular from "angular";
import { IScope, IAttributes, IController } from "angular";
import app from "../../main";

const emptyState = () => {
    
    const link = (
        scope: IScope, 
        element: JQuery, 
        attrs: IAttributes, 
        ctrl:IController) => { 
        
        if (attrs.message) {
            angular
                .element(element[0].querySelector('.state-message'))
                .text(attrs.message);

            const icon = attrs.icon || 'fa-inbox';

            angular
                .element(element[0].querySelector('.fa'))
                .addClass(icon);
        }

        if (attrs.noTable == '') {
            angular
                .element(element[0].querySelector('.empty-state-table'))
                .hide();

            angular
                .element(element[0].querySelector('.initial-state'))
                .addClass('borderless');
        }            
    }

    return {
        templateUrl: "app/components/emptyState/emptyState.html",
        link: link
    };

}

app.directive("emptyState", emptyState);