import { PermissionsLocalStorage } from "../permissionsLocalStorage";
import { CurrentUserSystemAccount } from "./currentUserSystemAccount";
import { CurrentUserUser } from "./currentUserUser";

export interface CurrentUser {
    isLoggedIn(): boolean;
    systemAccount: CurrentUserSystemAccount;
    user: CurrentUserUser;
    permissions: PermissionsLocalStorage;
    hasMultipleUsers: boolean;
    isSingleSignOn?: boolean;
}