import { RenewalStatus } from "../../api/types/model/bond";
import { CancellationStatus } from "../../api/types/model/cancellationRequest";
import { ReinstatementStatus } from "../../api/types/model/reinstatementRequest";
import { RiderStatus } from "../../api/types/model/riderRequest";

export type BondStatusFilterable = {
    status?: string;
    cancellationDate?: string;
    reinstatementDate?: string;
    cancellationStatus?: CancellationStatus;
    riderStatus?: RiderStatus;
    reinstatementStatus?: ReinstatementStatus;
    renewalStatus?: RenewalStatus;
    allowPrincipalAssignment?: boolean;
}