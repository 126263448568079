import { ApplicationService } from "../../api/applicationService";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { CloseApplicationOptions } from "./closeApplicationModalOptions";
import { CloseApplicationResult } from "./CloseApplicationResult";
import app from "../../main";

export class CloseApplicationModalController {
    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.ApplicationService
    ];

    constructor(
        private readonly $uibModalInstance: Modal<CloseApplicationResult>,
        private readonly options: CloseApplicationOptions,
        private readonly applicationService: ApplicationService
    ) { }

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }

    public applicationId: number;

    public closeApplication(): void {
        this.$uibModalInstance.close(this.applicationService.closeApplication(this.applicationId));
    }

    public $onInit(): void {
        if (!this.options.applicationId) {
            throw new Error('options.applicationId is not valid');
        }

        this.applicationId = this.options.applicationId;
    }
}

app.controller('CloseApplicationModalController', CloseApplicationModalController);
