import { Application } from "./application";
import { BondNumberGroup } from "./bondNumberGroup";
import { CarrierAppointment } from "./carrierAppointment";
import { Fee } from "./fee";
import { IBaseEntity } from "./iBaseEntity";
import { IndemnitySignature } from "./indemnitySignature";
import { Invoice } from "./invoice";
import { QuoteBondFormAttachment } from "./quoteBondFormAttachment";
import { QuoteRateTier } from "./quoteRateTier";
import { RateType } from "./rate";
import { SystemAccount } from "./systemAccount";
import { WritingCompany } from "./writingCompany";
import { CommissionType } from "./commissionType";

export interface Quote extends IBaseEntity {
    carrierSystemAccountId: number;
    fixedRate: number;
    ratePerThousand: number;
    rateType: RateType;
    aggregateBondLimit: number;
    application: Application;
    applicationId: number;
    appointment: CarrierAppointment;
    bondFormAttachments: QuoteBondFormAttachment[];
    bondNumberGroup: BondNumberGroup;
    bondNumberGroupId: number;
    carrierAppointmentId: number;
    carrierSystemAccount: SystemAccount;
    comments: string;
    commission: number;
    fees: Fee[];
    highlightComments: string;
    indemnityRequirements: IndemnityRequirements;
    indemnitySignatures: IndemnitySignature[];
    invoice: Invoice;
    invoiceId: number;
    isCreatedAutomatically: boolean;
    isManualReview: boolean;
    minimumPremium: number;
    principalComments: string;
    pulledCredit: boolean;
    quoteType: QuoteType;
    rateTiers: QuoteRateTier[];
    singleBondLimit: number;
    status: QuoteStatus;
    term: number;
    totalAmountDue: number;
    totalFees: number;
    totalPremium: number;
    writingCompany: WritingCompany;
    writingCompanyId: number;
    id: number;
    createdDateTime: Date;
    commissionType?: CommissionType;
    commissionPercent: number;
}

export enum IndemnityRequirements {
    None = "None",
    ApplicantOnly = "ApplicantOnly",
    ApplicantSpouse = "ApplicantSpouse",
    ApplicantSpouseCorporate = "ApplicantSpouseCorporate",
    ApplicantCorporate = "ApplicantCorporate"
}
export enum QuoteStatus {
    Pending = "Pending",
    Declined = "Declined",
    Approved = "Approved"
}
export enum QuoteType {
    NewBusiness = "NewBusiness",
    Renewal = "Renewal"
}