import { ODataFactory, ODataService } from "../../api/odata";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import app from "../../main";
import { IPromise } from "angular";
import { DeleteConfirmationModalOptions } from "./DeleteConfirmationModalOptions";

export class DeleteConfirmationModalController {

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.ODataFactory,
        Injectables.ToastMessageCreator
    ];

    constructor (
        private readonly $uibModalInstance: Modal<any>,
        private readonly options: DeleteConfirmationModalOptions,
        private readonly odata: ODataFactory,
        private readonly toastMessageCreator: ToastMessageCreator
        ) {}  

    public errorMessage: string;
    public successMessage: string;
    public header: string;
    public message: string;
    public key: any;
    public deleteFunction?: (key: any) =>IPromise<any>;
    public odataService?: ODataService<any>;
    public busyIndicator: BusyIndicator;

    public cancel = () => {
        this.$uibModalInstance.dismiss('cancel');
    }
    
    public deleteClicked = () => {
        
        if (this.deleteFunction) {
            this.busyIndicator.promise = this.deleteFunction(this.key);
        } else {
            this.busyIndicator.promise = this.odataService.delete(this.key);
        }

        this.busyIndicator.promise
            .then((response) => {
                this.toastMessageCreator.createSuccessMessage(this.successMessage);
                this.$uibModalInstance.close(response);
            }).catch( () => {
                this.toastMessageCreator.createErrorMessage(this.errorMessage);
            });
    }

    public $onInit = () => {
        this.busyIndicator = {
            message: 'Deleting...'
        };

        this.header = this.options.header;
        this.message = this.options.message;
        this.key = this.options.key;

        if (this.options.deleteFunction) {
            this.deleteFunction = this.options.deleteFunction;
        }

        if (this.options.endpoint) {
            this.odataService = this.odata.getService(this.options.endpoint);
        }

        this.errorMessage = this.options.errorMessage || 'An error occurred tryting to delete the record';
        this.successMessage = this.options.successMessage || 'Record deleted successfully';
    };
}

app.controller('DeleteConfirmationModalController', DeleteConfirmationModalController);