import { ITimeoutService } from "angular";
import { BondTypeService } from "../../api/bondTypeService";
import { SfaaCode } from "../../api/types/model/sfaaCode";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { ModalOpener } from "../../modals/modalOpener";
import { Table } from "../../utilities/tables/table";
import { BondTypeSelectionItem } from "./bondTypeSelectionItem";
import BondTypesTableQueryOptions from "./bondTypeQueryOptions";
import { IDebounceDelayer } from "../../utilities/debounceDelayer/iDebouceDelayer";
import { BusyIndicator } from "../busyIndicator/busyIndicator";

class BondTypeSelectionController {

    public static $inject = [
        Injectables.BondTypeService,
        Injectables.$timeout, 
        Injectables.ModalOpener,
        Injectables.IDebouceDelayer
    ];
    
    constructor(
        private readonly bondTypeService: BondTypeService,
        private readonly $timeout: ITimeoutService,
        private readonly modalOpener: ModalOpener,
        debounceDelayer: IDebounceDelayer
    ) {
        this.table = new Table(debounceDelayer);
        this.table.onChange = this.loadBondTypeMatches;
    }

    public busyIndicator: BusyIndicator;

    // bindings
    public onSelect: ({ bondTypeId }) => void;
    public preselectedSfaaCode: SfaaCode;
    public hideEmailButton: boolean;

    public readonly table: Table<BondTypeSelectionItem, BondTypesTableQueryOptions>;

    public emailApplicationClick(bondType: any): void {
        this.modalOpener.emailApplicationModal(null, bondType.id, bondType.bondAmount)
            .result
            .then(function(){})
            .catch(function(){});
    }

    public showOnlyFavoritesClicked(): void {
        this.loadBondTypeMatches();
    }

    public requestNewBondType(): void {
        this.modalOpener.showBondTypeRequestModal()
            .result
            .then(function(){})
            .catch(function(){});
    }

    public toggleFavorite(bondType: BondTypeSelectionItem): void {
        if (bondType.favoriteId) {
            this.bondTypeService.unmarkFavorite(bondType.id);
            bondType.favoriteId = null;
        } else {
            this.bondTypeService.markFavorite(bondType.id);
            bondType.favoriteId = bondType.id;
        }
    }

    public selectBondType(bondType: BondTypeSelectionItem): void {
        this.onSelect({bondTypeId: bondType.id});
    }

    public loadBondTypeMatches = (): void => {
        this.busyIndicator = {
            message: "Retrieving Bond Types...",
            promise: this.bondTypeService
                .getNewBondTypeMatchesQuery(this.table.queryOptions)
                .then((response) =>{
                    this.table.setData(response.items, response.totalRecordCount)
                })
        };
    }

    public $onInit(): void {
        this.table.queryOptions.favorites = false;
        this.table.queryOptions.isActiveOnly = true;

        this.$timeout(() => {
                if (this.preselectedSfaaCode) {
                    this.table.queryOptions.sfaaCodeId = this.preselectedSfaaCode.id;
                }
            },
            1000
        );

        this.loadBondTypeMatches();
    }
}

const bondTypeSelectionComponent = {
    bindings: {
        hideEmailButton: '=',
        onSelect: '&',
        preselectedSfaaCode: '=',
    }, 
    templateUrl: 'app/components/bondTypeSelectionForm/bondTypeSelection.html',
    controller: BondTypeSelectionController,
    controllerAs: 'vm'
}

app.component('bondTypeSelection', bondTypeSelectionComponent);