import { PaymentStatus } from "../../api/types/payments/paymentStatus";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { ModalOpener } from "../../modals/modalOpener";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";
import { PaymentListItem } from "./paymentListItem";
import { Table } from "../../utilities/tables/table";
import PaymentTableQueryOptions from "../../api/types/payments/paymentTableQueryOptions";

class PaymentListController {
    
    public static $inject = [
        Injectables.CurrentUserResolver,
        Injectables.ModalOpener
    ];
    
    public get isCarrier(): boolean {
        return this.currentUserResolver.getCurrentUser().systemAccount.isCarrier;
    }

    public onPaymentReassigned: () => void;
    public table: Table<PaymentListItem, PaymentTableQueryOptions>;
    
    constructor (
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly modalOpener: ModalOpener
    ) { }

    public showPaymentAssignmentModal(payment: PaymentListItem) {
        this.modalOpener.showPaymentAssignmentModal(payment.id)
            .result
            .then(() => {
                if (typeof this.onPaymentReassigned === 'function') {
                    this.onPaymentReassigned();
                }
            })
            .catch(() => {});
    }

    public getIsAssignButtonDisabled(payment: PaymentListItem) {
        return payment.paymentStatus !== PaymentStatus.Approved;
    }
    
    $onInit(): void {}
}

const paymentListComponent = {
    bindings: { 
        table: '<',
        onPaymentReassigned: '&?'
    },
    controller: PaymentListController,
    controllerAs: 'vm',
    templateUrl: 'app/components/paymentList/paymentList.html'
};

app.component('paymentList', paymentListComponent);
