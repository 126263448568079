import { Company } from "./company";
import { IBaseEntity } from "./iBaseEntity";

export interface CompanyContact extends IBaseEntity { 
    company: Company;
    companyId: number;
    contactName: string;
    email: string;
    fax: string;
    phone: string;
    phoneExt: string;
    id: number;
    createdDateTime: Date;
}