import { Application } from "./application";
import { DocumentVersion } from "./documentVersion";
import { IBaseEntity } from "./iBaseEntity";

export interface ApplicationDocumentVersion extends IBaseEntity { 
    applicationId: number;
    documentVersionId: number;
    application: Application;
    documentVersion: DocumentVersion;
    id: number;
    createdDateTime: Date;
}