import { A3RootScope } from "../../api/types/a3RootScope";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";

        
export class CurrentUserResolver {

    public static $inject = [
        Injectables.$rootScope
    ];
    
    constructor(
        private readonly $rootScope: A3RootScope
    ){}
    
    public getCurrentUser = () => {
            return this.$rootScope.currentUser;
    }
}

app.service(Injectables.CurrentUserResolver, CurrentUserResolver);