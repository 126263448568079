import { Quote } from "../quote";
import { BondTransaction } from "./bondTransaction";
import { Company } from "./company";
import { IBaseEntity } from "./iBaseEntity";
import { Person } from "./person";

export interface IndemnitySignature extends IBaseEntity { 
    bondTransaction: BondTransaction;
    bondTransactionId: number;
    clientIp: string;
    company: Company;
    companyId: number;
    emailedToAddress: string;
    emailSentDateTime: Date;
    indemnityLanguage: string;
    indemnityType: IndemnityType;
    person: Person;
    personId: number;
    printedName: string;
    quote: Quote;
    quoteId: number;
    signature: string;
    signatureDateTime: Date;
    status: IndemnityStatus;
    uniqueId: string;
    id: number;
    createdDateTime: Date;
}

export enum IndemnityStatus {	
    NotSent = "NotSent",
    Sent = "Sent",
    Signed = "Signed"
}
export enum IndemnityType {	
    Principal = "Principal",
    Spouse = "Spouse",
    Corporate = "Corporate"
}