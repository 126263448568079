import { TransactionType } from "../model/bondTransaction";
import { UnbilledBondTransactionBillingEntry } from "./unbilledBondTransactionBillingEntry";

export interface UnbilledBondTransaction {
    id: number;
    bondNumber: string;
    effectiveDate: Date;
    expirationDate: Date;
    transactionType: TransactionType;
    customerId: number;
    billingEntries: UnbilledBondTransactionBillingEntry[];
    isSelected: boolean;
}