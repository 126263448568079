import { BondService } from "../../api/bondService";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { MarkBondForReviewModalOptions } from "./markBondForReviewModalOptions";
import { MarkBondForReviewModalResult } from "./MarkBondForReviewModalResult";
import app from "../../main";
import { IPromise } from "angular";

export class MarkBondForReviewModalController {
    
    public static $inject = [
        Injectables.Options,
        Injectables.$uibModalInstance,
        Injectables.BondService,
        Injectables.ToastMessageCreator
    ];

    constructor(

        private readonly options: MarkBondForReviewModalOptions,
        private readonly $uibModalInstance: Modal<MarkBondForReviewModalResult>,
        private readonly bondService: BondService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {}

    public loadingPromise: IPromise<void>;
    public reviewComments: string;

    public submit(): void {
        this.loadingPromise = this.bondService.markForReview(this.options.bondId, this.reviewComments)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Bond has been marked for review');
                this.$uibModalInstance.close(new MarkBondForReviewModalResult(this.reviewComments));
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred when trying to mark this bond for review');
            });
    }

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }

    public $onInit() { 
    }
}

app.controller('MarkBondForReviewModalController', MarkBondForReviewModalController);