import { A3RootScope } from "../../../api/types/a3RootScope";
import { CurrentUser } from "../../../api/types/authentication/currentUser";
import { UserService } from "../../../api/userService";
import { Injectables } from "../../../configuration/injectables";
import { SystemSettings } from "../../../configuration/settings/systemSettings";
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";

export class HeaderController {
    static $inject = [
        Injectables.UserService,
        Injectables.$rootScope,
        Injectables.CurrentUserResolver,
        Injectables.SystemSettings

    ];

    constructor(
        private readonly userService: UserService,
        private readonly $rootScope: A3RootScope,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly systemSettings: SystemSettings){  
    }

    public currentUser: CurrentUser;
    public environment: string;
    public currentTheme: string;

    public toggleNav() {
        this.$rootScope.$broadcast('toggleNav');
    };

    public toggleSidebar() {
        var isCollapsed = !this.currentUser.user.sideMenuIsCollapsed;
        this.userService.setSideMenuIsCollapsed(this.currentUser.user.userId, isCollapsed);
    }

    public $onInit = () => {
    
        this.environment = this.systemSettings.environment;
        this.currentUser = this.currentUserResolver.getCurrentUser();

        if (this.currentUser &&
            this.currentUser.user &&
            this.currentUser.user.theme) {
            this.currentTheme = this.currentUser.user.theme;
        } else {
            this.currentTheme = 'a3';
        }
    }
}