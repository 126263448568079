import { SystemAccountService } from "../../api/systemAccountService";
import { SelectOption } from "../../api/types/selectOption";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";
import { Table } from "../../utilities/tables/table";
import BillingEntriesListItem from "./billingEntriesListItem";
import { BillingReportDropdownFilterOptions }  from   "../../states/agentSpecific/billingEntry/billingEntryReportFilterDropdown/billingReportDropdownFilterOptions";

class BillingEntriesListController {
    
    $inject = [
        Injectables.SystemAccountService,
        Injectables.CurrentUserResolver
    ];

    constructor(
        private readonly systemAccountService: SystemAccountService,
        private readonly currentUserResolver: CurrentUserResolver
    ) {}

    public table: Table<BillingEntriesListItem, BillingReportDropdownFilterOptions>;
    
    public producerUserOptions: SelectOption<number>[];
    
    $onInit = () => {
        if (!this.currentUserResolver.getCurrentUser().systemAccount.isCarrier) {
            this.systemAccountService.getProducerUserOptions()
                .then((producerUserOptions) => {
                    this.producerUserOptions = producerUserOptions;
                });
        }}
}

const billingEntriesListComponent = {
    templateUrl: 'app/components/billingEntriesList/billingEntriesList.html',
    bindings: {
        table: '<'
    },
    controllerAs: 'vm',
    controller: BillingEntriesListController
};

app.component('billingEntriesList', billingEntriesListComponent);