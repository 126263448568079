import { FICOIndicator } from "./fICOIndicator";
import { RejectCode } from "./rejectCode";
import { ScoreReasons } from "./scoreReasons";

export interface USFICO { 
    ficoScore: string;
    scoreReasons: ScoreReasons;
    rejectCode: RejectCode;
    riskBasedPricing: string;
    doddFrank: string;
    ficoIndicator: FICOIndicator;
    factActInquiriesAreKeyFactor: string;
}