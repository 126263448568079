import { BondProductConfiguration } from "./bondProductConfiguration";
import { CarrierAppointment } from "./carrierAppointment";
import { IBaseEntity } from "./iBaseEntity";

    export interface AppointmentBondProductConfiguration extends IBaseEntity { 
    carrierAppointmentId: number;
    bondProductConfigurationId: number;
    irpm: number;
    carrierAppointment: CarrierAppointment;
    bondProductConfiguration: BondProductConfiguration;
    id: number;
    createdDateTime: Date;
}