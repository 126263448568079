import { ITimeoutService } from "angular";
import moment from "moment";
import { AnalyticsService } from "../../../../../api/analyticsService";
import { SfaaCodeService } from "../../../../../api/sfaaCodeService";
import DropdownOption from "../../../../../api/types/dropdownOption";
import { BusyIndicator } from "../../../../../components/busyIndicator/busyIndicator";
import { constants } from "../../../../../configuration/constants";
import { Injectables } from "../../../../../configuration/injectables";
import app from "../../../../../main";
import { AmChartService } from "../../../../../utilities/amCharts/amChartService";
import { DashboardService } from "../../dashboardService";
import { DashboardWidget } from "../../types/dashboardWidget";

class DashboardWidgetStateActivityController {

    public static $inject = [
        Injectables.DashboardService,
        Injectables.AnalyticsService,
        Injectables.SfaaCodeService,
        Injectables.AmChartService,
        Injectables.$timeout
    ];

    constructor(
        private readonly dashboardService: DashboardService,
        private readonly analyticsService: AnalyticsService,
        private readonly sfaaCodeService: SfaaCodeService,
        private readonly amChartService: AmChartService,
        private readonly $timeout: ITimeoutService
    ) {}

    public widget: DashboardWidget;
    public busyIndicator: BusyIndicator;
    public widgetIndex: number;
    public sfaaCodeOptions: DropdownOption<string>[];
    public transactionTypeOptions: DropdownOption<string>[];
    public dateFilter: { startDate: Date, endDate: Date };

    public loadChart = () => {
        this.busyIndicator.promise = this.analyticsService.getStateActivity(
                this.widget.options.filters.transactionType.value, 
                this.dateFilter.startDate,
                this.dateFilter.endDate,
                this.widget.options.filters.selectedSfaaCode.value)
            .then((data) => {
                this.amChartService.makeUsaMapChart("mapDiv" + this.widgetIndex, data);
            });
    };

    public transactionTypeSelected = (selectedOption: DropdownOption<string>) => {
        this.widget.options.filters.transactionType = selectedOption;
        this.loadChart();
        this.dashboardService.save();
    }

    public sfaaCodeSelected = (selectedOption: DropdownOption<string>) => {
        this.widget.options.filters.selectedSfaaCode = selectedOption;
        this.loadChart();
        this.dashboardService.save();
    }

    public loadTransactionTypes = () => {
        this.transactionTypeOptions = constants.transactionTypeDropdownOptions;
        this.transactionTypeOptions.unshift(
            { label: 'All Transaction Types', value: null }, 
            { isDivider: true }
        );
    }

    public loadSfaaCodes = () => {
        this.sfaaCodeOptions = [];

        this.sfaaCodeService.getAllSfaaCodeOptions()
            .then((options) => {
                this.sfaaCodeOptions = options;
                this.sfaaCodeOptions.unshift(
                    { label: 'All SFAA Codes', value: null }, 
                    { isDivider: true }
                );
            });
    }

    public $onInit = () => {
        this.busyIndicator = {
            message: 'Loading...'
        };
        
        this.dateFilter = {
            startDate: moment().subtract(1, 'years').toDate(),
            endDate: moment().subtract(1, 'days').toDate()
        }
        
        this.loadSfaaCodes();
        this.loadTransactionTypes();
        
        this.$timeout(this.loadChart);

        this.dashboardService.refreshFunctions.push(this.loadChart);
    }
}

const dashboardWidgetStateActivityComponent = {
    bindings: {
        widget: '=',
        widgetIndex: '<'
    },
    templateUrl: 'app/states/common/dashboard/widgets/stateActivity/dashboardWidgetStateActivity.html',
    controller: DashboardWidgetStateActivityController,
    controllerAs: 'vm'
};

app.component('dashboardWidgetStateActivity', dashboardWidgetStateActivityComponent);