import app from "../../main";
import { PhoneFilterable } from "./phoneFilterable";

export const phoneFilter = () => {

    return (obj: PhoneFilterable): string => {

        if (!obj) {
            return 'Phone Unknown';
        }

        if (obj.cellPhone) {
            return obj.cellPhone;
        }

        if (obj.homePhone) {
            return obj.homePhone;
        }

        if (obj.phone) {
            return obj.phone;
        }
        
        return 'Phone Unknown';
    }
}

app.filter('phone', phoneFilter);