import { IBaseEntity } from "./iBaseEntity";
import { Lead } from "./lead";
import { MasterApplicationQuestion } from "./masterApplicationQuestion";
import { AdditionalQuestionResponseType } from "./questionResponse";

export interface LeadQuestionResponse extends IBaseEntity { 
    lead: Lead;
    leadId: number;
    masterApplicationQuestion: MasterApplicationQuestion;
    masterApplicationQuestionId: number;
    originType: AdditionalQuestionResponseType;
    questionText: string;
    responseText: string;
    id: number;
    createdDateTime: Date;
}