import { DownloadBatchType } from "../../api/types/downloadBatchType";

export type ModalExportFormDownloadOptions = {
    includeManifest: boolean,
    includeBondForms: boolean,
    type: DownloadBatchType,
    startDate: string;
    endDate: string;
    includeCoverLetters: boolean;
    includeApplications: boolean;
    includeOther: boolean; 
}