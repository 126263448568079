import app from "../../main";

export const filesizeFilter = () => {

    const units = [
        'bytes',
        'KB',
        'MB',
        'GB',
        'TB',
        'PB'
    ];

    return (bytes: number, precision: number) => {
        
        if (!bytes) {
            return '';
        }

        if (isNaN(parseFloat(bytes.toString())) || !isFinite(bytes)) {
            return '?';
        }

        var unit = 0;

        while (bytes >= 1024) {
            bytes /= 1024;
            unit++;
        }

        return bytes.toFixed(+precision) + ' ' + units[unit];
    }
}

app.filter('filesize', filesizeFilter);   