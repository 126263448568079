import { IJQueryService } from "../../../utilities/jquery/iJQueryService";
import defaultWrapper from "../defaultWrapper";
import fieldGroupWrapper from "../fieldGroupWrapper";

export default (element: JQuery, jQueryProvider: IJQueryService) => {
    element.attr('type', 'text');

    var labelStrategy = element.attr('aa-label-strategy') || 'placeholderOnly';
    element.attr('aa-label-strategy', labelStrategy);

    var aaFgClass = element.attr('aa-fg-class') ? element.attr('aa-fg-class') + ' search' : 'search';
    element.attr('aa-fg-class', aaFgClass);

    element.attr('ng-model-options', '{debounce:400}');

    fieldGroupWrapper(element, defaultWrapper(element), jQueryProvider);

    element
        .parent()
        .append(element, '<i class="fa fa-search fa-lg orange"></i>');
    
    element
        .parent()
        .append(element, '' +
        '<div class="dropdown text-left" uib-dropdown>' +
            '<button class="small" uib-dropdown-toggle>' +
                '<span ng-show="searchType == \'contains\' || !searchType">Contains</span>' +
                '<span ng-show="searchType == \'startswith\'">Starts With</span>' +
                '<span ng-show="searchType == \'endswith\'">Ends With</span>' +
                '<i class="fa fa-caret-down"></i>' +
            '</button>' +
            '<ul class="dropdown-menu dropdown-menu-right">' +
                '<li><a ng-click="searchType = \'contains\'">Contains</a></li>' +
                '<li><a ng-click="searchType = \'startswith\'">Starts With</a></li>' +
                '<li><a ng-click="searchType = \'endswith\'">Ends With</a></li>' +
            '</ul>' +
        '</div>');
};