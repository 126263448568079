import { AccountsByAccountType } from "../accountsByAccountType";
import { BankruptcyDisplay } from "../bankruptcyDisplay";
import { CollectionsDisplay } from "../collectionsDisplay";
import { Bureau } from "../model/creditReport";
import { TaxLienDisplay } from "../taxLienDisplay";
import { USAddresses } from "./uSAddresses";
import { USAlertContacts } from "./uSAlertContacts";
import { USBankruptcies } from "./uSBankruptcies";
import { USBeacon } from "./uSBeacon";
import { USCollections } from "./uSCollections";
import { USConsumerReferral } from "./uSConsumerReferral";
import { USConsumerStatements } from "./uSConsumerStatements";
import { USDataAttributes } from "./uSDataAttributes";
import { USDecisionPowerSegments } from "./uSDecisionPowerSegments";
import { USEDAS } from "./uSEDAS";
import { USEMailAddresses } from "./uSEMailAddresses";
import { USEmployments } from "./uSEmployments";
import { USErrorMessages } from "./uSErrorMessages";
import { USFICO } from "./uSFICO";
import { USFileId } from "./uSFileId";
import { USFraudAdvisor } from "./uSFraudAdvisor";
import { USGeoCode } from "./uSGeoCode";
import { USHeader } from "./uSHeader";
import { USIdentificationSSN } from "./uSIdentificationSSN";
import { USInquiries } from "./uSInquiries";
import { USLegalItems } from "./uSLegalItems";
import { USModelScores } from "./uSModelScores";
import { USOFACAlerts } from "./uSOFACAlerts";
import { USOnlineDirectories } from "./uSOnlineDirectories";
import { USOtherIdentifications } from "./uSOtherIdentifications";
import { USOtherNames } from "./uSOtherNames";
import { USOtherTelephoneNumbers } from "./uSOtherTelephoneNumbers";
import { USSubjectDeath } from "./uSSubjectDeath";
import { USTaxLiens } from "./uSTaxLiens";
import { USTrades } from "./uSTrades";

export interface USConsumerCreditReport { 
    usHeader: USHeader;
    usAddresses: USAddresses;
    usOtherNames: USOtherNames;
    usSubjectDeath: USSubjectDeath;
    usEmployments: USEmployments;
    usOtherIdentifications: USOtherIdentifications;
    useMailAddresses: USEMailAddresses;
    usOtherTelephoneNumbers: USOtherTelephoneNumbers;
    usBankruptcies: USBankruptcies;
    usCollections: USCollections;
    usLegalItems: USLegalItems;
    usFileId: USFileId;
    usAlertContacts: USAlertContacts;
    usTaxLiens: USTaxLiens;
    usTrades: USTrades;
    usInquiries: USInquiries;
    usConsumerStatements: USConsumerStatements;
    usedas: USEDAS;
    usBeacon: USBeacon;
    usfico: USFICO;
    usOnlineDirectories: USOnlineDirectories;
    usModelScores: USModelScores;
    usDataAttributes: USDataAttributes;
    usIdentificationSSN: USIdentificationSSN;
    usGeoCode: USGeoCode;
    usDecisionPowerSegments: USDecisionPowerSegments;
    usFraudAdvisor: USFraudAdvisor;
    usofacAlerts: USOFACAlerts;
    usConsumerReferral: USConsumerReferral;
    usErrorMessages: USErrorMessages;
    relationshipIdentifier: string;
    multipleNumber: string;
    $bankruptcies: BankruptcyDisplay[];
    $taxLiens: TaxLienDisplay[];
    $collections: CollectionsDisplay[];
    $accts: AccountsByAccountType;
    bureau: Bureau;
    $scoreResult: number;
}