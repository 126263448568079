import { Injectables } from "../../configuration/injectables";
import { ModalOpener } from "../../modals/modalOpener";
import { BondListItem } from "../bondListFilterDropdown/bondListItem";
import app from "../../main";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";

class BondListController {

    public static $inject = [
        Injectables.ModalOpener,
        Injectables.CurrentUserResolver
    ];

    constructor(
        private readonly modalOpener: ModalOpener,
        private readonly currentUserResolver: CurrentUserResolver
    ) {}

    public isCarrier: boolean;
    private readonly onBondAccountUpdated: () => void;

    public $onInit(): void {
        this.isCarrier = this.currentUserResolver.getCurrentUser().systemAccount.isCarrier;
    }

    public showBondAccountModal(bond: BondListItem) {
        this.modalOpener.showBondAccountModal(bond.customerId, bond.bondAccountId)
            .result
            .then(() => {
                if (typeof this.onBondAccountUpdated === 'function') {
                    this.onBondAccountUpdated();
                }
            })
            .catch(() => {});
    }

    public showShippingModal(bondTransaction) {
        this.modalOpener.showShippingModal(bondTransaction)
            .result
            .then(() => {})
            .catch(() => {});
    }
    
    public toggleTransactionVisibility(bond) {
        bond.transactionsAreVisible = !bond.transactionsAreVisible;
    }
}

const bondListComponent = {
    bindings: {
        table: '<',
        showCustomerNameColumn: '<?',
        showNameOnBondColumn: '<?',
        onBondAccountUpdated: '&?'
    },
    controller: BondListController,
    controllerAs: 'vm',
    templateUrl: 'app/components/bondList/bondList.html',
};

app.component('bondList', bondListComponent);
