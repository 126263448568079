import { ITimeoutService, ISCEService, IPromise } from "angular";
import { CustomersService } from "../../api/customerService";
import { SystemAccountService } from "../../api/systemAccountService";
import { CustomerSources } from "../../api/types/customerSources";
import { Customer } from "../../api/types/model/customer";
import { Person } from "../../api/types/model/person";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";

class CustomerLookupController {

    public static $inject = [
        Injectables.CurrentUserResolver,
        Injectables.$timeout,
        Injectables.CustomersService,
        Injectables.SystemAccountService,
        Injectables.$sce
    ];

    constructor(
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly $timeout: ITimeoutService,
        private readonly customersService: CustomersService,
        private readonly systemAccountService: SystemAccountService,
        private readonly $sce: ISCEService
    ) { }

    public customer: Customer;
    public matchingCustomers: Customer[];
    public matchingPeople: Person[];
    public source: CustomerSources;
    public showAms360ContactSelection: boolean;
    public showLookupForm: boolean;
    public processingPromise: IPromise<any>;
    public searchTimeout: IPromise<any>;
    public onSelect: ({ }) => {};
    public nameSearch: string;
    public emailSearch: string;
    public phoneSearch: string;

    public highlight(text: string, search: string): string {
        if (!search) {
            return this.$sce.trustAsHtml(text);
        }
        return this.$sce.trustAsHtml(text.replace(new RegExp(search, 'gi'), '<span class="highlightedText">$&</span>'));
    }

    public setSelectedCustomerContacts(): void {
        this.customer.people = [];
        for (const person of this.matchingPeople) {
            if (person.$include) {
                this.customer.people.push(person);
            }
        }
    }

    public selectCustomer(customer: Customer): void {
        this.customer = customer;
        this.$timeout(() => {
            this.source = CustomerSources.A3;
            this.onSelect({ customer: this.customer, source: this.source });
        });
    }

    public loadCustomerMatches(): void {
        // if already set then cancel the timout
        if (this.searchTimeout) {
            this.$timeout.cancel(this.searchTimeout);
        }

        // set timeout and delay searching
        this.searchTimeout = this.$timeout(() => {
            this.processingPromise =
                this.systemAccountService.getSystemAccount(this.currentUserResolver.getCurrentUser().systemAccount.id)
                    .then((systemAccount) => {
                        return this.customersService
                            .searchAllCustomers(this.nameSearch, this.phoneSearch, this.emailSearch, 5, systemAccount);
                    })
                    .then((matchingCustomers) => {
                        this.matchingCustomers = matchingCustomers;
                        for (const customer of matchingCustomers) {
                            customer.$highlighted = this.highlight(customer.name, this.nameSearch);
                        }
                    });
        }, 750);
    }

    public $onInit(): void {
        this.showLookupForm = true;
        this.showAms360ContactSelection = false;
    }
}

const customerLookupFormComponent = {
    templateUrl: 'app/components/customerLookupForm/customerLookup.html',
    bindings: {
        customer: '=',
        onSelect: '&',
        showLookupForm: '=',
        showAms360ContactSelection: '=',
        source: '=' // corrisponds to CustomerSource enum in typescript
    },
    controllerAs: 'vm',
    controller: CustomerLookupController
};

app.component('customerLookup', customerLookupFormComponent);
