import { SfaaCodeService } from "../../api/sfaaCodeService";
import { SfaaCode } from "../../api/types/model/sfaaCode";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { SearchControl } from "../../utilities/searchControl";
import { ITextHighlighter } from "../../utilities/textHighlighter/iTextHighlighter";
import { BusyIndicator } from "../busyIndicator/busyIndicator";


class SfaaCodeListController {

    static $inject = [
        Injectables.SfaaCodeService,
        Injectables.ITextHighlighter
    ];

    constructor(
        private readonly sfaaCodeService: SfaaCodeService,
        public readonly textHighlighter: ITextHighlighter
    ) {
        this.sfaaSearchField = new SearchControl();
    }

    public showLabel: boolean;
    public noEntriesText: string;
    public add: (newSfaaCode: SfaaCode) => void;
    public remove: (sfaaCode: SfaaCode) => void;
    
    public sfaaCodes: SfaaCode[];
    
    public busyIndicator: BusyIndicator;

    public sfaaSearchField: SearchControl<SfaaCode>;

    public controlInit($select) {
        if (this.showLabel) {
            $select.label = 'Search SFAA Codes';
        }
    }

    public searchSfaaCodes = (search?: string) => {
        return this.sfaaCodeService.searchSfaaCodes(search)
            .then((codes) => {
                this.sfaaSearchField.matches = codes;
            });
    }

    public addSfaaCode() {
        if (!this.sfaaCodes) this.sfaaCodes = [];
        
        if (!this.sfaaSearchField.selected)
            return;

        for (var i = 0; i < this.sfaaCodes.length; i++) {
            if (this.sfaaCodes[i].id === this.sfaaSearchField.selected.id )
                return;
        }

        this.sfaaCodes.push(this.sfaaSearchField.selected);
        var newSfaaCode = {...this.sfaaSearchField.selected};

        this.sfaaSearchField.clear();

        if (this.add) {
            this.add(newSfaaCode);
        }                
    };

    public removeSfaaCode(index: number) {
        var removedSfaaCode = {...this.sfaaCodes[index]};

        this.sfaaCodes.splice(index, 1);
        
        if (this.remove) {
            this.remove(removedSfaaCode);
        }
    };

    public $onInit = () => {
        this.busyIndicator = {
            message: 'Loading...'
        };
    }
}

const sfaaCodeListComponent = {
    templateUrl: 'app/components/sfaaCodeList/sfaaCodeList.html',
    bindings: {
        sfaaCodes: '=?',
        showLabel: '=?',
        noEntriesText: '@?',
        add: '&?',
        remove: '&?'
    },
    controllerAs: 'vm',
    controller: SfaaCodeListController
};

app.component('sfaaCodeList', sfaaCodeListComponent);