import { RenewalStatus } from "./model/bond";
import { BondAdditionalQuestionResponse } from "./model/bondAdditionalQuestionResponse";
import { Company } from "./model/company";
import { Person } from "./model/person";

    export interface BondCorrectionBond {
        id: number;
        bondAmount: number;
        bondTypeId: number;
        bondTypeName: string;
        effectiveDate: Date;
        expirationDate: Date;
        nameOnBond: string;
        people: Person[];
        companies: Company[];
        bondAdditionalQuestionResponses: BondAdditionalQuestionResponse[];
        renewalStatus: RenewalStatus;
        carrierId: number;
        writingCompanyId: number;
        writingCompanyName: string;
    }