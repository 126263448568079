import * as angular from "angular";
import { IScope, IAttributes, ITemplateLinkingFunction, IDirectiveFactory } from "angular";
import app from "../../main";

type TemplateColumnScope = IScope & {
    ngShow?: boolean | string;
    enableSorting?: boolean | string;
    enableAggregateFilter?: boolean | string;
    sortOn?: string;
    filterExpression?: string;
    enableInlineEdit?: boolean;
    cssClass?: string;
    headerCssClass?: string;
    headerText?: string;
    dataitem?: any
}

const templateColumn = () => {

    function link (
        scope: TemplateColumnScope, 
        element: JQuery, 
        attributes: IAttributes, 
        parentController: any, 
        linker: ITemplateLinkingFunction) {
            
        linker(scope, function (clone) {

            scope.ngShow = scope.ngShow != undefined ? scope.ngShow : true;
            // clone is transluded content
            scope.enableSorting = scope.enableSorting === 'false' ? false : scope.enableSorting !== false;
            scope.enableInlineEdit = scope.enableInlineEdit ? scope.enableInlineEdit : false;

            var tempEl = angular.element('<div></div>');
            tempEl.append(clone);

            (scope.$parent.$parent as any).addColumn({
                columnType: 'templateColumn',
                sortOn: scope.sortOn,
                filterExpression: scope.filterExpression,
                enableInlineEdit: scope.enableInlineEdit,
                cssClass: scope.cssClass,
                headerCssClass: scope.headerCssClass,
                enableAggregateFilter: scope.enableAggregateFilter,
                headerText: scope.headerText,
                enableSorting: scope.enableSorting,
                templateContent: tempEl.html(),
                //templateContent: tempEl.html().replace(/{{/g, "{{item | navigateObject:\'").replace(/}}/g, "\'}}")
                show: scope.ngShow
            });
        });
    }

    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: {
            headerText: '@',
            enableSorting: '@',
            enableAggregateFilter: '@',
            sortOn: '@',
            filterExpression: '@',
            enableInlineEdit: '@',
            cssClass: '@',
            headerCssClass: '@',
            ngShow: '=?',
        },
        require: '^boundGrid',
        link: link,
        template: '<div></div>'
    };
}

app.directive('templateColumn', templateColumn as IDirectiveFactory);
