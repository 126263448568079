import { CacheStore } from "./cacheStore";
import { CacheStoreOptions } from "./cacheStoreOptions";

export interface CacheFactory<T = any> {
    (name: string, options: CacheStoreOptions): CacheStore<T>;

    get(name: string): CacheStore<T>;
    clearAll(): void;
    destroy(storeName: string): void;
}
