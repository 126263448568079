import { BondAccountService } from "../../api/bondAccountService";
import { BondForBondAccountAssignment } from "../../api/types/bondAccounts/bondForBondAccountAssignment";
import { SelectOption } from "../../api/types/selectOption";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { BondAccountAssignmentModalOptions } from "./bondAccountAssignmentModalOptions";
import { BondAccountAssignmentModalResult } from "./bondAccountAssignmentModalResult";
import app from "../../main";
import { IPromise } from "angular";

export class BondAccountAssignmentModalController {
    
    public static $inject = [
        Injectables.Options,
        Injectables.$uibModalInstance,
        Injectables.BondAccountService,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly options: BondAccountAssignmentModalOptions,
        private readonly $uibModalInstance: Modal<BondAccountAssignmentModalResult>,
        private readonly bondAccountService: BondAccountService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {}

    public processingPromise: IPromise<any>;
    public processingMessage: string;
    public bondAccountOptions: SelectOption[];
    private customerId: number;
    public bonds: BondForBondAccountAssignment[];
    public bondAccountId: number;
    public carrierSystemAccountId: number;
    public errorMessage: string;
    
    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }

    public submit(): void {
        this.processingMessage = "Processing...";

        this.processingPromise = this.bondAccountService.assignBondsToBondAccount(this.bondAccountId, this.options.bondIds)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('The selected bonds were successfully assigned to the bond line');
                this.$uibModalInstance.close({} as BondAccountAssignmentModalResult);
            });
    }

    private loadBonds() {
        return this.bondAccountService.getBondsForBondAccountAssignment(this.options.bondIds)
            .then((bonds) => {
                this.bonds = bonds;

                if (this.bonds.length) {
                    this.customerId = this.bonds[0].customerId;

                    if (this.bonds.every((bond) => bond.carrierSystemAccountId == this.bonds[0].carrierSystemAccountId)) {
                        this.carrierSystemAccountId = this.bonds[0].carrierSystemAccountId;
                    } else {
                        this.errorMessage = 'The selected bonds have different carriers and cannot be applied to a line at the same time';
                    }

                    if (this.bonds.every((bond) => bond.bondAccountId == this.bonds[0].bondAccountId)) {
                        this.bondAccountId = this.bonds[0].bondAccountId;
                    }
                }
            })
    }

    public loadBondAccountOptions(): IPromise<any> {
        if (this.customerId && this.carrierSystemAccountId) {
            return this.bondAccountService.getBondAccountOptionsByCustomerId(this.customerId, this.carrierSystemAccountId)
                .then((bondAccountOptions) => {
                    this.bondAccountOptions = bondAccountOptions;
                    
                    if (!this.bondAccountOptions.length) {
                        this.errorMessage = 'This customer has no bond lines for this carrier';
                    } else if (this.bondAccountOptions.length == 1) {
                        this.bondAccountId = this.bondAccountOptions[0].value;
                    }
                });
            }
    }

    public $onInit() { 
        this.processingMessage = "Loading...";
        this.processingPromise = this.loadBonds()
            .then(() => {
                return this.loadBondAccountOptions();
            });
    }
}

app.controller('BondAccountAssignmentModalController', BondAccountAssignmentModalController);