import { SelectOption } from "../../../../api/types/selectOption";
import app from "../../../../main";
import { LeadReportItem } from "../leadReportItem";
import isUndefinedOrNull from "../../../../utilities/angularUtilities/isUndefinedOrNull";
import { Table } from "../../../../utilities/tables/table";
import { LeadsReportDropdownFilterOptions } from "./leadsReportDropdownFilterOptions";
import { Injectables } from "../../../../configuration/injectables";
import { SystemAccountService } from "../../../../api/systemAccountService";

class LeadReportFilterDropdownController {
    public static $inject = [
        Injectables.SystemAccountService
    ];

    constructor( private readonly systemAccountService: SystemAccountService ) {
        this.modifiedFilters = {} as LeadsReportDropdownFilterOptions;
        this.filterCount = 0;
    }

    public isOpen: boolean;
    public filterCount = 0;
    public table: Table<LeadReportItem, LeadsReportDropdownFilterOptions>;
    public modifiedFilters: LeadsReportDropdownFilterOptions;

    public clearFilters() {
        this.modifiedFilters = {
            leadType: null,
            minBondAmount: null,
            maxBondAmount: null,
            startDate: null,
            endDate: null
        } as LeadsReportDropdownFilterOptions;

        this.table.applyFilters({ ...this.modifiedFilters });

        this.setFilterCount();
        this.isOpen = false;
    }

    public cancel() {
        this.isOpen = false;
        this.modifiedFilters = { ...this.table.queryOptions };
    }

    public applyFilters() {
        this.isOpen = false;
        this.table.applyFilters({ ...this.modifiedFilters });
        this.setFilterCount();
    }

    private setModifiedFilters = () => {
        this.modifiedFilters.leadType = this.table.queryOptions.leadType;
        this.modifiedFilters.minBondAmount = this.table.queryOptions.minBondAmount;
        this.modifiedFilters.maxBondAmount = this.table.queryOptions.maxBondAmount;
        this.modifiedFilters.startDate = this.table.queryOptions.startDate;
        this.modifiedFilters.endDate = this.table.queryOptions.endDate;
        this.setFilterCount();
    };

    public setFilterCount() {
        this.filterCount = 0;

        if (!isUndefinedOrNull(this.table.queryOptions.leadType)) {
            this.filterCount++;
        }

        if (
            this.table.queryOptions.minBondAmount ||
            this.table.queryOptions.maxBondAmount
        ) {
            this.filterCount++;
        }

        if (
            this.table.queryOptions.startDate ||
            this.table.queryOptions.endDate
        ) {
            this.filterCount++;
        }

    }

    public $onInit() {
        this.setModifiedFilters();
    }
}

const leadEntryReportFilterDropdownComponent = {
    bindings: {
        table: "<"
    },
    controller: LeadReportFilterDropdownController,
    controllerAs: "vm",
    templateUrl:
        "app/states/agentSpecific/leads/leadsReportFilterDropdown/leadsEntryReportFilterDropdown.html"
};

app.component(
    "leadEntryReportFilterDropdown",
     leadEntryReportFilterDropdownComponent
);


