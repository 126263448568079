import { DocumentService } from "../../../../../api/documentService";
import { BusyIndicator } from "../../../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../../../configuration/injectables";
import { DashboardService } from "../../dashboardService";
import { DashboardWidget } from "../../types/dashboardWidget";
import app from "../../../../../main";
import { IRootElementService, IQService, IFilterService } from "angular";
import { SlabTextService } from "../../../../../utilities/slabText/slabTextService";
import { JQueryService } from "../../../../../utilities/jquery/jQueryService";
import DropdownOption from "../../../../../api/types/dropdownOption";

class DashboardWidgetBondFormCountController {

    public static $inject = [
        Injectables.$element,
        Injectables.DashboardService,
        Injectables.DocumentService,
        Injectables.$q,
        Injectables.$filter,
        Injectables.SlabTextService,
        Injectables.JQueryService
    ];

    constructor(
        private readonly $element:IRootElementService,
        private readonly dashboardService: DashboardService,
        private readonly documentService: DocumentService,
        private readonly $q: IQService,
        private readonly $filter: IFilterService,
        private readonly slabTextService: SlabTextService,
        private readonly jQueryService: JQueryService
    ) {}

    public widget: DashboardWidget;
    public busyIndicator: BusyIndicator;
    public value: string;
    public dateReferenceOptions: DropdownOption<string>[];
    public statusOptions: DropdownOption<string>[];

    public dateReferenceOptionSelected = (option: DropdownOption<string>) => {
        this.widget.options.dateReference = option;
        this.loadValue();
        this.dashboardService.save();
    };

    public statusOptionSelection = (option: DropdownOption<string>) => {
        this.widget.options.status = option;
        this.loadValue();
        this.dashboardService.save();
    }

    public loadValue = () => {
        var promises = [];

        if (this.widget.options.dateReference.value === 'Right Now') {
            promises.push(this.documentService.currentBondFormCount(this.widget.options.status.value));
        } else {
            return;
        }

        this.busyIndicator.promise = this.$q.all(promises)
            .then((values: number[]) => {
                this.value = this.$filter('number')(values[0]);
               
                this.updateValueSize(this.value);
            });
    }

    public updateValueSize = (value: string) => {
        this.slabTextService.setupSlabText(
            this.jQueryService.getElement('.value', this.$element)
                .html(value),
            { maxFontSize: 40 }
        );
    }

    public $onInit = () => {
        this.busyIndicator = {
            message: 'Loading...'
        };
       
        this.dateReferenceOptions = [
            {
                label: 'Right Now',
                value: 'Right Now'
            },{
                label: 'YTD',
                value: 'YTD'
            },{
                label: 'Last 90 Days',
                value: 'Last 90 Days'
            },{
                label: 'This Month',
                value: 'This Month'
            },{
                label: 'This Week',
                value: 'This Week'
            }
        ];

        this.statusOptions = [
            {
                label: 'All',
                value: 'All'
            },{
                isDivider: true
            },{
                label: 'Untoucned',
                value: 'Untoucned'
            },{
                label: 'Primary Review',
                value: 'Primary Review'
            },{
                label: 'Secondary Review',
                value: 'Secondary Review'
            },{
                label: 'Approved',
                value: 'Approved'
            },{
                label: 'Problem Reported',
                value: 'Problem Reported'
            }
        ];

        this.loadValue();

        this.dashboardService.refreshFunctions.push(this.loadValue);
    }
}

const dashboardWidgetBondFormCountComponent = {
    bindings: {
        widget: '='
    },
    controller: DashboardWidgetBondFormCountController,
    controllerAs: 'vm',
    templateUrl: 'app/states/common/dashboard/widgets/bondFormCount/dashboardWidgetBondFormCount.html'
};

app.component('dashboardWidgetBondFormCount', dashboardWidgetBondFormCountComponent);
