import { IHttpService, IPromise, IQService } from "angular";
import { Injectables } from "../configuration/injectables";
import app from "../main";
import { AutoFee } from "./types/model/autoFee";
import { PageResponse } from "./types/pageResponse";
import { SystemSettings } from "../configuration/settings/systemSettings";
import A3ApiResponse from "./types/a3ApiResponse";
import AutoFeeDetail from "./types/autoFeeDetail";
import { SelectOption } from "./types/selectOption";
import AutoFeeOption from "./types/model/autoFeeOption";
import { AutoFeesDropdownFilterOptions } from "../states/agentSpecific/autoFees/autoFeesFilterDropdown/autoFeesDropdownFilterOptions";
import { CacheStore } from "./cacheStore";

export class AutoFeeService {

    public static $inject = [
        Injectables.SystemSettings,
        Injectables.$http,
        Injectables.CacheStore
    ];

    constructor(
        private readonly systemSettings: SystemSettings,
        private readonly $http: IHttpService,
        private readonly cacheStore: CacheStore) {
    }

    public deleteById(id: number): IPromise<void> {
        const url = `${this.systemSettings.apiBaseUrl}AutoFeeActions/DeleteAutoFee/${id}`;

        return this.$http.delete(url)
            .then(() => {});
    }

    public getById(id: number): IPromise<AutoFeeDetail> {
        const url = `${this.systemSettings.apiBaseUrl}AutoFeeActions/GetAutoFeeDetail?id=${id}`;

        return this.$http.get<A3ApiResponse<AutoFeeDetail>>(url)
            .then((response) => response.data.value);
    }

    public load(tableQueryOptions: AutoFeesDropdownFilterOptions): IPromise<PageResponse<AutoFee>> {
        const queryString = this.buildAutoFeesQueryString(tableQueryOptions);
        const url = `${this.systemSettings.apiBaseUrl}AutoFeeActions/GetAutoFeeItems${queryString}`;

        return this.$http
            .get<A3ApiResponse<PageResponse<AutoFee>>>(url)
            .then((response) => response.data.value);   
    }

    private buildAutoFeesQueryString(tableQueryOptions: AutoFeesDropdownFilterOptions) {
        if (!tableQueryOptions) {
            tableQueryOptions = {
                pageNumber: 1,
                recordsPerPage: 10,
                orderBy: "",
                searchPhrase: ""
            } as AutoFeesDropdownFilterOptions;
        }

        if (!tableQueryOptions.searchPhrase) {
            tableQueryOptions.searchPhrase = "";
        }

        if (!tableQueryOptions.orderBy) {
            tableQueryOptions.orderBy = "id desc";
        }

        if (!tableQueryOptions.pageNumber) {
            tableQueryOptions.pageNumber = 1;
        }

        if (!tableQueryOptions.recordsPerPage) {
            tableQueryOptions.recordsPerPage = 10;
        }

        let queryString = "?";

        queryString += `&pageNumber=${tableQueryOptions.pageNumber}`;
        queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
        queryString += `&orderBy=${tableQueryOptions.orderBy}`;
        queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;

        if (tableQueryOptions.autoApply) {
            queryString += `&autoApply=${tableQueryOptions.autoApply}`;
        }

        if (tableQueryOptions.creditProducer) {
            queryString += `&creditProducer=${tableQueryOptions.creditProducer}`;
        }

        if (tableQueryOptions.includeInBrokerCommissionCalculation) {
            queryString += `&includeInBrokerCommissionCalculation=${tableQueryOptions.includeInBrokerCommissionCalculation}`;
        }

        return queryString;
    }

    public save(autoFee: AutoFeeDetail): IPromise<void> {
        const url = `${this.systemSettings.apiBaseUrl}AutoFeeActions/SaveAutoFee`;

        return this.$http.post<A3ApiResponse<void>>(url, autoFee)
            .then(() => {});
    }

    public getDefaultValues(autoFeeId: number): IPromise<AutoFeeOption> {
        return this.getFeeOptionsPromise()
            .then((options) => options.filter(option => option.id == autoFeeId)[0]);
    }

    public getFeeOptions(): IPromise<SelectOption<number>[]> {
        return this.getFeeOptionsPromise()
            .then((options) => options.map((autoFee) => {
                return {
                    label: autoFee.description,
                    value: autoFee.id
                };
            }));
    }

    private getFeeOptionsPromise(): IPromise<AutoFeeOption[]> {
        const url = `${this.systemSettings.apiBaseUrl}AutoFeeActions/GetFeeOptions`;

        return this.cacheStore.getData(url, () => {
            return this.$http.get<A3ApiResponse<AutoFeeOption[]>>(url)
                .then((response) => response.data.value);
        });
    }
}

app.service(Injectables.AutoFeeService, AutoFeeService);
