import { IPromise, IHttpService } from "angular";
import { Injectables } from "../configuration/injectables";
import app from "../main";
import { Memo } from "./types/model/memo";
import MemoPageRequest from "./types/memoPageRequest";
import { PageResponse } from "./types/pageResponse";
import A3ApiResponse from "./types/a3ApiResponse";
import { SystemSettings } from "../configuration/settings/systemSettings";

export class MemoService {
    
    public static $inject = [
        Injectables.$http, 
        Injectables.SystemSettings
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly systemSettings: SystemSettings
    ) {
    }

    public saveMemo(memo: Memo): IPromise<void> {
        let url = `${this.systemSettings.apiBaseUrl}MemoActions/SaveMemo`;

        return this.$http.post(url, memo)
            .then(() => {});
    }

    public getMemos(tableQueryOptions: MemoPageRequest): IPromise<PageResponse<Memo>>{
        let url = `${this.systemSettings.apiBaseUrl}MemoActions/GetMemos`;

        if (!tableQueryOptions) {
            tableQueryOptions = {
                pageNumber: 1,
                recordsPerPage: 10,
                orderBy: '',
                searchPhrase: ''
            };
        }

        if (!tableQueryOptions.recordsPerPage || !tableQueryOptions.pageNumber) {
            tableQueryOptions.recordsPerPage = 10;
            tableQueryOptions.pageNumber = 1;
        }

        url += `?recordsPerPage=${tableQueryOptions.recordsPerPage}`;
        url += `&pageNumber=${tableQueryOptions.pageNumber}`;
        
        if (tableQueryOptions.applicationId) {
            url += `&applicationId=${tableQueryOptions.applicationId}`;
        }

        if (tableQueryOptions.bondId) {
            url += `&bondId=${tableQueryOptions.bondId}`;
        }

        if (tableQueryOptions.orderBy) {
            url += `&orderBy=${tableQueryOptions.orderBy}`;
        }

        return this.$http.get<A3ApiResponse<PageResponse<Memo>>>(url)
            .then((response) => response.data.value);
    }
}

app.service(Injectables.MemoService, MemoService);
