import { Table } from "../../utilities/tables/table";
import app from "../../main";
import ApplicationListTableQueryOptions from "../applicationList/applicationListQueryOptions";
import ApplicationListItem from "../applicationList/applicationListItem";

class ApplicationListFilterDropdownController {
    public static $inject = [];

    constructor() {
        this.modifiedFilters = {} as ApplicationListTableQueryOptions;
    }

    public isOpen: boolean;
    public filterCount = 0;
    public modifiedFilters: ApplicationListTableQueryOptions;
    
    public table: Table<ApplicationListItem, ApplicationListTableQueryOptions>;

    public clearFilters() {
        this.modifiedFilters.status = null;
        
        this.table.applyFilters({...this.modifiedFilters});
        
        this.setFilterCount();
        this.isOpen = false;
    }

    public cancel() {
        this.isOpen = false;
        this.modifiedFilters = { ...this.table.queryOptions };
    }

    public applyFilters() {
        this.isOpen = false;
        
        this.table.applyFilters({ ...this.modifiedFilters });
        
        this.setFilterCount();
    }

    public setFilterCount() {
        this.filterCount = 0;

        if (this.table.queryOptions.status) {
            this.filterCount++;
        }
    }

    public $onInit() {
        this.modifiedFilters = { ...this.table.queryOptions };
        this.setFilterCount();
    }
}

const applicationListFilterDropdownComponent = {
    bindings: {
        table: '<'
    },
    controller: ApplicationListFilterDropdownController,
    controllerAs: 'vm',
    templateUrl: 'app/components/applicationListFilterDropdown/applicationListFilterDropdown.html'
};

app.component('applicationListFilterDropdown', applicationListFilterDropdownComponent);