import { BondTransactionService } from "../../../../../api/bondTransactionService";
import { BusyIndicator } from "../../../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../../../configuration/injectables";
import { DashboardService } from "../../dashboardService";
import { DashboardWidget } from "../../types/dashboardWidget";
import app from "../../../../../main";
import { IRootElementService, IQService, IFilterService } from "angular";
import { SlabTextService } from "../../../../../utilities/slabText/slabTextService";
import { JQueryService } from "../../../../../utilities/jquery/jQueryService";
import DropdownOption from "../../../../../api/types/dropdownOption";
import { constants } from "../../../../../configuration/constants";

class DashboardWidgetTransactionSumController {
    public static $inject = [
        Injectables.$element,
        Injectables.BondTransactionService,
        Injectables.DashboardService,
        Injectables.$q,
        Injectables.$filter,
        Injectables.SlabTextService,
        Injectables.JQueryService
    ];

    constructor(
        private readonly $element: IRootElementService,
        private readonly bondTransactionService: BondTransactionService,
        private readonly dashboardService: DashboardService,
        private readonly $q: IQService,
        private readonly $filter: IFilterService,
        private readonly slabTextService: SlabTextService,
        private readonly jQueryService: JQueryService
    ) {}

    public widget: DashboardWidget;
    public busyIndicator: BusyIndicator;
    public overComparison: string;
    public value: string;
    public dateReferenceOptions: DropdownOption<string>[];
    public transactionTypeOptions: DropdownOption<string>[];

    public dateReferenceSelected = (option: DropdownOption<string>) => {
        this.widget.options.dateReference = option;
        this.loadValue();
        this.dashboardService.save();
    };

    public transactionTypeSelected = (option: DropdownOption<string>) => {
        this.widget.options.transactionType = option;
        this.loadValue();
        this.dashboardService.save();
    };

    public loadValue = () => {
        const promises = [];

        if (this.widget.options.dateReference.value === "YTD") {
            promises.push(
                this.bondTransactionService.ytdSum(
                    "premium",
                    this.widget.options.transactionType.value
                )
            );
            promises.push(
                this.bondTransactionService.lastYearSum(
                    "premium",
                    this.widget.options.transactionType.value
                )
            );
        } else if (this.widget.options.dateReference.value === "Last 90 Days") {
            promises.push(
                this.bondTransactionService.last90DaysSum(
                    "premium",
                    this.widget.options.transactionType.value
                )
            );
            promises.push(
                this.bondTransactionService.last180ToLast90DaysSum(
                    "premium",
                    this.widget.options.transactionType.value
                )
            );
        } else if (this.widget.options.dateReference.value === "This Month") {
            promises.push(
                this.bondTransactionService.mtdSum(
                    "premium",
                    this.widget.options.transactionType.value
                )
            );
            promises.push(
                this.bondTransactionService.lastMonthSum(
                    "premium",
                    this.widget.options.transactionType.value
                )
            );
        } else if (this.widget.options.dateReference.value === "Last Month") {
            promises.push(
                this.bondTransactionService.lastMonthSum(
                    "premium",
                    this.widget.options.transactionType.value
                )
            );
            promises.push(
                this.bondTransactionService.twoMonthsAgoSum(
                    "premium",
                    this.widget.options.transactionType.value
                )
            );
        } else if (this.widget.options.dateReference.value === "This Week") {
            promises.push(
                this.bondTransactionService.thisWeekSum(
                    "premium",
                    this.widget.options.transactionType.value
                )
            );
            promises.push(
                this.bondTransactionService.lastWeekSum(
                    "premium",
                    this.widget.options.transactionType.value
                )
            );
        } else {
            return;
        }

        this.busyIndicator.promise = this.$q.all(promises).then((values) => {
            this.value = this.$filter("currency")(values[0] as number, "$");

            const timePeriod = this.dashboardService.getTimePeriod(
                this.widget.options.dateReference.value
            );

            const percentIntoTimePeriod =
                this.dashboardService.getPercentIntoTimePeriod(
                    this.widget.options.dateReference.value
                );

            this.overComparison = this.dashboardService.calculateComparisonText(
                values[0] as number,
                values[1] as number,
                timePeriod,
                percentIntoTimePeriod
            );

            this.updateValueSize(this.value);
        });
    };

    public updateValueSize = (value: string) => {
        this.slabTextService.setupSlabText(
            this.jQueryService.getElement(".value", this.$element).html(value),
            { maxFontSize: 40 }
        );
    };

    public $onInit = () => {
        this.busyIndicator = {
            message: "Loading..."
        };

        this.dateReferenceOptions = [
            {
                label: "YTD",
                value: "YTD"
            },
            {
                label: "Last 90 Days",
                value: "Last 90 Days"
            },
            {
                label: "This Month",
                value: "This Month"
            },
            {
                label: "This Week",
                value: "This Week"
            }
        ];

        this.transactionTypeOptions = constants.transactionTypeDropdownOptions;

        this.loadValue();
        this.dashboardService.refreshFunctions.push(this.loadValue);
    };
}

const dashboardWidgetTransactionSumComponent = {
    bindings: {
        widget: "="
    },
    templateUrl:
        "app/states/common/dashboard/widgets/bondTransactionSum/dashboardWidgetTransactionSum.html",
    controller: DashboardWidgetTransactionSumController,
    controllerAs: "vm"
};

app.component(
    "dashboardWidgetTransactionSum",
    dashboardWidgetTransactionSumComponent
);
