import { IHttpService, IPromise } from "angular";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/settings/systemSettings";
import app from "../main";
import { ODataFactory, ODataEndpoint } from "./odata";
import A3ApiResponse from "./types/a3ApiResponse";
import { Rate } from "./types/model/rate";

export class RateService {

public static $inject = [
    Injectables.$http, 
    Injectables.ODataFactory,
    Injectables.SystemSettings
];

    constructor(
        private readonly $http: IHttpService,
        private readonly odata: ODataFactory,
        private readonly systemSettings: SystemSettings){            
        }

    public getAllRates(): IPromise<Rate[]> {
        const svc = this.odata.getService(ODataEndpoint.Rate);

        svc.query.orderby('name');

        return svc.get<A3ApiResponse<Rate[]>>()
            .then((response) => response.data.value);
    }

    public getRateById(rateId: number): IPromise<Rate> {
        return this.$http.get<A3ApiResponse<Rate>>(this.systemSettings.apiBaseUrl + 'SystemAccountActions/GetRateById?rateId=' + rateId)
            .then((response) => response.data.value);
    }

    public saveRate(rate: Rate): IPromise<number | void> {
        return this.$http.post<A3ApiResponse<number | void>>(this.systemSettings.apiBaseUrl + 'SystemAccountActions/SaveRate', rate)
            .then((response) => response.data.value);
    }
}

app.service(Injectables.RateService, RateService);

