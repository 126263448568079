import { AccountDesignator } from "./accountDesignator";
import { AssignedDate } from "./assignedDate";
import { BalanceAmount } from "./balanceAmount";
import { BalanceDate } from "./balanceDate";
import { CreditorClassification } from "./creditorClassification";
import { CustomerId } from "./customerId";
import { DateOfFirstDelinquency } from "./dateOfFirstDelinquency";
import { DateOfLastPayment } from "./dateOfLastPayment";
import { DateReported } from "./dateReported";
import { Narratives } from "./narratives";
import { OriginalAmount } from "./originalAmount";
import { Status } from "./status";
import { StatusDate } from "./statusDate";
import { UpdateIndicator } from "./updateIndicator";

    export interface USCollection { 
    dateReported: DateReported;
    assignedDate: AssignedDate;
    customerId: CustomerId;
    clientNameNumber: string;
    accountNumber: string;
    originalAmount: OriginalAmount;
    balanceAmount: BalanceAmount;
    balanceDate: BalanceDate;
    dateOfLastPayment: DateOfLastPayment;
    statusDate: StatusDate;
    status: Status;
    dateOfFirstDelinquency: DateOfFirstDelinquency;
    accountDesignator: AccountDesignator;
    creditorClassification: CreditorClassification;
    updateIndicator: UpdateIndicator;
    narratives: Narratives;
}