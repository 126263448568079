import { Application } from "./application";
import { Attachment } from "../attachment";
import { Bond } from "../bond";
import { IntegrationType } from "../integrationType";
import { Tag } from "../tag";
import { SystemType } from "./application";
import { BondAccount } from "./bondAccount";
import { Company } from "./company";
import { EProducerAccount } from "./eProducerAccount";
import { IBaseEntity } from "./iBaseEntity";
import { Invoice } from "./invoice";
import { PaymentTransaction } from "./paymentTransaction";
import { Person } from "./person";
import { SystemAccount } from "./systemAccount";

export interface Customer extends IBaseEntity { 
    systemAccountId: number;
    ams360CustomerId: string;
    ams360CustomerNumber: number;
    applications: Application[];
    attachments: Attachment[];
    balance: number;
    bondAccounts: BondAccount[];
    bonds: Bond[];
    comments: string;
    companies: Company[];
    customerType: CustomerType;
    eProducerAccount: EProducerAccount;
    eProducerAccountId: number;
    invoices: Invoice[];
    name: string;
    originSystem: SystemType;
    paymentTransactions: PaymentTransaction[];
    people: Person[];
    systemAccount: SystemAccount;
    tags: Tag[];
    id: number;
    createdDateTime: Date;
    $integrationType: IntegrationType;
    $highlighted: string;
}

export enum CustomerType {	
    Company = "Company",
    Individual = "Individual"
}