import { BondService } from "../../../../../api/bondService";
import { ImportBondPreview } from "../../../../../api/types/importBondPreview";
import { BusyIndicator } from "../../../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../../../configuration/injectables";
import { ToastMessageCreator } from "../../../../../utilities/toastMessages/toastMessageCreator";
import { DashboardWidget } from "../../types/dashboardWidget";
import app from "../../../../../main";
import { IQService, IDeferred } from "angular";

export class ImportBondsWidgetController {

    public static $inject = [
        Injectables.BondService,
        Injectables.$q,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly bondService: BondService,
        private readonly $q: IQService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {}

    public busyIndicator: BusyIndicator;
    public widget: DashboardWidget;
    private loadingPromiseDefer:IDeferred<any>;
    public importPreviewBonds: ImportBondPreview[];
    public totalValidBonds: number;
    public totalInvalidBonds: number;
    public totalExistingMatches: number;
    public hasErrors: boolean;
    public importCompleted: boolean;
    public showExisting: boolean;
    public showErrorsOnly: boolean;
    
    public clearResults = () => {
        this.importPreviewBonds = null;
        this.totalValidBonds = 0;
        this.totalInvalidBonds = 0;
        this.importCompleted = false;
    }

    public downloadImportTemplate = () => {
        this.bondService.downloadRenewalTemplate();
    }

    public templateFileSelected = () => {
        this.busyIndicator.message = 'Processing Template...';
        this.loadingPromiseDefer = this.$q.defer();
        
        this.busyIndicator.promise = this.loadingPromiseDefer.promise;
    }

    public templateFileRead = (fileDataUrl: string) => {
        this.bondService.uploadBondImportTemplate(fileDataUrl)
            .then((bonds) => {
                this.importPreviewBonds = bonds;
                this.setCounts();
                this.hasErrors = this.totalInvalidBonds > 0;
                this.loadingPromiseDefer.resolve();
            })
            .catch(() => {
                this.loadingPromiseDefer.reject();
                this.toastMessageCreator.createErrorMessage('An error occurred trying to process the import template');
            });
    }

    private setCounts = () => {
        this.totalExistingMatches = this.importPreviewBonds.filter((bond) => bond.alreadyExists === true).length;
        this.totalInvalidBonds = this.importPreviewBonds.filter((bond) => bond.hasError === true).length;                 
        this.totalValidBonds = this.importPreviewBonds.length - this.totalExistingMatches - this.totalInvalidBonds;
    }

    public filterPreviewBonds = (bond: ImportBondPreview): boolean => {
        if (this.showErrorsOnly) {
            return bond.hasError === true;
        }

        if (!this.showExisting) {
            return bond.alreadyExists !== true;
        }

        return true;
    }

    public importBonds = () => {
        this.busyIndicator.message = 'Processing Import...';
        this.busyIndicator.promise = this.bondService.importBonds(this.importPreviewBonds)
            .then((importedBonds) => {
                let hasError = false;
                this.importCompleted = true;

                for(let i = 0; i < this.importPreviewBonds.length; i++) {
                    const matchingImportedBond = importedBonds.filter((importedBond) => 
                        importedBond.bondNumber === this.importPreviewBonds[i].bondNumber && 
                        importedBond.term === this.importPreviewBonds[i].term
                    )[0];

                    if (!matchingImportedBond) {
                        hasError = true;
                        this.importPreviewBonds[i].errorMessage = 'Bond was not processed for import';
                        continue;
                    }

                    if (matchingImportedBond.hasError) {
                        hasError = true;
                        this.importPreviewBonds[i].errorMessage = 'An error occurred trying to import';
                    }

                    this.importPreviewBonds[i].hasError = matchingImportedBond.hasError;
                    this.importPreviewBonds[i].id = matchingImportedBond.id;
                    this.importPreviewBonds[i].imported = matchingImportedBond.imported;
                }

                if (hasError) {
                    this.toastMessageCreator.createErrorMessage('An error occurred importing one or more bonds');
                } else {
                    this.toastMessageCreator.createSuccessMessage('All bonds were imported successfully');
                }

                this.setCounts();
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to import these bonds');
            });
    }

    public $onInit(): void {  
        this.busyIndicator = {}; 
    }
}

const importBondsWidgetComponent = {
    bindings: {
        widget: '='
    },
    controller: ImportBondsWidgetController,
    controllerAs: 'vm',
    templateUrl: 'app/states/common/dashboard/widgets/importBonds/dashboardWidgetImportBonds.html',
}

app.component('importBondsWidget', importBondsWidgetComponent);