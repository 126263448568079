import { CurrentUserSystemAccount } from "./authentication/currentUserSystemAccount";
import { CurrentUserUser } from "./authentication/currentUserUser";
import { PermissionsLocalStorage } from "./permissionsLocalStorage";

export interface AuthDataLocalStorage {
    token: string;
    loginId: number;
    hasMultipleUsers: boolean;
    systemAccount?: CurrentUserSystemAccount;
    user?: CurrentUserUser;
    permissions?: PermissionsLocalStorage;
    isSingleSignOn?: boolean;
}