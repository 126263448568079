import { Application } from "./application";
import { Bond } from "./bond";
import { CarrierAppointment } from "./carrierAppointment";
import { IBaseEntity } from "./iBaseEntity";
import { SystemAccount } from "./systemAccount";
import { User } from "./user";

export interface Memo extends IBaseEntity { 
    applicationId: number;
    bondId: number;
    carrierAppointmentId: number;
    userId: number;
    systemAccountId: number;
    memoText: string;
    systemAccount: SystemAccount;
    application: Application;
    bond: Bond;
    user: User;
    carrierAppointment: CarrierAppointment;
    id: number;
    createdDateTime: Date;
}