import { BondTransaction } from "../../api/types/model/bondTransaction";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { ShippingModalInfoModalOptions } from "./shippingModalInfoOptions";

export class ShippingInfoModalController{

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options 
    ];
    
    constructor(
        private readonly $uibModalInstance:  Modal<void>,
        private readonly options: ShippingModalInfoModalOptions
    ) {}
    
    public bondTransaction: BondTransaction

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }

    public $onInit(): void {
        this.bondTransaction = this.options.bondTransaction;
    }            
}

app.controller('ShippingInfoModalController', ShippingInfoModalController);
