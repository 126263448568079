import { IQService, IHttpService, IPromise } from "angular";
import { PaymentReportFilterOptions } from "../components/paymentsReportFilterDropdown/paymentReportFilterOptions";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/settings/systemSettings";
import app from "../main";
import { PaymentReportItem } from "../states/agentSpecific/payments/paymentReportItem";
import { FileDownloader } from "./fileDownloader";
import A3ApiResponse from "./types/a3ApiResponse";
import { PageResponse } from "./types/pageResponse";
import { Payment } from "./types/payment";
import { CreditCardFeeRequest } from "./types/payments/creditCardFeeRequest";
import { CreditCardFeeResponseDto } from "./types/payments/creditCardFeeResponseDto";
import { PaymentForReassignment } from "./types/payments/paymentAssignment/paymentForReassignment";
import { PaymentResponseDto } from "./types/payments/paymentResponseDto";
import { ProcessPaymentDto } from "./types/payments/processPaymentDto";
import { SelectOption } from "./types/selectOption";
import BillingEntryPaymentAssignmentRequest from "../modals/paymentAssignmentModal/paymentAssignmentRequest";
import PaymentTableQueryOptions from "./types/payments/paymentTableQueryOptions";
import { PaymentListItem } from "../components/paymentList/paymentListItem";
import { OpenBillingEntries } from "./types/payments/paymentAssignment/openBillingEntries";

export class PaymentService {

    public static $inject = [
        Injectables.$q,
        Injectables.$http,
        Injectables.FileDownloader,
        Injectables.SystemSettings
    ];

    constructor(
        readonly $q: IQService,
        private readonly $http: IHttpService,
        private readonly fileDownloader: FileDownloader,
        private readonly systemSettings: SystemSettings) {
        }

    public assignPayment(request: BillingEntryPaymentAssignmentRequest): IPromise<void> {
        return this.$http
            .post<A3ApiResponse<void>>(this.systemSettings.apiBaseUrl + 'PaymentActions/AssignPayment', request)
            .then(() => {});
    }

    public getBillingEntriesForPaymentReassignment(customerId?: number, eproducerAccountId?: number) {
        const url = `${this.systemSettings.apiBaseUrl}PaymentActions/GetBillingEntriesForPaymentReassignment?customerId=${customerId}&eproducerAccountId=${eproducerAccountId}`;
        return this.$http
            .get<A3ApiResponse<OpenBillingEntries[]>>(url)
            .then((response) => response.data.value);
    }

    public getPaymentForAssignment(paymentTransactionId): IPromise<PaymentForReassignment> {
        return this.$http
            .get<A3ApiResponse<PaymentForReassignment>>(this.systemSettings.apiBaseUrl + 'PaymentActions/GetPaymentForReassignment?paymentTransactionId=' + paymentTransactionId)
            .then((response) => response.data.value);
    }

    public getPaymentTransactionFees(request: CreditCardFeeRequest): IPromise<CreditCardFeeResponseDto> {
        return this.$http
            .post<A3ApiResponse<CreditCardFeeResponseDto>>(this.systemSettings.apiBaseUrl + 'EProducerActions/GetGatewayTransactionFee', request)
            .then((response) => response.data.value);
    }

    public processPayment(payment: Payment): IPromise<PaymentResponseDto> {
        const url = `${this.systemSettings.apiBaseUrl}PaymentActions/processPayment`;
        
        return this.$http
            .post<A3ApiResponse<PaymentResponseDto>>(url, { payment: payment } as ProcessPaymentDto)
            .then((response) => response.data.value);
    }

    public downloadPaymentReport(tableQueryOptions: PaymentReportFilterOptions): IPromise<void> {
        return this.fileDownloader.downloadFile(
            this.systemSettings.apiBaseUrl + 'PaymentActions/DownloadPaymentReport', 
            tableQueryOptions
        );
    }

    public getPaymentReportData(tableQueryOptions: PaymentReportFilterOptions): IPromise<PageResponse<PaymentReportItem>> {
        const queryString = this.buildPaymentReportQueryString(tableQueryOptions);

        return this.$http.get<A3ApiResponse<PageResponse<PaymentReportItem>>>(
                this.systemSettings.apiBaseUrl + 'PaymentActions/GetPaymentReport' + queryString
            )
            .then((response) => response.data.value);
    }

    public async getPaymentListItems(tableQueryOptions: PaymentTableQueryOptions): Promise<PageResponse<PaymentListItem>> {
        let queryString = '?';

        if (!tableQueryOptions.searchPhrase) {
            tableQueryOptions.searchPhrase = "";
        }

        if (!tableQueryOptions.orderBy) {
            tableQueryOptions.orderBy = 'id desc';
        }

        if (tableQueryOptions.customerId) {
            queryString += `customerId=${tableQueryOptions.customerId}`;
        }

        if (tableQueryOptions.eProducerAccountId) {
            queryString += `eProducerAccountId=${tableQueryOptions.eProducerAccountId}`;
        }

        if (tableQueryOptions.paymentStatus) {
            queryString += `&paymentStatus=${tableQueryOptions.paymentStatus}`;
        }

        if (tableQueryOptions.minimumAmount) {
            queryString += `&minimumAmount=${tableQueryOptions.minimumAmount}`;
        }

        if (tableQueryOptions.maximumAmount) {
            queryString += `&maximumAmount=${tableQueryOptions.maximumAmount}`;
        }
        
        if (tableQueryOptions.startDate) {
            queryString += `&startDate=${tableQueryOptions.startDate}`;
        }
        
        if (tableQueryOptions.endDate) {
            queryString += `&endDate=${tableQueryOptions.endDate}`;
        }

        if (
            tableQueryOptions.isNotFullyApplied && (
                tableQueryOptions.isNotFullyApplied.toString() === 'true' || 
                tableQueryOptions.isNotFullyApplied.toString() === 'false'
            )
        ){
            queryString += `&isNotFullyApplied=${tableQueryOptions.isNotFullyApplied}`;
        }

        if (tableQueryOptions.paymentMethod !== null && tableQueryOptions.paymentMethod !== undefined) {
            queryString += `&paymentMethod=${tableQueryOptions.paymentMethod}`;
        }

        queryString += `&pageNumber=${tableQueryOptions.pageNumber}`;
        queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
        queryString += `&orderBy=${tableQueryOptions.orderBy}`;
        queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;
        
        const url = `${this.systemSettings.apiBaseUrl}PaymentActions/GetPaymentListItems${queryString}`;
        
        return await this.$http.get<A3ApiResponse<PageResponse<PaymentListItem>>>(url)
            .then((response) => response.data.value);
    }

    public getAchAccountTypeOptions(): SelectOption<string>[] {
        return [
            { label: 'Checking', value: 'Checking' },
            { label: 'Savings', value: 'Savings' },
            { label: 'Corporate', value: 'Corporate' },
            { label: 'Corporate Savings', value: 'CorpaSavings' },
        ];
    }

    private buildPaymentReportQueryString(tableQueryOptions: PaymentReportFilterOptions) {
        if (!tableQueryOptions) {
            tableQueryOptions = {
                pageNumber: 1,
                recordsPerPage: 10,
                orderBy: '',
                searchPhrase: ''
            }
        }
        
        if (!tableQueryOptions.searchPhrase) {
            tableQueryOptions.searchPhrase = '';
        }

        if (!tableQueryOptions.orderBy) {
            tableQueryOptions.orderBy = 'id desc';
        }

        if (!tableQueryOptions.pageNumber) {
            tableQueryOptions.pageNumber = 1;
        }

        if (!tableQueryOptions.recordsPerPage) {
            tableQueryOptions.recordsPerPage = 10;
        }
        
        let queryString = '?';

        queryString += `&pageNumber=${tableQueryOptions.pageNumber}`;
        queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
        queryString += `&orderBy=${tableQueryOptions.orderBy}`;
        queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;

        if (tableQueryOptions.status) {
            queryString += `&status=${tableQueryOptions.status}`;
        }

        if (tableQueryOptions.minimumAmount) {
            queryString += `&minimumAmount=${tableQueryOptions.minimumAmount}`;
        }

        if (tableQueryOptions.maximumAmount) {
            queryString += `&maximumAmount=${tableQueryOptions.maximumAmount}`;
        }
        
        if (tableQueryOptions.startDate) {
            queryString += `&startDate=${tableQueryOptions.startDate}`;
        }
        
        if (tableQueryOptions.endDate) {
            queryString += `&endDate=${tableQueryOptions.endDate}`;
        }

        if (
            tableQueryOptions.isNotFullyApplied && (
                tableQueryOptions.isNotFullyApplied.toString() === 'true' || 
                tableQueryOptions.isNotFullyApplied.toString() === 'false'
            )
        ){
            queryString += `&isNotFullyApplied=${tableQueryOptions.isNotFullyApplied}`;
        }

        if (tableQueryOptions.paymentMethod !== null && tableQueryOptions.paymentMethod !== undefined) {
            queryString += `&paymentMethod=${tableQueryOptions.paymentMethod}`;
        }

        return queryString;
    }
}

app.service(Injectables.PaymentService, PaymentService);
