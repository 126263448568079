import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { ITextHighlighter } from "../../utilities/textHighlighter/iTextHighlighter";


export const highlightFilter = (iTextHighlighter: ITextHighlighter) => {

    return (text: string, searchText: string): string => {
        
        if (!text) {
            return '';
        }

        if (!searchText) {
            return text;
        }

        return iTextHighlighter.highlight(text, searchText);
    }
};   

highlightFilter.$inject = [Injectables.ITextHighlighter];

app.filter('highlight', highlightFilter);
