import { SystemAccountService } from "../../../api/systemAccountService";
import { SelectOption } from "../../../api/types/selectOption";
import { Injectables } from "../../../configuration/injectables";
import app from "../../../main";
import { Table } from "../../../utilities/tables/table";
import { TransactionReportTableQueryOptions } from "./transactionReportTableQueryOptions";
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";
import { TransactionReportListItem } from "./bondTransactionReportItem";
import { SfaaCodeService } from "../../../api/sfaaCodeService";
import { TagService } from "../../../api/tagService";
import { IPromise } from "angular";


class TransactionReportFilterDropdownController {
    public static $inject = [
        Injectables.SystemAccountService,
        Injectables.CurrentUserResolver,
        Injectables.SfaaCodeService,
        Injectables.TagService
    ];

    constructor(
        private readonly systemAccountService: SystemAccountService,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly sfaaCodeService: SfaaCodeService,
        private readonly tagService: TagService
    ) {

        this.modifiedFilters = {} as TransactionReportTableQueryOptions;
        this.filterCount = 0;
    }

    public isOpen: boolean;
    public filterCount: number;
    public tagOptions: SelectOption<string>[];
    public sfaaCodeOptions: SelectOption<number>[] = [];
    public modifiedFilters: TransactionReportTableQueryOptions;
    public carrierOptions: SelectOption<number>[];
    public agencyOptions: SelectOption<number>[];
    public isCarrier: boolean;
    public table: Table<TransactionReportListItem, TransactionReportTableQueryOptions>;
    
    private loadCarrierOptions() {
        return this.systemAccountService.getAppointedCarrierOptions()
            .then((carrierOptions) => {
                this.carrierOptions = carrierOptions;
                this.carrierOptions.unshift({ label: 'Any', value: null });
            });
    }

    private loadAgencyOptions() {
        return this.systemAccountService.getAppointedAgencyOptions()
            .then((agencyOptions) => {
                this.agencyOptions = agencyOptions;
                this.agencyOptions.unshift({ label: 'Any', value: null});
            });
    }

    public loadSfaaCodes(): IPromise<void> {
        return this.sfaaCodeService
            .getNewAllSfaaCodeOptions()
            .then((options) => {
                this.sfaaCodeOptions = options
            });
    }

    public clearFilters() {
        this.modifiedFilters = {
            carrierSystemAccountId:null,
            agencySystemAccountId: null,
            minimumBondAmount: null,
            maximumBondAmount: null,
            minimumCommissionAmount: null,
            maximumCommissionAmount: null,
            minimumRefundAmount: null,
            maximumRefundAmount: null,
            minimumPremiumAmount: null,
            maximumPremiumAmount: null,
            minimumEffectiveDate: null,
            maximumEffectiveDate: null,
            minimumCreatedDateTime: null,
            maximumCreatedDateTime: null,
            transactionType: null,
            tag: null,
            requestedState: null,
            requestedBondTypeName: null,
            sfaaCodeId: null,
            agencyCode: null,
            agencySubCode: null,
            requestedBrokerContains: null,
            bondStatus: null
        } as TransactionReportTableQueryOptions ;
        this.table.applyFilters({ ...this.modifiedFilters });

        this.setFilterCount();
        this.isOpen = false;
    }

    public cancel() {
        this.isOpen = false;
        this.modifiedFilters = { ...this.table.queryOptions };
    }

    public applyFilters() {
        this.isOpen = false;
        this.table.applyFilters({ ...this.modifiedFilters });
        this.setFilterCount();
    }

    private setModifiedFilters = () => {
        this.modifiedFilters.carrierSystemAccountId = this.table.queryOptions.carrierSystemAccountId;
        this.modifiedFilters.agencySystemAccountId = this.table.queryOptions.agencySystemAccountId;
        this.modifiedFilters.minimumEffectiveDate = this.table.queryOptions.minimumEffectiveDate;
        this.modifiedFilters.maximumEffectiveDate = this.table.queryOptions.maximumEffectiveDate;
        this.modifiedFilters.minimumCreatedDateTime = this.table.queryOptions.minimumCreatedDateTime;
        this.modifiedFilters.maximumCreatedDateTime = this.table.queryOptions.maximumCreatedDateTime;
        this.modifiedFilters.minimumBondAmount = this.table.queryOptions.minimumBondAmount;
        this.modifiedFilters.maximumBondAmount = this.table.queryOptions.maximumBondAmount;
        this.modifiedFilters.minimumCommissionAmount = this.table.queryOptions.minimumCommissionAmount;
        this.modifiedFilters.maximumCommissionAmount = this.table.queryOptions.maximumCommissionAmount;
        this.modifiedFilters.minimumRefundAmount = this.table.queryOptions.minimumRefundAmount;
        this.modifiedFilters.maximumRefundAmount = this.table.queryOptions.maximumRefundAmount;
        this.modifiedFilters.minimumPremiumAmount = this.table.queryOptions.minimumPremiumAmount;
        this.modifiedFilters.maximumPremiumAmount = this.table.queryOptions.maximumPremiumAmount;
        this.modifiedFilters.transactionType = this.table.queryOptions.transactionType;
        this.modifiedFilters.tag =  this.table.queryOptions.tag; 
        this.modifiedFilters.sfaaCodeId = this.table.queryOptions.sfaaCodeId;
        this.modifiedFilters.requestedState = this.table.queryOptions.requestedState;
        this.modifiedFilters.requestedBondTypeName = this.table.queryOptions.requestedBondTypeName;
        this.modifiedFilters.agencyCode = this.table.queryOptions.agencyCode;
        this.modifiedFilters.agencySubCode = this.table.queryOptions.agencySubCode;
        this.modifiedFilters.requestedBrokerContains = this.table.queryOptions.requestedBrokerContains;
        this.modifiedFilters.bondStatus = this.table.queryOptions.bondStatus;
        this.setFilterCount();
    };

    public setFilterCount() {
        this.filterCount = 0;

        if (this.table.queryOptions.carrierSystemAccountId) {
            this.filterCount++;
        }
        
        if (this.table.queryOptions.agencySystemAccountId) {
            this.filterCount++;
        }

        if (
            this.table.queryOptions.minimumEffectiveDate ||
            this.table.queryOptions.maximumEffectiveDate
        ) {
            this.filterCount++;
        }

        if (
            this.table.queryOptions.minimumCreatedDateTime ||
            this.table.queryOptions.maximumCreatedDateTime
        ) {
            this.filterCount++;
        }

        if ( 
            this.table.queryOptions.maximumBondAmount ||
            this.table.queryOptions.minimumBondAmount
        ) {
            this.filterCount++;
        }

        if ( 
            this.table.queryOptions.maximumCommissionAmount ||
            this.table.queryOptions.minimumCommissionAmount
        ) {
            this.filterCount++;
        }

        if ( 
            this.table.queryOptions.maximumRefundAmount ||
            this.table.queryOptions.minimumRefundAmount
        ) {
            this.filterCount++;
        }

        if ( 
            this.table.queryOptions.maximumPremiumAmount ||
            this.table.queryOptions.minimumPremiumAmount
        ) {
            this.filterCount++;
        }

        if ( this.table.queryOptions.transactionType) {
            this.filterCount++;
        }

        if (this.table.queryOptions.tag) {
            this.filterCount++;
        }

        if (this.table.queryOptions.sfaaCodeId) {
            this.filterCount++;
        }

        if (this.table.queryOptions.agencyCode) {
            this.filterCount++;
        }

        if (this.table.queryOptions.agencySubCode) {
            this.filterCount++;
        }

        if (this.table.queryOptions.requestedState) {
            this.filterCount++;
        }

        if (this.table.queryOptions.requestedBondTypeName) {
            this.filterCount++;
        }

        if (this.table.queryOptions.requestedBrokerContains) {
            this.filterCount++;
        }

        if (this.table.queryOptions.bondStatus) {
            this.filterCount++;
        }
    }

    private async loadTagOptions() {
        this.tagOptions = await this.tagService.loadTagOptions();
    }

    public $onInit() {
        this.isCarrier = this.currentUserResolver.getCurrentUser().systemAccount.isCarrier;

        if (this.isCarrier) {
            this.loadAgencyOptions();
        } else {
            this.loadCarrierOptions();
        }
        this.loadTagOptions();
        this.loadSfaaCodes();
        this.setModifiedFilters();
    }
}

const transactionReportFilterDropdownComponent = {
    bindings: {
        table: "<"
    },
    controller: TransactionReportFilterDropdownController,
    controllerAs: "vm",
    templateUrl: "app/states/common/bondTransactions/transactionReportFilterDropdown.html"
};

app.component(
    "transactionReportFilterDropdown",
    transactionReportFilterDropdownComponent
);