import { BondChange } from "../model/bondChange";

export type RiderRequestForApproval = {
    id: number;
    effectiveDate: Date;
    attorneyInFactUserId: number;
    changes: BondChange[];
    premiumChange: number;
    commissionChange: number;
    reasonId: number;
    agencyComments: string;
    carrierComments: string;
}