import { IScope } from "angular";
import { EProducerService } from "../../api/eProducerService";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";

export type EProducerBrokerSelectionOption = {
    name: string;
    physicalAddress: string;
    physicalCity: string;
    physicalState: string;
    phone: string;
};

class EproducerBrokerSelectionController {
    
    public static $inject = [
        Injectables.EProducerService,
        Injectables.$scope
    ];

    constructor(
        private readonly eProducerService: EProducerService,
        private readonly $scope: IScope
    ) {
    }

    public isReferral: boolean;
    public validity: string;
    public searchingEProducerBrokers: boolean;
    public eProducerBrokerSearch: string;
    public selectedAccount: EProducerBrokerSelectionOption;
    public matchingEProducerBrokers: EProducerBrokerSelectionOption[];
    
    public isReferralChanged = () => {
        if (this.isReferral) {
            this.setValidity();
        } else {
            this.clearSelection();
        }
    }

    public setSelection = (broker) => {
        this.selectedAccount = broker;
        this.setValidity();
    }

    public clearSelection = () => {
        this.selectedAccount = null;
        this.setValidity();
    }

    public fieldChanged = () => {
        this.setValidity();
    }

    public searchEProducerBrokers = () => {
        if (!this.eProducerBrokerSearch || this.eProducerBrokerSearch.length < 3) {
            return;
        }

        this.searchingEProducerBrokers = true;

        this.eProducerService.searchEProducerBrokers(this.eProducerBrokerSearch)
            .then((accounts) => {
                this.searchingEProducerBrokers = false;
                this.matchingEProducerBrokers = accounts;
            });
    }

    public setValidity = () => {
        if (!this.isReferral || this.selectedAccount) {
            this.validity = 'valid';
        } else {
            this.validity = null;
        }
    }

    public $onInit = () => {
        if (!this.isReferral) {
            this.isReferral = false;
        }

        this.$scope.$watchGroup([
                'this.isReferral', 
                'this.selectedAccount'
            ], () => {
                this.setValidity();
        });

        this.setValidity();
    }
}

const eproducerBrokerSelectionComponent = {
    templateUrl: 'app/components/eProducerBrokerSelection/eProducerBrokerSelection.html',
    controller: EproducerBrokerSelectionController,
    controllerAs: 'vm',
    bindings: {
        selectedAccount: '=?',
        isReferral: '=?',
        required: '=?',
        form: '=?'
    }
}

app.component('eproducerBrokerSelection', eproducerBrokerSelectionComponent);