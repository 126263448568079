import { EProducerService } from "../../api/eProducerService";
import { TagService } from "../../api/tagService";
import { EProducerAccount } from "../../api/types/model/eProducerAccount";
import { SelectOption } from "../../api/types/selectOption";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { SearchControl } from "../../utilities/searchControl";
import { Table } from "../../utilities/tables/table";
import { CustomerListItem } from "../customerList/customerListItem";
import { CustomerTableQueryOptions } from "../customerList/customerTableQueryOptions";
import { CustomerSearchResult } from "../customerSearchDropdown/customerSearchResult";


class CustomerListFilterDropdownController {
    
    public static $inject = [
        Injectables.TagService,
        Injectables.EProducerService
    ];

    constructor(
            private readonly tagService: TagService,
            private readonly eProducerService: EProducerService
        ) {
        this.modifiedFilters = {} as CustomerTableQueryOptions;
    }

    public isOpen: boolean;
    public filterCount: number = 0;
    public modifiedFilters: CustomerTableQueryOptions;
    public tagOptions: SelectOption<string>[];
    public accountSearchResults: EProducerAccount[];
    public selectedBroker: EProducerAccount;
    public parentCompanySearchControl: SearchControl<CustomerSearchResult>;

    public table: Table<CustomerListItem, CustomerTableQueryOptions>;

    public clearFilters() {
        this.modifiedFilters.contactFirstName = null;
        this.modifiedFilters.contactLastName = null;
        this.modifiedFilters.contactEmail = null;
        this.modifiedFilters.contactPhone = null;
        this.modifiedFilters.isActive = null;
        this.modifiedFilters.tag = null;
        this.modifiedFilters.brokerId = null;
        this.modifiedFilters.parentCustomerId = null;
        this.selectedBroker = null;

        this.parentCompanySearchControl.clear();
        
        this.table.applyFilters({ ...this.modifiedFilters });
        
        this.setFilterCount();
        this.isOpen = false;
    }

    public cancel() {
        this.isOpen = false;
        this.modifiedFilters = { ...this.table.queryOptions };
    }

    public applyFilters() {
        this.isOpen = false;

        if (this.selectedBroker) {
            this.modifiedFilters.brokerId = this.selectedBroker.id;
        } else { 
            this.modifiedFilters.brokerId = null;
        }

        if (this.parentCompanySearchControl.selected) {
            this.modifiedFilters.parentCustomerId = this.parentCompanySearchControl.selected.id;
        } else {
            this.modifiedFilters.parentCustomerId = null;
        }

        this.table.applyFilters({ ...this.modifiedFilters });
        
        this.setFilterCount();
    }

    public setFilterCount() {
        this.filterCount = 0;

        if (this.table.queryOptions.isActive !== null && this.table.queryOptions.isActive !== undefined) {
            this.filterCount++;
        }

        if (this.table.queryOptions.contactFirstName) {
            this.filterCount++;
        }

        if (this.table.queryOptions.contactLastName) {
            this.filterCount++;
        }

        if (this.table.queryOptions.contactEmail) {
            this.filterCount++;
        }

        if (this.table.queryOptions.contactPhone) {
            this.filterCount++;
        }

        if (this.table.queryOptions.tag) {
            this.filterCount++;
        }

        if (this.table.queryOptions.brokerId) {
            this.filterCount++;
        }

        if (this.table.queryOptions.parentCustomerId) {
            this.filterCount++;
        }
    }

    private async loadTagOptions() {
        this.tagOptions = await this.tagService.loadTagOptions();
    }

    public searchEProducerAccounts(searchPhrase: string) {
        this.eProducerService.searchEProducerBrokers(searchPhrase)
            .then((results) => {
                this.accountSearchResults = results;
            });
    } 

    public clearBrokerselection() {
        this.modifiedFilters.brokerId = null;
        this.selectedBroker = null;

        this.setFilterCount();
    }

    public $onInit() {
        this.modifiedFilters = { ...this.table.queryOptions };
        this.setFilterCount();
        this.loadTagOptions();
    }
}

const customerListFilterDropdownComponent = {
    bindings: {
        table: '<'
    },
    controller: CustomerListFilterDropdownController,
    controllerAs: 'vm',
    templateUrl: 'app/components/customerListFilterDropdown/customerListFilterDropdown.html'
};

app.component('customerListFilterDropdown', customerListFilterDropdownComponent);