import {
    ODataFactory,
    ODataFilterCollection,
    ODataEndpoint,
    ODataFilterDataType
} from "../../../../../api/odata";
import { SfaaCodeService } from "../../../../../api/sfaaCodeService";
import { TagService } from "../../../../../api/tagService";
import isDefined from "../../../../../utilities/angularUtilities/isDefined";
import { Application } from "../../../../../api/types/model/application";
import { SelectOption } from "../../../../../api/types/selectOption";
import { BusyIndicator } from "../../../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../../../configuration/injectables";
import { DashboardService } from "../../dashboardService";
import { DashboardWidget } from "../../types/dashboardWidget";
import A3ApiResponse from "../../../../../api/types/a3ApiResponse";
import app from "../../../../../main";
import { ITimeoutService } from "angular";
import { Table } from "../../../../../utilities/tables/table";
import * as angular from "angular";
import { IDebounceDelayer } from "../../../../../utilities/debounceDelayer/iDebouceDelayer";
import { QuoteStatus } from "../../../../../api/types/model/quote";
import { CurrentUserResolver } from "../../../../../utilities/currentUserResolver/currentUserResolver";

class DashboardWidgetOpenApplicationsListCarrierController {
    public static $inject = [
        Injectables.CurrentUserResolver,
        Injectables.ODataFactory,
        Injectables.DashboardService,
        Injectables.$timeout,
        Injectables.TagService,
        Injectables.SfaaCodeService,
        Injectables.IDebouceDelayer
    ];

    constructor(
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly odata: ODataFactory,
        private readonly dashboardService: DashboardService,
        private readonly $timeout: ITimeoutService,
        private readonly tagService: TagService,
        private readonly sfaaCodeService: SfaaCodeService,
        debouceDelayer: IDebounceDelayer
    ) {
        this.table = new Table(debouceDelayer);
    }

    public widget: DashboardWidget;
    public busyIndicator: BusyIndicator;
    public table: Table<Application, null>;
    public filterCollection: ODataFilterCollection;
    public tagOptions: SelectOption<string>[];
    public sfaaCodeOptions: SelectOption<string>[];
    public errorMessage: string;

    public setFilters = () => {
        this.filterCollection = this.odata.getFilterCollection([
            this.odata.getFilter(
                "(quotes/any(q:q/status eq 'Approved') and '{0}' eq 'Approved') or (quotes/all(q:q/status ne 'Approved') and '{0}' eq 'Pending')",
                ODataFilterDataType.select,
                "Status",
                null,
                null,
                true,
                [
                    { label: "Manual Review", value: "Pending" },
                    { label: "Approved", value: "Approved" }
                ]
            ),
            this.odata.getFilter(
                "bondType/name",
                ODataFilterDataType.string,
                "Bond Type"
            ),
            this.odata.getFilter(
                "nameOnBond",
                ODataFilterDataType.string,
                "Principal"
            ),
            this.odata.getFilter(
                "bondType/sfaaCode/code",
                ODataFilterDataType.select,
                "SFAA Code",
                null,
                null,
                true,
                this.sfaaCodeOptions
            ),
            this.odata.getFilter(
                "bondType/obligee/requisitioningState",
                ODataFilterDataType.state,
                "State"
            ),
            this.odata.getFilter(
                "desiredEffectiveDate",
                ODataFilterDataType.date,
                "Effective Date"
            ),
            this.odata.getFilter(
                "bondAmount",
                ODataFilterDataType.money,
                "Bond Amount"
            ),
            this.odata.getFilter(
                "submissionDateTime",
                ODataFilterDataType.date,
                "Submitted"
            ),
            this.odata.getFilter(
                "Tags/any(t:contains(t/name,'{0}'))",
                ODataFilterDataType.select,
                "Tagged ",
                null,
                null,
                true,
                this.tagOptions,
                true,
                "with "
            )
        ]);
    };

    public saveRecordsPerPage = () => {
        this.widget.options.recordsPerPage = this.table.pager.recordsPerPage;
        this.dashboardService.save();
    };

    public saveOrderby = () => {
        this.widget.options.orderby = this.table.sorter.sortBy;
        this.dashboardService.save();
    };

    public buildQuery = () => {
        const select =
            "status,customerId,nameOnBond,submissionDateTime,desiredEffectiveDate,id,bondAmount";
        const expand =
            "quotes($select=status),customer($expand=systemAccount($select=companyName);$select=id),bondType($select=name;$expand=obligee($select=requisitioningState),sfaaCode($select=code))";
        const filter =
            "status eq 'Submitted' and (applicationType eq 'SingleBond' or bond/carrierSystemAccountId eq " +
            this.currentUserResolver.getCurrentUser().systemAccount.id +
            ")";

        const query = this.odata
            .getQuery()
            .orderby(this.table.sorter.sortBy)
            .skip(
                (this.table.pager.currentPage - 1) *
                    this.table.pager.recordsPerPage
            )
            .top(this.table.pager.recordsPerPage)
            .expand(expand)
            .filter(filter)
            .select(select);

        const filterExpr = this.filterCollection.getFilterExpression();
        if (filterExpr) {
            query.and(filterExpr);
        }

        return query;
    };

    public loadGrid = () => {
        if (this.table.sorter.sortBy !== this.widget.options.orderby) {
            this.saveOrderby();
        }

        if (
            this.table.pager.recordsPerPage !=
            this.widget.options.recordsPerPage
        ) {
            this.saveRecordsPerPage();
        }

        const query = this.buildQuery();

        const dataSvc = this.odata.getService(ODataEndpoint.Application);

        this.busyIndicator.promise = dataSvc
            .get<A3ApiResponse<Application[]>>(query)
            .then((response) => {
                for (let i = 0; i < response.data.value.length; i++) {
                    const app = response.data.value[i];
                    app["approvedQuoteCount"] = app.quotes.filter(
                        (quote) => quote.status == QuoteStatus.Approved
                    ).length;
                    app["declinedQuoteCount"] = app.quotes.filter(
                        (quote) => quote.status == QuoteStatus.Declined
                    ).length;
                    app["pendingQuoteCount"] = app.quotes.filter(
                        (quote) => quote.status == QuoteStatus.Pending
                    ).length;
                }

                this.table.setData(
                    response.data.value,
                    response.data["@odata.count"]
                );
            })
            .catch(() => {
                this.errorMessage =
                    "An error occurred trying to get the widget data";
            });
    };

    public loadSfaaCodes = () => {
        return this.sfaaCodeService.getAllSfaaCodeOptions().then((options) => {
            this.sfaaCodeOptions = options;
        });
    };

    public loadTagOptions = () => {
        return this.tagService.loadTagOptions().then((tagOptions) => {
            this.tagOptions = tagOptions;
        });
    };

    public $onInit = () => {
        this.busyIndicator = {
            message: "Loading..."
        };

        this.sfaaCodeOptions = [];
        this.tagOptions = [];

        this.loadTagOptions();
        this.loadSfaaCodes();
        this.setFilters();

        if (!isDefined(this.widget.options.title)) {
            this.widget.options.title = "Applications";
        }

        if (angular.isDefined(this.widget.options.filters)) {
            this.filterCollection.mergeFilters(this.widget.options.filters);
        }

        if (isDefined(this.widget.options.orderby)) {
            this.table.sorter.sort(this.widget.options.orderby);
        }

        if (isDefined(this.widget.options.recordsPerPage)) {
            this.table.pager.setRecordPerPage({
                value: this.widget.options.recordsPerPage
            });
        }

        this.$timeout(() => {
            this.loadGrid();
        });

        this.table.onChange = this.loadGrid;

        this.dashboardService.refreshFunctions.push(this.loadGrid);
    };
}

const dashboardWidgetOpenApplicationsListCarrierComponent = {
    bindings: {
        widget: "="
    },
    templateUrl:
        "app/states/common/dashboard/widgets/openApplicationCarrier/dashboardWidgetOpenApplicationsListCarrier.html",
    controller: DashboardWidgetOpenApplicationsListCarrierController,
    controllerAs: "vm"
};

app.component(
    "dashboardWidgetOpenApplicationsListCarrier",
    dashboardWidgetOpenApplicationsListCarrierComponent
);
