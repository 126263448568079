import { ITimeoutService } from "angular";
import { Injectables } from "../../../../configuration/injectables";
import app from "../../../../main";
import deepCopy from "../../../../utilities/immutable/deepCopy";
import { DashboardService } from "../dashboardService";
import { DashboardWidget } from "../types/dashboardWidget";
import { WidgetColumnCollection } from "./widgetColumnCollection";

export class DashboardWidgetColumnSelectorController {
    public static $inject = [
        Injectables.DashboardService,
        Injectables.$timeout
    ];

    constructor(
        private readonly dashboardService: DashboardService,
        private readonly $timeout: ITimeoutService
    ) {}

    public isDropdownOpen: boolean;
    public widget: DashboardWidget;
    public widgetColumns: WidgetColumnCollection;
    public columnsChanged: () => void;

    public dropdownToggled = (open: boolean) => {
        if (open) {
            this.widgetColumns = deepCopy(this.widget.options.columns);
        }
    };

    public hideColumnsSelectionDropdown = () => {
        this.isDropdownOpen = false;
    };

    public applyColumnSelection = () => {
        this.widget.options.columns = deepCopy(this.widgetColumns);
        this.isDropdownOpen = false;

        this.dashboardService.save();

        this.$timeout(() => {
            if (this.columnsChanged instanceof Function) {
                this.columnsChanged();
            }
        });
    };

    public $onInit = () => {};
}

const dashboardWidgetColumnSelectorComponent = {
    bindings: {
        widget: "=",
        columnsChanged: "&"
    },
    templateUrl:
        "app/states/common/dashboard/columnSelector/dashboardWidgetColumnSelector.html",
    controller: DashboardWidgetColumnSelectorController,
    controllerAs: "vm"
};

app.component(
    "dashboardWidgetColumnSelector",
    dashboardWidgetColumnSelectorComponent
);
