import { AuthService } from "../../../api/authService";
import { A3RootScope } from "../../../api/types/a3RootScope";
import { CurrentUser } from "../../../api/types/authentication/currentUser";
import { Injectables } from "../../../configuration/injectables";
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";
import { State } from "../../state";
import { CurrentState } from "../../CurrentState";
import { StateChangeListener } from "../../stateChangeListener";
import { JQueryService } from "../../../utilities/jquery/jQueryService";

export class MainController {

    static $inject = [
        Injectables.$rootScope,
        Injectables.AuthService,
        Injectables.CurrentUserResolver,
        Injectables.$state,
        Injectables.StateChangeListener,
        Injectables.JQueryService
    ];

    constructor(
        private readonly $rootScope: A3RootScope,
        private readonly authService: AuthService,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly $state: State,
        private readonly stateChangeListener: StateChangeListener,
        private readonly jQueryService: JQueryService
    ) {}

    public navVisible: boolean;
    public paddingTop: boolean;
    public currentState: CurrentState;
    public currentUser: CurrentUser;

    private setPaddingTop = () => {
        this.paddingTop = this.currentState.name.indexOf('main.') === 0;
    }

    public $onInit = () => {
        this.$rootScope.$on('toggleNav', () => {
            this.navVisible = !this.navVisible;
        });

        this.stateChangeListener.onStateChanged((toState, toParams, prevState, prevStateParams) => {
            this.jQueryService.getElement('html, body, #content > .scroller').scrollTop(0);
            this.currentState = toState;
            this.setPaddingTop();
        });

        // if not going to change password then ensure user is logged in
        if (window.location.hash.indexOf('#/changepassword') === -1) {
            this.authService.confirmLoggedInState();
        }

        this.navVisible = false;
        this.currentUser = this.currentUserResolver.getCurrentUser();
        this.currentState = this.$state.current;

        this.setPaddingTop();
    }
}
