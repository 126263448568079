import { CarrierAppointment } from "./model/carrierAppointment";

export interface Quote {
    $isVisible: boolean;
    $canEdit: boolean;
    $carrierLogoUrl: string;
    $canSendIndemnity: boolean;
    $canPurchase: boolean;
    logo: object;
    requiresAdditionalDocuments: boolean;
    requiresManualReview: boolean;
    requiresCredit: boolean;
    $index: number;
    $applicationIndex: number;
    appointment: CarrierAppointment;
    $tierAdding: boolean;
    $newTierRate: number;
    commissionPercent: number;
    $newThreshold: number;
}