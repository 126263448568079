import app from "../../main";
import { CollectionHeaderFilterable } from "./collectionHeaderFilterable";

export class collectionHeaderFilter  {

    public static $inject = [];

    constructor() {}

    public $onInit = () => {}

    public static Factory(): any {
        return  (object: CollectionHeaderFilterable) => {
            if (!object) {
                return '';
            } 

            if (object.clientNameNumber !== '--') {
                return object.clientNameNumber;
            }

            if (object.creditorClassification !== '--') {
                return object.creditorClassification;
            }
            
            if (object.accountDesignator !== '--') {
                return object.accountDesignator;
            }
            
            return 'Collection';
        };
    }
}

app.filter('collectionHeader',  collectionHeaderFilter.Factory);