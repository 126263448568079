import { ISCEService } from "angular";
import { TransactionType } from "../../api/types/model/bondTransaction";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";

export type TransationFilterable = { transactionType: TransactionType };

export const transactionTypeFilter = ($sce: ISCEService) => {
    return (input: TransationFilterable | string) => {
        if (!input) { return ''; }
        
        let type = '';

        if (input instanceof String) {
            if (input === 'NewBusiness') {
                type = 'New Business';
            } else {
                type = input as string;
            }
        } else {
            if ((input as TransationFilterable).transactionType === 'NewBusiness') {
                type = 'New Business';
            }
            else {
                type = (input as TransationFilterable).transactionType;
            }
        }

        return $sce.trustAsHtml(type);
    };
}

transactionTypeFilter.$inject = [Injectables.$sce];

app.filter('transactionType', transactionTypeFilter);
