import { ApplicationService } from "../../api/applicationService";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { DeclineApplicationOptions } from "./declineApplicationModalOptions";
import { DeclineApplicationResult } from "./DeclineApplicationResult";
import app from "../../main";

export class DeclineApplicationModalController {
    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.ApplicationService
    ];

    constructor(
        private readonly $uibModalInstance: Modal<DeclineApplicationResult>,
        private readonly options: DeclineApplicationOptions,
        private readonly applicationService: ApplicationService
    ){}

    public applicationId: number;
    public comments: string;
    public adverseActionRequired: boolean;

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }

    public declineApplication(): void {
        this.$uibModalInstance.close(
            this.applicationService.declineApplication(
                this.applicationId, 
                this.comments, 
                this.adverseActionRequired
            )
        );
    }

    public $onInit(): void {
        this.applicationId = this.options.applicationId;
    }
}

app.controller('DeclineApplicationModalController', DeclineApplicationModalController);
