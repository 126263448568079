import { BondType } from "./bondType";
import { IBaseEntity } from "./iBaseEntity";
import { SystemAccount } from "./systemAccount";

export interface SystemAccountBondTypeFavorite extends IBaseEntity { 
    systemAccountId: number;
    bondTypeId: number;
    bondType: BondType;
    systemAccount: SystemAccount;
    id: number;
    createdDateTime: Date;
}