import { IBaseEntity } from "./iBaseEntity";
import { MasterApplicationQuestion } from "./masterApplicationQuestion";
import { UnderwritingConfigurationBondFormAttachment } from "./underwritingConfigurationBondFormAttachment";

export interface UnderwritingConfigurationBondFormAttachmentCondition extends IBaseEntity {
    underwritingConfigurationBondFormAttachmentId: number;
    masterApplicationQuestionId: number;
    underwritingConfigurationBondFormAttachment: UnderwritingConfigurationBondFormAttachment;
    masterApplicationQuestion: MasterApplicationQuestion;
}
