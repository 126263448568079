import { IScope } from "angular";
import { Injectables } from "../../../../../configuration/injectables";
import app from "../../../../../main";
import { DashboardService } from "../../dashboardService";
import { Dashboard } from "../../types/dashboard";
import { DashboardWidget } from "../../types/dashboardWidget";

export class ChartWidgetController {

    public static $inject = [
        Injectables.DashboardService,
        Injectables.$scope
    ];

    constructor(
        private readonly dashboardService: DashboardService,
        $scope: IScope
    ) {
        this.widgetIndex = ($scope.$parent as any).widgetIndex;
    }

    public widgetTitle: string;
    public widgetIndex: number;
    public widget: DashboardWidget;

    public get dashboards(): Dashboard[] {
        return this.dashboardService.dashboardConfiguration.dashboards;
    }

    public moveWidget(target: number) {
        this.dashboardService.moveWidgetToTargetDashboard(this.widgetIndex, target);
    }

    public setTitle(title: string) {
        this.widget.options.title = title;
        this.dashboardService.save();
    }

    public $onInit(): void {
        this.widgetTitle = this.widget.options.title;
    }
}

const chartWidgetDirective = () => {
    return {    
        bindToController: {
            removeWidget: '&',
            widget: '=',
            cloneWidget: '&'
        },
        controller: ChartWidgetController,
        controllerAs: 'vm',
        replace: true,
        restrict: 'E',
        transclude: true,
        scope: {},
        templateUrl: 'app/states/common/dashboard/widgetBases/chartWidget/chartWidget.html'
    };
}

app.directive('chartWidget', chartWidgetDirective);