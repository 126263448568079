import { Bond } from "../bond";
import { Quote } from "../quote";
import { Customer } from "./customer";
import { IBaseEntity } from "./iBaseEntity";
import { SystemAccount } from "./systemAccount";

export interface BondAccount extends IBaseEntity { 
    quoteId: number;
    aggregateBondLimit: number;
    singleBondLimit: number;
    quote: Quote;
    bonds: Bond[];
    carrierSystemAccountId: number;
    customerId: number;
    customer: Customer;
    carrierSystemAccount: SystemAccount;
    id: number;
    createdDateTime: Date;
}