import { ApplicationFollowUp } from "./applicationFollowUp";
import { IBaseEntity } from "./iBaseEntity";
import { SystemAccount } from "./systemAccount";

export interface EmailTemplate extends IBaseEntity { 
    systemAccountId: number;
    name: string;
    comments: string;
    htmlBody: string;
    subject: string;
    isDefault: boolean;
    isDefaultBrokerInvite: boolean;
    systemAccount: SystemAccount;
    applicationFollowUps: ApplicationFollowUp[];
    id: number;
    createdDateTime: Date;
    $callToActionText: string;
    $templateHeader: string;
    $includeCallToAction: boolean;
    $templateEditableBody: string;
    $preview: string;
    $templateFooter: string;
    $document: globalThis.Document;
}