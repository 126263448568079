import { Injectables } from "../../../configuration/injectables";
import { State } from "../../state";
import { RateDetailParams } from "./rateDetailParams";

export class RateDetailController {
    public static $inject = [
        Injectables.$state,
        Injectables.$stateParams,
    ];

    constructor(
        private readonly $state: State,
        private readonly $stateParams: RateDetailParams,
    ) { }

    public id: number;

    public saved(): void {
        this.$state.go('main.ratesGrid');
    }

    public canceled(): void {
        this.$state.go('main.ratesGrid');
    }

    public $onInit(): void {
        this.id = this.$stateParams.id;
    }
}
