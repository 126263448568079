import { BondService } from "../../api/bondService";
import { BondTypeService } from "../../api/bondTypeService";
import { CustomersService } from "../../api/customerService";
import { SystemAccountService } from "../../api/systemAccountService";
import { BondAccountService } from "../../api/bondAccountService";
import { BondNumberGroup } from "../../api/types/model/bondNumberGroup";
import { BondAmountTypes } from "../../api/types/model/bondType";
import { IssuingBondObligee, IssuingBond } from "../../api/types/model/issuingBond";
import { MasterApplicationQuestion } from "../../api/types/model/masterApplicationQuestion";
import { SelectOption, OptionGroup } from "../../api/types/selectOption";
import { CommissionDropdownController } from "../../components/commissionDropdown/commissionDropdown";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import app from "../../main";
import { IFilterService, IPromise, IQService } from "angular";
import { IssueBondModalResult } from "./IssueBondModalResult";
import { IssueBondModalOptions } from "./IssueBondModalOptions";
import * as moment from "moment";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { WritingCompany } from "../../api/types/model/writingCompany";
import { RateTier } from "../../api/types/model/rateTier";
import { CommissionType } from "../../api/types/model/commissionType";
import { BondTypeForApplication } from "../../api/types/bondTypeForApplication";
import { Obligee } from "../../api/types/model/obligee";
import { ObligeeService } from "../../api/obligeeService";

export class IssueBondModalController {
    public static $inject = [
        Injectables.Options,
        Injectables.$uibModalInstance,
        Injectables.BondService,
        Injectables.$filter,
        Injectables.$q,
        Injectables.SystemAccountService,
        Injectables.BondTypeService,
        Injectables.CustomersService,
        Injectables.BondAccountService,
        Injectables.ObligeeService,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly options: IssueBondModalOptions,
        private readonly $uibModalInstance: Modal<IssueBondModalResult>,
        private readonly bondService: BondService,
        private readonly $filter: IFilterService,
        private readonly $q: IQService,
        private readonly systemAccountService: SystemAccountService,
        private readonly bondTypeService: BondTypeService,
        private readonly customerService: CustomersService,
        private readonly bondAccountService: BondAccountService,
        private readonly obligeeService: ObligeeService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {}

    public summaryChanged: boolean;
    public busyIndicator: BusyIndicator;
    public bondTypeVariableBondAmounts: SelectOption<number>[];
    public bondType: BondTypeForApplication;
    public bondTypeApplicationItem: BondTypeForApplication;
    public producerUserOptions: SelectOption<number>[];
    public clientServiceManagerUserOptions: SelectOption<number>[];
    public clientServiceAgentUserOptions: SelectOption<number>[];
    public clientServiceExecutiveUserOptions: SelectOption<number>[];
    public writingCompanyOptionGroups: OptionGroup<number>[];
    public obligee: IssuingBondObligee;
    public bond: IssuingBond;
    public bondNumberType: string;
    public bondNumberTypeOptions: SelectOption<string>[];
    public hasFeeSelection: string;
    public hasFeeSelectionOptions: SelectOption<string>[];
    public bondNumberGroups: BondNumberGroup[];
    public carrierSystemAccountId: number;
    public bondTypeQuestions: MasterApplicationQuestion[];
    public isCompanyPrincipalType: boolean;
    public aifOptions: SelectOption<number>[];
    public bondAccountOptions: SelectOption<number>[];
    public commissionDropdownController: CommissionDropdownController;

    public effectiveDateChanged() {
        if (
            this.bond.expirationDate ||
            !this.bond.effectiveDate ||
            this.bond.effectiveDate.toString().indexOf("_") !== -1
        ) {
            return;
        }

        this.bond.expirationDate = moment(this.bond.effectiveDate).add(1, "year").format("MM/DD/YYYY") as any as Date;
    }

    public summaryFieldChanged(): void {
        this.summaryChanged = true;
    }

    public loadCustomerServicingRoleUserIds(): IPromise<void> {
        return this.customerService
            .loadCustomerServicingRoleUserIds(this.bond.customerId)
            .then((servicingRoleUserIds) => {
                this.bond.producerUserId = servicingRoleUserIds.producerUserId;
                this.bond.clientServiceExecutiveUserId = servicingRoleUserIds.clientServiceExecutiveUserId;
                this.bond.clientServiceManagerUserId = servicingRoleUserIds.clientServiceManagerUserId;
                this.bond.clientServiceAgentUserId = servicingRoleUserIds.clientServiceAgentUserId;
            });
    }

    public bondTypeSelected(bondTypeId: number) {
        this.bond.bondTypeId = bondTypeId;
        
        return this.bondTypeService
            .getBondTypeForApplication(bondTypeId)
            .then((bondTypeApplicationItem) => {
                this.bondType  = bondTypeApplicationItem;
                if (this.bondType.bondAmountType == "Fixed") {
                    this.bond.bondAmount = this.bondType.bondAmount;
                }
                if (this.bondType.bondAmountType === BondAmountTypes.Variable) {
                    this.setBondTypeVariableBondAmounts();
                }
                this.busyIndicator.promise = this.$q.all([this.getBondTypeQuestions(), this.loadPrincipal()]);
                this.busyIndicator.promise = this.loadExistingObligee();
        })
    }

// Todo Dan - Load the necessary bond type data for the bond type id
// ctrl+f for this.bondType. indicates the properties that we need to load for the bond type
// you may also need to look at the html file for other references to vm.bondType. to help find additional properties to load

    public getBondTypeQuestions(): IPromise<void> {
        return this.bondTypeService.getBondTypeQuestions(this.bondType.id).then((bondTypeQuestions) => {
            this.bondTypeQuestions = bondTypeQuestions;
        });
    }

    public setBondTypeVariableBondAmounts(): void {
        this.bondTypeVariableBondAmounts = [];

        for (let i = 0; i < this.bondType.bondTypeVariableBondAmounts.length; i++) {
            this.bondTypeVariableBondAmounts.push({
                label: this.$filter("currency")(this.bondType.bondTypeVariableBondAmounts[i].amount, "$", 2),
                value: this.bondType.bondTypeVariableBondAmounts[i].amount
            });
        }
    }

    public loadWritingCompanyOptionGroups(): IPromise<void> {
        return this.systemAccountService.getWritingCompanyOptionGroups().then((optionGroups) => {
            this.writingCompanyOptionGroups = optionGroups;
        });
    }

    public writingCompanyChanged(): void {
        this.carrierSystemAccountId = null;
        for (let i = 0; i < this.writingCompanyOptionGroups.length; i++) {
            for (let j = 0; j < this.writingCompanyOptionGroups[i].options.length; j++) {
                if (this.bond.writingCompanyId == this.writingCompanyOptionGroups[i].options[j].value) {
                    this.carrierSystemAccountId = this.writingCompanyOptionGroups[i].value;
                }
            }
        }

        const promises = [
            this.loadBondNumberGroups(),
            this.loadBondAccounts(),
            this.commissionDropdownController.getCommissionOptions({
                bondTypeId: this.bond.bondTypeId,
                writingCompanyId: this.bond.writingCompanyId,
                carrierSystemAccountId: this.carrierSystemAccountId
            })
        ];

        this.busyIndicator.promise = this.$q.all(promises);
        this.setWritingCompany(this.bond.writingCompanyId) ;
    }

    public setWritingCompany(writingCompanyId: number): IPromise<void> {
        let defaultMinimumPremium :number ;

        return  this.systemAccountService.
            getDefaultMinimumPremiumByWritingCompanyId(writingCompanyId) 
            .then ((response)=> {
                defaultMinimumPremium = response; 
                if (this.bond) {
                    this.bond.minimumPremium = defaultMinimumPremium;
                }
            }) ;
    }
    
    public addNewTierRate(): void {
        // $tierAdding is used as an ugly validation solution
        this.bond['$tierAdding'] = true;

        if (!this.bond.rateTiers) {
            this.bond.rateTiers = [];
        }

        if (!this.bond['$newThreshold'] || !this.bond['$newTierRate']) {
            return;
        }

        // insert in order of threshold
        const newTierIndex = this.getNewTierIndex(this.bond.rateTiers, this.bond['$newThreshold']);

        this.bond.rateTiers.splice(newTierIndex, 0, {
            ratePerThousand: this.bond['$newTierRate'],
            thresholdAmount: this.bond['$newThreshold'],
            rateId: this.bond.id || 0,
            rate: undefined,
            id: undefined,
            createdDateTime: undefined
        });

        this.bond['$newTierRate'] = undefined;
        this.bond['$newThreshold'] = undefined;

        this.bond['$tierAdding'] = false;
    }

    public getNewTierIndex(tiers:  RateTier[], newThreshold: number): number {
        let low = 0,
            high = tiers.length;

        while (low < high) {
            // tslint:disable-next-line:no-bitwise
            const mid = low + high >>> 1;

            if (tiers[mid].thresholdAmount < newThreshold) {
                low = mid + 1;
            }
            else {
                high = mid;
            }
        }
        return low;
    }

    public removeTierRate(index: number): void {
        this.bond.rateTiers.splice(index, 1);
    }

    private loadBondAccounts(): IPromise<void> {
        return this.bondAccountService
            .getBondAccountOptionsByCustomerId(this.options.customerId, this.carrierSystemAccountId)
            .then((bondAccountOptions) => {
                this.bondAccountOptions = bondAccountOptions;

                this.bondAccountOptions.unshift({
                    label: "None",
                    value: null
                });
            });
    }

    public loadBondNumberGroups(): IPromise<void> {
        return this.systemAccountService.getBondNumberGroups(this.carrierSystemAccountId).then((bondNumberGroups) => {
            this.bondNumberGroups = bondNumberGroups;

            // if only one exists then set to first
            if (bondNumberGroups.length === 1) {
                this.bond.bondNumberGroupId = bondNumberGroups[0].id;
            }
        });
    }

    public async loadAttorneyInFactOptions(): Promise<void> {
        this.aifOptions = await this.systemAccountService.getAttorneyInFactOptions();
        this.bond.attorneyInFactUserId = this.systemAccountService.getDefaultAttorneyInFactUserId(this.aifOptions);
    }

    public loadPrincipal() {
        return this.customerService.loadDefaultPrincipal(this.bond.customerId).then((principal) => {
            (this.bond.company = principal.company), (this.bond.people = principal.people);

            if (this.bond.company) {
                this.isCompanyPrincipalType = true;
            }
        });
    }

    public submit(): void {
        
        if (!this.obligee.isGeneric) {
            this.bond.obligee = null;
        } else {
            this.bond.obligee = this.obligee;
        }

        if (!this.isCompanyPrincipalType) {
            this.bond.company = null;
        }

        if (this.bondNumberType === "manual") {
            this.bond.bondNumberGroupId = null;
        }

        if (this.bond.commissionType === CommissionType.Fixed) {
            this.bond.commissionPercent = 11;
        }

        if (
            this.bond.commissionType === CommissionType.Fixed &&
            this.bond.commissionFixedAmount &&
            this.bond.premium
        ) {
            this.bond.commissionPercent = this.bond.commissionFixedAmount / this.bond.premium; // premium;
        }

        if (this.hasFeeSelection === "no") {
            this.bond.fees = [];
        }

        this.busyIndicator.promise = this.bondService
            .issueBond(this.bond)
            .then((bondId) => {
                this.$uibModalInstance.close({ bondId: bondId } as IssueBondModalResult);
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage("An error occurred trying to issue this bond");
            });
    }

    public cancel(): void {
        this.$uibModalInstance.dismiss("cancel");
    }

    private loadExistingObligee(): IPromise<void> {
        return this.obligeeService.getObligeeById(this.bondType.obligeeId)
            /* The above code is defining a TypeScript function that takes an argument of type
            "Obligee" and assigns it to the "obligee" property of the "bond" object. */
            .then((obligee:  Obligee) => {
                this.obligee = obligee;
            });
    }

    // private clearObligee(): IPromise<void> {
    //     this.bond.obligee = undefined;

    //     return this.$q.resolve();
    // } 

    public $onInit() {
        this.bondNumberType = "auto";
        this.bondNumberTypeOptions = [
            { label: "Automatic", value: "auto" },
            { label: "Manual", value: "manual" }
        ];

        this.hasFeeSelection = "no";
        this.hasFeeSelectionOptions = [
            { label: "No Fees", value: "no" },
            { label: "Add Fees", value: "yes" }
        ];

        this.bond = {
            customerId: this.options.customerId,
            people: [],
            company: null,
            questionResponses: [],
            fees: []
        } as IssuingBond;

        const promises = [
            this.loadWritingCompanyOptionGroups(),
            this.loadCustomerServicingRoleUserIds(),
            this.loadAttorneyInFactOptions()
        ];

        this.busyIndicator = {
            message: "Processing...",
            promise: this.$q.all(promises)
        };
    }
}

app.controller("IssueBondModalController", IssueBondModalController);