import { AttachmentService } from "../../api/attachmentService";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { AttachmentModalData } from "./attachmentModalData";
import { AttachmentModalOptions } from "./attachmentModalOptions";
import { AttachmentModalResult } from "./attachmentModalResult";
import app from "../../main";
import { IQService, IDeferred } from "angular";
import { SystemSettings } from "../../configuration/settings/systemSettings";

export class AttachmentModalController {

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.AttachmentService,
        Injectables.$q,
        Injectables.ToastMessageCreator,
        Injectables.SystemSettings

    ];

    constructor(
        private readonly $uibModalInstance: Modal<AttachmentModalResult>,
        private readonly options: AttachmentModalOptions,
        private readonly attachmentService: AttachmentService,
        private readonly $q: IQService,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly systemSettings: SystemSettings
    ){
        this.busyIndicator = {}
    }

    public errorMessage: string;
    public attachment: AttachmentModalData;
    public dropzone: Dropzone;
    public uploadComplete: boolean;
    public busyIndicator: BusyIndicator;
    private uploadDefer:IDeferred<void>;
    

    public dzAddedFile(file: File): void {
        delete this.errorMessage;
        // FIRES BEFORE accept function
        this.attachment.filename = file.name;
        this.attachment.fileSize = file.size;
    }

    public dzError(file, response, xhr): void {
        if (!response.Message) {
            delete this.attachment.filename;
            delete this.attachment.fileSize;
        }
    }

    public dzProcessing(file): void {
        this.dropzone.options.url = this.systemSettings.apiBaseUrl + 'upload/UploadFiles';
    }

    public dzSuccess(file, response): void {
        this.dropzone.removeAllFiles();

        // this gets called once per file but we only
        // need to process once
        if (this.uploadComplete) {
            return;
        }

        this.uploadComplete = true;
        this.attachment.fileId = response;
        this.save();
    }
    
    public dzQueueComplete(response): void {
        this.uploadComplete = false;
    }

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }

    public save_click(): void{
        if (!this.attachment.id && this.dropzone.files.length === 0) {
            this.errorMessage = 'Looks like you forgot to select a file attach';
            return;
        }

        if (this.dropzone.files.length > 0) {
            // upload attachments; after success dropzone will call save
            this.uploadDefer = this.$q.defer();
            this.busyIndicator.promise = this.uploadDefer.promise;
            this.busyIndicator.message = 'Uploading...';
            this.dropzone.processQueue();
        } else {
            this.save();
        }
    }

    public save(): void {
        this.busyIndicator.message = 'Saving...';
        this.busyIndicator.promise = this.attachmentService.save(this.attachment)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('The attachment was successfully uploaded');
                this.$uibModalInstance.close({} as AttachmentModalResult);
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to save the attachment');
            });
    }

    public loadAttachment(attachmentId: number): void {
        this.busyIndicator.message = 'Loading Attachment...';
        this.busyIndicator.promise = this.attachmentService.getAttachmentModalData(attachmentId)
            .then((attachment) => {
                this.attachment = attachment;
            });
    }

    public getDefaultNewAttachment(): AttachmentModalData {
        const defaultAttachment: AttachmentModalData = {
            visibleToAgent: true,
            visibleInEProducer: false,
            applicationId: this.options.applicationId,
            customerId: this.options.customerId,
            eProducerAccountId: this.options.eProducerAccountId,
            bondId: this.options.bondId,
            fileId: null,
            comments: null,
            filename: null,
            fileSize: null
        };

        return defaultAttachment;
    }

    public $onInit(): void {
        
        if (this.options.attachmentId) {
            this.loadAttachment(this.options.attachmentId);
        } else {
            this.attachment = this.getDefaultNewAttachment();
        }

    }
}

app.controller('AttachmentModalController', AttachmentModalController);
