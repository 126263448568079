import { EProducerService } from "../../api/eProducerService";
import { EProducerAccount } from "../../api/types/model/eProducerAccount";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import app from "../../main";
import { BrokerAssignmentModalResult } from "./BrokerAssignmentModalResult";
import { BrokerAssignmentModalOptions } from "./BrokerAssignmentModalOptions";

export class BrokerAssignmentModalController {
    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.EProducerService,
        Injectables.ToastMessageCreator
    ];

    constructor (
        private readonly $uibModalInstance: Modal<BrokerAssignmentModalResult>,
        private readonly modalOptions: BrokerAssignmentModalOptions,
        private readonly eProducerService: EProducerService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {}

    public busyIndicator: BusyIndicator;
    public accountSearchResults: EProducerAccount[];
    public selectedBroker: EProducerAccount;

    public searchEProducerAccounts(searchPhrase: string) {
        this.eProducerService.searchEProducerBrokers(searchPhrase)
            .then((results) => {
                this.accountSearchResults = results;
            });
    } 

    public save() {
        this.busyIndicator.promise = this.eProducerService.assignCustomerToAccount(this.modalOptions.customerId, this.selectedBroker.id)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Customer has been assigned to a broker');
                this.$uibModalInstance.close(new BrokerAssignmentModalResult());
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to assign the customer to this broker');
            });
    }

    public cancel() {
        this.$uibModalInstance.dismiss();
    }

    $onInit() {
        this.busyIndicator = {
            message: 'Assigning to Broker'
        };
    }
}

app.controller('BrokerAssignmentModalController', BrokerAssignmentModalController);