import { IHttpService, IPromise } from "angular";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/settings/systemSettings";
import app from "../main";
import { ODataFactory, ODataService, ODataEndpoint, ODataFilterCollection } from "./odata";
import A3ApiResponse from "./types/a3ApiResponse";
import { CopyUnderwritingConfigurationDto } from "./types/copyUnderwritingConfigurationDto";
import { BondType } from "./types/model/bondType";
import { UnderwritingConfiguration } from "./types/model/underwritingConfiguration";

export class UnderwritingConfigurationService {

    public static $inject = [
        Injectables.$http, 
        Injectables.ODataFactory,
        Injectables.SystemSettings
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly odata: ODataFactory,
        private readonly systemSettings: SystemSettings) {}

    public copyConfiguration(id: number): IPromise<CopyUnderwritingConfigurationDto> {
        return this.$http
            .post<A3ApiResponse<CopyUnderwritingConfigurationDto>>(this.systemSettings.apiBaseUrl + 'UnderwritingConfigurationActions/CopyUnderwritingConfiguration', { id: id })
            .then((response) => response.data.value);
    }

    public getUnderwritingConfigurationDetail(id: number): IPromise<UnderwritingConfiguration> {
        const underwritingSvc: ODataService<UnderwritingConfiguration> = this.odata.getService<UnderwritingConfiguration>(ODataEndpoint.UnderwritingConfiguration);

        const query = this.odata.getQuery();
        query.filter('id eq ' + id);
        query.expand('bondType,' +
                        'bondFormAttachments(' +
                        '$expand=document,conditions(' +
                            '$expand=masterApplicationQuestion(' +
                                '$expand=masterApplicationQuestionChoices' +
                            ')' +
                        ')' +
                        '),' +
                        'underwritingQuestions(' +
                        '$expand=masterApplicationQuestion(' +
                            '$expand=masterApplicationQuestionChoices' +
                        ')' +
                    '),' +
                    'rateOverrides(' +
                        '$orderby=sequence;' +
                        '$expand=conditions(' +
                            '$expand=masterApplicationQuestion(' +
                                '$expand=masterApplicationQuestionChoices' +
                            ')' +
                        ')' +
                    ')');

        return underwritingSvc
                .get(query)
                .then((response) => {
                    return response.data.value[0];
                });
    }

    public getDashboardWidgetUnderwritingConfigurations(
        orderby: string, 
        currentPage: number,
        recordsPerPage: number,
        filterCollection: ODataFilterCollection
    ): IPromise<{data: BondType[], totalRecordCount: number}> {
        let select = 'name,id,isActive'; 
        let expand = 'underwritingConfigurations($select=id),obligee($select=requisitioningState),sfaaCode($select=code)';

        var query = this.odata.getQuery()
            .orderby(orderby)
            .skip((currentPage - 1) * recordsPerPage)
            .top(recordsPerPage)
            .expand(expand)
            .select(select);

        var filterExpr = filterCollection.getFilterExpression();
        if (filterExpr) {
            query.filter(filterExpr);
        }

        var dataSvc = this.odata.getService(ODataEndpoint.BondType);

        return dataSvc.get<A3ApiResponse<BondType[]>>(query)
            .then((response) => {
                return {
                    data: response.data.value,
                    totalRecordCount: response.data['@odata.count']
                };
            });
    }

    public saveUnderwritingConfiguration(config: UnderwritingConfiguration): IPromise<void> {
        const underwritingSvc: ODataService<UnderwritingConfiguration> = this.odata.getService<UnderwritingConfiguration>(ODataEndpoint.UnderwritingConfiguration);

        if (config.id) {
            return underwritingSvc.put(config.id, config).then(() => {});
        } else {
            return underwritingSvc.post(config).then(() => {});
        }
    }
}

app.service(Injectables.UnderwritingConfigurationService, UnderwritingConfigurationService);
