import { IJQueryService } from "../../../utilities/jquery/iJQueryService";
import defaultWrapper from "../defaultWrapper";
import fieldGroupWrapper from "../fieldGroupWrapper";

export default (element: JQuery, jQueryProvider: IJQueryService) => {
    var stateOptions = '<option value="Single">Single</option>' +
        '<option value="Married">Married</option>' +
        '<option value="Separated">Separated</option>' +
        '<option value="Divorced">Divorced</option>';

    element.append(stateOptions);

    var aaFgClass = element.attr('aa-fg-class') ? element.attr('aa-fg-class') + ' select' : 'select';
    element.attr('aa-fg-class', aaFgClass);

    fieldGroupWrapper(element, defaultWrapper(element), jQueryProvider);
};