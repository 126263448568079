export interface SelectOption<T = number> {
    value: T;
    label: string;
    isDisabled?: boolean;
}

export interface OptionGroup<T = number> {
    label: string;
    value: number;
    options: SelectOption<T>[];
}