import app from "../../main";
import { Table } from "../../utilities/tables/table";
import { InvoiceListItem } from "../invoiceList/invoiceListItem";
import { InvoiceTableQueryOptions } from "../invoiceList/invoiceTableQueryOptions";

class InvoiceListFilterDropdownController {
    public static $inject = [];

    constructor() {
        this.modifiedFilters = {} as InvoiceTableQueryOptions;
    }

    public isOpen: boolean;
    public filterCount = 0;
    public modifiedFilters: InvoiceTableQueryOptions;

    public table: Table<InvoiceListItem, InvoiceTableQueryOptions>;

    public clearFilters() {
        this.modifiedFilters.status = null;
        this.modifiedFilters.amountDueMaximum = null;
        this.modifiedFilters.amountDueMinimum = null;

        this.table.applyFilters({ ...this.modifiedFilters });

        this.setFilterCount();
        this.isOpen = false;
    }

    public cancel() {
        this.isOpen = false;
        this.modifiedFilters = { ...this.table.queryOptions };
    }

    public applyFilters() {
        this.isOpen = false;

        this.table.applyFilters({ ...this.modifiedFilters });

        this.setFilterCount();
    }

    public setFilterCount() {
        this.filterCount = 0;

        if (this.table.queryOptions.status) {
            this.filterCount++;
        }

        if (
            this.table.queryOptions.amountDueMinimum ||
            this.table.queryOptions.amountDueMaximum
        ) {
            this.filterCount++;
        }
    }

    public $onInit() {
        this.modifiedFilters = { ...this.table.queryOptions };
        this.setFilterCount();
    }
}

const invoiceListFilterDropdownComponent = {
    bindings: {
        table: "<"
    },
    controller: InvoiceListFilterDropdownController,
    controllerAs: "vm",
    templateUrl:
        "app/components/invoiceListFilterDropdown/invoiceListFilterDropdown.html"
};

app.component("invoiceListFilterDropdown", invoiceListFilterDropdownComponent);
