import { ApplicationFollowUpStrategiesBondTypesConflict } from "./applicationFollowUpStrategiesBondTypesConflict";
import { ApplicationFollowUpStrategiesSfaaCodesConflict } from "./applicationFollowUpStrategiesSfaaCodesConflict";

export interface CheckStrategyAssignmentConflictsResult { 
    hasConflicts: boolean;
    applicationFollowUpStrategiesBondTypes: ApplicationFollowUpStrategiesBondTypesConflict[];
    applicationFollowUpStrategiesSfaaCodes: ApplicationFollowUpStrategiesSfaaCodesConflict[];
}


