import { Injectables } from "../configuration/injectables";
import { ODataFactory, ODataEndpoint } from "./odata";
import { CacheStore as LegacyCacheStore } from "./types/cacheStore";
import { IBaseEntity } from "./types/model/iBaseEntity";
import { SfaaCode } from "./types/model/sfaaCode";
import { SelectOption } from "./types/selectOption";
import { CacheFactory } from "./types/cacheFactory";
import app from "../main";
import { IPromise } from "angular";
import { SystemSettings } from "../configuration/settings/systemSettings";
import { IHttpService } from "angular";
import A3ApiResponse from "./types/a3ApiResponse";
import { CacheStore } from "./cacheStore";

export class SfaaCodeService {
    public static $inject = [
        Injectables.ODataFactory, 
        Injectables.CacheFactory,
        Injectables.SystemSettings,
        Injectables.$http,
        Injectables.CacheStore
    ];

    private readonly legacyCacheStore: LegacyCacheStore;

    constructor(
        private readonly odata: ODataFactory,
        private readonly CacheFactory: CacheFactory,
        private readonly systemSettings: SystemSettings,
        private readonly $http: IHttpService,
        private readonly cacheStore: CacheStore) {
        
        this.legacyCacheStore = this.CacheFactory.get('global');

        if (!this.legacyCacheStore) {
            this.legacyCacheStore = CacheFactory('global', {});
        }
    }

    public getNewAllSfaaCodeOptions(): IPromise<SelectOption[]> {
        const url = this.systemSettings.apiBaseUrl + "SfaaActions/GetAllSfaaCodes";

        return this.cacheStore.getData(url, () => {
            return this.$http
                .get<A3ApiResponse<SfaaCode[]>>(url)
                .then((response) => {
                    const sfaaCodes = response.data.value;
                    const sfaaLocalCodeOptions: SelectOption[] = [];
                    
                    for (const sfaaCode of sfaaCodes) {
                        sfaaLocalCodeOptions.push({ value: sfaaCode.id , label: sfaaCode.code + ' - ' + sfaaCode.description });
                    }

                    return sfaaLocalCodeOptions;
                });
            });
    }

    public getAllSfaaCodeOptions(orderBy?: string): IPromise<SelectOption<string>[]> {
        return this.getAllSfaaCodes(orderBy)
            .then((sfaaCodes) => {

                const sfaaCodeOptions: SelectOption<string>[] = [];
                for (const sfaaCode of sfaaCodes) {
                    sfaaCodeOptions.push({ value: '"' + sfaaCode.id + '"', label: sfaaCode.code + ' - ' + sfaaCode.description });
                }

                return sfaaCodeOptions;
            });
    }

    public getAllSfaaCodes(orderBy = 'code'): IPromise<SfaaCode[]> {
        const svc = this.odata.getService<SfaaCode>(ODataEndpoint.SfaaCode, this.legacyCacheStore);
        svc.query.orderby(orderBy);

        return svc.get().then((response) => response.data.value);
    }

    public getById(id: number): IPromise<SfaaCode> {
        const svc = this.odata.getService<SfaaCode>(ODataEndpoint.SfaaCode);
        svc.query.filter('id eq ' + id);

        return svc.get().then((response) => response.data.value[0]);
    }

    public searchSfaaCodes(search: string): IPromise<SfaaCode[]> {
        return this.getAllSfaaCodes()
            .then((codes) => this.filterSfaaCodes(search, codes));
    }

    public filterSfaaCodes(search: string, sfaaCodes: SfaaCode[]): SfaaCode[] {
        if (!search) {
            return sfaaCodes;
        }

        const filtered: SfaaCode[] = [];
        for (const sfaaCode of sfaaCodes) {
            if (sfaaCode.code.toLowerCase().indexOf(search.toLowerCase()) !== -1 || sfaaCode.description.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
                filtered.push(sfaaCode);
            }
        }

        return filtered;
    }

    public dropdownSearch(search: string): IPromise<IBaseEntity[]> {
        const svc = this.odata.getService(ODataEndpoint.SfaaCode, this.legacyCacheStore);
        svc.query.filter("(contains(code,'" + search + "'))");
        svc.query.orderby('code');
        svc.query.select('code,description,id');
        // it might make more sense to just show all for sfaa
        // query.top(25);

        return svc.get()
            .then((response) => response.data.value);
    }
}

app.service(Injectables.SfaaCodeService, SfaaCodeService);