import { AutoFee } from "./autoFee";
import { IBaseEntity } from "./iBaseEntity";
import { SfaaCode } from "./sfaaCode";

export interface AutoFeeSfaaCode extends IBaseEntity { 
    sfaaCodeId: number;
    autoFeeId: number;
    autoFee: AutoFee;
    sfaaCode: SfaaCode;
    id: number;
    createdDateTime: Date;
}