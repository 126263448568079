import { DocumentVersion } from "../../api/types/documentVersion";
import app from "../../main";

class DocumentVersionListController {

    public static $inject = [];

    constructor() {}
    
    public versions: DocumentVersion[];

    public $onInit = () => {}
}

const documentVersionsListComponent = {
    templateUrl: 'app/components/documentVersionList/documentVersionsList.html',
    bindings: { 
        versions: '<'
    },
    controllerAs: "vm",
    controller: DocumentVersionListController
}

app.component('documentVersionsList', documentVersionsListComponent);
