import { IBaseEntity } from "./iBaseEntity";
import { SystemAccount } from "./systemAccount";


export interface BondNumberGroup extends IBaseEntity { 
    systemAccountId: number;
    name: string;
    nextNumber: number;
    prefix: string;
    suffix: string;
    systemAccount: SystemAccount;
    id: number;
    createdDateTime: Date;
}