import { IParseService, IScope } from "angular";
import { Injectables } from "../../configuration/injectables";
import Sortable from 'sortablejs';
import app from "../../main";

const expando = 'Sortable:ng-sortable';

type SortableScope = IScope & {
    ngSortable?: any;
}

const ngSortableDirective = (
    $parse: IParseService
) => {
    let removed;
    let nextSibling;

    const getNgRepeatExpression = (node) => {
        return node.getAttribute('ng-repeat') || 
                node.getAttribute('data-ng-repeat') || 
                node.getAttribute('x-ng-repeat') || 
                node.getAttribute('dir-paginate');
    }

    const compile = ($element: JQuery) => {

        let ngRepeat = [].filter.call($element[0].childNodes, (node) => {
            return node.nodeType === Node.ELEMENT_NODE && getNgRepeatExpression(node);
        })[0];

        if (!ngRepeat) {
            return;
        }

        var match = getNgRepeatExpression(ngRepeat)
            .match(/^\s*([\s\S]+?)\s+in\s+([\s\S]+?)(?:\s+as\s+([\s\S]+?))?(?:\s+track\s+by\s+([\s\S]+?))?\s*$/);

        if (!match) {
            return;
        }

        var rhs = match[2];

        return (scope: SortableScope, $el: JQuery) => {

            var itemsExpr = $parse(rhs);
            
            var getSource = () => {
                return itemsExpr(scope.$parent) || [];
            };

            let el = $el[0];
            let options = scope.ngSortable || {};
            let watchers = [];
            let offDestroy = null;
            let sortable = null;

            el[expando] = getSource;

            const _emitEvent = (/**Event*/evt, /*Mixed*/item?) => {
                var name = 'on' + evt.type.charAt(0).toUpperCase() + evt.type.substr(1);
                var source = getSource();

                /* jshint expr:true */
                options[name] && options[name]({
                    model: item || source[evt.newIndex],
                    models: source,
                    oldIndex: evt.oldIndex,
                    newIndex: evt.newIndex,
                    originalEvent: evt
                });
            }


            const _sync = (evt: Sortable.SortableEvent) => {
                var items = getSource();

                if (!items) {
                    // Without ng-repeat
                    return;
                }

                if (el !== evt.from) {
                    var prevItems = evt.from[expando]();

                    removed = prevItems[evt.oldIndex];

                    if (evt.clone) {
                        removed = {...removed};
                        prevItems.splice(
                            evt.newDraggableIndex, 
                            0, 
                            prevItems.splice(evt.oldIndex, 1)[0]
                        );
                        evt.from.removeChild(evt.clone);
                    }
                    else {
                        prevItems.splice(evt.oldIndex, 1);
                    }

                    items.splice(evt.newIndex, 0, removed);

                    evt.from.insertBefore(evt.item, nextSibling); // revert element
                }
                else {
                    items.splice(evt.newIndex, 0, items.splice(evt.oldIndex, 1)[0]);

                    // move ng-repeat comment node to right position
                    if (nextSibling.nodeType === Node.COMMENT_NODE) {
                        evt.from.insertBefore(nextSibling, evt.item.nextSibling);
                    }
                }

                scope.$apply();
            }

            const _destroy = () => {
                offDestroy();

                // for(let i = 0; i < watchers.length; i++) {
                //     watchers[i]();
                // }

                sortable.destroy();

                el[expando] = null;
                el = null;
                //watchers = null;
                sortable = null;
                nextSibling = null;
            }


            // Initialization
            sortable = Sortable.create(
                el,
                {
                    ...options,
                    onStart: function (/**Event*/evt) {
                        nextSibling = evt.from === evt.item.parentNode ? evt.item.nextSibling : evt.clone.nextSibling;
                        _emitEvent(evt);
                        scope.$apply();
                    },
                    onEnd: function (/**Event*/evt) {
                        _emitEvent(evt, removed);
                        scope.$apply();
                    },
                    onAdd: function (/**Event*/evt) {
                        _sync(evt);
                        _emitEvent(evt, removed);
                        scope.$apply();
                    },
                    onUpdate: function (/**Event*/evt) {
                        _sync(evt);
                        _emitEvent(evt);
                    },
                    onRemove: function (/**Event*/evt) {
                        _emitEvent(evt, removed);
                    },
                    onSort: function (/**Event*/evt) {
                        _emitEvent(evt);
                    }
                }
            );

            // // Create watchers for `options`
            // const attributeOptions = [
            //     'sort', 'disabled', 'draggable', 'handle', 'animation', 'group', 'ghostClass', 'filter',
            //     'onStart', 'onEnd', 'onAdd', 'onUpdate', 'onRemove', 'onSort', 'onMove', 'onClone', 'setData'
            // ];
            
            // for(let i = 0; i < attributeOptions.length; i++) {
            //     watchers.push(scope.$watch('ngSortable.' + attributeOptions[i], (value) => {
            //         if (value !== void 0) {
            //             options[attributeOptions[i]] = value;

            //             if (!/^on[A-Z]/.test(attributeOptions[i])) {
            //                 sortable.option(attributeOptions[i], value);
            //             }
            //         }
            //     }));
            // }

            offDestroy = scope.$on('$destroy', _destroy);
        }
    }

    return {
        restrict: 'AC',
        scope: { ngSortable: "=?" },
        priority: 1001,
        compile: compile
    };
}

ngSortableDirective.$inject = [Injectables.$parse];

app.directive('ngSortable', ngSortableDirective);