import { ApplicationFollowUpStrategy } from "./applicationFollowUpStrategy";
import { EmailTemplate } from "./emailTemplate";
import { IBaseEntity } from "./iBaseEntity";

    export interface ApplicationFollowUpStrategyEmail extends IBaseEntity { 
        applicationFollowUpStrategyId: number;
        daysAfterSubmission: number;
        timeOfDay: string;
        emailTemplateId: number;
        emailTemplate: EmailTemplate;
        applicationFollowUpStrategy: ApplicationFollowUpStrategy;
        id: number;
        createdDateTime: Date;
    }