import { BondTypeRequest } from "../../api/types/model/bondTypeRequest";
import { SfaaCode } from "../../api/types/model/sfaaCode";
import app from "../../main";
import { IAddress } from "../../utilities/addresses/iAddress";

class BondTypeRequestController {

    public static $inject = [];

    constructor ( ) {}  

    public request: BondTypeRequest;
    public selectedSfaaCode: SfaaCode;

    public sfaaCodeChanged = () => {
        if (this.selectedSfaaCode) {
            this.request.sfaaCodeId = this.selectedSfaaCode.id;
        } else {
            this.request.sfaaCodeId = null;
        }
    }

    public addressChanged = (address: IAddress) => {
        this.request.obligeePhysicalAddress = address.physicalAddress;
        this.request.obligeePhysicalCity = address.physicalCity;
        this.request.obligeePhysicalCounty = address.physicalCounty;
        this.request.obligeePhysicalState = address.physicalState;
        this.request.obligeePhysicalZip = address.physicalZip;
        this.request.obligeePhysicalSuiteAptNumber = address.physicalSuiteAptNumber
        this.request.obligeeMailAddress = address.mailAddress;
        this.request.obligeeMailCity = address.mailCity;
        this.request.obligeeMailCounty = address.mailCounty;
        this.request.obligeeMailState = address.mailState;
        this.request.obligeeMailZip = address.mailZip;
        this.request.obligeeMailSuiteAptNumber = address.mailSuiteAptNumber;
    }

    public $onInit = () => {
        this.request = {} as BondTypeRequest;
    }
}

const bondTypeRequest = {
    templateUrl: 'app/components/bondTypeRequestForm/bondTypeRequest.html',
    bindings: {
        request: '='
    },
    controller: BondTypeRequestController,
    controllerAs: 'vm'
}
                    
app.component('bondTypeRequest', bondTypeRequest);