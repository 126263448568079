import { RateService } from "../../api/rateService";
import { Rate, RateType } from "../../api/types/model/rate";
import { RateTier } from "../../api/types/model/rateTier";
import { SelectOption } from "../../api/types/selectOption";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { BusyIndicator } from "../busyIndicator/busyIndicator";

export type RateFormOnSaveParameter = {
    rate: Rate;
};

class RateFormController {
    public static $inject = [
        Injectables.ToastMessageCreator,
        Injectables.RateService
    ];

    constructor(
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly rateService: RateService
    ) {}

    public id: number;
    public onCancel: () => void;
    public onSave: (parameter: RateFormOnSaveParameter) => void;
    public rate: Rate;
    public busyIndicator: BusyIndicator;
    public rateTypeOptions: SelectOption<RateType>[];
    public addingRateTier: boolean;
    public newThreshold: number;
    public newTierRate: number;

    public cancel = () => {
        if (this.onCancel) {
            this.onCancel();
        }
    };

    public getNewTierIndex = (tiers, newThreshold) => {
        let low = 0;
        let high: number = tiers.length;

        while (low < high) {
            const mid: number = (low + high) >>> 1;
            if (tiers[mid].thresholdAmount < newThreshold) {
                low = mid + 1;
            } else {
                high = mid;
            }
        }

        return low;
    };

    public addNewTierRate = () => {
        this.addingRateTier = true;

        if (!this.rate.rateTiers) this.rate.rateTiers = [];

        if (!this.newThreshold || !this.newTierRate) {
            return;
        }

        // insert in order of threshold
        const newTierIndex = this.getNewTierIndex(
            this.rate.rateTiers,
            this.newThreshold
        );
        this.rate.rateTiers.splice(newTierIndex, 0, {
            ratePerThousand: this.newTierRate,
            thresholdAmount: this.newThreshold
        } as RateTier);

        this.newTierRate = null;
        this.newThreshold = null;
        this.addingRateTier = false;
    };

    public save = () => {
        if (
            this.rate.rateType === RateType.Tiered &&
            this.rate.rateTiers.length <= 0
        ) {
            return;
        }

        this.busyIndicator.message = "Saving...";
        this.busyIndicator.promise = this.rateService
            .saveRate(this.rate)
            .then((rateId) => {
                if (!this.rate.id) {
                    this.rate.id = rateId as number;
                }

                if (this.onSave instanceof Function) {
                    this.onSave({ rate: this.rate });
                }

                this.toastMessageCreator.createSuccessMessage(
                    "Rate configuration saved successfully"
                );
            })
            .catch(function () {
                this.toastMessageCreator.createErrorMessage(
                    "An error occurred trying to save the rate"
                );
            });
    };

    public loadRate = () => {
        this.busyIndicator.promise = this.rateService
            .getRateById(this.id)
            .then((rate) => {
                this.rate = rate;
            });
    };

    public removeTierRate = (index: number) => {
        this.rate.rateTiers.splice(index, 1);
    };

    public $onInit = () => {
        this.busyIndicator = {
            message: "Loading..."
        };

        this.rateTypeOptions = [
            { label: "Rate per Thousand", value: RateType.RatePerThousand },
            { label: "Tiered", value: RateType.Tiered },
            { label: "Fixed", value: RateType.Fixed }
        ];

        if (this.id) {
            this.loadRate();
        } else {
            this.rate = {
                rateType: RateType.RatePerThousand,
                rateTiers: []
            } as Rate;
        }
    };
}

const rateFormComponent = {
    bindings: {
        id: "=",
        onSave: "&",
        onCancel: "&"
    },
    controller: RateFormController,
    controllerAs: "vm",
    templateUrl: "app/components/rateForm/rateForm.html"
};

app.component("rateForm", rateFormComponent);
