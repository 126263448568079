import app from "../../main";

export class UibToolTipConfig {
    constructor(
        $uibTooltipProvider: any
    ) {
        $uibTooltipProvider.options({
            popupDelay: 250
        });
    }
}

// app.config(['$uibTooltipProvider', ($uibTooltipProvider) => {
//         return new UibToolTipConfig($uibTooltipProvider);
// }]);
