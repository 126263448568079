import { Application } from "./application";
import { BondType } from "./bondType";
import { IBaseEntity } from "./iBaseEntity";

export interface AccountRequestBondType extends IBaseEntity { 
    applicationId: number;
    bondTypeId: number;
    bondAmount: number;
    application: Application;
    bondType: BondType;
    id: number;
    createdDateTime: Date;
}