import { IBaseEntity } from "./iBaseEntity";
import { Rate } from "./rate";

export interface RateTier extends IBaseEntity { 
    rateId: number;
    thresholdAmount: number;
    ratePerThousand: number;
    rate: Rate;
    id: number;
    createdDateTime: Date;
}