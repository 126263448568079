export type IAddress = {
    physicalAddress: string;
    physicalSuiteAptNumber: string;
    physicalCity: string;
    physicalState: string;
    physicalZip: string;
    physicalCounty: string;

    mailAddress: string;
    mailSuiteAptNumber: string;
    mailCity: string;
    mailState: string;
    mailZip: string;
    mailCounty: string;
}