import { IPromise } from "angular";
import { FollowUpService } from "../../api/followUpService";
import { ApplicationFollowUp } from "../../api/types/model/applicationFollowUp";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { ModalOpener } from "../../modals/modalOpener";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { BusyIndicator } from "../busyIndicator/busyIndicator";

class FollowUpListController {

    public static $inject = [
        Injectables.FollowUpService, 
        Injectables.ModalOpener,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly followUpService: FollowUpService,
        private readonly modalOpener: ModalOpener,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {}

    public leadId: number;
    public applicationId: number;
    public followUps: ApplicationFollowUp[];
    public busyIndicator: BusyIndicator;

    public get missingId(): boolean {
        return !this.leadId && !this.applicationId;
    }

    public addFollowUp(): void {
        if (this.missingId) {
            return;
        }

        this.modalOpener.showFollowUpModal({ leadId: this.leadId, applicationId: this.applicationId} as ApplicationFollowUp)
            .result
            .then((followUp: ApplicationFollowUp) => {
                this.followUps.push(followUp);
            })
            .catch(() => {});
    }

    public send(followUp: ApplicationFollowUp, $index: number): void {
        this.busyIndicator.message = 'Sending...';
        this.busyIndicator.promise = this.followUpService.send(followUp)
            .then((updatedFollowUp) => {
                this.followUps[$index] = updatedFollowUp;
                this.toastMessageCreator.createSuccessMessage('Follow up was sent successfully');
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to send this follow up message');
            });
    }

    public editFollowUp(followUp: ApplicationFollowUp, $index: number): void {
        this.modalOpener.showFollowUpModal({...followUp})
            .result
            .then((updatedFollowUp: ApplicationFollowUp) => {
                this.followUps[$index] = updatedFollowUp;
            })
            .catch(() => {});
    }

    public unsubscribeFollowUp(followUp: ApplicationFollowUp, $index: number): void {
        this.busyIndicator.message = 'Unsubscribing...';
        this.busyIndicator.promise = this.followUpService.unsubscribe(followUp)
            .then((updatedFollowUp: ApplicationFollowUp) => {
                this.followUps[$index] = updatedFollowUp;
                this.toastMessageCreator.createSuccessMessage('This the recipient has been unsubscribed from this message');
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to unsubscribe');
            });
    }

    public copyFollowUp(followUp: ApplicationFollowUp, $index: number): void {
        this.busyIndicator.message = 'Copying...';
        this.busyIndicator.promise = this.followUpService.copyFollowUp(followUp)
            .then((newFollowUp: ApplicationFollowUp) => {
                this.followUps.splice($index + 1, 0, newFollowUp);
                this.toastMessageCreator.createSuccessMessage('Follow up was copied successfully');
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to copy this follow up message');
            });
    }

    public subscribeFollowUp(followUp, $index): void {
        this.busyIndicator.message = 'Subscribing...';
        this.busyIndicator.promise = this.followUpService.subscribe(followUp)
            .then((updatedFollowUp: ApplicationFollowUp) => {
                this.followUps[$index] = updatedFollowUp;
                this.toastMessageCreator.createSuccessMessage('This the recipient is now subscribed to this message');
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to subscribe');
            });
    }

    public $onInit(): void {
        this.busyIndicator = {}
    }
}

const followUpListDirective = () => ({
    restrict: 'E',
    templateUrl: 'app/components/followUpList/followUpList.html',
    scope: {},
    transclude: true,
    bindToController: { 
        followUps: '=', 
        leadId: '=', 
        applicationId: '=' 
    },
    controller: FollowUpListController,
    controllerAs: 'vm'
});

app.directive('followUpList', followUpListDirective);
