export interface GetRiderTransactionReportDto   { 
    transactionId: number;
    bondNumber: string;
    changeType: string;
    originalValue: string;
    newValue: string;
    requestedBy: string;
    agencyComments: string;
    carrierComments: string;
    changeAsText: string;
    writingCompany: string;
    requisitioningState: string;
    bondType: string;
    effectiveDate: string;
    expirationDate: string;
    transactionDate: string;
    agency: string;
    agencyCode: string;
    principalName: string;
    physicalAddress: string;
    physicalSuiteAptNumber: string;
    physicalCity: string;
    physicalState: string;
    physicalZip: string;
    mailAddress: string;
    mailSuiteAptNumber: string;
    mailCity: string;
    mailState: string;
    mailZip: string;
    obligeeName: string;
    obligeeAddress: string;
    obligeeSuiteAptNumber: string;
    obligeeCity: string;
    obligeeState: string;
    obligeeZip: string;
    sfaaCode: string;
    grossPremium: string;
    bondAmount: string;
    commission: string;
    commissionPercent: string;
}