import { ApplicationType } from "../../api/types/model/application";
import app from "../../main";
import { ApplicationTypeFilterable } from "./applicationTypeFilterable";

export class applicationTypeFilter  {

    public static $inject = [];

    constructor() {}

    public $onInit = () => {}

    public static Factory = () => {

        return  (object: ApplicationTypeFilterable) => {
            if (!object) {
                return '';
            }

            var type = '';
            
            if (object.applicationType === ApplicationType.SingleBond) {
                type = 'New Business';
            }

            if (object.applicationType === ApplicationType.BondAccount) {
                type = 'Bond Account';
            }

            if (object.applicationType === ApplicationType.SingleBondRenewal) {
                type = 'Renewal';
            }

            return type;
        }
    };   
}

app.filter('applicationType', applicationTypeFilter.Factory);