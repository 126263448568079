import { ODataFilter } from "../../api/odata";
import app from "../../main";

export const filterStringFilter = () => {
    return (filter: ODataFilter) => {
        let filterString = filter.toString();

        if (!filterString) {
            return "";
        }

        // remove enums that like
        // A3.Core.Common.SystemType'EProducer'
        if (filterString.indexOf("A3.Model.") === -1 && filterString.indexOf("A3.Core.") === -1) {
            return filterString;
        }

        let startIndex = filterString.indexOf("A3.Model.");

        if (startIndex === -1) {
            startIndex = filterString.indexOf("A3.Core.");
        }

        const endIndex = filterString.indexOf("'");
        const enumString = filterString.substring(startIndex, endIndex);

        filterString = filterString
            .replace(enumString, "") // remove the Enum namespace
            .replace(/'/g, "") // remove apostrophes
            .replace(/([a-z])([A-Z])/g, "$1 $2"); // add spaces before upper case that are preceeded by lowercase

        return filterString;
    };
};

app.filter("filterString", filterStringFilter);
