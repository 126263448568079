import { Injectables } from "../../../configuration/injectables";
import app from "../../../main";
import { BondLineReportItem } from "../../../states/agentSpecific/bondLines/bondLineReportItem";
import { IDebounceDelayer } from "../../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../../utilities/tables/table";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { PaymentReportFilterOptions } from "../../../components/paymentsReportFilterDropdown/paymentReportFilterOptions";
import { BondAccountService } from "../../../api/bondAccountService";
import { ModalOpener } from "../../../modals/modalOpener";
import { BondLineReportFilterOptions } from "./bondLinesReportFilterOptions";
import { BondAccountStatus } from "../../../api/types/bondAccounts/bondAccountStatus";

export class BondLinesReportController {
    
    public static $inject = [
        Injectables.BondAccountService,
        Injectables.IDebouceDelayer,
        Injectables.ModalOpener
    ];

    constructor(
        private readonly bondAccountService: BondAccountService,
        debouceDelayer: IDebounceDelayer,
        private readonly modalOpener: ModalOpener
    ) {
        this.table = new Table(debouceDelayer);
        this.table.queryOptions.status = BondAccountStatus.Active;
    }

    public readonly table: Table<BondLineReportItem, BondLineReportFilterOptions>;
    public readonly filters: PaymentReportFilterOptions;

    public readonly onTableChanged: (param: {
        table: Table<BondLineReportItem, BondLineReportFilterOptions>;
    }) => void;
    
    public readonly onTableReady: (param: {
        table: Table<BondLineReportItem, BondLineReportFilterOptions>;
    }) => void;

    public busyIndicator: BusyIndicator;

    public showBondAccountModal(bond: BondLineReportItem) {
        this.modalOpener
            .showBondAccountModal(bond.customerId, bond.id)
            .result
            .then(() => {
                this.refresh();
            })
            .catch(() => {});
    }

    public exportReport = () => {
        this.busyIndicator.message = "Loading for export...";
        this.busyIndicator.promise = this.bondAccountService
            .downloadBondLinesReport(this.table.queryOptions)
            .then(() => {});
    };

    public exportBondLinesExcelReport = () => {
        this.busyIndicator = {
            message: "Loading...",
            promise: this.bondAccountService.getBondLineExcelReport(
                this.table.queryOptions
            )
        };
    };

    public refresh = () => {
        this.loadData();
    };

    public loadData = () => {
        this.busyIndicator.message = "Loading Bond Lines...";
        this.busyIndicator.promise = this.bondAccountService
            .getBondLineReportData(this.table.queryOptions)
            .then((response) => {
                this.table.setData(response.items, response.totalRecordCount);
            });
    };

    public $onInit(): void {
        this.busyIndicator = {};

        if (this.filters) {
            this.table.applyFilters(this.filters);
        }

        this.loadData();
        
        this.table.onChange = () => {
            this.loadData();

            if (this.onTableChanged instanceof Function) {
                this.onTableChanged({ table: this.table });
            }
        };

        if (this.onTableReady instanceof Function) {
            this.onTableReady({ table: this.table });
        }
    }
}

const bondLinesReportComponent = {
    bindings: {
        filters: "=?",
        onTableChanged: "&?",
        onTableReady: "&?"
    },
    controller: BondLinesReportController,
    templateUrl: "app/states/agentspecific/bondLines/bondLinesReport.html",
    controllerAs: "vm"
};

app.component("bondLinesReport", bondLinesReportComponent);
