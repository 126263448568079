import { IAttributes, IScope } from "angular";
import app from "../../main";

type BoundColumnScope = IScope & {
    ngShow?: boolean | string;
    enableSorting?: boolean | string;
    enableAggregateFilter?: boolean | string;
    sortOn?: string;
    filterExpression?: string;
    enableInlineEdit?: boolean;
    cssClass?: string;
    headerCssClass?: string;
    headerText?: string;
    dataitem?: any
}

const boundColumn = () => {

    const link = (
        scope: BoundColumnScope,
        element: JQuery,
        attrs: IAttributes,
        parentController: any) => {
            
            scope.ngShow = scope.ngShow != undefined ? scope.ngShow : true;
            scope.enableSorting = scope.enableSorting === 'false' ? false : scope.enableSorting !== false;
            scope.enableAggregateFilter = scope.enableAggregateFilter === 'true' || scope.enableAggregateFilter === true;
            scope.sortOn = scope.sortOn ? scope.sortOn : scope.dataitem;
            scope.filterExpression = scope.filterExpression ? scope.filterExpression : scope.dataitem;
            scope.enableInlineEdit = scope.enableInlineEdit ? scope.enableInlineEdit : false;

            (scope.$parent.$parent as any).addColumn({
                columnType: 'boundColumn',
                sortOn: scope.sortOn,
                filterExpression: scope.filterExpression,
                enableInlineEdit: scope.enableInlineEdit,
                enableAggregateFilter: scope.enableAggregateFilter,
                cssClass: scope.cssClass,
                headerCssClass: scope.headerCssClass,
                headerText: scope.headerText,
                dataitem: scope.dataitem,
                enableSorting: scope.enableSorting,
                show: scope.ngShow
            });
        }

    return {
        restrict: 'E',
        replace: true,
        scope: {
            headerText: '@',
            dataitem: '@',
            enableSorting: '@',
            enableAggregateFilter: '@',
            sortOn: '@',
            filterExpression: '@',
            enableInlineEdit: '@',
            cssClass: '@',
            headerCssClass: '@',
            ngShow: '=?'
        },
        require: '^boundGrid',
        link: link,
        template: '<div></div>'

    };
}

app.directive('boundColumn', boundColumn);
