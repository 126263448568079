import { CustomerSources } from "../../api/types/customerSources";
import { Customer } from "../../api/types/model/customer";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { CustomerLookupActions } from "./CustomerLookupActions";
import { CustomerLookupResult } from "./CustomerLookupResult";
import app from "../../main";

export class CustomerLookupModalController {
    
    public static $inject = [
        Injectables.$uibModalInstance
    ];

    constructor(
        private readonly $uibModalInstance: Modal<CustomerLookupResult>
    ) {}

    public customer:  Customer;
    public showAms360ContactSelection: boolean;
    public showLookupForm: boolean;
    public source: CustomerSources;

    public cancel(): void {
        this.$uibModalInstance.dismiss();
    }

    public selectCustomer(customer:  Customer, source: CustomerSources): void {
        this.$uibModalInstance.close(new CustomerLookupResult(customer, source, CustomerLookupActions.Selected));
    }

    public goBack(): void {
        this.showAms360ContactSelection = false;
        this.showLookupForm = true;
    }

    public $onInit(): void {
        this.showLookupForm = true;
        this.showAms360ContactSelection = false;
    }
}

app.controller('CustomerLookupModalController', CustomerLookupModalController);
