import { ISCEService, ITimeoutService, IPromise } from "angular";
import { EmailTemplateService } from "../../../api/emailTemplateService";
import { EmailTemplate } from "../../../api/types/model/emailTemplate";
import { Injectables } from "../../../configuration/injectables";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { State } from "../../state";
import { EmailTemplateDetailParams } from "./emailTemplateDetailParams";

export class EmailTemplatesDetailController {
    public static $inject = [
        Injectables.$stateParams,
        Injectables.EmailTemplateService,
        Injectables.$state,
        Injectables.$sce,
        Injectables.$timeout,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly $stateParams: EmailTemplateDetailParams,
        private readonly emailTemplateService: EmailTemplateService,
        private readonly $state: State,
        private readonly $sce: ISCEService,
        private readonly $timeout: ITimeoutService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {}

    public template: EmailTemplate;
    public processingPromiseMessage: string;
    public processingPromise: Promise<any>;
    public previewBuilderTimeout: IPromise<any>;

    public setPreview() {
        if (this.previewBuilderTimeout) {
            this.$timeout.cancel(this.previewBuilderTimeout);
        }

        if (!this.template.$callToActionText) {
            this.template.$callToActionText = 'Click Here';
        }

        this.previewBuilderTimeout = this.$timeout(() => this.buildPreview(), 750);
    }

    public buildPreview() {
        this.template.$preview = this.$sce.trustAsHtml(
                this.template.$templateHeader +
                this.emailTemplateService.populateEmailDefaultPlaceholders(this.template.$templateEditableBody) +
                (this.template.$includeCallToAction ? this.emailTemplateService.getDefaultCallToActionForPreview(this.template.$callToActionText) : '') +
                this.template.$templateFooter);
    }

    public async save() {
        this.processingPromiseMessage = 'Saving...';
        this.processingPromise = this.saveTemplate();
    }

    private async saveTemplate()
    {
        try
        {
            await this.emailTemplateService.save(this.template);

            this.$state.go('main.emailTemplates');
            this.toastMessageCreator.createSuccessMessage('The email template has been saved');
        } catch (err) {
            this.toastMessageCreator.createErrorMessage('An error occurred trying to save the email template');
        }
    }

    public async getById(id: number) {
        this.template = await this.emailTemplateService.getById(id) as EmailTemplate;
        this.emailTemplateService.parseHtmlBodyToVmParts(this.template);
        this.buildPreview();
    }

    public $onInit() {
        if (this.$stateParams.id && this.$stateParams.id > 0)
            this.processingPromise = this.getById(this.$stateParams.id);
        else {
            this.template = {
                $templateHeader: this.emailTemplateService.getDefaultHeaderForPreview(),
                $templateEditableBody: '',
                $templateFooter: this.emailTemplateService.getDefaultFooterForPreview()
            } as EmailTemplate;

            this.setPreview();
        }
    }
}