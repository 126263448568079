import { IFilterService, IHttpService } from "angular";
import { ODataEndpoint, ODataFilter, ODataFilterCollection, ODataQuery, ODataService } from ".";
import { Injectables } from "../../configuration/injectables";
import { SystemSettings } from "../../configuration/settings/systemSettings";
import app from "../../main";
import { CacheStore } from "../types/cacheStore";
import { IBaseEntity } from "../types/model/iBaseEntity";
import { SelectOption } from "../types/selectOption";

export default class ODataFactory {
    public static $inject = [
        Injectables.$http,
        Injectables.SystemSettings, 
        Injectables.$filter
    ];

    constructor(
        public readonly $http: IHttpService,
        private readonly systemSettings: SystemSettings,
        private readonly $filter: IFilterService) { }

    public getService<T extends IBaseEntity>(endpoint: ODataEndpoint, cacheStore?: CacheStore): ODataService<T> {
        return new ODataService<T>(endpoint, cacheStore, this.$http, this.systemSettings);
    }

    public getQuery(): ODataQuery {
        return new ODataQuery();
    }

    public getFilter(
        navigationExpression: string,
        dataType: string,
        label: string,
        value?: string,
        maxValue?: string,
        isVisible: boolean = true,
        selectOptions?: SelectOption<any>[],
        isString?: boolean,
        comparisonText?: string): ODataFilter {
        
        return new ODataFilter(
            navigationExpression,
            dataType,
            label,
            value,
            maxValue,
            isVisible,
            selectOptions,
            isString,
            comparisonText,
            this.$filter);
    }

    public getFilterCollection(filters: ODataFilter[]): ODataFilterCollection {
        return new ODataFilterCollection(filters, this.$filter);
    }
}

app.service(Injectables.ODataFactory, ODataFactory);