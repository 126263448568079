import { IQService, IWindowService, IPromise } from "angular";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/settings/systemSettings";
import app from "../main";
import { BrowserNotificationService } from "./browserNotificationService";

export class SignalRService {

    public static $inject = [
        Injectables.BrowserNotificationService,
        Injectables.$q,
        Injectables.$window,
        Injectables.SystemSettings
    ];

    constructor(
        private readonly browserNotificationService: BrowserNotificationService,
        readonly $q: IQService,
        private readonly $window: IWindowService,
        private readonly systemSettings: SystemSettings){  
    }

    get connected(): boolean {
        const connected = this.$window.localStorage.getItem('signalRConnected');
        return connected && connected === 'true';
    }

    set connected(value: boolean) {
        this.$window.localStorage.setItem('signalRConnected', value ? 'true' : 'false');
    }

    public initialized: boolean = false;
    public subscribed: boolean = false;
    public proxy: any;

    public subscribe(id: number): IPromise<void> {
        try {
            return this.initialize()
                .then(() => {
                    this.proxy.server
                        .subscribe(id)
                        .then(() => this.subscribed = true);
                });
        } catch (e) {
            return this.$q.reject(e);
        }
    }

    public unsubscribe(id: number): IPromise<void> {
        try {
            return this.initialize()
                .then(() => {
                    this.proxy.server.unsubscribe(id);

                    // console.log('unsubscribed from signalR');
                    this.connected = false;
                });
        } catch (e) {
            return this.$q.reject(e);
        }
    }

    public initialize(): IPromise<void> {
        if (this.initialized) { return this.$q.when(); }

        try {

            // Set the url manually
            $.connection.hub.url = this.systemSettings.odataBaseUrl + 'signalr/';

            // Creating proxy
            this.proxy = $.connection['a3Hub'];

            // $.connection.hub.transportConnectTimeout = 10000;

            // listeners
            $.connection['a3Hub'].client.BrowserNotification = this.browserNotificationService.handleBrowserNotification;

            // logging
            // $.connection.a3Hub.logging = true;

            // error handler
            $.connection.hub.error((error) => {
                this.initialized = false;
                // tslint:disable-next-line:no-console
                // console.log(`SignalrAdapter: ${error}`);
                this.connected = false;
                return () => {};
            });

            $.connection.hub.disconnected(() => {
                // console.log('disconnected from signalR');
                this.connected = false;
            });

            $.connection.hub.reconnected(() => {
                // console.log('reconnected to signalR');
                this.connected = true;
            });

            // Starting connection
            const jQueryPromise = $.connection.hub
                .start()
                .done(() => {

                    this.initialized = true;
                    // console.log('initializing connection to signalR');
                    this.connected = true;

                    return () => {};
                })
                .fail((err) => {

                    // tslint:disable-next-line:no-console
                    console.log(err);
                    return () => {};
                });

            return this.$q.when(jQueryPromise).then(() => {});

        } catch (e) {
            const deferred = this.$q.defer();
            deferred.reject();
            
            return deferred.promise
                .then(() => { });
        }
    }
}

app.service(Injectables.SignalRService, SignalRService);
