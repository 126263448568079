import { InvoiceStatus } from "../../api/types/model/invoiceStatus";
import { InvoiceTableLineItem } from "./invoiceListLineItem";

export interface InvoiceListItem {
    id: number;
    totalAmountDue: number;
    totalAmountReceived: number;
    originalAmount: number;
    invoiceNumber: number;
    comments: string;
    createdDateTime: Date;
    customerId?: number;
    eProducerAccountId?: number;
    status: InvoiceStatus;
    lineItems: InvoiceTableLineItem[];
}
