import { IBaseEntity } from "./iBaseEntity";
import { SupportTicketMessage } from "./supportTicketMessage";
import { User } from "./user";

export interface SupportTicket extends IBaseEntity { 
    createdDateTime: Date;
    category: string;
    status: string;
    assignedTo: string;
    title: string;
    initialMessage: string;
    stateReportedFrom: string;
    urgency: string;
    user: User;
    messages: SupportTicketMessage[];
    notificationCount: number;
    workItemId: number;
    ticketNumber: string;
    environment: string;
}