import { Attachment } from "./attachment";
import { Bond } from "./bond";
import { Quote } from "./quote";
import { Tag } from "./tag";
import { AccountRequestBondType } from "./accountRequestBondType";
import { ApplicationDocumentVersion } from "./applicationDocmentVersion";
import { ApplicationFollowUp } from "./applicationFollowUp";
import { ApplicationQuestionResponse } from "./applicationQuestionResponse";
import { BondType } from "./bondType";
import { Company } from "./company";
import { Customer } from "./customer";
import { DeclinedApplication } from "./declinedApplication";
import { EProducerAccount } from "./eProducerAccount";
import { IBaseEntity } from "./iBaseEntity";
import { Memo } from "./memo";
import { Obligee } from "./obligee";
import { Person } from "./person";
import { User } from "./user";

export interface Application extends IBaseEntity { 
    status: ApplicationStatus;
    customerId: number;
    nameOnBond: string;
    submissionSignature: string;
    submissionDateTime: Date;
    submissionIpAddress: string;
    customer: Customer;
    comments: string;
    applicationType: ApplicationType;
    requestedSingleBondLimit: number;
    requestedAggregateLimit: number;
    bondTypeId: number;
    bondAmount: number;
    desiredEffectiveDate: Date;
    submittedByUser: User;
    bondId: number;
    bond: Bond;
    submittedByUserId: number;
    ams360CustomerId: string;
    ams360CustomerNumber: number;
    tags: Tag[];
    obligeeId: number;
    attorneyInFactUserId: number;
    attorneyInFact: string;
    attorneyInFactEmail: string;
    attorneyInFactPhone: string;
    attorneyInFactAddress: string;
    attorneyInFactSuiteAptNumber: string;
    attorneyInFactCity: string;
    attorneyInFactState: string;
    attorneyInFactZip: string;
    attorneyInFactSignatureImageFileId: number;
    attorneyInFactSignatureFile: File;
    eProducerAccountId: number;
    originSystem: SystemType;
    eProducerAccount: EProducerAccount;
    attachments: Attachment[];
    applicationQuestionResponses: ApplicationQuestionResponse[];
    companies: Company[];
    people: Person[];
    accountRequestBondTypes: AccountRequestBondType[];
    applicationDocumentVersions: ApplicationDocumentVersion[];
    quotes: Quote[];
    memos: Memo[];
    applicationFollowUps: ApplicationFollowUp[];
    declinedApplications: DeclinedApplication[];
    principalType: PrincipalType;
    id: number;
    createdDateTime: Date;
 }

export enum PrincipalType {	
    Individual = "Individual",
    Company = "Company"
}
export enum ApplicationType {	
    BondAccount = "BondAccount",
    SingleBond = "SingleBond",
    SingleBondRenewal = "SingleBondRenewal"
}
export enum ApplicationStatus {	
    NotSubmitted = "NotSubmitted",
    Submitted = "Submitted",
    Closed = "Closed",
    Completed = "Completed"
}
export enum SystemType {	
    Unknown = "Unknown",
    EProducer = "EProducer",
    A3Dashboard = "A3Dashboard"
}