export interface UserForDropdown {
    id: number;
    fullName: string;
    isLockedOut: boolean;
    allowUnselected: boolean;
    isProducer: boolean;
    isClientServiceManager: boolean;
    isClientServiceAgent: boolean;
    isClientServiceExecutive: boolean;
    isAttorneyInFact: boolean;
    
}