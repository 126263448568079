import { IHttpService, IPromise } from "angular";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/settings/systemSettings";
import app from "../main";
import A3ApiResponse from "./types/a3ApiResponse";
import { RiderRequestForApproval } from "./types/bonds/riderRequestForApproval";
import { RiderRequestForCreating } from "./types/bonds/riderRequestForCreating";
import { BondChange } from "./types/model/bondChange";
import { Fee } from "./types/model/fee";
import { RescindRiderRequestDto } from "./types/rescindRiderRequestDto";
import { RiderRequestDeclination } from "./types/riderRequestDeclination";
import { RiderRequestForEditing } from "./types/riderRequestForEditing";
import { RiderRequestSubmission } from "./types/riderRequestSubmission"
import { PageResponse } from "./types/pageResponse";
import { RiderReportListItem } from "../states/common/riders/riderReportItem";
import { RiderReportTableQueryOptions } from "../states/common/riders/riderReportTableQueryOptions";
import { FileDownloader } from "./fileDownloader";

export class RiderRequestService {
            
    public static $inject = [
        Injectables.$http,
        Injectables.SystemSettings,
        Injectables.FileDownloader
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly systemSettings: SystemSettings,
        private readonly fileDownloader: FileDownloader){  
    }

    private readonly baseUrl = this.systemSettings.apiBaseUrl + 'RiderRequestActions/';

    public async getRiderChangeDescriptions(changes: BondChange[]): Promise<BondChange[]> {
        const response = await this.$http.post<A3ApiResponse<BondChange[]>>(this.baseUrl + 'GetChangeDescriptions', changes)
        return response.data.value;
    }

    public async createRider(request: RiderRequestForCreating): Promise<void> {
        await this.$http.post(this.baseUrl + 'CreateRider', request);
    }

    public async approveRiderByCarrier(request: RiderRequestForApproval): Promise<void> {
        await this.$http.post(this.baseUrl + 'ApproveRider', request);
    }

    public async declineRiderByCarrier(riderRequestId: number, carrierComments: string): Promise<void> {
        const request:  RiderRequestDeclination = {
            CarrierComments: carrierComments,
            RiderRequestId: riderRequestId,
        };

        await this.$http.post(this.baseUrl + 'DeclineRider', request);
    }

    public async getDefaultRiderFees(bondId: number): Promise<Fee[]> {
        const response = await this.$http.get<A3ApiResponse<Fee[]>>(this.baseUrl + 'GetDefaultRiderFees?bondId=' + bondId);
        return response.data.value;
    }

    public async getRiderRequestForEditing(bondId: number): Promise<RiderRequestForEditing> {
        const response = await this.$http.get<A3ApiResponse<RiderRequestForEditing>>(this.baseUrl + 'GetRiderRequestForEditing?bondId=' + bondId);
        return response.data.value;
    }

    public async rescindRiderRequest(requestId: number): Promise<void> {
        const request: RescindRiderRequestDto = {
            id: requestId
        };

        await this.$http.post(this.baseUrl + 'RescindRiderRequest', request);
    }

    public async requestRider(request: RiderRequestSubmission): Promise<void> {
        await this.$http.post(this.baseUrl + 'RequestRider', request);
    }

    public async updateRiderRequest(request: RiderRequestForEditing): Promise<void> {
        await this.$http.post(this.baseUrl + 'UpdateRiderRequest', request);
    }

    public getRiderReportExcelReport(tableQueryOptions: RiderReportTableQueryOptions) : IPromise<PageResponse<RiderReportListItem>> {
        const queryString = this. buildRiderReportQueryString(tableQueryOptions);
        const url = `${this.systemSettings.apiBaseUrl}RiderRequestActions/GetRiderReportExcelReport${queryString}`;

        return this.fileDownloader.downloadFile(url,null);
    }

    public async getRiderReportItems(tableQueryOptions: RiderReportTableQueryOptions)
    {
        const queryString = this.buildRiderReportQueryString(tableQueryOptions);   
        const url = `${this.systemSettings.apiBaseUrl}RiderRequestActions/GetRiderReport${queryString}`;

        return this.$http
            .get<A3ApiResponse<PageResponse< RiderReportListItem>>>(url)
            .then((response)=> response.data.value);
    }

    private buildRiderReportQueryString(tableQueryOptions:RiderReportTableQueryOptions)
    {
        if (!tableQueryOptions) {
            tableQueryOptions = {
                pageNumber: 1,
                recordsPerPage: 10,
            } as RiderReportTableQueryOptions;
        }

        if (!tableQueryOptions.searchPhrase) {
            tableQueryOptions.searchPhrase = "";
        }

        if (!tableQueryOptions.orderBy) {
            tableQueryOptions.orderBy = "BondTransactions.CreatedDateTime DESC";
        }

        if (!tableQueryOptions.pageNumber) {
            tableQueryOptions.pageNumber = 1;
        }

        if (!tableQueryOptions.recordsPerPage) {
            tableQueryOptions.recordsPerPage = 10;
        }

        let queryString = "?";

        queryString += `&pageNumber=${tableQueryOptions.pageNumber}`;
        queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
        queryString += `&orderBy=${tableQueryOptions.orderBy}`;
        queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;

        if ( tableQueryOptions.carrierSystemAccountId) {
            queryString += `&carrierSystemAccountId=${tableQueryOptions.carrierSystemAccountId}`;
        }
        
        if ( tableQueryOptions.agencySystemAccountId) {
            queryString += `&agencySystemAccountId=${tableQueryOptions.agencySystemAccountId}`;
        }

        if (tableQueryOptions.minimumEffectiveDate) {
            queryString += `&minimumEffectiveDate=${tableQueryOptions.minimumEffectiveDate}`;
        }

        if (tableQueryOptions.maximumEffectiveDate) {
            queryString += `&maximumEffectiveDate=${tableQueryOptions.maximumEffectiveDate}`;
        }
        
        if (tableQueryOptions.minimumCreatedDateTime) {
            queryString += `&minimumCreatedDateTime=${tableQueryOptions.minimumCreatedDateTime}`;
        }

        if (tableQueryOptions.maximumCreatedDateTime) {
            queryString += `&maximumCreatedDateTime=${tableQueryOptions.maximumCreatedDateTime}`;
        }

        if (tableQueryOptions.minimumAmount) {
            queryString += `&minimumAmount=${tableQueryOptions.minimumAmount}`;
        }

        if (tableQueryOptions.maximumAmount) {
            queryString += `&maximumAmount=${tableQueryOptions.maximumAmount}`;
        }

        return queryString;
    }
}

app.service(Injectables.RiderRequestService, RiderRequestService);