import { CustomersService } from "../../api/customerService";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { CustomerSearchResult } from "../../components/customerSearchDropdown/customerSearchResult";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { SearchControl } from "../../utilities/searchControl";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { CustomerRelationshipChildCustomer } from "./customerRelationshipChildCustomer";
import { CustomerRelationships } from "./customerRelationships";
import app from "../../main";
import { RelatedCustomersModalOptions } from "./RelatedCustomersModalOptions";
import { RelatedCustomersModalResult } from "./RelatedCustomersModalResult";

export class RelatedCustomersModalController {

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.CustomersService,
        Injectables.Options,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly $uibModalInstance: Modal<RelatedCustomersModalResult>,
        private readonly customersService: CustomersService,
        private readonly options:RelatedCustomersModalOptions,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {}

    public customerId: number;
    public busyIndicator: BusyIndicator;
    public customerRelationships: CustomerRelationships;
    public customerSearchControl: SearchControl<CustomerSearchResult>;

    private loadRelationships() {
        return this.customersService.loadCustomerRelationships(this.customerId)
            .then((customerRelationships) => {
                if (customerRelationships !== null) {
                    this.customerRelationships = customerRelationships;
                } else {
                    this.buildDefaultRelationships();
                }
            });
    }

    private buildDefaultRelationships() {
        this.busyIndicator.message = 'Loading Customer Information...';
        this.busyIndicator.promise = this.customersService.getCustomerDefaultParentRelationship(this.customerId)
            .then((customer) => {
                this.customerRelationships = {
                    primaryCustomerId: this.customerId,
                    primaryCustomerLookupCode: customer.lookupCode,
                    primaryCustomerName: customer.name,
                    childCustomers: []
                };
            });
    }

    public close() {
        this.$uibModalInstance.dismiss();
    }

    public removeChildCustomer(childCustomer: CustomerRelationshipChildCustomer, index: number) {
        
        if (childCustomer.id) {
            childCustomer.isRemoved = childCustomer.isRemoved !== true;
        } else {
            this.customerRelationships.childCustomers.splice(index, 1);
        }
    }

    public save () {
        this.busyIndicator.message = 'Saving...';
        this.busyIndicator.promise = this.customersService.saveCustomerRelationships(this.customerRelationships)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Related customers saved successfully');
                this.$uibModalInstance.close(new RelatedCustomersModalResult(true));
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to save the related customers');
            })
    }

    public addChildCustomer() {
        if (!this.customerSearchControl.selected) {
            return;
        }

        // if selected is already a child customer
        if (this.customerRelationships.childCustomers.some((childRelationship) => childRelationship.customerId === this.customerSearchControl.selected.id)) {
            return;
        }

        this.customerRelationships.childCustomers.push({
            customerId: this.customerSearchControl.selected.id,
            customerName: this.customerSearchControl.selected.name,
            lookupCode: this.customerSearchControl.selected.lookupCode,
        } as CustomerRelationshipChildCustomer);

        this.customerSearchControl.clear();
    }

    $onInit() {
        this.customerId = this.options.customerId;

        this.busyIndicator = {
            message: 'Loading Related Customers',
            promise: this.loadRelationships()
        };
    }
}

app.controller('RelatedCustomersModalController', RelatedCustomersModalController);