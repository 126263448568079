import { IJQueryService } from "../../../utilities/jquery/iJQueryService";
import defaultWrapper from "../defaultWrapper";
import fieldGroupWrapper from "../fieldGroupWrapper";

export default (element: JQuery, jQueryProvider: IJQueryService) => {
    element.attr('mask-integer', '');
    element.attr('is-integer', '');
    element.attr('type', 'text');
    
    fieldGroupWrapper(element, defaultWrapper(element), jQueryProvider);
};