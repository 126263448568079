import { BondStatus } from "../../api/types/model/bond";

export interface BondListItem {
    customerId: number;
    id: number;
    bondNumber: string;
    transactionsAreVisible: boolean;
    status: BondStatus;
    nameOnBond: string;
    bondAmount: number;
    writingCompanyName: string;
    effectiveDate: Date;
    expirationDate: Date;
    bondTypeName: string;
    allowPrincipalAssignment: boolean;
    cancellationDate?: Date;
    bondAccountId?: number;
    carrierBondAccountNumber?: string;
    isElectronic: boolean;
}