import { Injectables } from "../../configuration/injectables";
import app from "../../main";
    
export class ToastMessageCreator {
    public static $inject=[
        Injectables.ngToast
    ];

    constructor(
        private readonly ngToast: any
    ) {}

    public createSuccessMessage = (message: string) => {
        this.ngToast.create({
            content: message
        });
    }

    public createErrorMessage = (message: string) => {
        this.ngToast.create({
            className: 'danger',
            content: message
        });
    }
}

app.service(Injectables.ToastMessageCreator, ToastMessageCreator);