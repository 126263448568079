import { SystemAccountService } from "../../../../api/systemAccountService";
import { SelectOption } from "../../../../api/types/selectOption";
import { Injectables } from "../../../../configuration/injectables";
import app from "../../../../main";
import isUndefinedOrNull from "../../../../utilities/angularUtilities/isUndefinedOrNull";
import { Table } from "../../../../utilities/tables/table";
import { BondTypeReportDropdownFilterOptions } from "./bondTypesReportDropdownFilterOptions";
import { BondTypesItem } from "../bondTypesItem";
import { SfaaCodeService } from "../../../../api/sfaaCodeService";
import { IPromise } from "angular";
import { CurrentUserResolver } from "../../../../utilities/currentUserResolver/currentUserResolver";

class BondTypesReportFilterDropdownController {
    
    public static $inject = [
        Injectables.SfaaCodeService,
    ];

    constructor(
        private readonly sfaaCodeService: SfaaCodeService
    ) {
        this.modifiedFilters = {} as BondTypeReportDropdownFilterOptions;
        this.filterCount = 0;
    }

    public isOpen: boolean;
    public filterCount = 0;
    public modifiedFilters: BondTypeReportDropdownFilterOptions;
    public table: Table<BondTypesItem, BondTypeReportDropdownFilterOptions>;
    public sfaaCodeOptions: SelectOption<number>[] = [];

    public loadSfaaCodes(): IPromise<void> {
        return this.sfaaCodeService
            .getNewAllSfaaCodeOptions()
            .then((options) => {
                this.sfaaCodeOptions = options
            });
    }

    public clearFilters() {
        this.modifiedFilters = {
            requisitioningState: null,
            isActive: null,
            startDate: null,
            endDate: null,
            sfaaCodeId: null
        } as BondTypeReportDropdownFilterOptions;

        this.table.applyFilters({ ...this.modifiedFilters });
        
        this.setFilterCount();
        this.isOpen = false;
    }

    public cancel() {
        this.isOpen = false;
        this.modifiedFilters = { ...this.table.queryOptions };
    }

    public applyFilters() {
        this.isOpen = false;
        this.table.applyFilters({ ...this.modifiedFilters });
        this.setFilterCount();
    }

    private setModifiedFilters = () => {
        this.modifiedFilters.requisitioningState = this.table.queryOptions.requisitioningState;
        this.modifiedFilters.startDate = this.table.queryOptions.startDate;
        this.modifiedFilters.endDate = this.table.queryOptions.endDate;
        this.modifiedFilters.isActive = this.table.queryOptions.isActive;
        this.modifiedFilters.sfaaCodeId = this.table.queryOptions.sfaaCodeId;

        this.setFilterCount();
    };

    public setFilterCount() {
        this.filterCount = 0;

        if (this.table.queryOptions.requisitioningState) {
            this.filterCount++;
        }

        if (!isUndefinedOrNull(this.table.queryOptions.isActive)) {
            this.filterCount++;
        }
    
        if (
            this.table.queryOptions.startDate ||
            this.table.queryOptions.endDate
        ) {
            this.filterCount++;
        }

        if (this.table.queryOptions.sfaaCodeId) {
            this.filterCount++;
        }
    }

    public $onInit() {
        this.loadSfaaCodes();
        this.setModifiedFilters();
    }
}

const bondTypesReportDropdownFilterComponent = {
    bindings: {
        table: "<"
    },
    controller: BondTypesReportFilterDropdownController,
    templateUrl:"app/states/common/bondTypes/bondTypesReportFilterDropdown/bondTypesReportFilterDropdown.html",
	controllerAs: "vm",
};

app.component(
    "bondTypesReportFilterDropdown",
    bondTypesReportDropdownFilterComponent
);
