
/* NOTE: 
    there are 2 versions of this because I had trouble getting the nested transclusion to work. 
    We could refactor existing code to only use the attribute version of this directive

    the attribute version can be used in the <bound-grid> directive or other directives that use transclusion
*/

import * as angular from "angular";
import { IHttpService, IDirectiveFactory, IDirectiveCompileFn, IScope, IAttributes } from "angular";
import * as saveAs from "file-saver";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";

class PdfDownloadElementController {

    public static $inject = [
        Injectables.ToastMessageCreator,
        Injectables.$http
    ];

    constructor(
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly $http: IHttpService
    ) {
    }

    public url: string;
    public downloading: boolean;
    public class: string;

    public downloadPdf = () => {
        if (this.downloading) {
            return;
        } 

        this.downloading = true;

        this.$http.get<ArrayBuffer>(this.url, { responseType: 'arraybuffer' })
            .then((response) => {
                this.downloading = false;
                
                const headers = response.headers();
                const dispositionHeader = headers['content-disposition'].replace(/['"]+/g, '');
                const filename = dispositionHeader.substring(dispositionHeader.indexOf('filename=') + 9);

                const blob = new Blob([response.data], { type: headers['content-type'] });
                
                //uses FileSave.js
                //http://purl.eligrey.com/github/FileSaver.js/blob/master/FileSaver.js
                saveAs(blob, filename);
            })
            .catch((response) => {
                this.downloading = false;
                
                if (response && response.Message) {
                    this.toastMessageCreator.createErrorMessage(response.Message);
                } else {
                    this.toastMessageCreator.createErrorMessage('An error occurred trying to download the document');
                }
            });
    }       

    public $onInit = () => {}
}

const pdfDownloadElementDirective = () => {

    return {
        restrict: 'E',
        transclude: true,
        template: '<button ' +
                    'ng-click="vm.downloadPdf()" ' +
                    'class={{vm.class}} ' +
                    'ng-disabled="vm.downloading" '+
                    'href>' +
                        '<span ng-transclude></span> ' + 
                '</button>',
        bindToController: {
            'url': '<',
            'ngShow': '<',
            'class': '@?'
        },
        scope: {},
        replace: true,
        controller: PdfDownloadElementController,
        controllerAs: 'vm'
    }
}

const pdfDownloadAttributeDirective: IDirectiveFactory = () => {

    const compile:IDirectiveCompileFn = () => {
        return {
            post: (
                scope: IScope, 
                element: JQuery, 
                attributes: IAttributes, 
                controller: PdfDownloadAttributeController) => {

                angular
                    .element(element)
                    .on('click', controller.downloadPdf);
            }
        }
    }

    return {
        restrict: 'A',
        priority: 1,
        scope: { },
        compile: compile,
        controller: PdfDownloadAttributeController,
    }
}

class PdfDownloadAttributeController {
    
    public static $inject = [
        Injectables.$http,
        Injectables.$attrs,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly $attrs: IAttributes,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {
    }

    public downloading: boolean;

    public downloadPdf = () => {
        if (this.downloading) {
            return;
        }

        this.downloading = true;

        this.$http.get<ArrayBuffer>(this.$attrs.url, { responseType: 'arraybuffer' })
            .then((response) => {
                this.downloading = false;

                var headers = response.headers();
                var dispositionHeader = headers['content-disposition'].replace(/['"]+/g, '');
                var filename = dispositionHeader.substring(dispositionHeader.indexOf('filename=') + 9);

                var blob = new Blob([response.data], { type: headers['content-type'] });

                //uses FileSave.js
                //http://purl.eligrey.com/github/FileSaver.js/blob/master/FileSaver.js
                saveAs(blob, filename);
            })
            .catch((response) => {
                this.downloading = false;

                if (response && response.Message) {
                    this.toastMessageCreator.createErrorMessage(response.Message);
                } else {
                    this.toastMessageCreator.createErrorMessage('An error occurred trying to download the document');
                }
            });
    };

    public $onInit = () => {}
}

app.directive('pdfDownload', pdfDownloadElementDirective);
app.directive('pdfDownload', pdfDownloadAttributeDirective);