import { Injectables } from "../../../configuration/injectables";
import { State } from "../../state";

export class BondTypeSelectionStateParams {
    public customerId: number;
    public eProducerAccountId: number;
}

export class ApplicationBondTypeSelectionController {

    public static $inject = [
        Injectables.$state,
        Injectables.$stateParams
    ];

    constructor(
        private readonly $state: State,
        private readonly $stateParams: BondTypeSelectionStateParams) { 
    }

    public bondTypeSelected(bondTypeId: number): void {
        this.$state.go('main.application', {
            bondTypeId: bondTypeId,
            customerId: this.$stateParams.customerId,
            eProducerAccountId: this.$stateParams.eProducerAccountId
        });
    }

    public $onInit = () => {}
}