import { IBaseEntity } from "./iBaseEntity";
import { User } from "./user";

export interface DocumentRating extends IBaseEntity { 
    userId: number;
    documentId: number;
    rating: number;
    document: Document;
    user: User;
    id: number;
    createdDateTime: Date;
}