import { ITimeoutService, IScope } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";

const focusMe = ($timeout: ITimeoutService) => {
    
    const link = (scope: IScope, element: JQuery) => {
        
        scope.$watch('focusMe', (value: boolean) => {   
            if (value !== false) {
                $timeout(() => {
                    element[0].focus();
                });
            }
        });
    }

    return {
        scope: { focusMe: '=' },
        link: link
    };
}

focusMe.$inject = [Injectables.$timeout];

app.directive('focusMe', focusMe);
