import { IBaseEntity } from "./iBaseEntity";
import { RateTier } from "./rateTier";
import { SystemAccount } from "./systemAccount";
import { UnderwritingConfiguration } from "./underwritingConfiguration";

export interface Rate extends IBaseEntity { 
    fixedRate: number;
    ratePerThousand: number;
    rateType: RateType;
    systemAccountId: number;
    comments: string;
    name: string;
    rateTiers: RateTier[];
    systemAccount: SystemAccount;
    underwritingConfigurations: UnderwritingConfiguration[];
    id: number;
    createdDateTime: Date;
}

export enum RateType {	
    RatePerThousand = "RatePerThousand",
    Tiered = "Tiered",
    Fixed = "Fixed"
}