import { BondTransactionService } from "../../api/bondTransactionService";
import { SystemAccountService } from "../../api/systemAccountService";
import { EpicNewBusinessExportRequest, CommissionBreakdown } from "../../api/types/model/epicNewBusinessExportRequest";
import { SelectOption } from "../../api/types/selectOption";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { ExportEpicModalOptions } from "./epicBondTransactionExportModalOptions";
import { ExportEpicModalResult } from "./ExportEpicModalResult";
import { ExportEpicResultActions } from "./ExportEpicResultActions";
import app from "../../main";
import { IQService, IPromise } from "angular";

export class EpicExportBondTransactionModalController {
    
    public static $inject = [
        Injectables.Options,
        Injectables.$uibModalInstance,
        Injectables.$q,
        Injectables.SystemAccountService,
        Injectables.BondTransactionService,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly options: ExportEpicModalOptions,
        private readonly $uibModalInstance: Modal<ExportEpicModalResult>,
        private readonly $q: IQService,
        private readonly systemAccountService: SystemAccountService,
        private readonly bondTransactionService: BondTransactionService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {}

    public commissionBreakdownTypes: SelectOption<string>[];
    public commissionCalculationOptions: SelectOption<string>[];
    public billToOptions: SelectOption<string>[];
    public producerUserOptions: SelectOption<number>[];
    public clientServiceManagerUserOptions: SelectOption<number>[];
    public brokerCommissionContractOptions: SelectOption<number>[][];
    public premiumPayableContractOptions: SelectOption<number>[];
    public bondTransactionId: number;
    public exportRequest: EpicNewBusinessExportRequest;
    public loadingPromise: IPromise<void>;

    public loadServicingUserOptions(): IPromise<void> {
            return this.$q.all([
            this.systemAccountService.getProducerUserOptions(),
            this.systemAccountService.getClientServiceManagerUserOptions()
        ])
        .then(([producerUserOptions, clientServiceManagerUserOptions]) => {
            this.clientServiceManagerUserOptions = clientServiceManagerUserOptions;
            this.producerUserOptions = producerUserOptions;
        });
    }

    public addCommissionBreakdownItem() {
        this.exportRequest.commissionBreakdowns.push({} as CommissionBreakdown);
    }

    public removeCommissionBreakdownItem(index: number) {
        this.exportRequest.commissionBreakdowns.splice(index, 1);
    }

    public loadPremiumPayableContractOptions() {
        return this.systemAccountService.getPremiumPayableContracts(
                this.exportRequest.epicPremiumPayableCode,
                this.exportRequest.epicProfitCenterCode
            )
            .then((options) => {
                this.premiumPayableContractOptions = options;

                if (this.premiumPayableContractOptions.length == 1) {
                    this.exportRequest.premiumPayableContractId = this.premiumPayableContractOptions[0].value;
                }
            })
            .catch(() => {
                this.premiumPayableContractOptions = [];
            });
    }

    public premiumPayableChanged() {
        this.loadingPromise = this.loadPremiumPayableContractOptions();
    }

    public loadAllBrokerPayableContractOptions() {
        var promises: IPromise<void>[] = [];

        for (var i = 0; i < this.exportRequest.commissionBreakdowns.length; i++) {
            promises.push(this.loadBrokerCommissionContracts(i));
        }

        return this.$q.all(promises);
    }

    public loadBrokerCommissionContracts(index: number): IPromise<void> {
        const commissionBreakdown = this.exportRequest.commissionBreakdowns[index];

        if (!commissionBreakdown || !commissionBreakdown.brokerLookupCode) {
            return this.$q.when();
        }

        return this.systemAccountService.getBrokerCommissionContractOptions(
                commissionBreakdown.brokerLookupCode,
                this.exportRequest.epicProfitCenterCode
            )
            .then((options) => {
                this.brokerCommissionContractOptions[index] = options;

                if (options && options.length === 1) {
                    commissionBreakdown.epicBrokerPayableContractId = options[0].value;
                }
            })
            .catch(() => {
                this.brokerCommissionContractOptions[index] = [];
            });
    }

    public brokerCommissionContractChanged(index: number) {
        this.loadingPromise = this.loadBrokerCommissionContracts(index);
    }

    public export() {
        this.loadingPromise = this.bondTransactionService.saveAndExportEpicNewBusinessExportRequest(this.exportRequest)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Policy was successfully exported to Epic');
                this.$uibModalInstance.close({resultAction: ExportEpicResultActions.Success} as ExportEpicModalResult);
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to export the policy to Epic');
            });
    }

    public loadBondTransaction() {
        return this.bondTransactionService
            .getEpicNewBusinessExportRequest(this.bondTransactionId)
            .then((exportRequest) => {
                this.exportRequest = exportRequest;

                if (!this.exportRequest.commissionBreakdowns || !this.exportRequest.commissionBreakdowns.length) {
                    this.exportRequest.commissionBreakdowns = [];
                }
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('Unable to load export settings for this bond transaction');
            });
    }

    public $onInit(): void {
        this.bondTransactionId = this.options.bondTransactionId;

        this.brokerCommissionContractOptions = [];

        this.commissionBreakdownTypes = [
            { label: 'Producer Pay (PPay)', value: 'ProducerPay' },
            { label: 'Broker Pay (BPay)', value: 'BrokerPay' },
            { label: 'Broker Receivable (BREC)', value: 'BrokerReceivable' }
        ];

        this.commissionCalculationOptions = [
            { label: 'Percent of Commission', value: 'PercentOfCommission' },
            { label: 'Percent of Premium', value: 'PercentOfPremium' }
        ];

        this.billToOptions = [
            { label: 'Bill to Client', value: 'false' },
            { label: 'Bill to Broker', value: 'true' }
        ];

        this.loadingPromise = this.$q.all([
                this.loadServicingUserOptions(),
                this.loadBondTransaction()
            ])
            .then(() => {
                return this.$q.all([
                    this.loadAllBrokerPayableContractOptions(),
                    this.loadPremiumPayableContractOptions()
                ]);
            })
            .then(() => { });
    }

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }
}

app.controller('EpicExportBondTransactionModalController', EpicExportBondTransactionModalController);