import { IBaseEntity } from "./iBaseEntity";
import { User } from "./user";
import { UserGroup } from "./userGroup";

export interface UserUserGroup extends IBaseEntity { 
    userId: number;
    userGroupId: number;
    user: User;
    userGroup: UserGroup;
    id: number;
    createdDateTime: Date;
}