import { ApplicationQuestionResponse } from "../../api/types/model/applicationQuestionResponse";
import { UnderwritingQuestions } from "../../api/types/underwritingQuestions";
import app from "../../main";

class AdditionalQuestionListController {

    public static $inject = [];

    public questionResponses:  ApplicationQuestionResponse[];
    public questions: UnderwritingQuestions[];

    constructor() { }

    public $onInit(): void {

    }

    public initQuestion(question): void {
        question.options = [];

        if (question.isRequired === true) {
            question.required = true;
        }

        for(let i = 0; i < question.masterApplicationQuestionChoices.length; i++) {
            question.options.push({ 
                label: question.masterApplicationQuestionChoices[i].text, 
                value: question.masterApplicationQuestionChoices[i].text 
            });
        }

        this.setUiSelectedValue(question);
    }

    public setQuestionResponses(): void {
        this.questionResponses = [];

        // parent questions
        for (let i = 0; i < this.questions.length; i++) {
            if (this.questions[i].selectedValue && this.questions[i].id) {
                this.questionResponses.push({
                    responseText: this.questions[i].selectedValue,
                    masterApplicationQuestionId: this.questions[i].id,
                    questionText: this.questions[i].question,
                    originType: this.questions[i].originType
                } as  ApplicationQuestionResponse);
            }

            // sub questions
            if (!this.questions[i].subQuestions) {
                continue;
            }

            for (let j = 0; j < this.questions[i].subQuestions.length; j++) {
                if (this.questions[i].subQuestions[j].selectedValue && this.questions[i].subQuestions[j].id) {
                    this.questionResponses.push({
                        responseText: this.questions[i].subQuestions[j].selectedValue,
                        masterApplicationQuestionId: this.questions[i].subQuestions[j].id,
                        questionText: this.questions[i].subQuestions[j].question,
                        originType: this.questions[i].originType
                    } as  ApplicationQuestionResponse);
                }
            }
        }
    }

    public setUiSelectedValue(question): void {
        for (const response of this.questionResponses) {
            if (response.masterApplicationQuestionId === question.id) {
                question.selectedValue = response.responseText;
            }
        }
    }
}

const additionalQuestionListComponent = {
    templateUrl: 'app/components/additionalQuestionList/additionalQuestionList.html',
    bindings: { 
        questions: '=', 
        questionResponses: '=' 
    },
    controller: AdditionalQuestionListController,
    controllerAs: 'vm'
};

app.component('additionalQuestions', additionalQuestionListComponent);
