import { ReinstatementRequestService } from "../../api/reinstatementRequestService";
import { ReinstatementRequestBond } from "../../api/types/reinstatementRequestBond";
import { SystemAccountService } from "../../api/systemAccountService";
import { ReinstatementReason } from "../../api/types/model/reinstatementReason";
import { ReinstatementRequest, ReinstatementStatus } from "../../api/types/model/reinstatementRequest";
import { SelectOption } from "../../api/types/selectOption";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { RequestReinstatementOptions } from "./reinstatementRequestModalOptions";
import { RequestReinstatementResult } from "./RequestReinstatementResult";
import { RequestReinstatementResultAction } from "./RequestReinstatementResultAction";
import app from "../../main";
import { IPromise, IQService } from "angular";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";

export class ReinstatementRequestModalController {

    public static $inject = [
        Injectables.CurrentUserResolver,
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.ReinstatementRequestService,
        Injectables.SystemAccountService,
        Injectables.ToastMessageCreator,
        Injectables.$q
    ];

    constructor(
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly $uibModalInstance: Modal<RequestReinstatementResult>,
        private readonly options: RequestReinstatementOptions,
        private readonly reinstatementRequestService: ReinstatementRequestService,
        private readonly systemAccountService: SystemAccountService,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly $q: IQService
    ){ }

    public request: ReinstatementRequest;
    public reasonId: number;
    public bond: ReinstatementRequestBond;
    public aifOptions: SelectOption<number>[];
    public busyIndicator: BusyIndicator;
    public reinstatementReasons: ReinstatementReason[];
    public agentSubmissionTypeOptions: SelectOption<string>[] = [
        { label: 'Execute Reinstatement', value: 'Execute' },
        { label: 'Request Reinstatement', value: 'Request' }
    ];
    public agentSubmissionType: 'Execute' | 'Request' = 'Execute';

    get isCarrier(): boolean { 
        return this.currentUserResolver.getCurrentUser().systemAccount.isCarrier; 
    }

    public get isAgentExecuting(): boolean {
        return this.agentSubmissionType === 'Execute' && 
                this.request && 
                !this.request.id && 
                !this.isCarrier;
    }
    
    public get isUpdating(): boolean {
        return this.agentSubmissionType === 'Request' &&
                this.request && 
                this.request.id && 
                !this.isCarrier;
    }

    public get isSubmitting(): boolean {
        return this.request && !this.request.id && !this.isCarrier;
    }

    public get isApproving(): boolean {

        // approve on submit if the user is a carrier
        // OR
        // if this is an existing request that has been changed to "execute"
        return (
                    this.request && 
                    this.request.id && 
                    this.isCarrier
                ) || (
                    !this.isCarrier &&
                    !!this.request.id &&
                    this.agentSubmissionType === 'Execute' 
                );
    }

    public get isCreating(): boolean {
        return this.request && !this.request.id && this.isCarrier;
    }

    get totalFees(): number {
        let totalFees = 0;

        if (this.request?.fees?.length) {
            for(let i = 0; i < this.request.fees.length; i++) {
                totalFees += this.request.fees[i].amount;
            }
        }

        return totalFees;
    }

    public approveRequestClicked() {
        this.busyIndicator.message = 'Procesing Reinstatement...';
        this.busyIndicator.promise = this.approveRequest();
    }

    public approveRequest(): IPromise<void> {
        
        return this.reinstatementRequestService.approveReinstatementRequestByCarrier(
                this.request.id, 
                this.request.effectiveDate, 
                this.request.premiumIncrease, 
                this.request.commission, 
                this.reasonId, 
                this.request.carrierComments
            )
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Bond was successfully reinstated');
                this.bond.reinstatementStatus = ReinstatementStatus.None;
                this.$uibModalInstance.close(new RequestReinstatementResult(RequestReinstatementResultAction.BondReinstated));
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred while trying to reinstate the bond');
            });
    }

    public cancel(): void {
        this.$uibModalInstance.dismiss();
    }

    public decline(): void {
        this.busyIndicator.message = 'Processing...';
        this.busyIndicator.promise = this.reinstatementRequestService.declineReinstatementByCarrier(this.request.id, this.request.carrierComments)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Reinstatement request has been declined');
                this.bond.reinstatementStatus = ReinstatementStatus.None;
                this.$uibModalInstance.close(new RequestReinstatementResult(RequestReinstatementResultAction.Declined));
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to decline the reinstatement request');
            });
    }

    public async loadAttorneyInFactOptions(): Promise<void> {
        this.aifOptions = await this.systemAccountService.getAttorneyInFactOptions(this.bond.id);
    
        if (!this.request.attorneyInFactUserId) {
            this.request.attorneyInFactUserId = this.systemAccountService.getDefaultAttorneyInFactUserId(this.aifOptions);
        }
    }

    public async loadReinstatementRequest(): Promise<void> {
        const response = await this.reinstatementRequestService.getReinstatementRequest(this.bond.id);

        this.request = {
            agencyComments: response.agencyComments,
            attorneyInFactUserId: response.attorneyInFactUserId,
            id: response.id,
            requestedEffectiveDate: response.requestedEffectiveDate,
            user: response.user,
            effectiveDate: response.effectiveDate || response.requestedEffectiveDate
        } as ReinstatementRequest;
    }

    public rescindRequest(): IPromise<void> {
        if (!this.request.id) {
            return;
        }

        this.busyIndicator.message = 'Processing...';
        this.busyIndicator.promise = this.reinstatementRequestService.rescindReinstatementRequest(this.request.id)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage( 'Your reinstatement request has been rescinded succesfully');
                this.bond.reinstatementStatus = ReinstatementStatus.None;
                this.$uibModalInstance.close(new RequestReinstatementResult(RequestReinstatementResultAction.Rescinded));
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to rescind the reinstatement request');
            });
    }

    private agentExecute(): void {
        const saveReinstatementRequest = {
            agencySystemAccountId: this.currentUserResolver.getCurrentUser().user.systemAccountId,
            carrierSystemAccountId: this.bond.carrierSystemAccountId,
            createdDateTime: this.request.createdDateTime,
            effectiveDate: this.request.requestedEffectiveDate,
            requestedEffectiveDate: this.request.requestedEffectiveDate,
            premiumIncrease: this.request.premiumIncrease,
            commission: this.request.commission,
            reasonCode: this.request.reasonCode,
            reasonDescription: this.request.reasonDescription,
            bond: this.bond,
            userId: this.request.userId,
            fees: this.request.fees
        } as ReinstatementRequest;

        this.busyIndicator.message = 'Reinstating Bond...';
        this.busyIndicator.promise = this.reinstatementRequestService.requestReinstatement(
                this.bond.id, 
                this.request.requestedEffectiveDate, 
                this.request.agencyComments, 
                this.request.attorneyInFactUserId,
                this.request.fees
            )
            .then(() => this.loadReinstatementRequest())
            .then(() => {
                this.request.carrierSystemAccountId = saveReinstatementRequest.carrierSystemAccountId;
                this.request.agencySystemAccountId = saveReinstatementRequest.agencySystemAccountId;
                this.request.bond = saveReinstatementRequest.bond;
                this.request.createdDateTime = saveReinstatementRequest.createdDateTime;
                this.request.effectiveDate = saveReinstatementRequest.effectiveDate;
                this.request.premiumIncrease = saveReinstatementRequest.premiumIncrease;
                this.request.commission = saveReinstatementRequest.commission;
                this.request.reasonCode = saveReinstatementRequest.reasonCode;
                this.request.reasonDescription = saveReinstatementRequest.reasonDescription;
                this.request.userId = saveReinstatementRequest.userId;
                this.request.fees = saveReinstatementRequest.fees;

                return this.approveRequest();
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to submit the reinstatement request');
            });
    }

    private updateRequest(): void {
        this.busyIndicator.message = 'Updating Request...';
        this.busyIndicator.promise = this.reinstatementRequestService
            .updateReinstatementRequest(
                this.request.id, 
                this.request.requestedEffectiveDate, 
                this.request.agencyComments, 
                this.request.attorneyInFactUserId,
                this.request.fees
            )
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('The reinstatement request has been updated');
                this.$uibModalInstance.close(new RequestReinstatementResult(RequestReinstatementResultAction.Submitted));
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to update the reinstatement request');
            });
    }

    private submitNewRequest(): void {
        this.busyIndicator.message = 'Submitting Request...';
        this.busyIndicator.promise = this.reinstatementRequestService
            .requestReinstatement(
                this.bond.id, 
                this.request.requestedEffectiveDate, 
                this.request.agencyComments, 
                this.request.attorneyInFactUserId,
                this.request.fees
            )
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Your reinstatement request has been submitted');
                this.$uibModalInstance.close(new RequestReinstatementResult(RequestReinstatementResultAction.Submitted));
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to submit the reinstatement request');
            });
    }

    public submit(): void {
        if (this.isAgentExecuting) {
            this.agentExecute();
        } else if (this.isUpdating) {
            this.updateRequest();
        } else if (this.isSubmitting) {
            this.submitNewRequest();
        } else if (this.isApproving) {
            this.busyIndicator.message = 'Approving Reinstatement...';
            this.busyIndicator.promise = this.approveRequest();
        } else if (this.isCreating) {
            this.carrierExecute();
        }
    }

    private carrierExecute(): void {
        this.request.requestedEffectiveDate = this.request.requestedEffectiveDate ? this.request.requestedEffectiveDate : this.request.effectiveDate;

        this.busyIndicator.message = 'Submitting Request';
        this.busyIndicator.promise = this.reinstatementRequestService
                                            .requestReinstatement(
                                                this.bond.id, 
                                                this.request.requestedEffectiveDate, 
                                                this.request.agencyComments, 
                                                this.request.attorneyInFactUserId,
                                                []
                                            )
                                            .then((requestId) => {
                                                this.request.id = requestId;
                                                return this.approveRequest();
                                            });
    }

    private async loadReinstatementReasons(): Promise<void> {
        this.reinstatementReasons = await this.systemAccountService.getReinstatementReasons(this.bond.carrierSystemAccountId);

        if (this.reinstatementReasons && this.reinstatementReasons.length === 1) {
            this.reasonId = this.reinstatementReasons[0].id;
        }
    }

    public $onInit(): void {
        this.bond = this.options.bond;
        this.busyIndicator = { message: 'Loading...' };
        this.request = {
            requestedEffectiveDate: this.bond.cancellationDate
        } as ReinstatementRequest;

        const promises = [
            this.loadAttorneyInFactOptions(),
            this.loadReinstatementReasons()
        ];

        if (this.bond.reinstatementStatus === ReinstatementStatus.Requested) {
            promises.push(this.loadReinstatementRequest());
            this.agentSubmissionType = 'Request';
        }

        this.busyIndicator = {
            message: 'Loading...',
            promise: this.$q.all(promises)
        }
    }
}

app.controller('ReinstatementRequestModalController', ReinstatementRequestModalController);