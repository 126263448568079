import { ApplicationQuestionResponse } from "./model/applicationQuestionResponse";
import { RenewalStatus } from "./model/bond";
import { MasterApplicationQuestion } from "./model/masterApplicationQuestion";

export interface BondRenewalQuoteCreationData {
    bondId: number;
    bondTypeName: string;
    bondAmount: number;
    carrierName: string;
    carrierSystemAccountId: number;
    expirationDate: Date;
    effectiveDate: Date;
    nameOnBond: string;
    isRenewable: boolean;
    renewalStatus: RenewalStatus;
    applicationQuestionResponses: ApplicationQuestionResponse[];
    applicationQuestions: MasterApplicationQuestion[];
    applicationId: number;
}