import { BrowserNotification } from "../browserNotification";
import { DocumentVersion } from "../documentVersion";
import { AuditLog } from "./auditLog";
import { DocumentRating } from "./documentRating";
import { IBaseEntity } from "./iBaseEntity";
import { Memo } from "./memo";
import { SystemAccount } from "./systemAccount";
import { UserUserGroup } from "./userUserGroup";

export interface User extends IBaseEntity { 
    systemAccountId: number;
    ams360AccountExecCode: string;
    ams360AccountRepCode: string;
    ams360BranchCode: string;
    ams360DepartmentCode: string;
    ams360DivisionCode: string;
    ams360EmployeeCode: string;
    ams360GroupCode: string;
    auditLogs: AuditLog[];
    dashboardConfiguration: string;
    documentRatings: DocumentRating[];
    memos: Memo[];
    documentVersions: DocumentVersion[];
    email: string;
    receivesReleaseEmails: boolean;
    receivesSupportTicketEmails: boolean;
    receivesEProducerApplicationSubmissionEmails: boolean;
    receivesEProducerBondPurchaseEmails: boolean;
    receivesEProducerBrokerAccountRequestEmails: boolean;
    receivesEProducerPaymentReceivedEmails: boolean;
    receivesIndemnitySignatureReceivedEmails: boolean;
    receivesCancellationRequestEmails: boolean;
    receivesReinstatementRequestEmails: boolean;
    receivesReinstatementApprovedDeclinedEmails: boolean;
    receivesCancellationApprovedEmails: boolean;
    receivesEProducerAttachmentEmails: boolean;
    receivesRiderRequestEmails: boolean;
    receivesRiderApprovedDeclinedEmails: boolean;
    receivesDocumentCorrectionCompletionEmails: boolean;
    receivesBondTypeRequestCompletionEmails: boolean;
    receivesBondTypeRequestCompletionCarrierEmail: boolean;
    receivesCarrierCreatedRiderEmails: boolean;
    receivesCarrierCreatedCancellationEmails: boolean;
    receivesCarrierCreatedReinstatementEmails: boolean;
    receivesTestEmails: boolean;
    receivesReleaseBrowserNotifications: boolean;
    receivesSupportTicketBrowserNotifications: boolean;
    receivesEProducerApplicationSubmissionBrowserNotifications: boolean;
    receivesEProducerBondPurchaseBrowserNotifications: boolean;
    receivesEProducerBrokerAccountRequestBrowserNotifications: boolean;
    receivesEProducerPaymentReceivedBrowserNotifications: boolean;
    receivesIndemnitySignatureReceivedBrowserNotifications: boolean;
    receivesCancellationRequestBrowserNotifications: boolean;
    receivesReinstatementRequestBrowserNotifications: boolean;
    receivesReinstatementApprovedDeclinedBrowserNotifications: boolean;
    receivesCancellationApprovedBrowserNotifications: boolean;
    receivesEProducerAttachmentBrowserNotifications: boolean;
    receivesRiderRequestBrowserNotifications: boolean;
    receivesRiderApprovedDeclinedBrowserNotifications: boolean;
    receivesBondTypeRequestCompletionBrowserNotifications: boolean;
    receivesBondTypeRequestCompletionCarrierBrowserNotifications: boolean;
    receivesCarrierCreatedRiderBrowserNotifications: boolean;
    receivesCarrierCreatedCancellationBrowserNotifications: boolean;
    receivesCarrierCreatedReinstatementBrowserNotifications: boolean;
    receivesTestNotifications: boolean;
    firstName: string;
    fullName: string;
    isAttorneyInFact: boolean;
    isLockedOut: boolean;
    lastLoginDate: Date;
    lastName: string;
    password: string;
    passwordHash: string;
    passwordResetKey: string;
    profilePicture: File;
    profilePictureFileId: number;
    sideMenuIsCollapsed: boolean;
    signatureImageFile: File;
    signatureImageFileId: number;
    systemAccount: SystemAccount;
    theme: string;
    userUserGroups: UserUserGroup[];
    notifications: BrowserNotification[];
    
    canDeleteFromDocumentLibrary: boolean;
    canEditBondTypes: boolean;
    canEditDocumentLibrary: boolean;
    canEditObligees: boolean;
    canEditSystemAccounts: boolean;
    canEditUserGroups: boolean;
    canEditUsers: boolean;
    
    id: number;
    createdDateTime: Date;
}