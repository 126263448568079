import { LeadReportService } from "../../../api/leadReportService";
import { Injectables } from "../../../configuration/injectables";
import app from "../../../main";
import { LeadReportItem } from "./leadReportItem";
import { IDebounceDelayer } from "../../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../../utilities/tables/table";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { LeadsReportDropdownFilterOptions } from "./leadsReportFilterDropdown/leadsReportDropdownFilterOptions";


export class ReportLeadsEntryController {
    public static $inject = [
        Injectables.LeadReportService,
        Injectables.IDebouceDelayer
    ];
    constructor(
        private readonly leadReportService: LeadReportService,
        debouceDelayer: IDebounceDelayer
    ) {
        this.table = new Table(debouceDelayer);
        this.table.onChange = this.loadData;
        
    }

    public readonly table: Table<LeadReportItem, LeadsReportDropdownFilterOptions>;
    public readonly filters: LeadsReportDropdownFilterOptions;

    public busyIndicator: BusyIndicator;

    public refresh = () => {
        this.loadData();
    };

    public exportLeadEntries(){
        this.busyIndicator = {
            message: "Loading...",
            promise: this.leadReportService.downloadLeadEntriesExcelReport(this.table.queryOptions)
        };
    }

    public loadData = () => {
        this.busyIndicator.message = "Loading Lead Entries...";
        this.busyIndicator.promise = this.leadReportService
            .getLeadEntriesReportData(this.table.queryOptions)
            .then((response) => {
                this.table.setData(response.items, response.totalRecordCount);
            }); 
    };

    public $onInit = () => {
        this.busyIndicator = {};

        if (this.filters) {
            this.table.applyFilters(this.filters);
        }

        this.loadData();
    };
}

const leadsEntryReportComponent = {
    bindings: {
        filters: "=?",
    },
    controller: ReportLeadsEntryController,
    templateUrl: "app/states/agentSpecific/leads/leadsEntryReport.html",
    controllerAs: "vm"
};

app.component("leadsEntryReport", leadsEntryReportComponent);

