import {
    ODataFactory,
    ODataFilterCollection,
    ODataEndpoint,
    ODataFilterDataType
} from "../../../../../api/odata";
import { Application } from "../../../../../api/types/model/application";
import { SelectOption } from "../../../../../api/types/selectOption";
import { BusyIndicator } from "../../../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../../../configuration/injectables";
import { Table } from "../../../../../utilities/tables/table";
import { DashboardService } from "../../dashboardService";
import { DashboardWidget } from "../../types/dashboardWidget";
import { ITimeoutService } from "angular";
import A3ApiResponse from "../../../../../api/types/a3ApiResponse";
import app from "../../../../../main";
import { CurrentUserResolver } from "../../../../../utilities/currentUserResolver/currentUserResolver";
import { IDebounceDelayer } from "../../../../../utilities/debounceDelayer/iDebouceDelayer";
import { CancellationRequest } from "../../../../../api/types/model/cancellationRequest";

class DashboardWidgetReinstatementRequestsController {
    public static $inject = [
        Injectables.ODataFactory,
        Injectables.DashboardService,
        Injectables.$timeout,
        Injectables.CurrentUserResolver,
        Injectables.IDebouceDelayer
    ];

    constructor(
        private readonly odata: ODataFactory,
        private readonly dashboardService: DashboardService,
        private readonly $timeout: ITimeoutService,
        private readonly currentUserResolver: CurrentUserResolver,
        debouceDelayer: IDebounceDelayer
    ) {
        this.table = new Table(debouceDelayer);
    }

    public get isCarrier() {
        return this.currentUserResolver.getCurrentUser().systemAccount
            .isCarrier;
    }

    public widget: DashboardWidget;
    public busyIndicator: BusyIndicator;
    public table: Table<CancellationRequest, null>;
    public orderby: string;
    public filterCollection: ODataFilterCollection;
    public errorMessage: string;
    public sfaaCodeOptions: SelectOption<string>[];
    public tagOptions: SelectOption<string>[];
    public carrierOptions: SelectOption<number>[];

    public setFilters = () => {
        this.filterCollection = this.odata.getFilterCollection([
            this.odata.getFilter(
                "bond/bondAmount",
                ODataFilterDataType.money,
                "Bond Amount"
            ),
            this.odata.getFilter(
                "createdDateTime",
                ODataFilterDataType.date,
                "Submitted"
            ),
            this.odata.getFilter(
                "bond/bondType/name",
                ODataFilterDataType.string,
                "Bond Type"
            ),
            this.odata.getFilter(
                "bond/nameOnBond",
                ODataFilterDataType.string,
                "Principal"
            )
        ]);
    };

    public saveRecordsPerPage = () => {
        this.widget.options.recordsPerPage = this.table.pager.recordsPerPage;
        this.dashboardService.save();
    };

    public saveOrderby = () => {
        this.widget.options.orderby = this.table.sorter.sortBy;
        this.dashboardService.save();
    };

    public buildQuery = () => {
        const query = this.odata
            .getQuery()
            .orderby(this.table.sorter.sortBy)
            .skip(
                (this.table.pager.currentPage - 1) *
                    this.table.pager.recordsPerPage
            )
            .top(this.table.pager.recordsPerPage)
            .expand(
                "bond($expand=quote($expand=application)),carrierSystemAccount($select=companyName),agencySystemAccount($select=companyName)"
            )
            .filter("status eq 'Requested'");

        const filterExpr = this.filterCollection.getFilterExpression();
        if (filterExpr) {
            query.filter(filterExpr);
        }

        return query;
    };

    public loadGrid = () => {
        if (this.table.sorter.sortBy !== this.widget.options.orderby) {
            this.saveOrderby();
        }

        if (
            this.table.pager.recordsPerPage !=
            this.widget.options.recordsPerPage
        ) {
            this.saveRecordsPerPage();
        }

        const query = this.buildQuery();

        const dataSvc = this.odata.getService(
            ODataEndpoint.ReinstatementRequest
        );

        this.busyIndicator.promise = dataSvc
            .get<A3ApiResponse<CancellationRequest[]>>(query)
            .then((response) => {
                this.table.setData(
                    response.data.value,
                    response.data["@odata.count"]
                );
            })
            .catch(() => {
                this.errorMessage =
                    "An error occurred trying to get the widget data";
            });
    };

    public $onInit = () => {
        this.busyIndicator = {
            message: "Loading..."
        };

        this.setFilters();

        this.loadGrid();

        this.dashboardService.refreshFunctions.push(this.loadGrid);
        this.table.onChange = this.loadGrid;
    };
}

const cashboardWidgetReinstatementRequestsComponent = {
    templateUrl:
        "app/states/common/dashboard/widgets/reinstatementRequests/dashboardWidgetReinstatementRequests.html",
    bindings: {
        widget: "="
    },
    controllerAs: "vm",
    controller: DashboardWidgetReinstatementRequestsController
};

app.component(
    "dashboardWidgetReinstatementRequests",
    cashboardWidgetReinstatementRequestsComponent
);
