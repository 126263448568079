import { ITimeoutService, IFilterService } from "angular";
import { ODataFactory, ODataFilterCollection, ODataFilterDataType } from "../../../../../api/odata";
import { SfaaCodeService } from "../../../../../api/sfaaCodeService";
import { CacheStore } from "../../../../../api/types/cacheStore";
import { BondType } from "../../../../../api/types/model/bondType";
import { SelectOption } from "../../../../../api/types/selectOption";
import { UnderwritingConfigurationService } from "../../../../../api/underwritingConfigurationService";
import { BusyIndicator } from "../../../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../../../configuration/injectables";
import app from "../../../../../main";
import { Table } from "../../../../../utilities/tables/table";
import { DashboardService } from "../../dashboardService";
import { DashboardWidget } from "../../types/dashboardWidget";
import { IDebounceDelayer } from "../../../../../utilities/debounceDelayer/iDebouceDelayer";
import isDefined from "../../../../../utilities/angularUtilities/isDefined";
import { TagService } from "../../../../../api/tagService";
import { SystemAccountService } from "../../../../../api/systemAccountService";


class DashboardWidgetUnderwritingConfigurationsCarrierController {

    public static $inject = [
        Injectables.ODataFactory,
        Injectables.DashboardService,
        Injectables.TagService,
        Injectables.SfaaCodeService,
        Injectables.UnderwritingConfigurationService,
        Injectables.SystemAccountService,
        Injectables.$filter,
        Injectables.IDebouceDelayer
    ];

    constructor(
        private readonly odata: ODataFactory,
        private readonly dashboardService: DashboardService,
        private readonly tagService: TagService,
        private readonly sfaaCodeService: SfaaCodeService,
        private readonly underwritingConfigurationService: UnderwritingConfigurationService,
        private readonly systemAccountService: SystemAccountService,
        private readonly $filter: IFilterService,
        debouceDelayer: IDebounceDelayer
    ) {
        this.table = new Table(debouceDelayer);
    }

    public cacheStore: CacheStore;
    public widget: DashboardWidget;
    public busyIndicator: BusyIndicator;
    public table: Table<BondType, null>;
    public recordsPerPage: number;
    public orderby: string;
    public currentPage: number;
    public filterCollection: ODataFilterCollection;
    public errorMessage: string;
    public sfaaCodeOptions: SelectOption<string>[];
    public tagOptions: SelectOption<string>[];
    public carrierOptions: SelectOption<number>[];

    public setFilters = () => {
        
        this.filterCollection = new ODataFilterCollection([
            this.odata.getFilter("name", ODataFilterDataType.string, 'Bond Type',null,null,true,null,null,null),            
            this.odata.getFilter('sfaaCode/code', ODataFilterDataType.select, 'SFAA Code', null,null,true,this.sfaaCodeOptions,true, null),            
            this.odata.getFilter('obligee/requisitioningState', ODataFilterDataType.state, 'State',null,null,true,null,null, null),
            this.odata.getFilter('isActive', ODataFilterDataType.bool, 'Bond Type Status', null,null, true,[{ label: 'Active', value: 'true' }, { label: 'Inactive', value: 'false' }],true, null),
            this.odata.getFilter('underwritingConfigurations/any()',ODataFilterDataType.bool,'Has U/W Configs',null,null,true,[ {label: 'Yes', value: 'true'}, {label: 'No', value: 'false'}], true, null),
        ],
        this.$filter);
    }

    public saveRecordsPerPage = () => {
        this.widget.options.recordsPerPage = this.table.pager.recordsPerPage;
        this.dashboardService.save();
    }

    public saveOrderby = () => {
        this.widget.options.orderby = this.table.sorter.sortBy;
        this.dashboardService.save();
    }
 
    public loadGrid = () => {
        if (this.table.sorter.sortBy !== this.widget.options.orderby) {
            this.saveOrderby();
        }

        if (this.table.pager.recordsPerPage != this.widget.options.recordsPerPage) {
            this.saveRecordsPerPage();
        }

        this.busyIndicator.promise = this.underwritingConfigurationService.getDashboardWidgetUnderwritingConfigurations(
                this.table.sorter.sortBy,
                this.table.pager.currentPage,
                this.table.pager.recordsPerPage,
                this.filterCollection,
            ) 
            .then((response) => {
                this.table.setData(response.data, response.totalRecordCount);
            })
            .catch(() => {
                this.errorMessage = "An error occurred trying to get the widget data";
            });
    }

    public loadSfaaCodes = () => {
        return this.sfaaCodeService.getAllSfaaCodeOptions()
            .then((options) => {
                this.sfaaCodeOptions = options;
            });
    }

    public loadTagOptions = () => {
        return this.tagService.loadTagOptions()
            .then((tagOptions) => {
                this.tagOptions = tagOptions;
            });
    }

    public loadCarriers = () => {
        return this.systemAccountService.getAppointedCarrierOptions()
            .then((options) => {
                this.carrierOptions = options;
            });
    }

    public $onInit = () => {
        this.busyIndicator = {
            message: 'Loading...'
        };

        this.sfaaCodeOptions = [];
        this.carrierOptions = [];
        this.tagOptions = [];

        this.loadTagOptions();
        this.loadSfaaCodes();
        this.loadCarriers();

        if (isDefined(this.widget.options.filters)) {
            this.filterCollection.mergeFilters(this.widget.options.filters);
        }        

        this.setFilters();

        this.loadGrid();

        this.dashboardService.refreshFunctions.push(this.loadGrid);

        this.table.onChange = this.loadGrid;
    }
}

const dashboardWidgetUnderwritingConfigurationsCarrierComponent = {
    bindings: {
        removeWidget: '&', 
        widget: '=', 
        cloneWidget: '&'
    },
    templateUrl: 'app/states/common/dashboard/widgets/underwritingConfigurationsCarrier/dashboardWidgetUnderwritingConfigurationsCarrier.html',
    controller: DashboardWidgetUnderwritingConfigurationsCarrierController,
    controllerAs: 'vm'
};

app.component('dashboardWidgetUnderwritingConfigurationsCarrier', dashboardWidgetUnderwritingConfigurationsCarrierComponent);