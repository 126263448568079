import { Customer } from "./customer";
import { IBaseEntity } from "./iBaseEntity";
import { Person } from "./person";

export interface CreditReport extends IBaseEntity { 
    arfOutput: string;
    cachedReport: boolean;
    creditPullDate: Date;
    customer: Customer;
    customerId: number;
    fico: number;
    isAutomated: boolean;
    iv: number[];
    nrs: number;
    person: Person;
    personId: number;
    textOutput: string;
    xmlOutput: string;
    reportNotFound: boolean;
    bureau: Bureau;
    isDecrypted: boolean;
    id: number;
    createdDateTime: Date;
}

export enum Bureau {	
    Experian = "Experian",
    EquifaxV6 = "EquifaxV6"
}