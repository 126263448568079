export interface ImportBondPreview {
    id: number;
    errorMessage: string;
    hasError: boolean;
    customerId: number;
    customerLookupCode: string;
    customerName: string;
    bondTypeId: number;
    bondType: string;
    term: number;
    bondNumber: string;
    bondAmount: number;
    effectiveDate: Date;
    expirationDate: Date;
    writingCompanyId: number;
    writingCompany: string;
    premium: number;
    commission: number;
    fees: number;
    outstandingBalance: number;
    commissionPercent: number;
    imported: boolean;
    alreadyExists: boolean;
}