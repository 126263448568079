import { Bond } from "../bond";
import { PaymentStatus } from "../payments/paymentStatus";
import { Customer } from "./customer";
import { IBaseEntity } from "./iBaseEntity";
import { InvoiceLineItem } from "./invoiceLineItem";
import { SystemAccount } from "./systemAccount";

export interface PaymentTransaction extends IBaseEntity {
    systemAccountId: number;
    accountNumber: string;
    amount: number;
    bond: Bond;
    bondId: number;
    city: string;
    comments: string;
    companyName: string;
    customer: Customer;
    paymentInstitutionName: string;
    customerId: number;
    email: string;
    firstName: string;
    invoiceLineItems: InvoiceLineItem[];
    lastName: string;
    message: string;
    paymentMethod: PaymentMethod;
    paymentStatus: PaymentStatus;
    paymentTransactionFee: number;
    phone: string;
    referenceNumber: string;
    responseCode: string;
    state: string;
    streetAddress: string;
    suiteAptNumber: string;
    systemAccount: SystemAccount;
    transactionId: string;
    zipCode: string;
    id: number;
    createdDateTime: Date;
    checkNumber?: string;
}

export enum PaymentMethod {
    CreditCard = "CreditCard",
    ACH = "ACH",
    Other = "Other",
    Return = "Return",
    Check = "Check",
    WireTransfer = "WireTransfer"
}
