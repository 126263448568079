import { IDirectiveFactory, IDirectiveLinkFn, IScope } from "angular";
import app from "../../main";
import Inputmask from "inputmask";

const ssnMaskDirective: IDirectiveFactory = () => {
    
    const link: IDirectiveLinkFn = (
        scope: IScope,
        elem: JQuery
    ) => {
        const mask = new Inputmask("999-99-9999");
        mask.mask(elem);
    }
    
    return {
        link: link
    };
}

app.directive('maskSsn', ssnMaskDirective);