import { IBaseEntity } from "./iBaseEntity";
import { MasterApplicationQuestion } from "./masterApplicationQuestion";
import { UnderwritingConfiguration } from "./underwritingConfiguration";

export interface UnderwritingQuestion extends IBaseEntity { 
    underwritingConfigurationId: number;
    masterApplicationQuestionId: number;
    requiredValue: string;
    isRequired: boolean;
    underwritingConfiguration: UnderwritingConfiguration;
    masterApplicationQuestion: MasterApplicationQuestion;
    id: number;
    createdDateTime: Date;
}
