import { BondTransaction } from "./bondTransaction";
import { Customer } from "./customer";
import { IBaseEntity } from "./iBaseEntity";
import { InvoiceLineItem } from "./invoiceLineItem";
import { InvoiceStatus } from "./invoiceStatus";

export interface Invoice extends IBaseEntity { 
    bondTransactions: BondTransaction[];
    comments: string;
    customer: Customer;
    customerId?: number;
    lineItems: InvoiceLineItem[];
    originalAmount: number;
    status: InvoiceStatus;
    totalAmountDue: number;
    totalAmountReceived: number;
    totalPaymentTransactionFee: number;
    eProducerAccountId?: number;
    invoiceNumber: number;
    id: number;
    createdDateTime: Date;
}