import { IFilterService, IScope, IAttributes, INgModelController, IDirectiveFactory } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";

const isIntegerDirective = ($filter: IFilterService) => {
    var FLOAT_REGEXP_1 = /^\$?\d+.(\d{3})*(\,\d*)$/; //Numbers like: 1.123,56
    var FLOAT_REGEXP_2 = /^\$?\d+,(\d{3})*(\.\d*)$/; //Numbers like: 1,123.56
    var FLOAT_REGEXP_3 = /^\$?\d+(\.\d*)?$/; //Numbers like: 1123.56
    var FLOAT_REGEXP_4 = /^\$?\d+(\,\d*)?$/; //Numbers like: 1123,56

    const link = function (
        scope: IScope, 
        elm: JQuery, 
        attrs: IAttributes, 
        ctrl: INgModelController) {

        if ('isString' in attrs) {
            return;
        }

        ctrl.$parsers.unshift(function (viewValue) {
            viewValue = viewValue.replace(/\,/g, '');
            if (FLOAT_REGEXP_1.test(viewValue)) {
                ctrl.$setValidity('float', true);
                return parseFloat(viewValue.replace('.', '').replace(',', '.'));
            } else if (FLOAT_REGEXP_2.test(viewValue)) {
                ctrl.$setValidity('float', true);
                return parseFloat(viewValue.replace(',', ''));
            } else if (FLOAT_REGEXP_3.test(viewValue)) {
                ctrl.$setValidity('float', true);
                return parseFloat(viewValue);
            } else if (FLOAT_REGEXP_4.test(viewValue)) {
                ctrl.$setValidity('float', true);
                return parseFloat(viewValue.replace(',', '.'));
            } else if (!viewValue) {
                ctrl.$setValidity('float', true);
                return undefined;
            } else {
                ctrl.$setValidity('float', false);
                return undefined;
            }
        });

        ctrl.$formatters.unshift(
            function (modelValue) {
                return $filter('number')(parseInt(modelValue), 0);
            }
        );
    };

    isIntegerDirective.$inject = [Injectables.$filter];

    return {
        require: 'ngModel',
        link: link
    };
}

app.directive('isInteger', isIntegerDirective as IDirectiveFactory);