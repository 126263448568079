import { ApplicationService } from "../../api/applicationService";
import { SystemAccountService } from "../../api/systemAccountService";
import { QuoteStatus, QuoteType, IndemnityRequirements } from "../../api/types/model/quote";
import { SystemAccount } from "../../api/types/model/systemAccount";
import { Injectables } from "../../configuration/injectables";
import { ModalOpener } from "../../modals/modalOpener";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import app from "../../main";
import { IScope, IQService, IPromise } from "angular";
import { BusyIndicator } from "../busyIndicator/busyIndicator";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";
import { PurchaseQuoteResult } from "../../modals/purchaseQuoteModal/PurchaseQuoteResult";
import QuoteListItem from "./quoteListItem";

class QuoteListController {

    public static $inject = [
        Injectables.ApplicationService,
        Injectables.SystemAccountService,
        Injectables.CurrentUserResolver,
        Injectables.ModalOpener,
        Injectables.$scope,
        Injectables.$q,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly applicationService: ApplicationService,
        private readonly systemAccountService: SystemAccountService,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly modalOpener: ModalOpener,
        private readonly $scope: IScope,
        private readonly $q: IQService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {
    }

    public quotes: QuoteListItem[];
    public filteredQuotes: QuoteListItem[];
    public showBestQuotes: boolean;
    public quotesUpdated: () => object;
    public applicationId: number;
    public showAddButton: boolean;
    public isCarrier: boolean;
    public onPurchase: (object) => object;
    public isDisabled: boolean;
    public ngDisabled: boolean;
    public isApplicationDeclined: boolean;
    public showAutoQuoteButton: boolean;
    public busyIndicator: BusyIndicator;
    public onAutoQuoteClicked: () => void;

    public filterQuotes(): IPromise<QuoteListItem[]> {
        if (!this.quotes) {
            return this.$q.reject();
        }

        let noApprovedQuotes = true;
        for (const quote of this.quotes) {
            if (quote.status === QuoteStatus.Approved) {
                noApprovedQuotes = false;
                break;
            }
        }

        if (noApprovedQuotes || !this.showBestQuotes) {
            return this.$q.resolve(this.quotes);
        } else {
            return this.applicationService.getBestQuotes(this.quotes);
        }
    }

    public performFilter(): IPromise<any> {
        return this.filterQuotes()
            .then((quotes: QuoteListItem[]) => {
                this.filteredQuotes = quotes;
                for (const quote of this.filteredQuotes) {
                    this.initQuote(quote as QuoteListItem);
                }
            });
    }
    
    public get emailQuotesToolTip(): string {
        return "You must have at least 1 approved quote to email";
    }

    public get disableEmailQuotesButton(): boolean {
        return !this.quotes || this.quotes.every((quote) => quote.status !== QuoteStatus.Approved);
    }

    public get showEmailButton(): boolean {
        return this.quotes && this.quotes.every((quote) => quote.quoteType === QuoteType.Renewal);
    }

    public showEmailModal(): void {
        if (this.disableEmailQuotesButton) {
            return;
        }

        this.modalOpener.emailApplicationModal(this.applicationId)
            .result
            .then(() => {})
            .catch(() => {});
    }

    public performDelete(quoteId: number): IPromise<any> {
        return this.applicationService.deleteQuote(quoteId).then(() => {
            this.filteredQuotes.filter((quote: QuoteListItem) => quote.id !== quoteId);
        });
    }

    public downloadQuoteDocument(quoteId: number): void {
        this.modalOpener.showQuoteDocumentsModal(quoteId);
    }

    public showDeleteConfirmation(quote: QuoteListItem, $index: number): void {
        if (!quote) {
            throw new Error('quote is not valid');
        }
        if ($index === undefined || $index < 0) {
            throw new Error('$index is not valid');
        }
        if (!quote['canEdit'] && !this.currentUserResolver.getCurrentUser().systemAccount.isCarrier) {
            throw new Error('user is not a Carrier, or quote is un-editable');
        }

        this.modalOpener
            .deleteConfirmationModal(
                'Delete Quote',
                'Are you sure you want to delete this quote?',
                quote.id,
                null,
                this.applicationService.deleteQuote.bind(this.applicationService),
                'The quote has been deleted.',
                'There was an error deleting your quote.')
            .result
            .then(() => {

                for (let i = 0; i < this.quotes.length; i++) {
                    if (this.quotes[i].id === quote.id) {
                        this.quotes.splice(i, 1);
                    }
                }

                if (this.quotesUpdated) {
                    this.quotesUpdated();
                }

                return this.performFilter();
            })
            .catch(() => {});
    }

    public showQuoteModal(quote: QuoteListItem): void {
        const editMode = quote && quote.id;
        const quoteId = editMode ? quote.id : null;

        this.modalOpener.editQuoteModal(this.applicationId, quoteId)
            .result
            .then((savedQuote: QuoteListItem) => {
                if (editMode) {
                    for (let i = 0; i < this.quotes.length; i++) {
                        if (this.quotes[i].id === quote.id) {
                            this.quotes[i] = savedQuote;
                        }
                    }
                } else {
                    this.quotes.push(savedQuote);
                }

                if (this.quotesUpdated) {
                    this.quotesUpdated();
                }

                return this.performFilter();
            })
            .catch(() => {});
    }

    public setAddButtonStatus(): void {
        this.showAddButton = true;
    }

    public showIndemnityModal(quote: QuoteListItem): void {
        this.modalOpener.indemnityModal(quote.id)
            .result
            .then(() => {})
            .catch(() => {});
    }

    public showPurchaseModal(quoteId: number): void {
        if (!quoteId) {
            throw new Error('quoteId is not valid');
        }

        this.modalOpener.purchaseQuoteModal(quoteId)
            .result
            .then((result: PurchaseQuoteResult) => {
                if (this.onPurchase) {
                    this.onPurchase({ bondId: result.bondId });
                }
            })
            .catch(() => {});
    }
    
    public autoQuote = () => {
        this.busyIndicator.promise = this.applicationService.autoQuoteApplication(this.applicationId)
            .then(() => {
                if (typeof this.onAutoQuoteClicked === 'function') {
                    this.onAutoQuoteClicked();
                }
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred while trying to auto quote the renewal application');
            });
    }

    public initQuote(quote: QuoteListItem): IPromise<void> {
        if (!quote) {
            throw new Error('quote is not valid');
        }

        quote['$canPurchase'] = quote.status === QuoteStatus.Approved;
        quote['$canSendIndemnity'] = quote.indemnityRequirements !== IndemnityRequirements.None;

        return this.systemAccountService.getSmallLogo(quote.carrierSystemAccountId).then((dataUrl: string) => {
            quote['$carrierLogoUrl'] = dataUrl;
        });
    }

    public $onInit(): void {
        this.busyIndicator = {};

        this.isDisabled = this.isDisabled || false;
        this.isCarrier = this.currentUserResolver.getCurrentUser().systemAccount.isCarrier;

        this.setAddButtonStatus();

        this.$scope.$watch('vm.quotes', () => {
            this.busyIndicator.promise = this.performFilter();
        });

        this.$scope.$watch('vm.showBestQuotes', () => {
            this.busyIndicator.promise = this.performFilter();
        });
    }
}

const quoteListComponent = {
    templateUrl: 'app/components/quoteList/quoteList.html',
    bindings: { 
        quotes: '=', 
        ngDisabled: '=?', 
        applicationId: '<',
        quotesUpdated: '&', 
        onPurchase: '&', 
        isApplicationDeclined: '=',
        showAutoQuoteButton: '=',
        onAutoQuoteClicked: '&?'
    },
    controller: QuoteListController,
    controllerAs: 'vm'
};

app.component('quoteList', quoteListComponent);