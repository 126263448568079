import { Type } from "./type";
import { City } from "./city";
import { GeoReturnCodes } from "./geoReturnCodes";

export interface USGeoCode { 
    geoSMSA: string;
    geoStateCode: string;
    geoCountyCode: string;
    geoCensusTract: string;
    geoSuffix: string;
    geoBlockGroup: string;
    streetNumber: string;
    streetName: string;
    streetType: string;
    city: City;
    state: string;
    postalCode: string;
    type: Type;
    geoReturnCodes: GeoReturnCodes;
    microVisionCode: string;
    microVisionReturnCode: string;
}