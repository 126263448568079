import { IHttpPromise, IPromise } from "angular";
import { ODataFactory, ODataEndpoint } from "../../../api/odata";
import { CarrierAppointment } from "../../../api/types/model/carrierAppointment";
import { SystemAccount } from "../../../api/types/model/systemAccount";
import { Injectables } from "../../../configuration/injectables";
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { State } from "../../state";
import { AgencyAppointmentsDetailParams } from "./agencyAppointmentsDetailParams";

export class AgencyAppointmentsDetailController {
    public static $inject = [
        Injectables.ODataFactory,
        Injectables.$stateParams,
        Injectables.CurrentUserResolver,
        Injectables.$state,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private odata: ODataFactory,
        private $stateParams: AgencyAppointmentsDetailParams,
        private currentUserResolver: CurrentUserResolver,
        private $state: State,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {}

    public isNewAppointment: boolean;
    public selectedAgency: SystemAccount;
    public formSubmitted: boolean;
    public item: CarrierAppointment;
    public systemAccountSearch: SystemAccount[];

    public $onInit(): void {
        this.isNewAppointment = this.$stateParams.id ? false : true;
        this.formSubmitted = false;
        this.item = { isActive: true } as CarrierAppointment;
        this.systemAccountSearch = [];

        if (!this.isNewAppointment) {
            this.loadExisting(this.$stateParams.id);
        }
    }

    public loadExisting(id: number): void {
        const svc = this.odata.getService<CarrierAppointment>(
            ODataEndpoint.CarrierAppointment
        );

        svc.query.expand("agencySystemAccount");
        svc.query.filter(`id eq ${id}`);

        svc.get().then((response) => {
            this.item = response.data.value[0];

            // show on UI as percent but store as decimal (35.0 vs .35)
            this.item.defaultCommissionPercent =
                this.item.defaultCommissionPercent * 100;
            this.item.renewalCommissionPercent =
                this.item.renewalCommissionPercent * 100;
        });
    }

    public searchAgents(search: string): IPromise<void> {
        const svc = this.odata.getService<SystemAccount>(
            ODataEndpoint.SystemAccount
        );

        return svc
            .collectionFunction<SystemAccount>(
                `A3.AvailableAppointmentSystemAccounts?$filter=contains(companyName, '${search}') and accountType ne 'Carrier'&$top=10`
            )
            .then((response: any) => {
                this.systemAccountSearch = response.data.value;
            });
    }

    public save(): void {
        if (this.isNewAppointment) {
            this.item.agencySystemAccountId = this.selectedAgency.id;
            this.item.carrierSystemAccountId =
                this.currentUserResolver.getCurrentUser().user.systemAccountId;
        }

        this.item.defaultCommissionPercent =
            this.item.defaultCommissionPercent * 0.01;
        this.item.renewalCommissionPercent =
            this.item.renewalCommissionPercent * 0.01;

        this.saveAppointment().then(() => {
            this.savedSuccessfully();
        });
    }

    private savedSuccessfully(): void {
        this.$state.go("main.agencyAppointments");
        this.toastMessageCreator.createSuccessMessage(
            "Agency appointment saved successfully"
        );
    }

    public saveAppointment(): IHttpPromise<SystemAccount> {
        const svc = this.odata.getService<CarrierAppointment>(
            ODataEndpoint.CarrierAppointment
        );

        if (this.isNewAppointment) {
            return svc.post<SystemAccount>(this.item);
        } else {
            return svc.put<SystemAccount>(this.item.id, this.item);
        }
    }
}
