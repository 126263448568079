import { MarketMaxInd } from "./marketMaxInd";
import { RejectCode } from "./rejectCode";
import { ScoreReasons } from "./scoreReasons";

export interface USModelScore { 
    scoreFormatType: string;
    modelNumber: string;
    modelId: string;
    scoreNumber: string;
    marketMaxInd: MarketMaxInd;
    scoreResult: string;
    scoreReasons: ScoreReasons;
    rejectCode: RejectCode;
    riskBasedPricing: string;
    doddFrank: string;
    factActInquiriesAreKeyFactor: string;
}