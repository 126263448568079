import app from "../../main";

const headersInterceptor = () => {

    return (config) => {
        config.headers = config.headers || {};
        config.headers.Accept = 'application/json;odata.metadata=none, text/plain, */*';
        
        return config;
    }
}

app.factory('headersInterceptor', headersInterceptor)
