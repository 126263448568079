import { IBaseEntity } from "./iBaseEntity";
import { SystemAccount } from "./systemAccount";
import { Document } from "./document";

export interface SystemAccountDocument extends IBaseEntity { 
    documentId: number;
    systemAccountId: number;
    document: Document;
    systemAccount: SystemAccount;
    id: number;
    createdDateTime: Date;
}