import { CustomerId } from "./customerId";
import { DateOfInquiry } from "./dateOfInquiry";
import { InquiryAbbreviation } from "./inquiryAbbreviation";
import { InquiryIntent } from "./inquiryIntent";
import { PermissiblePurposeCode } from "./permissiblePurposeCode";

export interface USInquiry { 
    dateOfInquiry: DateOfInquiry;
    inquiryAbbreviation: InquiryAbbreviation;
    endUserText: string;
    customerId: CustomerId;
    inquiryIntent: InquiryIntent;
    permissiblePurposeCode: PermissiblePurposeCode;
}