import { SelectOption } from "../../api/types/selectOption";
import { TablePagerOptions } from "./tablePagerOptions";

export class TablePager {

    constructor() {
        this.recordsPerPageOptions = [
            { value: 10, label: '10' },
            { value: 20, label: '20' },
            { value: 50, label: '50' }
        ];

        this.recordsPerPage = 10;
        this.currentPage = 1;
    }

    public recordsPerPageOptions: SelectOption[];
    public recordsPerPage: number;
    public currentPage: number;
    public totalRecordCount: number;
    public onPageChange: (model: TablePagerOptions) => void;

    public get totalRecordsCountFormatted(): string {
        if (this.totalRecordCount !== null && this.totalRecordCount !== undefined) {
            return this.totalRecordCount.toLocaleString();
        } else {
            return '';
        }
    }

    public get isVisible(): boolean {
        return this.totalRecordCount > 0;
    }

    public setRecordPerPage(selectedOption) {
        this.recordsPerPage = selectedOption.value;
        this.currentPage = 1;
        this.pageOptionsChanged();
    }

    public pageOptionsChanged() {
        if (typeof this.onPageChange === 'function') {
            this.onPageChange({
                recordsPerPage: this.recordsPerPage,
                pageNumber: this.currentPage
            });
        }
    }

    public get totalPages(): number {
        return Math.ceil(this.totalRecordCount / this.recordsPerPage);
    }
}

