import * as angular from "angular";
import { IJQueryService } from "../../../utilities/jquery/iJQueryService";
import fieldGroupWrapper from "../fieldGroupWrapper";

export default (element: JQuery, jQueryProvider: IJQueryService) => {
    const wrapperClass = element.attr("aa-wrapper-class") || "field-wrapper";
    const aaFgClass = element.attr("aa-fg-class") || "";
    const label = element.attr("aa-label") || "";
    const inputWrapperclass = element.attr("aa-input-wrapper-class") || "";

    const id = element.attr("id") || "";

    let ngRequired = element.attr("ng-required") || "";
    if (ngRequired.length > 0) {
        ngRequired = `ng-required="${ngRequired}"`;
    }

    let ngshow = element.attr("ng-show") || "";
    if (ngshow.length > 0) {
        ngshow = `ng-show="${ngshow}"`;
    }

    let ngchange = element.attr("ng-change") || "";
    if (ngchange.length > 0) {
        ngchange = `ng-change="${ngchange}"`;
    }

    const field = angular.element(`
        <label 
            class="radio-option flex ${aaFgClass}"
            ng-repeat="opt in ${element.attr("options")}" >

            <input 
                value="{{opt.value}}" 
                type="radio" 
                class="v-center ${inputWrapperclass}"
                ng-model="$parent.${element.attr("aa-field")}" 
                ${ngRequired} 
                ${ngchange} 
                id="${id}-{{opt.value}}" /> 
            
            <span class="radio-label">{{opt.label}}</span>
        </label>
    `);

    element.replaceWith(field);

    const wrapperElementHtml = `
        <div 
            class="${wrapperClass}" 
            ${ngshow}>
            
            <label class="v-center">
                ${label}
            </label>
            
            <input />
        </div>`;

    fieldGroupWrapper(field, wrapperElementHtml, jQueryProvider);
};
