import { IJQueryService } from "../../../utilities/jquery/iJQueryService";
import defaultWrapper from "../defaultWrapper";
import fieldGroupWrapper from "../fieldGroupWrapper";

export default (element: JQuery, jQueryProvider: IJQueryService) => {

    const value = element.attr('aa-option-value') ? element.attr('aa-option-value') : 'value';
    const text = element.attr('aa-option-text') ? element.attr('aa-option-text') : 'label';
    const field = element.attr('aa-field');
    const options = element.attr('options');

    element.attr('is-select', '');

    const unselectedText = element.attr('aa-unselected-text') ? element.attr('aa-unselected-text') : '';
    if (unselectedText) {
        const required = element.attr('ng-required') || element.attr('required');
        element.append('<option ng-selected="!' + field + ' || ' + field + ' == undefined" value="" ' + (required ? 'disabled' : '') + ' >' + unselectedText + '</option>');
    }

    element.attr('ng-class', "{'unselected' : !" + element.attr('aa-field') + "}");
    
    element.append(
        '<option ' +
            'ng-selected="' + field + ' == opt.' + value + '" '+
            'value="{{opt.' + value + '}}" ' +
            'ng-repeat="opt in ' + options + '" ' + 
            'ng-disabled="opt.isDisabled"> ' +
            
            '{{opt.' + text + '}}' +
        '</option>'
    );

    const aaFgClass = element.attr('aa-fg-class') ? element.attr('aa-fg-class') + ' select' : 'select';
    element.attr('aa-fg-class', aaFgClass);

    fieldGroupWrapper(element, defaultWrapper(element), jQueryProvider);
};