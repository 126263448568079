import { ISCEService } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { ITextHighlighter } from "./iTextHighlighter";

export class AngularTextHighlighter implements ITextHighlighter {
    
    static $inject = [
        Injectables.$sce
    ];

    constructor(
        private readonly $sce: ISCEService
    ) {}

    public highlight = (text: string, search: string): string => {
        if (!search) {
            return this.$sce.trustAsHtml(text);
        }

        const matchingRegEx = new RegExp(search, 'gi');
        const replacementString = '<span class="highlightedText">$&</span>';
        const htmlResult = text.replace(matchingRegEx, replacementString);

        return this.$sce.trustAsHtml(htmlResult);
    };
}

app.service(Injectables.ITextHighlighter, AngularTextHighlighter);