import { IDirectiveFactory, IDirectiveLinkFn, IScope } from "angular";
import app from "../../main";
import Inputmask from "inputmask";

const urlMaskDirective: IDirectiveFactory = () => {

    const link: IDirectiveLinkFn = (
        scope: IScope,
        elem: JQuery
    ) => {
        const mask = new Inputmask("\\http://*{+}", {
            definitions: {
                '*': {
                    validator: "[0-9A-Za-z\.!#$%&'*+/=?^_`{|}~\-]",
                    cardinality: 1,
                    casing: "lower"
                }
            }
        });

        mask.mask(elem);
    }

    return {
        link: link
    }
}

app.directive('maskUrl', urlMaskDirective);