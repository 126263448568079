import * as angular from "angular";
import { ActivityService } from "../../../../../api/activityService";
import { ActivityListItem } from "../../../../../components/activityList/activityListItem";
import { ActivityTableQueryOptions } from "../../../../../components/activityList/activityTableQueryOptions";
import { BusyIndicator } from "../../../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../../../configuration/injectables";
import app from "../../../../../main";
import { IDebounceDelayer } from "../../../../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../../../../utilities/tables/table";
import { DashboardService } from "../../dashboardService";
import { DashboardWidget } from "../../types/dashboardWidget";

class ActivityListWidgetController {

    public static $inject = [
        Injectables.IDebouceDelayer,
        Injectables.ActivityService,
        Injectables.DashboardService
    ];

    constructor (
        debouceDelayer: IDebounceDelayer,
        private readonly activityService: ActivityService,
        private readonly dashboardService: DashboardService
    ) {
        this.activitiesTable = new Table(debouceDelayer);
    }

    public widget: DashboardWidget;
    public readonly activitiesTable: Table<ActivityListItem, ActivityTableQueryOptions>;
    public busyIndicator: BusyIndicator;

    private tableQueryOptionsChanged = () => {
        this.loadActivities();
        
        this.widget.options.tableQueryOptions = {...this.activitiesTable.queryOptions};

        this.dashboardService.save();
    }

    private loadActivities = () => {
        this.busyIndicator.promise = this.activityService.getAllActivities(this.activitiesTable.queryOptions)
            .then((response) => {
                this.activitiesTable.setData(response.items, response.totalRecordCount);
            });
    }

    public $onInit() {
        this.busyIndicator = {
            message: 'Loading Activities...'
        };

        if (this.widget.options && this.widget.options.tableQueryOptions) {
            this.activitiesTable.applyFilters({ ...this.widget.options.tableQueryOptions });
            this.activitiesTable.sorter.sort(this.widget.options.tableQueryOptions.orderBy);
        }

        this.activitiesTable.onChange = this.tableQueryOptionsChanged;
        this.dashboardService.refreshFunctions.push(this.loadActivities);
        this.loadActivities();
    }
}

const dashboardWidgetActivityListComponent = {
    templateUrl: 'app/states/common/dashboard/widgets/activityList/activityListWidget.html',
    bindings: {
        widget: '='
    },
    controllerAs: 'vm',
    controller: ActivityListWidgetController
}

app.component('dashboardWidgetActivityList', dashboardWidgetActivityListComponent); 