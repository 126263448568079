export interface RelatedCustomerCardData {
    id: number;
    isParentCompany: boolean;
    parentCompanyId?: number;
    isEmployer: boolean;
    employerCustomerId?: number;
    subsidiaryCompanyCount: number;
    parentCompanyName?: string;
    parentCompanyLookupCode?: string;
    parentCompanyComments?: string;
    sisterCompanyCount: number;
    employeeCustomerCount: number;
    employerCustomerName?: string;
    employerCustomerLookupCode?: string;
    employerCustomerComments?: string;
    relatedEmployeeCustomerCount: number;
}