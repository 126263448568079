    export type GetBondTypeLeaderBoardDto = {
        score: number;
        userId: number;
        userFullName: string;
        added: number;
        isActive: number;
        reviewSfaaCode: number;
        reviewCancellation: number;
        reviewObligee: number;
        reviewRenewal: number;
        reviewExpiration: number;
        reviewApplicationSettings: number;
        reviewBondAmount: number;
        reviewAppQuestions: number;
        reviewAdditionalInformation: number;
        formAdded: number;
        documentMapped: number;
        companyName: string;
        qaCount: number;
    }