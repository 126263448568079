import { ODataFactory, ODataEndpoint } from "../../api/odata";
import { SystemAccountContact } from "../../api/types/model/systemAccountContact";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { SystemAccountContactOptions } from "./systemAccountContactModalOptions";
import app from "../../main";
import A3ApiResponse from "../../api/types/a3ApiResponse";
import { IPromise } from "angular";

export class ContactModalController {

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.ODataFactory,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly $uibModalInstance: Modal<SystemAccountContact>,
        private readonly options: SystemAccountContactOptions,
        private readonly odata: ODataFactory,
        private readonly toastMessageCreator: ToastMessageCreator) {
    }

    public contact: SystemAccountContact;
    public processingPromise: IPromise<void>;

    public $onInit(): void {
        this.contact = this.options.contact;
    }

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }

    public save(): void {
        const svc = this.odata.getService<SystemAccountContact>(ODataEndpoint.SystemAccountContact);
        this.processingPromise = svc
            .save<A3ApiResponse<SystemAccountContact>>(this.contact)
            .then((response) => {
                const savedContact = response.data.value;

                if (savedContact && savedContact.id) {
                    this.contact.id = savedContact.id;
                }

                this.$uibModalInstance.close(this.contact);
                this.toastMessageCreator.createSuccessMessage('Contact saved successfully');
            });
    }
}

app.controller('ContactModalController', ContactModalController);
