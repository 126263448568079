import { IQService, IHttpService, IPromise } from "angular";
import { InvoiceListItem } from "../components/invoiceList/invoiceListItem";
import { InvoiceTableQueryOptions } from "../components/invoiceList/invoiceTableQueryOptions";
import { InvoiceReportFilterOptions } from "../states/agentSpecific/invoices/invoiceReportFilterDropdown/invoiceReportFilterOptions";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/settings/systemSettings";
import { FileDownloader } from "./fileDownloader";
import app from "../main";
import A3ApiResponse from "./types/a3ApiResponse";
import { InvoiceSearchResult } from "./types/invoices/invoiceSearchResult";
import { NewInvoiceRequest } from "./types/invoices/newInvoiceRequest";
import { InvoiceReportItem } from "../states/agentSpecific/invoices/invoiceReportItem";
import { UnbilledBondTransaction } from "./types/invoices/unbilledBondTransaction";
import { PageResponse } from "./types/pageResponse";
import InvoiceDetail from "../modals/invoiceModal/invoiceDetail";

export class InvoiceService {
    public static $inject = [
        Injectables.$q,
        Injectables.$http,
        Injectables.FileDownloader,
        Injectables.SystemSettings
    ];

    constructor(
        private readonly $q: IQService,
        private readonly $http: IHttpService,
        private readonly fileDownloader: FileDownloader,
        private readonly systemSettings: SystemSettings
    ) {}

    public search(
        searchPhrase: string,
        customerId: number,
        eProducerAccountId: number
    ): IPromise<InvoiceSearchResult[]> {
        return this.$http.get<A3ApiResponse<InvoiceSearchResult[]>>(
                this.systemSettings.apiBaseUrl +
                    "InvoiceActions/GetInvoiceSearchResults?searchPhrase=" +
                    searchPhrase +
                    "&customerId=" +
                    customerId +
                    "&eProducerAccountId=" +
                    eProducerAccountId
            )
            .then((response) => response.data.value);
    }

    public loadInvoiceDetail(invoiceId: number): IPromise<InvoiceDetail> {
        const url = `${this.systemSettings.apiBaseUrl}InvoiceActions/GetInvoiceDetail?invoiceId=${invoiceId}`;

        return this.$http.get<A3ApiResponse<InvoiceDetail>>(url)
            .then((response) => response.data.value);
    }

    public getInvoiceReportData(tableQueryOptions: InvoiceReportFilterOptions): IPromise<PageResponse<InvoiceReportItem>> {
        const queryString = this.buildInvoiceReportQueryString(tableQueryOptions);
        const url = `${this.systemSettings.apiBaseUrl}InvoiceActions/GetInvoiceReport${queryString}`;

        return this.$http.get<A3ApiResponse<PageResponse<InvoiceReportItem>>>(url)
            .then((response) => response.data.value);
    }

    public downloadInvoiceExcelReport(tableQueryOptions?: InvoiceReportFilterOptions): IPromise<void> {
        const queryString = this.buildInvoiceReportQueryString(tableQueryOptions);
        const url = `${this.systemSettings.apiBaseUrl}InvoiceActions/GetInvoiceListExcelReport${queryString}`;

        return this.fileDownloader.downloadFile(url,null);
    }

    private buildInvoiceReportQueryString(tableQueryOptions: InvoiceReportFilterOptions) {
        if (!tableQueryOptions) {
            tableQueryOptions = {
                pageNumber: 1,
                recordsPerPage: 10,
                orderBy: "",
                searchPhrase: ""
            } as InvoiceReportFilterOptions;
        }

        if (!tableQueryOptions.searchPhrase) {
            tableQueryOptions.searchPhrase = "";
        }

        if (!tableQueryOptions.orderBy) {
            tableQueryOptions.orderBy = "Invoices.Id desc";
        }

        if (!tableQueryOptions.pageNumber) {
            tableQueryOptions.pageNumber = 1;
        }

        if (!tableQueryOptions.recordsPerPage) {
            tableQueryOptions.recordsPerPage = 10;
        }

        let queryString = "?";

        queryString += `&pageNumber=${tableQueryOptions.pageNumber}`;
        queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
        queryString += `&orderBy=${tableQueryOptions.orderBy}`;
        queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;

        if (tableQueryOptions.status) {
            queryString += `&status=${tableQueryOptions.status}`;
        }

        if (tableQueryOptions.minimumAmount) {
            queryString += `&minimumAmount=${tableQueryOptions.minimumAmount}`;
        }

        if (tableQueryOptions.maximumAmount) {
            queryString += `&maximumAmount=${tableQueryOptions.maximumAmount}`;
        }

        if (tableQueryOptions.startDate) {
            queryString += `&startDate=${tableQueryOptions.startDate}`;
        }

        if (tableQueryOptions.endDate) {
            queryString += `&endDate=${tableQueryOptions.endDate}`;
        }

        return queryString;
    }

    public mergeInvoices(invoiceIds: number[]): IPromise<void> {
        return this.$http
            .post<A3ApiResponse<void>>(
                this.systemSettings.apiBaseUrl + "InvoiceActions/MergeInvoices",
                { invoiceIds }
            )
            .then(() => {});
    }

    public voidInvoice(invoiceIds: number[]): IPromise<void> {
        return this.$http
            .post<A3ApiResponse<void>>(
                this.systemSettings.apiBaseUrl + "InvoiceActions/VoidInvoices",
                { invoiceIds }
            )
            .then(() => {});
    }

    public createInvoice(invoice: NewInvoiceRequest): IPromise<number> {
        return this.$http
            .post<A3ApiResponse<number>>(
                this.systemSettings.apiBaseUrl + "InvoiceActions/CreateInvoice",
                invoice
            )
            .then((response) => response.data.value);
    }

    public getInvoiceDocumentUrl(id: number): string {
        return (
            this.systemSettings.apiBaseUrl +
            "DataDocument/GetInvoiceDocument?invoiceId=" +
            id
        );
    }

    public getInvoicesAmountDue(invoiceIds: number[]): IPromise<number> {
        if (!invoiceIds || !invoiceIds.length) {
            return this.$q.when(null);
        }

        let queryString = "";
        for (let i = 0; i < invoiceIds.length; i++) {
            queryString += "invoiceIds=" + invoiceIds[i].toString() + "&";
        }

        return this.$http
            .get<A3ApiResponse<number>>(
                this.systemSettings.apiBaseUrl +
                    "InvoiceActions/GetAmountDue?" +
                    queryString
            )
            .then((response) => response.data.value);
    }

    public getBillableItemsForNewInvoice(
        customerId: number,
        eProducerAccountId: number
    ): IPromise<UnbilledBondTransaction[]> {
        return this.$http
            .get<A3ApiResponse<UnbilledBondTransaction[]>>(
                this.systemSettings.apiBaseUrl +
                    "InvoiceActions/GetBillableItemsForNewInvoice?customerId=" +
                    customerId +
                    "&eProducerAccountId=" +
                    eProducerAccountId
            )
            .then((response) => response.data.value);
    }

    public getInvoiceListItems(
        tableQueryOptions: InvoiceTableQueryOptions
    ): IPromise<PageResponse<InvoiceListItem>> {
        if (!tableQueryOptions) {
            tableQueryOptions = {
                pageNumber: 1,
                recordsPerPage: 10,
                orderBy: "",
                searchPhrase: ""
            };
        }

        let queryString = "?";

        if (tableQueryOptions.customerId) {
            queryString += `&customerId=${tableQueryOptions.customerId}`;
        }

        if (tableQueryOptions.eProducerAccountId) {
            queryString += `&eProducerAccountId=${tableQueryOptions.eProducerAccountId}`;
        }

        queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
        queryString += `&pageNumber=${tableQueryOptions.pageNumber}`;

        if (tableQueryOptions.status) {
            queryString += `&status=${tableQueryOptions.status}`;
        }

        if (tableQueryOptions.amountDueMinimum) {
            queryString += `&amountDueMinimum=${tableQueryOptions.amountDueMinimum}`;
        }

        if (tableQueryOptions.amountDueMaximum) {
            queryString += `&amountDueMaximum=${tableQueryOptions.amountDueMaximum}`;
        }

        if (tableQueryOptions.orderBy) {
            queryString += `&orderBy=${tableQueryOptions.orderBy}`;
        }

        if (!tableQueryOptions.searchPhrase) {
            tableQueryOptions.searchPhrase = "";
        }

        queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;

        return this.$http
            .get<A3ApiResponse<PageResponse<InvoiceListItem>>>(
                `${this.systemSettings.apiBaseUrl}InvoiceActions/GetInvoiceListItems${queryString}`
            )
            .then((response) => response.data.value);
    }
}

app.service(Injectables.InvoiceService, InvoiceService);
