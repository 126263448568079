import { ApplicationFollowUp } from "./applicationFollowUp";
import { ApplicationFollowUpStrategiesBondTypes } from "./applicationFollowUpStrategiesBondTypes";
import { ApplicationFollowUpStrategiesSfaaCodes } from "./applicationFollowUpStrategiesSfaaCodes";
import { ApplicationFollowUpStrategyEmail } from "./applicationFollowUpStrategyEmail";
import { EmailTemplate } from "./emailTemplate";
import { IBaseEntity } from "./iBaseEntity";
import { SystemAccount } from "./systemAccount";

export interface ApplicationFollowUpStrategy extends IBaseEntity { 
    name: string;
    comments: string;
    systemAccountId: number;
    isDefault: boolean;
    submissionEmailTemplateId: number;
    purchaseEmailTemplateId: number;
    prospectCreationEmailTemplateId: number;
    submissionEmailTemplate: EmailTemplate;
    purchaseEmailTemplate: EmailTemplate;
    prospectCreationEmailTemplate: EmailTemplate;
    strategyType: ApplicationFollowUpStrategyType;
    systemAccount: SystemAccount;
    applicationFollowUpStrategiesBondTypes: ApplicationFollowUpStrategiesBondTypes[];
    applicationFollowUpStrategiesSfaaCodes: ApplicationFollowUpStrategiesSfaaCodes[];
    emails: ApplicationFollowUpStrategyEmail[];
    applicationFollowUps: ApplicationFollowUp[];
    id: number;
    createdDateTime: Date;
}

export enum ApplicationFollowUpStrategyType {	
    Application = "Application",
    Prospect = "Prospect"
}