import app from "../../main";

export const objectNameToText = () => {

    return (objectName: string): string => {

        return objectName
            .replace(/([a-z])([A-Z])/g, '$1 $2')   
            .replace(/([0-9])/g, ' $1 ') // "FirstName1" to "FirstName 1 "
            .replace(/([A-Z])([A-Z])([A-Z])([A-Z])([a-z])/g, '$1$2$3 $4$5') // "SFAACode" to "SFAA Code"
            .replace(/([A-Z])([A-Z])([A-Z])([a-z])/g, '$1$2 $3$4') // "AMBestRating" to "AM BestRating"
            .trim(); // Remove first last spaces
    }
}

app.filter('objectNameToText', objectNameToText);