import { Injectables } from "../../configuration/injectables";
import { FormatType } from "../physicalAddressFilter/physicalAddressFilter";
import app from "../../main";
import { ISCEService } from "angular";

export class EmployerAddressFilter {

    public static $inject = [
        Injectables.$sce
    ];

    public static Factory($sce: ISCEService): any {
        return (object?: any, formatType?: FormatType) => {
            if (!object) { return ''; }

            let address = '';

            switch (formatType) {
                case FormatType.TwoLine:
                    address = (object.employerAddress ? object.employerAddress : '') + ' ' +
                        (object.employerSuiteAptNumber ? object.employerSuiteAptNumber : '') + '<br/>' +
                        (object.employerCity ? object.employerCity : '') + ', ' +
                        (object.employerState ? object.employerState : '') + ' ' +
                        (object.employerZip ? object.employerZip : '') +
                        (object.employerCounty ? '<br/>' + object.employerCounty + ' county' : '');
                    break;
                case FormatType.Pipe:
                    address = (object.employerAddress ? object.employerAddress : '') + '{|}' +
                        (object.employerSuiteAptNumber ? object.employerSuiteAptNumber : '') + '{|}' +
                        (object.employerCity ? object.employerCity : '') + '{|}' +
                        (object.employerState ? object.employerState : '') + '{|}' +
                        (object.employerZip ? object.employerZip : '') + '{|}' +
                        (object.employerCounty ? object.employerCounty : '');
                    break;
                default:
                    address = (object.employerAddress ? object.employerAddress : '') + ' ' +
                        (object.employerSuiteAptNumber ? object.employerSuiteAptNumber : '') + ' ' +
                        (object.employerCity ? object.employerCity : '') + ', ' +
                        (object.employerState ? object.employerState : '') + ' ' +
                        (object.employerCounty ? object.employerCounty + ' county' : '');
                    break;
            }

            return $sce.trustAsHtml(address);
        };
    }
}

app.filter('employerAddress', EmployerAddressFilter.Factory);
