import { DocumentService } from "../../api/documentService";
import { DocumentCategory } from "../../api/types/model/documentCategory";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { IScope, ITimeoutService } from "angular";
import { SystemSettings } from "../../configuration/settings/systemSettings";
import DocumentSearchResult from "../../api/types/documents/documentSearchResult";


export interface FileUpdateMetadata {
    name: string;
    id: number;
    documentCategoryId: number;
}

export interface FileUploadMetadata {
    id: number;
    name: string;
    sent: number;
    total: number;
    category: string;
    categoryId: number;
}

export interface DocumentPicker {
    save: () => void;
}

class DocumentPickerController {
    public static fileIdCounter = 0;

    public documentCategory: DocumentCategory;
    public searchResults: DocumentSearchResult[] = [];
    public documents: DocumentPickerDocument[] = [];
    public dropzone: Dropzone;
    public showStatus: boolean;
    public placeholder: string;
    public allowMultipleSelections: boolean;
    public control: DocumentPicker;
    public documentFilter: 'PublicOnly' | 'PrivateOnly' | 'Any';

    private documentToReplace: FileUpdateMetadata;
    private replacedDocuments: Map<number, FileUpdateMetadata> = new Map<number, FileUpdateMetadata>();

    public onDocumentAdded: (args: { addedFile: FileUploadMetadata }) => void;
    public onDocumentSelected: (args: { selectedDocument: DocumentPickerDocument }) => void;
    public onDocumentUpdated: (args: { updatedFile: FileUpdateMetadata }) => void;
    public onDocumentRemoved: (args: { documentId: number }) => void;
    public onDocumentUploaded: (args: { file: FileUploadMetadata, response: number, updatedFile: FileUpdateMetadata }) => void;
    public onUploadProgressChanged: (args: { file: FileUploadMetadata }) => void;
    public onUploadCanceled: (args: { fileId: number }) => void;
    public onUploadQueueCompleted: () => void;

    public get defaultMessage(): string {
        if (this.placeholder) {
            return this.placeholder;
        } else {
            return `Use Default ${this.documentCategoryName}`;
        }
    }

    public get showDefault(): boolean {
        return (this.documents === undefined || this.documents.length === 0)
            && (this.dropzone === undefined || this.dropzone.files.length === 0);
    }

    public get documentCategoryName(): string {
        return this.documentCategory !== undefined ? this.documentCategory.name : '';
    }

    public get documentCategoryId(): number {
        return this.documentCategory !== undefined ? this.documentCategory.id : undefined;
    }

    public get documentCount(): number {
        let count = 0;

        if (this.documents) {
            count += this.documents.length;
        }

        if (this.dropzone && this.dropzone.files) {
            count += this.dropzone.files.length;
        }

        return count;
    }

    public get canAddDocument(): boolean {
        if (!this.allowMultipleSelections && this.documentCount > 0) {
            return false;
        } else {
            return true;
        }
    }

    public get selectedDocument(): DocumentPickerDocument {
        if (!this.searchResults) {
            return undefined;
        } else {
            return this.searchResults['selected'];
        }
    }

    public set selectedDocument(document: DocumentPickerDocument) {
        this.searchResults['selected'] = document;
    }

    public get isDocumentSelected(): boolean {
        return this.selectedDocument !== undefined;
    }

    public get showSearch(): boolean {
        return this.canAddDocument;
    }

    public static $inject = [
        Injectables.DocumentService,
        Injectables.$timeout,
        Injectables.SystemSettings,
        Injectables.$scope
    ];

    constructor(
        private documentService: DocumentService,
        private $timeout: ITimeoutService,
        private systemSettings: SystemSettings,
        private $scope: IScope
    ) { }

    public $onInit(): void {
        this.loadSelectableDocuments();
        this.setupDirectivePublicApi();
    }

    private loadSelectableDocuments(): void {
        const unsubscribe = this.$scope.$watch('vm.documentCategory', () => {
            if (this.documentCategory !== undefined) {
                unsubscribe();
                this.searchDocuments('');
            }
        });
    }

    public searchDocuments(search: string): void {
        if (!search || this.documentCategoryId === undefined) {
            return;
        }

        this.documentService.searchDocuments(search, this.documentCategoryId)
            .then((documents) => {
                this.searchResults = this.filterDocuments(documents);
            });
    }

    private filterDocuments(documents: DocumentSearchResult[]): DocumentSearchResult[] {
        if (this.documentFilter === 'PrivateOnly') {
            return documents.filter((d) => !d.isPublic);
        } else if (this.documentFilter === 'PublicOnly') {
            return documents.filter((d) => d.isPublic);
        } else {
            return documents;
        }
    }

    public onClick($select): void {
        $select.searchInput.on('blur', () => {
            $select.searchInput.val(null);
        });
    }

    private setupDirectivePublicApi(): void {
        if (!this.control) {
            this.control = {
                save: () => {
                    this.uploadNextFileInQueue();
                }
            };
        }
    }

    public addSelectedDocument(): void {
        if (!this.isDocumentSelected || !this.canAddDocument) {
            return;
        }

        const documentAlreadyAdded = this.documents.find((d) => d.id === this.selectedDocument.id) !== undefined;

        if (this.onDocumentSelected && !documentAlreadyAdded) {
            this.onDocumentSelected({
                selectedDocument: this.selectedDocument
            });
        }

        this.selectedDocument = undefined;
    }

    public uploadNewVersion(document: DocumentSearchResult): void {
        this.documentToReplace = {
            name: document.name,
            id: document.id,
            documentCategoryId: this.documentCategoryId
        };

        this.$timeout(() => {
            this.dropzone['clickableElements'][0].click();
        });
    }

    public removeDocument(document: DocumentSearchResult): void {
        this.cancelDocumentReplacement(document);

        if (this.onDocumentRemoved) {
            this.onDocumentRemoved({ documentId: document.id });
        }
    }

    private cancelDocumentReplacement(document: DocumentSearchResult): void {
        let replacementKey;

        this.replacedDocuments.forEach((value, key) => {
            if (value.id === document.id) {
                replacementKey = key;
            }
        });

        if (replacementKey) {
            this.replacedDocuments.delete(replacementKey);
        }

        for (let i = 0; i < this.dropzone.files.length; i++) {
            if (this.dropzone.files[i]['id'] === replacementKey) {
                this.cancelUpload(i);
                break;
            }
        }
    }

    public cancelUpload(fileIndex: number): void {
        if (this.onUploadCanceled) {
            this.onUploadCanceled({ fileId: this.dropzone.files[fileIndex]['id'] });
        }

        this.dropzone.removeFile(this.dropzone.files[fileIndex]);
    }

    public getDocumentUrl(document: DocumentSearchResult): string {
        return this.documentService.getDocumentUrl(document.id);
    }

    public getPreviewDocumentUrl(document: DocumentSearchResult): string {
        return this.documentService.getSampleBondDocumentUrl(document.id);
    }

    public canDownloadDocument(document: DocumentSearchResult): boolean {
        return document.id > 0;
    }

    public canPreviewDocument(document: DocumentSearchResult): boolean {
        return document.id > 0;
    }

    public canEditDocument(document: DocumentSearchResult): boolean {
        return document.id > 0;
    }

    public canReplaceDocument(document: DocumentSearchResult): boolean {
        return !document.isPublic;
    }

    public isDocumentUpdating(document: DocumentSearchResult): boolean {
        return document['$updating'];
    }

    public isDocumentUntouched(document: DocumentSearchResult): boolean {
        return document.status === 'Untouched';
    }

    public isDocumentInPrimaryReview(document: DocumentSearchResult): boolean {
        return document.status === 'PrimaryReview';
    }

    public isDocumentInSecondaryReview(document: DocumentSearchResult): boolean {
        return document.status === 'SecondaryReview';
    }

    public isDocumentApproved(document: DocumentSearchResult): boolean {
        return document.status === 'Approved';
    }

    public isDocumentProblemReported(document: DocumentSearchResult): boolean {
        return document.status === 'ProblemReported';
    }

    public dropzoneAddedFile(file): void {
        this.setFileId(file);

        if (this.documentToReplace) {
            this.replaceDocument(file);
        }

        this.addNewDocument(file);
    }

    private setFileId(file): void {
        file.id = DocumentPickerController.fileIdCounter++;
    }

    private replaceDocument(file): void {
        file.updating = true;

        if (this.onDocumentUpdated) {
            this.onDocumentUpdated({ updatedFile: this.documentToReplace });
        }

        this.replacedDocuments.set(file.id, { ...this.documentToReplace });

        delete this.documentToReplace;
    }

    private addNewDocument(file: any): void {
        if (this.onDocumentAdded) {
            this.onDocumentAdded({
                addedFile: {
                    id: file.id,
                    name: file.name,
                    sent: file.upload.bytesSent,
                    total: file.upload.total,
                    category: this.documentCategoryName,
                    categoryId: this.documentCategoryId
                }
            });
        }
    }

    public dropzoneProcessing(file): void {
        this.dropzone.options.url = this.getFileUploadUrl(file);
    }

    private getFileUploadUrl(file): string {
        if (this.isReplacementFile(file)) {
            return this.systemSettings.apiBaseUrl + 'documentLibrary/Update';
        } else {
            return this.systemSettings.apiBaseUrl + 'documentLibrary/Upload?categoryId=' + this.documentCategoryId;
        }
    }

    private isReplacementFile(file): boolean {
        return this.replacedDocuments.has(file.id);
    }

    public dropzoneError(file, response, xhr): void {
        for (let i = 0; i < this.dropzone.files.length; i++) {
            if (this.dropzone.files[i].name === file.name) {
                this.cancelUpload(i);
            }
        }

        // TODO: Handle errors when files fail to upload.
    }

    public dropzoneSending(file, xhr, formData): void {
        if (this.isReplacementFile(file)) {
            const blob = new Blob([JSON.stringify(this.replacedDocuments.get(file.id))], { type: 'text/json' });
            formData.append('document', blob);
        }
    }

    public dropzoneSuccess(file, response): void {
        if (this.onDocumentUploaded) {
            this.onDocumentUploaded({
                file: file,
                response: response,
                updatedFile: this.replacedDocuments.get(file.id)
            });
        }

        this.dropzone.removeFile(file);
    }

    public dropzoneComplete(file): void {
        if (file.status !== 'error') {
            this.uploadNextFileInQueue();
        }
    }

    private uploadNextFileInQueue(): void {
        for (const file of this.dropzone.files) {
            if (file['upload']['progress'] < 100) {
                this.dropzone.processQueue();
                return;
            }
        }

        // Event fired here, rather than in dropzoneQueueComplete, so that
        // the event will be fired even if there are no documents to upload.
        if (this.onUploadQueueCompleted) {
            this.onUploadQueueCompleted();
        }
    }

    public dropzoneUploadProgress(file): void {
        if (this.onUploadProgressChanged) {
            this.onUploadProgressChanged({ file: file });
        }
    }
}

const documentPickerComponent = {
    templateUrl: 'app/components/documentPicker/documentPicker.html',
    bindings: {
        placeholder: '@?',
        documentFilter: '@?',
        documentCategory: '=?',
        documents: '=?',
        showStatus: '=?',
        allowMultipleSelections: '=?',
        onDocumentSelected: '&?',
        onDocumentAdded: '&?',
        onDocumentRemoved: '&?',
        onDocumentUpdated: '&?',
        onDocumentUploaded: '&?',
        onUploadProgressChanged: '&?',
        onUploadQueueCompleted: '&?',
        onUploadCanceled: '&?',
        control: '=?',
        hideEmptyState: '@?'
    },
    controller: DocumentPickerController,
    controllerAs: 'vm'
};

app.component('documentPicker', documentPickerComponent);


export interface DocumentPickerDocument {
    id: number;
    name: string;
}