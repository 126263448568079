import { IBaseEntity } from "./iBaseEntity";
import { SystemAccount } from "./systemAccount";
import { UserUserGroup } from "./userUserGroup";

export interface UserGroup extends IBaseEntity { 
    name: string;
    systemAccountId: number;
    systemAccount: SystemAccount;
    userUserGroups: UserUserGroup[];
    
    canDeleteFromDocumentLibrary: boolean;
    canEditBondTypes: boolean;
    canEditDocumentLibrary: boolean;
    canEditObligees: boolean;
    canEditSystemAccounts: boolean;
    canEditUserGroups: boolean;
    canEditUsers: boolean;
    canEditActivitySettings: boolean;
    
    id: number;
    createdDateTime: Date;
}