import { PaymentMethod } from "../../api/types/model/paymentTransaction";
import { PaymentStatus } from "../../api/types/payments/paymentStatus";
import { TableQueryOptions } from "../../api/types/tableQuery";

export interface PaymentReportFilterOptions extends TableQueryOptions {
    status?: PaymentStatus;
    minimumAmount?: number;
    maximumAmount?: number;
    startDate?: Date;
    endDate?: Date;
    isNotFullyApplied?: boolean;
    paymentMethod?: PaymentMethod;
}