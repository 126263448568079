import { DocumentVersion } from "./documentVersion";
import { BondTransaction } from "./bondTransaction";
import { DocumentCorrectionRequest } from "./documentCorrectionRequest";
import { IBaseEntity } from "./iBaseEntity";

export interface BondTransactionDocumentVersion extends IBaseEntity { 
    bondTransaction: BondTransaction;
    bondTransactionId: number;
    documentVersion: DocumentVersion;
    documentVersionId: number;
    documentCorrectionRequests: DocumentCorrectionRequest[];
    includeInDownload: boolean;
    sequence: number;
    id: number;
    createdDateTime: Date;
}