import { EProducerService } from "../../api/eProducerService";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { BusyIndicator } from "../busyIndicator/busyIndicator";
import { EProducerUserAccessCardData } from "./eProducerUserAccessCardData";

    
class EProducerUserAccessCardController {

    public static $inject = [
        Injectables.EProducerService,
        Injectables.ToastMessageCreator
    ];

    constructor (
        private readonly eProducerService: EProducerService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {}

    public eproducerUser: EProducerUserAccessCardData;
    public busyIndicator: BusyIndicator;

    public onRemoveClick: () => void;

    public get showRemoveButton(): boolean {
        return typeof this.onRemoveClick === 'function';
    }

    public resetApplicantPassword() {
        this.busyIndicator.promise = this.eProducerService.resetUserPassword(this.eproducerUser.email)
            .then(() =>
                this.toastMessageCreator.createSuccessMessage('Password reset email was sent successfully')
            )
            .catch((err) =>
                this.toastMessageCreator.createErrorMessage(err.message)
            );
    }

    public $onInit(): void {
        this.busyIndicator = {
            hideMessage: true
        }
    }
}

const eproducerUserAccessCardComponent = {
    bindings: {
        eproducerUser: '<',
        onRemoveClick: '&?'
    },
    templateUrl: 'app/components/eProducerUserAccessCard/eProducerUserAccessCard.html',
    controller: EProducerUserAccessCardController,
    controllerAs: 'vm'
};

app.component('eproducerUserAccessCard', eproducerUserAccessCardComponent);