import { Bond } from "../bond";
import { BondChange } from "./bondChange";
import { Fee } from "./fee";
import { IBaseEntity } from "./iBaseEntity";
import { SystemAccount } from "./systemAccount";
import { User } from "./user";

export interface RiderRequest extends IBaseEntity { 
    agencySystemAccountId: number;
    carrierSystemAccountId: number;
    agencyComments: string;
    agencySystemAccount: SystemAccount;
    attorneyInFactUserId: number;
    bond: Bond;
    bondId: number;
    carrierComments: string;
    carrierSystemAccount: SystemAccount;
    commissionChange: number;
    effectiveDate: Date;
    fees: Fee[];
    premiumChange: number;
    reasonCode: string;
    reasonDescription: string;
    requestedEffectiveDate: Date;
    riderRequestChanges: BondChange[];
    changes: BondChange[];
    status: RiderStatus;
    user: User;
    userId: number;
    id: number;
    createdDateTime: Date;
}

export enum RiderStatus {	
    None = "None",
    Requested = "Requested",
    Approved = "Approved",
    Declined = "Declined"
}