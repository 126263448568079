import {
    ITimeoutService,
    ILocationService,
    IAnchorScrollService,
    IAttributes
} from "angular";
import { ODataFactory } from "../../api/odata";
import { TableQueryOptions } from "../../api/types/tableQuery";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { ModalOpener } from "../../modals/modalOpener";
import { IDebounceDelayer } from "../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../utilities/tables/table";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";

export type BoundGrid = {
    refresh?(): void;
    totalRecords?: number;
};

const boundGrid = (
    $timeout: ITimeoutService,
    modalOpener: ModalOpener,
    $location: ILocationService,
    $anchorScroll: IAnchorScrollService,
    toastMessageCreator: ToastMessageCreator
) => {
    const compile = ($element: JQuery, $attrs: IAttributes) => {
        // default attributes
        if (!$attrs.keyField) {
            $attrs.keyField = "id";
        }

        // default attributes
        if (!$attrs.labelField) {
            $attrs.labelField = "name";
        }
    };

    const controller = (
        $scope: any,
        odata: ODataFactory,
        debouceDelayer: IDebounceDelayer
    ) => {
        $scope.table = new Table<any, TableQueryOptions>(debouceDelayer);
        $scope.columns = [];

        if ($scope.id) {
            $scope.id = $scope.id.trim();
        }

        if (!$scope.query) {
            $scope.query = odata.getQuery();
        }

        if (!$scope.controlObject) {
            $scope.controlObject = {};
        }

        $scope.aggregateFilterQuery = !$scope.aggregateFilterExpression
            ? ""
            : $scope.aggregateFilterExpression;
        $scope.dataService = odata.getService($scope.endpoint);
        $scope.initialGridLoad = true;

        $scope.busyIndicator = {
            message: "Loading..."
        };

        // REFRESH
        // this is used to refersh the grid from the parent controller
        $scope.controlObject.refresh = () => {
            $scope.loadGrid();
        };

        // LOAD DATA
        $scope.loadGrid = () => {
            $scope.query
                .orderby($scope.table.sorter.sortBy)
                .skip(
                    ($scope.table.pager.currentPage - 1) *
                        $scope.table.pager.recordsPerPage
                )
                .top($scope.table.pager.recordsPerPage);

            // Apply filter
            let filterString = $scope.query
                .getFilter()
                .replace(" and (" + $scope.aggregateSearchString + ")", "")
                .replace("(" + $scope.aggregateSearchString + ")", "");

            // default aggregate search type
            let searchType = "contains";
            if ($scope.$parent && $scope.$parent.searchType) {
                searchType = $scope.$parent.searchType;
            }

            if (
                $scope.table.queryOptions.searchPhrase &&
                $scope.aggregateFilterQuery
            ) {
                const quotesEncoded =
                    $scope.table.queryOptions.searchPhrase.replace(/'/g, "''");

                $scope.aggregateSearchString = $scope.aggregateFilterQuery
                    .replace(/{{aggSearchType}}/g, searchType)
                    .replace(/{{search}}/g, quotesEncoded)
                    .replace(/\{0\}/g, quotesEncoded);

                // and only needs to be there if the filter string has value
                filterString +=
                    (filterString ? " and (" : "(") +
                    $scope.aggregateSearchString +
                    ")";
            }
            $scope.query.filter(filterString);

            // GET
            $scope.busyIndicator.message = "Loading...";
            $scope.busyIndicator.promise = $scope.dataService
                .get($scope.query)
                .then(function (response) {
                    $scope.table.setData(
                        response.data.value,
                        response.data["@odata.count"]
                    );
                    $scope.totalPages = $scope.table.pager.totalPages;
                    $scope.totalRecords = response.data["@odata.count"];

                    if ($scope.scrollToTop) {
                        $location.hash($scope.endpoint);
                        $anchorScroll();
                    }

                    if ($scope.onLoaded) {
                        $scope.onLoaded({ records: $scope.table.data });
                    }
                });
        };

        // DELETE
        $scope.busyIndicator.message = "Deleting Record...";
        $scope.busyIndicator.promise = $scope.delete = function (id, name) {
            modalOpener
                .deleteModal(name, id)
                .result.then(function (idToDelete) {
                    $scope.dataService
                        .delete(idToDelete)
                        .then(function () {
                            $scope.loadGrid();
                            toastMessageCreator.createSuccessMessage(
                                "Record deleted successfully"
                            );
                        })
                        .catch(function () {
                            toastMessageCreator.createErrorMessage(
                                "<strong>Oops...</strong> An error occurred while trying to delete"
                            );
                        });
                })
                .catch(() => {});
        };

        // CALL UPDATE ITEM
        $scope.updateItem = function (item) {
            const itemUpdated = $scope.onItemModified();
            if (itemUpdated) {
                itemUpdated(item);
            }
        };

        // ADD Detail Template
        $scope.addDetailTemplate = function (html) {
            $scope.detailTemplate = html;
        };

        // REGISTER COLUMN
        $scope.addColumn = function (column) {
            $scope.columns.push(column);

            // add button column class if column type is buttonColumn
            column.cssClass = column.cssClass ? column.cssClass : "";
            column.cssClass +=
                column.columnType == "buttonColumn" ? " table-button-col" : "";

            if (column.enableAggregateFilter && column.filterExpression) {
                $scope.aggregateFilterQuery +=
                    ($scope.aggregateFilterQuery ? " or " : "") +
                    "{{aggSearchType}}(" +
                    column.filterExpression +
                    ", '{{search}}')";
            }
        };

        $scope.$watch("aggregateSearch", () => {
            if ($scope.initialGridLoad) {
                $scope.initialGridLoad = false;
                return;
            }

            $scope.table.queryOptions.searchPhrase = $scope.aggregateSearch;
            $scope.table.searchPhraseChanged();
        });

        $scope.$watch("$parent.searchType", function () {
            if ($scope.aggregateSearch) {
                $scope.loadGrid();
            }
        });

        $scope.loadGrid();

        $scope.table.onChange = $scope.loadGrid;
    };

    controller.$inject = [
        Injectables.$scope,
        Injectables.ODataFactory,
        Injectables.IDebouceDelayer
    ];

    return {
        restrict: "E",
        scope: {
            endpoint: "@",
            query: "=",
            enablePaging: "@",
            keyField: "@",
            labelField: "@",
            aggregateSearch: "=",
            aggregateFilterExpression: "=",
            onItemModified: "&",
            controlObject: "=?",
            onLoaded: "&",
            totalRecords: "=?",
            scrollToTop: "=?",
            wrapperClass: "=?",
            tableClass: "=?",
            id: "@?"
        },
        transclude: true,
        replace: true,
        templateUrl: "app/components/boundGrid/boundGrid.html",
        compile: compile,
        controller: controller
    };
};

boundGrid.$inject = [
    Injectables.$timeout,
    Injectables.ModalOpener,
    Injectables.$location,
    Injectables.$anchorScroll,
    Injectables.ToastMessageCreator
];

app.directive("boundGrid", boundGrid);
