import { Type } from "./type";
import { AssetAmount } from "./assetAmount";
import { CourtId } from "./courtId";
import { DateFiled } from "./dateFiled";
import { DateReported } from "./dateReported";
import { Disposition } from "./disposition";
import { ExemptAmount } from "./exemptAmount";
import { Filer } from "./filer";
import { LiabilityAmount } from "./liabilityAmount";
import { Narratives } from "./narratives";
import { PriorIntentCode } from "./priorIntentCode";
import { VerificationDate } from "./verificationDate";

export interface USBankruptcy { 
    dateFiled: DateFiled;
    courtId: CourtId;
    caseNumber: string;
    type: Type;
    filer: Filer;
    disposition: Disposition;
    liabilityAmount: LiabilityAmount;
    assetAmount: AssetAmount;
    exemptAmount: ExemptAmount;
    verificationDate: VerificationDate;
    priorIntentCode: PriorIntentCode;
    dateReported: DateReported;
    narratives: Narratives;
}