import { Injectables } from "../configuration/injectables";
import { SignalRService } from "./signalRService";
import { A3RootScope } from "./types/a3RootScope";
import { AuthDataLocalStorage } from "./types/authDataLocalStorage";
import { CurrentUser } from "./types/authentication/currentUser";
import { User } from "./types/model/user";
import { RedirectDataLocalStorage } from "./types/redirectDataLocalStorage";
import { CacheFactory } from "./types/cacheFactory";
import app from "../main";
import { IHttpService, IQService, IPromise } from "angular";
import { LocalStorageService } from "../utilities/localStorage/localStorageService";
import { IStateService } from "../../typeDefinitions/uiRouter";
import { SystemSettings } from "../configuration/settings/systemSettings";
import { CacheStore } from "./cacheStore";
import A3ApiResponse from "./types/a3ApiResponse";
import { UserForAuthentication, UserForAuthenticationSystemAccount } from "../states/common/login/userSelectionController";
import { SystemType } from "./types/applicationDetail";
import { SystemAccountType } from "./types/model/systemAccount";

export class AuthService {

    public static $inject = [
        Injectables.$http,
        Injectables.$q,
        Injectables.LocalStorageService,
        Injectables.$rootScope,
        Injectables.CacheFactory,
        Injectables.$state,
        Injectables.SignalRService,
        Injectables.SystemSettings,
        Injectables.CacheStore
    ];

    constructor(
        private readonly $http: IHttpService,
        readonly $q: IQService,
        private readonly localStorageService: LocalStorageService,
        private readonly $rootScope: A3RootScope,
        private readonly CacheFactory: CacheFactory,
        private readonly $state: IStateService,
        private readonly signalRService: SignalRService,
        private readonly systemSettings: SystemSettings,
        private readonly cacheStore: CacheStore) {

        $rootScope.currentUser = {} as CurrentUser;
        $rootScope.currentUser.isLoggedIn = () => this.localStorageService.getAuthenticationData() != null;
    }
    
    public getUsersForAuthentication(): IPromise<UserForAuthentication[]> {
        const url = `${this.systemSettings.apiBaseUrl}LoginActions/GetUsersForAuthentication`;

        return this.$http.get<A3ApiResponse<UserForAuthentication[]>>(url)
            .then((response) => response.data.value);
    }

    public setSsoUser(ssoAuthenticationResponse: AuthenticationResponse): void {
        this.handleAuthenticationResponse(ssoAuthenticationResponse);
    }

    public setUser(userId: number, isSingleSignOn: boolean = false): IPromise<void> {
        const url = `${this.systemSettings.apiBaseUrl}LoginActions/SetLoginUser`;

        return this.$http.post<A3ApiResponse<AuthenticationResponse>>(url, { userId })
            .then((response) => {
                this.cacheStore.clearAll();

                const user = response.data.value;
                user.isSingleSignOn = isSingleSignOn;

                this.handleAuthenticationResponse(user);
            });
    }

    public changePassword(email: string, password: string, passwordResetKey: string): IPromise<void> {
        const url = `${this.systemSettings.apiBaseUrl}UserActions/ChangeUserPassword`;

        const request = {
            email: email,
            passwordResetKey: passwordResetKey,
            password: password
        };

        return this.$http.post(url, request)
            .then(() => {})
            .catch(() => {
                throw new Error('Unable to update password');
            });
    }
    
    public changeCurrentUserPassword(password: string): IPromise<void> {
        const url = `${this.systemSettings.apiBaseUrl}UserActions/ChangeCurrentUserPassword`;
        
        return this.$http
            .post(url, { password: password })
            .then(() => {});
    }

    public fillAuthData(): void {
        const authData: AuthDataLocalStorage = this.localStorageService.getAuthenticationData();

        if (authData) {
            this.$rootScope.currentUser.user = authData.user;
            this.$rootScope.currentUser.permissions = authData.permissions;
            this.$rootScope.currentUser.systemAccount = authData.systemAccount;
            this.$rootScope.currentUser.hasMultipleUsers = authData.hasMultipleUsers;
            this.$rootScope.currentUser.isSingleSignOn = authData.isSingleSignOn;

            //this.signalRService.subscribe(this.$rootScope.currentUser.user.userId);
        } else {

            if (this.$rootScope.currentUser && 
                this.$rootScope.currentUser.user && 
                this.signalRService.subscribed) {
                //this.signalRService.unsubscribe(this.$rootScope.currentUser.user.userId);
            }

            this.$rootScope.currentUser.user = null;
            this.$rootScope.currentUser.permissions = null;
            this.$rootScope.currentUser.systemAccount = null;
            this.$rootScope.currentUser.hasMultipleUsers = null;
            this.$rootScope.currentUser.isSingleSignOn = null;
        }
    }

    public forgotPassword(email: string): IPromise<void> {
        const request = { email: email };

        return this.$http
            .post(this.systemSettings.apiBaseUrl + 'UserActions/ForgotPassword', request)
            .then(() => {})
            .catch(() => {
                throw new Error("Unable to request a password reset");
            });
    }

    public login(loginData: User): IPromise<void> {
        this.CacheFactory.clearAll();
        this.cacheStore.clearAll();

        const url = `${this.systemSettings.apiBaseUrl}LoginActions/Login`;

        return this.$http.post<A3ApiResponse<AuthenticationResponse>>(url, { email: loginData.email, password: loginData.password })
            .then((response) => {
                this.handleAuthenticationResponse(response.data.value);
            })
            .catch((err) => {
                this.logout();
                throw new Error("Login attempt was unsuccessful");
            })
    }

    private handleAuthenticationResponse = (authResponse: AuthenticationResponse) => {
        
        let redirectStorage: RedirectDataLocalStorage = this.localStorageService.getA3RedirectData();
        let redirectState = 'main.dashboard';
        let redirectParams = null;

        const existingUser = this.localStorageService.getAuthenticationData();

        if (redirectStorage) {
            redirectState = redirectStorage.stateName;
            redirectParams = redirectStorage.params;
            this.localStorageService.removeA3RedirectData();
        }
        
        const currentUser: AuthDataLocalStorage = {
            token: authResponse.token,
            loginId: authResponse.loginId,
            permissions: authResponse.permissions,
            systemAccount: authResponse.systemAccount,
            hasMultipleUsers: authResponse.hasMultipleUsers || existingUser?.hasMultipleUsers,
            isSingleSignOn: authResponse.isSingleSignOn || existingUser?.isSingleSignOn
        };

        if (authResponse.userId) {
            currentUser.user = {
                systemAccountId: authResponse.systemAccountId,
                email: authResponse.email,
                firstName: authResponse.firstName,
                lastName: authResponse.lastName,
                fullName: authResponse.fullName,
                lastLoginDate: authResponse.lastLoginDate,
                theme: authResponse.theme || 'a3',
                sideMenuIsCollapsed: authResponse.sideMenuIsCollapsed,
                userId: authResponse.userId,
                hasMultipleUsers: authResponse.hasMultipleUsers,
                isSingleSignOn: authResponse.isSingleSignOn
            }
        } else {
            // logged in but still needs to select the user account
            redirectState = 'userSelection';
            redirectParams = null;
        }

        this.localStorageService.setAuthenticationData(currentUser);
        this.fillAuthData();        

        this.$state.go(redirectState, redirectParams);
    }

    public logout(): void {
        this.localStorageService.removeAuthenticationData();
        this.fillAuthData();
        this.CacheFactory.clearAll();
        this.cacheStore.clearAll();
    }

    public setRedirectUrl(stateName: string, params: any): void {
        this.localStorageService.setA3RedirectData({ stateName: stateName, params: params });
    }

    public setSideMenuIsCollapsed(isCollapsed: boolean): void {
        this.$rootScope.currentUser.user.sideMenuIsCollapsed = isCollapsed;
        const authData: AuthDataLocalStorage = this.localStorageService.getAuthenticationData();
        authData.user.sideMenuIsCollapsed = isCollapsed;
        this.localStorageService.setAuthenticationData(authData);
    }

    public setTheme(theme: string): void {
        this.$rootScope.currentUser.user.theme = theme;
        const authData: AuthDataLocalStorage = this.localStorageService.getAuthenticationData();
        authData.user.theme = theme;
        this.localStorageService.setAuthenticationData(authData);
    }

    public confirmLoggedInState = (): IPromise<void> => {
        // this ensures the user is still logged into the api when the page loads
        // calling this will force an API call which will be checked for a valid auth token
        // if no valid token is found then the user is redirected to the login UI automatically
        const url = `${this.systemSettings.apiBaseUrl}UserActions/IsLoggedIn`;
        
        return this.$http.get(url)
            .then(function() {})
            .catch(function() {});
    }
}

export interface AuthenticationResponse {
    token: string;
    loginId: number;
    systemAccountId?: number;
    email: string;
    firstName: string;
    lastName: string;
    fullName: string;
    lastLoginDate: Date;
    accountType: SystemAccountType;
    systemType: SystemType;
    isLockedOut: boolean;
    theme?: string;
    sideMenuIsCollapsed?: boolean;
    userId?: number;
    permissions?: any;
    systemAccount?: UserForAuthenticationSystemAccount;
    hasMultipleUsers: boolean;
    isSingleSignOn?: boolean;
}

app.service(Injectables.AuthService, AuthService);
