import { IBaseEntity } from "./iBaseEntity";
import { Release, ReleaseNoteType } from "./release";

export interface ReleaseNote extends IBaseEntity { 
    description: string;
    release: Release;
    releaseId: number;
    releaseNoteType: ReleaseNoteType;
    id: number;
    createdDateTime: Date;
}