import * as angular from "angular";
import { IPromise } from "angular";
import { ApplicationService } from "../../../api/applicationService";
import { ODataFactory, ODataFilterCollection, ODataQuery, ODataEndpoint, ODataFilterDataType } from "../../../api/odata";
import { SfaaCodeService } from "../../../api/sfaaCodeService";
import { SystemAccountService } from "../../../api/systemAccountService";
import { SelectOption } from "../../../api/types/selectOption";
import { BoundGrid } from "../../../components/boundGrid/boundGrid";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../configuration/injectables";
import { ModalOpener } from "../../../modals/modalOpener";
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";
import { DataExport } from "../../../utilities/dataExportService";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";

export class ReportAdverseActionsController {
    
    public static $inject = [
        Injectables.ODataFactory,
        Injectables.CurrentUserResolver,
        Injectables.DataExport,
        Injectables.ApplicationService,
        Injectables.ModalOpener,
        Injectables.SystemAccountService,
        Injectables.SfaaCodeService,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly odata: ODataFactory,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly dataExport: DataExport,
        private readonly applicationService: ApplicationService,
        private readonly modalOpener: ModalOpener,
        private readonly systemAccountService: SystemAccountService,
        private readonly sfaaCodeService: SfaaCodeService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) { }

    public busyIndicator: BusyIndicator;
    public filterCollection: ODataFilterCollection;
    public reportQuery: ODataQuery;
    public boundGrid: BoundGrid = {};
    public sfaaCodeOptions: SelectOption<string>[] = [];
    public writingCompanyOptions: SelectOption<number>[] = [];
    public tagOptions: SelectOption<string>[] = [];
    public baseFilter = 'declinedApplications/any(da: da/adverseActionRequired eq true)';
    public totalRecords: number;

    public exportReport(): void {
        const applicationSvc = this.odata.getService(ODataEndpoint.Application);

        this.busyIndicator = {
            message: 'Loading...',
            promise: applicationSvc.get(this.reportQuery).then((response) => {
                if (response.data.value.length === 0) {
                    this.toastMessageCreator.createErrorMessage('No records exist or your search found no results.');
                }
                else {
                    this.dataExport.exportToCsv(response.data.value, 'A3 Declined Applications', true);
                }
            })
        };
    }

    public exportAdverseActionNotificationsPdf(): void {
        if (this.totalRecords === 0) {
            this.toastMessageCreator.createErrorMessage('No records exist or your search found no results.');
        } else {
            const filter = `$filter=${this.reportQuery.getFilter()}&$orderby=${this.reportQuery.getOrderby()}`;

            this.applicationService.downloadAdverseActionNotificationDocumentSet(filter);
        }
    }

    public showFiltersModal(): void {
        this.modalOpener.showFiltersModal(angular.copy(this.filterCollection))
            .result
            .then((result) => {
                this.filterCollection = result.filterCollection;
                this.refreshReport();
            })
            .catch(() => {});
    }

    public removeFilter(filter): void {
        filter.reset();
        this.refreshReport();
    }

    public refreshReport(): void {
        this.reportQuery.filter(this.filterCollection.getFilterExpression());
        this.reportQuery.and(this.baseFilter);
        this.boundGrid.refresh();
    }

    public resetAllFilters(): void {
        this.filterCollection.resetAllFilters();
        this.refreshReport();
    }

    public loadWritingCompanies(): IPromise<void> {
        return this.systemAccountService
            .getWritingCompanyOptions()
            .then((options) => {
                this.writingCompanyOptions = options
            });
    }

    public loadSfaaCodes(): IPromise<void> {
        return this.sfaaCodeService
            .getAllSfaaCodeOptions()
            .then((options) => {
                this.sfaaCodeOptions = options
            });
    }

    public loadFilterOptions(): void {
        this.filterCollection = this.odata.getFilterCollection([
            this.odata.getFilter('nameOnBond', ODataFilterDataType.string, 'Principal Name'),
            this.odata.getFilter('bondAmount', ODataFilterDataType.money, 'Bond Amount'),
            this.odata.getFilter('createdDateTime', ODataFilterDataType.date, 'Created Date'),
            this.odata.getFilter('bondType/name', ODataFilterDataType.string, 'Bond Type'),
            this.odata.getFilter('bondType/obligee/requisitioningState', ODataFilterDataType.state, 'Requisitioning State'),
            this.odata.getFilter('customer/systemAccount/companyName', ODataFilterDataType.string, 'Agency Name', null, null, this.currentUserResolver.getCurrentUser().systemAccount.isCarrier),
            this.odata.getFilter('bondType/sfaaCode/code', ODataFilterDataType.select, 'SFAA Code', null, null, true, this.sfaaCodeOptions),
            this.odata.getFilter('declinedApplications/any(da: da/hasAdverseActionBeenDownloaded eq true)', ODataFilterDataType.bool, 'Download Status', null, null, true, [{ label: 'Downloaded', value: 'true' }, { label: 'Not Downloaded', value: 'false' }], false)
        ]);
    }

    public $onInit(): void {
        this.reportQuery = this.odata.getQuery();
        this.reportQuery.expand('customer($expand=systemAccount),bondType($expand=obligee,sfaaCode),quotes($expand=carrierSystemAccount($select=companyName,id),writingCompany),tags');
        this.reportQuery.filter(this.baseFilter);
        this.reportQuery.setAdditionalParameters('includeDeclined=true');
        this.reportQuery.orderby('createdDateTime desc');

        this.loadWritingCompanies();
        this.loadSfaaCodes();
        this.loadFilterOptions();
    }
}
