import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { InvoiceService } from "../../api/invoiceService";
import InvoiceDetail from "./invoiceDetail";
import InvoiceModalOptions from "./invoiceModalOptions";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";

export class InvoiceModalController {

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.InvoiceService,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly $uibModalInstance: Modal<any>,
        private readonly options: InvoiceModalOptions,
        private readonly invoiceService: InvoiceService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {}

    public invoice: InvoiceDetail;
    public busyIndicator: BusyIndicator; 
    public invoiceDownloadUrl: string;
    
    public close() {
        this.$uibModalInstance.dismiss();
    }

    public voidInvoice() {
        this.busyIndicator.message = "Voiding...";
        this.busyIndicator.promise = this.invoiceService.voidInvoice([this.options.invoiceId])
            .then(() => {
                return this.loadInvoice();
            })
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Invoice has been voided');
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to void the invoice');
            })
    }

    private loadInvoice = () => { 
        return this.invoiceService.loadInvoiceDetail(this.options.invoiceId)
            .then((invoiceDetail) => {
                this.invoice = invoiceDetail;
            });
    }

    public refresh = () => {
        this.busyIndicator.message = 'Loading...';
        this.busyIndicator.promise = this.loadInvoice();
    }
    
    public $onInit() {
        this.busyIndicator = {
            message: 'Loading...',
            promise: this.loadInvoice()
        };
        
        this.invoiceDownloadUrl = this.invoiceService.getInvoiceDocumentUrl(this.options.invoiceId);
    }
}

app.controller('InvoiceModalController', InvoiceModalController);
