import { BillToType } from "../../../api/types/billToType";
import { EpicBillingReportBondChange } from "./epicBillingReportBondChange";

export interface EpicBillingReportItem {
    id?: number;
	customerId: number;
    customerName: string;
    customerLookupCode: string;
    billToType: BillToType;
    eProducerAccountLookupCode: string
    brokerCommission?: number;
    bondNumber: string;
    epicLineOfBusinessCode: string;
    code: string;
    isOriginalEntry: boolean;
    description: string;
    bondId: number;
    bondTransactionId: number;
    effectiveDate: Date;
    expirationDate: Date;
    transactionEffectiveDate: Date;
    createdDateTime: Date;
    originalAmount: number;
    commission: number;
    epicUserCode: string;
    productionCredit: boolean;
    epicIssuingCompanyCode: string;
    epicPremiumPayableCode: string
    includedInEpicBillingReport: boolean;
    enteredInEpic: boolean;
    billTo: string;
    bondChanges: EpicBillingReportBondChange[];
    bRecCode: string;
    becBrokerCommission?: number;
    netOrGross: string;
    bPayCode: string;
    bPayBrokerCommission?: number;
    producer1ProductionCredit: string
    isIncomplete: boolean;
    $selected: boolean;
}