import { IJQueryService } from "../../../utilities/jquery/iJQueryService";
import fieldGroupWrapper from "../fieldGroupWrapper";

export default (element: JQuery, jQueryProvider: IJQueryService) => {
    element.attr('type', 'checkbox');
    var fieldGroupClass = element.attr('aa-fg-class');
    var ngshow = element.attr('ng-show') || '';
    if (ngshow.length > 0) {
        ngshow = 'ng-show="' + ngshow + '"';
    }
    element.attr('aa-val-msg', 'termsCheckValidation');

    var labelClass = element.attr('aa-label-class') || '';

    var aaLabelStrategy = element.attr('aa-label-strategy') || '';
    if (!aaLabelStrategy.length) {
        element.attr('aa-label-strategy', 'checkBoxLabel');
    }
    
    var wrapper = '<div class="field-wrapper checkbox ' + fieldGroupClass + '" ' + ngshow + '"><label class="'+ labelClass +'"><input/><span></span></label></div>';
    fieldGroupWrapper(element, wrapper, jQueryProvider);
};