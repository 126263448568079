import { ApplicationService } from "../../api/applicationService";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ReconsiderApplicationOptions } from "./reconsiderApplicationModalOptions";
import { ReconsiderApplicationResult } from "./ReconsiderApplicationResult";
import app from "../../main";
    
export class ReconsiderApplicationModalController {
    
    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.ApplicationService,
    ];

    constructor(
        private readonly $uibModalInstance: Modal<ReconsiderApplicationResult>,
        private readonly options: ReconsiderApplicationOptions,
        private readonly applicationService: ApplicationService,
    ){}

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }

    public applicationId: number;

    public reconsiderApplication(): void {
        this.$uibModalInstance.close(
            this.applicationService.reconsiderApplication(this.applicationId)
        );
    }

    public $onInit(): void {this.applicationId = this.options.applicationId;
    }
}

app.controller('ReconsiderApplicationModalController', ReconsiderApplicationModalController);
