import { ISCEService } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { FormatType } from "./formatType";

export class PhysicalAddressFilter {

    public static $inject = [
        Injectables.$sce
    ];

    public static Factory($sce: ISCEService): any {
        return (object?: any, formatType?: FormatType) => {
            if (!object) { return ''; }

            let address = '';

            switch (formatType) {
                case FormatType.TwoLine:
                    address = (object.physicalAddress ? object.physicalAddress : '') + ' ' +
                        (object.physicalSuiteAptNumber ? object.physicalSuiteAptNumber : '') + '<br/>' +
                        (object.physicalCity ? object.physicalCity : '') + ', ' +
                        (object.physicalState ? object.physicalState : '') + ' ' +
                        (object.physicalZip ? object.physicalZip : '') +
                        (object.physicalCounty ? '<br/>' + object.physicalCounty + ' county' : '');
                    break;
                case FormatType.Pipe:
                    address = (object.physicalAddress ? object.physicalAddress : '') + '{|}' +
                        (object.physicalSuiteAptNumber ? object.physicalSuiteAptNumber : '') + '{|}' +
                        (object.physicalCity ? object.physicalCity : '') + '{|}' +
                        (object.physicalState ? object.physicalState : '') + '{|}' +
                        (object.physicalZip ? object.physicalZip : '') + '{|}' +
                        (object.physicalCounty ? object.physicalCounty : '');
                    break;
                default:
                    address = (object.physicalAddress ? object.physicalAddress : '') + ' ' +
                        (object.physicalSuiteAptNumber ? object.physicalSuiteAptNumber : '') + ' ' +
                        (object.physicalCity ? object.physicalCity : '') + ', ' +
                        (object.physicalState ? object.physicalState : '') + ' ' +
                        (object.physicalCounty ? object.physicalCounty + ' county' : '');
                    break;
            }

            return $sce.trustAsHtml(address);
        };
    }
}

app.filter('physicalAddress', PhysicalAddressFilter.Factory);
export { FormatType };

