import { SelectOption } from "../../api/types/selectOption";
import { constants } from "../../configuration/constants";
import app from "../../main";

class EProducerBrokerSummaryController {

    public static $inject = [];

    constructor ( ) {}  

    public isDisabled: boolean;
    public companyTypeOptions: SelectOption<string>[];
    public referredByTypeOptions: SelectOption<string>[];

    public $onInit = () => {
        this.companyTypeOptions = constants.companyTypeOptions;
        this.referredByTypeOptions = constants.referredByTypesOptions;
    };
}

const eproducerBrokerSummaryComponent = {
    templateUrl: 'app/components/eProducerBrokerSummary/eProducerBrokerSummary.html',
    bindings: { 
        account: '=' 
    },
    controller: EProducerBrokerSummaryController,
    controllerAs: 'vm'
}
                    
app.component('eproducerBrokerSummary', eproducerBrokerSummaryComponent);