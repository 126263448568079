import { BondService } from "../../api/bondService";
import { CustomersService } from "../../api/customerService";
import { BondForReassignment } from "../../api/types/bondForReassignment";
import { Customer } from "../../api/types/model/customer";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { State } from "../../states/state";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { ReassignBondModalOptions } from "./reassignBondModalOptions";
import { ReassignBondModalResult } from "./ReassignBondModalResult";
import { ModalReassignBondActions } from "./ModalReassignBondActions";
import app from "../../main";
import { IPromise } from "angular";

export class ReassignBondModalController {
    
    public static $inject = [
        Injectables.Options,
        Injectables.$uibModalInstance,
        Injectables.CustomersService,
        Injectables.BondService,
        Injectables.$state,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly options: ReassignBondModalOptions,
        private readonly $uibModalInstance: Modal<ReassignBondModalResult>,
        private readonly customerService: CustomersService,
        private readonly bondService: BondService,
        private readonly $state: State,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {}

    public bondId: number;
    public loadingPromise: IPromise<void>;
    public customerSearchPromise: IPromise<void>;
    public matchingCustomers: Customer[];
    public selectedCustomer: Customer;
    public bond: BondForReassignment;

    public searchCustomers(searchPhrase: string)
    {
        this.customerSearchPromise = this.customerService.searchA3Customers(searchPhrase, null, null, 10)
            .then((customers) => {
                this.matchingCustomers = customers;
            });
    }

    public loadBond(bondId: number) {
        return this.bondService.getBondForReassignment(bondId)
            .then((bond) => {
                this.bond = bond;
            });
    }

    public $onInit(): void {
        this.bondId = this.options.bondId;

        this.loadingPromise = this.loadBond(this.bondId);
    }

    public selectCustomer(customer: Customer) {
        this.selectedCustomer = customer;
    }

    public reassignBond(): void {
        this.loadingPromise = this.bondService.reassignBond(this.bondId, this.selectedCustomer.id)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Bond was re-assigned successfully');
                this.$state.go('main.customerDetail', { customerId: this.selectedCustomer.id });
                this.$uibModalInstance.close(new ReassignBondModalResult(ModalReassignBondActions.Success));
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to reassign this bond');
            });
    }

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }
}

app.controller('ReassignBondModalController', ReassignBondModalController);