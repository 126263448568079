import { SelectOption } from "../../../../api/types/selectOption";
import app from "../../../../main";
import { BillingEntryReportItem } from "../billingEntryReportItem";
import isUndefinedOrNull from "../../../../utilities/angularUtilities/isUndefinedOrNull";
import { Table } from "../../../../utilities/tables/table";
import { BillingReportDropdownFilterOptions } from "./billingReportDropdownFilterOptions";
import { Injectables } from "../../../../configuration/injectables";
import { SystemAccountService } from "../../../../api/systemAccountService";

class BillingReportFilterDropdownController {
    public static $inject = [
        Injectables.SystemAccountService
    ];

    constructor( private readonly systemAccountService: SystemAccountService ) {
        this.modifiedFilters = {} as BillingReportDropdownFilterOptions;
    }

    public isOpen: boolean;
    public filterCount = 0;
    public codeOptions: SelectOption<string>[];
    public producerUserOptions: SelectOption<number>[];  
    public modifiedFilters: BillingReportDropdownFilterOptions;

    public table: Table<BillingEntryReportItem, BillingReportDropdownFilterOptions>;

    public clearFilters() {
        this.modifiedFilters = {
            invoiced: null,
            minimumAmountReceived: null,
            maximumAmountReceived: null,
            minimumAmountDue: null,
            maximumAmountDue: null,
            minimumAmountBilled: null,
            maximumAmountBilled: null,
            startDate: null,
            endDate: null,
            producerUserId: null,
            code: null,
            billingEntryType: null,
            productionCredit: null,
            transactionType: null,
        } as BillingReportDropdownFilterOptions;
        this.table.applyFilters({ ...this.modifiedFilters });

        this.setFilterCount();
        this.isOpen = false;
    }

    public cancel() {
        this.isOpen = false;
        this.modifiedFilters = { ...this.table.queryOptions };
    }

    public applyFilters() {
        this.isOpen = false;
        this.table.applyFilters({ ...this.modifiedFilters });
        this.setFilterCount();
    }

    private setModifiedFilters = () => {
        this.modifiedFilters.invoiced = this.table.queryOptions.invoiced;
        this.modifiedFilters.minimumAmountReceived = this.table.queryOptions.minimumAmountReceived;
        this.modifiedFilters.maximumAmountReceived = this.table.queryOptions.maximumAmountReceived;
        this.modifiedFilters.minimumAmountDue = this.table.queryOptions.minimumAmountDue;
        this.modifiedFilters.maximumAmountDue = this.table.queryOptions.maximumAmountDue;
        this.modifiedFilters.minimumAmountBilled = this.table.queryOptions.minimumAmountBilled;
        this.modifiedFilters.maximumAmountBilled = this.table.queryOptions.maximumAmountBilled;
        this.modifiedFilters.startDate = this.table.queryOptions.startDate;
        this.modifiedFilters.endDate = this.table.queryOptions.endDate;
        this.modifiedFilters.producerUserId = this.table.queryOptions.producerUserId;
        this.modifiedFilters.code = this.table.queryOptions.code;
        this.modifiedFilters.billingEntryType = this.table.queryOptions.billingEntryType;
        this.modifiedFilters.productionCredit = this.table.queryOptions.productionCredit;
        this.modifiedFilters.transactionType = this.table.queryOptions.transactionType;

        this.setFilterCount();
    };

    public setFilterCount() {
        this.filterCount = 0;

        if (!isUndefinedOrNull(this.table.queryOptions.invoiced)) {
            this.filterCount++;
        }

        if (
            this.table.queryOptions.minimumAmountReceived ||
            this.table.queryOptions.maximumAmountReceived
        ) {
            this.filterCount++;
        }

        if (
            this.table.queryOptions.minimumAmountDue ||
            this.table.queryOptions.maximumAmountDue
        ) {
            this.filterCount++;
        }

        if (
            this.table.queryOptions.minimumAmountBilled ||
            this.table.queryOptions.maximumAmountBilled
        ) {
            this.filterCount++;
        }

        if (
            this.table.queryOptions.startDate ||
            this.table.queryOptions.endDate
        ) {
            this.filterCount++;
        }

        if (this.table.queryOptions.producerUserId) {
            this.filterCount++;
        }

        if (this.table.queryOptions.productionCredit) {
            this.filterCount++;
        }

        if (this.table.queryOptions.code) {
            this.filterCount++;
        }

        if  (!isUndefinedOrNull(this.table.queryOptions.billingEntryType)) {
            this.filterCount++;
        }

        if (this.table.queryOptions.transactionType) {
            this.filterCount++;
        }
    }

    public $onInit() {
        this.codeOptions = [
            { label: 'Any', value: null },
            { label: 'New Business', value: 'NEWB'},
            { label: 'Renewal', value: 'RENB'},
            { label: 'Rider', value: 'ENDT'},
            { label: 'Cancellation', value: 'CANC'},
            { label: 'Reinstatement', value: 'REIN'},
            { label: 'Agency Fee', value: 'AFEE' },
            { label: 'Carrier Fee', value: 'CFEE' },
            { label: 'Tax/Surcharge (Carrier paid)', value: 'SRGX' },
        ];

        this.systemAccountService.getProducerUserOptions()
            .then((producerUserOptions) => {
                this.producerUserOptions = producerUserOptions;
            });
            
        this.setModifiedFilters();
    }
}

const billingEntryReportFilterDropdownComponent = {
    bindings: {
        table: "<"
    },
    controller: BillingReportFilterDropdownController,
    controllerAs: "vm",
    templateUrl:
        "app/states/agentSpecific/billingEntry/billingEntryReportFilterDropdown/billingEntryReportFilterDropdown.html"
};

app.component(
    "billingEntryReportFilterDropdown",
    billingEntryReportFilterDropdownComponent
);
