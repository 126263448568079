import app from "../../main";

class HelpController {

    public static $inject = [];

    constructor() {}

    public $onInit = () => {}
}

const helpComponent = {
    bindings: { message: '@' },
    templateUrl: 'app/components/help/help.html',
    controllerAs: 'vm',
    controller: HelpController
};

app.component('help', helpComponent);