import { RiderRequestBond } from "../../api/types/riderRequestBond";
import { RiderRequestService } from "../../api/riderRequestService";
import { SystemAccountService } from "../../api/systemAccountService";
import { RiderRequestForApproval } from "../../api/types/bonds/riderRequestForApproval";
import { RiderRequestForCreating } from "../../api/types/bonds/riderRequestForCreating";
import { BondChange, BondChangeType } from "../../api/types/model/bondChange";
import { RiderReason } from "../../api/types/model/riderReason";
import { RiderStatus } from "../../api/types/model/riderRequest";
import { RiderRequest } from "../../api/types/riderRequest";
import { SelectOption } from "../../api/types/selectOption";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { IEmployerAddressFilter } from "../../filters/employerAddressFilter/employerAddressFilterType";
import { IMailingAddressFilter } from "../../filters/mailingAddressFilter/mailingAddressFilterType";
import { IPersonNameFilter } from "../../filters/personName/personNameFilterType";
import { IPhysicalAddressFilter } from "../../filters/physicalAddressFilter/physicalAddressFilterType";
import { ISpouseNameFilter } from "../../filters/spouseName/spouseNameFilterType";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { RequestRiderOptions } from "./riderRequestModalOptions";
import { RequestRiderResultAction } from "./RequestRiderResultAction";
import { RequestRiderResult } from "./RequestRiderResult";
import app from "../../main";
import { ISCEService, IFilterService } from "angular";
import { FormatType } from "../../filters/physicalAddressFilter/formatType";
import * as moment from "moment";
import toCamel from "../../utilities/stringUtilities/toCamel";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";

export class RiderRequestModalController {

    public static $inject = [
        Injectables.$sce,
        Injectables.$uibModalInstance,
        Injectables.RiderRequestService,
        Injectables.Options,
        Injectables.CurrentUserResolver,
        Injectables.$filter,
        Injectables.SystemAccountService,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly $sce: ISCEService,
        private readonly $uibModalInstance: Modal<RequestRiderResult>,
        private readonly riderRequestService: RiderRequestService,
        private readonly options: RequestRiderOptions,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly $filter: IFilterService,
        private readonly systemAccountService: SystemAccountService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) { }

    public request: RiderRequest;
    public busyIndicator: BusyIndicator;
    public confirmRequest: boolean;
    public bondEffectiveDate: Date;
    public originalEffectiveDate: Date;
    public riderReasons: RiderReason[];
    public aifOptions: SelectOption<number>[];
    public bond: RiderRequestBond;
    public agentSubmissionTypeOptions: SelectOption<string>[] = [
        { label: 'Execute Rider', value: 'Execute' },
        { label: 'Request Rider', value: 'Request' }
    ];
    public agentSubmissionType: 'Request' | 'Execute' = 'Execute';

    get isCarrier(): boolean {
        return this.currentUserResolver.getCurrentUser().systemAccount.isCarrier;
    }

    get newRequest(): boolean {
        return this.bond.riderStatus === RiderStatus.None;
    }

    public get isAgentExecuting(): boolean {
        return this.agentSubmissionType === 'Execute' && 
                this.request && 
                !this.request.id && 
                !this.isCarrier;
    }
    
    public get isUpdating(): boolean {
        return this.agentSubmissionType === 'Request' &&
                this.request && 
                this.request.id && 
                !this.isCarrier;
    }

    public get isSubmitting(): boolean {
        return this.request && !this.request.id && !this.isCarrier;
    }

    public get isApproving(): boolean {
        if (!this.request) {
            return false;
        }

        // approve on submit if the user is a carrier
        // OR
        // if this is an existing request that has been changed to "execute"
        return (
                this.request && 
                this.request.id && 
                this.isCarrier
            ) || (
                !this.isCarrier &&
                !!this.request.id &&
                this.agentSubmissionType === 'Execute' 
            );
    }

    public get isCreating(): boolean {
        return this.request && !this.request.id && this.isCarrier;
    }

    get showRequestedEffectiveDate(): boolean {
        return !this.isCarrier || !this.newRequest;
    }

    get disableRequestedEffectiveDate(): boolean {
        return this.isCarrier;
    }

    get requireRequestedEffectiveDate(): boolean {
        return !this.isCarrier;
    }

    get totalFees(): number {
        let totalFees = 0;

        if (this.request?.fees?.length) {
            for(let i = 0; i < this.request.fees.length; i++) {
                totalFees += this.request.fees[i].amount;
            }
        }

        return totalFees;
    }

    public $onInit(): void {
        this.busyIndicator = {};
        this.bond = this.options.bond;
        
        this.setBondEffectiveDates();

        if (!this.newRequest) {
            this.loadExistingRiderRequest();
        } else {
            this.prepareNewRiderRequest();
        }
    }

    private prepareNewRiderRequest()
    {
        if (!this.options.changes) {
            this.options.changes = [];
        }

        this.request = { 
            changes: this.options.changes,
            requestedEffectiveDate: this.getDefaultEffectiveDate(),
            commissionChange: 0,
            premiumChange: 0,
            bondId: this.options.bond.id
        } as RiderRequest;

        this.busyIndicator.message = 'Loading...';
        this.busyIndicator.promise = this.loadAttorneyInFactOptions()
            .then(() => this.loadRiderReasons())
            .then(() => this.loadDefaultFees());
    }

    private loadExistingRiderRequest()
    {
        this.agentSubmissionType = "Request";

        this.busyIndicator.message = 'Loading Rider Request...';
        this.busyIndicator.promise = this.loadRider()
            .then(() => this.loadAttorneyInFactOptions())
            .then(() => this.loadRiderReasons());
    }

    private setBondEffectiveDates() {
        if (typeof this.bond.effectiveDate === 'string') {
            this.bondEffectiveDate = moment.utc(this.bond.effectiveDate, moment.ISO_8601) as any;
        } else {
            this.bondEffectiveDate = moment.utc(this.bond.effectiveDate) as any;
        }

        if (typeof this.bond.originalEffectiveDate === 'string') {
            this.originalEffectiveDate = moment.utc(this.bond.effectiveDate, moment.ISO_8601) as any;
        } else {
            this.originalEffectiveDate = moment.utc(this.bond.effectiveDate) as any;
        }
    }

    public async loadRider(): Promise<void> {
        const requestForEditing = await this.riderRequestService.getRiderRequestForEditing(this.bond.id)
        this.request = requestForEditing as RiderRequest;
    }

    public async loadAttorneyInFactOptions(): Promise<void> {
        this.aifOptions = await this.systemAccountService.getAttorneyInFactOptions(this.bond.id);
    
        if (!this.request.attorneyInFactUserId) {
            this.request.attorneyInFactUserId = this.systemAccountService.getDefaultAttorneyInFactUserId(this.aifOptions);
        }      
    }

    public async loadRiderReasons(): Promise<void> {
        if (!this.request.effectiveDate) {
            this.request.effectiveDate = this.request.requestedEffectiveDate;
        }

        this.riderReasons = await this.systemAccountService.getRiderReasons(this.bond.carrierId);

        if (this.riderReasons && this.riderReasons.length === 1) {
            this.request.reasonId = this.riderReasons[0].id;
        }
    }

    public getDefaultEffectiveDate(): Date {
        const currentDate = moment().startOf('day').toDate();

        if (currentDate < this.bondEffectiveDate) {
            return this.bondEffectiveDate;
        }

        return currentDate;
    }

    public async loadDefaultFees(): Promise<void> {
        this.request.fees = await this.riderRequestService.getDefaultRiderFees(this.bond.id);
    }

    public decline(): void {
        
        this.busyIndicator.message = "Declining Request...";
        this.busyIndicator.promise = this.riderRequestService.declineRiderByCarrier(this.request.id, this.request.carrierComments)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Rider request has been cancelled');
                this.bond.riderStatus = RiderStatus.None;
                this.$uibModalInstance.close(new RequestRiderResult(RequestRiderResultAction.Declined));
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to decline the rider request');
            });
    }

    public backToRequest(): void {
        this.confirmRequest = false;
    }

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }

    public nextButtonClicked(): void {
        if (!this.request.changes.length) {
            return;
        }

        if (this.request.requestedEffectiveDate < this.originalEffectiveDate) {
            return;
        }

        this.getChangeDescriptionText();
    }

    private getChangeDescriptionText(): void {
        const changes = this.prepareChangesForSubmission();

        this.busyIndicator.message = 'Processing Descriptions...';
        this.busyIndicator.promise = this.riderRequestService.getRiderChangeDescriptions(changes)
            .then((updatedChanges) => {
                this.confirmRequest = true;
                this.request.changes = updatedChanges;
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to get the rider change descriptions');
            });
    }

    public submit(): void {
        if (this.isAgentExecuting) {
            this.agentExecute();
        } else if (this.isUpdating) {
            this.updateRequest();
        } else if (this.isSubmitting) {
            this.submitNewRequest();
        } else if (this.isApproving) {
            this.busyIndicator.message = 'Approving Rider...';
            this.busyIndicator.promise = this.approveRequest();
        } else if (this.isCreating) {
            this.carrierExecute();
        }
    }

    private carrierExecute(): void {
        const request: RiderRequestForCreating = {
            bondId: this.bond.id,
            effectiveDate: this.request.effectiveDate,
            carrierComments: this.request.carrierComments,
            attorneyInFactUserId: this.request.attorneyInFactUserId,
            changes: this.request.changes,
            premiumChange: this.request.premiumChange,
            commissionChange: this.request.commissionChange,
            reasonId: this.request.reasonId,
        }

        this.busyIndicator.message = 'Creating Rider...';
        this.busyIndicator.promise = this.riderRequestService.createRider(request)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Your rider has been created successfully');
                this.$uibModalInstance.close(new RequestRiderResult(RequestRiderResultAction.Approved));
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to create the rider');
            });
    }

    private agentExecute() : void {
        const saveRider = { 
            changes: this.request.changes,
            effectiveDate: this.request.requestedEffectiveDate,
            requestedEffectiveDate: this.request.requestedEffectiveDate,
            commissionChange: this.request.commissionChange,
            premiumChange: this.request.premiumChange,
            bondId: this.options.bond.id,
            fees: this.request.fees,
            reasonId: this.request.reasonId, 
            agencyComments: this.request.agencyComments,
            carrierComments: this.request.carrierComments
        } as RiderRequest;

        this.busyIndicator.message = 'Executing Rider...';
        this.busyIndicator.promise = this.riderRequestService.requestRider(this.request)
            .then(() => this.loadRider())
            .then(() => {
                this.request.changes = saveRider.changes;
                this.request.effectiveDate = saveRider.effectiveDate;
                this.request.requestedEffectiveDate = saveRider.requestedEffectiveDate;
                this.request.commissionChange = saveRider.commissionChange;
                this.request.premiumChange = saveRider.premiumChange;
                this.request.fees = saveRider.fees;
                this.request.reasonId = saveRider.reasonId;
                this.request.agencyComments = saveRider.agencyComments;
                this.request.carrierComments = saveRider.carrierComments;
                
                return this.approveRequest();
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to excute the rider');
            });
    }

    private submitNewRequest(): void {
        this.busyIndicator.message = 'Submitting Request...';
        this.busyIndicator.promise = this.riderRequestService.requestRider(this.request)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Your rider request has been submitted');
                this.$uibModalInstance.close(new RequestRiderResult(RequestRiderResultAction.Submitted));
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to submit the rider request');
            });
    }

    private updateRequest(): void {
        this.busyIndicator.message = 'Updating Request...';
        this.busyIndicator.promise = this.riderRequestService.updateRiderRequest(this.request)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Your rider request has been updated');
                this.$uibModalInstance.close(new RequestRiderResult(RequestRiderResultAction.Submitted));
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to update the rider request');
            });
    }

    private approveRequest(): Promise<void> {
        const request: RiderRequestForApproval = {
            agencyComments: this.request.agencyComments,
            carrierComments: this.request.carrierComments,
            commissionChange: this.request.commissionChange,
            effectiveDate: this.request.effectiveDate,
            id: this.request.id,
            premiumChange: this.request.premiumChange,
            reasonId: this.request.reasonId,
            attorneyInFactUserId: this.request.attorneyInFactUserId,
            changes: this.request.changes
        };

        return this.riderRequestService.approveRiderByCarrier(request)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Rider has been created');
                this.$uibModalInstance.close(new RequestRiderResult(RequestRiderResultAction.Approved));
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to create the rider');
            });
    }

    public prepareChangesForSubmission(): BondChange[] {
        this.setChangeNewValues();

        const changes: BondChange[] = [];

        // change the server enum ChangeType to a camelCase with a lower first char
        for(let i = 0; i < this.request.changes.length; i++) {
            const change = this.request.changes[i];

            changes.push({
                changeType: `${change.changeType.toString().charAt(0).toUpperCase()}${change.changeType.toString().slice(1)}` as BondChangeType,
                originalValue: (typeof change.originalValue === 'object' ? this.$sce.getTrustedHtml(change.originalValue) : change.originalValue),
                bondAdditionalQuestionResponseId: change.bondAdditionalQuestionResponseId,
                bondCorrectionId: change.bondCorrectionId,
                companyId: change.companyId,
                id: change.id,
                newValue: change.newValue,
                description: change.description,
                personId: change.personId,
                riderRequestId: change.riderRequestId,
                createdDateTime: change.createdDateTime
            } as BondChange);
        }

        return changes;
    }

    public setChangeNewValues(): void {
        for (const change of this.request.changes) {
            // uses delimeter to combine address and name changes into a single string
            switch (change.changeType) {
                case BondChangeType.BondTypeId:
                    change.newValue = change['$newValue_bondType'].id;
                    break;
                case BondChangeType.IndividualName:
                    change.newValue = this.$filter<IPersonNameFilter>('personName')({
                        firstName: change['$newValue_individualFirstName'],
                        lastName: change['$newValue_individualLastName'],
                        middleName: change['$newValue_individualMiddleName'],
                        prefix: change['$newValue_individualPrefix'],
                        suffix: change['$newValue_individualSuffix']
                    }, FormatType.Pipe);
                    break;

                case BondChangeType.IndividualSpouseName:
                    change.newValue = this.$filter<ISpouseNameFilter>('spouseName')({
                        spouseFirstName: change['$newValue_individualSpouseFirstName'],
                        spouseLastName: change['$newValue_individualSpouseLastName'],
                        spouseMiddleName: change['$newValue_individualSpouseMiddleName'],
                    }, FormatType.Pipe);
                    break;

                case BondChangeType.IndividualPhysicalAddress:
                    change.newValue = this
                        .$sce
                        .getTrustedHtml(this.$filter<IPhysicalAddressFilter>('physicalAddress')({
                            physicalAddress: change['$newValue_individualAddress'],
                            physicalCity: change['$newValue_individualCity'],
                            physicalCounty: change['$newValue_individualCounty'],
                            physicalState: change['$newValue_individualState'],
                            physicalSuiteAptNumber: change['$newValue_individualApptNumber'],
                            physicalZip: change['$newValue_individualZip'],
                        }, FormatType.Pipe));
                    break;

                case BondChangeType.IndividualMailingAddress:
                    change.newValue = this
                        .$sce
                        .getTrustedHtml(this.$filter<IMailingAddressFilter>('mailingAddress')({
                            mailAddress: change['$newValue_individualMailAddress'],
                            mailCity: change['$newValue_individualMailCity'],
                            mailCounty: change['$newValue_individualMailCounty'],
                            mailState: change['$newValue_individualMailState'],
                            mailSuiteAptNumber: change['$newValue_individualMailApptNumber'],
                            mailZip: change['$newValue_individualMailZip'],
                        }, FormatType.Pipe));
                    break;

                case BondChangeType.CompanyPhysicalAddress:
                    change.newValue = this
                        .$sce
                        .getTrustedHtml(this.$filter<IPhysicalAddressFilter>('physicalAddress')({
                            physicalAddress: change['$newValue_companyAddress'],
                            physicalCity: change['$newValue_companyCity'],
                            physicalCounty: change['$newValue_companyCounty'],
                            physicalState: change['$newValue_companyState'],
                            physicalSuiteAptNumber: change['$newValue_companyApptNumber'],
                            physicalZip: change['$newValue_companyZip'],
                        }, FormatType.Pipe));
                    break;

                case BondChangeType.IndividualEmployerAddress:
                    change.newValue = this
                        .$sce
                        .getTrustedHtml(this.$filter<IEmployerAddressFilter>('employerAddress')({
                            employerAddress: change['$newValue_individualEmployerAddress'],
                            employerCity: change['$newValue_individualEmployerCity'],
                            employerCounty: change['$newValue_individualEmployerCounty'],
                            employerState: change['$newValue_individualEmployerState'],
                            employerSuiteAptNumber: change['$newValue_individualEmployerApptNumber'],
                            employerZip: change['$newValue_individualEmployerZip'],
                        }, FormatType.Pipe));
                    break;

                case BondChangeType.CompanyMailingAddress:
                    change.newValue = this
                        .$sce
                        .getTrustedHtml(this.$filter<IMailingAddressFilter>('mailingAddress')({
                            mailAddress: change['$newValue_companyMailAddress'],
                            mailCity: change['$newValue_companyMailCity'],
                            mailCounty: change['$newValue_companyMailCounty'],
                            mailState: change['$newValue_companyMailState'],
                            mailSuiteAptNumber: change['$newValue_companyMailApptNumber'],
                            mailZip: change['$newValue_companyMailZip'],
                        }, FormatType.Pipe));
                    break;

                case BondChangeType.ObligeeMailAddress:
                    change.newValue = this
                        .$sce
                        .getTrustedHtml(this.$filter<IMailingAddressFilter>('mailingAddress')({
                            mailAddress: change['$newValue_obligeeMailAddress'],
                            mailCity: change['$newValue_obligeeMailCity'],
                            mailCounty: change['$newValue_obligeeMailCounty'],
                            mailState: change['$newValue_obligeeMailState'],
                            mailSuiteAptNumber: change['$newValue_obligeeMailApptNumber'],
                            mailZip: change['$newValue_obligeeMailZip'],
                        }, FormatType.Pipe));
                    break;

                case BondChangeType.ObligeePhysicalAddress:
                    change.newValue = this
                        .$sce
                        .getTrustedHtml(this.$filter<IPhysicalAddressFilter>('physicalAddress')({
                            physicalAddress: change['$newValue_obligeePhysicalAddress'],
                            physicalCity: change['$newValue_obligeePhysicalCity'],
                            physicalCounty: change['$newValue_obligeePhysicalCounty'],
                            physicalState: change['$newValue_obligeePhysicalState'],
                            physicalSuiteAptNumber: change['$newValue_obligeePhysicalApptNumber'],
                            physicalZip: change['$newValue_obligeePhysicalZip'],
                        }, FormatType.Pipe));
                    break;

                default:
                    change.newValue = change['$newValue_' + toCamel(change.changeType.toString())];
                    break;
            }
        }
    }

    public rescindRequest(): void {
        if (!this.request.id) {
            return;
        }

        this.busyIndicator.message = 'Rescinding Request...';
        this.busyIndicator.promise = this.riderRequestService.rescindRiderRequest(this.request.id)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Your rider request has been rescinded succesfully');
                this.$uibModalInstance.close(new RequestRiderResult(RequestRiderResultAction.Rescinded));
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to rescind the rider request');
            });
    }
}

app.controller('RiderRequestModalController', RiderRequestModalController);