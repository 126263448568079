import { ActivityService } from "../../../api/activityService";
import { ApplicationService } from "../../../api/applicationService";
import { AttachmentService } from "../../../api/attachmentService";
import { BondService } from "../../../api/bondService";
import { BondTypeService } from "../../../api/bondTypeService";
import { EProducerService } from "../../../api/eProducerService";
import { InvoiceService } from "../../../api/invoiceService";
import { SystemAccountService } from "../../../api/systemAccountService";
import { BillToType } from "../../../api/types/billToType";
import { EProducerBrandingSettings } from "../../../api/types/eProducerBrandingSettings";
import { BondStatus } from "../../../api/types/model/bond";
import { BondType } from "../../../api/types/model/bondType";
import { EProducerAccount } from "../../../api/types/model/eProducerAccount";
import { EProducerUser } from "../../../api/types/model/eProducerUser";
import { EProducerAccountFavoriteBondTypeConfiguration } from "../../../api/types/model/favoriteBondTypeConfiguration";
import { SelectOption } from "../../../api/types/selectOption";
import { ActivityListItem } from "../../../components/activityList/activityListItem";
import { ActivityTableQueryOptions } from "../../../components/activityList/activityTableQueryOptions";
import { AttachmentTableListItem } from "../../../components/attachmentList/attachmentTableListItem";
import { AttachmentTableQueryOptions } from "../../../components/attachmentList/attachmentTableQueryOptions";
import { BondListTableQueryOptions } from "../../../components/bondList/bondListTableQueryOptions";
import { BondListItem } from "../../../components/bondListFilterDropdown/bondListItem";
import { InvoiceListItem } from "../../../components/invoiceList/invoiceListItem";
import { InvoiceTableQueryOptions } from "../../../components/invoiceList/invoiceTableQueryOptions";
import { Injectables } from "../../../configuration/injectables";
import { ModalOpener } from "../../../modals/modalOpener";
import { IDebounceDelayer } from "../../../utilities/debounceDelayer/iDebouceDelayer";
import { SearchControl } from "../../../utilities/searchControl";
import { Table } from "../../../utilities/tables/table";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import * as angular from "angular";
import { IQService, IPromise } from "angular";
import { SystemSettings } from "../../../configuration/settings/systemSettings";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { InvoiceStatus } from "../../../api/types/model/invoiceStatus";
import ApplicationListTableQueryOptions from "../../../components/applicationList/applicationListQueryOptions";
import ApplicationListItem from "../../../components/applicationList/applicationListItem";
import { PaymentService } from "../../../api/paymentService";
import { PaymentListItem } from "../../../components/paymentList/paymentListItem";
import PaymentTableQueryOptions from "../../../api/types/payments/paymentTableQueryOptions";
import { PaymentStatus } from "../../../api/types/payments/paymentStatus";
import BillingEntriesListItem from "../../../components/billingEntriesList/billingEntriesListItem";
import { BillingEntryService } from "../../../api/billingEntryService";
import { NewInvoiceRequest } from "../../../api/types/invoices/newInvoiceRequest";
import { BillingReportDropdownFilterOptions } from "../../../states/agentSpecific/billingEntry/billingEntryReportFilterDropdown/billingReportDropdownFilterOptions";

export class EProducerAccountsDetailController {
    public static $inject = [
        Injectables.$stateParams,
        Injectables.EProducerService,
        Injectables.ModalOpener,
        Injectables.ApplicationService,
        Injectables.$q,
        Injectables.BondTypeService,
        Injectables.SystemAccountService,
        Injectables.BondService,
        Injectables.IDebouceDelayer,
        Injectables.InvoiceService,
        Injectables.ActivityService,
        Injectables.AttachmentService,
        Injectables.ToastMessageCreator,
        Injectables.SystemSettings,
        Injectables.PaymentService,
        Injectables.BillingEntries
    ];

    constructor(
        private readonly $stateParams: EProducerAccountDetailStateParams,
        private readonly eProducerService: EProducerService,
        private readonly modalOpener: ModalOpener,
        private readonly applicationService: ApplicationService,
        private readonly $q: IQService,
        private readonly bondTypeService: BondTypeService,
        private readonly systemAccountService: SystemAccountService,
        private readonly bondService: BondService,
        debouceDelayer: IDebounceDelayer,
        private readonly invoiceService: InvoiceService,
        private readonly activityService: ActivityService,
        private readonly attachmentService: AttachmentService,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly systemSettings: SystemSettings,
        private readonly paymentService: PaymentService,
        private readonly billingEntriesService: BillingEntryService
    ) {
        this.applicationsTable = new Table(debouceDelayer);
        this.applicationsTable.queryOptions.status = "Open";
        this.applicationsTable.onChange = this.loadApplications;

        this.bondsTable = new Table(debouceDelayer);
        this.bondsTable.queryOptions.status = BondStatus.Active;
        this.bondsTable.onChange = this.loadBonds;
        
        this.billingEntriesTable = new Table(debouceDelayer);
        this.billingEntriesTable.onChange = this.loadBillingEntries;

        this.invoiceTable = new Table(debouceDelayer);
        this.invoiceTable.queryOptions.status = InvoiceStatus.Open;
        this.invoiceTable.onChange = this.loadInvoices;
        
        this.paymentsTable = new Table(debouceDelayer);
        this.paymentsTable.queryOptions.paymentStatus = PaymentStatus.Approved;
        this.paymentsTable.onChange = this.loadPayments;

        this.activitiesTable = new Table(debouceDelayer);
        this.activitiesTable.onChange = this.loadActivities;

        this.attachmentsTable = new Table(debouceDelayer);
        this.attachmentsTable.onChange = this.loadAttachments;
    }

    public readonly applicationsTable: Table<ApplicationListItem, ApplicationListTableQueryOptions>;
    public readonly bondsTable: Table<BondListItem, BondListTableQueryOptions>;
    public readonly invoiceTable: Table<InvoiceListItem, InvoiceTableQueryOptions>;
    public readonly billingEntriesTable: Table<BillingEntriesListItem,BillingReportDropdownFilterOptions>; 
    public readonly paymentsTable: Table<PaymentListItem, PaymentTableQueryOptions>;
    public readonly activitiesTable: Table<ActivityListItem, ActivityTableQueryOptions>;
    public readonly attachmentsTable: Table<AttachmentTableListItem, AttachmentTableQueryOptions>;

    public account: EProducerAccount;
    public accountId: number;
    public busyIndicator: BusyIndicator;

    public contactsBusyIndicator: BusyIndicator;

    public attachmentsBusyIndicator: BusyIndicator;
    public attachmentsLoaded: boolean;

    public invoicesLoaded: boolean;
    public invoicesBusyIndicator: BusyIndicator;

    public applicationsLoaded: boolean;
    public applicationBusyIndicator: BusyIndicator;

    public paymentsBusyIndicator: BusyIndicator;
    public paymentsLoaded: boolean;

    public bondsBusyIndicator: BusyIndicator;
    public bondsLoaded: boolean;
    
    public usersBusyIndicator: BusyIndicator;
    public users: EProducerUser[];

    public brandingBusyIndicator: BusyIndicator;
    public branding: EProducerBrandingSettings;
    public lgLogo: File;
    public smLogo: File;
    public smallLogoDropzone: Dropzone;
    public largeLogoDropzone: Dropzone;
    public lgLogoPicturePath: string;
    public smLogoPicturePath: string;
    private logosInProcess: number;
    public epicIntegrationEnabled: boolean;

    public commissionCalculationTypeOptions: SelectOption<string>[];
    public summaryChanged: boolean;

    public favoriteBondTypesBusyIndicator: BusyIndicator;
    public favoriteBondTypeConfiguration: EProducerAccountFavoriteBondTypeConfiguration;

    public favoriteBondTypeSearch: SearchControl<BondType>;

    public invoiceSearchPhrase: string;
    public onlyOpenInvoices: boolean;
    public onlyApprovedPayments: boolean;

    public activitiesLoaded: boolean;
    public activitiesBusyIndicator: BusyIndicator;
    public brokerCategoryOptions: SelectOption<number>[];

    private billingEntriesLoaded: boolean;
    public billingEntriesBusyIndicator: BusyIndicator;

    public eProducerSettingsActivePane: string;

    public lgDzAddedFile(file: File): void {
        this.lgLogo = file;

        if (this.largeLogoDropzone.files.length > 1) {
            this.largeLogoDropzone.files[0] = this.largeLogoDropzone.files[1];
            this.largeLogoDropzone.files.splice(1, 1);
        }
    }

    public lgDzError(file: File, response: any, xhr: any): void {
        if (!response.Message) {
            this.lgLogo = undefined;
        }
    }

    public lgDzProcessing(file: File): void {
        this.largeLogoDropzone.options.url = this.systemSettings.apiBaseUrl + 'upload/UploadEProducerAccountLogo';
    }

    public lgDzSuccess(file: File, response: any): void {
        this.largeLogoDropzone.removeAllFiles();
        this.branding.brandingLargeLogoFileId = response;
        this.saveBranding();
    }

    public smDzAddedFile(file: File): void {
        this.smLogo = file;

        if (this.smallLogoDropzone.files.length > 1) {
            this.smallLogoDropzone.files[0] = this.smallLogoDropzone.files[1];
            this.smallLogoDropzone.files.splice(1, 1);
        }
    }

    public smDzError(file: File, response: any, xhr: any): void {
        if (!response.Message) {
            this.smLogo = undefined;
        }
    }

    public smDzProcessing(file: File): void {
        this.smallLogoDropzone.options.url = this.systemSettings.apiBaseUrl + 'upload/UploadEProducerAccountLogo?height=75&width=75';
    }

    public smDzSuccess(file: File, response: any): void {
        this.smallLogoDropzone.removeAllFiles();
        this.branding.brandingSmallLogoFileId = response;
        this.saveBranding();
    }

    public applicationTabSelected() {
        if (this.applicationsLoaded) {
            return;
        }

        this.loadApplications();
    }

    public loadApplications = () => {
        
        this.applicationsTable.queryOptions.eProducerAccountId = this.account.id;

        this.applicationBusyIndicator = {
            message: 'Loading Applications...',
            promise: this.applicationService.getApplicationList(this.applicationsTable.queryOptions)
                .then((response) => {
                    this.applicationsTable.setData(
                        response.items,
                        response.totalRecordCount
                    );

                    this.applicationsLoaded = true;
                })
        };
    }

    public attachmentTabSelected(): void {
        if (this.attachmentsLoaded) {
            return;
        }

        this.loadAttachments();
    }

    public loadAttachments = () => {
        this.attachmentsBusyIndicator = {
            message: 'Loading Attachments...',
            promise: this.attachmentService.getEProducerAttachments(
                    this.account.id,
                    this.attachmentsTable.queryOptions
                )
                .then((response) => {
                    this.attachmentsTable.setData(response.items, response.totalRecordCount);
                    this.attachmentsLoaded = true;
                })
        }
    }

    public setLogoPicture(): IPromise<void[]> {
        const promises = [] as IPromise<void>[];

        if (this.branding.brandingLargeLogoFileId) {
            promises.push(this.eProducerService.getLargeLogo(this.branding.id)
                .then((imgData) => {
                    this.lgLogoPicturePath = imgData;
                    this.lgLogo = undefined;
                }));
        }

        if (this.branding.brandingSmallLogoFileId) {
            promises.push(this.eProducerService.getSmallLogo(this.branding.id)
                .then((imgData) => {
                    this.smLogoPicturePath = imgData;
                    this.smLogo = undefined;
                }));
        }

        return this.$q.all(promises);
    }

    public newPayment() {
        
        this.modalOpener.newPaymentModal(null, this.accountId)
            .result
            .then(() => {
                this.loadPayments();

                if (this.invoicesLoaded) {
                    this.loadInvoices();
                }

                if (this.billingEntriesLoaded) {
                    this.loadBillingEntries();
                }
            })
            .catch(() => {});
    }

    public contactsTabSelected() : void {
        if (this.account.people) {
            return;
        }

        this.loadContacts();
    }

    public loadContacts = () => {
        this.contactsBusyIndicator = {
            message: 'Loading Contacts...',
            promise: this.eProducerService.GetPeopleContactsForEProducerAccountId(this.accountId)
                .then((people) => {
                    this.account.people = people;
                })
        };
    }

    public invoiceTabSelected(): void {
        if (this.invoicesLoaded) {
            return;
        }

        this.loadInvoices();
    }

    public mergeInvoices() {
        const selectedInvoices = this.invoiceTable.getSelectedRecords();
        if (!selectedInvoices || !selectedInvoices.length) {
            return;
        }

        if (selectedInvoices.length === 1) {
            this.toastMessageCreator.createErrorMessage("You must select more than one invoice to merge");
            return;
        }

        if (selectedInvoices.filter((invoice) => invoice.status == InvoiceStatus.Voided).length) {
            this.toastMessageCreator.createErrorMessage("You cannot merge voided invoices");
            return;
        }

        const message =
            `Merging invoices is permanent. The original invoices will be voided. Are you sure you want to merge ${selectedInvoices.length} invoice${(selectedInvoices.length > 1 ? "s" : "")} ?`;
  
        this.modalOpener
            .confirmModal('Merge Invoice', message, 'Merge', 'Cancel')
            .result
            .then(() => {
                this.invoicesBusyIndicator = {
                    message: "Merging Invoices...",
                    promise: this.invoiceService.mergeInvoices(selectedInvoices.map((invoice) => invoice.id))
                        .then(() => {
                            this.toastMessageCreator.createSuccessMessage('The selected invoices have been merged');
                            
                            if (this.paymentsLoaded) {
                                this.loadPayments();
                            }

                            this.loadInvoices();
                        })
                        .catch(() => {
                            this.toastMessageCreator.createErrorMessage('An error occurred while trying to merge these invoices');
                        })
                };
            })
            .catch(() => {});
    }

    
    public createNewInvoice() {
        const selectedBillingEntries = this.billingEntriesTable.getSelectedRecords();
        if (!selectedBillingEntries || !selectedBillingEntries.length) {
            return;
        }

        const newInvoice = {
            billingEntryIds: selectedBillingEntries.map((billingEntry) => billingEntry.id)
        } as NewInvoiceRequest;

        this.billingEntriesBusyIndicator.message = 'Creating Invoice...'
        this.billingEntriesBusyIndicator.promise = this.invoiceService.createInvoice(newInvoice)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Invoice was created successfully');
                this.loadBillingEntries();

                if (this.invoicesLoaded) {
                    this.loadInvoices();
                }
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to create this invoice');
            });
    }

    public loadInvoices = () => {
        this.invoiceTable.queryOptions.eProducerAccountId = this.accountId;

        this.invoicesBusyIndicator = {
            message: "Loading Invoices...",
            promise: this.invoiceService.getInvoiceListItems(this.invoiceTable.queryOptions)
                .then((response) => {
                    this.invoiceTable.setData(response.items, response.totalRecordCount);
                    this.invoicesLoaded = true;
                })
            };
    }

    public showVoidInvoicePrompt() {
        const selectedInvoices = this.invoiceTable.getSelectedRecords()
        if (!selectedInvoices || !selectedInvoices.length){
            return;
        }

        const message = 'Voiding invoices is permanent. Any payments assoicated to the invoices you are voiding will be unassigned. Are you sure you want to void ' + selectedInvoices.length + ' invoice' + (selectedInvoices.length > 1 ? 's' : '') + '?'
        
        this.modalOpener.confirmModal('Void Invoice(s)', message, 'Void', 'Cancel')
            .result
            .then(() => {
                this.invoicesBusyIndicator = {
                    message: "Voiding Invoices...",
                    promise: this.invoiceService.voidInvoice(selectedInvoices.map((invoice) => invoice.id))
                        .then(() => {
                            this.toastMessageCreator.createSuccessMessage('The selected invoices have been voided');
                            this.loadInvoices();
                            this.loadPayments();
                        })
                        .catch(() => {
                            this.toastMessageCreator.createErrorMessage('An error occurred while trying to void the invoice');
                        })
                }
            })
            .catch(() => {});
    }
    
    public paymentsTabSelected() {
        if (this.paymentsLoaded) {
            return;
        }

        this.loadPayments();
    }

    public loadPayments = () => {
        this.paymentsTable.queryOptions.eProducerAccountId = this.account.id;

        this.paymentsBusyIndicator = {
            message: 'Loading Payments',
            promise: this.paymentService
            .getPaymentListItems(this.paymentsTable.queryOptions)
            .then((response) => {
                this.paymentsTable.setData(response.items,response.totalRecordCount);
                this.paymentsLoaded = true;
            })
        }
    }

    public paymentReassigned() {
        this.loadPayments();

        if (this.invoicesLoaded) {
            this.loadInvoices();
        }
    }

    public loadBranding(): void {
        if (this.branding && this.branding.id) {
            return;
        }

        this.brandingBusyIndicator = {
            message: 'Loading...',
            promise: this.eProducerService.getBranding(this.account.id)
            .then((branding) => {
                this.branding = branding;
                this.setLogoPicture();
            })
        };
    }

    public loadFavoriteBondTypeConfiguration(): void {
        this.favoriteBondTypesBusyIndicator = {
            message: 'Loading...',
            promise: this.eProducerService.getFavoriteBondTypeConfiguration(this.account.id)
                .then((favoriteBondTypeConfiguration) => {
                    this.favoriteBondTypeConfiguration = favoriteBondTypeConfiguration;
                })
        };
    }

    public addFavoriteBondType(): void {
        for (const favorite of this.favoriteBondTypeConfiguration.eProducerAccountFavoriteBondTypes) {
            if (favorite.bondTypeId === this.favoriteBondTypeSearch.selected.id) {
                return;
            }
        }

        this.favoriteBondTypeConfiguration.eProducerAccountFavoriteBondTypes.push({
            bondTypeId: this.favoriteBondTypeSearch.selected.id,
            eProducerAccountId: this.account.id,
            bondType: this.favoriteBondTypeSearch.selected
        });

        delete this.favoriteBondTypeSearch.selected;
    }

    public saveFavoriteBondTypes(): void {
        const request = {
            ...this.favoriteBondTypeConfiguration
        };

        this.favoriteBondTypesBusyIndicator = {
            message: 'Saving...',
            promise: this.eProducerService.saveFavoriteBondTypeConfiguration(request)
                .then(() => {
                    this.toastMessageCreator.createSuccessMessage('Favorites saved successfully');
                })
        };
    }

    public removeFavoriteBondType(index: number): void {
        this.favoriteBondTypeConfiguration.eProducerAccountFavoriteBondTypes.splice(index, 1);
    }

    public searchBondTypes(search: string): IPromise<void> {
        return this.bondTypeService.dropdownSearch(search).then((response) => {
            this.favoriteBondTypeSearch.matches = response;
        });
    }

    public saveBrandingClick(): void {
        if (this.largeLogoDropzone.files.length > 0) {
            this.logosInProcess++;
            this.largeLogoDropzone.processQueue();
        }

        if (this.smallLogoDropzone.files.length > 0) {
            this.logosInProcess++;
            this.smallLogoDropzone.processQueue();
        }

        this.saveBranding();
    }

    public saveBranding(): void {
        if (this.logosInProcess > 0) {
            this.logosInProcess--;
            return;
        }

        const request = {...this.branding};

        this.brandingBusyIndicator = {
            message: 'Saving...',
            promise: this.eProducerService.saveBranding(request)
                .then(() => {
                    this.setLogoPicture();
                    this.toastMessageCreator.createSuccessMessage('Branding info saved successfully');
                })
        };
    }

    public saveCompanyInfo(): void {
        // clean up account object.
        // we get only the Id's of these related objects just for the tab badge counts
        const {
            attachments,
            applications,
            bonds,
            ...account
        } = angular.copy(this.account);

        this.busyIndicator.message = 'Saving...';
        this.busyIndicator.promise = this.eProducerService.updateEProducerAccount(account as EProducerAccount)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Broker company info saved successfully');
            });
    }

    /* BONDS */
    public bondTabSelected(): void {
        if (this.bondsLoaded) {
            return;
        }  

        this.loadBonds();
    }

    private loadBonds = () => {
        this.bondsBusyIndicator = {
            message: 'Loading Bonds...',
            promise: this.bondService.getBondsByEProducerAccountId(this.accountId, this.bondsTable.queryOptions)
                .then((response) => {
                    this.bondsTable.setData(response.items, response.totalRecordCount);
                    this.bondsLoaded = true;
                })
        };
    }

    public exportBonds() {
        this.bondsBusyIndicator = {
            message: 'Loading Bonds...',
            promise: this.bondService.getBondListReportByEProducerAccountId(this.accountId, this.bondsTable.queryOptions)
        };
    }
    /* BONDS END */

    /* ACTIVITIES */
    public activitiesSelected(): void {
        if (this.activitiesLoaded) {
            return;
        }

        this.loadActivities();
    }

    private loadActivities = () => {
        this.activitiesBusyIndicator = {
            message: 'Loading Activities',
            promise: this.activityService.getBrokerActivities(
                this.accountId, 
                this.activitiesTable.queryOptions
            )
            .then((response) => {
                this.activitiesTable.setData(response.items, response.totalRecordCount);
                this.activitiesLoaded = true;
            })
        }
    }
    /* ACTIVITIES END */

    public addUser(): void {
        this.busyIndicator.message = 'Loading...';
        this.busyIndicator.promise = this.modalOpener.showEproducerUserModal(null, this.account.id)
            .result
            .then(() => this.loadUsers())
            .catch(() => {});
    }

    public loadUsers = () => {
        this.usersBusyIndicator = {
            message: 'Loading Users...',
            promise: this.eProducerService.getAccountUsers(this.account.id)
                .then((users) => {
                    this.users = users;
                })
        };
    }

    public approveAccount(): void {
        this.busyIndicator.message = 'Approving...';
        this.busyIndicator.promise = this.modalOpener.confirmModal('Approve Broker Account', 'Are you sure you want to <b>Approve</b> a broker account for <b>' + this.account.name + '</b>', 'Approve')
            .result
            .then(() => this.eProducerService.approveAccount(this.account.id))
            .then(() => {
                this.account.isApproved = true;
                this.toastMessageCreator.createSuccessMessage('This account has been approved and is ready for use');
            })
            .catch(() => {});
    }

    public lockAccount(): void {
        this.busyIndicator.message = 'Locking...';
        this.busyIndicator.promise = this.modalOpener.confirmModal('Lock Broker Account', 'Are you sure you want to lock the broker account for <b>' + this.account.name + '</b>', 'Lock Account')
            .result
            .then(() => this.eProducerService.lockAccount(this.account.id))
            .then(() => {
                this.account.isLocked = true;
            })
            .catch(() => {});
    }

    public unLockAccount(): void {
        this.busyIndicator.message = 'Unlocking...';
        this.busyIndicator.promise = this.eProducerService.unLockAccount(this.account.id)
            .then(() => {
                this.account.isLocked = false;
                this.toastMessageCreator.createSuccessMessage('This account has been unlocked and is ready for use');
            });
    }

    public loadAccount(id: number): IPromise<void> {
        if (!id) {
            throw new Error('id is not valid');
        }

        return this.eProducerService.getEProducerAccount(id)
            .then((account) => {
                this.account = account;

                this.checkToDisableCommissionFields();
            });
    }

    public loadEpicSettings(): IPromise<void> {
        return this.systemAccountService.isEpicIntegrationEnabled()
            .then((epicIntegrationEnabled) => {
                this.epicIntegrationEnabled = epicIntegrationEnabled;
            });
    }

    public saveSummary(): void {
        const summary = {
            id: this.account.id,
            lookupCode: this.account.lookupCode,
            commissionCalculationType: this.account.commissionCalculationType,
            commission: this.account.commission,
            billToType: this.account.billToType,
            eProducerAccountBrokerCategoryId : this.account.eProducerAccountBrokerCategoryId
        };

        this.busyIndicator.message = 'Saving...';
        this.busyIndicator.promise = this.eProducerService.saveEProducerSummary(summary)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Broker record saved successfully');
                this.summaryChanged = false;
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred while trying to save the broker record');
            })
    }

    public checkToDisableCommissionFields(): void {
        if (this.account.billToType == BillToType.BillClient || this.account.billToType == BillToType.BillBrokerNoCommission) {
            this.account.commission = 0;
            this.account.commissionCalculationType = null;
        }
    }

    private async getBrokerCategoryTypeOptions(): Promise<void> {
        this.brokerCategoryOptions = await this.eProducerService.getBrokerCateogryTypeOptions();
    }

    public relationshipTypeChanged()
    {    
        this.summaryFieldChanged() ;
    }

    public summaryFieldChanged(): void {
        this.checkToDisableCommissionFields();

        this.summaryChanged = true;
    }

    public loadEProducerSettings() {
        if (!this.eProducerSettingsActivePane) {
            this.eProducerSettingsActivePane = 'Users'
        }

        this.loadUsers();
        this.loadBranding();
        this.loadFavoriteBondTypeConfiguration();
    }

    public billingEntriesTabSelected = () => {
        if (this.billingEntriesLoaded) {
            return;
        }

        this.loadBillingEntries();
    }

    public loadBillingEntries = () => {
        this.billingEntriesTable.queryOptions.eProducerAccountId = this.accountId;

        this.billingEntriesBusyIndicator = {
            message: 'Loading Billing Entries...',
            promise: this.billingEntriesService.getBillingEntryListItems(this.billingEntriesTable.queryOptions)
                .then((response) => {
                    this.billingEntriesTable.setData(
                        response.items,
                        response.totalRecordCount
                    );

                    this.billingEntriesLoaded = true;
                })
        };
    }

    public exportBillingEntries = () => {
        this.busyIndicator = {
            message: "Preparing Excel...",
            promise: this.billingEntriesService.getBillingEntriesListDownload(
                this.billingEntriesTable.queryOptions
            )
        };
    }

    public $onInit(): void {
        this.accountId = this.$stateParams.id;
        this.logosInProcess = 0;
        this.favoriteBondTypeSearch = new SearchControl();
        this.onlyOpenInvoices = true;
        this.onlyApprovedPayments = true;
        
        this.commissionCalculationTypeOptions = [
            { label: 'Percent of Commission', value: 'PercentOfCommission' },
            { label: 'Percent of Premium', value: 'PercentOfPremium' }
        ];

        const promises: IPromise<void>[] = [
            this.loadAccount(this.accountId),
            this.loadEpicSettings(),
            this.getBrokerCategoryTypeOptions()
        ];

        this.busyIndicator = {
            message: 'Loading...',
            promise: this.$q.all(promises)
        };
    }
}

export class EProducerAccountDetailStateParams {
    public id: number;
}
