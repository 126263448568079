import { IPromise } from "angular";
import app from "../../main";
import { BusyIndicator } from "./busyIndicator";

export type BusyIndicatorTracker = {
    promise: PromiseType<any>,
    isPromise?: (value: any) => boolean,
    reset?: (options: BusyIndicator) => void,
    callThen?: (promise: PromiseType<any>, success: any, error: any) => void,
    active?: () => boolean
};

type PromiseType<T> = (IPromise<T> | Promise<T>) & { isResolved: boolean };

export type BusyIndicatorTrackerFactory = { create: () => BusyIndicatorTracker };

const busyIndicatorTrackerFactory = () => {

    return {
        create: () => {

            const tracker: BusyIndicatorTracker = {
                promise: null,
            };

            const addPromise = (promise: PromiseType<any>) => {

                if (!tracker.isPromise(promise)) {
                    return;
                }

                tracker.promise = promise;
    
                tracker.callThen(
                    promise, 
                    () => {
                        promise.isResolved = true;
                        promise = null;
                    }, 
                    () => {
                        promise.isResolved = true;
                        promise = null;
                    }
                );
            };

            tracker.reset = (options: BusyIndicator) => {
                tracker.promise = null;
                addPromise(options.promise as PromiseType<any>);
            };
    
            tracker.isPromise = (promiseType: PromiseType<any>) => {
                return promiseType && promiseType.then instanceof Function;
            };
    
            tracker.callThen = (
                promise: PromiseType<any>, 
                success, 
                error) => {
                
                var then = promise.then;
                then.call(promise, success, error);
            };
    
            tracker.active = () => {
                return tracker.promise != null && !tracker.promise.isResolved;
            };
    
            return tracker;
        }
    }
}

app.factory('busyIndicatorTrackerFactory', busyIndicatorTrackerFactory);