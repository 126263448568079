import { IHttpService, ISCEService, IScope } from "angular";
import { AuthService } from "../../../api/authService";
import A3ApiResponse from "../../../api/types/a3ApiResponse";
import { Injectables } from "../../../configuration/injectables";
import { SystemSettings } from "../../../configuration/settings/systemSettings";
import { LocalStorageService } from "../../../utilities/localStorage/localStorageService";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { State } from "../../state";
import { DocumentEditorParams } from "./DocumentEditorParams";
import { DocumentEditorSettings } from "./DocumentEditorSettings";


export class DocumentEditorController {

    public static $inject = [
        Injectables.$http, 
        Injectables.$sce, 
        Injectables.$scope, 
        Injectables.$stateParams, 
        Injectables.LocalStorageService, 
        Injectables.$state, 
        Injectables.AuthService, 
        Injectables.SystemSettings,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly $http: IHttpService, 
        private readonly $sce: ISCEService, 
        private readonly $scope: IScope, 
        private readonly $stateParams: DocumentEditorParams, 
        private readonly localStorageService: LocalStorageService, 
        private readonly $state: State, 
        private readonly authService: AuthService, 
        private readonly systemSettings: SystemSettings,
        private readonly toastMessageCreator: ToastMessageCreator
    ){
    }

    public loading: boolean;
    public request: number;
    public saving: boolean;
    public documentId: number;
    public documentEditorUrlWithId: string;
    public settings: DocumentEditorSettings;

    public receiveMessage = (event: MessageEvent<string>) => {
        if (event.data === 'loaded') {
            this.loading = false;
            
            const iframe: HTMLIFrameElement = document.getElementById('documentEditorFrame') as HTMLIFrameElement;
            iframe.contentWindow.postMessage(this.localStorageService.getDocumentLibrarySettings(), '*');
        } else if (event.data === 'updated') {
            this.loading = true;
            this.request++;
            this.loadDocumentEditor();
            this.toastMessageCreator.createSuccessMessage('The form was updated succesfully');
        }
        else if (event.data === 'back') {
            this.$state.go('main.documentLibrary', { reloadDocumentLibrarySettings: true });

        } else if (event.data === 'previous') {
            this.loading = true;
            this.getNextDocument(true);

        } else if (event.data === 'save') {
            this.$state.go('main.documentLibrary', { reloadDocumentLibrarySettings: true });
            this.toastMessageCreator.createSuccessMessage('Document saved successfully');
            this.saving = false;

        } else if (event.data === 'saveAndNext') {
            this.toastMessageCreator.createSuccessMessage('Document saved successfully');
            this.saving = false;
            this.loading = true;
            this.getNextDocument();

        } else if (event.data === 'next') {
            this.loading = true;
            this.getNextDocument();

        } else if (event.data === 'saveError') {
            this.toastMessageCreator.createErrorMessage('An error occurred while trying to save the document');

        } else if (event.data === 'unauthorized') {
            this.authService.logout();
            this.$state.go('login');
        } else if (event.data === 'saving') {
            this.saving = true;
        }

        this.$scope.$apply();
    }

    public getNextDocument = (getPrevious: boolean = false) => {

        var url = this.systemSettings.apiBaseUrl + 'DocumentLibrary/GetNextId?currentId=' + this.documentId;

        if (getPrevious) {
            url += '&getPrevious=true';
        }

        var filter = '';
        if (this.settings && this.settings.search && this.settings.search !== "")
            filter = "(" + (this.settings.searchType ? this.settings.searchType : 'contains') + "(name, '" + this.settings.search + "'))";

        if (this.settings && this.settings.filterExpression) {
            filter += filter.length > 0 ? " and (" + this.settings.filterExpression + ")" : this.settings.filterExpression;
        }
        
        if (filter !== '') {
            url += '&$filter=' + filter;
        }

        if (this.settings && this.settings.orderby) {
            url += '&$orderBy=' + this.settings.orderby;
        }

        this.$http.get<A3ApiResponse<number>>(url)
            .then((response) => {

                if (response.data.value && response.data.value > 0) {
                    this.documentId = response.data.value;
                    this.loadDocumentEditor();
                } else {
                    this.loading = false;

                    if (getPrevious) {
                        this.toastMessageCreator.createErrorMessage('This is the first document in the list.');
                    } else {
                        this.toastMessageCreator.createErrorMessage('This is the last document in the list.');
                    }
                }
            });
    }

    public loadDocumentEditor =  () => {

        var documentEditorUrl = this.systemSettings.documentEditorBaseUrl + "Default.aspx?documentId=";
        var url = documentEditorUrl + this.documentId +
            '&token=' + this.localStorageService.getAuthenticationData().token + 
            '&request=' + this.request;

        this.documentEditorUrlWithId = this.$sce.trustAsResourceUrl(url);
    }

    public $onInit = () => {
        this.loading = true;
        this.request = 1;

        this.documentId = this.$stateParams.id;
        this.settings = this.localStorageService.getDocumentLibrarySettings(); // use this instead of stateparams in case browser is refreshed

        this.loadDocumentEditor();

        window.addEventListener("message", this.receiveMessage);
        
        this.$scope.$on('$destroy', () => {
            window.removeEventListener("message", this.receiveMessage);
        });
    }
}