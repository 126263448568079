import { Quote } from "../quote";
import { IBaseEntity } from "./iBaseEntity";

export interface QuoteBondFormAttachment extends IBaseEntity { 
    document: Document;
    documentId: number;
    quote: Quote;
    quoteId: number;
    id: number;
    createdDateTime: Date;
}