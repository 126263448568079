import { IJQueryService } from "../../../utilities/jquery/iJQueryService";
import defaultWrapper from "../defaultWrapper";
import fieldGroupWrapper from "../fieldGroupWrapper";

export default (element: JQuery, jQueryProvider: IJQueryService) => {
    element.attr('mask-float', '');
    element.attr('is-float', '');
    element.attr('type', 'number');
    
    fieldGroupWrapper(element, defaultWrapper(element), jQueryProvider);
};