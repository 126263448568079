import { Bond } from "../bond";
import { RiderRequest } from "../riderRequest";
import { BondCorrection } from "./bondCorrection";
import { BondTransactionAdditionalQuestionResponse } from "./bondTransactionAdditionalQuestionResponse";
import { BondTransactionDocumentVersion } from "./bondTransactionDocumentVersion";
import { Company } from "./company";
import { IBaseEntity } from "./iBaseEntity";
import { IndemnitySignature } from "./indemnitySignature";
import { Invoice } from "./invoice";
import { Obligee } from "./obligee";
import { Person } from "./person";
import { QuestionResponse } from "./questionResponse";

export interface BondTransaction extends IBaseEntity { 
    attorneyInFact: string;
    attorneyInFactAddress: string;
    attorneyInFactCity: string;
    attorneyInFactEmail: string;
    attorneyInFactPhone: string;
    attorneyInFactSignatureFile: File;
    attorneyInFactSignatureImageFileId: number;
    attorneyInFactState: string;
    attorneyInFactSuiteAptNumber: string;
    attorneyInFactZip: string;
    expedited: boolean;
    mailAddress: string;
    mailCity: string;
    mailState: string;
    mailSuiteAptNumber: string;
    mailTo: string;
    mailZip: string;
    agencyComments: string;
    ams360PolicyId: string;
    ams360PolicyTransactionEffectiveDate: Date;
    ams360PolicyTransactionPremiumId: string;
    bond: Bond;
    bondId: number;
    bondTransactionAdditionalQuestionResponses: BondTransactionAdditionalQuestionResponse[];
    bondTransactionDocumentVersions: BondTransactionDocumentVersion[];
    carrierComments: string;
    commission: number;
    fees: number;
    indemnitySignatures: IndemnitySignature[];
    invoice: Invoice;
    invoiceId: number;
    isAgencyInvoiced: boolean;
    isCarrierInvoiced: boolean;
    isExportedToAms360: boolean;
    premium: number;
    premiumAsText: string;
    reasonCode: string;
    reasonText: string;
    refundedPremium: number;
    requiredCreditReport: boolean;
    riderRequest: RiderRequest;
    riderRequestId: number;
    bondCorrection: BondCorrection;
    bondCorrectionId: number;
    transactionEffectiveDate: Date;
    poaSignatureDate: Date;
    termTotalPremium: number;
    termTotalCommission: number;
    transactionType: TransactionType;
    obligeeId: number;
    obligee: Obligee;
    correctedTransactionType: TransactionType;
    
    people: Person[];
    companies: Company[];
    questionResponses: QuestionResponse[];
    bondNumber: string;
    nameOnBond: string;
    bondAmount: number;
    expirationDate: Date;
    effectiveDate: Date;
    amountAsText: string;
    bondAmountAsText: string;
    amountAsTextNumbersOnly: string;
    amount: number;
    
    id: number;
    createdDateTime: Date;

    name: string; 
    companyName: string;
    lookupCode: string;
    ams360IntegrationIsActive: boolean;
}

export enum TransactionType {	
    NewBusiness = "NewBusiness",
    Rider = "Rider",
    Cancellation = "Cancellation",
    Reinstatement = "Reinstatement",
    Renewal = "Renewal",
    Correction = "Correction",
    Import = "Import"
}
export enum TransactionOrigin {	
    A3Dashboard = "A3Dashboard",
    EProducerPrincipal = "EProducerPrincipal",
    EProducerBroker = "EProducerBroker"
}