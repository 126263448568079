import { IDirectiveFactory, IDirectiveLinkFn, IScope } from "angular";
import app from "../../main";
import Inputmask from "inputmask";

const feinMaskDirective: IDirectiveFactory = () => {

    const link: IDirectiveLinkFn = (
        scope: IScope,
        elem: JQuery
    ) => {
        const mask = new Inputmask("99-9999999");
        mask.mask(elem);
    }

    return {
        link: link
    }
}

app.directive('maskFein', feinMaskDirective);