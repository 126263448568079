import * as angular from "angular";
import { ODataFactory, ODataService, ODataEndpoint } from "../../../api/odata";
import { QuestionType, MasterApplicationQuestion } from "../../../api/types/model/masterApplicationQuestion";
import { SelectOption } from "../../../api/types/selectOption";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../configuration/injectables";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { State } from "../../state";

export type ApplicationQuestionDetailStateParams = {
    id?: number;
}

export class ApplicationQuestionDetailController {

    public static $inject = [
        Injectables.$stateParams,
        Injectables.$state,
        Injectables.ODataFactory,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly $stateParams: ApplicationQuestionDetailStateParams,
        private readonly $state: State,
        private readonly odata: ODataFactory,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {

    }

    public addSubQuestion = () => {
        this.question.subQuestions.push({
            type: QuestionType.TextBox,
            masterApplicationQuestionChoices: []
        } as MasterApplicationQuestion);
    };

    public removeSubQuestion = (index: number) => {
        this.question.subQuestions.splice(index, 1);
    };

    public save = () => {

        // ensure valid choices are present
        if (!this.areChoicesValid(this.question))
            return;

        // check all sub question for valid choices
        for (var i = 0; i < this.question.subQuestions.length; i++) {
            if (!this.areChoicesValid(this.question.subQuestions[i]))
                return;
        }

        this.setChoicesSequence(this.question);
        for (var i = 0; i < this.question.subQuestions.length; i++) {
            this.setChoicesSequence(this.question.subQuestions[i]);
        }

        // clean question properties
        this.question.name = this.clean(this.question.name);
        this.question.question = this.clean(this.question.question);
        this.question.requiredText = this.clean(this.question.requiredText);
        this.question.sampleResponse = this.clean(this.question.sampleResponse);

        // clean sub questions
        for (var i = 0; i < this.question.subQuestions.length; i++) {
            this.question.subQuestions[i].name = this.clean(this.question.subQuestions[i].name);
            this.question.subQuestions[i].question = this.clean(this.question.subQuestions[i].question);
            this.question.subQuestions[i].requiredText = this.clean(this.question.subQuestions[i].requiredText);
            this.question.subQuestions[i].sampleResponse = this.clean(this.question.subQuestions[i].sampleResponse);
        }

        var question = angular.copy(this.question);

        delete question.bondTypeMasterApplicationQuestions;
        delete question.underwritingQuestions;

        this.busyIndicator.message = 'Saving...';

        if (!this.newRecord) {
            this.busyIndicator.promise = this.questionSvc.put(this.question.id, question);
        } else {
            this.busyIndicator.promise = this.questionSvc.post(question);
        }

        this.busyIndicator.promise
            .then(() => {
                this.$state.go('main.applicationQuestionsGrid');
                this.toastMessageCreator.createSuccessMessage('Question saved successfully');
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to save the application question');
            });
    }

    public clean = (value: string) => {
        return value.replace(/\[/g, "").replace(/\]/g, "").replace(/\}/g, "").replace(/\{/g, "");
    }

    public setChoicesSequence = (question) => {
        for (var i = 0; i < question.masterApplicationQuestionChoices.length; i++) {
            question.masterApplicationQuestionChoices[i].sequence = i + 1;
        }
    }

    public areChoicesValid = (question) => {
        return (question.type != 'DropDown' && question.type != 'Radio') || question.masterApplicationQuestionChoices.length > 0;
    };

    public sortChoices = (question) => {
        question.masterApplicationQuestionChoices.sort((a, b) => {
            a = parseInt(a.sequence);
            b = parseInt(b.sequence);
            return a - b;
        });
    };

    public moveChoiceDown = (array: [], index: number) => {
        if (index >= array.length)
            return;

        var temp = array[index + 1];
        array[index + 1] = array[index];
        array[index] = temp;

    };

    public moveChoiceUp = (array: [], index: number) => {
        if (index <= 0)
            return;

        var temp = array[index - 1];
        array[index - 1] = array[index];
        array[index] = temp;
    };

    public addChoice = (question, choice) => {
        choice = choice.trim();

        if (choice == '')
            return;

        question.masterApplicationQuestionChoices.push({
            text: choice,
            sequence: question.masterApplicationQuestionChoices.length + 1
        });

        question.choiceToAdd = undefined;
    };

    public removeChoice = (array:[], index: number) => {
        array.splice(index, 1);
    };

    public questionSvc: ODataService<MasterApplicationQuestion>;
    public newRecord: boolean;
    public focusFirstField: boolean;
    public choiceToAdd: string;
    public question: MasterApplicationQuestion;
    public questionTypes: SelectOption<QuestionType>[]
    public busyIndicator: BusyIndicator;

    public $onInit = () => {
        this.busyIndicator = {
            message: 'Loading...'
        };

        this.questionSvc = this.odata.getService(ODataEndpoint.MasterApplicationQuestion);

        this.newRecord = this.$stateParams.id ? false : true;
        this.focusFirstField = true;
        this.choiceToAdd = '';
        this.question = {
            isActive: true,
            type: QuestionType.TextBox,
            masterApplicationQuestionChoices: [],
            subQuestions: []
        } as MasterApplicationQuestion;

        this.questionTypes = [
            { label: 'Text Box', value: QuestionType.TextBox }, 
            { label: 'Dropdown', value: QuestionType.DropDown }, 
            { label: 'Radio Buttons', value: QuestionType.Radio }, 
            { label: 'Text Area', value: QuestionType.TextArea }
        ];

        if (!this.newRecord) {
            var query = this.odata.getQuery();
            query.expand('bondTypeMasterApplicationQuestions($expand=bondType($select=name,id);$select=bondType),masterApplicationQuestionChoices,subQuestions($expand=masterApplicationQuestionChoices)');
            query.filter('id eq ' + this.$stateParams.id);

            this.questionSvc.get(query)
                .then((response) => {
                    this.question = response.data.value[0];

                    this.sortChoices(this.question);
                    for (var i = 0; i < this.question.subQuestions.length; i++) {
                        this.sortChoices(this.question.subQuestions[i]);
                    }
                });
        }
    }
}