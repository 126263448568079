export interface TaxLienDisplay {
    dateFiled: string;
    customerNumber: string;
    courtName: string;
    industry: string;
    caseNumber: string;
    amount: string;
    lienClass: string;
    releaseDate: string;
    lienStatus: string;
    dateReported: string;
    narratives: string[];
}