import { EProducerService } from "../../../api/eProducerService";
import { SelectOption } from "../../../api/types/selectOption";
import { Injectables } from "../../../configuration/injectables";
import app from "../../../main";
import { Table } from "../../../utilities/tables/table";
import { EProducerAccountListItem } from "./eProducerAccountListItem";
import { EProducerDropdownFilterOptions } from "./eProducerAccountsDropdownFilterOptions";

class EProducerAccountsFilterDropdownController {
    public static $inject = [Injectables.EProducerService];

    constructor(private readonly eProducerService: EProducerService) {
        this.modifiedFilters = {} as EProducerDropdownFilterOptions;
    }

    public isOpen: boolean;
    public filterCount = 0;
    public modifiedFilters: EProducerDropdownFilterOptions;
    public brokerCategoryOptions: SelectOption<number>[];

    public table: Table<EProducerAccountListItem, EProducerDropdownFilterOptions>;

    public clearFilters() {
        this.modifiedFilters.brokerCategoryId = null;

        this.table.applyFilters({ ...this.modifiedFilters });

        this.setFilterCount();
        this.isOpen = false;
    }

    public cancel() {
        this.isOpen = false;
        this.modifiedFilters = { ...this.table.queryOptions };
    }

    public applyFilters() {
        this.isOpen = false;

        this.table.applyFilters({ ...this.modifiedFilters });

        this.setFilterCount();
    }

    public setFilterCount() {
        this.filterCount = 0;

        if (this.table.queryOptions.brokerCategoryId) {
            this.filterCount++;
        }
    }    

    private async getBrokerCategoryTypeOptions(): Promise<void> {
        this.brokerCategoryOptions = await this.eProducerService.getBrokerCateogryTypeOptions();
    }

    public $onInit() {
        this.modifiedFilters = { ...this.table.queryOptions };
        this.setFilterCount();
        this.getBrokerCategoryTypeOptions();
    }
}

const EProducerAccountsFilterDropdown = {
    bindings: {
        table: "<"
    },
    controller: EProducerAccountsFilterDropdownController,
    controllerAs: "vm",
    templateUrl:
        "app/states/agentSpecific/eProducerBrokers/eProducerAccountsFilterDropdown.html"
};

app.component("eproducerAccountsFilterDropdown", EProducerAccountsFilterDropdown);
