import OpenApplicationCounterFilterType from "../../../api/types/openApplicationCounterFilterType";
import { PaymentStatus } from "../../../api/types/payments/paymentStatus";
import { Dashboard } from "./types/dashboard";
import { DashboardWidget } from "./types/dashboardWidget";
import { WidgetType } from "./widgetBases/widgetTypes";
import deepFreeze from "../../../utilities/immutable/deepFreeze";

export const openApplicationsListWidget: DashboardWidget = {
    id: WidgetType.dashboardWidgetOpenApplicationsList,
    name: "Open Applications List",
    description:
        "This displays the list of applications that are still open. These need to be followed up on to close a sale.",
    thumbnailClass: "fa fa-list",
    image: "/img/dashboardWidgets/openApplicationsWidget.png",
    sizeX: 2,
    sizeY: 2,
    minSizeX: 2,
    directive: WidgetType.dashboardWidgetOpenApplicationsList,
    options: {
        title: "Open Applications",
        color: null,
        recordsPerPage: 10,
        orderby: "createdDateTime desc",
        columns: {
            number: {
                name: "number",
                label: "#",
                visible: true
            },
            status: {
                name: "status",
                label: "Status",
                visible: true
            },
            customer: {
                name: "customer",
                label: "Customer",
                visible: true
            },
            email: {
                name: "email",
                label: "Email",
                visible: true
            },
            approvedQuotesCount: {
                name: "approvedQuotesCount",
                label: "# Approved Quotes",
                visible: true
            },
            pendingQuotesCount: {
                name: "pendingQuotesCount",
                label: "# Pending Quotes",
                visible: true
            },
            createdDateTime: {
                name: "createdDateTime",
                label: "Created Date",
                visible: true
            },
            createdDateTimeAgo: {
                name: "createdDateTimeAgo",
                label: "Created",
                visible: false
            },
            bondType: {
                name: "bondType",
                label: "Bond Type",
                visible: false
            },
            phone: {
                name: "phone",
                label: "Phone",
                visible: true
            },
            detailsButton: {
                name: "detailsButton",
                label: "Details Button",
                visible: true
            },
            editButton: {
                name: "editButton",
                label: "Edit Button",
                visible: true
            }
        }
    },
    tags: ["List", "Applications", "Todo"],
    carriers: false,
    agents: true,
    mgas: true
};

export const openApplicationsListWidgetCarrier: DashboardWidget = {
    id: WidgetType.dashboardWidgetOpenApplicationsListCarrier,
    name: "Open Applications",
    description: "This displays the list of applications that are still open.",
    thumbnailClass: "fa fa-list",
    image: "/img/dashboardWidgets/openApplicationsWidget.png",
    sizeX: 2,
    sizeY: 2,
    minSizeX: 2,
    directive: WidgetType.dashboardWidgetOpenApplicationsListCarrier,
    options: {
        title: "Applications",
        color: null,
        recordsPerPage: 10,
        orderby: "createdDateTime desc",
        columns: {
            number: {
                name: "number",
                label: "#",
                visible: true
            },
            status: {
                name: "status",
                label: "Status",
                visible: true
            },
            nameOnBond: {
                name: "nameOnBond",
                label: "Name on Bond",
                visible: true
            },
            sfaaCode: {
                name: "sfaaCode",
                label: "SFAA Code",
                visible: true
            },
            approvedQuotesCount: {
                name: "approvedQuotesCount",
                label: "# Approved Quotes",
                visible: true
            },
            pendingQuotesCount: {
                name: "pendingQuotesCount",
                label: "# Pending Quotes",
                visible: true
            },
            bondType: {
                name: "bondType",
                label: "Bond Type",
                visible: true
            },
            state: {
                name: "state",
                label: "State",
                visible: true
            },
            submittedDate: {
                name: "submittedDate",
                label: "Submitted On",
                visible: true
            },
            submittedDateAgo: {
                name: "submittedDateAgo",
                label: "Submitted",
                visible: true
            },
            agency: {
                name: "agency",
                label: "Agency",
                visible: true
            },
            bondAmount: {
                name: "bondAmount",
                label: "Bond Amount",
                visible: true
            },
            desiredEffectiveDate: {
                name: "desiredEffectiveDate",
                label: "Effective Date",
                visible: true
            },
            detailsButton: {
                name: "detailsButton",
                label: "Details Button",
                visible: true
            }
        }
    },
    tags: ["List", "Applications", "Todo"],
    carriers: true,
    agents: false,
    mgas: false
};

export const pendingIndenitySignatures: DashboardWidget = {
    id: WidgetType.dashboardWidgetPendingIndemnitySignatures,
    name: "Pending Indemnity Signatures",
    description:
        "This displays the list of indemnity signatures that have been sent to applicants that still need to be signed.",
    thumbnailClass: "fa fa-list",
    image: "/img/dashboardWidgets/indemnityListWidget.png",
    sizeX: 2,
    sizeY: 2,
    minSizeX: 2,
    directive: WidgetType.dashboardWidgetPendingIndemnitySignatures,
    options: {
        title: "Pending Indemnity Signatures",
        color: null,
        recordsPerPage: 10,
        orderby: "createdDateTime desc",
        columns: {
            name: {
                name: "name",
                label: "Customer",
                visible: true
            },
            submissionDateTime: {
                name: "submissionDateTime",
                label: "Submitted",
                visible: true
            },
            indemnityModalButton: {
                name: "indemnityModalButton",
                label: "Indemnity",
                visible: true
            },
            applicationDetailButton: {
                name: "applicationDetailButton",
                label: "Application Detail",
                visible: true
            }
        }
    },
    tags: ["List", "Applications", "Indemnity Signatures", "Todo"],
    carriers: false,
    agents: true,
    mgas: true
};

export const openApplicationsCountCarrier: DashboardWidget = {
    id: WidgetType.dashboardWidgetOpenApplicationsCountCarrier,
    name: "Open Application Count",
    description: "This displays a count of the current open applications.",
    thumbnailClass: "fa fa-info-circle",
    image: "/img/dashboardWidgets/paymentsWidget.png",
    sizeX: 1,
    sizeY: 1,
    directive: WidgetType.dashboardWidgetOpenApplicationsCountCarrier,
    options: {
        title: "Open Application Count",
        dateReference: {
            label: "Right Now",
            value: "Right Now"
        },
        status: {
            label: "All",
            value: OpenApplicationCounterFilterType.All
        }
    },
    tags: ["Number", "Applications"],
    carriers: true,
    agents: false,
    mgas: false
};

export const openApplicationsCountAgent: DashboardWidget = {
    id: WidgetType.dashboardWidgetOpenApplicationsCountAgent,
    name: "Open Application Count",
    description: "This displays a count of the current open applications.",
    thumbnailClass: "fa fa-info-circle",
    image: "/img/dashboardWidgets/paymentsWidget.png",
    sizeX: 1,
    sizeY: 1,
    directive: WidgetType.dashboardWidgetOpenApplicationsCountAgent,
    options: {
        title: "Open Application Count",
        dateReference: {
            label: "Right Now",
            value: "Right Now"
        },
        status: {
            label: "All",
            value: OpenApplicationCounterFilterType.All
        }
    },
    tags: ["Number", "Applications"],
    carriers: false,
    agents: true,
    mgas: false
};

export const dashboardWidgetPayments: DashboardWidget = {
    id: WidgetType.dashboardWidgetPayments,
    name: "Payments",
    description: "This displays the list of all payments submitted.",
    thumbnailClass: "fa fa-list",
    image: "/img/dashboardWidgets/paymentsWidget.png",
    sizeX: 2,
    sizeY: 2,
    minSizeX: 2,
    directive: WidgetType.dashboardWidgetPayments,
    options: {
        title: "Payments",
        recordsPerPage: 10,
        orderby: "createdDateTime desc",
        filters: {
            status: PaymentStatus.Approved
        },
        columns: {}
    },
    tags: ["List", "Payments"],
    carriers: false,
    agents: true,
    mgas: true
};

export const dashboardWidgetLeads: DashboardWidget = {
    id: WidgetType.dashboardWidgetLeads,
    name: "Leads",
    description:
        "This displays the list of all leads submitted gathered through eProducer.",
    thumbnailClass: "fa fa-list",
    image: "/img/dashboardWidgets/paymentsWidget.png",
    sizeX: 2,
    sizeY: 2,
    minSizeX: 2,
    directive: WidgetType.dashboardWidgetLeads,
    options: {
        title: "Leads",
        color: null,
        recordsPerPage: 10,
        orderby: "createdDateTime desc",
        columns: {
            leadType: {
                name: "leadType",
                label: "Lead Type",
                visible: true
            },
            leadName: {
                name: "name",
                label: "Lead Name",
                visible: true
            },
            phone: {
                name: "phone",
                label: "Phone",
                visible: true
            },
            email: {
                name: "email",
                label: "Email",
                visible: true
            },
            premium: {
                name: "premium",
                label: "Lowest Premium",
                visible: true
            },
            quoteCount: {
                name: "quoteCount",
                label: "Carrier/Quotes",
                visible: true
            },
            createdDateTimeAgo: {
                name: "createdDateTimeAgo",
                label: "Submitted",
                visible: true
            },
            createdDateTime: {
                name: "createdDateTime",
                label: "Submitted Date",
                visible: true
            },
            detailsButton: {
                name: "detailsButton",
                label: "Details",
                visible: true
            }
        }
    },
    tags: ["List", "Leads", "Todo"],
    carriers: false,
    agents: true,
    mgas: true
};

export const dashboardWidgetLeadTotals: DashboardWidget = {
    id: WidgetType.dashboardWidgetLeadTotals,
    name: "Lead Count",
    description:
        "This displays the value of all leads submitted gathered through eProducer in a given time range.",
    thumbnailClass: "fa fa-info-circle",
    image: "/img/dashboardWidgets/paymentsWidget.png",
    sizeX: 1,
    sizeY: 1,
    minSizeX: 1,
    directive: WidgetType.dashboardWidgetLeadTotals,
    options: {
        title: "Lead Count",
        dateReference: {
            label: "This Month",
            value: "This Month"
        },
        leadType: {
            label: "All",
            value: "All"
        }
    },
    tags: ["Number", "Analytics", "Leads"],
    carriers: false,
    agents: true,
    mgas: true
};

export const dashboardWidgetLeadTotalsCarrier: DashboardWidget = {
    id: WidgetType.dashboardWidgetLeadTotalsCarrier,
    name: "Lead Count",
    description:
        "This displays the value of all leads submitted gathered through A3 in a given time range.",
    thumbnailClass: "fa fa-info-circle",
    image: "/img/dashboardWidgets/paymentsWidget.png",
    sizeX: 1,
    sizeY: 1,
    minSizeX: 1,
    directive: WidgetType.dashboardWidgetLeadTotalsCarrier,
    options: {
        title: "Lead Count",
        dateReference: {
            label: "This Month",
            value: "This Month"
        },
        leadType: {
            label: "All",
            value: "All"
        }
    },
    tags: ["Number", "Analytics", "Leads"],
    carriers: true,
    agents: false,
    mgas: false
};

export const dashboardWidgetBondsAgent: DashboardWidget = {
    id: WidgetType.dashboardWidgetBondsAgent,
    name: "Bonds",
    description: "This displays the list of all bonds that have been executed.",
    thumbnailClass: "fa fa-list",
    image: "/img/dashboardWidgets/paymentsWidget.png",
    sizeX: 2,
    sizeY: 2,
    minSizeX: 2,
    directive: WidgetType.dashboardWidgetBondsAgent,
    options: {
        title: "Bond List",
        color: null,
        recordsPerPage: 10,
        orderby: "createdDateTime desc",
        columns: {
            bondNumber: {
                name: "bondNumber",
                label: "Bond Number",
                visible: true
            },
            status: {
                name: "status",
                label: "Status",
                visible: true
            },
            principal: {
                name: "principal",
                label: "Principal",
                visible: true
            },
            bondType: {
                name: "bondType",
                label: "Bond Type",
                visible: true
            },
            bondAmount: {
                name: "bondAmount",
                label: "Bond Amount",
                visible: true
            },
            term: {
                name: "term",
                label: "Term",
                visible: true
            },
            carrier: {
                name: "carrier",
                label: "Carrier",
                visible: true
            },
            createdDateTimeAgo: {
                name: "createdDateTimeAgo",
                label: "Submitted",
                visible: true
            },
            createdDateTime: {
                name: "createdDateTime",
                label: "Submitted On",
                visible: false
            },
            shippingButton: {
                name: "shippingButton",
                label: "Shipping",
                visible: true
            },
            detailsButton: {
                name: "detailsButton",
                label: "Details Button",
                visible: false
            }
        }
    },
    tags: ["List", "Bonds"],
    carriers: false,
    agents: true,
    mgas: true
};

export const dashboardWidgetBondsToRenewAgent: DashboardWidget = {
    id: WidgetType.dashboardWidgetBondsToRenewAgent,
    name: "Bonds to Renew",
    description:
        "This displays the list of bonds that are ready to begin the renewal process.",
    thumbnailClass: "fa fa-list",
    image: "/img/dashboardWidgets/paymentsWidget.png",
    sizeX: 2,
    sizeY: 2,
    minSizeX: 2,
    directive: WidgetType.dashboardWidgetBondsToRenewAgent,
    options: {
        title: "Bonds to Renew",
        color: null,
        recordsPerPage: 10,
        orderby: "expirationDate desc",
        columns: {
            bondNumber: {
                name: "bondNumber",
                label: "Bond Number",
                visible: true
            },
            status: {
                name: "status",
                label: "Status",
                visible: true
            },
            renewalStatus: {
                name: "renewalStatus",
                label: "Renewal Status",
                visible: true
            },
            principal: {
                name: "principal",
                label: "Principal",
                visible: true
            },
            bondType: {
                name: "bondType",
                label: "Bond Type",
                visible: true
            },
            bondAmount: {
                name: "bondAmount",
                label: "Bond Amount",
                visible: true
            },
            term: {
                name: "term",
                label: "Term",
                visible: true
            },
            carrier: {
                name: "carrier",
                label: "Carrier",
                visible: true
            },
            daysTillCancellation: {
                name: "daysTillCancellation",
                label: "Cancellation Starts",
                visible: true
            },
            detailsButton: {
                name: "detailsButton",
                label: "Details Button",
                visible: true
            }
        }
    },
    tags: ["List", "Bonds"],
    carriers: false,
    agents: true,
    mgas: true
};

export const dashboardWidgetBondsCarrier: DashboardWidget = {
    id: WidgetType.dashboardWidgetBondsCarrier,
    name: "Bonds",
    description:
        "WThis displays the list of all bonds that have been executed.",
    thumbnailClass: "fa fa-list",
    image: "/img/dashboardWidgets/paymentsWidget.png",
    sizeX: 2,
    sizeY: 2,
    minSizeX: 2,
    directive: WidgetType.dashboardWidgetBondsCarrier,
    options: {
        title: "Bonds",
        color: null,
        recordsPerPage: 10,
        orderby: "createdDateTime desc",
        columns: {
            bondNumber: {
                name: "bondNumber",
                label: "Bond Number",
                visible: true
            },
            status: {
                name: "status",
                label: "Status",
                visible: true
            },
            principal: {
                name: "principal",
                label: "Principal",
                visible: true
            },
            bondType: {
                name: "bondType",
                label: "Bond Type",
                visible: true
            },
            bondAmount: {
                name: "bondAmount",
                label: "Bond Amount",
                visible: true
            },
            term: {
                name: "term",
                label: "Term",
                visible: true
            },
            carrier: {
                name: "carrier",
                label: "Carrier",
                visible: true
            },
            createdDateTimeAgo: {
                name: "createdDateTimeAgo",
                label: "Submitted",
                visible: true
            },
            createdDateTime: {
                name: "createdDateTime",
                label: "Submitted On",
                visible: false
            },
            shippingButton: {
                name: "shippingButton",
                label: "Shipping",
                visible: true
            },
            detailsButton: {
                name: "detailsButton",
                label: "Details Button",
                visible: false
            }
        }
    },
    tags: ["List", "Bonds"],
    carriers: true,
    agents: false,
    mgas: false
};

export const dashboardWidgetBondTransactionsAgent: DashboardWidget = {
    id: WidgetType.dashboardWidgetBondTransactionsAgent,
    name: "Bond Transactions",
    description: "This displays the list of all recorded bond transactions.",
    thumbnailClass: "fa fa-list",
    image: "/img/dashboardWidgets/paymentsWidget.png",
    sizeX: 2,
    sizeY: 2,
    minSizeX: 2,
    directive: WidgetType.dashboardWidgetBondTransactionsAgent,
    options: {
        title: "Bond Transactions",
        color: null,
        orderby: "createdDateTime desc",
        recordsPerPage: 10,
        columns: {
            bondNumber: {
                name: "bondNumber",
                label: "Bond Number",
                visible: true
            },
            transactionType: {
                name: "transactionType",
                label: "Transaction Type",
                visible: true
            },
            nameOnBond: {
                name: "nameOnBond",
                label: "Principal",
                visible: true
            },
            bondType: {
                name: "bondType",
                label: "Bond Type",
                visible: true
            },
            carrier: {
                name: "carrier",
                label: "Carrier",
                visible: true
            },
            premium: {
                name: "premium",
                label: "Premium",
                visible: true
            },
            commission: {
                name: "commission",
                label: "Commission",
                visible: true
            },
            refundedPremium: {
                name: "refundedPremium",
                label: "Premium Refund",
                visible: true
            },
            fees: {
                name: "fees",
                label: "Fees",
                visible: true
            },
            effectiveDate: {
                name: "effectiveDate",
                label: "Effective Date",
                visible: true
            },
            createdDateTimeAgo: {
                name: "createdDateTimeAgo",
                label: "Submitted",
                visible: true
            },
            createdDateTime: {
                name: "createdDateTime",
                label: "Submitted On",
                visible: false
            },
            shippingButton: {
                name: "shippingButton",
                label: "Shipping",
                visible: true
            },
            bondDetailsButton: {
                name: "bondDetailsButton",
                label: "Bond Details",
                visible: false
            },
            documentsButton: {
                name: "documentsButton",
                label: "Documents",
                visible: false
            }
        }
    },
    tags: ["List", "Bonds"],
    carriers: false,
    agents: true,
    mgas: true
};

export const dashboardWidgetBondTransactionsCarrier: DashboardWidget = {
    id: WidgetType.dashboardWidgetBondTransactionsCarrier,
    name: "Bond Transactions",
    description: "This displays the list of all recorded bond transactions.",
    thumbnailClass: "fa fa-list",
    image: "/img/dashboardWidgets/paymentsWidget.png",
    sizeX: 2,
    sizeY: 2,
    minSizeX: 2,
    directive: WidgetType.dashboardWidgetBondTransactionsCarrier,
    options: {
        title: "Bond Transactions",
        color: null,
        orderby: "createdDateTime desc",
        recordsPerPage: 10,
        columns: {
            bondNumber: {
                name: "bondNumber",
                label: "Bond Number",
                visible: true
            },
            transactionType: {
                name: "transactionType",
                label: "Transaction Type",
                visible: true
            },
            nameOnBond: {
                name: "nameOnBond",
                label: "Principal",
                visible: true
            },
            effectiveDate: {
                name: "effectiveDate",
                label: "Effective Date",
                visible: true
            },
            createdDateTime: {
                name: "createdDateTime",
                label: "Occured On",
                visible: true
            },
            createdDateTimeAgo: {
                name: "createdDateTimeAgo",
                label: "Occurred",
                visible: false
            },
            detailButton: {
                name: "detailButton",
                label: "Detail Button",
                visible: true
            }
        }
    },
    tags: ["List", "Bonds"],
    carriers: true,
    agents: false,
    mgas: false
};

export const dashboardWidgetTransactionCount: DashboardWidget = {
    id: WidgetType.dashboardWidgetTransactionCount,
    name: "Transaction Count",
    description:
        "This displays a count of transactions that have come through A3 during specified time intervals.",
    thumbnailClass: "fa fa-info-circle",
    image: "/img/dashboardWidgets/paymentsWidget.png",
    sizeX: 1,
    sizeY: 1,
    directive: WidgetType.dashboardWidgetTransactionCount,
    options: {
        title: "Transaction Count",
        dateReference: {
            label: "This Month",
            value: "This Month"
        },
        transactionType: {
            label: "All",
            value: "All"
        }
    },
    tags: ["Number", "Bonds"],
    carriers: false,
    agents: true,
    mgas: true
};

export const dashboardWidgetTransactionCountCarrier: DashboardWidget = {
    id: WidgetType.dashboardWidgetTransactionCountCarrier,
    name: "Transaction Counts",
    description:
        "This displays a count of transactions that have come through A3 during specified time intervals.",
    thumbnailClass: "fa fa-info-circle",
    image: "/img/dashboardWidgets/paymentsWidget.png",
    sizeX: 1,
    sizeY: 1,
    directive: WidgetType.dashboardWidgetTransactionCountCarrier,
    options: {
        title: "Transaction Count",
        dateReference: {
            label: "This Month",
            value: "This Month"
        },
        transactionType: {
            label: "All",
            value: "All"
        }
    },
    tags: ["Number", "Bonds"],
    carriers: true,
    agents: false,
    mgas: false
};

export const dashboardWidgetTransactionSum: DashboardWidget = {
    id: WidgetType.dashboardWidgetTransactionSum,
    name: "Premium Total",
    description:
        "This displays a sum of transactions premium that have come through A3 during specified time intervals.",
    thumbnailClass: "fa fa-info-circle",
    image: "/img/dashboardWidgets/paymentsWidget.png",
    sizeX: 1,
    sizeY: 1,
    directive: WidgetType.dashboardWidgetTransactionSum,
    options: {
        title: "Premium Total",
        dateReference: {
            label: "This Month",
            value: "This Month"
        },
        transactionType: {
            label: "All",
            value: "All"
        }
    },
    tags: ["Total", "Bonds"],
    carriers: false,
    agents: true,
    mgas: true
};

export const dashboardWidgetTransactionSumCarrier: DashboardWidget = {
    id: WidgetType.dashboardWidgetTransactionSumCarrier,
    name: "Premium Total",
    description:
        "This displays a sum of transactions premium that have come through A3 during specified time intervals.",
    thumbnailClass: "fa fa-info-circle",
    image: "/img/dashboardWidgets/paymentsWidget.png",
    sizeX: 1,
    sizeY: 1,
    directive: WidgetType.dashboardWidgetTransactionSumCarrier,
    options: {
        title: "Premium Total",
        dateReference: {
            label: "This Month",
            value: "This Month"
        },
        transactionType: {
            label: "All",
            value: "All"
        }
    },
    tags: ["Total", "Bonds"],
    carriers: true,
    agents: false,
    mgas: false
};

export const dashboardWidgetBondsByCarrierBreakdownAgent: DashboardWidget = {
    id: WidgetType.dashboardWidgetBondsByCarrierBreakdownAgent,
    name: "Bonds By Carrier Breakdown",
    description:
        "This widget shows a breakdown of active bond counts by carriers.",
    thumbnailClass: "fa fa-pie-chart",
    image: "/img/dashboardWidgets/activityTracker.png",
    sizeX: 2,
    sizeY: 2,
    minSizeX: 1,
    minSizeY: 1,
    directive: WidgetType.dashboardWidgetBondsByCarrierBreakdownAgent,
    options: {
        title: "Bonds by Carrier"
    },
    tags: ["Chart", "Bonds"],
    carriers: false,
    agents: true,
    mgas: true
};

export const dashboardWidgetBondsByAgentBreakdownCarrier: DashboardWidget = {
    id: WidgetType.dashboardWidgetBondsByAgentBreakdownCarrier,
    name: "Bonds By Agency Breakdown",
    description:
        "This widget shows a breakdown of active bond counts by agencies.",
    thumbnailClass: "fa fa-pie-chart",
    image: "/img/dashboardWidgets/activityTracker.png",
    sizeX: 2,
    sizeY: 2,
    minSizeX: 1,
    minSizeY: 1,
    directive: WidgetType.dashboardWidgetBondsByAgentBreakdownCarrier,
    options: {
        title: "Bonds by Agency"
    },
    tags: ["Chart", "Bonds"],
    carriers: true,
    agents: false,
    mgas: false
};

export const importBondsWidget: DashboardWidget = {
    id: WidgetType.importBondsWidget,
    name: "Bond Import",
    description:
        "This widget provides tools to allow bonds to be imported into A3",
    thumbnailClass: "fa fa-pie-chart",
    image: "/img/dashboardWidgets/activityTracker.png",
    sizeX: 4,
    sizeY: 2,
    minSizeX: 4,
    minSizeY: 2,
    directive: WidgetType.importBondsWidget,
    options: {
        title: "Bond Import"
    },
    tags: ["Bonds", "Import"],
    carriers: false,
    agents: true,
    mgas: false
};

export const dashboardWidgetCancellationRequests: DashboardWidget = {
    id: WidgetType.dashboardWidgetCancellationRequests,
    name: "Cancellation Requests",
    description:
        "This displays a list of cancellation requests that have been submitted but have not yet been approved or declined by the carrier",
    thumbnailClass: "fa fa-list",
    image: "/img/dashboardWidgets/cancellationRequests.png",
    sizeX: 2,
    sizeY: 2,
    minSizeX: 2,
    directive: WidgetType.dashboardWidgetCancellationRequests,
    options: {
        title: "Cancellation Requests",
        color: null,
        recordsPerPage: 10,
        orderby: "requestedCancellationDate desc",
        columns: {
            nameOnBond: {
                name: "nameOnBond",
                label: "Principal",
                visible: true
            },
            carrier: {
                name: "carrier",
                label: "Carrier",
                visible: true
            },
            agency: {
                name: "agency",
                label: "Agency",
                visible: true
            },
            requestedCancellationDate: {
                name: "cancellationDate",
                label: "Cancellation Date",
                visible: true
            },
            createdDateTimeAgo: {
                name: "submitted",
                label: "Submitted",
                visible: true
            }
        }
    },
    tags: ["List", "Cancellations", "Transactions", "Todo"],
    carriers: true,
    agents: true,
    mgas: true
};

export const dashboardWidgetRiderRequests: DashboardWidget = {
    id: WidgetType.dashboardWidgetRiderRequests,
    name: "Rider Requests",
    description:
        "This displays a list of rider requests that have been submitted but have not yet been approved or declined by the carrier",
    thumbnailClass: "fa fa-list",
    image: "/img/dashboardWidgets/riderRequests.png",
    sizeX: 2,
    sizeY: 2,
    minSizeX: 2,
    directive: WidgetType.dashboardWidgetRiderRequests,
    options: {
        title: "Rider Requests",
        columns: {
            nameOnBond: {
                name: "nameOnBond",
                label: "Principal",
                visible: true
            },
            carrier: {
                name: "carrier",
                label: "Carrier",
                visible: true
            },
            agency: {
                name: "agency",
                label: "Agency",
                visible: true
            },
            requestedEffectiveDate: {
                name: "requestedEffectiveDate",
                label: "Effective Date",
                visible: true
            },
            createdDateTime: {
                name: "submitted",
                label: "Submitted",
                visible: true
            }
        }
    },
    tags: ["List", "Riders", "Transactions", "Todo"],
    carriers: true,
    agents: true,
    mgas: true
};

export const dashboardWidgetReinstatementRequests: DashboardWidget = {
    id: WidgetType.dashboardWidgetReinstatementRequests,
    name: "Reinstatement Requests",
    description:
        "This displays a list of reinstatement requests that have been submitted but have not yet been approved or declined by the carrier",
    thumbnailClass: "fa fa-list",
    image: "/img/dashboardWidgets/reinstatementRequests.png",
    sizeX: 2,
    sizeY: 2,
    minSizeX: 2,
    directive: WidgetType.dashboardWidgetReinstatementRequests,
    options: {
        title: "Reinstatement Requests",
        color: null,
        recordsPerPage: 10,
        orderby: "createdDateTime desc",
        columns: {
            nameOnBond: {
                name: "nameOnBond",
                label: "Principal",
                visible: true
            },
            carrier: {
                name: "carrier",
                label: "Carrier",
                visible: true
            },
            agency: {
                name: "agency",
                label: "Agency",
                visible: true
            },
            requestedCancellationDate: {
                name: "requestedEffectiveDate",
                label: "Effective Date",
                visible: true
            },
            createdDateTime: {
                name: "createdDateTime",
                label: "Submitted",
                visible: true
            }
        }
    },
    tags: ["List", "Riders", "Transactions", "Todo"],
    carriers: true,
    agents: true,
    mgas: true
};

export const dashboardWidgetActivityTracker: DashboardWidget = {
    id: WidgetType.dashboardWidgetActivityTracker,
    name: "Activity Tracker",
    description:
        "Presents A3 usage by tracking leads, applications, new business and renewals in an interactive graph.",
    thumbnailClass: "fa fa-area-chart",
    image: "/img/dashboardWidgets/activityTracker.png",
    sizeX: 4,
    sizeY: 2,
    minSizeX: 4,
    minSizeY: 2,
    directive: WidgetType.dashboardWidgetActivityTracker,
    options: {
        title: "Activity Tracker",
        filters: {
            transactionType: {
                label: "All Transaction Types",
                value: null
            }
        }
    },
    tags: ["Chart", "Transactions"],
    carriers: false,
    agents: true,
    mgas: true
};

export const dashboardWidgetActivityTrackerCarrier: DashboardWidget = {
    id: WidgetType.dashboardWidgetActivityTrackerCarrier,
    name: "Activity Tracker",
    description:
        "Presents A3 usage by tracking quoted applications and transactions in an interactive graph.",
    thumbnailClass: "fa fa-area-chart",
    image: "/img/dashboardWidgets/activityTracker.png",
    sizeX: 4,
    sizeY: 2,
    minSizeX: 4,
    minSizeY: 2,
    directive: WidgetType.dashboardWidgetActivityTrackerCarrier,
    options: {
        title: "Activity Tracker"
    },
    tags: ["Chart", "Transactions"],
    carriers: true,
    agents: false,
    mgas: false
};

export const dashboardWidgetStateActivity: DashboardWidget = {
    id: WidgetType.dashboardWidgetStateActivity,
    name: "Transactions By State",
    description:
        "Presents the transactions distributed across an interactive map that allows you to easily see where your business is being placed.",
    thumbnailClass: "fa fa-area-chart",
    image: "/img/dashboardWidgets/activityTracker.png",
    sizeX: 2,
    sizeY: 2,
    minSizeX: 2,
    minSizeY: 2,
    directive: WidgetType.dashboardWidgetStateActivity,
    options: {
        title: "Transactions by State",
        filters: {
            transactionType: {
                label: "All Transaction Types",
                value: null
            },
            selectedSfaaCode: {
                label: "All SFAA Codes",
                value: null
            }
        }
    },
    tags: ["Map", "Transactions"],
    carriers: false,
    agents: true,
    mgas: true
};

export const dashboardWidgetStateActivityCarrier: DashboardWidget = {
    id: WidgetType.dashboardWidgetStateActivityCarrier,
    name: "Activity By State",
    description:
        "Presents the useage of A3 on an interactive map that allows you to easily see where your business is being placed.",
    thumbnailClass: "fa fa-area-chart",
    image: "/img/dashboardWidgets/activityTracker.png",
    sizeX: 4,
    sizeY: 2,
    minSizeX: 2,
    minSizeY: 2,
    directive: WidgetType.dashboardWidgetStateActivityCarrier,
    options: {
        title: "Transactions by State",
        filters: {
            transactionType: {
                label: "All Transaction Types",
                value: null
            },
            selectedSfaaCode: {
                label: "All SFAA Codes",
                value: null
            }
        }
    },
    tags: ["Map", "Transactions"],
    carriers: true,
    agents: false,
    mgas: false
};

export const dashboardWidgetBondTypeConfigurationList: DashboardWidget = {
    id: WidgetType.dashboardWidgetBondTypeConfigurationList,
    name: "Bond Type Configurations",
    description:
        "This displays the list of bond types that are configured in A3 as well as the progress in getting each type to production.",
    thumbnailClass: "fa fa-list",
    image: "/img/dashboardWidgets/openApplicationsWidget.png",
    sizeX: 4,
    sizeY: 2,
    minSizeX: 2,
    directive: WidgetType.dashboardWidgetBondTypeConfigurationList,
    options: {
        title: "Bond Type Configurations",
        color: null,
        recordsPerPage: 10,
        orderby: "createdDateTime desc",
        columns: {
            flaggedForReview: {
                name: "flaggedForReview",
                label: "Review Status",
                visible: true
            },
            bondTypename: {
                name: "name",
                label: "Name",
                visible: true
            },
            isActive: {
                name: "isActive",
                label: "Is Active",
                visible: true
            },
            sfaaCode: {
                name: "sfaaCode",
                label: "Sfaa Code",
                visible: true
            },
            sfaaDescription: {
                name: "sfaaDescription",
                label: "Sfaa Description",
                visible: false
            },
            createdDateTimeAgo: {
                name: "createdDateTimeAgo",
                label: "Created Time ago",
                visible: true
            },
            createdDateTime: {
                name: "createdDateTime",
                label: "Created Date",
                visible: false
            },
            progress: {
                name: "progress",
                label: "Progress Indicators",
                visible: true
            },
            historyButton: {
                name: "historyButton",
                label: "History Button",
                visible: false
            }
        }
    },
    tags: ["List", "Configurations"],
    carriers: true,
    agents: true,
    mgas: true
};

export const dashboardWidgetBondTypeChangesCount: DashboardWidget = {
    id: WidgetType.dashboardWidgetBondTypeChangesCount,
    name: "Bond Type Changes Counts",
    description:
        "This displays the count of changes to bond type configurations.",
    thumbnailClass: "fa fa-info-circle",
    image: "/img/dashboardWidgets/paymentsWidget.png",
    sizeX: 1,
    sizeY: 1,
    minSizeX: 1,
    directive: WidgetType.dashboardWidgetBondTypeChangesCount,
    options: {
        title: "Bond Type Changes",
        dateReference: {
            label: "This Month",
            value: "This Month"
        },
        transactionType: {
            label: "All",
            value: "All"
        }
    },
    tags: ["Number", "Configurations"],
    carriers: true,
    agents: true,
    mgas: true
};

export const dashboardWidgetBondTypeConfigurationCount: DashboardWidget = {
    id: WidgetType.dashboardWidgetBondTypeConfigurationCount,
    name: "Bond Type Configuration Counts",
    description: "This displays the count of bond type configurations.",
    thumbnailClass: "fa fa-info-circle",
    image: "/img/dashboardWidgets/paymentsWidget.png",
    sizeX: 1,
    sizeY: 1,
    minSizeX: 1,
    directive: WidgetType.dashboardWidgetBondTypeConfigurationCount,
    options: {
        title: "Bond Type Configurations",
        dateReference: {
            label: "Right Now",
            value: "This Month"
        },
        transactionType: {
            label: "All",
            value: "All"
        }
    },
    tags: ["Number", "Configurations"],
    carriers: true,
    agents: true,
    mgas: true
};

export const dashboardWidgetBondFormCount: DashboardWidget = {
    id: WidgetType.dashboardWidgetBondFormCount,
    name: "Bond Form Counts",
    description:
        "This displays the count of bond forms in the document library.",
    thumbnailClass: "fa fa-info-circle",
    image: "/img/dashboardWidgets/paymentsWidget.png",
    sizeX: 1,
    sizeY: 1,
    minSizeX: 1,
    directive: WidgetType.dashboardWidgetBondFormCount,
    options: {
        title: "Bond Forms",
        dateReference: {
            label: "Right Now",
            value: "Right Now"
        },
        status: {
            label: "All",
            value: "All"
        }
    },
    tags: ["Number", "Configurations"],
    carriers: true,
    agents: true,
    mgas: true
};

export const dashboardWidgetBondTypeConfigurationsByState: DashboardWidget = {
    id: WidgetType.dashboardWidgetBondTypeConfigurationsByState,
    name: "Bond Types By State",
    description:
        "Presents the number of bond types configured in A3 on a US state map.",
    thumbnailClass: "fa fa-area-chart",
    image: "/img/dashboardWidgets/activityTracker.png",
    sizeX: 2,
    sizeY: 2,
    minSizeX: 1,
    minSizeY: 1,
    directive: WidgetType.dashboardWidgetBondTypeConfigurationsByState,
    options: {
        title: "Bond Types by State",
        filters: {
            type: {
                label: "Active Bond Types",
                value: "active"
            }
        }
    },
    tags: ["Map", "Configurations"],
    carriers: true,
    agents: true,
    mgas: true
};

export const dashboardWidgetBondTypeProgress: DashboardWidget = {
    id: WidgetType.dashboardWidgetBondTypeProgress,
    name: "Bond Type Progress",
    description: "Tracks the bond types that have been added to A3 in a graph.",
    thumbnailClass: "fa fa-area-chart",
    image: "/img/dashboardWidgets/activityTracker.png",
    sizeX: 4,
    sizeY: 2,
    minSizeX: 2,
    minSizeY: 1,
    directive: WidgetType.dashboardWidgetBondTypeProgress,
    options: {
        title: "Bond Type Progress"
    },
    tags: ["Chart", "Configurations"],
    carriers: true,
    agents: true,
    mgas: true
};

export const dashboardWidgetUnderwritingConfigurationsCarrier: DashboardWidget =
    {
        id: WidgetType.dashboardWidgetUnderwritingConfigurationsCarrier,
        name: "Underwriting Configurations",
        description:
            "Shows you the number of underwriting configurations for each of the bond types in A3.",
        thumbnailClass: "fa fa-list",
        image: "/img/dashboardWidgets/paymentsWidget.png",
        sizeX: 2,
        sizeY: 2,
        minSizeX: 1,
        minSizeY: 1,
        directive: WidgetType.dashboardWidgetUnderwritingConfigurationsCarrier,
        options: {
            title: "Underwriting Configurations",
            color: null,
            recordsPerPage: 10,
            orderby: "createdDateTime desc",
            columns: {
                bondTypeIsActive: {
                    name: "bondTypeIsActive",
                    label: "Bond Type Status",
                    visible: true
                },
                bondTypeName: {
                    name: "bondTypeName",
                    label: "Bond Type Name",
                    visible: true
                },
                requisitioningState: {
                    name: "requisitioningState",
                    label: "State",
                    visible: true
                },
                sfaaCode: {
                    name: "sfaaCode",
                    label: "Sfaa Code",
                    visible: true
                },
                numberOfConfigurations: {
                    name: "numberOfConfigurations",
                    label: "# of U/W Configs",
                    visible: true
                }
            }
        },
        tags: ["List", "Configurations", "Underwriting"],
        carriers: true,
        agents: false,
        mgas: false
    };

export const dashboardWidgetNewlyActiveBondTypes: DashboardWidget = {
    id: WidgetType.dashboardWidgetNewlyActiveBondTypes,
    name: "Newly Active Bond Types",
    description:
        "This widget lists the most recently activated bond types in A3.",
    thumbnailClass: "fa fa-list",
    image: "/img/dashboardWidgets/cancellationRequests.png",
    sizeX: 3,
    sizeY: 2,
    minSizeX: 2,
    directive: WidgetType.dashboardWidgetNewlyActiveBondTypes,
    options: {
        title: "Newly Active Bond Types"
    },
    tags: ["List", "Configurations", "Bond Types"],
    carriers: true,
    agents: true,
    mgas: true
};

export const activityListWidget: DashboardWidget = {
    id: WidgetType.activityListWidget,
    name: "Activity List",
    description: "Displays a list of all activities",
    thumbnailClass: "fa fa-list",
    image: "/img/dashboardWidgets/openApplicationsWidget.png",
    sizeX: 4,
    sizeY: 2,
    minSizeX: 3,
    directive: WidgetType.activityListWidget,
    options: {
        title: "Activity List"
    },
    tags: ["List", "Activities"],
    carriers: false,
    agents: true,
    mgas: false
};

const widgetDefinitions: Dashboard[] = [
    {
        name: "Applications",
        widgetTypes: [
            openApplicationsListWidget,
            openApplicationsListWidgetCarrier,
            pendingIndenitySignatures,
            openApplicationsCountCarrier,
            openApplicationsCountAgent
        ]
    },
    {
        name: "Payments",
        widgetTypes: [dashboardWidgetPayments]
    },
    {
        name: "Leads",
        widgetTypes: [
            dashboardWidgetLeads,
            dashboardWidgetLeadTotals,
            dashboardWidgetLeadTotalsCarrier
        ]
    },
    {
        name: "Bonds",
        widgetTypes: [
            dashboardWidgetBondsAgent,
            dashboardWidgetBondsToRenewAgent,
            dashboardWidgetBondsCarrier,
            dashboardWidgetBondTransactionsAgent,
            dashboardWidgetBondTransactionsCarrier,
            dashboardWidgetTransactionCount,
            dashboardWidgetTransactionCountCarrier,
            dashboardWidgetTransactionSum,
            dashboardWidgetTransactionSumCarrier,
            dashboardWidgetBondsByCarrierBreakdownAgent,
            dashboardWidgetBondsByAgentBreakdownCarrier,
            importBondsWidget
        ]
    },
    {
        name: "Requests",
        widgetTypes: [
            dashboardWidgetCancellationRequests,
            dashboardWidgetRiderRequests,
            dashboardWidgetReinstatementRequests
        ]
    },
    {
        name: "Analytics",
        widgetTypes: [
            dashboardWidgetActivityTracker,
            dashboardWidgetActivityTrackerCarrier,
            dashboardWidgetStateActivity,
            dashboardWidgetStateActivityCarrier
        ]
    },
    {
        name: "Configurations",
        widgetTypes: [
            dashboardWidgetBondTypeConfigurationList,
            dashboardWidgetBondTypeChangesCount,
            dashboardWidgetBondTypeConfigurationCount,
            dashboardWidgetBondFormCount,
            dashboardWidgetBondTypeConfigurationsByState,
            dashboardWidgetBondTypeProgress,
            dashboardWidgetUnderwritingConfigurationsCarrier,
            dashboardWidgetNewlyActiveBondTypes
        ]
    },
    {
        name: "Activities",
        widgetTypes: [activityListWidget]
    }
];
deepFreeze(widgetDefinitions);

export default widgetDefinitions;
