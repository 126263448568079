import {
    ODataFactory,
    ODataFilterCollection,
    ODataFilterDataType,
    ODataEndpoint
} from "../../../../../api/odata";
import { Application } from "../../../../../api/types/model/application";
import { SelectOption } from "../../../../../api/types/selectOption";
import { BusyIndicator } from "../../../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../../../configuration/injectables";
import { ModalOpener } from "../../../../../modals/modalOpener";
import { DashboardService } from "../../dashboardService";
import { DashboardWidget } from "../../types/dashboardWidget";
import app from "../../../../../main";
import A3ApiResponse from "../../../../../api/types/a3ApiResponse";
import { isDefined } from "angular";
import { IDebounceDelayer } from "../../../../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../../../../utilities/tables/table";
import { CurrentUserResolver } from "../../../../../utilities/currentUserResolver/currentUserResolver";

class DashboardWidgetPendingIndemnitySignaturesController {
    public static $inject = [
        Injectables.CurrentUserResolver,
        Injectables.ODataFactory,
        Injectables.DashboardService,
        Injectables.ModalOpener,
        Injectables.IDebouceDelayer
    ];

    constructor(
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly odata: ODataFactory,
        private readonly dashboardService: DashboardService,
        private readonly modalOpener: ModalOpener,
        debouceDelayer: IDebounceDelayer
    ) {
        this.table = new Table(debouceDelayer);
    }

    public table: Table<Application, null>;
    public widget: DashboardWidget;
    public busyIndicator: BusyIndicator;
    public filterCollection: ODataFilterCollection;
    public errorMessage: string;
    public sfaaCodeOptions: SelectOption<string>[];
    public tagOptions: SelectOption<string>[];
    public carrierOptions: SelectOption<number>[];

    public setFilters = () => {
        this.filterCollection = this.odata.getFilterCollection([
            this.odata.getFilter(
                "bondAmount",
                ODataFilterDataType.money,
                "Bond Amount"
            ),
            this.odata.getFilter(
                "submissionDateTime",
                ODataFilterDataType.date,
                "Submitted"
            ),
            this.odata.getFilter(
                "bondType/name",
                ODataFilterDataType.string,
                "Bond Type"
            ),
            this.odata.getFilter(
                "nameOnBond",
                ODataFilterDataType.string,
                "Name"
            ),
            this.odata.getFilter(
                "desiredEffectiveDate",
                ODataFilterDataType.date,
                "Effective"
            ),
            this.odata.getFilter(
                "customer/eProducerAccount/name",
                ODataFilterDataType.string,
                "Broker Name",
                null,
                null,
                !this.currentUserResolver.getCurrentUser().systemAccount
                    .isCarrier
            ),
            this.odata.getFilter(
                "customer/eProducerAccount/agencyCode",
                ODataFilterDataType.string,
                "Broker Agency Code",
                null,
                null,
                !this.currentUserResolver.getCurrentUser().systemAccount
                    .isCarrier
            ),
            this.odata.getFilter(
                "customer/eProducerAccount/agencySubCode",
                ODataFilterDataType.string,
                "Broker Sub Code",
                null,
                null,
                !this.currentUserResolver.getCurrentUser().systemAccount
                    .isCarrier
            )
        ]);
    };

    public saveRecordsPerPage = () => {
        this.widget.options.recordsPerPage = this.table.pager.recordsPerPage;
        this.dashboardService.save();
    };

    public saveOrderby = () => {
        this.widget.options.orderby = this.table.sorter.sortBy;
        this.dashboardService.save();
    };

    public buildQuery = () => {
        const query = this.odata
            .getQuery()
            .orderby(this.table.sorter.sortBy)
            .skip(
                (this.table.pager.currentPage - 1) *
                    this.table.pager.recordsPerPage
            )
            .top(this.table.pager.recordsPerPage)
            .filter(
                "quotes/any(q:q/indemnitySignatures/any(signature:signature/status eq 'Sent')) and status eq 'Submitted'"
            )
            .expand(
                "quotes($filter=indemnitySignatures/any(s:s/status eq 'Sent');$select=indemnitySignatures,id;$expand=indemnitySignatures),customer($select=customerType,name,people,companies;$expand=people($select=firstName,lastName),companies($select=name))"
            )
            .select("quotes,customer,submissionDateTime,customerId");

        const filterExpr = this.filterCollection.getFilterExpression();
        if (filterExpr) {
            query.filter(filterExpr);
        }

        return query;
    };

    public loadGrid = () => {
        if (this.table.sorter.sortBy !== this.widget.options.orderby) {
            this.saveOrderby();
        }

        if (
            this.table.pager.recordsPerPage !=
            this.widget.options.recordsPerPage
        ) {
            this.saveRecordsPerPage();
        }

        let query = this.buildQuery();

        const dataSvc = this.odata.getService(ODataEndpoint.Application);

        this.busyIndicator.promise = dataSvc
            .get<A3ApiResponse<Application[]>>(query)
            .then((response) => {
                this.table.setData(
                    response.data.value,
                    response.data["@odata.count"]
                );
            })
            .catch(() => {
                this.errorMessage =
                    "An error occurred trying to get the widget data";
            });
    };

    public showIndemnityModal = (quote) => {
        this.modalOpener
            .indemnityModal(quote.id)
            .result.then(() => {})
            .catch(() => {});
    };

    public $onInit = () => {
        this.busyIndicator = {
            message: "Loading..."
        };

        this.sfaaCodeOptions = [];
        this.carrierOptions = [];
        this.tagOptions = [];

        this.setFilters();

        if (isDefined(this.widget.options.filters)) {
            this.filterCollection.mergeFilters(this.widget.options.filters);
        }

        this.loadGrid();

        this.dashboardService.refreshFunctions.push(this.loadGrid);
        this.table.onChange = this.loadGrid;
    };
}

const dashboardWidgetPendingIndemnitySignaturesComponent = {
    templateUrl:
        "app/states/common/dashboard/widgets/indemnitySignatures/dashboardWidgetPendingIndemnitySignatures.html",
    bindings: {
        widget: "="
    },
    controllerAs: "vm",
    controller: DashboardWidgetPendingIndemnitySignaturesController
};

app.component(
    "dashboardWidgetPendingIndemnitySignatures",
    dashboardWidgetPendingIndemnitySignaturesComponent
);
