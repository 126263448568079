export interface BankruptcyDisplay {
    dateFiled: string;
    customerNumber: string;
    courtName: string;
    industry: string;
    caseNumber: string;
    type: string;
    filer: string;
    disposition: string;
    liabilityAmount: string;
    assetAmount: string;
    exemptAmount: string;
    verificationDate: string;
    priorIntentCode: string;
    dateReported: string;
    narratives?: string[];
}