import { AuthService } from "../../../api/authService";
import { Injectables } from "../../../configuration/injectables";
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { State } from "../../state";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { User } from "../../../api/types/model/user";

export class ChangePasswordStateParams {
    public email: string;
    public passwordResetKey: string;
}

export class ChangePasswordController {
    public static $inject = [
        Injectables.$state,
        Injectables.$stateParams,
        Injectables.CurrentUserResolver,
        Injectables.AuthService,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private $state: State,
        private $stateParams: ChangePasswordStateParams,
        private currentUserResolver: CurrentUserResolver,
        private authService: AuthService,
        private toastMessageCreator: ToastMessageCreator
    ) {}

    public password: string;
    public message: string;
    public incorrectPassword: boolean;
    public confirmPassword: string;
    public formSubmitted = false;
    public isLoading = false;

    public changePassword(formValid: boolean): void {
        this.incorrectPassword = false;
        this.formSubmitted = true;
        
        if (this.password !== this.confirmPassword) {
            this.message = "Passwords do not match";
            this.incorrectPassword = true;
            return;
        }

        if (formValid) {
            const regExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;

            if (!regExp.test(this.password)) {
                this.message = "Password requires 1 upper-case, 1 lower-case and 1 numeric ";
                return;
            }

            this.isLoading = true;

            this.authService
                .changePassword(
                    this.$stateParams.email,
                    this.password,
                    this.$stateParams.passwordResetKey
                )
                .then(() => {
                    this.isLoading = false;

                    return this.authService.login({email: this.$stateParams.email, password: this.password } as User)
                        .then(() => {
                            this.toastMessageCreator.createSuccessMessage("Your password has been updated");
                        });
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.message = err.message;
                });
        }
    }

    public cancel(): void {
        this.$state.go("login");
    }

    public $onInit(): void {
        if (this.currentUserResolver.getCurrentUser()?.isLoggedIn()) {
            this.$state.go("main.dashboard");
            return;
        }

        this.message = "";
    }
}
