import { IPromise, IQService } from "angular";
import { SystemAccountService } from "../../../api/systemAccountService";
import { BondNumberGroup } from "../../../api/types/model/bondNumberGroup";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../configuration/injectables";
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { State } from "../../state";
import { BondNumberDetailStateParams } from "./bondNumberDetailStateParams";


export class BondNumberDetailController {
    
    public static $inject = [
        Injectables.$stateParams,
        Injectables.$state,
        Injectables.SystemAccountService,
        Injectables.$q,
        Injectables.ToastMessageCreator,
        Injectables.CurrentUserResolver
    ];

    constructor(
        private readonly $stateParams: BondNumberDetailStateParams,
        private readonly $state: State,
        private readonly systemAccountService: SystemAccountService,
        private readonly $q: IQService,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly currentUserResolver: CurrentUserResolver
    ) {
    }

    public bondNumberGroup: BondNumberGroup;
    public integratedCarrier: boolean;
    public busyIndicator: BusyIndicator;

    private loadBondNumberGroup(): IPromise<any> {
        return this.systemAccountService.getBondNumberGroupById(this.$stateParams.id)
            .then((bondNumberGroup) => {
                this.bondNumberGroup = bondNumberGroup;
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to load the bond number group');
            });
    }

    private loadHasBondNumberIntegration(): IPromise<any> {
        return this.systemAccountService
            .getHasBondNumberIntegration(this.currentUserResolver.getCurrentUser().systemAccount.id)
            .then((response) => {
                this.integratedCarrier = response;
            });
    }

    public save(): void {
        this.busyIndicator.message = 'Saving...';
        this.busyIndicator.promise = this.systemAccountService.saveBondNumberGroup(this.bondNumberGroup)
            .then(() => {
                this.$state.go('main.bondNumbersGrid');
                this.toastMessageCreator.createSuccessMessage('Bond number saved successfully');
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to save the bond number');    
            });
    }

    public $onInit(): void {
        this.busyIndicator = {
            message: 'Loading...'
        };

        this.bondNumberGroup = {} as BondNumberGroup;
        
        const promises: IPromise<any>[] = [];

        if (this.$stateParams.id) {
            promises.push(this.loadBondNumberGroup());
        }

        promises.push(this.loadHasBondNumberIntegration());

        this.busyIndicator.promise = this.$q.all(promises);
    }
}
