import * as moment from "moment";
import app from "../../main";
import { DateOnlyFilter } from "./dateOnlyFilterType";

export const dateOnlyFilter = (): DateOnlyFilter => {

    return (date: Date | string)  => {
    
        if (!date) {
            return '';
        }

        let returnDate = moment.utc(date);

        if (!returnDate.isValid()) {
            return '';
        }

        return returnDate.format('L'); // MM/DD/YYYY
    };
}

app.filter('dateOnly', dateOnlyFilter);