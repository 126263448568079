import { RenewalStatus } from "./model/bond";
import { BondAdditionalQuestionResponse } from "./model/bondAdditionalQuestionResponse";
import { Company } from "./model/company";
import { Obligee } from "./model/obligee";
import { Person } from "./model/person";
import { RiderStatus } from "./model/riderRequest";

export interface RiderRequestBond {
    id: number;
    bondAmount: number;
    bondTypeId: number;
    bondTypeName: string;
    bondNumber: string;
    effectiveDate: Date;
    expirationDate: Date;
    nameOnBond: string;
    riderStatus: RiderStatus;
    originalEffectiveDate: Date;
    people: Person[];
    companies: Company[];
    bondAdditionalQuestionResponses: BondAdditionalQuestionResponse[];
    obligee: Obligee;
    renewalStatus: RenewalStatus;
    carrierId: number;
    writingCompanyName: string;
    writingCompanyId: number;
}