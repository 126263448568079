import { IBaseEntity } from "./iBaseEntity";


export interface PermissionContainer extends IBaseEntity { 
    canDeleteFromDocumentLibrary: boolean;
    canEditBondTypes: boolean;
    canEditDocumentLibrary: boolean;
    canEditObligees: boolean;
    canEditSystemAccounts: boolean;
    canEditUserGroups: boolean;
    canEditUsers: boolean;
    canEditActivitySettings: boolean;
    id: number;
    createdDateTime: Date;
}


export interface IPermissionContainer extends IBaseEntity { 
    canDeleteFromDocumentLibrary: boolean;
    canEditBondTypes: boolean;
    canEditDocumentLibrary: boolean;
    canEditObligees: boolean;
    canEditSystemAccounts: boolean;
    canEditUserGroups: boolean;
    canEditUsers: boolean;
    canEditActivitySettings: boolean;
}