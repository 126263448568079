import { BondType } from "./bondType";
import { IBaseEntity } from "./iBaseEntity";
import { Document } from "./document";

export interface BondTypeDocument extends IBaseEntity { 
    bondTypeId: number;
    documentId: number;
    bondType: BondType;
    document: Document;
    id: number;
    createdDateTime: Date;
}