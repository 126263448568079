import { Amount } from "./amount";
import { CourtId } from "./courtId";
import { DateFiled } from "./dateFiled";
import { DateReported } from "./dateReported";
import { LienClass } from "./lienClass";
import { LienStatus } from "./lienStatus";
import { Narratives } from "./narratives";
import { ReleaseDate } from "./releaseDate";
import { VerificationDate } from "./verificationDate";

export interface USTaxLien { 
    dateFiled: DateFiled;
    courtId: CourtId;
    caseNumber: string;
    amount: Amount;
    lienClass: LienClass;
    releaseDate: ReleaseDate;
    verificationDate: VerificationDate;
    dateReported: DateReported;
    lienStatus: LienStatus;
    narratives: Narratives;
}