import { IWindowService, IRootScopeService } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";

export const nowTimeFactory = ($window: IWindowService, $rootScope: IRootScopeService) => {
    
    var nowTime = Date.now();

    var updateTime = () => {
        $window.setTimeout(() => {
            $rootScope.$apply(() => {
                nowTime = Date.now();
                updateTime();
            });
        }, 1000);
    };

    updateTime();
    
    return () => {
        return nowTime;
    };
}

nowTimeFactory.$inject = [
    Injectables.$window,
    Injectables.$rootScope
];

app.factory('nowTime', nowTimeFactory);
