import { DocumentService } from "../../api/documentService";
import DocumentDetails from "../../api/types/documents/documentDetails";
import { Injectables } from "../../configuration/injectables";
import app from "../../main"
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { BusyIndicator } from "../busyIndicator/busyIndicator";

class DocumentLibraryDetailsPaneController {

    public static $inject = [
        Injectables.CurrentUserResolver,
        Injectables.DocumentService,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly documentService: DocumentService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {}

    public isOpen: boolean;
    public documentDetails: DocumentDetails;
    public onHide: () => {}
    public systemAccountCompanyName: string;
    public busyIndicator: BusyIndicator;

    public hide = () => {
        this.isOpen = false;

        if (this.onHide instanceof Function) {
            this.onHide();
        }
    }

    public saveUsage = () => {
        this.busyIndicator = {
            message: 'Saving...',
            promise: this.documentService.saveDocumentDetails(this.documentDetails)
                .then(() => {
                    this.toastMessageCreator.createSuccessMessage('Document details saved successfully');
                })
        }
    }

    public $onInit = () => {
        this.systemAccountCompanyName = this.currentUserResolver.getCurrentUser().systemAccount.companyName;
    }
}

const component = {
    bindings: {
        isOpen: '<',
        documentDetails: '<',
        onHide: '&'
    },
    controllerAs: 'vm',
    controller: DocumentLibraryDetailsPaneController,
    templateUrl: 'app/components/documentLibraryDetailsPane/documentLibraryDetailsPane.html'
}

app.component('documentLibraryDetailsPane', component);