import { CustomersService } from "../../api/customerService";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { SearchControl } from "../../utilities/searchControl";
import { CustomerSearchResult } from "./customerSearchResult";

class CustomerSearchDropdownController {

    public static $inject = [
        Injectables.CustomersService
    ];
    
    constructor(
        private readonly customersService: CustomersService
    ) {}

    public label: string;
    public required: string;
    public disabled: string;
    public searchControl: SearchControl<CustomerSearchResult>

    private disableRelationships: string;

    private isOptionDisabled(option: CustomerSearchResult) {
        if (this.disableRelationships === 'true' && (option.isParentCustomer || option.parentCustomerName)) {
            return true;
        }

        return false;
    }
    
    private buildOptionLabel = (option: CustomerSearchResult) => {
        if (!option) {
            return;
        }
        
        option.isDisabled = this.isOptionDisabled(option);

        let parentLabel = '';

        if (option.isParentCustomer) {
            parentLabel = ' - Is Parent Customer';
        } else if (option.parentCustomerName) {
            parentLabel = ' - ' + option.parentCustomerName;
        }
        
        return option.name + parentLabel;
    }

    private buildSelectionLabel = () => {
        if (!this.searchControl.selected) { 
            return ''; 
        }
        
        return this.searchControl.selected.name;
    }

    public $onInit() {
        this.searchControl = new SearchControl();
        this.searchControl.allowSelectionClearing = true;
        this.searchControl.isRequired = this.required === 'true';
        this.searchControl.isDisabled = this.disabled === 'true';            
        this.searchControl.label = this.label ? this.label : "Customer Search";
        this.searchControl.name="customerSearch";
        this.searchControl.placeholder = 'Search Customers';
        this.searchControl.emptyStateMessage = 'No matching customers found';
        this.searchControl.onSearch = this.customersService.searchForDropdown;
        this.searchControl.buildSelectionLabel = this.buildSelectionLabel;
        this.searchControl.buildOptionLabel = this.buildOptionLabel;
    }
}

const customerSearchDropdownComponent = {
    bindings: {
        label: '@?',
        searchControl: '=?',
        required: '@?',
        disabled: '@?',
        disableRelationships: '@?'
    },
    controller: CustomerSearchDropdownController,
    controllerAs: 'vm',
    templateUrl: 'app/components/customerSearchDropdown/customerSearchDropdown.html',
};

app.component('customerSearchDropdown', customerSearchDropdownComponent);