import { BondChange, BondChangeType } from "./model/bondChange";
import { Company } from "./model/company";
import { Person } from "./model/person";
import { QuestionResponse } from "./model/questionResponse";
import { WritingCompany } from "./model/writingCompany";
import { RiderRequest } from "./riderRequest";


export interface RiderRequestBondChange extends BondChange {
    bondAdditionalQuestionResponseId: number;
    changeType: BondChangeType;
    companyId: number;
    description: string;
    newValue: string;
    originalValue: string;
    personId: number;
    riderRequest: RiderRequest;
    riderRequestId: number;
    id: number;
    createdDateTime: Date;
    newValueText: string;
    originalValueText: string;
    writingCompanyId: WritingCompany;
    bondTypeId: number;

    $selectedPerson: Person;
    $selectedCompany: Company;
    $selectedQuestion: QuestionResponse;
    $fields: { [key: string]: string };
    $isCompany: boolean;
    $isQuestion: boolean;
    $isObligee: boolean;
    $isPerson: boolean;
}
