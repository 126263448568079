import { BondTransactionService } from "../../../../../api/bondTransactionService";
import { BusyIndicator } from "../../../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../../../configuration/injectables";
import { DashboardService } from "../../dashboardService";
import { DashboardWidget } from "../../types/dashboardWidget";
import app from "../../../../../main";
import { IRootElementService, IQService, IFilterService } from "angular";
import { SlabTextService } from "../../../../../utilities/slabText/slabTextService";
import { JQueryService } from "../../../../../utilities/jquery/jQueryService";
import DropdownOption from "../../../../../api/types/dropdownOption";
import { constants } from "../../../../../configuration/constants";
   
class DashboardWidgetBondTypeConfigurationCountController {

    public static $inject = [
        Injectables.$element,
        Injectables.DashboardService,
        Injectables.BondTransactionService,
        Injectables.$q,
        Injectables.$filter,
        Injectables.SlabTextService,
        Injectables.JQueryService
    ];

    constructor(
        private readonly $element:IRootElementService,
        private readonly dashboardService: DashboardService,
        private readonly bondTransactionService: BondTransactionService,
        private readonly $q: IQService,
        private readonly $filter: IFilterService,
        private readonly slabTextService: SlabTextService,
        private readonly jQueryService: JQueryService) {
    }

    public widget: DashboardWidget;
    public busyIndicator: BusyIndicator;
    public value: string;
    public overComparison: string;
    public dateReferenceOptions: DropdownOption<string>[];
    public transactionTypeOptions: DropdownOption<string>[];

    public dateReferenceSelected = (option: DropdownOption<string>) => {
        this.widget.options.dateReference = option;
        this.loadValue();
        this.dashboardService.save();
    }

    public transactionTypeSelected = (option: DropdownOption<string>) => {
        this.widget.options.transactionType = option;
        this.loadValue();
        this.dashboardService.save();
    }

    public loadValue = () => {
        var promises = [];

        if (this.widget.options.dateReference.value === 'YTD') {
            promises.push(this.bondTransactionService.ytdCount(this.widget.options.transactionType.value));
            promises.push(this.bondTransactionService.lastYearCount(this.widget.options.transactionType.value));
        } else if (this.widget.options.dateReference.value === 'Last 90 Days') {
            promises.push(this.bondTransactionService.last90DaysCount(this.widget.options.transactionType.value));
            promises.push(this.bondTransactionService.last180ToLast90DaysCount(this.widget.options.transactionType.value));
        } else if (this.widget.options.dateReference.value === 'This Month') {
            promises.push(this.bondTransactionService.mtdCount(this.widget.options.transactionType.value));
            promises.push(this.bondTransactionService.lastMonthCount(this.widget.options.transactionType.value));
        } else if (this.widget.options.dateReference.value === 'Last Month') {
            promises.push(this.bondTransactionService.lastMonthCount(this.widget.options.transactionType.value));
            promises.push(this.bondTransactionService.twoMonthsAgoCount(this.widget.options.transactionType.value));
        } else if (this.widget.options.dateReference.value === 'This Week') {
            promises.push(this.bondTransactionService.thisWeekCount(this.widget.options.transactionType.value));
            promises.push(this.bondTransactionService.lastWeekCount(this.widget.options.transactionType.value));
        } else {
            return;
        }

        this.busyIndicator.promise = this.$q.all(promises)
        .then((values: number[]) => {
            this.value = this.$filter('number')(values[0]);

            var timePeriod = this.dashboardService.getTimePeriod(this.widget.options.dateReference.value);
            var percentIntoTimePeriod = this.dashboardService.getPercentIntoTimePeriod(this.widget.options.dateReference.value);
            this.overComparison = this.dashboardService.calculateComparisonText(values[0], values[1], timePeriod, percentIntoTimePeriod);

            this.updateValueSize(this.value);
        });
    };

    public updateValueSize = (value: string) => {
        this.slabTextService.setupSlabText(
            this.jQueryService.getElement('.value', this.$element)
                .html(value),
            { maxFontSize: 40 }
        )
    };


    public $onInit = () => {
        this.busyIndicator = {
            message: 'Loading...'
        };

         this.dateReferenceOptions = [{
                label: 'YTD',
                value: 'YTD'
            }, {
                label: 'Last 90 Days',
                value: 'Last 90 Days'
            }, {
                label: 'This Month',
                value: 'This Month'
            }, {
                label: 'This Week',
                value: 'This Week'
            }
        ];

        this.transactionTypeOptions = constants.transactionTypeDropdownOptions;

        this.loadValue();

        this.dashboardService.refreshFunctions.push(this.loadValue);
    }
}

const dashboardWidgetBondTypeConfigurationCountComponent = {
    bindings: {
        widget: '='
    },
    templateUrl: 'app/states/common/dashboard/widgets/bondTypeConfigurationCount/dashboardWidgetBondTypeConfigurationCount.html',
    controller: DashboardWidgetBondTypeConfigurationCountController,
    controllerAs: 'vm'
};

app.component('dashboardWidgetBondTypeConfigurationCount', dashboardWidgetBondTypeConfigurationCountComponent);
