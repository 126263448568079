import { IBaseEntity } from "./iBaseEntity";
import { SystemAccount } from "./systemAccount";

export interface Tag extends IBaseEntity { 
    name: string;
    eProducerAccountId: number;
    leadId: number;
    applicationId: number;
    bondId: number;
    customerId: number;
    systemAccountId: number;
    systemAccount: SystemAccount;
    id: number;
    createdDateTime: Date;
}