import { Injectables } from "../../../configuration/injectables";
import app from "../../../main";
import { JQueryService } from "../../../utilities/jquery/jQueryService";

export const mainNavigationClass = (jQueryService: JQueryService) => {
    return {
        restrict: 'A',
        replace: false,
        scope: {}, 
        link: (scope, elem, attrs) => {

            if (!attrs.mainNavigationClass) {
                return;
            }

            const listItems = elem.find('li');

            for(let i = 0; i < listItems.length; i++) {
                listItems[i].addEventListener('click', function() {
                    // remove specified class name from siblings
                    jQueryService.getElement(this)
                        .parent()
                        .children('li')
                        .removeClass(attrs.mainNavigationClass);
    
                    // add class name to li tha was clicked
                    jQueryService.getElement(this)
                        .addClass(attrs.mainNavigationClass);
                })
            }
        }
    };
}

mainNavigationClass.$inject = [Injectables.JQueryService];

app.directive('mainNavigationClass', mainNavigationClass);
