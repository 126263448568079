import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { IAddress } from "./iAddress";

export class AddressService {

    public isMailingAddressSameAsPhysical(object: IAddress): boolean {
        if (!object) { 
            return true; 
        }

        const same = (object.physicalCity === object.mailCity) &&
            (object.physicalAddress === object.mailAddress) &&
            (object.physicalSuiteAptNumber === object.mailSuiteAptNumber) &&
            (object.physicalCounty === object.mailCounty) &&
            (object.physicalZip === object.mailZip) &&
            (object.physicalState === object.mailState);

        return same;
    }

    public copyPhysicalAddressToMailAddress(object: IAddress): boolean {
        if (!object) { 
            return; 
        }

        object.mailAddress = object.physicalAddress;
        object.mailSuiteAptNumber = object.physicalSuiteAptNumber;
        object.mailCity = object.physicalCity;
        object.mailState = object.physicalState;
        object.mailZip = object.physicalZip;
        object.mailCounty = object.physicalCounty;
    }
}

app.service(Injectables.AddressService, AddressService);
