import { AutoFeeBondType } from "./autoFeeBondType";
import { AutoFeeSfaaCode } from "./autoFeeSfaaCode";
import { IBaseEntity } from "./iBaseEntity";
import { SystemAccount } from "./systemAccount";

export interface AutoFee extends IBaseEntity { 
    systemAccountId: number;
    appliesInA3: boolean;
    appliesToAllBondTypes: boolean;
    appliesToCancellations: boolean;
    appliesToEproducerBrokers: boolean;
    appliesToEProducerPrincipals: boolean;
    appliesToNewBusiness: boolean;
    appliesToReinstatements: boolean;
    appliesToRenewals: boolean;
    appliesToRiders: boolean;
    autoFeeBondTypes: AutoFeeBondType[];
    autoFeeSfaaCodes: AutoFeeSfaaCode[];
    description: string;
    feeAmount: number;
    feePercent: number;
    feeType: FeeType;
    maximumAmount: number;
    minimumAmount: number;
    systemAccount: SystemAccount;
    id: number;
    createdDateTime: Date;
}

export enum FeeType {	
    PercentOfPremium = "PercentOfPremium",
    Flat = "Flat"
}