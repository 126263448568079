import { ODataFactory, ODataQuery } from "../../../api/odata";
import { Injectables } from "../../../configuration/injectables";

export class AgencyAppointmentsController {
    
    public static $inject = [
        Injectables.ODataFactory
    ];

    constructor(
        private readonly odata: ODataFactory
    ) {}
    
    public query: ODataQuery;

    public $onInit = () => {
        this.query = this.odata.getQuery()
            .expand('agencySystemAccount');
    }
}