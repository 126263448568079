import { IDirectiveFactory, IDirectiveLinkFn, IScope } from "angular";
import app from "../../main";
import Inputmask from "inputmask";

const dateMaskDirective: IDirectiveFactory = () => {

    const link: IDirectiveLinkFn = (
        scope: IScope,
        elem: JQuery
    ) => {
        const mask = new Inputmask("datetime", {
            inputFormat: "mm/dd/yyyy",
            clearIncomplete: true,
            positionCaretOnClick: 'select'
        });
        mask.mask(elem);
    }

    return {
        link: link
    };
} 

app.directive('maskDate', dateMaskDirective);