import { AuthService } from "../../../api/authService";
import { SystemType } from "../../../api/types/applicationDetail";
import { SystemAccountType } from "../../../api/types/model/systemAccount";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../configuration/injectables";
import app from "../../../main";

type Cookie = {
    name: string;
    value: string;
}

export class UserSelectionController {
    public static $inject = [
        Injectables.AuthService
    ];

    constructor(
        private readonly authService: AuthService
    ) {}

    public busyIndicator: BusyIndicator;
    public userOptions: UserForAuthentication[];

    public parseCookie = (cookieHeader: string): Cookie[] => {
    
        const cookies = cookieHeader
            .split(';')
            .map(cookie => cookie.trim());
        
        const parsedCookies = cookies.map(cookie => {    
            const [nameValue] = cookie
                .split(';')
                .map(part => part.trim());
    
            const [name, value] = nameValue
                .split('=')
                .map(decodeURIComponent);
            
            return { name, value };
        });
    
        return parsedCookies;
    }

    private getCookie = (name: string) => {
        return this.parseCookie(document.cookie)
            .filter(cookie => cookie.name === name)[0];
    }

    private deleteCookie = (name: string) => {
        document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    }
    
    private getUsersForAuthentication = () => {
        return this.authService.getUsersForAuthentication()
            .then((users) => {
                this.userOptions = users;

                if (this.userOptions.length == 1) {
                    return this.authService.setUser(this.userOptions[0].id);
                }
            });
    }

    public selectUser = (userId: number) => {
        this.busyIndicator.promise = this.authService.setUser(userId);
    }

    public $onInit(): void {
        const cookieName = 'SSO-User';

        var ssoUserCookie = this.getCookie(cookieName);

        if (ssoUserCookie) {
            const ssoAuthenticationResponse = JSON.parse(ssoUserCookie.value);
            this.authService.setSsoUser(ssoAuthenticationResponse);
            this.deleteCookie(cookieName)
        }
        
        this.busyIndicator = {
            message: 'Loading...',
            promise: this.getUsersForAuthentication()
        }
    }
}

export interface UserForAuthentication {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    systemAccountCompanyName: string;
    systemAccountId: number;
    lastLoginDate: Date;
    accountType: SystemAccountType;
    systemAtype: SystemType;
    fullName: string;
    theme: string;
    sideMenuIsCollapsed: boolean;
    permissions: any;
    systemAccount: UserForAuthenticationSystemAccount;
}

export interface UserForAuthenticationSystemAccount {
    id: number;
    companyName: string;
    isCarrier: boolean;
    isAgency: boolean;
    defaultAttorneyInFactUserId?: number;
    phone: string;
    email: string;
    accountType: SystemAccountType;
    mailAddress: string;
    mailSuiteAptNumber: string;
    mailCity: string;
    mailState: string;
    mailZip: string;
    physicalAddress: string;
    physicalSuiteAptNumber: string;
    physicalCity: string;
    physicalState: string;
    physicalZip: string;
}

app.controller("UserSelectionController", UserSelectionController);
