import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { ModalOpener } from "../../modals/modalOpener";

class ShowActivityModalButtonController {

    public static $inject = [
        Injectables.ModalOpener
    ];

    constructor(
        private readonly modalOpener: ModalOpener
    ) {}

    public activityId: number;
    public customerId: number;
    public bondId: number;
    public bondAccountId: number;
    public eproducerAccountId: number;
    public applicationId: number;
    public onSubmit: () => void;
    public defaultOptions: string;

    public showActivityModal() {
        this.modalOpener.showActivityModal(
            this.activityId, 
            this.customerId, 
            this.bondId, 
            this.bondAccountId,
            this.eproducerAccountId,
            this.applicationId,
            this.defaultOptions
        )
        .result
        .then(() => {
            if (typeof this.onSubmit === 'function') {
                this.onSubmit();
            }
        })
        .catch(() => {});
    }

    public $onInit() {
    }
}

const showActivityModalButtonComponent = {
    bindings: {
        activityId: '<?',
        customerId: '<?',
        bondId: '<?',
        bondAccountId: '<?',
        eproducerAccountId: '<?',
        applicationId: '<?',
        onSubmit: '&?',
        buttonClass: '@?',
        defaultOptions: '@?'
    },
    controller: ShowActivityModalButtonController,
    controllerAs: 'vm',
    templateUrl: 'app/components/showActivityModalButton/showActivityModalButton.html',
};

app.component('showActivityModalButton', showActivityModalButtonComponent);