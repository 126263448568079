import { IBaseEntity } from "./iBaseEntity";
import { MasterApplicationQuestion } from "./masterApplicationQuestion";
import { RuleConditionType } from "./ruleCondition";

export interface RateOverrideCondition extends IBaseEntity {
    rateOverrideId: number;
    masterApplicationQuestionId: number;
    masterApplicationQuestion: MasterApplicationQuestion;
    requiredValue: string;
    conditionType: RuleConditionType;
    minimumBondAmount?: number;
    maximumBondAmount?: number;
}
