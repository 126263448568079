import { Bond } from "../bond";
import { Fee } from "./fee";
import { IBaseEntity } from "./iBaseEntity";
import { SystemAccount } from "./systemAccount";
import { User } from "./user";

export interface CancellationRequest extends IBaseEntity { 
    agencySystemAccountId: number;
    carrierSystemAccountId: number;
    agencyComments: string;
    agencySystemAccount: SystemAccount;
    attorneyInFactUserId: number;
    bond: Bond;
    bondId: number;
    carrierComments: string;
    carrierSystemAccount: SystemAccount;
    commissionReduction: number;
    effectiveDate: Date;
    fees: Fee[];
    premiumRefund: number;
    reasonCode: string;
    reasonDescription: string;
    requestedCancellationDate: Date;
    status: CancellationStatus;
    user: User;
    userId: number;
    id: number;
    createdDateTime: Date;
}

export enum CancellationStatus {	
    None = "None",
    Requested = "Requested",
    Cancelled = "Cancelled"
}