export default (
    element: JQuery, 
    labelText: string, 
    isRequired?: boolean
) => {
    
    var requiredIndicator = isRequired ? ' <i class="fa fa-asterisk validation-indicator"></i>' : '';
    
    element
        .parent()
        .find('span')
        .append(requiredIndicator + ' ' + labelText);
}