import { ApplicationService } from "../../api/applicationService";
import { EmailTemplateService } from "../../api/emailTemplateService";
import { EmailTemplate } from "../../api/types/model/emailTemplate";
import { SelectOption } from "../../api/types/selectOption";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { ApplicationAccessOptions } from "./applicationAccess";
import { ApplicationAccessResult } from "./ApplicationAccessResult";
import app from "../../main";
import { IPromise, IQService } from "angular";

export class ApplicationAccessModalController {

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.ApplicationService,
        Injectables.EmailTemplateService,
        Injectables.$q,
        Injectables.ToastMessageCreator
    ];

    constructor(

        private readonly $uibModalInstance: Modal<ApplicationAccessResult>,
        private readonly options: ApplicationAccessOptions,
        private readonly applicationService: ApplicationService,
        private readonly emailTemplateService: EmailTemplateService,
        private readonly $q: IQService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) { }

    public processingPromise: IPromise<any>;
    public applicationId: number;
    public bondTypeId: number;
    public bondAmount: number;
    public htmlBody: string;
    public subject: string;
    public returnUrl: string;
    public emailAddress: string;
    public emailTemplateId: number;
    public templateOptions: SelectOption[];

    public templateSelectionChanged(): void {
        this.processingPromise = this.loadMessageContent();
    }

    public loadMessageContent(): IPromise<void> {
        return this.applicationService.getApplicationEmailTemplate({ applicationId: this.applicationId, bondTypeId: this.bondTypeId, bondAmount: this.bondAmount, emailTemplateId: this.emailTemplateId })
            .then((template) => {
                this.htmlBody = template.htmlBody;
                this.subject = template.subject;
                this.returnUrl = template.returnUrl
            });
    }

    public copyReturnUrl() {
        navigator.clipboard.writeText(this.returnUrl);
        this.toastMessageCreator.createSuccessMessage("Url Copied to clipboard");
    }

    public send(): void {
        this.processingPromise = this.applicationService.sendApplicationEmail({
            emailAddress: this.emailAddress,
            subject: this.subject,
            message: this.htmlBody,
            applicationId: this.applicationId,
            bondTypeId: this.bondTypeId,
            bondAmount: this.bondAmount,
            emailTemplateId: this.emailTemplateId,
            ctaUrl: this.returnUrl
        });

        this.processingPromise
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('This application has been emailed successfully');
                return;
            }).catch(() => {
                this.toastMessageCreator.createErrorMessage('Something happened and we were unable to send the application email.');
                return;
            }).finally(() => {
                this.$uibModalInstance.close(null);
                return;
            });
    }

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }

    public loadTemplates(): IPromise<void> {
        this.templateOptions = [];

        return this.emailTemplateService.load('id,name')
            .then((templates: EmailTemplate[]) => {
                for (let i = 0; i < templates.length; i++) {
                    this.templateOptions.push({
                        value: templates[i].id, 
                        label: templates[i].name 
                    });
                }
            });
    }

    public $onInit(): void {
        if (!this.options.applicationId && !this.options.bondTypeId) {
            throw new Error('options.applicationId or options.bondTypeId must be valid');
        }

        this.applicationId = this.options.applicationId;
        this.bondTypeId = this.options.bondTypeId;
        this.bondAmount = this.options.bondAmount;

        this.processingPromise = this.$q
            .all([
                this.loadMessageContent(),
                this.loadTemplates()
            ]);
    }
}

app.controller('ApplicationAccessModalController', ApplicationAccessModalController);
