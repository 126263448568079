import { IPromise } from "angular";
import { EpicIntegrationService } from "../../../api/epicIntegrationService";
import { EpicIntegrationLog } from "../../../api/types/epicIntegrationLog";
import { Injectables } from "../../../configuration/injectables";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";

export class ReportEpicIntegrationLogsController {

    public static $inject = [
        Injectables.EpicIntegrationService,
        Injectables.ToastMessageCreator
    ];

    public logEntries: EpicIntegrationLog[];
    public loadingPromise: IPromise<void>;
    public startDate: Date;
    public endDate: Date;
    public reportHasRan: boolean;
    
    constructor(
        private readonly epicIntegrationService: EpicIntegrationService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) { }

    public runReport() {
        this.loadingPromise = this.epicIntegrationService.getIntegrationLogs(this.startDate, this.endDate)
            .then((logEntries) => {
                this.logEntries = logEntries;
                this.reportHasRan = true;
            })
            .catch((err) => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to run the report');
            });
    }

    public $onInit(): void {
        const now = new Date();

        this.startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        this.endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    }

    public processingPromise: IPromise<void>;
}
