import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { CustomersService } from "../../api/customerService";
import { BusyIndicator } from "../busyIndicator/busyIndicator";

export type CustomerOrEproducerBrokerSelectionOption = {
    name: string;
    lookupCode: string;
    customerId?: number;
    eProducerAccountId?: number;
};

class CustomerOrEproducerBrokerSelectionController {
    
    public static $inject = [
        Injectables.CustomersService
    ];

    constructor(
        private readonly customerService: CustomersService
    ) {
    }

    public onAccountSelected: (param: {selectedAccount: CustomerOrEproducerBrokerSelectionOption}) => void;
    public busyIndicator: BusyIndicator;
    public searchPhrase: string;
    public selectedAccount: CustomerOrEproducerBrokerSelectionOption;
    public matches: CustomerOrEproducerBrokerSelectionOption[];
    
    public setSelection = (option) => {
        this.onAccountSelected({selectedAccount: option});
    }

    public search = () => {
        if (!this.searchPhrase || this.searchPhrase.length < 3) {
            return;
        }

        this.busyIndicator.promise = this.customerService.searchCustomersOrEproducerBrokers(this.searchPhrase)
            .then((accounts) => {
                this.matches = accounts;
            });
    }

    public $onInit = () => {
        this.busyIndicator = {
            message: 'Searching...'
        };
    }
}

const customerOrEproducerBrokerSelectionComponent = {
    templateUrl: 'app/components/customerOrEproducerBrokerSelection/customerOrEproducerBrokerSelection.html',
    controller: CustomerOrEproducerBrokerSelectionController,
    controllerAs: 'vm',
    bindings: {
        onAccountSelected: '&',
    }
}

app.component('customerOrEproducerBrokerSelection', customerOrEproducerBrokerSelectionComponent);