import { BondChange } from "./model/bondChange";
import { Fee } from "./model/fee";

export interface RequestReinstatementDto   { 
    bondId: number;
    agencyComments: string;
    requestedEffectiveDate: Date;
    attorneyInFactUserId: number;
    riderRequestChanges: BondChange[];
    fees: Fee[];   
}