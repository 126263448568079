import app from "../../main";
import { BusyIndicator } from "../busyIndicator/busyIndicator";

export class BalanceCardController {

    public balanceCardBusyIndicator: BusyIndicator;

    public $onInit = () => {
        if (!this.balanceCardBusyIndicator) {
            this.balanceCardBusyIndicator = {};
        }

        this.balanceCardBusyIndicator.hideMessage = true;
    }
}

const balanceCardComponent = {
    bindings: {
        balance: '<',
        cashOnAccountBalance: '<',
        unbilledBalance: '<',
        epicReconciliationDateTime: '<',
        balanceCardBusyIndicator: '<?'
    },
    controller: BalanceCardController,
    controllerAs: 'vm',
    templateUrl: 'app/components/customerBalanceCard/balanceCard.html',
};

app.component('balanceCard', balanceCardComponent);