import { IQService, IPromise } from "angular";
import { SystemAccountService } from "../../../api/systemAccountService";
import { A3RootScope } from "../../../api/types/a3RootScope";
import { SystemAccount } from "../../../api/types/model/systemAccount";
import { User } from "../../../api/types/model/user";
import { SelectOption } from "../../../api/types/selectOption";
import { UserService } from "../../../api/userService";
import { constants } from "../../../configuration/constants";
import { Injectables } from "../../../configuration/injectables";
import { SystemSettings } from "../../../configuration/settings/systemSettings";
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";

export class AgencyProfileController {

    public static $inject = [
        Injectables.CurrentUserResolver,
        Injectables.UserService,
        Injectables.$q,
        Injectables.SystemAccountService,
        Injectables.ToastMessageCreator,
        Injectables.SystemSettings
    ];

    constructor(
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly userService: UserService,
        private readonly $q: IQService,
        private readonly systemAccountService: SystemAccountService,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly systemSettings: SystemSettings) { 
    }

    public lgLogo: File;
    public smLogo: File;
    public largeLogoDropzone: Dropzone;
    public smallLogoDropzone: Dropzone;
    public agency:  SystemAccount;
    public waitCount: number;
    public ams360Error: any;
    public ams360Success: any;
    public processingPromise: IPromise<any>;
    public creditCardProcessors: SelectOption<string>[];
    public achProcessors: SelectOption<string>[];
    public lgLogoPicturePath: string;
    public smLogoPicturePath: string;
    public aifUsers:  User[];

    public lgDzAddedFile(file: File): void {
        this.lgLogo = file;

        if (this.largeLogoDropzone.files.length > 1) {
            this.largeLogoDropzone.files[0] = this.largeLogoDropzone.files[1];
            this.largeLogoDropzone.files.splice(1, 1);
        }
    }

    public lgDzError(file: File, response: any, xhr: any): void {
        if (!response.Message) {
            this.lgLogo = undefined;
        }
    }

    public lgDzProcessing(file: File): void {
        this.largeLogoDropzone.options.url = this.systemSettings.apiBaseUrl + 'upload/UploadSystemAccountLogo';
    }

    public lgDzSuccess(file: File, response: any): void {
        this.largeLogoDropzone.removeAllFiles();
        this.agency.largeLogoFileId = response;
        this.submit();
    }

    public smDzAddedFile(file: File): void {
        this.smLogo = file;

        if (this.smallLogoDropzone.files.length > 1) {
            this.smallLogoDropzone.files[0] = this.smallLogoDropzone.files[1];
            this.smallLogoDropzone.files.splice(1, 1);
        }
    }

    public smDzError(file: File, response: any, xhr: any): void {
        if (!response.Message) {
            this.smLogo = undefined;
        }
    }

    public smDzProcessing(file: File): void {
        this.smallLogoDropzone.options.url = this.systemSettings.apiBaseUrl + 'upload/UploadSystemAccountLogo?height=75&width=75';
    }

    public smDzSuccess(file: File, response: any): void {
        this.smallLogoDropzone.removeAllFiles();
        this.agency.thumbnailLogoFileId = response;
        this.submit();
    }

    public save_click(): void {
        if (this.largeLogoDropzone.files.length > 0) {
            // upload attachments; after success dropzone will call submit
            this.waitCount++;
            this.largeLogoDropzone.processQueue();
        }

        if (this.smallLogoDropzone.files.length > 0) {
            // upload attachments; after success dropzone will call submit
            this.waitCount++;
            this.smallLogoDropzone.processQueue();
        }
        this.submit();
    }

    public submit(): void {
        if (this.waitCount > 0) {
            this.waitCount--;
            return;
        }

        this.waitCount = 0;

        this.processingPromise = this.systemAccountService.save(this.agency.id, this.agency)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Your company information was saved successfully');
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred while trying to save your company information');
            })
            .finally(() => {
                this.setLogoPicture();
            });
    }

    public setLogoPicture(): IPromise<void[]> {
        const promises = [] as IPromise<void>[];

        if (this.agency.largeLogoFileId) {
            promises.push(this.systemAccountService.getLargeLogo(this.agency.id)
                .then((imgData) => {
                    this.lgLogoPicturePath = imgData;
                    this.lgLogo = undefined;
                }));
        }

        if (this.agency.thumbnailLogoFileId) {
            promises.push(this.systemAccountService.getSmallLogo(this.agency.id)
                .then((imgData) => {
                    this.smLogoPicturePath = imgData;
                    this.smLogo = undefined;
                }));
        }

        return this.$q.all(promises);
    }

    public $onInit(): void {
        this.agency = {} as  SystemAccount;
        this.creditCardProcessors = [];
        this.achProcessors = [];

        for (const property in constants.creditCardProcessors) {
            if (constants.creditCardProcessors.hasOwnProperty(property)) {
                this.creditCardProcessors.push({
                    value: constants.creditCardProcessors[property],
                    label: constants.creditCardProcessors[property]
                });
            }
        }

        for (const property in constants.achProcessors) {
            if (constants.achProcessors.hasOwnProperty(property)) {
                this.achProcessors.push({
                    value: constants.achProcessors[property],
                    label: constants.achProcessors[property]
                })
            }
        }

        this.waitCount = 0;

        this.processingPromise = this.systemAccountService
            .loadAgencyData(this.currentUserResolver.getCurrentUser().user.systemAccountId)
            .then((account) => {
                this.agency = account;
                return this.setLogoPicture();
            });
    }
}
