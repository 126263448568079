import { IQService } from "angular";
import app from "../../main";
import { Injectables } from "../injectables";

const systemTypeInterceptorFacory = ($q: IQService) => {

    return {
        request: (config) => {
            if (config.url.indexOf('/login') !== -1) {
                return config;
            }

            config.headers = config.headers || {};
            config.headers.SystemType = 'a3web';
            
            return config;
        },
        responseError: (rejection) => {
            return $q.reject(rejection);
        }
    }
}

systemTypeInterceptorFacory.$inject = [Injectables.$q];

app.factory('systemTypeInterceptor', systemTypeInterceptorFacory);
