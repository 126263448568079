import { IHttpService, IQService, IPromise } from "angular";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/settings/systemSettings";
import app from "../main";
import A3ApiResponse from "./types/a3ApiResponse";
import { SelectOption } from "./types/selectOption";
import { Tag } from "./types/tag";
import { CacheStore } from "./cacheStore";

export class TagService {
    public static $inject = [
        Injectables.$http,
        Injectables.CacheStore,
        Injectables.SystemSettings
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly cacheStore: CacheStore,
        private readonly systemSettings: SystemSettings
    ) {}

    public create(tag: Tag): IPromise<Tag> {
        if (!tag) {
            throw new Error("tag is not valid");
        }

        return this.$http
            .post<A3ApiResponse<Tag>>(
                this.systemSettings.apiBaseUrl + "TagActions/CreateTag",
                tag
            )
            .then((response) => {
                this.cacheStore.clearSingle(this.getTagsUrl);
                this.loadTagOptions();
                return response.data.value;
            });
        return;
    }

    public deleteById(id: number): IPromise<void> {
        return this.$http
            .delete(
                this.systemSettings.apiBaseUrl +
                    "TagActions/DeleteTag?tagId=" +
                    id
            )
            .then(() => {
                this.cacheStore.clearSingle(this.getTagsUrl);
                this.loadTagOptions();
            });
    }

    public getByApplicationId(id: number): IPromise<Tag[]> {
        return this.$http
            .get<A3ApiResponse<Tag[]>>(
                this.systemSettings.apiBaseUrl +
                    "TagActions/GetTagDetailsByApplicationId?applicationId=" +
                    id
            )
            .then((response) => response.data.value);
    }

    public getByBondId(id: number): IPromise<Tag[]> {
        return this.$http
            .get<A3ApiResponse<Tag[]>>(
                this.systemSettings.apiBaseUrl +
                    "TagActions/GetTagDetailsByBondId?bondId=" +
                    id
            )
            .then((response) => response.data.value);
    }

    public getByCustomerId(id: number): IPromise<Tag[]> {
        return this.$http
            .get<A3ApiResponse<Tag[]>>(
                this.systemSettings.apiBaseUrl +
                    "TagActions/GetTagDetailsByCustomerId?customerId=" +
                    id
            )
            .then((response) => response.data.value);
    }

    public getByEProducerAccountId(accountId: number): IPromise<Tag[]> {
        return this.$http
            .get<A3ApiResponse<Tag[]>>(
                this.systemSettings.apiBaseUrl +
                    "TagActions/GetTagDetailsByEProducerAccountId?accountId=" +
                    accountId
            )
            .then((response) => response.data.value);
    }

    public getByLeadId(id: number): IPromise<Tag[]> {
        return this.$http
            .get<A3ApiResponse<Tag[]>>(
                this.systemSettings.apiBaseUrl +
                    "TagActions/GetTagDetailsByLeadId?leadId=" +
                    id
            )
            .then((response) => response.data.value);
    }

    private getTagsUrl = `${this.systemSettings.apiBaseUrl}TagActions/GetTagOptions`;
    public loadTagOptions(): IPromise<SelectOption<string>[]> {
        return this.cacheStore.getData(this.getTagsUrl, () => {
            return this.$http
                .get<A3ApiResponse<string[]>>(this.getTagsUrl)
                .then((response) => {
                    const tagOptions: SelectOption<string>[] = [];

                    for (const tagOption of response.data.value) {
                        tagOptions.push({ label: tagOption, value: tagOption });
                    }

                    return tagOptions;
                });
        });
    }
}
app.service(Injectables.TagService, TagService);
