import { EProducerService } from "../../api/eProducerService";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { BondDetailOptions } from "../bondDetailModal/bondDetailModalOptions";
import { GrantCustomerEProducerAccessRequest } from "./grantCustomerEProducerAccessRequest";
import app from "../../main";
import { GrantCustomerEproducerAccessModal } from "./GrantCustomerEproducerAccessModal";

export class GrantCustomerEproducerAccessModalController {
    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.EProducerService,
        Injectables.ToastMessageCreator
    ];

    constructor (
        private readonly $uibModalInstance: Modal<GrantCustomerEproducerAccessModal>,
        private readonly modalOptions: BondDetailOptions,
        private readonly eProducerService: EProducerService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {}

    public busyIndicator: BusyIndicator;
    public request: GrantCustomerEProducerAccessRequest;
    public errorMessage: string;
    
    public save() {
        this.errorMessage = '';
        this.busyIndicator.promise = this.eProducerService.assignCustomerToIndividualAccount(this.request)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Customer user account has been created');
                this.$uibModalInstance.close(new GrantCustomerEproducerAccessModal());
            })
            .catch((err) => {
                if (err && err.message) {
                    this.errorMessage = err.message;
                } else {
                    this.toastMessageCreator.createErrorMessage('An error occurred trying to create a user account for this customer');
                }
            })
    }

    public cancel() {
        this.$uibModalInstance.dismiss();
    }

    $onInit() {
        this.busyIndicator = {
            message: 'Creating user account'
        };

        this.request = {
            customerId: this.modalOptions.customerId
        } as GrantCustomerEProducerAccessRequest;
    }
}

app.controller('GrantCustomerEproducerAccessModalController', GrantCustomerEproducerAccessModalController);