import { EProducerFormFieldOptions } from "./eProducerFormFieldOptions";
import { AdditionalQuestionResponseType } from "./model/questionResponse";

export interface EProducerFormField { 
    isRequired: boolean;
    label: string;
    type: string;
    model: string;
    masterApplicationQuestionId: number;
    options: EProducerFormFieldOptions[];
    subQuestions: EProducerFormField[];
    originType: AdditionalQuestionResponseType;
    showWhenParentValueEquals: string;
    requiredText: string;
}
