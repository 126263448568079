import { BondTypeFilterType } from "../../../api/types/bondTypeFilterType";
import { LeadType } from "../../../api/types/model/lead";

export interface LeadReportItem {
    Id: number;
    leadType: LeadType;
    createdDateTime: Date;
    bondType: BondTypeFilterType;
    bondAmount: number;
    nameOnBond: string;
    lowestPremium: number;
    highestPremium: number;
    carrierCount: number;
    prequalifiedQuoteCount: number;
    desiredEffectiveDate: Date
}