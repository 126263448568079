import { IJQueryService } from "../../../utilities/jquery/iJQueryService";
import defaultWrapper from "../defaultWrapper";
import fieldGroupWrapper from "../fieldGroupWrapper";

export default (element: JQuery, jQueryProvider: IJQueryService) => {
    element.attr('mask-money-negative', '');
    element.attr('is-float-negative', '');
    element.attr('type', 'text');
    element.attr('ondblclick', 'this.setSelectionRange(0, this.value.length)');
    element.attr('onblur', "if (this.value == '') { this.value = null; return; } if (this.value && this.value.indexOf('-') != 0) {this.value = '-' + this.value;}");
    
    fieldGroupWrapper(element, defaultWrapper(element), jQueryProvider);
};