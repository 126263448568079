export enum ExternalNotificationType {    
    PasswordReset = "PasswordReset",
    UserCreation = "UserCreation",
    EProducerUserPasswordReset = "EProducerUserPasswordReset",
    EProducerApplicationAccess = "EProducerApplicationAccess",
    EProducerNewApplicationAccess = "EProducerNewApplicationAccess",
    IndemnitySignatureSent = "IndemnitySignatureSent",
    EProducerAccountApproved = "EProducerAccountApproved",
    DocumentCorrectionRequestCompleted = "DocumentCorrectionRequestCompleted",
    PaymentPortalCustomerReceipt = "PaymentPortalCustomerReceipt",
    BondTypeRequestSubmitted = "BondTypeRequestSubmitted",
    DocumentCorrectRequestSubmitted = "DocumentCorrectRequestSubmitted"
}
