import * as angular from "angular";
import { IRootElementService, IQService, IFilterService } from "angular";
import { LeadService } from "../../../../../api/leadService";
import DropdownOption from "../../../../../api/types/dropdownOption";
import { BusyIndicator } from "../../../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../../../configuration/injectables";
import app from "../../../../../main";
import { JQueryService } from "../../../../../utilities/jquery/jQueryService";
import { SlabTextService } from "../../../../../utilities/slabText/slabTextService";
import { DashboardService } from "../../dashboardService";
import { DashboardWidget } from "../../types/dashboardWidget";
import { constants } from "../../../../../configuration/constants";

class DashboardWidgetLeadTotalsController {

    public static $inject = [
        Injectables.$element,
        Injectables.DashboardService,
        Injectables.LeadService,
        Injectables.$q,
        Injectables.$filter,
        Injectables.SlabTextService,
        Injectables.JQueryService
    ];

    constructor (
        private readonly $element:IRootElementService,
        private readonly dashboardService: DashboardService,
        private readonly leadService: LeadService,
        private readonly $q: IQService,
        private readonly $filter: IFilterService,
        private readonly slabTextService: SlabTextService,
        private readonly jQueryService: JQueryService) {
    }

    public widget: DashboardWidget;
    public busyIndicator: BusyIndicator;
    public value: string;
    public overComparison: string;
    public dateReferenceOptions: DropdownOption<string>[];
    public leadTypeOptions: DropdownOption<string>[];
    
    public dateReferenceSelected = (option: DropdownOption<string>) => {
        this.widget.options.dateReference = option;
        this.loadValue();
        this.dashboardService.save();
    }

    public leadTypeSelected = (option: DropdownOption<string>) => {
        this.widget.options.leadType = option;
        this.loadValue();
        this.dashboardService.save();
    }

    public loadValue = () => {
        var promises = [];

        if (this.widget.options.dateReference.value === 'YTD') {
            promises.push(this.leadService.ytdCount(this.widget.options.leadType.value));
            promises.push(this.leadService.lastYearCount(this.widget.options.leadType.value));
        } else if (this.widget.options.dateReference.value === 'Last 90 Days') {
            promises.push(this.leadService.last90DaysCount(this.widget.options.leadType.value));
            promises.push(this.leadService.last180ToLast90DaysCount(this.widget.options.leadType.value));
        } else if (this.widget.options.dateReference.value === 'This Month') {
            promises.push(this.leadService.mtdCount(this.widget.options.leadType.value));
            promises.push(this.leadService.lastMonthCount(this.widget.options.leadType.value));
        } else if (this.widget.options.dateReference.value === 'Last Month') {
            promises.push(this.leadService.lastMonthCount(this.widget.options.leadType.value));
            promises.push(this.leadService.twoMonthsAgoCount(this.widget.options.leadType.value));
        } else if (this.widget.options.dateReference.value === 'This Week') {
            promises.push(this.leadService.thisWeekCount(this.widget.options.leadType.value));
            promises.push(this.leadService.lastWeekCount(this.widget.options.leadType.value));
        } else {
            return;
        }

        this.busyIndicator.promise = this.$q.all(promises)
            .then((values: number[]) => {
                this.value = this.$filter('number')(values[0]);

                var timePeriod = this.dashboardService.getTimePeriod(this.widget.options.dateReference.value);
                var percentIntoTimePeriod = this.dashboardService.getPercentIntoTimePeriod(this.widget.options.dateReference.value);
                this.overComparison = this.dashboardService.calculateComparisonText(values[0], values[1], timePeriod, percentIntoTimePeriod);

                this.updateValueSize(this.value);
            });
    }

    public updateValueSize = (value) => {
        this.slabTextService.setupSlabText(
            this.jQueryService.getElement('.value', this.$element)
                .html(value),
            { maxFontSize: 40 }
        );
    }

    public $onInit = () => {

        this.busyIndicator = {
            message: 'Loading...'
        };

        this.dateReferenceOptions = [{
            label: 'YTD',
            value: 'YTD'
            }, {
                label: 'Last 90 Days',
                value: 'Last 90 Days'
            }, {
                label: 'Last Month',
                value: 'Last Month'
            }, {
                label: 'This Month',
                value: 'This Month'
            }, {
                label: 'This Week',
                value: 'This Week'
            }
        ];

        this.leadTypeOptions = [
            {
                label: 'All',
                value: 'All'
            },
            {
                isDivider: true
            },
            {
                label: 'Inquiries Only',
                value: 'Inquiries Only'
            },
            {
                label: 'Prospects Only',
                value: 'Prospects Only'
            }
        ];

        this.loadValue();

        this.dashboardService.refreshFunctions.push(this.loadValue);
    }
}

const dashboardWidgetLeadTotalsComponent = {
    bindings: { 
        widget: '='
    },
    templateUrl: 'app/states/common/dashboard/widgets/leadTotal/dashboardWidgetLeadTotals.html',
    controller: DashboardWidgetLeadTotalsController,
    controllerAs: 'vm'
};

app.component('dashboardWidgetLeadTotals', dashboardWidgetLeadTotalsComponent);