import { IHttpService, IPromise } from "angular";
import * as saveAs from "file-saver";
import { Injectables } from "../configuration/injectables";
import app from "../main";

export class FileDownloader {
    
    public static $inject = [
        Injectables.$http
    ];

    constructor(
        private readonly $http: IHttpService
    ) {}

    public downloadFile(url: string, data: any): IPromise<any> {
        return this.$http
            .post<BlobPart>(url, data, { responseType: 'arraybuffer' })
            .then((response) => {
                
                var headers = response.headers();
                var dispositionHeader = headers['content-disposition'].replace(/['"]+/g, '');
                var filename = dispositionHeader.substring(dispositionHeader.indexOf('filename=') + 9);

                var blob = new Blob([response.data], { type: headers['content-type'] });
                
                //uses FileSave.js
                //http://purl.eligrey.com/github/FileSaver.js/blob/master/FileSaver.js
                saveAs(blob, filename);
            });
    }
}

app.service(Injectables.FileDownloader, FileDownloader);
