import { CustomersService } from "../../../api/customerService";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { CustomerListItem } from "../../../components/customerList/customerListItem";
import { CustomerTableQueryOptions } from "../../../components/customerList/customerTableQueryOptions";
import { Injectables } from "../../../configuration/injectables";
import { ModalOpener } from "../../../modals/modalOpener";
import { IDebounceDelayer } from "../../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../../utilities/tables/table";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";

export class CustomerGridController {
    
    public static $inject = [
        Injectables.ModalOpener,
        Injectables.CustomersService,
        Injectables.IDebouceDelayer,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly modalOpener: ModalOpener,
        private readonly customersService: CustomersService,
        debouceDelayer: IDebounceDelayer,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {
        this.table = new Table(debouceDelayer);
        this.table.queryOptions.isActive = true;
        this.table.onChange = this.loadCustomers;
    }

    public readonly table: Table<CustomerListItem, CustomerTableQueryOptions>;
    public busyIndicator: BusyIndicator;
    
    public showNewCustomerModal = () => {
        this.modalOpener.showNewCustomerModal()
            .result
            .then(function(){})
            .catch(function(){});
    }

    private loadCustomers = () => {
        this.busyIndicator.promise = this.customersService.getCustomerListData(this.table.queryOptions)
            .then((response) => {
                this.table.setData(response.items, response.totalRecordCount);
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to load customer information');
            });
    }

    public $onInit(): void {
        this.busyIndicator = {
            message: 'Loading Customers...'
        };        

        this.loadCustomers();
    }

}