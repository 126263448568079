import { SystemAccountType } from "../model/systemAccount";

export interface CurrentUserSystemAccount {
    id: number;
    companyName: string;
    isCarrier: boolean;
    isAgency: boolean;
    defaultAttorneyInFactUserId?: number;
    phone: string;
    email: string;
    accountType: SystemAccountType;
    mailAddress: string;
    mailSuiteAptNumber: string;
    mailCity: string;
    mailState: string;
    mailZip: string;
    physicalAddress: string;
    physicalSuiteAptNumber: string;
    physicalCity: string;
    physicalState: string;
    physicalZip: string;
}