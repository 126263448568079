export interface UserGroupDetail {
    id?: number;
    name: string;
    canEditUsers: boolean;
    canEditObligees: boolean;
    canEditDocumentLibrary: boolean;
    canEditActivitySettings: boolean;
    canEditUserGroups: boolean;
    canEditBondTypes: boolean;
    canEditApiConfiguration: boolean;
    canReturnPayments: boolean;
    canEditBillingEntriesReport: boolean;
}