import { CurrentUser } from "../../../api/types/authentication/currentUser";
import { Injectables } from "../../../configuration/injectables";
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";

export class FooterController {
    static $inject = [
        Injectables.CurrentUserResolver
    ];

    constructor(
        private readonly currentUserResolver: CurrentUserResolver
    ) {}

    public version: string;
    public currentUser: CurrentUser;

    public $onInit = () => {
        this.currentUser = this.currentUserResolver.getCurrentUser();
    }
}