import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { IDebounceDelayer } from "../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../utilities/tables/table";
import { BondTransactionHistoryListItem } from "./bondTransactionHistoryListItem";
import { BondTransactionHistoryTableQueryOptions } from "./bondTransactionHistoryTableQueryOptions";
import app from "../../main";
import { BondTransactionService } from "../../api/bondTransactionService";

export class BondTransactionHistoryModalController {
    
    public static $inject = [
        Injectables.Options,
        Injectables.$uibModalInstance,
        Injectables.BondTransactionService,
        Injectables.IDebouceDelayer
    ];

    constructor(
        private readonly options: BondTransactionHistoryTableQueryOptions,
        private readonly $uibModalInstance: Modal<void>,        
        private readonly bondTransactionService: BondTransactionService,
        debouceDelayer: IDebounceDelayer         
    ) {
        this.bondTransactionHistoryTable = new Table(debouceDelayer);
        this.bondTransactionHistoryTable.onChange = this.loadHistory;
    }

    public readonly bondTransactionHistoryTable: Table<BondTransactionHistoryListItem, BondTransactionHistoryTableQueryOptions>;
    public busyIndicator: BusyIndicator;  

    private loadHistory = () => {
        this.busyIndicator.promise = this.bondTransactionService.getBondTransactionHistoryListItems(this.bondTransactionHistoryTable.queryOptions)
            .then((response) => {
                this.bondTransactionHistoryTable.setData(response.items, response.totalRecordCount);
            });
    }

    public close(): void {
        this.$uibModalInstance.dismiss('close');
    }

    public $onInit() { 
        this.bondTransactionHistoryTable.queryOptions.bondTransactionId = this.options.bondTransactionId; 
        this.busyIndicator = {
            message: "Loading History..."
        };
        this.loadHistory();        
    }
}

app.controller('BondTransactionHistoryModalController', BondTransactionHistoryModalController);