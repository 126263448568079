import { BillToType } from "../../api/types/billToType";
import { CommissionCalculationType } from "../../api/types/model/eProducerAccount";

export interface BrokerCardData {
    name: string;
    phone: string;
    billToType: BillToType;
    commissionCalculationType: CommissionCalculationType;
    commission: number;
    lookupCode: string;
    isApproved: boolean;
    isLocked: boolean;
    contact1: string;
    contact2: string;
    contact3: string;
    mailAddress: string;
    mailSuiteAptNumber: string;
    mailCity: string;
    mailState: string;
    mailZip: string;
    eProducerAccountBrokerCategoryId: number;
    eProducerCategoryName: string ;
}