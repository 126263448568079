import { DocumentService } from "../../api/documentService";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { SystemSettings } from "../../configuration/settings/systemSettings";

export class QuoteDocumentsModalController {

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.DocumentService,
        Injectables.ToastMessageCreator,
        Injectables.SystemSettings
    ];

    constructor(
        private readonly $uibModalInstance: Modal<QuoteDocumentsModalResult>,
        private readonly options: DownloadQuoteDocumentsModalOptions,          
        private readonly documentService: DocumentService,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly systemSettings: SystemSettings
    ) { }

    public busyIndicator: BusyIndicator;
    public quoteId: number;
    public quoteDocumentOptions: QuoteDocumentOption[];
    public addAttachment: boolean;
    public documentViewerUrl: string;

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }

    public loadQuoteDocuments() {
        this.busyIndicator = {
            message: 'Working...',
            promise: this.documentService.loadQuoteDocumentOptions()
                .then((quoteDocumentOptions: QuoteDocumentOption[]) => {
                    this.quoteDocumentOptions = quoteDocumentOptions;
                })
        };
    }

    private getSelectedDocumentVersionIds(): number[] {
        const selectedDocumentVersionIds = [];

        for (const quoteDocumentOption of this.quoteDocumentOptions) {
            if (quoteDocumentOption.includeInDownload) {
                selectedDocumentVersionIds.push(quoteDocumentOption.documentVersionId);
            }
        }

        return selectedDocumentVersionIds;
    }

    public loadDouments(): void {

        let url = `${this.systemSettings.apiBaseUrl}DataDocument/GetQuoteDocument?`;
        url += `quoteId=${this.quoteId}`;
        url += `&addAttachment=${this.addAttachment ? 'true' : 'false'}`;

        const selectedDocumentVersionIds = this.getSelectedDocumentVersionIds();

        if (!selectedDocumentVersionIds.length) {
            return;
        }
        
        for (let i = 0; i < selectedDocumentVersionIds.length; i++) {
            url += `&documentVersionIds=${selectedDocumentVersionIds[i]}`;
        }

        this.documentViewerUrl = url;
    }

    public downloadDocuments(): void {
        this.busyIndicator = {
            message: 'Working...',
            promise: this.documentService.downloadQuoteDocuments(this.quoteId, this.getSelectedDocumentVersionIds(), this.addAttachment)
                .then(() => {
                    this.$uibModalInstance.close(new QuoteDocumentsModalResult());
                })
                .catch(() => {
                    this.toastMessageCreator.createErrorMessage('An error occurred trying to generate the quote documents');
                })
            };
    }

    public $onInit(): void {
        this.quoteId = this.options.quoteId;
        this.loadQuoteDocuments();
    }
}

app.controller('quoteDocumentsModalController', QuoteDocumentsModalController);

export interface QuoteDocumentOption {
    documentVersionId: number;
    name: string;
    includeInDownload: boolean;
    documentCategory: string;
}

export class QuoteDocumentsModalResult {}

export type DownloadQuoteDocumentsModalOptions = {
    quoteId: number;
}
