import { SelectOption } from "../types/selectOption";
import { ODataFilterDataType } from ".";
import { IFilterService } from "angular";
import * as moment from "moment";

export default class ODataFilter {
    private isEnabled: boolean;

    /***
    * @param {string} navExpression - OData expression that is used to compare to the value
    * @param {odata.filterDataType} dataType - data type that is used to create the filter
    * @param {string} label - what is used as the label for describing the filter
    * @param {string} value - used as the value to compare to or used as the min value in the expression where filters allow for min and max
    * @param {} maxValue -
    * @param {string} isVisible - expression evaluated to determine if the filter should be shown
    * @param {Array} selectOptions - options array would be used to populate a filter's uib-dropdowns and radio options
    * @param {Bool} isString - indicates if the filter should be handled as a string
    * @param {string} comparison text - indicates the text used to describe how the filter is applied (e.x. contains, is, has)
    */
    constructor(
        public navigationExpression: string,
        public dataType: string,
        public label: string,
        public value: string,
        public maxValue: string,
        public isVisible: boolean = true,
        public selectOptions: SelectOption<any>[],
        public isString: boolean,
        public comparisonText: string,
        private $filter: IFilterService) { }

    /***
    * Returns OData filter expression
    */
    public getFilterExpression(): string {

        const quote = this.isString ? "'" : '';

        switch (this.dataType) {
            case ODataFilterDataType.daysTill:
                const dateValue = moment.utc().add(this.value, 'days').format();

                return `(${this.navigationExpression} le ${dateValue})`;

            case ODataFilterDataType.money:
                if (this.value && this.maxValue) {
                    return `(${this.navigationExpression} ge ${this.value} and ${this.navigationExpression} le ${this.maxValue})`;
                }
                if (this.value) {
                    return `(${this.navigationExpression} ge ${this.value})`;
                }
                if (this.maxValue) {
                    return `(${this.navigationExpression} le ${this.maxValue})`;
                }
                return '';

            case ODataFilterDataType.date:
                const minValue = moment.utc(this.value).format();

                // we have to add 1 day to maxValue and do Less Than (NOT LE) so that we can capture records that are in the middle
                // of the last day of filter (e.g. 10-31-2016 14:00  this would not be included if filter was <= 10-31-2016)
                const maxValue = moment.utc(this.maxValue).add(1, 'd').format();

                if (this.value && this.maxValue) {
                    return `(${this.navigationExpression} ge ${minValue} and ${this.navigationExpression} lt ${maxValue})`;
                }

                if (this.value) {
                    return `(${this.navigationExpression} ge ${minValue})`;
                }

                if (this.maxValue) {
                    return `(${this.navigationExpression} lt ${maxValue})`;
                }

                return '';

            case ODataFilterDataType.state:
                if (this.value) {
                    return `(${this.navigationExpression} eq '${this.value}')`;
                }
                return '';

            case ODataFilterDataType.select:
                if (this.navigationExpression.indexOf('{0}') > 0) {
                    return this.navigationExpression.replace(/\{0\}/g, this.value);
                }
                else if (this.value) {
                    return `(${this.navigationExpression} eq ${quote}${this.value}${quote})`;
                        }
                return '';

            case ODataFilterDataType.bool:
                if (this.value) {
                    return `(${this.navigationExpression} eq ${this.value})`;
                }
                return '';

            case ODataFilterDataType.stringNotContains:
                if (this.value) {
                    if (this.navigationExpression.indexOf('{0}') > 0) {
                        return this.navigationExpression.replace(/\{0\}/g, this.value);
                    }
                    else if (this.value) {
                        return `(${this.navigationExpression} eq null or contains(${this.navigationExpression}, '${this.value}') eq false)`;
                            }
                    return '';
                }
                return '';

            default:
                if (this.navigationExpression.indexOf('{0}') > 0) {
                    return this.navigationExpression.replace(/\{0\}/g, this.value);
                }
                else if (this.value) {

                    const quotesEncoded = this.value.replace(/'/g, "''");

                    return `(contains(${this.navigationExpression}, '${quotesEncoded}'))`;

                }
                return '';
        }
    }

    /***
     * Returns human readable version of the filter
     */
    public toString(): string {
        switch (this.dataType) {
            case ODataFilterDataType.daysTill:
                return `${this.label} is within ${this.value} days`;

            case ODataFilterDataType.money:
                const numericValue = +this.value;
                const numericMaxValue = +this.maxValue;

                if (this.value && this.maxValue) {
                    return this.label + ' from ' + this.$filter('currency')(numericValue, '$') + ' to ' + this.$filter('currency')(numericMaxValue, '$');
                }
                if (this.value) {
                    return this.label + ' is >= ' + this.$filter('currency')(numericValue, '$');
                }
                if (this.maxValue) {
                    return this.label + ' is <= ' + this.$filter('currency')(numericMaxValue, '$');
                }
                return '';

            case ODataFilterDataType.date:
                const minValue = moment.utc(this.value).format('MM/DD/YYYY');
                const maxValue = moment.utc(this.maxValue).format('MM/DD/YYYY');

                if (this.value && this.maxValue) {
                    return this.label + ' from ' + minValue + ' to ' + maxValue;
                }
                if (this.value) {
                    return this.label + ' is >= ' + minValue;
                }
                if (this.maxValue) {
                    return this.label + ' is <= ' + maxValue;
                }
                return '';

            case ODataFilterDataType.state:
                if (this.value) {
                    return this.label + ' is ' + this.value;
                }
                return '';

            case ODataFilterDataType.select:
                if (this.value) {
                    let selectedOptionLabel = '';

                    for(let i = 0; i < this.selectOptions.length; i++){
                        if (this.value == this.selectOptions[i].value) {
                            selectedOptionLabel = this.selectOptions[i].label;
                            break;
                        }
                    }

                    return this.label + (this.comparisonText ? this.comparisonText : ' is ') + selectedOptionLabel;
                }
                return '';

            case ODataFilterDataType.bool:
                if (this.value) {
                    return this.label + ' is ' + this.value;
                }
                return '';

            case ODataFilterDataType.stringNotContains:
                if (this.value) {
                    return this.label + ' does\'t contain "' + this.value + '"';
                }

            default:
                if (this.value) {
                    return this.label + (this.comparisonText ? this.comparisonText : ' contains') + ' "' + this.value + '"';
                }
                return '';
        }
    }

    /***
    * Resets the filter to its original state. Clears the value and max value and resets the enabled status back to the default
    */
    public reset(): void {
        this.value = null;
        this.maxValue = null;
        this.isEnabled = null;
    }

    public getIsEnabled(): boolean {
        if (this.isEnabled === false) { return false; }
        if (this.value || this.maxValue) { return true; }

        return false;
    }

    public setIsEnabled(val: boolean): void {
        this.isEnabled = val === true;
    }
}
