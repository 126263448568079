export interface CurrentUserUser {
    systemAccountId: number;
    email: string;
    firstName: string;
    lastName: string;
    fullName: string;
    lastLoginDate: Date;
    theme: string;
    sideMenuIsCollapsed: boolean;
    userId: number;
    hasMultipleUsers: boolean;
    isSingleSignOn?: boolean;
}