export default (element: JQuery) => {

    const wrapperClass = element.attr('aa-wrapper-class') || 'field-wrapper';
    const aaFgClass = element.attr('aa-fg-class') || '';
    const inputWrapperclass = element.attr('aa-input-wrapper-class') || '';

    let ngshow = element.attr('ng-show') || '';
    if (ngshow.length > 0) {
        ngshow = `ng-show="${ngshow}"`;
    }

    const ngclass = `ng-class="{
        'empty-input' : !${element.attr('aa-field')}, 
        'disabled' : ${element.attr('ng-disabled')} 
    }"`;

    let errorMessage = element.attr('error-message') || '';

    if (errorMessage.length > 0) {
        errorMessage = `
            <div class="">
                <div class="">{{${errorMessage}}}</div>
            </div>`;
    }

    return `
        <div 
            class=" ${aaFgClass} ${wrapperClass} " 
            ${ngshow}
            ${ngclass}>
            
            <div class="${inputWrapperclass}">
                <input/>
            </div>
            
            ${errorMessage} 
        </div>`;
};