import { CancellationRequestService } from "../../api/cancellationRequestService";
import { CancellationRequestBond } from "../../api/types/cancellationRequestBond";
import { SystemAccountService } from "../../api/systemAccountService";
import { CurrentUser } from "../../api/types/authentication/currentUser";
import { BondStatus } from "../../api/types/model/bond";
import { CancellationReason } from "../../api/types/model/cancellationReason";
import { CancellationRequest, CancellationStatus } from "../../api/types/model/cancellationRequest";
import { ProratedRefund } from "../../api/types/proratedRefund";
import { RefundType } from "../../api/types/refundType";
import { SelectOption } from "../../api/types/selectOption";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { RequestCancellationOptions } from "./cancellationRequestModalOptions";
import { RequestCancellationResult } from "./RequestCancellationResult";
import { RequestCancellationResultAction } from "./RequestCancellationResultAction";
import { IQService } from "angular";
import * as moment from "moment";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";

export class CancellationRequestModalController {

    public static $inject = [
        Injectables.CurrentUserResolver, 
        Injectables.$uibModalInstance, 
        Injectables.Options, 
        Injectables.CancellationRequestService, 
        Injectables.$q, 
        Injectables.SystemAccountService, 
        Injectables.ToastMessageCreator
    ];

    public request: CancellationRequest;
    public busyIndicator: BusyIndicator;
    public aifOptions: SelectOption[];
    public bond: CancellationRequestBond;
    public refundType: RefundType;
    public cancellationReasonId: number;
    public refundOptions: SelectOption<RefundType>[];
    public currentUser: CurrentUser;
    public cancellationReasons: CancellationReason[];
    public validationMessage: string;
    public agentCancelSubmissionTypeOptions: SelectOption<string>[] = [
        { label: 'Execute Cancellation', value: 'Execute' },
        { label: 'Request Cancellation', value: 'Request' }
    ];
    public agentSubmissionType: 'Request' | 'Execute' = 'Execute';

    constructor(
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly modalInstance: Modal<RequestCancellationResult>,
        private readonly options: RequestCancellationOptions,
        private readonly cancellationRequestService: CancellationRequestService,
        private readonly $q: IQService,
        private readonly systemAccountService: SystemAccountService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) { }

    get isCarrier(): boolean {
        return this.currentUserResolver.getCurrentUser().systemAccount.isCarrier;
    }

    
    public get isAgentExecuting(): boolean {
        return this.agentSubmissionType === 'Execute' && 
                this.request && 
                !this.request.id && 
                !this.isCarrier;
    }
    
    public get isUpdating(): boolean {
        return this.agentSubmissionType === 'Request' &&
                this.request && 
                this.request.id && 
                !this.isCarrier;
    }

    public get isSubmitting(): boolean {
        return this.request && !this.request.id && !this.isCarrier;
    }

    public get isApproving(): boolean {

        // approve on submit if the user is a carrier
        // OR
        // if this is an existing request that has been changed to "execute"
        return (
                    this.request && 
                    this.request.id && 
                    this.isCarrier
                ) || (
                    !this.isCarrier &&
                    !!this.request.id &&
                    this.agentSubmissionType === 'Execute' 
                );
    }

    public get isCreating(): boolean {
        return this.request && !this.request.id && this.isCarrier;
    }

    get showRefundAdjustments(): boolean {
        return this.refundType !== RefundType.None;
    }

    public calculateRefund(): void {
        if (!this.isDateValidForPremiumCalculation()) {
            return;
        }

        this.busyIndicator.message = 'Calculating...';
        this.busyIndicator.promise = this.cancellationRequestService.getCancellationRefundAmounts(this.bond.id, this.request.effectiveDate, this.refundType)
            .then((refund: ProratedRefund) => {
                if (refund) {
                    this.request.premiumRefund = refund.premium;
                    this.request.commissionReduction = refund.commission;
                }
            });
    }

    private isDateValidForPremiumCalculation() {
        if (!this.getEffectiveDate() && (this.refundType === RefundType.ProRata )) {
            this.validationMessage = 'Please input an Effective Date to calculate returned premium';
            return false;
        }

        return true;
    }

    private getEffectiveDate() {
        if (this.request.effectiveDate) {
            return this.request.effectiveDate;
        } else {
            return this.request.requestedCancellationDate;
        }
    }

    private formValid(): boolean {
        const date = this.getEffectiveDate();

        if (!date && (this.refundType === RefundType.ProRata )) {
            this.validationMessage = 'Please input an Effective Date to calculate returned premium';
            return false;
        }

        this.validationMessage = undefined;
        return true;
    }

    private async requestCancellation(): Promise<number> {
        if (!this.request.requestedCancellationDate && this.request.effectiveDate) {
            this.request.requestedCancellationDate = this.request.effectiveDate;
        }

        return await this.cancellationRequestService.requestCancellation(
            this.bond.id, 
            this.isCarrier ? this.request.effectiveDate : this.request.requestedCancellationDate, 
            this.request.agencyComments, 
            this.request.attorneyInFactUserId,
            this.request.fees
        );
    }

    public carrierExecute(): void {
        this.busyIndicator.message = 'Cancelling Bond...';
        this.busyIndicator.promise = this.requestCancellation()
            .then((cancellationRequestId: number) => {
                this.request.id = cancellationRequestId;
                return this.approveRequest();
            })
            .catch(() => { 
                this.toastMessageCreator.createErrorMessage('An error occurred trying to cancel the bond'); 
            });
    }

    private async approveRequest(): Promise<void> {
        await this.cancellationRequestService.cancelBondByCarrier(this.request, this.cancellationReasonId)
            .then(() => this.handleCancelledBond())
            .catch(() => { 
                this.toastMessageCreator.createErrorMessage('An error occurred trying to cancel the bond'); 
            });
    }

    public handleCancelledBond(): void {
        this.toastMessageCreator.createSuccessMessage('Bond has been cancelled successfully');

        this.bond.cancellationDate = this.request.effectiveDate;
        this.bond.cancellationStatus = CancellationStatus.Cancelled;

        if (moment(this.request.effectiveDate).isAfter(moment())) {
            // effective in the future
            this.bond.status = BondStatus.PendingCancellation;
        } else {
            // effective Today
            this.bond.status = BondStatus.Cancelled;
        }

        this.modalInstance.close(new RequestCancellationResult(RequestCancellationResultAction.BondCanceled, this.bond));
    }

    public close(): void {
        this.modalInstance.dismiss();
    }

    public async getCancellationReasons(): Promise<void> {
        this.cancellationReasons = await this.systemAccountService.getCancellationReasons(this.bond.carrierSystemAccountId);

        if (this.cancellationReasons && this.cancellationReasons.length === 1) {
            this.cancellationReasonId = this.cancellationReasons[0].id;
        }
    }

    public async loadAttorneyInFactOptions(): Promise<void> {
        this.aifOptions = await this.systemAccountService.getAttorneyInFactOptions(this.bond.id);
    
        if (!this.request.attorneyInFactUserId) {
            this.request.attorneyInFactUserId = this.systemAccountService.getDefaultAttorneyInFactUserId(this.aifOptions);
        }
    }

    private setNullAmountsToZeroOnCancellationRequest(currentRequest: CancellationRequest): CancellationRequest {
        const returnRequest: CancellationRequest = currentRequest;

        if (!returnRequest.premiumRefund) {
            returnRequest.premiumRefund = 0;
        }

        if (returnRequest.commissionReduction) {
            returnRequest.commissionReduction = 0;
        }

        return returnRequest;
    }

    public async loadCancellation(): Promise<void> {

        let cancellationRequest = await this.cancellationRequestService.getCancellationRequest(this.bond.id);
        
        if (!cancellationRequest) {
            cancellationRequest = {} as CancellationRequest;
        }

        this.request = this.setNullAmountsToZeroOnCancellationRequest(cancellationRequest);

        if (this.request && this.request.requestedCancellationDate && !this.request.effectiveDate) {
            this.request.effectiveDate = this.request.requestedCancellationDate;
        }
    }

    public rescindRequest(): void {
        if (!this.request.id) {
            return;
        }

        this.busyIndicator.message = 'Rescinding...';
        this.busyIndicator.promise = this.cancellationRequestService.rescindCancellationRequest(this.request.id)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Your cancellation request has been rescinded succesfully');
                this.modalInstance.close(new RequestCancellationResult(RequestCancellationResultAction.Rescinded));
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to rescind the cancellation request');
            });
    }

    private updateRequest(): void {
        this.busyIndicator.message = 'Updating Request...';
        this.busyIndicator.promise = this.cancellationRequestService.updateCancellationRequest(
                this.request.id, 
                this.request.requestedCancellationDate, 
                this.request.agencyComments, 
                this.request.attorneyInFactUserId,
                this.request.fees
            )
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Your cancellation request has been updated');
                this.modalInstance.close(new RequestCancellationResult(RequestCancellationResultAction.Submitted));
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to update the cancellation request');
            });
    }

    private agentExecute(): void {
        if (!this.request.requestedCancellationDate && this.request.effectiveDate) {
            this.request.requestedCancellationDate = this.request.effectiveDate;
        }

        const saveCancellation = {
            premiumRefund: this.request.premiumRefund,
            commissionReduction: this.request.commissionReduction,
            fees: this.request.fees,
            reasonCode: this.request.reasonCode,
            reasonDescription: this.request.reasonDescription,
            effectiveDate: this.request.requestedCancellationDate
        } as CancellationRequest;

        this.busyIndicator.message = 'Executing Cancellation...';
        this.busyIndicator.promise = this.requestCancellation()
            .then(() => this.loadCancellation())
            .then(() => {
                this.request.premiumRefund = saveCancellation.premiumRefund;
                this.request.commissionReduction = saveCancellation.commissionReduction;
                this.request.fees = saveCancellation.fees;
                this.request.reasonCode = saveCancellation.reasonCode;
                this.request.reasonDescription = saveCancellation.reasonDescription;
                this.request.effectiveDate = saveCancellation.effectiveDate;

                return this.approveRequest();
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to cancel this bond');
            });
    }

    private submitNewRequest(): void {
        this.busyIndicator.message = 'Submitting...';
        this.busyIndicator.promise = this.requestCancellation()
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('A cancellation request has been submitted');
                this.modalInstance.close(new RequestCancellationResult(RequestCancellationResultAction.Submitted));
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to submit your request');
            });
    }

    public submit(): void {
        if (!this.formValid()) {
            return;
        }

        if (this.isAgentExecuting) {
            this.agentExecute();
        } else if (this.isUpdating) {
            this.updateRequest();
        } else if (this.isSubmitting) {
            this.submitNewRequest();
        } else if (this.isApproving) {
            this.busyIndicator.message = 'Approving Rider...';
            this.busyIndicator.promise = this.approveRequest();
        } else if (this.isCreating) {
            this.carrierExecute();
        }
    }

    get totalFees(): number {
        let totalFees = 0;

        if (this.request?.fees?.length) {
            for(let i = 0; i < this.request.fees.length; i++) {
                totalFees += this.request.fees[i].amount;
            }
        }

        return totalFees;
    }

    public $onInit(): void {
        this.bond = this.options.bond;
        this.refundOptions = this.cancellationRequestService.getRefundTypeOptions();
        this.refundType = RefundType.None;
        this.request = {} as CancellationRequest;
        this.busyIndicator = {
            message: 'Loading...'
        };

        const promises = [
            this.loadAttorneyInFactOptions(),
            this.getCancellationReasons() 
        ];

        if (this.bond.cancellationStatus === CancellationStatus.Requested) {
            promises.push(this.loadCancellation());
            this.agentSubmissionType = 'Request';
        }

        this.busyIndicator.promise = this.$q.all(promises);
    }
}
