import { CustomersService } from "../../api/customerService";
import { CustomerType } from "../../api/types/model/customer";
import { NewCustomer } from "../../api/types/newCustomer";
import { SelectOption } from "../../api/types/selectOption";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { State } from "../../states/state";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { NewCustomerResult } from "./NewCustomerResult";
import app from "../../main";
import { IPromise } from "angular";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { constants } from "../../configuration/constants";

export class NewCustomerModalController {

    public static $inject = [
        Injectables.$state,
        Injectables.$uibModalInstance,
        Injectables.CustomersService,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly $state: State,
        private readonly $uibModalInstance: Modal<NewCustomerResult>,
        private readonly customerService: CustomersService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {}

    public busyIndicator: BusyIndicator;
    public customerTypeOptions: SelectOption<string>[];
    public newCustomer: NewCustomer;
    public processingPromise: IPromise<void>;

    public $onInit(): void {
        this.busyIndicator = {};
        this.customerTypeOptions = constants.customerTypeOptions;

        this.newCustomer = {
            customerType: CustomerType.Company
        } as NewCustomer;
    }

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }
    
    public save() {
        if (this.newCustomer.customerType === CustomerType.Individual) {
            this.newCustomer.company = null;
        } else {
            this.newCustomer.contact = null;
        }

        this.busyIndicator.message = 'Saving...';
        this.busyIndicator.promise = this.customerService.createNewCustomer(this.newCustomer)
            .then((customerId) => {
                this.$uibModalInstance.close(new NewCustomerResult());
                this.$state.go('main.customerDetail', { customerId: customerId })
                this.toastMessageCreator.createSuccessMessage('New customer created successfully');
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to create the new customer');
            });
    }
}

app.controller('NewCustomerModalController', NewCustomerModalController);
