export default (
    element: JQuery, 
    labelText: string, 
    isRequired: boolean
) => {

    element
        .parent()
        .parent()
        .addClass('no-label');

    var requiredIndicator = isRequired ? '* ' : '';
    
    element.attr('placeholder', requiredIndicator + labelText);
};