export interface BondForBondAccountAssignment {
    id: number;
    bondNumber: string;
    bondType: string;
    bondAmount: number;
    effectiveDate: Date;
    expirationDate: Date;
    carrierSystemAccountId: number;
    customerId: number;
    bondAccountId?: number;
}