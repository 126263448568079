import { BondTransaction } from "./bondTransaction";
import { IBaseEntity } from "./iBaseEntity";
import { SystemAccountStatement } from "./systemAccountStatement";

export interface SystemAccountStatementLineItem extends IBaseEntity { 
    agency: string;
    agencyCode: string;
    bondDescription: string;
    bondNumber: string;
    bondTransaction: BondTransaction;
    bondTransactionId: number;
    creditReportFee: number;
    effectiveDate: Date;
    grossPremium: number;
    principalName: string;
    systemAccountStatement: SystemAccountStatement;
    systemAccountStatementId: number;
    transactionDate: Date;
    transactionFee: number;
    transactionType: string;
    writingCompany: string;
    id: number;
    createdDateTime: Date;
}