import app from "../../main";

export class PersonNameFilter {

    public static Factory(): (object?: any, name?: string) => string {
        return (object?: any, delimeter?: string) => {
            if (!object) { return ''; }

            let name = '';

            if (delimeter) {
                name = (object.prefix ? object.prefix : '') + '{|}' +
                    (object.firstName ? object.firstName : '') + '{|}' +
                    (object.middleName ? object.middleName : '') + '{|}' +
                    (object.lastName ? object.lastName : '') + '{|}' +
                    (object.suffix ? object.suffix : '');
            }
            else {
                name = (object.prefix ? object.prefix : '') + ' ' +
                    (object.firstName ? object.firstName : '') + ' ' +
                    (object.middleName ? object.middleName : '') + ' ' +
                    (object.lastName ? object.lastName : '') + ' ' +
                    (object.suffix ? object.suffix : '');
            }

            return name;
        };
    }
}

app.filter('personName', PersonNameFilter.Factory);
