export enum Injectables {
    $filter = "$filter",
    $http = "$http",
    $injector = "$injector",
    $interval = "$interval",
    $q = "$q",
    $compile = "$compile",
    $rootScope = "$rootScope",
    $sce = "$sce",
    $scope = "$scope",
    $state = "$state",
    $stateParams = "$stateParams",
    $timeout = "$timeout",
    $window = "$window",
    $uibModalInstance = "$uibModalInstance",
    $uibModal = "$uibModal",
    AddressService = "addressService",
    ApplicationFollowUpStrategyService = "applicationFollowUpStrategyService",
    ApplicationService = "applicationService",
    ApplicationTestDataCreator = "ApplicationTestDataCreator",
    AnalyticsService = "analyticsService",
    AttachmentService = "attachmentService",
    AuthenticationService = "AuthenticationService",
    AuthService = "authService",
    AutoFeeService = "autoFeeService",
    BondService = "bondService",
    BondTransactionService = "bondTransactionService",
    BondTypeService = "bondTypeService",
    BrowserNotificationService = "browserNotificationService",
    CacheFactory = "CacheFactory",
    CreditReportService = "creditReportService",
    CustomersService = "customersService",
    CustomersServiceAms360 = "customersServiceAms360",
    DashboardService = "dashboardService",
    DataExport = "dataExport",
    DataExportService = "dataExportService",
    DocumentLibraryService = "documentLibraryService",
    DocumentService = "documentService",
    EmailTemplateService = "emailTemplateService",
    EProducerService = "eProducerService",
    FollowUpService = "followUpService",
    GuidCreator = "GuidCreator",
    InvoiceService = "invoiceService",
    LeadService = "leadService",
    LeadReportService = "leadReportServce",
    LocalStorageService = "localStorageService",
    MemoService = "MemoService",
    ModalOpener = "modalOpener",
    ODataFactory = "ODataFactory",
    Options = "options",
    PaymentService = "paymentService",
    RateService = "rateService",
    SfaaCodeService = "sfaaCodeService",
    SignalRService = "signalRService",
    SupportService = "supportService",
    SystemAccountService = "systemAccountService",
    TagService = "tagService",
    UnderwritingConfigurationService = "underwritingConfigurationService",
    UnderwritingProfileService = "underwritingProfileService",
    UserService = "userService",
    UtilityService = "utilityService",
    WritingCompanyService = "writingCompanyService",
    ObligeeService = "obligeeService",
    ObligeeHistoryService = "obligeeHistoryService",
    EpicIntegrationService = "epicIntegrationService",
    ActivityService = "activityService",
    FileDownloader = "FileDownloader",
    RiderRequestService = "riderRequestService",
    BondAccountService = "bondAccountService",
    TableSorter = "TableSorter",
    Table = "Table",
    TablePager = "TablePager",
    IDebouceDelayer = "IDebouceDelayer",
    $urlRouterProvider = "$urlRouterProvider",
    $stateProvider = "$stateProvider",
    QuoteApi = "QuoteApi",
    QueryStringBuilder = "QueryStringBuilder",
    CurrencyFormatter = "CurrencyFormatter",
    UserGroupService = "userGroupService",
    ngToast = "ngToast",
    ToastMessageCreator = "ToastMessageCreator",
    CurrentUserResolver = "currentUserResolver",
    StateChangeListener = "StateChangeListener",
    ITextHighlighter = "ITextHighlighter",
    $parse = "$parse",
    $document = "$document",
    $attrs = "$attrs",
    $location = "$location",
    $anchorScroll = "$anchorScroll",
    AngularAgilityFormsExtensionsProvider = "aaFormExtensionsProvider",
    $element = "$element",
    constants = "constants",
    ngToastProvider = "ngToastProvider",
    $provide = "$provide",
    ModalCreator = "ModalCreator",
    SlabTextService = "SlabTextService",
    PerfectScrollbarService = "PerfectScrollbarService",
    SystemSettings = "SystemSettings",
    JQueryService = "jqService",
    JQueryProvider = "JQueryProvider",
    AmChartService = "AmChartService",
    BillingEntries = "BillingEntriesService",
    CancellationRequestService = "CancellationRequestService",
    ReinstatementRequestService = "ReinstatementRequestService",
    aaNotify = "aaNotify",
    CacheStore = "CacheStore"
}
