import { IAttributes, IController, IScope } from "angular";
import { A3WebApplicationFormFields } from "../../api/types/a3WebApplicationFormFields";
import { Person, MaritalStatus } from "../../api/types/model/person";
import app from "../../main";
import isDefined from "../../utilities/angularUtilities/isDefined";

export interface ChangedProperty {
    person: Person;
    changedProperty: any;
}

class PersonFormController {
    public static $inject: string[] = [];

    constructor() { }

    public person: Person;
    public changed?: (changedProperty: ChangedProperty) => void;
    public requiredFields: A3WebApplicationFormFields;
    public hideOptionalFields: boolean;
    public heading: any;
    public isDisabled?: boolean;
    public spousalIndemnityRequired: boolean;

    public get isMarried(): boolean {
        return this.person && (
            this.person.maritalStatus === MaritalStatus.Separated ||
            this.person.maritalStatus === MaritalStatus.Married
        );
    }

    public addressChanged(object: any, changedProperty: any): void {
        this.localChange(changedProperty);
    }

    public localChange(property: any): void {
        this.changed({ person: this.person, changedProperty: property });
    }

    public isSpouseFieldRequired(field: string): boolean {
        if (!this.requiredFields) {
            return false;
        }

        return (this.isMarried && this.spousalIndemnityRequired) || this.requiredFields[field];
    }

    public $onInit(): void { }
}

const personDirective = () => {
    
    const link = (
        scope: IScope, 
        elm: JQuery, 
        attrs: IAttributes, 
        ctrl: IController) => {

        if (isDefined(attrs.disabled)) {
            ctrl.isDisabled = true;
        }

        if (isDefined(attrs.ngDisabled)) {
            ctrl.isDisabled = scope.$parent.$eval(attrs.ngDisabled);
        }
    };

    return {
        restrict: 'E',
        templateUrl: 'app/components/personForm/person.html',
        scope: {},
        transclude: {
            heading: '?personHeading' 
        },
        bindToController: {
            person: '=',
            changed: '&',
            requiredFields: '=',
            hideOptionalFields: '=',
            heading: '=',
            isDisabled: '=?',
            spousalIndemnityRequired: '=?'
        },
        link: link,
        controller: PersonFormController,
        controllerAs: 'vm'
    };
};

app.directive('person', personDirective);
