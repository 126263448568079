import { ObligeeDetailBondType } from "./obligeeDetailBondType";

export interface ObligeeDetail {
    id: number; 
    name: string; 
    isActive: boolean; 
    mailAddress: string; 
    mailSuiteAptNumber: string; 
    mailCity: string;
    mailState: string; 
    mailZip: string; 
    physicalAddress: string; 
    physicalSuiteAptNumber: string; 
    physicalCity: string; 
    physicalState: string;
    physicalZip: string; 
    phone: string; 
    fax: string; 
    email: string; 
    websiteURL: string; 
    contact1: string;
    contact2: string;
    contact3: string;
    requisitioningState: string;
    requisitioningDepartment: string; 
    comments: string; 
    createdDateTime: Date;
    mailCounty: string; 
    physicalCounty: string; 
    isGeneric: boolean; 
    masterObligeeId: number;
    bondTypes: ObligeeDetailBondType[];
}