import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { AttachmentService } from "../../api/attachmentService";

export class AttachmentViewerModalController {

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.AttachmentService
    ];

    constructor(
        private readonly $uibModalInstance: Modal<AttachmentViewerModalResult>,
        private readonly options: AttachmentViewerModalOptions,
        private readonly attachmentService: AttachmentService
    ) { }

    public busyIndicator: BusyIndicator;
    public attachmentId: number;
    public fileName: string;
    public url: string;

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }

    public $onInit(): void {
        this.fileName = this.options.fileName;
        this.attachmentId = this.options.attachmentId;
        this.url = this.attachmentService.getAttachmentDownloadUrl(this.attachmentId);
    }
}

app.controller('attachmentViewerModalController', AttachmentViewerModalController);

export class AttachmentViewerModalResult {}

export type AttachmentViewerModalOptions = {
    attachmentId: number;
    fileName: string;
}
