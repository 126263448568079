import { SystemAccountService } from "../../../api/systemAccountService";
import { SelectOption } from "../../../api/types/selectOption";
import { Injectables } from "../../../configuration/injectables";
import app from "../../../main";
import { Table } from "../../../utilities/tables/table";
import { RiderReportTableQueryOptions } from "./riderReportTableQueryOptions"
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";
import { RiderReportListItem } from "./riderReportItem";

class RiderReportFilterDropdownController {
    public static $inject = [
        Injectables.SystemAccountService,
        Injectables.CurrentUserResolver
    ];

    constructor(
        private readonly systemAccountService: SystemAccountService,
        private readonly currentUserResolver: CurrentUserResolver
    ) {

        this.modifiedFilters = {} as RiderReportTableQueryOptions;
        this.filterCount = 0;
    }

    public isOpen: boolean;
    public filterCount: number;
    public modifiedFilters: RiderReportTableQueryOptions;
    public carrierOptions: SelectOption<number>[];
    public agencyOptions: SelectOption<number>[];
    public isCarrier: boolean;
    public table: Table<RiderReportListItem, RiderReportTableQueryOptions>;
    
    private loadCarrierOptions() {
        return this.systemAccountService.getAppointedCarrierOptions()
            .then((carrierOptions) => {
                this.carrierOptions = carrierOptions;
                this.carrierOptions.unshift({ label: 'Any', value: null });
            });
    }

    private loadAgencyOptions() {
        return this.systemAccountService.getAppointedAgencyOptions()
            .then((agencyOptions) => {
                this.agencyOptions = agencyOptions;
                this.agencyOptions.unshift({ label: 'Any', value: null});
            });
    }

    public clearFilters() {
        this.modifiedFilters = {
            carrierSystemAccountId:null,
            agencySystemAccountId: null,
            minimumCreatedDateTime: null,
            maximumCreatedDateTime: null,
            minimumAmount: null,
            maximumAmount: null,
            minimumEffectiveDate: null,
            maximumEffectiveDate: null
        } as RiderReportTableQueryOptions ;

        this.table.applyFilters({ ...this.modifiedFilters });

        this.setFilterCount();
        this.isOpen = false;
    }

    public cancel() {
        this.isOpen = false;
        this.modifiedFilters = { ...this.table.queryOptions };
    }

    public applyFilters() {
        this.isOpen = false;
        this.table.applyFilters({ ...this.modifiedFilters });
        this.setFilterCount();
    }

    private setModifiedFilters = () => {
        this.modifiedFilters.carrierSystemAccountId = this.table.queryOptions.carrierSystemAccountId;
        this.modifiedFilters.agencySystemAccountId = this.table.queryOptions.agencySystemAccountId;
        this.modifiedFilters.minimumEffectiveDate = this.table.queryOptions.minimumEffectiveDate;
        this.modifiedFilters.maximumEffectiveDate = this.table.queryOptions.maximumEffectiveDate;
        this.modifiedFilters.minimumAmount = this.table.queryOptions.minimumAmount;
        this.modifiedFilters.maximumAmount = this.table.queryOptions.maximumAmount;
        this.modifiedFilters.minimumCreatedDateTime = this.table.queryOptions.minimumCreatedDateTime;
        this.modifiedFilters.maximumCreatedDateTime = this.table.queryOptions.maximumCreatedDateTime;
        this.setFilterCount();
    };

    public setFilterCount() {
        this.filterCount = 0;

        if (this.table.queryOptions.carrierSystemAccountId) {
            this.filterCount++;
        }
        
        if (this.table.queryOptions.agencySystemAccountId) {
            this.filterCount++;
        }

        if ( 
            this.table.queryOptions.minimumCreatedDateTime ||
            this.table.queryOptions.maximumCreatedDateTime
        ) {
            this.filterCount++;
        }
        
        if (
            this.table.queryOptions.minimumEffectiveDate ||
            this.table.queryOptions.maximumEffectiveDate
        ) {
            this.filterCount++;
        }

        if ( 
            this.table.queryOptions.maximumAmount ||
            this.table.queryOptions.minimumAmount
        ) {
            this.filterCount++;
        }
    }

    public $onInit() {
        this.isCarrier = this.currentUserResolver.getCurrentUser().systemAccount.isCarrier;

        if (this.isCarrier) {
            this.loadAgencyOptions();
        } else {
            this.loadCarrierOptions();
        }

        this.setModifiedFilters();
    }
}

const riderReportFilterDropdownComponent = {
    bindings: {
        table: "<"
    },
    controller: RiderReportFilterDropdownController,
    controllerAs: "vm",
    templateUrl: "app/states/common/riders/riderReportFilterDropdown.html"
};

app.component(
    "riderReportFilterDropdown",
    riderReportFilterDropdownComponent
);