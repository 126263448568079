import { AppointmentBondProductConfiguration } from "./appointmentBondProductConfigurations";
import { BondType } from "./bondType";
import { IBaseEntity } from "./iBaseEntity";
import { Rate } from "./rate";
import { SystemAccount } from "./systemAccount";

export interface BondProductConfiguration extends IBaseEntity { 
    appointmentBondProductConfigurations: AppointmentBondProductConfiguration[];
    bondType: BondType;
    bondTypeId: number;
    offerToAllAppointments: boolean;
    rate: Rate;
    rateId: number;
    riskGroupImplementationId: number;
    systemAccount: SystemAccount;
    systemAccountId: number;
    id: number;
    createdDateTime: Date;
}