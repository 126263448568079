import { IPromise } from "angular";
import { SystemAccountService } from "../../../api/systemAccountService";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../configuration/injectables";
import { ModalOpener } from "../../../modals/modalOpener";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { CarrierApiApprovedIp } from "./carrierApiApprovedIp";
import { CarrierApiConfiguration } from "./carrierApiConfiguration";

export class CarrierApiConfigurationController {

    public static $inject = [
        Injectables.SystemAccountService,
        Injectables.ModalOpener,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly systemAccountService: SystemAccountService,
        private readonly modalOpener: ModalOpener,
        private readonly toastMessageCreator: ToastMessageCreator
    ){}

    public busyIndicator: BusyIndicator;
    public configuration: CarrierApiConfiguration;
    public newToken: string;
    public newIpAddress: string;
    public invalidIpAddress: boolean;

    public get hasActiveAuthenticationToken() {
        return (this.configuration && 
                this.configuration.apiTokenCreatedDateTime) ||
                this.newToken;
    }

    public addApprovedIpAddress(): void {
        this.invalidIpAddress = !this.isValidIpAddress(this.newIpAddress);

        if (!this.invalidIpAddress) {
            this.configuration.approvedIpAddresses.push({ipAddress: this.newIpAddress});
            this.newIpAddress = null;
        }
    }

    public removeIpAddress(ipAddress: CarrierApiApprovedIp, index: number) {
        if (ipAddress.id) {
            ipAddress.isRemoved = ipAddress.isRemoved !== true;
        } else {
            this.configuration.approvedIpAddresses.splice(index, 1);
        }
    }

    private isValidIpAddress(ipAddress: string): boolean {
        const expression = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        return !!ipAddress && expression.test(ipAddress);
    }

    public promptToDeactivateToken(): void {
        this.modalOpener.confirmModal(
            'Deactivate Token',
            'Are you sure you want to deactivate the api authentication token?',
            'Deactivate',
            'Cancel'
        )
        .result
        .then(() => {
            this.deactivateToken();
        })
        .catch(() => {});
    }

    public deactivateToken(): void {
        this.busyIndicator.message = 'Deactivating Token...',
        this.busyIndicator.promise = this.systemAccountService.deactivateCarrierApiAuthenticationToken()
            .then(() => {
                this.configuration.apiTokenCreatedDateTime = null;
                this.newToken = null;
                this.toastMessageCreator.createSuccessMessage('Api authentication token has been deactivated');
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to deactivate the api authentication token');
            })
    }

    public promptToRegenerateToken(): void {
        this.modalOpener.confirmModal(
                'Recreate Token', 
                'Recreating the api authentication token will deactivate the current token. Are you sure you want to proceed?',
                'Recreate Token',
                'Cancel')
            .result
            .then(() => {
                this.createAuthenticationToken();
            })
            .catch(() => {});
    }

    public createAuthenticationToken() {
        this.busyIndicator.message = 'Creating Authentication Token';
        this.busyIndicator.promise = this.systemAccountService.createApiAuthenticationToken()
            .then((newToken) => {
                this.newToken = newToken;
                this.toastMessageCreator.createSuccessMessage('Api authentication was created successfully');
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to create the authentication token');
            });
    }

    private loadConfiguration(): IPromise<any> {
        return this.systemAccountService.loadCarrierApiConfiguration()
            .then((configuration) => {
                this.configuration = configuration;
            })
            .catch(() => {});
    }

    public saveConfiguration() {
        this.busyIndicator.message = 'Saving...';
        this.busyIndicator.promise = this.systemAccountService.saveCarrierApiConfiguration(this.configuration)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Api configuration saved successfully');
                return this.loadConfiguration();
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred while trying to save');
            });
    }

    public $onInit(): void {
        this.busyIndicator = {
            message: 'Loading...',
            promise: this.loadConfiguration()
        }
    }
}