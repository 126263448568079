import { BondService } from "../../api/bondService";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { BondBulkDownloadModalOptions } from "./bondBulkDownloadModalOptions";
import { BondBulkDownloadModalResult } from "./bondBulkDownloadModalResult";
import app from "../../main";
import { IPromise, IRootScopeService } from "angular";

export class BondBulkDownloadModalController {
    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.BondService
    ];

    constructor(
        private readonly $uibModalInstance: Modal<BondBulkDownloadModalResult>,
        private readonly modalOptions: BondBulkDownloadModalOptions,
        private readonly bondService: BondService
    ) {
    }

    public bondIds: number[];
    public processingPromise: IPromise<void>;

    public $onInit(): void {
        this.bondIds = this.modalOptions.bondIds;
    }

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }

}

app.controller('BondBulkDownloadModalController', BondBulkDownloadModalController);
