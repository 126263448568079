import { IControllerConstructor, Injectable } from "angular";

export interface ModalOpenOptions {
    bindToController: boolean;
    backdrop?: true | false | 'static';
    backdropClass?: string;
    controller: string | Injectable<IControllerConstructor>;
    controllerAs: string;
    animation?: boolean;
    appendTo?: any;
    component?: string;
    keyboard?: boolean;
    size: string;
    templateUrl: string;
    windowClass?: string;
    resolve?: {};
}