export interface UserDetail {
    id?: number;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    signatureImageFileId: number;
    isAttorneyInFact?: boolean;
    canEditUsers: boolean;
    canEditObligees: boolean;
    canEditDocumentLibrary: boolean;
    canEditActivitySettings: boolean;
    canEditUserGroups: boolean;
    canEditBondTypes: boolean;
    canEditApiConfiguration: boolean;
    isProducer?: boolean;
    isClientServiceExecutive?: boolean;
    isClientServiceManager?: boolean;
    isClientServiceAgent?: boolean;
    isLockedOut?: boolean;
    epicUserCode?: string;
    userGroupIds: number[];
}
