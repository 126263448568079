import { InvoiceService } from "../../api/invoiceService";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { Table } from "../../utilities/tables/table";
import { InvoiceListItem } from "./invoiceListItem";
import { InvoiceTableQueryOptions } from "./invoiceTableQueryOptions";

class InvoiceListController {

    public static $inject = [
        Injectables.InvoiceService
    ];

    constructor(
        private readonly invoiceService: InvoiceService
    ) {
    }

    public table: Table<InvoiceListItem, InvoiceTableQueryOptions>;

    public getInvoiceDownloadUrl(invoiceId) {
        return this.invoiceService.getInvoiceDocumentUrl(invoiceId);
    }
    
    public $onInit(): void { }
}

const invoiceListComponent = {
    templateUrl: 'app/components/invoiceList/invoiceList.html',
    bindings: { 
        table: '<',
        customerId: '<?', 
        eproducerAccountId: '<?'
    },
    controllerAs: 'vm',
    controller: InvoiceListController
};

app.component('invoiceList', invoiceListComponent);