import { SelectOption } from "../../../api/types/selectOption";
import { UserGroupService } from "../../../api/userGroupService";
import { Injectables } from "../../../configuration/injectables";
import app from "../../../main";
import { Table } from "../../../utilities/tables/table";
import { UserTableItem } from "./userTableItem";
import { UserTableQueryOptions } from "./userTableQueryOptions";

export class UserListFilterDropdownController {
    static $inject = [
        Injectables.UserGroupService
    ];

    constructor(
            private readonly userGroupService: UserGroupService
        ) {
        this.modifiedFilters = {} as UserTableQueryOptions;
    }

    public isOpen: boolean;
    public filterCount: number = 0;
    public modifiedFilters: UserTableQueryOptions;
    public userGroupOptions: SelectOption[];
    
    public table: Table<UserTableItem, UserTableQueryOptions>;

    public clearFilters() {
        this.modifiedFilters.userGroupId = null;
        this.modifiedFilters.isLockedOut = null;
        
        this.table.applyFilters({ ...this.modifiedFilters });
        
        this.setFilterCount();
        this.isOpen = false;
    }

    public cancel() {
        this.isOpen = false;
        this.modifiedFilters = { ...this.table.queryOptions };
    }

    public applyFilters() {
        this.isOpen = false;

        this.table.applyFilters({ ...this.modifiedFilters });
        
        this.setFilterCount();
    }

    public setFilterCount() {
        this.filterCount = 0;

        if (this.table.queryOptions.userGroupId) {
            this.filterCount++;
        }

        if (this.table.queryOptions.isLockedOut) {
            this.filterCount++;
        }
    }

    private loadUserGroups() {
        this.userGroupService.getUserGroupSelectOptions()
            .then((userGroupOptions) => {
                this.userGroupOptions = [{ label: 'Any', value: null }];

                for(let i = 0; i < userGroupOptions.length; i++) {
                    this.userGroupOptions.push({
                        label: userGroupOptions[i].label,
                        value: userGroupOptions[i].value
                    });
                }
            });
    }

    public $onInit() {
        this.modifiedFilters = { ...this.table.queryOptions };
        this.setFilterCount();
        this.loadUserGroups();
    }
}

const userListFilterDropdownComponent = {
    bindings: {
        table: '<'
    },
    controller: UserListFilterDropdownController,
    controllerAs: 'vm',
    templateUrl: 'app/states/common/users/userListFilterDropdown.html'
};

app.component('userListFilterDropdown', userListFilterDropdownComponent);