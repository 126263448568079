import { Injectables } from "../../../../configuration/injectables";
import app from "../../../../main";
import { DashboardService } from "../dashboardService";
import { DashboardWidget } from "../types/dashboardWidget";
import { WidgetColor } from "./widgetColors";

export class DashboardWidgetColorsController {

    public static $inject = [
        Injectables.DashboardService   
    ];

    constructor( 
        private readonly dashboardService: DashboardService
    ) {}

    public widget: DashboardWidget;
    public widgetColors: WidgetColor[];
    
    public setWidgetBorderColor = (color: WidgetColor) => {
        this.widget.options.color = color;
        this.dashboardService.save();
    };

    public $onInit = () => {
        this.widgetColors = this.dashboardService.widgetColors;
    }

}

const dashboardWidgetColorsComponent = {        
        templateUrl: 'app/states/common/dashboard/widgetColorSelector/dashboardWidgetColors.html',
        bindings: { widget: '=' },
        controller: DashboardWidgetColorsController,
        controllerAs: 'vm'
};

app.component('dashboardWidgetColors', dashboardWidgetColorsComponent);