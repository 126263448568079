import * as angular from "angular";
import { IDirectiveFactory } from "angular";
import app from "../../main";
import { TimeAgoScope } from "./timeAgoScope";

export const timeAgoDirective: IDirectiveFactory = (timeAgo, nowTime) => {

    const link = (scope: TimeAgoScope, elem: JQuery) => {

        var fromTime = timeAgo.parse(scope.fromTime);

        // Track changes to time difference
        scope.$watch(() => {
            return nowTime() - fromTime;
        }, (value) => {
            angular
                .element(elem)
                .text(timeAgo.inWords(value, fromTime, scope.format));
        });
    }

    return {
        scope: {
            formatTime: '@',
            format: '@'
        },
        restrict: 'EA',
        link: link
    }
}

timeAgoDirective.$inject = [
    'timeAgo', 
    'nowTime'
];

app.directive('timeAgo', timeAgoDirective);
