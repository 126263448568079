import * as angular from "angular";
import { IParseService, IScope, IAttributes, INgModelController } from "angular";
import { ODataFactory, ODataEndpoint } from "../../api/odata";
import { ZipLookup } from "../../api/types/model/zipLookup";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";

const zipCityState = (
    odata: ODataFactory, 
    $parse: IParseService) => {

    const link = (
        scope: IScope, 
        element: JQuery, 
        attributes: IAttributes, 
        controller: INgModelController) => { 

        // if city and state is set
        const hasCity = element.attr('city') ? true : false;
        const hasState = element.attr('state') ? true : false;

        const cityGetFn = $parse(attributes.city);
        const stateGetFn = $parse(attributes.state);
        const setCityFn = cityGetFn.assign || angular.noop;
        const setStateFn = stateGetFn.assign || angular.noop;

        controller.$viewChangeListeners.push(() => {
            const zipExpression = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

            if (!controller.$modelValue) {
                return;
            }

            if (!controller.$modelValue.match(zipExpression)) {
                return;
            }

            if (cityGetFn(scope) || stateGetFn(scope)) {
                return;
            }
        
            const zipLookupSvc = odata.getService<ZipLookup>(ODataEndpoint.ZipLookup);

            const query = odata.getQuery();
            query.filter('zip eq ' + controller.$modelValue);

            zipLookupSvc.get(query)
                .then((response) => {
                    if (response.data.value.length <= 0) {
                        return;
                    }

                    if (hasCity) {
                        setCityFn(scope, response.data.value[0].city);
                    }

                    if (hasState) {
                        setStateFn(scope, response.data.value[0].state);
                    }
                });
            });
    };

    return {
        require: 'ngModel',
        link: link
    };
}

zipCityState.$inject = [
    Injectables.ODataFactory,
    Injectables.$parse
];

app.directive('zipCityState', zipCityState);
