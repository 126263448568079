import { IBaseEntity } from "./iBaseEntity";

export interface Log extends IBaseEntity { 
    thread: string;
    level: string;
    logger: string;
    message: string;
    exception: string;
    id: number;
    createdDateTime: Date;
}