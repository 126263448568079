import { BondAccountRateType } from "../../api/types/bondAccounts/bondAccountRateType";
import { BondStatus, RenewalStatus } from "../../api/types/model/bond";
import { CommissionType } from "../../api/types/model/commissionType";
import { Fee } from "../../api/types/model/fee";
import { SelectOption } from "../../api/types/selectOption";

export interface RenewalModalBond {
    id: number;
    nameOnBond: string;
    carrierSystemAccountId: number;
    writingCompany: string;
    bondAccountId?: number;
    carrierAccountNumber?: string;
    bondNumber: string;
    bondAmount: number;
    bondType: string;
    currentEffectiveDate: Date;
    currentExpirationDate: Date;
    newEffectiveDate: Date;
    newExpirationDate: Date;
    status: BondStatus;
    renewalStatus: RenewalStatus;
    lastTermLength: number;
    lastPremiumAmount: number;
    lastCommissionPercent: number;
    lastCommissionAmount: number;
    premiumOptions: SelectOption<string>[];
    bondAccountRatePerThousand: number;
    bondAccountRateType: BondAccountRateType;
    commissionPercent: number;
    commissionType: CommissionType;
    commission: number;
    isBondAccountPremium: boolean;
    premium: number;
    fees: Fee[];
}