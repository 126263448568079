import { IBaseEntity } from "./iBaseEntity";
import { Rate } from "./rate";
import { UnderwritingConfigurationRateQuestion } from "./underwritingConfigurationRateQuestion";

export interface UnderwritingConfigurationRate extends IBaseEntity {
    underWritingConfigurationId: number;
    rate: Rate;
    rateId: number;
    sequence: number;
    underwritingConfigurationRateQuestions: UnderwritingConfigurationRateQuestion[];
}
