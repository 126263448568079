import { A3WebApplicationFormFields } from "./a3WebApplicationFormFields";
import { MasterApplicationQuestion } from "./model/masterApplicationQuestion";
import { UnderwritingQuestions } from "./underwritingQuestions";

export interface A3WebApplicationForm { 
    required: A3WebApplicationFormFields;
    masterApplicationQuestion: MasterApplicationQuestion[];
    minimumEffectiveDate: Date;
    maximumEffectiveDate: Date;
    underwritingQuestions: UnderwritingQuestions[];
}