import { IndemnityRequirements } from "./model/quote";


export interface EProducerPreQualificationQuote { 
    term: number;
    requiresCredit: boolean;
    requiresManualReview: boolean;
    requiresAdditionalDocuments: boolean;
    totalPremium: number;
    totalAmountDue: number;
    totalFees: number;
    carrierDescription: string;
    carrierAmBestRating: string;
    carrierName: string;
    isSponsored: boolean;
    sequence: number;
    carrierSystemAccountId: number;
    indemnityRequirements: IndemnityRequirements;
    highlightComments: string;
    minimumEffectiveDate: Date;
    maximumEffectiveDate: Date;
}


