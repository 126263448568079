import { BondService } from "../../api/bondService";
import { BondPrincipal } from "../../api/types/bondPrincipal";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import app from "../../main";
import { IPromise } from "angular";
import { AssignPrincipalModalOptions } from "./AssignPrincipalModalOptions";
import { AssignPrincipalModalResult } from "./AssignPrincipalModalResult";
import { AssignPrincipalBond } from "./AssignPrincipalBond";

export class AssignPrincipalModalController {
    
    public static $inject = [
        Injectables.Options,
        Injectables.$uibModalInstance,
        Injectables.BondService,
        Injectables.ToastMessageCreator
    ];

    constructor(

        private readonly options: AssignPrincipalModalOptions,
        private readonly $uibModalInstance: Modal<AssignPrincipalModalResult>,
        private readonly bondService: BondService,
        private readonly toastMessageCreator: ToastMessageCreator) {
    }

    public processingPromise: IPromise<any>;
    public bondIds: number[];
    public customerId: number;
    public bonds: AssignPrincipalBond[];
    public isCompanyPrincipalType: boolean;
    public principal: BondPrincipal;

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }

    public submit(): void {
        if (!this.isCompanyPrincipalType) {
            this.principal.company = null;
        }

        this.processingPromise = this.bondService.assignPrincipal(this.bonds, this.principal)
            .then(() => {
                this.$uibModalInstance.close({} as AssignPrincipalModalResult);
                this.toastMessageCreator.createSuccessMessage('Principal assigned successfully');
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to assign a principal information');
            });
    }

    public loadBonds(customerId: number, bondIds: number[]): IPromise<any> {
        return this.bondService.getBondsForPrincipalAssignment(customerId, bondIds)
            .then((data) => {
                this.bonds = data.bonds;
                this.principal = data.principal;

                if (this.principal.company) {
                    this.isCompanyPrincipalType = true;
                }
            });
    }

    public $onInit() { 
        this.bondIds = this.options.bondIds;
        this.customerId = this.options.customerId;

        this.processingPromise = this.loadBonds(this.customerId, this.bondIds);
    }
}

app.controller('AssignPrincipalModalController', AssignPrincipalModalController);

