import {
    ODataFactory,
    ODataFilterCollection,
    ODataFilterDataType,
    ODataEndpoint
} from "../../../../../api/odata";
import { SfaaCodeService } from "../../../../../api/sfaaCodeService";
import { SystemAccountService } from "../../../../../api/systemAccountService";
import { TagService } from "../../../../../api/tagService";
import isDefined from "../../../../../utilities/angularUtilities/isDefined";
import { Bond } from "../../../../../api/types/bond";
import { SelectOption } from "../../../../../api/types/selectOption";
import { BusyIndicator } from "../../../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../../../configuration/injectables";
import { ModalOpener } from "../../../../../modals/modalOpener";
import { DashboardService } from "../../dashboardService";
import { DashboardWidget } from "../../types/dashboardWidget";
import app from "../../../../../main";
import A3ApiResponse from "../../../../../api/types/a3ApiResponse";
import { ITimeoutService } from "angular";
import { Table } from "../../../../../utilities/tables/table";
import { IDebounceDelayer } from "../../../../../utilities/debounceDelayer/iDebouceDelayer";
import { CurrentUserResolver } from "../../../../../utilities/currentUserResolver/currentUserResolver";

class DashboardWidgetBondsAgentController {
    public static $inject = [
        Injectables.CurrentUserResolver,
        Injectables.ODataFactory,
        Injectables.DashboardService,
        Injectables.$timeout,
        Injectables.ModalOpener,
        Injectables.TagService,
        Injectables.SfaaCodeService,
        Injectables.SystemAccountService,
        Injectables.IDebouceDelayer
    ];

    constructor(
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly odata: ODataFactory,
        private readonly dashboardService: DashboardService,
        private readonly $timeout: ITimeoutService,
        private readonly modalOpener: ModalOpener,
        private readonly tagService: TagService,
        private readonly sfaaCodeService: SfaaCodeService,
        private readonly systemAccountService: SystemAccountService,
        private readonly debouceDelayer: IDebounceDelayer
    ) {
        this.table = new Table(debouceDelayer);
    }

    public widget: DashboardWidget;
    public busyIndicator: BusyIndicator;
    public table: Table<Bond, null>;
    public orderby: string;
    public filterCollection: ODataFilterCollection;
    public errorMessage: string;
    public sfaaCodeOptions: SelectOption<string>[];
    public tagOptions: SelectOption<string>[];
    public carrierOptions: SelectOption<number>[];

    public setFilters = () => {
        this.filterCollection = this.odata.getFilterCollection([
            this.odata.getFilter(
                "status",
                ODataFilterDataType.select,
                "Status",
                null,
                null,
                true,
                [
                    { label: "Active", value: "'Active'" },
                    {
                        label: "Pending Cancellation",
                        value: "'PendingCancellation'"
                    },
                    { label: "Cancelled", value: "'Cancelled'" },
                    {
                        label: "Pending Reinstatement",
                        value: "'PendingReinstatement'"
                    }
                ],
                false
            ),
            this.odata.getFilter(
                "bondAmount",
                ODataFilterDataType.money,
                "Bond Amount"
            ),
            this.odata.getFilter(
                "renewalStatus",
                ODataFilterDataType.select,
                "Renewal Application Status",
                null,
                null,
                true,
                [
                    {
                        label: "No Renewal Application",
                        value: "'None'"
                    },
                    {
                        label: "Renewal Application Submitted",
                        value: "'ApplicationSubmitted'"
                    }
                ],
                false
            ),
            this.odata.getFilter(
                "expirationDate",
                ODataFilterDataType.daysTill,
                "Expiration Date"
            ),
            this.odata.getFilter(
                "createdDateTime",
                ODataFilterDataType.date,
                "Purchase Date"
            ),
            this.odata.getFilter(
                "effectiveDate",
                ODataFilterDataType.date,
                "Effective Date"
            ),
            this.odata.getFilter(
                "expirationDate",
                ODataFilterDataType.date,
                "Expiration Date"
            ),
            this.odata.getFilter(
                "bondType/name",
                ODataFilterDataType.string,
                "Bond Type"
            ),
            this.odata.getFilter(
                "bondType/obligee/requisitioningState",
                ODataFilterDataType.state,
                "Requisitioning State"
            ),
            this.odata.getFilter(
                "quote/carrierSystemAccount/id",
                ODataFilterDataType.select,
                "Carrier",
                null,
                null,
                !this.currentUserResolver.getCurrentUser().systemAccount
                    .isCarrier,
                this.carrierOptions
            ),
            this.odata.getFilter(
                "bondType/sfaaCode/code",
                ODataFilterDataType.select,
                "SFAA Code",
                null,
                null,
                true,
                this.sfaaCodeOptions
            ),
            this.odata.getFilter(
                "Tags/any(t:contains(t/name,'{0}'))",
                ODataFilterDataType.select,
                "Tagged ",
                null,
                null,
                true,
                this.tagOptions,
                true,
                "with "
            ),
            this.odata.getFilter(
                "customer/eProducerAccount/name",
                ODataFilterDataType.string,
                "Broker Name",
                null,
                null,
                !this.currentUserResolver.getCurrentUser().systemAccount
                    .isCarrier
            ),
            this.odata.getFilter(
                "customer/eProducerAccount/agencyCode",
                ODataFilterDataType.string,
                "Broker Agency Code",
                null,
                null,
                !this.currentUserResolver.getCurrentUser().systemAccount
                    .isCarrier
            ),
            this.odata.getFilter(
                "customer/eProducerAccount/agencySubCode",
                ODataFilterDataType.string,
                "Broker Sub Code",
                null,
                null,
                !this.currentUserResolver.getCurrentUser().systemAccount
                    .isCarrier
            )
        ]);
    };

    public saveRecordsPerPage = () => {
        this.widget.options.recordsPerPage = this.table.pager.recordsPerPage;
        this.dashboardService.save();
    };

    public saveOrderby = () => {
        this.widget.options.orderby = this.table.sorter.sortBy;
        this.dashboardService.save();
    };

    public loadSfaaCodes = () => {
        return this.sfaaCodeService.getAllSfaaCodeOptions().then((options) => {
            this.sfaaCodeOptions = options;
        });
    };

    public loadTagOptions = () => {
        return this.tagService.loadTagOptions().then((tagOptions) => {
            this.tagOptions = tagOptions;
        });
    };

    public loadCarriers = () => {
        return this.systemAccountService
            .getAppointedCarrierOptions()
            .then((options) => {
                this.carrierOptions = options;
            });
    };

    public buildQuery = () => {
        const select = "id,customerId,quoteId,nameOnBond,cancellationDate,reinstatementDate,bondNumber,status,cancellationStatus,riderStatus,reinstatementStatus,bondAmount,effectiveDate,expirationDate,createdDateTime";
        const applicationExpand = "id,bondAmount";
        const bondTypeExpand = "id,name";
        const carrierExpand = "id,companyName";

        const query = this.odata
            .getQuery()
            .orderby(this.table.sorter.sortBy)
            .skip((this.table.pager.currentPage - 1) * this.table.pager.recordsPerPage)
            .top(this.table.pager.recordsPerPage)
            .expand("quote($expand=application($select=" + applicationExpand + "),carrierSystemAccount($select=" + carrierExpand + ");$select=application,carrierSystemAccount),bondType($select=" + bondTypeExpand + "),bondTransactions($filter=transactionType eq 'NewBusiness')")
            .select(select);

        const filterExpr = this.filterCollection.getFilterExpression();
        if (filterExpr) {
            query.filter(filterExpr);
        }
        return query;
    };

    public loadGrid = () => {
        if (this.table.sorter.sortBy !== this.widget.options.orderby) {
            this.saveOrderby();
        }

        if (
            this.table.pager.recordsPerPage !=
            this.widget.options.recordsPerPage
        ) {
            this.saveRecordsPerPage();
        }

        const query = this.buildQuery();

        const dataSvc = this.odata.getService(ODataEndpoint.Bond);

        this.busyIndicator.promise = dataSvc
            .get<A3ApiResponse<Bond[]>>(query)
            .then((response) => {
                this.table.setData(
                    response.data.value,
                    response.data["@odata.count"]
                );
            })
            .catch(() => {
                this.errorMessage =
                    "An error occurred trying to get the widget data";
            });
    };

    public showShippingModal = (bondTransaction) => {
        this.modalOpener
            .showShippingModal(bondTransaction)
            .result.then(() => {})
            .catch(() => {});
    };

    public $onInit = () => {
        this.busyIndicator = {
            message: "Loading..."
        };

        this.sfaaCodeOptions = [];
        this.carrierOptions = [];
        this.tagOptions = [];

        this.loadTagOptions();
        this.setFilters();
        this.loadSfaaCodes();
        this.loadCarriers();

        if (!isDefined(this.widget.options.title)) {
            this.widget.options.title = "Bond List Agent";
        }

        if (isDefined(this.widget.options.filters)) {
            this.filterCollection.mergeFilters(this.widget.options.filters);
        }

        if (isDefined(this.widget.options.orderby)) {
            this.table.sorter.sort(this.widget.options.orderby);
        }

        if (isDefined(this.widget.options.recordsPerPage)) {
            this.table.pager.setRecordPerPage({
                value: this.widget.options.recordsPerPage
            });
        }
        this.setFilters();

        this.$timeout(() => {
            this.loadGrid();
        });

        this.table.onChange = this.loadGrid;

        this.dashboardService.refreshFunctions.push(this.loadGrid);
    };
}

const dashboardWidgetBondsAgentComponent = {
    templateUrl:
        "app/states/common/dashboard/widgets/bondListAgent/dashboardWidgetBondsAgent.html",
    bindings: {
        widget: "="
    },
    controllerAs: "vm",
    controller: DashboardWidgetBondsAgentController
};

app.component("dashboardWidgetBondsAgent", dashboardWidgetBondsAgentComponent);