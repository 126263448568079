import { BrowserNotification } from "../browserNotification";
import { DocumentVersion } from "../documentVersion";
import { BondNumberGroup } from "./bondNumberGroup";
import { CancellationReason } from "./cancellationReason";
import { CarrierAppointment } from "./carrierAppointment";
import { Customer } from "./customer";
import { EProducerAccount } from "./eProducerAccount";
import { IBaseEntity } from "./iBaseEntity";
import { Memo } from "./memo";
import { Rate } from "./rate";
import { ReinstatementReason } from "./reinstatementReason";
import { RequiredFieldSet } from "./requiredFieldSet";
import { RiderReason } from "./riderReason";
import { SystemAccountBondTypeFavorite } from "./systemAccountBondTypeFavorite";
import { SystemAccountContact } from "./systemAccountContact";
import { SystemAccountDocument } from "./systemAccountDocument";
import { SystemAccountStatement } from "./systemAccountStatement";
import { UnderwritingConfiguration } from "./underwritingConfiguration";
import { User } from "./user";
import { UserGroup } from "./userGroup";
import { WritingCompany } from "./writingCompany";

export interface SystemAccount extends IBaseEntity { 
    accountType: SystemAccountType;
    allowACHPaymentsThroughA3: boolean;
    allowACHPaymentsThroughEProducer: boolean;
    allowACHPaymentsThroughPaymentForm: boolean;
    allowCCPaymentsThroughA3: boolean;
    allowCCPaymentsThroughEProducer: boolean;
    allowCCPaymentsThroughPaymentForm: boolean;
    allowOtherPaymentsThroughA3: boolean;
    ams360AccountExecCode: string;
    ams360AccountRepCode: string;
    ams360AgencyId: string;
    ams360BranchCode: string;
    ams360DepartmentCode: string;
    ams360DivisionCode: string;
    ams360GroupCode: string;
    ams360IntegrationIsActive: boolean;
    ams360LineOfBusinessCode: string;
    appointedAgencies: CarrierAppointment[];
    analyticsTrackingId: string;
    analyticsIntegrationIsActive: boolean;
    adWordsIntegrationIsActive: boolean;
    adWordsPurchaseBondConversionId: string;
    adWordsPurchaseBondConversionLabel: string;
    adWordsSubmitApplicationConversionId: string;
    adWordsSubmitApplicationConversionLabel: string;
    adWordsVisitApplicationConversionId: string;
    adWordsVisitApplicationConversionLabel: string;
    balance: number;
    billingContact: string;
    billingEmail: string;
    billingPhone: string;
    bondNumberGroups: BondNumberGroup[];
    bondNumberIntegrationType: BondNumberIntegrationType;
    cancellationReasons: CancellationReason[];
    canCreateNonPublicDocuments: boolean;
    carrierAppointments: CarrierAppointment[];
    claimsEmail: string;
    claimsFax: string;
    claimsMailAddress: string;
    claimsMailCity: string;
    claimsMailState: string;
    claimsMailSuiteAptNumber: string;
    claimsMailZip: string;
    claimsPhone: string;
    companyName: string;
    creditCardProcessor: CreditCardProcessor;
    achProcessor: AchProcessor;
    creditReportFee: number;
    customers: Customer[];
    defaultAttorneyInFactUser: User;
    defaultAttorneyInFactUserId: number;
    description: string;
    systemAccountDocuments: SystemAccountDocument[];
    documentVersions: DocumentVersion[];
    email: string;
    memos: Memo[];
    eProducerAccounts: EProducerAccount[];
    eProducerGoogleAnalyticsCode: string;
    eProducerPortalURL: string;
    expeditedProcessingFee: number;
    fein: string;
    indemnityLanguage: string;
    isSponsored: boolean;
    largeLogo: File;
    largeLogoFileId: number;
    legalName: string;
    mailAddress: string;
    mailCity: string;
    mailCounty: string;
    mailState: string;
    mailSuiteAptNumber: string;
    mailZip: string;
    multipleQuoteDisplayRule: MultipleQuoteDisplayRule;
    newBusinessReasonCode: string;
    newBusinessReasonDescription: string;
    noBondTypeRedirectUrl: string;
    phone: string;
    physicalAddress: string;
    physicalCity: string;
    physicalCounty: string;
    physicalState: string;
    physicalSuiteAptNumber: string;
    physicalZip: string;
    paymentMailAddress: string;
    paymentMailCity: string;
    paymentMailCounty: string;
    paymentMailState: string;
    paymentMailSuiteAptNumber: string;
    paymentMailZip: string;
    paymentMadeOutToName: string;
    paymentEmail: string;
    paymentPhone: string;
    quoteSequence: number;
    rates: Rate[];
    reinstatementReasons: ReinstatementReason[];
    renewalReasonCode: string;
    renewalReasonDescription: string;
    requiredFieldSets: RequiredFieldSet[];
    riderReasons: RiderReason[];
    stateOfIncorporation: string;
    systemAccountBondTypeFavorites: SystemAccountBondTypeFavorite[];
    systemAccountContacts: SystemAccountContact[];
    systemAccountStatements: SystemAccountStatement[];
    thumbnailLogo: File;
    thumbnailLogoFileId: number;
    transactionFeePercent: number;
    underwritingConfigurations: UnderwritingConfiguration[];
    userGroups: UserGroup[];
    users: User[];
    notifications: BrowserNotification[];
    websiteURL: string;
    writingCompanies: WritingCompany[];
    secretProperties: SystemAccountSecretProperties;
    paymentPageUrl: string;
    paymentInstructions1: string;
    paymentInstructions2: string;
    id: number;
    createdDateTime: Date;
}
export interface SystemAccountSecretProperties extends IBaseEntity { 
    ams360Url: string;
    ams360WebServicePassword: string;
    ams360WebServiceUsername: string;
    authorizeNetLogin: string;
    authorizeNetTransactionId: string;
    checkGatewayPassword: string;
    checkGatewayUsername: string;
    xpressPayBillTypePk: string;
    eProcessingNetworkAccountNumber: string;
    eProcessingNetworkRestrictKey: string;
    vantivUsername: string;
    vantivPassword: string;
    vantivMerchantId: string;
    vantivAchUsername: string;
    vantivAchPassword: string;
    vantivAchMerchantId: string;
}

export enum SystemAccountType {	
    Agency = "Agency",
    Carrier = "Carrier",
    MGA = "MGA"
}
export enum CreditCardProcessor {	
    AuthorizeNet = "AuthorizeNet",
    WorldPay = "WorldPay",
    EProcessingNetwork = "EProcessingNetwork",
    Vantiv = "Vantiv"
}

export enum AchProcessor {
    CheckCommerce = 'CheckCommerce',
    Vantiv = 'Vantiv'
}

export enum BondNumberIntegrationType {	
    Westfield = "Westfield"
}
export enum MultipleQuoteDisplayRule {	
    WritingCompanyWithHighestPriorityThenCheapest = "WritingCompanyWithHighestPriorityThenCheapest",
    WritingCompanyWithHighestPriorityThenMostExpensive = "WritingCompanyWithHighestPriorityThenMostExpensive",
    Cheapest = "Cheapest",
    MostExpensive = "MostExpensive",
    ShowAll = "ShowAll"
}