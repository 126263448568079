import { SystemAccountService } from "../../api/systemAccountService";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";

class SystemAccountLogoController {

    public static $inject = [
        Injectables.SystemAccountService
    ];

    constructor(
        private readonly systemAccountService: SystemAccountService
    ) {
    }

    public systemAccountId: number;
    public companyName: string;
    public writingCompanyName: string;
    public showLabels: string;
    public url: string;

    public setLogoUrl() {
        this.systemAccountService.getSmallLogo(this.systemAccountId)
            .then((dataUrl: string) => {
                this.url = dataUrl;
            });
    }

    public $onInit(): void {
        this.setLogoUrl();
    }
}

const component = {
    templateUrl: 'app/components/systemAccountLogo/systemAccountLogo.html',
    bindings: { 
        systemAccountId: '<', 
        companyName: '<?',
        writingCompanyName: '<?',
        showLabels: '@?'
    },
    controller: SystemAccountLogoController,
    controllerAs: 'vm'
};

app.component('systemAccountLogo', component);
