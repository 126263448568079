import app from "../../main";

export const wordsFilter = () => {
    return (input: string, words: number) => {
        
        if (isNaN(words)) {
            return input;
        }

        if (words <= 0) {
            return '';
        } 

        if (input) {
            var inputWords = input.split(/\s+/);
            if (inputWords.length > words) {
                input = inputWords.slice(0, words).join(' ') + '…';
            }
        }

        return input;
    };
};

app.filter('words', wordsFilter);