export interface CollectionsDisplay {
    dateReported: string;
    assignedDate: string;
    customerNumber: string;
    courtName: string;
    industry: string;
    clientNameNumber: string;
    accountNumber: string;
    originalAmount: string;
    balanceAmount: string;
    balanceDate: string;
    dateOfLastPayment: string;
    statusDate: string;
    status: string;
    dateOfFirstDelinquency: string;
    accountDesignator: string;
    creditorClassification: string;
    updateIndicator: string;
    narratives?: string[];
}