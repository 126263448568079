import { IHttpService, IPromise } from "angular";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/settings/systemSettings";
import app from "../main";
import { ModalExportFormDownloadOptions } from "../modals/exportFormsModal/exportFormsModalOptions";

export class DocumentLibraryService {

    public static $inject = [
        Injectables.$http,
        Injectables.SystemSettings
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly systemSettings: SystemSettings) {
    }

    public downloadBatch(options: ModalExportFormDownloadOptions): IPromise<ArrayBuffer> {
        return this.$http
            .post<ArrayBuffer>(this.systemSettings.apiBaseUrl + 'DocumentLibrary/DownloadBatch', options, { responseType: 'arraybuffer' })
            .then((response) => response.data);
    }
}

app.service(Injectables.DocumentLibraryService, DocumentLibraryService);
