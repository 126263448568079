import { ODataFilter } from "../../api/odata";
import app from "../../main";

class FilterTagsController {

    public filters: any;
    public filterRemoved: (params: { filter: any }) => void;
    public allFiltersRemoved: () => void;

    public static $inject = [];

    constructor() { }

    public $onInit(): void { }

    public onFilterRemoved(filter: ODataFilter): void {
        if (typeof this.filterRemoved === 'function') {
            this.filterRemoved({ filter: filter });
        }
    }

    public onAllFiltersRemoved(): void {
        if (typeof this.allFiltersRemoved === 'function') {
            this.allFiltersRemoved();
        }
    }
}

const filterTagsComponent = {
    templateUrl: 'app/components/filterTags/filterTags.html',
    bindings: {
        filters: '=',
        filterRemoved: '&',
        allFiltersRemoved: '&'
    },
    controller: FilterTagsController,
    controllerAs: 'vm'
};

app.component('filterTags', filterTagsComponent);
