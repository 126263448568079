export interface QuoteSelectionFilters {
    term: string;
    noDocuments: boolean;
    noReview: boolean;
    noCredit: boolean;
    noCorporateIndemnity: boolean;
    noSpouseIndemnity: boolean;
    noIndemnity: boolean;
    getBestQuotes: boolean;
}
