import { SystemAccountService } from "../../api/systemAccountService";
import { PaymentMethod } from "../../api/types/model/paymentTransaction";
import { SelectOption } from "../../api/types/selectOption";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { PaymentReportItem } from "../../states/agentSpecific/payments/paymentReportItem";
import isUndefinedOrNull from "../../utilities/angularUtilities/isUndefinedOrNull";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";
import { Table } from "../../utilities/tables/table";
import { PaymentReportFilterOptions } from "./paymentReportFilterOptions";

class PaymentReportFilterDropdownController {
    public static $inject = [
        Injectables.SystemAccountService,
        Injectables.CurrentUserResolver
    ];

    constructor(
        private readonly systemAccountService: SystemAccountService,
        private readonly currentUserResolver: CurrentUserResolver
    ) {
        this.modifiedFilters = {} as PaymentReportFilterOptions;
    }

    public isOpen: boolean;
    public filterCount = 0;
    public modifiedFilters: PaymentReportFilterOptions;

    public table: Table<PaymentReportItem, PaymentReportFilterOptions>;
    public paymentMethods: SelectOption<PaymentMethod>[];

    public clearFilters() {
        this.modifiedFilters.status = null;
        this.modifiedFilters.minimumAmount = null;
        this.modifiedFilters.maximumAmount = null;
        this.modifiedFilters.startDate = null;
        this.modifiedFilters.endDate = null;
        this.modifiedFilters.isNotFullyApplied = null;
        this.modifiedFilters.paymentMethod = null;

        this.table.applyFilters({ ...this.modifiedFilters });

        this.setFilterCount();
        this.isOpen = false;
    }

    public cancel() {
        this.isOpen = false;
        this.modifiedFilters = { ...this.table.queryOptions };
    }

    public applyFilters() {
        this.isOpen = false;
        this.table.applyFilters({ ...this.modifiedFilters });
        this.setFilterCount();
    }

    private setModifiedFilters = () => {
        this.modifiedFilters.status = this.table.queryOptions.status;
        this.modifiedFilters.minimumAmount =
            this.table.queryOptions.minimumAmount;
        this.modifiedFilters.maximumAmount =
            this.table.queryOptions.maximumAmount;
        this.modifiedFilters.startDate = this.table.queryOptions.startDate;
        this.modifiedFilters.endDate = this.table.queryOptions.endDate;
        this.modifiedFilters.isNotFullyApplied =
            this.table.queryOptions.isNotFullyApplied;
        this.modifiedFilters.paymentMethod =
            this.table.queryOptions.paymentMethod;

        this.setFilterCount();
    };

    public setFilterCount() {
        this.filterCount = 0;

        if (!isUndefinedOrNull(this.table.queryOptions.status)) {
            this.filterCount++;
        }

        if (
            this.table.queryOptions.minimumAmount ||
            this.table.queryOptions.maximumAmount
        ) {
            this.filterCount++;
        }

        if (
            this.table.queryOptions.startDate ||
            this.table.queryOptions.endDate
        ) {
            this.filterCount++;
        }

        if (this.table.queryOptions.isNotFullyApplied) {
            this.filterCount++;
        }

        if (this.table.queryOptions.paymentMethod) {
            this.filterCount++;
        }
    }

    public getPaymentMethods() {
        return this.systemAccountService
            .getPaymentMethods(
                this.currentUserResolver.getCurrentUser().user.systemAccountId
            )
            .then((paymentMethods) => {
                this.paymentMethods = [{ label: "All", value: null }];

                for (let i = 0; i < paymentMethods.length; i++) {
                    this.paymentMethods.push(paymentMethods[i]);
                }
            });
    }

    public $onInit() {
        this.getPaymentMethods();
        this.setModifiedFilters();
    }
}

const paymentReportFilterDropdownComponent = {
    bindings: {
        table: "<"
    },
    controller: PaymentReportFilterDropdownController,
    controllerAs: "vm",
    templateUrl:
        "app/components/paymentsReportFilterDropdown/paymentReportFilterDropdown.html"
};

app.component(
    "paymentReportFilterDropdown",
    paymentReportFilterDropdownComponent
);
