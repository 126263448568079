import { CompanyLicense } from "./companyLicense";
import { IBaseEntity } from "./iBaseEntity";
import { PeopleLicense } from "./peopleLicense";

export interface MasterLicense extends IBaseEntity { 
    name: string;
    gorverningBody: string;
    companyLicenses: CompanyLicense[];
    peopleLicenses: PeopleLicense[];
    id: number;
    createdDateTime: Date;
}