import * as moment from "moment";
import { ODataTimeFrameFilterType } from ".";

export default class ODataQuery {

    // tslint:disable-next-line:variable-name
    private _orderby: string;
    // tslint:disable-next-line:variable-name
    private _filter: string;
    // tslint:disable-next-line:variable-name
    private _expand: string;
    // tslint:disable-next-line:variable-name
    private _skip: number;
    // tslint:disable-next-line:variable-name
    private _top: number;
    // tslint:disable-next-line:variable-name
    private _select: string;
    // tslint:disable-next-line:variable-name
    private _additionalParameters: string;

    constructor() {
        this.init();
    }

    private init(): void {
        this._orderby = '';
        this._filter = '';
        this._expand = '';
        this._skip = 0;
        this._top = 0;
        this._select = '';
        this._additionalParameters = '';
    }

    public reset(): void {
        this.init();
    }

    public getOrderby(): string {
        return this._orderby;
    }

    public orderby(orderby: string): ODataQuery {
        this._orderby = orderby;
        return this;
    }

    public setAdditionalParameters(additionalParameters: string): string {
        this._additionalParameters = additionalParameters;
        return this._additionalParameters;
    }

    public getFilter(): string {
        return this._filter;
    }

    public filter(filter: string): ODataQuery {
        this._filter = filter;
        return this;
    }

    public filterById(id: number): ODataQuery {
        this._filter = `id eq ${id}`;
        return this;
    }

    public getTop(): number {
        return this._top;
    }

    public top(top: number): ODataQuery {
        this._top = top;
        return this;
    }

    public getSkip(): number {
        return this._skip;
    }

    public skip(skip: number): ODataQuery {
        this._skip = skip;
        return this;
    }

    public getExpand(): string {
        return this._expand;
    }

    public expand(expand: string): ODataQuery {
        this._expand = expand;
        return this;
    }

    public getSelect(): string {
        return this._select;
    }

    public select(select: string): ODataQuery {
        this._select = select;
        return this;
    }

    public filterYTD(property: string): ODataQuery {
        const year = moment().year();
        return this.filter(property + ' ge ' + year + '-01-01');
    }

    public filterLastYear(property: string): ODataQuery {
        const thisYear = moment().format('YYYY-01-01');
        const lastYear = moment().subtract(1, 'years').format('YYYY-01-01');

        return this.filter(`(${property} ge ${lastYear} and ${property} lt ${thisYear})`);
    }

    public filterMTD(property: string): ODataQuery {
        const now = moment();

        return this.filter(property + ' ge ' + now.format('YYYY-MM-01'));
    }

    public filterWTD(property: string): ODataQuery {
        const now = moment();
        const day = now.day();

        const sunday = day === 0 ? now.format('YYYY-MM-DD') : now.subtract(day, 'days').format('YYYY-MM-DD');

        return this.filter(property + ' ge ' + sunday);
    }

    public filterLastWeek(property: string): ODataQuery {
        const now = moment();
        const day = now.day();

        const sundayThisWeek = day === 0 ? now : moment().subtract(day, 'days');
        const sundayLastWeek = {...sundayThisWeek}.subtract(7, 'days');

        return this.filter(`(${property} ge ${sundayLastWeek.format('YYYY-MM-DD')} and ${property} lt ${sundayThisWeek.format('YYYY-MM-DD')})`);
    }

    public filterLast90Days(property: string): ODataQuery {
        const start = moment().subtract(90, 'days');

        return this.filter(property + ' ge ' + start.format('YYYY-MM-DD'));
    }

    public filterLast180ToLast90Days(property: string): ODataQuery {
        const start = moment().subtract(180, 'days');
        const end = moment().subtract(90, 'days');

        return this.filter(`(${property} ge ${start.format('YYYY-MM-DD')} and ${property} lt ${end.format('YYYY-MM-DD')})`);
    }

    public filterLastMonth(property: string): ODataQuery {
        const now = moment();
        const lastMonth = moment().subtract(1, 'months');

        return this.filter(`(${property} ge ${lastMonth.format('YYYY-MM-01')} and ${property} lt ${now.format('YYYY-MM-01')})`);
    }

    public filterTwoMonthsAgo(property: string): ODataQuery {
        const start = moment().subtract(1, 'months');
        const end = moment().subtract(2, 'months');

        return this.filter(`(${property} ge ${start.format('YYYY-MM-01')} and ${property} lt ${end.format('YYYY-MM-01')})`);
    }

    public filterByTimeFrame(aggregateType: ODataTimeFrameFilterType, property: string): void {
        switch (aggregateType) {
            case ODataTimeFrameFilterType.Last180ToLast90Days:
                this.filterLast180ToLast90Days(property);
                break;
            case ODataTimeFrameFilterType.Last90Days:
                this.filterLast180ToLast90Days(property);
                break;
            case ODataTimeFrameFilterType.LastMonth:
                this.filterLastMonth(property);
                break;
            case ODataTimeFrameFilterType.LastWeek:
                this.filterLastWeek(property);
                break;
            case ODataTimeFrameFilterType.LastYear:
                this.filterLastYear(property);
                break;
            case ODataTimeFrameFilterType.Mtd:
                this.filterMTD(property);
                break;
            case ODataTimeFrameFilterType.ThisWeek:
                this.filterWTD(property);
                break;
            case ODataTimeFrameFilterType.TwoMonthsAgo:
                this.filterTwoMonthsAgo(property);
                break;
            case ODataTimeFrameFilterType.Ytd:
                this.filterYTD(property);
                break;
            default:
        }
    }

    public and(filter: string): ODataQuery {
        if (this._filter && this._filter !== '') {
            this.filter(`(${this._filter}) and (${filter})`);
        } else {
            this.filter(filter);
        }

        return this;
    }

    public toString(): string {
        let url = '';

        if (this._filter) {
            url += `$filter=${this._filter}&`;
        }

        if (this._orderby) {
            url += `$orderby=${this._orderby}&`;
        }

        if (this._skip > 0) {
            url += `$skip=${this._skip}&`;
        }

        if (this._top > 0) {
            url += `$top=${this._top}&`;
        }

        if (this._expand) {
            url += `$expand=${this._expand}&`;
        }

        if (this._select) {
            url += `$select=${this._select}&`;
        }

        url += '$count=true&';

        if (this._additionalParameters) {
            url += this._additionalParameters + '&';
        }

        return url;
    }
}