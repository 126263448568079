import app from "../../main";
import { Injectables } from "../injectables";

const textAngularConfig = ($provide) => {

    // $provide.decorator('taOptions', ['taRegisterTool', '$delegate', (taRegisterTool, taOptions) => {
    //     taOptions.toolbar = [
    //         ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'pre', 'quote'],
    //         ['bold', 'italics', 'underline', 'strikeThrough', 'ul', 'ol', 'redo', 'undo', 'clear'],
    //         ['justifyLeft', 'justifyCenter', 'justifyRight', 'indent', 'outdent'],
    //         ['html', 'insertImage', 'insertLink', 'insertVideo'] // removed 'wordcount', 'charcount'
    //     ];

    //     return taOptions;
    // }]);
}

textAngularConfig.$inject = [Injectables.$provide];

app.config(textAngularConfig);