import { DateTelephoneReported } from "./dateTelephoneReported";
import { TelephoneSource } from "./telephoneSource";
import { TelephoneType } from "./telephoneType";
import { VerificationDate } from "./verificationDate";

export interface USOtherTelephoneNumber { 
    telephoneNumber: string;
    telephoneType: TelephoneType;
    internationalCallingCode: string;
    dateTelephoneReported: DateTelephoneReported;
    telephoneSource: TelephoneSource;
    verificationDate: VerificationDate;
}