import * as moment from "moment";
import { PrincipalType } from "../../../api/types/model/application";
import { BondAmountTypes } from "../../../api/types/model/bondType";
import { Company } from "../../../api/types/model/company";
import { QuestionType } from "../../../api/types/model/masterApplicationQuestion";
import { SelectOption } from "../../../api/types/selectOption";
import { UnderwritingQuestions } from "../../../api/types/underwritingQuestions";
import { Injectables } from "../../../configuration/injectables";
import app from "../../../main";
import { UtilityService } from "../../../utilities/utilityService";
import { ApplicationController } from "./applicationController";


export interface TestApplicationPerson {
    firstName: string;
    middleName: string;
    lastName: string;
    suffix: string;
    ssn: string;
    dateOfBirth: string;
    physicalAddress: string;
    mailAddress: string;
    physicalCity: string;
    mailCity: string;
    physicalState: string;
    mailState: string;
    physicalZip: string;
    mailZip: string;
    homePhone: string;
    cellPhone: string;
    employerName: string;
    employerAddress: string;
    employerSuiteAptNumber: string;
    employerZip: string;
    employerState: string;
    employerCity: string;
    employerCounty: string;
    residenceType: string;
    dateMovedToResidence: string;
    maritalStatus: string;
    spouseFirstName: string;
    spouseMiddleName: string;
    spouseLastName: string;
    spouseGender: string;
    spouseDateOfBirth: string;
    spouseSsn: string;
    spouseEmail: string;
    spouseCellPhone: string;
    jobTitle: string;
    driversLicenseNumber: string;
    driversLicenseState: string;
    fax: string;
    email: string;
    prefix: string;
    gender: string;
    physicalCounty: string;
    mailCounty: string;
    yearsExperience: number;
}

export interface TestApplicationCompany {
    name: string;
    dba: string;
    companyType: string;
    physicalZip: string;
    physicalState: string;
    physicalCity: string;
    physicalCounty: string;
    physicalAddress: string;
    dateFormed: string;
    fein: string;
    mailAddress: string;
    mailZip: string;
    mailCity: string;
    mailState: string;
    mailCounty: string;
    mailSuiteAptNumber: string;
    physicalSuiteAptNumber: string;
    email: string;
    fax: string;
    phone: string;
}

export interface TestApplicationQuestionResponse {
    selectedValue: string;
    masterApplicationQuestionId: string;
    questionText: string;
    originType?: string;
}

export interface TestApplicationObligee {
    contact1: string;
    contact2: string;
    contact3: string;
    email: string;
    fax: string;
    mailAddress: string;
    mailCity: string;
    mailCounty: string;
    mailState: string;
    mailSuiteAptNumber: string;
    mailZip: string;
    name: string;
    phone: string;
    physicalAddress: string;
    physicalCity: string;
    physicalCounty: string;
    physicalState: string;
    physicalSuiteAptNumber: string;
    physicalZip: string;
    websiteURL: string;
}

export interface TestApplication {
    value: number;
    label: string;
    $ownerCount: string;
    people: TestApplicationPerson[];
    companies: TestApplicationCompany[];
    applicationQuestionResponses: TestApplicationQuestionResponse[];
    desiredEffectiveDate: string;
    submissionSignature: string;
    nameOnBond: string;
    obligee: TestApplicationObligee;
}

export class ApplicationTestDataCreator {

    public static $inject = [
        Injectables.UtilityService
    ];

    constructor(private readonly utilityService: UtilityService) {
    }

    public getTestValueForQuestion(question: UnderwritingQuestions): string {
        if (question.type ===  QuestionType.DropDown || question.type ===  QuestionType.Radio){
            for (const option of question.masterApplicationQuestionChoices){
                if (option.text !== question.showWhenParentValueEquals){
                    return option.text;
                }
            }
        } else {
            return question.sampleResponse ? question.sampleResponse : 'TESTDATA';
        }
    }

    public fillApplication(applicationController: ApplicationController): void {

        applicationController.application.principalType =  PrincipalType.Company;

        applicationController.testApplicant = this.getEquifaxTestApplicationById(applicationController.selectedTestApplicantId);
        applicationController.isCompanyPrincipalType = true;
        applicationController.application.$ownerCount = parseInt(applicationController.testApplicant.$ownerCount, 10);

        delete applicationController.application.people;
        delete applicationController.application.companies;

        applicationController.application.people = [];
        applicationController.application.companies = [];
        applicationController.application.companies.push({} as  Company);

        applicationController.application.desiredEffectiveDate = this.utilityService.momentRandom(moment().add('months', 1), moment()).toDate();
        applicationController.application.submissionSignature = applicationController.testApplicant.submissionSignature;

        for (const person of applicationController.testApplicant.people){
            const addedPerson = applicationController.application.addPerson();

            Object.keys(person).forEach ((key) => {
                if (key.startsWith('date') || key.endsWith('Date') || key.includes('Date')) {
                    addedPerson[key] = moment(person[key]).toDate();
                } else {
                    addedPerson[key] = person[key];
                }
            });
        }

        const thisCompany = applicationController.application.companies[0];
        const testCompany = applicationController.testApplicant.companies[0];

        Object.keys(testCompany).forEach((key) => {
            if (key.startsWith('date') || key.endsWith('Date') || key.includes('Date')) {
                thisCompany[key] = moment(testCompany[key]).toDate();
            } else {
                thisCompany[key] = testCompany[key];
            }
        });

        for (const question of applicationController.formOptions.underwritingQuestions){
            question.selectedValue = this.getTestValueForQuestion(question);
            for (const subQuestion of question.subQuestions) {
                subQuestion.selectedValue = this.getTestValueForQuestion(subQuestion);
            }
        }

        applicationController.setNameOnBondOptions(null, 'name');
        applicationController.selectedNameOnBond = applicationController.testApplicant.nameOnBond;
        applicationController.application.nameOnBond = applicationController.testApplicant.nameOnBond;

        if (applicationController.bondType.bondAmountType ===  BondAmountTypes.Fixed) {
            applicationController.application.bondAmount = applicationController.application.bondAmount;
        } else if (applicationController.bondType.bondAmountType ===  BondAmountTypes.Variable) {
            applicationController.application.bondAmount = applicationController.bondTypeVariableBondAmounts[0].value;
        } else if (applicationController.bondType.bondAmountType ===  BondAmountTypes.UserDefined) {
            applicationController.application.bondAmount = applicationController.bondType.minimumBondAmount;
        }

        if (applicationController.application.obligee.isGeneric) {
            applicationController.application.obligee = {
                ...applicationController.testApplicant.obligee,
                    masterObligeeId: applicationController.application.obligee.id
            } as any;
        }
    }

    public getEquifaxTestApplicationById(id: number): TestApplication {
        for (const app of this.equifaxApplicants) {
            if (app.value === id ) {
                return app;
            }
        }
    }

    get equifaxApplicantOptions(): SelectOption<number>[] {
        return this.equifaxApplicants.map((applicant) => {
            return { value: applicant.value, label: applicant.label };
        });
    }

    get equifaxApplicants(): TestApplication[] {
        return [
            {
                value: 1,
                label: '701 - WAYNE E FGPNKK',
                $ownerCount: '2',
                people: [
                    {
                        firstName: 'WAYNE',
                        middleName: 'E',
                        lastName: 'FGPNKK',
                        suffix: '',
                        ssn: '666-96-8390',
                        dateOfBirth: '9/17/1978',
                        physicalAddress: '2008 AMBER LEAF PL APT 39',
                        mailAddress: '2008 AMBER LEAF PL APT 39',
                        physicalCity: 'WALDORF',
                        mailCity: 'WALDORF',
                        physicalState: 'MD',
                        mailState: 'MD',
                        physicalZip: '20602',
                        mailZip: '20602',
                        homePhone: '(999) 888-2724',
                        cellPhone: '(897) 789-4323',
                        employerName: 'Curae; Phasellus Ornare LLC',
                        employerAddress: 'Ap #756-7013 Nulla Rd.',
                        employerSuiteAptNumber: '2335 Eu, Rd.',
                        employerZip: '91355',
                        employerState: 'DE',
                        employerCity: 'Bear',
                        physicalCounty: 'Bear',
                        mailCounty: 'Bear',
                        employerCounty: 'Newark',
                        residenceType: 'Own',
                        dateMovedToResidence: '04/26/35',
                        maritalStatus: 'Married',
                        spouseFirstName: 'Colleen',
                        spouseMiddleName: 'September',
                        spouseLastName: 'Jones',
                        spouseGender: 'M',
                        spouseDateOfBirth: '09/12/1979',
                        spouseSsn: '208-11-7583',
                        spouseEmail: 'lectus.convallis.est@dolor.org',
                        spouseCellPhone: '(751) 776-2179',
                        jobTitle: 'Fusce',
                        driversLicenseNumber: '591649918',
                        driversLicenseState: 'NV',
                        fax: '(469) 993-5765',
                        email: 'eget@adipiscingnonluctus.co.uk',
                        prefix: 'Dr.',
                        gender: 'M',
                        yearsExperience: 4

                    },
                    {
                        firstName: 'LEROY',
                        middleName: 'F',
                        lastName: 'YZHKKW',
                        suffix: '',
                        ssn: '666-44-7488',
                        dateOfBirth: '01/12/1947',
                        physicalAddress: '6558 ROSWELL RD NE APT 7G',
                        mailAddress: '6558 ROSWELL RD NE APT 7G',
                        physicalCity: 'ATLANTA',
                        mailCity: 'ATLANTA',
                        mailCounty: 'ATLANTA',
                        physicalCounty: 'ATLANTA',
                        physicalState: 'GA',
                        mailState: 'GA',
                        physicalZip: '30328',
                        mailZip: '30328',
                        cellPhone: '(404) 555-1212',
                        homePhone: '(404) 555-1212',
                        employerName: 'Proin Nisl Sem Institute',
                        employerAddress: '993-4450 Eget St.',
                        employerSuiteAptNumber: 'P.O. Box 696, 4966 Eros Avenue',
                        employerZip: '31048',
                        employerState: 'TN',
                        employerCity: 'Chattanooga',
                        employerCounty: 'Memphis',
                        residenceType: 'Own',
                        dateMovedToResidence: '09/15/31',
                        maritalStatus: 'Married',
                        spouseFirstName: 'Irene',
                        spouseMiddleName: 'Aubrey',
                        spouseLastName: 'Donaldson',
                        spouseGender: 'F',
                        spouseDateOfBirth: '05/31/1970',
                        spouseSsn: '183-08-6346',
                        spouseEmail: 'vulputate.dui@tempus.co.uk',
                        spouseCellPhone: '(157) 222-7539',
                        jobTitle: 'id,',
                        driversLicenseNumber: '159735869',
                        driversLicenseState: 'IN',
                        fax: '(667) 377-0989',
                        email: 'velit.Pellentesque.ultricies@liberoProin.org',
                        prefix: '',
                        gender: 'F',
                        yearsExperience: 4

                    }
                ],
                companies: [
                    {
                        name: 'Risus In Limited',
                        dba: 'Parturient Company',
                        companyType: 'Proprietorship',
                        physicalZip: '93207',
                        physicalState: 'CA',
                        physicalCity: 'SanDiego',
                        physicalCounty: 'SanFrancisco',
                        physicalAddress: '2289DisSt.',
                        dateFormed: '04/28/56',
                        fein: '75-6210',
                        mailAddress: '5007Elit.Street',
                        mailZip: '94591',
                        mailCity: 'SanJose',
                        mailState: 'PA',
                        mailCounty: 'SanDiego',
                        mailSuiteAptNumber: 'P.O.Box557',
                        physicalSuiteAptNumber: 'P.O.Box381',
                        email: 'tortor.dictum@doloregestasrhoncus.ca',
                        fax: '(226)211-8734',
                        phone: '(712)708-3649'
                    }
                ],
                applicationQuestionResponses: [
                    {
                        selectedValue: 'F319F2WM45X13P9',
                        masterApplicationQuestionId: '3',
                        questionText: 'What is your state license number?'
                    },
                    {
                        selectedValue: 'Yes',
                        masterApplicationQuestionId: '1',
                        questionText: 'Are you trustworthy?',
                        originType: 'Underwriting'
                    }
                ],
                obligee: {
                    contact1: 'John Krazinski',
                    contact2: 'Emily Redden',
                    contact3: 'Tyler Smith',
                    email: 'tortor.dictum@doloregestasrhoncus.ca',
                    fax: '(561) 372-0098',
                    mailAddress: '123 Oak Street',
                    mailCity: 'Joplin',
                    mailCounty: 'Jasper',
                    mailState: 'MO',
                    mailSuiteAptNumber: 'Suite 12',
                    mailZip: '64801',
                    name: 'Lotor Esrat Obligee',
                    phone: '(888) 811-8290',
                    physicalAddress: '123 Oak Street',
                    physicalCity: 'Joplin',
                    physicalCounty: 'Jasper',
                    physicalState: 'MO',
                    physicalSuiteAptNumber: 'Suite 12',
                    physicalZip: '64801',
                    websiteURL: 'doloregestasrhoncus.ca'
                },
                desiredEffectiveDate: '7/5/2018',
                submissionSignature: 'AutomatedTester',
                nameOnBond: 'WAYNE E FGPNKK'
            },
            {
                value: 2,
                label: '529 - SHIRLETTE RENEE MLNNXZ',
                $ownerCount: '1',
                people: [
                    {
                        firstName: 'SHIRLETTE',
                        middleName: 'RENEE',
                        lastName: 'MLNNXZ',
                        suffix: '',
                        ssn: '666-92-5010',
                        dateOfBirth: '11/15/1967',
                        physicalAddress: '4060 PARK FULTON OVAL APT 133',
                        mailAddress: '4060 PARK FULTON OVAL APT 133',
                        physicalCity: 'CLEVELAND',
                        mailCity: 'CLEVELAND',
                        physicalState: 'OH',
                        mailState: 'OH',
                        physicalZip: '44144',
                        mailZip: '44144',
                        homePhone: '(216) 555-1212',
                        cellPhone: '(216) 555-1212',
                        employerName: 'Amet Massa Quisque Corporation',
                        employerAddress: '9401 Erat. Av.',
                        employerSuiteAptNumber: '5062 Malesuada Avenue',
                        employerZip: '20331',
                        employerState: 'IN',
                        employerCity: 'South Bend',
                        employerCounty: 'Fort Wayne',
                        mailCounty: 'ATLANTA',
                        physicalCounty: 'ATLANTA',
                        yearsExperience: 4,

                        residenceType: 'Own',
                        dateMovedToResidence: '08/21/46',
                        maritalStatus: 'Married',
                        spouseFirstName: 'Emerson',
                        spouseMiddleName: 'Cole',
                        spouseLastName: 'Shields',
                        spouseGender: 'M',
                        spouseDateOfBirth: '07/18/1984',
                        spouseSsn: '878-02-1108',
                        spouseEmail: 'ut.lacus.Nulla@sedliberoProin.co.uk',
                        spouseCellPhone: '(639) 263-8854',
                        jobTitle: 'malesuada',
                        driversLicenseNumber: '614079174',
                        driversLicenseState: 'OH',
                        fax: '(358) 219-0146',
                        email: 'amet@neccursus.org',
                        prefix: '',
                        gender: 'F'

                    }
                ],
                companies: [
                    {
                        name: 'Augue Consulting',
                        dba: 'Nec Enim Nunc Limited',
                        companyType: 'SCorp',
                        physicalZip: '44833',
                        physicalState: 'GA',
                        physicalCity: 'Athens',
                        physicalCounty: 'Georgia',
                        physicalAddress: 'Ap #217-1935 Scelerisque, St.',
                        dateFormed: '05/29/91',
                        fein: '10-0174',
                        mailAddress: '5584 Nec St.',
                        mailZip: '35525',
                        mailCity: 'Augusta',
                        mailState: 'GA',
                        mailCounty: 'Atlanta',
                        mailSuiteAptNumber: '5608 Hymenaeos. Avenue',
                        physicalSuiteAptNumber: 'Ap #171-9102 Tellus. Rd.',
                        email: 'sit.amet@pellentesquea.net',
                        fax: '(711) 675-4987',
                        phone: '(331) 646-1662'
                    }
                ],
                applicationQuestionResponses: [
                    {
                        selectedValue: 'N687Q8ZF82P31W1',
                        masterApplicationQuestionId: '3',
                        questionText: 'What is your state license number?'
                    },
                    {
                        selectedValue: 'Yes',
                        masterApplicationQuestionId: '1',
                        questionText: 'Are you trust worthy?',
                        originType: 'Underwriting'
                    }
                ],
                obligee: {
                    contact1: 'John Krazinski',
                    contact2: 'Emily Redden',
                    contact3: 'Tyler Smith',
                    email: 'tortor.dictum@doloregestasrhoncus.ca',
                    fax: '(561) 372-0098',
                    mailAddress: '123 Oak Street',
                    mailCity: 'Joplin',
                    mailCounty: 'Jasper',
                    mailState: 'MO',
                    mailSuiteAptNumber: 'Suite 12',
                    mailZip: '64801',
                    name: 'Lotor Esrat Obligee',
                    phone: '(888) 811-8290',
                    physicalAddress: '123 Oak Street',
                    physicalCity: 'Joplin',
                    physicalCounty: 'Jasper',
                    physicalState: 'MO',
                    physicalSuiteAptNumber: 'Suite 12',
                    physicalZip: '64801',
                    websiteURL: 'doloregestasrhoncus.ca'
                },
                desiredEffectiveDate: '7/21/2018',
                submissionSignature: 'Automated Tester',
                nameOnBond: 'SHIRLETTE RENEE MLNNXZ'
            },
            {
                value: 3,
                label: '505 - BRIAN KEITH WRZKLNFLNN',
                $ownerCount: '2',
                people: [
                    {
                        firstName: 'BRIAN KEITH',
                        middleName: 'KEITH',
                        lastName: 'WRZKLNFLNN',
                        suffix: 'JR',
                        ssn: '666-98-6782',
                        dateOfBirth: '05/10/1994',
                        physicalAddress: '707 WEHRING LN',
                        mailAddress: '707 WEHRING LN',
                        physicalCity: 'PHENIX CITY',
                        mailCity: 'PHENIX CITY',
                        physicalState: 'AL',
                        mailState: 'AL',
                        physicalZip: '36869',
                        mailZip: '36869',
                        homePhone: '(110) 789-5678',
                        cellPhone: '(110) 789-4378',
                        employerName: 'Nascetur Associates',
                        employerAddress: 'P.O. Box 145, 9322 Fermentum Av.',
                        employerSuiteAptNumber: 'P.O. Box 211, 4973 Iaculis Av.',
                        mailCounty: 'ATLANTA',
                        physicalCounty: 'ATLANTA',
                        yearsExperience: 4,

                        employerZip: '71483',
                        employerState: 'PA',
                        employerCity: 'Erie',
                        employerCounty: 'Allentown',
                        residenceType: 'Rent',
                        dateMovedToResidence: '09/13/74',
                        maritalStatus: 'Married',
                        spouseFirstName: 'Wyoming',
                        spouseMiddleName: 'Kyle',
                        spouseLastName: 'Mcclure',
                        spouseGender: 'M',
                        spouseDateOfBirth: '09/04/1974',
                        spouseSsn: '285-95-5159',
                        spouseEmail: 'natoque@etnetuset.org',
                        spouseCellPhone: '(315) 980-6811',
                        jobTitle: 'scelerisque',
                        driversLicenseNumber: '258673453',
                        driversLicenseState: 'NE',
                        fax: '(744) 234-2907',
                        email: 'enim.Etiam.imperdiet@volutpat.ca',
                        prefix: 'Dr.',
                        gender: 'F'

                    },
                    {
                        firstName: 'DANNY',
                        middleName: 'J',
                        lastName: 'UGTXNN',
                        suffix: '',
                        ssn: '666-41-5405',
                        dateOfBirth: '7/1/1989',
                        physicalAddress: '116 BURNETT RD APT F',
                        mailAddress: '116 BURNETT RD APT F',
                        physicalCity: 'BRUNSWICK',
                        mailCity: 'BRUNSWICK',
                        physicalState: 'GA',
                        mailState: 'GA',
                        physicalZip: '31523',
                        mailZip: '31523',
                        homePhone: '(471) 889-4783',
                        cellPhone: '(471) 889-4783',
                        employerName: 'Commodo LLC',
                        employerAddress: '420-4645 Sed, Avenue',
                        employerSuiteAptNumber: '389 Eu, St.',
                        mailCounty: 'ATLANTA',
                        physicalCounty: 'ATLANTA',
                        yearsExperience: 4,

                        employerZip: '57769',
                        employerState: 'OR',
                        employerCity: 'Eugene',
                        employerCounty: 'Salem',
                        residenceType: 'Rent',
                        dateMovedToResidence: '03/09/48',
                        maritalStatus: 'Married',
                        spouseFirstName: 'Kibo',
                        spouseMiddleName: 'Tanek',
                        spouseLastName: 'Woodard',
                        spouseGender: 'M',
                        spouseDateOfBirth: '05/06/1946',
                        spouseSsn: '603-50-4071',
                        spouseEmail: 'euismod@penatibuset.ca',
                        spouseCellPhone: '(738) 418-7312',
                        jobTitle: 'dolor.',
                        driversLicenseNumber: '427959960',
                        driversLicenseState: 'PA',
                        fax: '(608) 515-8998',
                        email: 'pretium@auctor.co.uk',
                        prefix: 'Mrs.',
                        gender: 'F'

                    }
                ],
                companies: [
                    {
                        name: 'Varius Ultrices LLC',
                        dba: 'Quisque Incorporated',
                        companyType: 'Partnership',
                        physicalZip: '43700',
                        physicalState: 'MN',
                        physicalCity: 'Bloomington',
                        physicalCounty: 'Rochester',
                        physicalAddress: 'P.O. Box 289, 7375 Dui St.',
                        dateFormed: '04/03/56',
                        fein: '65-1940',
                        mailAddress: '2306 Mattis Street',
                        mailZip: '85066',
                        mailCity: 'Minneapolis',
                        mailState: 'WI',
                        mailCounty: 'Minneapolis',
                        mailSuiteAptNumber: 'Ap #632-849 Praesent St.',
                        physicalSuiteAptNumber: '113 Morbi St.',
                        email: 'ornare.lectus@Pellentesquetincidunttempus.ca',
                        fax: '(771) 057-3845',
                        phone: '(605) 378-3286'
                    }
                ],
                applicationQuestionResponses: [
                    {
                        selectedValue: 'U741M3PQ35A19S1',
                        masterApplicationQuestionId: '3',
                        questionText: 'What is your state license number?'
                    },
                    {
                        selectedValue: 'Yes',
                        masterApplicationQuestionId: '1',
                        questionText: 'Are you trust worthy?',
                        originType: 'Underwriting'
                    }
                ],
                obligee: {
                    contact1: 'John Krazinski',
                    contact2: 'Emily Redden',
                    contact3: 'Tyler Smith',
                    email: 'tortor.dictum@doloregestasrhoncus.ca',
                    fax: '(561) 372-0098',
                    mailAddress: '123 Oak Street',
                    mailCity: 'Joplin',
                    mailCounty: 'Jasper',
                    mailState: 'MO',
                    mailSuiteAptNumber: 'Suite 12',
                    mailZip: '64801',
                    name: 'Lotor Esrat Obligee',
                    phone: '(888) 811-8290',
                    physicalAddress: '123 Oak Street',
                    physicalCity: 'Joplin',
                    physicalCounty: 'Jasper',
                    physicalState: 'MO',
                    physicalSuiteAptNumber: 'Suite 12',
                    physicalZip: '64801',
                    websiteURL: 'doloregestasrhoncus.ca'
                },
                desiredEffectiveDate: '7/20/2018',
                submissionSignature: 'Automated Tester',
                nameOnBond: 'BRIAN KEITH KEITH WRZKLNFLNN'
            },
            {
                value: 4,
                label: '520 - ROBERT C MDSXX',
                $ownerCount: '2',
                people: [
                    {
                        firstName: 'ROBERT',
                        middleName: 'C',
                        lastName: 'MDSXX',
                        suffix: '',
                        ssn: '666-15-3225',
                        dateOfBirth: '09/12/1969',
                        physicalAddress: '6650 MAXWELL DR',
                        mailAddress: '6650 MAXWELL DR',
                        physicalCity: 'LITHIA SPRINGS',
                        mailCity: 'LITHIA SPRINGS',
                        physicalState: 'GA',
                        mailState: 'GA',
                        physicalZip: '30122',
                        mailZip: '30122',
                        homePhone: '(509) 412-8470',
                        cellPhone: '(989) 521-9716',
                        employerName: 'A Industries',
                        employerAddress: '742-970 Sit Rd.',
                        employerSuiteAptNumber: '578-4892 Et Av.',
                        employerZip: '99510',
                        employerState: 'AK',
                        employerCity: 'Juneau',
                        employerCounty: 'College',
                        residenceType: 'Own',
                        dateMovedToResidence: '07/18/78',
                        maritalStatus: 'Married',
                        mailCounty: 'ATLANTA',
                        physicalCounty: 'ATLANTA',
                        yearsExperience: 4,

                        spouseFirstName: 'Cynthia',
                        spouseMiddleName: 'Jordan',
                        spouseLastName: 'Bradshaw',
                        spouseGender: 'M',
                        spouseDateOfBirth: '09/17/1968',
                        spouseSsn: '000-66-4626',
                        spouseEmail: 'at.auctor@lacus.com',
                        spouseCellPhone: '(603) 829-1449',
                        jobTitle: 'Suspendisse',
                        driversLicenseNumber: '677874811',
                        driversLicenseState: 'VT',
                        fax: '(186) 220-5021',
                        email: 'egestas.hendrerit@nasceturridiculusmus.co.uk',
                        prefix: '',
                        gender: 'M'

                    },
                    {
                        firstName: 'NITA',
                        middleName: 'FAYE',
                        lastName: 'MKPPXH',
                        suffix: '',
                        ssn: '666-98-9138',
                        dateOfBirth: '7/23/1978',
                        physicalAddress: '11600 SW 9TH CT',
                        mailAddress: '11600 SW 9TH CT',
                        physicalCity: 'PEMBROKE PINES',
                        mailCity: 'PEMBROKE PINES',
                        physicalState: 'FL',
                        mailState: 'FL',
                        physicalZip: '33025',
                        mailZip: '33025',
                        homePhone: '(887) 678-0513',
                        cellPhone: '(448) 892-5006',
                        employerName: 'Neque Nullam Nisl Consulting',
                        employerAddress: '498-1913 Vitae St.',
                        employerSuiteAptNumber: '3452 Dui, Avenue',
                        employerZip: '43617',
                        employerState: 'KY',
                        employerCity: 'Louisville',
                        employerCounty: 'Frankfort',
                        residenceType: 'Rent',
                        dateMovedToResidence: '01/05/77',
                        mailCounty: 'ATLANTA',
                        physicalCounty: 'ATLANTA',
                        yearsExperience: 4,
                        maritalStatus: 'Married',
                        spouseFirstName: 'Eugenia',
                        spouseMiddleName: 'Stuart',
                        spouseLastName: 'Vega',
                        spouseGender: 'F',
                        spouseDateOfBirth: '01/29/1980',
                        spouseSsn: '005-69-9440',
                        spouseEmail: 'dictum.eu@conubianostraper.com',
                        spouseCellPhone: '(255) 627-6497',
                        jobTitle: 'blandit',
                        driversLicenseNumber: '168270607',
                        driversLicenseState: 'FL',
                        fax: '(497) 180-3600',
                        email: 'magnis.dis@Nunc.org',
                        prefix: '',
                        gender: 'F'

                    }
                ],
                companies: [
                    {
                        name: 'Quis Diam Pellentesque LLC',
                        dba: 'Dolor Incorporated',
                        companyType: 'CCorp',
                        physicalZip: '34764',
                        physicalState: 'NV',
                        physicalCity: 'Henderson',
                        physicalCounty: 'Henderson',
                        physicalAddress: 'Ap #197-2760 Lorem St.',
                        dateFormed: '01/16/86',
                        fein: '32-1672',
                        mailAddress: '647-4817 Ante Avenue',
                        mailZip: '86277',
                        mailCity: 'Reno',
                        mailState: 'MO',
                        mailCounty: 'Reno',
                        mailSuiteAptNumber: '9161 Donec St.',
                        physicalSuiteAptNumber: 'Ap #385-2173 Sem. St.',
                        email: 'molestie.arcu@Donecegestas.org',
                        fax: '(645) 902-9444',
                        phone: '(785) 674-7440',

                    }
                ],
                applicationQuestionResponses: [
                    {
                        selectedValue: 'D532Y8OI92X33Z3',
                        masterApplicationQuestionId: '3',
                        questionText: 'What is your state license number?'
                    },
                    {
                        selectedValue: 'Yes',
                        masterApplicationQuestionId: '1',
                        questionText: 'Are you trust worthy?',
                        originType: 'Underwriting'
                    }
                ],
                obligee: {
                    contact1: 'John Krazinski',
                    contact2: 'Emily Redden',
                    contact3: 'Tyler Smith',
                    email: 'tortor.dictum@doloregestasrhoncus.ca',
                    fax: '(561) 372-0098',
                    mailAddress: '123 Oak Street',
                    mailCity: 'Joplin',
                    mailCounty: 'Jasper',
                    mailState: 'MO',
                    mailSuiteAptNumber: 'Suite 12',
                    mailZip: '64801',
                    name: 'Lotor Esrat Obligee',
                    phone: '(888) 811-8290',
                    physicalAddress: '123 Oak Street',
                    physicalCity: 'Joplin',
                    physicalCounty: 'Jasper',
                    physicalState: 'MO',
                    physicalSuiteAptNumber: 'Suite 12',
                    physicalZip: '64801',
                    websiteURL: 'doloregestasrhoncus.ca'
                },
                desiredEffectiveDate: '7/12/2018',
                submissionSignature: 'Automated Tester',
                nameOnBond: 'ROBERT C MDSXX'
            },
            {
                value: 5,
                label: '544 - TERRANCE N KXNPGK',

                $ownerCount: '2',
                people: [
                    {
                        firstName: 'TERRANCE',
                        middleName: 'N',
                        lastName: 'KXNPGK',
                        suffix: '',
                        ssn: '666-43-6389',
                        dateOfBirth: '03/19/1951',
                        physicalAddress: '904 STABLE RIDGE DR',
                        mailAddress: '904 STABLE RIDGE DR',
                        physicalCity: 'HOLLY SPRINGS',
                        mailCity: 'HOLLY SPRINGS',
                        physicalState: 'NC',
                        mailState: 'NC',
                        physicalZip: '27540',
                        mailZip: '27540',
                        homePhone: '(690) 478-1505',
                        cellPhone: '(690) 478-1505',
                        employerName: 'Non Lorem Vitae Associates',
                        employerAddress: 'Ap #105-340 Proin Road',
                        employerSuiteAptNumber: '307-1023 Eu, St.',
                        mailCounty: 'ATLANTA',
                        physicalCounty: 'ATLANTA',
                        yearsExperience: 4,

                        employerZip: '65731',
                        employerState: 'PA',
                        employerCity: 'Pittsburgh',
                        employerCounty: 'Philadelphia',
                        residenceType: 'Rent',
                        dateMovedToResidence: '10/11/93',
                        maritalStatus: 'Married',
                        spouseFirstName: 'Veronica',
                        spouseMiddleName: 'Callum',
                        spouseLastName: 'Mendez',
                        spouseGender: 'M',
                        spouseDateOfBirth: '11/07/1990',
                        spouseSsn: '595-01-4629',
                        spouseEmail: 'nunc.est@nec.ca',
                        spouseCellPhone: '(179) 226-0659',
                        jobTitle: 'Proin',
                        driversLicenseNumber: '411745542',
                        driversLicenseState: 'NV',
                        fax: '(569) 154-1382',
                        email: 'In@In.com',
                        prefix: 'Mrs.',
                        gender: 'M'

                    },
                    {
                        firstName: 'MARLA',
                        middleName: 'CARLENE',
                        lastName: 'FGNKGGG',
                        suffix: '',
                        ssn: '666-78-9472',
                        dateOfBirth: '9/22/1972',
                        physicalAddress: '2020 COWAN AVE',
                        mailAddress: '2020 COWAN AVE',
                        physicalCity: 'SAVANNAH',
                        mailCity: 'SAVANNAH',
                        physicalState: 'GA',
                        mailState: 'GA',
                        physicalZip: '31405',
                        mailZip: '31405',
                        homePhone: '(162) 638-7202',
                        cellPhone: '(162) 638-7202',
                        employerName: 'Lobortis Risus Associates',
                        employerAddress: '291-6463 Varius St.',
                        employerSuiteAptNumber: 'P.O. Box 246, 7133 Vel St.',
                        mailCounty: 'ATLANTA',
                        physicalCounty: 'ATLANTA',
                        employerZip: '46398',
                        employerState: 'ID',
                        employerCity: 'Idaho Falls',
                        employerCounty: 'Meridian',
                        residenceType: 'Rent',
                        dateMovedToResidence: '07/21/35',
                        maritalStatus: 'Married',
                        spouseFirstName: 'Odette',
                        spouseMiddleName: 'Kerry',
                        spouseLastName: 'Conway',
                        spouseGender: 'M',
                        spouseDateOfBirth: '01/04/1955',
                        spouseSsn: '633-92-7656',
                        spouseEmail: 'lobortis@eratnonummyultricies.ca',
                        spouseCellPhone: '(453) 856-3540',
                        jobTitle: 'facilisi.',
                        driversLicenseNumber: '841458276',
                        driversLicenseState: 'OH',
                        fax: '(307) 839-5209',
                        email: 'Sed.id@lobortisultricesVivamus.ca',
                        prefix: '',
                        gender: 'M',
                        yearsExperience: 4,

                    }
                ],
                companies: [
                    {
                        name: 'Consectetuer Adipiscing Elit LLC',
                        dba: 'Mi Tempor Inc.',
                        companyType: 'Proprietorship',
                        physicalZip: '12452',
                        physicalState: 'IA',
                        physicalCity: 'Cedar Rapids',
                        physicalCounty: 'Sioux City',
                        physicalAddress: '4892 Sed Avenue',
                        dateFormed: '12/24/45',
                        fein: '35-3032',
                        mailAddress: '875-2685 Amet Street',
                        mailZip: '38331',
                        mailCity: 'Sioux City',
                        mailState: 'ID',
                        mailCounty: 'Davenport',
                        mailSuiteAptNumber: 'Ap #948-5802 Nisi Ave',
                        physicalSuiteAptNumber: 'Ap #250-9988 Molestie St.',
                        email: 'Etiam.vestibulum@rutrumurnanec.com',
                        fax: '(415) 771-0940',
                        phone: '(999) 947-0555'
                    }
                ],
                applicationQuestionResponses: [
                    {
                        selectedValue: 'C681F1VA63D19S2',
                        masterApplicationQuestionId: '3',
                        questionText: 'What is your state license number?'
                    },
                    {
                        selectedValue: 'Yes',
                        masterApplicationQuestionId: '1',
                        questionText: 'Are you trust worthy?',
                        originType: 'Underwriting'
                    }
                ],
                obligee: {
                    contact1: 'John Krazinski',
                    contact2: 'Emily Redden',
                    contact3: 'Tyler Smith',
                    email: 'tortor.dictum@doloregestasrhoncus.ca',
                    fax: '(561) 372-0098',
                    mailAddress: '123 Oak Street',
                    mailCity: 'Joplin',
                    mailCounty: 'Jasper',
                    mailState: 'MO',
                    mailSuiteAptNumber: 'Suite 12',
                    mailZip: '64801',
                    name: 'Lotor Esrat Obligee',
                    phone: '(888) 811-8290',
                    physicalAddress: '123 Oak Street',
                    physicalCity: 'Joplin',
                    physicalCounty: 'Jasper',
                    physicalState: 'MO',
                    physicalSuiteAptNumber: 'Suite 12',
                    physicalZip: '64801',
                    websiteURL: 'doloregestasrhoncus.ca'
                },
                desiredEffectiveDate: '7/14/2018',
                submissionSignature: 'Automated Tester',
                nameOnBond: 'TERRANCE N KXNPGK'
            },
            {
                value: 6,
                label: '386 - KAY N DKZNLPNX',

                $ownerCount: '3',
                people: [
                    {
                        firstName: 'KAY',
                        middleName: 'N',
                        lastName: 'DKZNLPNX',
                        suffix: '',
                        ssn: '666-66-6846',
                        dateOfBirth: '03/02/1980',
                        physicalAddress: '470 GRANT ST',
                        mailAddress: '470 GRANT ST',
                        physicalCity: 'AMERICAN FALLS',
                        mailCity: 'AMERICAN FALLS',
                        physicalState: 'ID',
                        mailState: 'ID',
                        physicalZip: '83211',
                        mailZip: '83211',
                        homePhone: '(293) 565-3301',
                        cellPhone: '(293) 565-3301',
                        employerName: 'Fermentum Company',
                        employerAddress: '6023 Amet Av.',
                        employerSuiteAptNumber: '207-9843 Nulla. Road',
                        mailCounty: 'ATLANTA',
                        physicalCounty: 'ATLANTA',
                        yearsExperience: 4,
                        employerZip: '10395',
                        employerState: 'PA',
                        employerCity: 'Erie',
                        employerCounty: 'Erie',
                        residenceType: 'Own',
                        dateMovedToResidence: '06/04/62',
                        maritalStatus: 'Married',
                        spouseFirstName: 'Tatyana',
                        spouseMiddleName: 'Echo',
                        spouseLastName: 'Glenn',
                        spouseGender: 'M',
                        spouseDateOfBirth: '05/20/1946',
                        spouseSsn: '042-65-2449',
                        spouseEmail: 'neque@duiFusce.co.uk',
                        spouseCellPhone: '(259) 768-6199',
                        jobTitle: 'Morbi',
                        driversLicenseNumber: '672154342',
                        driversLicenseState: 'KY',
                        fax: '(481) 353-1858',
                        email: 'rhoncus@elitdictumeu.net',
                        prefix: '',
                        gender: 'M'

                    },
                    {
                        firstName: 'DONNA',
                        middleName: 'M',
                        lastName: 'YCKDR',
                        suffix: '',
                        ssn: '666-04-9932',
                        dateOfBirth: '9/19/1979',
                        physicalAddress: '5 S BEAN LN',
                        mailAddress: '5 S BEAN LN',
                        physicalCity: 'SALMON',
                        mailCity: 'SALMON',
                        physicalState: 'ID',
                        mailState: 'ID',
                        physicalZip: '83467',
                        mailZip: '83467',
                        homePhone: '(958) 866-6569',
                        cellPhone: '(958) 866-6569',
                        employerName: 'Suscipit Incorporated',
                        employerAddress: 'P.O. Box 727, 3286 Tempus Road',
                        employerSuiteAptNumber: '517-1364 Quam Av.',
                        employerZip: '65248',
                        employerState: 'PA',
                        employerCity: 'Harrisburg',
                        employerCounty: 'Pittsburgh',
                        residenceType: 'Rent',
                        dateMovedToResidence: '08/11/55',
                        maritalStatus: 'Married',
                        spouseFirstName: 'Cadman',
                        spouseMiddleName: 'Hyacinth',
                        mailCounty: 'ATLANTA',
                        physicalCounty: 'ATLANTA',
                        yearsExperience: 4,
                        spouseLastName: 'Henson',
                        spouseGender: 'F',
                        spouseDateOfBirth: '02/13/1987',
                        spouseSsn: '217-34-3040',
                        spouseEmail: 'In@duiFuscealiquam.com',
                        spouseCellPhone: '(215) 524-7886',
                        jobTitle: 'tortor.',
                        driversLicenseNumber: '654183975',
                        driversLicenseState: 'CA',
                        fax: '(409) 996-9820',
                        email: 'commodo@blandit.edu',
                        prefix: '',
                        gender: 'M'

                    },
                    {
                        firstName: 'MELISSA',
                        middleName: 'D',
                        lastName: 'DKZWXZ',
                        suffix: '',
                        ssn: '666-88-5053',
                        dateOfBirth: '4/3/1975',
                        physicalAddress: '12432 HENRIETTA AVE',
                        mailAddress: '12432 HENRIETTA AVE',
                        physicalCity: 'LARGO',
                        mailCity: 'LARGO',
                        physicalState: 'FL',
                        mailState: 'FL',
                        physicalZip: '33774',
                        mailZip: '33774',
                        homePhone: '(727) 555-1212',
                        cellPhone: '(727) 555-1212',
                        employerName: 'Placerat Augue Institute',
                        employerAddress: '9223 Consectetuer St.',
                        employerSuiteAptNumber: '7817 Libero Rd.',
                        mailCounty: 'ATLANTA',
                        physicalCounty: 'ATLANTA',
                        yearsExperience: 4,
                        employerZip: '20265',
                        employerState: 'FL',
                        employerCity: 'Miami',
                        employerCounty: 'Orlando',
                        residenceType: 'Rent',
                        dateMovedToResidence: '06/26/85',
                        maritalStatus: 'Married',
                        spouseFirstName: 'Ivana',
                        spouseMiddleName: 'Upton',
                        spouseLastName: 'Wheeler',
                        spouseGender: 'F',
                        spouseDateOfBirth: '09/21/1992',
                        spouseSsn: '172-14-1276',
                        spouseEmail: 'Aliquam.auctor.velit@Nuncsedorci.ca',
                        spouseCellPhone: '(604) 348-3113',
                        jobTitle: 'Sed',
                        driversLicenseNumber: '962068485',
                        driversLicenseState: 'ME',
                        fax: '(858) 188-0297',
                        email: 'sem.magna.nec@etnetuset.edu',
                        prefix: '',
                        gender: 'F'

                    }
                ],
                companies: [
                    {
                        name: 'Donec Sollicitudin Adipiscing Foundation',
                        dba: 'Eros Turpis Non Company',
                        companyType: 'Partnership',
                        physicalZip: '23281',
                        physicalState: 'WA',
                        physicalCity: 'Bellevue',
                        physicalCounty: 'Bellevue',
                        physicalAddress: 'P.O. Box 270, 7510 Enim. Ave',
                        dateFormed: '09/06/37',
                        fein: '49-3535',
                        mailAddress: '787-890 Vestibulum Av.',
                        mailZip: '74657',
                        mailCity: 'Olympia',
                        mailState: 'ME',
                        mailCounty: 'Olympia',
                        mailSuiteAptNumber: '315-5832 Ultricies Av.',
                        physicalSuiteAptNumber: '637-7457 Facilisis Street',
                        email: 'est.Nunc.ullamcorper@ullamcorpermagnaSed.org',
                        fax: '(223) 971-7159',
                        phone: '(409) 971-9162'
                    }
                ],
                applicationQuestionResponses: [
                    {
                        selectedValue: 'U545H3OJ27V55W4',
                        masterApplicationQuestionId: '3',
                        questionText: 'What is your state license number?'
                    },
                    {
                        selectedValue: 'Yes',
                        masterApplicationQuestionId: '1',
                        questionText: 'Are you trust worthy?',
                        originType: 'Underwriting'
                    }
                ],
                obligee: {
                    contact1: 'John Krazinski',
                    contact2: 'Emily Redden',
                    contact3: 'Tyler Smith',
                    email: 'tortor.dictum@doloregestasrhoncus.ca',
                    fax: '(561) 372-0098',
                    mailAddress: '123 Oak Street',
                    mailCity: 'Joplin',
                    mailCounty: 'Jasper',
                    mailState: 'MO',
                    mailSuiteAptNumber: 'Suite 12',
                    mailZip: '64801',
                    name: 'Lotor Esrat Obligee',
                    phone: '(888) 811-8290',
                    physicalAddress: '123 Oak Street',
                    physicalCity: 'Joplin',
                    physicalCounty: 'Jasper',
                    physicalState: 'MO',
                    physicalSuiteAptNumber: 'Suite 12',
                    physicalZip: '64801',
                    websiteURL: 'doloregestasrhoncus.ca'
                },
                desiredEffectiveDate: '7/10/2018',
                submissionSignature: 'Automated Tester',
                nameOnBond: 'KAY N DKZNLPNX'
            },
            {
                value: 7,
                label: '435 - KATHYLYNN LYNN MLNNXZ',

                $ownerCount: '2',
                people: [
                    {
                        firstName: 'KATHYLYNN',
                        middleName: 'LYNN',
                        lastName: 'MLNNXZ',
                        suffix: '',
                        ssn: '666-84-8198',
                        dateOfBirth: '07/07/1967',
                        physicalAddress: '1409 12TH AVE APT 13',
                        mailAddress: '1409 12TH AVE APT 13',
                        physicalCity: 'BELLE PLAINE',
                        mailCity: 'BELLE PLAINE',
                        physicalState: 'IA',
                        mailState: 'IA',
                        physicalZip: '52208',
                        mailZip: '52208',
                        homePhone: '(319) 555-1212',
                        cellPhone: '(319) 555-1212',
                        employerName: 'Ligula Eu Enim Limited',
                        employerAddress: '888-6855 Nunc. Street',
                        employerSuiteAptNumber: '1742 Integer St.',
                        employerZip: '72856',
                        employerState: 'AR',
                        employerCity: 'Springdale',
                        employerCounty: 'Fayetteville',
                        residenceType: 'Own',
                        dateMovedToResidence: '02/15/78',
                        maritalStatus: 'Married',
                        spouseFirstName: 'Phelan',
                        spouseMiddleName: 'Ahmed',
                        spouseLastName: 'Blankenship',
                        spouseGender: 'M',
                        spouseDateOfBirth: '09/21/1997',
                        spouseSsn: '930-02-6054',
                        spouseEmail: 'ante.bibendum@eleifend.ca',
                        mailCounty: 'ATLANTA',
                        physicalCounty: 'ATLANTA',
                        yearsExperience: 4,
                        spouseCellPhone: '(174) 189-9869',
                        jobTitle: 'eget,',
                        driversLicenseNumber: '841005522',
                        driversLicenseState: 'MA',
                        fax: '(875) 911-4077',
                        email: 'nec.ante.blandit@justofaucibuslectus.co.uk',
                        prefix: 'Mr.',
                        gender: 'F'

                    },
                    {
                        firstName: 'MARY',
                        middleName: 'L',
                        lastName: 'DKWKNBG',
                        suffix: '',
                        ssn: '666-00-0186',
                        dateOfBirth: '2/26/1959',
                        physicalAddress: '2010 TX 8TV ST',
                        mailAddress: '2010 TX 8TV ST',
                        physicalCity: 'KANSAS CITY',
                        mailCity: 'KANSAS CITY',
                        physicalState: 'MO',
                        mailState: 'MO',
                        physicalZip: '64101',
                        mailZip: '64101',
                        homePhone: '(770) 555-1212',
                        cellPhone: '(770) 555-1212',
                        employerName: 'Egestas Nunc LLP',
                        employerAddress: '668-6889 Donec Street',
                        employerSuiteAptNumber: '231-5980 Interdum St.',
                        employerZip: '80842',
                        employerState: 'OH',
                        employerCity: 'Akron',
                        employerCounty: 'Columbus',
                        residenceType: 'Rent',
                        dateMovedToResidence: '11/03/74',
                        maritalStatus: 'Married',
                        spouseFirstName: 'Ayanna',
                        spouseMiddleName: 'Chelsea',
                        spouseLastName: 'Roach',
                        spouseGender: 'F',
                        spouseDateOfBirth: '02/18/1964',
                        spouseSsn: '295-72-5682',
                        spouseEmail: 'facilisi.Sed.neque@pharetra.edu',
                        mailCounty: 'ATLANTA',
                        physicalCounty: 'ATLANTA',
                        yearsExperience: 4,
                        spouseCellPhone: '(394) 488-2657',
                        jobTitle: 'Nunc',
                        driversLicenseNumber: '910902081',
                        driversLicenseState: 'MT',
                        fax: '(213) 215-4154',
                        email: 'blandit.enim.consequat@Loremipsumdolor.edu',
                        prefix: '',
                        gender: 'M'

                    }
                ],
                companies: [
                    {
                        name: 'Posuere Cubilia Curae; Inc.',
                        dba: 'Auctor Mauris Vel Ltd',
                        companyType: 'SCorp',
                        physicalZip: '23725',
                        physicalState: 'IL',
                        physicalCity: 'Rockford',
                        physicalCounty: 'Chicago',
                        physicalAddress: 'Ap #743-9637 Non, St.',
                        dateFormed: '04/06/43',
                        fein: '72-3864',
                        mailAddress: '518-9596 A, Street',
                        mailZip: '86644',
                        mailCity: 'Naperville',
                        mailState: 'MO',
                        mailCounty: 'Naperville',
                        mailSuiteAptNumber: 'Ap #671-6105 Faucibus Road',
                        physicalSuiteAptNumber: 'Ap #589-764 Ut, St.',
                        email: 'sem.elit.pharetra@maurisipsumporta.com',
                        fax: '(684) 152-1746',
                        phone: '(647) 020-5143'
                    }
                ],
                applicationQuestionResponses: [
                    {
                        selectedValue: 'S884W8HS22B04T5',
                        masterApplicationQuestionId: '3',
                        questionText: 'What is your state license number?'
                    },
                    {
                        selectedValue: 'Yes',
                        masterApplicationQuestionId: '1',
                        questionText: 'Are you trust worthy?',
                        originType: 'Underwriting'
                    }
                ],
                obligee: {
                    contact1: 'John Krazinski',
                    contact2: 'Emily Redden',
                    contact3: 'Tyler Smith',
                    email: 'tortor.dictum@doloregestasrhoncus.ca',
                    fax: '(561) 372-0098',
                    mailAddress: '123 Oak Street',
                    mailCity: 'Joplin',
                    mailCounty: 'Jasper',
                    mailState: 'MO',
                    mailSuiteAptNumber: 'Suite 12',
                    mailZip: '64801',
                    name: 'Lotor Esrat Obligee',
                    phone: '(888) 811-8290',
                    physicalAddress: '123 Oak Street',
                    physicalCity: 'Joplin',
                    physicalCounty: 'Jasper',
                    physicalState: 'MO',
                    physicalSuiteAptNumber: 'Suite 12',
                    physicalZip: '64801',
                    websiteURL: 'doloregestasrhoncus.ca'
                },
                desiredEffectiveDate: '7/22/2018',
                submissionSignature: 'Automated Tester',
                nameOnBond: 'KATHYLYNN LYNN MLNNXZ'
            },
            {
                value: 8,
                label: '573 - JOHN LOUIS WZXMGKWL',

                $ownerCount: '4',
                people: [
                    {
                        firstName: 'JOHN',
                        middleName: 'LOUIS',
                        lastName: 'WZXMGKWL',
                        suffix: '',
                        ssn: '666-90-9404',
                        dateOfBirth: '10/1/1975',
                        physicalAddress: '829 BALINESE AVE',
                        mailAddress: '829 BALINESE AVE',
                        physicalCity: 'HENDERSON',
                        mailCity: 'HENDERSON',
                        physicalState: 'NV',
                        mailState: 'NV',
                        physicalZip: '89015',
                        mailZip: '89015',
                        homePhone: '(764) 707-0764',
                        cellPhone: '(764) 707-0764',
                        employerName: 'Ornare Fusce Mollis PC',
                        employerAddress: 'P.O. Box 989, 4435 Egestas Av.',
                        employerSuiteAptNumber: '364-5781 Eu St.',
                        employerZip: '94543',
                        employerState: 'VA',
                        employerCity: 'Norfolk',
                        employerCounty: 'Newport News',
                        residenceType: 'Rent',
                        dateMovedToResidence: '05/30/85',
                        maritalStatus: 'Married',
                        spouseFirstName: 'Sean',
                        spouseMiddleName: 'Jermaine',
                        mailCounty: 'ATLANTA',
                        physicalCounty: 'ATLANTA',
                        yearsExperience: 4,
                        spouseLastName: 'Richards',
                        spouseGender: 'F',
                        spouseDateOfBirth: '12/17/1995',
                        spouseSsn: '281-06-6006',
                        spouseEmail: 'tellus@orciDonec.co.uk',
                        spouseCellPhone: '(510) 676-1078',
                        jobTitle: 'lacus.',
                        driversLicenseNumber: '211955095',
                        driversLicenseState: 'UT',
                        fax: '(494) 974-3854',
                        email: 'adipiscing.lobortis.risus@sollicitudin.ca',
                        prefix: '',
                        gender: 'M'

                    },
                    {
                        firstName: 'DAVID',
                        middleName: 'RAY',
                        lastName: 'KNNMGKB',
                        suffix: '',
                        ssn: '666-88-4918',
                        dateOfBirth: '2/19/1975',
                        physicalAddress: '502 PRINCE DR',
                        mailAddress: '502 PRINCE DR',
                        physicalCity: 'GREENWOOD',
                        mailCity: 'GREENWOOD',
                        physicalState: 'IN',
                        mailState: 'IN',
                        physicalZip: '46142',
                        mailZip: '46142',
                        homePhone: '(317) 555-1212',
                        cellPhone: '(317) 555-1212',
                        employerName: 'Duis A Foundation',
                        employerAddress: '409-2455 Viverra. Street',
                        employerSuiteAptNumber: 'Ap #368-7849 Sapien, Street',
                        employerZip: '58700',
                        employerState: 'MT',
                        employerCity: 'Butte',
                        employerCounty: 'Helena',
                        residenceType: 'Rent',
                        dateMovedToResidence: '01/20/79',
                        maritalStatus: 'Married',
                        spouseFirstName: 'Lara',
                        mailCounty: 'ATLANTA',
                        physicalCounty: 'ATLANTA',
                        yearsExperience: 4,
                        spouseMiddleName: 'Edward',
                        spouseLastName: 'Greer',
                        spouseGender: 'M',
                        spouseDateOfBirth: '01/13/1973',
                        spouseSsn: '662-20-9008',
                        spouseEmail: 'molestie@velturpis.edu',
                        spouseCellPhone: '(353) 864-6249',
                        jobTitle: 'nec',
                        driversLicenseNumber: '785518551',
                        driversLicenseState: 'AR',
                        fax: '(692) 290-9197',
                        email: 'ac.mattis.velit@velsapien.com',
                        prefix: '',
                        gender: 'F'

                    },
                    {
                        firstName: 'PATRICK',
                        middleName: 'ALLAN',
                        lastName: 'NXGKX',
                        suffix: 'JR',
                        ssn: '666-86-0831',
                        dateOfBirth: '',
                        physicalAddress: '15 LILY CT',
                        mailAddress: '15 LILY CT',
                        physicalCity: 'GRANITE CITY',
                        mailCity: 'GRANITE CITY',
                        physicalState: 'IL',
                        mailState: 'IL',
                        physicalZip: '62040',
                        mailZip: '62040',
                        homePhone: '(993) 560-4557',
                        cellPhone: '(993) 560-4557',
                        mailCounty: 'ATLANTA',
                        physicalCounty: 'ATLANTA',
                        yearsExperience: 4,
                        employerName: 'Molestie Company',
                        employerAddress: 'P.O. Box 842, 3051 Sagittis Avenue',
                        employerSuiteAptNumber: '2054 Mollis Rd.',
                        employerZip: '49566',
                        employerState: 'ME',
                        employerCity: 'Lewiston',
                        employerCounty: 'Augusta',
                        residenceType: 'Rent',
                        dateMovedToResidence: '11/19/67',
                        maritalStatus: 'Married',
                        spouseFirstName: 'Cheyenne',
                        spouseMiddleName: 'Hedwig',
                        spouseLastName: 'Rowe',
                        spouseGender: 'F',
                        spouseDateOfBirth: '04/18/1989',
                        spouseSsn: '882-97-8993',
                        spouseEmail: 'Ut@tortorInteger.com',
                        spouseCellPhone: '(378) 646-8689',
                        jobTitle: 'parturient',
                        driversLicenseNumber: '440682574',
                        driversLicenseState: 'OH',
                        fax: '(825) 586-3907',
                        email: 'accumsan.laoreet@mollis.ca',
                        prefix: '',
                        gender: 'F'

                    },
                    {
                        firstName: 'GARRY',
                        middleName: 'L',
                        lastName: 'NLUXPKH',
                        suffix: '2',
                        ssn: '666-68-3469',
                        dateOfBirth: '',
                        physicalAddress: '3710 N PINE GROVE AVE APT 207',
                        mailAddress: '3710 N PINE GROVE AVE APT 207',
                        physicalCity: 'CHICAGO',
                        mailCity: 'CHICAGO',
                        physicalState: 'IL',
                        mailState: 'IL',
                        physicalZip: '60613',
                        mailZip: '60613',
                        homePhone: '(773) 555-1212',
                        cellPhone: '(773) 555-1212',
                        employerName: 'Ligula Eu Incorporated',
                        employerAddress: 'Ap #677-2601 Sit St.',
                        employerSuiteAptNumber: 'Ap #751-8074 Nibh Avenue',
                        employerZip: '96317',
                        mailCounty: 'ATLANTA',
                        physicalCounty: 'ATLANTA',
                        yearsExperience: 4,
                        employerState: 'CA',
                        employerCity: 'San Francisco',
                        employerCounty: 'Fresno',
                        residenceType: 'Rent',
                        dateMovedToResidence: '01/11/84',
                        maritalStatus: 'Married',
                        spouseFirstName: 'Ashton',
                        spouseMiddleName: 'Farrah',
                        spouseLastName: 'Gregory',
                        spouseGender: 'F',
                        spouseDateOfBirth: '10/25/1964',
                        spouseSsn: '905-03-0797',
                        spouseEmail: 'purus@turpisvitae.org',
                        spouseCellPhone: '(952) 650-7696',
                        jobTitle: 'purus,',
                        driversLicenseNumber: '742682878',
                        driversLicenseState: 'NV',
                        fax: '(644) 819-6254',
                        email: 'Integer.urna@Nulla.net',
                        prefix: 'Mrs.',
                        gender: 'M'

                    }
                ],
                companies: [
                    {
                        name: 'Sit LLC',
                        dba: 'Magna Nam Ligula Inc.',
                        companyType: 'Proprietorship',
                        physicalZip: '93807',
                        physicalState: 'CO',
                        physicalCity: 'Lakewood',
                        physicalCounty: 'Colorado Springs',
                        physicalAddress: 'P.O. Box 250, 2485 Aliquet Av.',
                        dateFormed: '04/15/87',
                        fein: '46-0101',
                        mailAddress: '7871 In Rd.',
                        mailZip: '62096',
                        mailCity: 'Fort Collins',
                        mailState: 'AZ',
                        mailCounty: 'Aurora',
                        mailSuiteAptNumber: 'Ap #870-3162 Erat. St.',
                        physicalSuiteAptNumber: 'Ap #906-3225 A St.',
                        email: 'ut.cursus@magnatellusfaucibus.org',
                        fax: '(186) 368-3262',
                        phone: '(721) 738-3745'
                    }
                ],
                applicationQuestionResponses: [
                    {
                        selectedValue: 'G237K5HX81Y84B5',
                        masterApplicationQuestionId: '3',
                        questionText: 'What is your state license number?'
                    },
                    {
                        selectedValue: 'Yes',
                        masterApplicationQuestionId: '1',
                        questionText: 'Are you trust worthy?',
                        originType: 'Underwriting'
                    }
                ],
                obligee: {
                    contact1: 'John Krazinski',
                    contact2: 'Emily Redden',
                    contact3: 'Tyler Smith',
                    email: 'tortor.dictum@doloregestasrhoncus.ca',
                    fax: '(561) 372-0098',
                    mailAddress: '123 Oak Street',
                    mailCity: 'Joplin',
                    mailCounty: 'Jasper',
                    mailState: 'MO',
                    mailSuiteAptNumber: 'Suite 12',
                    mailZip: '64801',
                    name: 'Lotor Esrat Obligee',
                    phone: '(888) 811-8290',
                    physicalAddress: '123 Oak Street',
                    physicalCity: 'Joplin',
                    physicalCounty: 'Jasper',
                    physicalState: 'MO',
                    physicalSuiteAptNumber: 'Suite 12',
                    physicalZip: '64801',
                    websiteURL: 'doloregestasrhoncus.ca'
                },
                desiredEffectiveDate: '7/2/2018',
                submissionSignature: 'Automated Tester',
                nameOnBond: 'JOHN LOUIS WZXMGKWL'
            },
            {
                value: 9,
                label: '576 - JOHN J ZRGKBXP',

                $ownerCount: '3',
                people: [
                    {
                        firstName: 'JOHN',
                        middleName: 'J',
                        lastName: 'ZRGKBXP',
                        suffix: '',
                        ssn: '666-34-1484',
                        dateOfBirth: '',
                        physicalAddress: '1016 BRIDGEWATER DR',
                        mailAddress: '1016 BRIDGEWATER DR',
                        physicalCity: 'BURLINGTON',
                        mailCity: 'BURLINGTON',
                        physicalState: 'NC',
                        mailState: 'NC',
                        physicalZip: '27217',
                        mailZip: '27217',
                        homePhone: '(336) 555-1212',
                        cellPhone: '(336) 555-1212',
                        employerName: 'Non Enim Commodo Institute',
                        employerAddress: 'Ap #975-888 Duis Avenue',
                        employerSuiteAptNumber: '5918 Neque Av.',
                        employerZip: '52286',
                        employerState: 'PA',
                        employerCity: 'Pittsburgh',
                        employerCounty: 'Reading',
                        residenceType: 'Own',
                        dateMovedToResidence: '07/25/82',
                        mailCounty: 'ATLANTA',
                        physicalCounty: 'ATLANTA',
                        yearsExperience: 4,
                        maritalStatus: 'Married',
                        spouseFirstName: 'Gail',
                        spouseMiddleName: 'Jackson',
                        spouseLastName: 'Mason',
                        spouseGender: 'F',
                        spouseDateOfBirth: '04/25/1955',
                        spouseSsn: '420-94-9589',
                        spouseEmail: 'dictum.eleifend@Proinnon.ca',
                        spouseCellPhone: '(197) 761-3927',
                        jobTitle: 'nonummy',
                        driversLicenseNumber: '415877304',
                        driversLicenseState: 'KY',
                        fax: '(258) 454-3201',
                        email: 'velit@dolorelitpellentesque.com',
                        prefix: 'Dr.',
                        gender: 'F'

                    },
                    {
                        firstName: 'DONNA',
                        middleName: 'ANN',
                        lastName: 'SGPP',
                        suffix: '',
                        ssn: '666-61-3816',
                        dateOfBirth: '',
                        physicalAddress: '8021 ANTIOCH RD',
                        mailAddress: '8021 ANTIOCH RD',
                        physicalCity: 'OVERLAND PARK',
                        mailCity: 'OVERLAND PARK',
                        physicalState: 'KS',
                        mailState: 'KS',
                        physicalZip: '66204',
                        mailZip: '66204',
                        homePhone: '(913) 555-1212',
                        cellPhone: '(913) 555-1212',
                        employerName: 'Class Aptent Company',
                        employerAddress: 'P.O. Box 258, 7720 Ad Street',
                        employerSuiteAptNumber: '851-2086 Dolor St.',
                        mailCounty: 'ATLANTA',
                        physicalCounty: 'ATLANTA',
                        yearsExperience: 4,
                        employerZip: '72873',
                        employerState: 'KY',
                        employerCity: 'Frankfort',
                        employerCounty: 'Covington',
                        residenceType: 'Rent',
                        dateMovedToResidence: '12/25/46',
                        maritalStatus: 'Married',
                        spouseFirstName: 'Iris',
                        spouseMiddleName: 'Hanna',
                        spouseLastName: 'Floyd',
                        spouseGender: 'M',
                        spouseDateOfBirth: '06/28/1957',
                        spouseSsn: '645-30-7737',
                        spouseEmail: 'mollis.Phasellus.libero@consectetuer.ca',
                        spouseCellPhone: '(480) 864-2704',
                        jobTitle: 'mus.',
                        driversLicenseNumber: '116518482',
                        driversLicenseState: 'MO',
                        fax: '(112) 566-6614',
                        email: 'pede@ipsum.org',
                        prefix: '',
                        gender: 'M'

                    },
                    {
                        firstName: 'DANENE',
                        middleName: 'YVONNE',
                        lastName: 'DRKMF',
                        suffix: '',
                        ssn: '666-66-2945',
                        dateOfBirth: '6/8/1968',
                        physicalAddress: 'PO BOX 3273 ',
                        mailAddress: 'PO BOX 3273 ',
                        physicalCity: 'MCCALL',
                        mailCity: 'MCCALL',
                        physicalState: 'ID',
                        mailState: 'ID',
                        physicalZip: '83638',
                        mailZip: '83638',
                        homePhone: '(979) 945-1778',
                        cellPhone: '(979) 945-1778',
                        employerName: 'Faucibus Institute',
                        employerAddress: '764-201 Orci Av.',
                        employerSuiteAptNumber: 'P.O. Box 990, 205 Sagittis Rd.',
                        mailCounty: 'ATLANTA',
                        physicalCounty: 'ATLANTA',
                        yearsExperience: 4,
                        employerZip: '96770',
                        employerState: 'CA',
                        employerCity: 'Fresno',
                        employerCounty: 'Fresno',
                        residenceType: 'Rent',
                        dateMovedToResidence: '07/30/32',
                        maritalStatus: 'Married',
                        spouseFirstName: 'Victor',
                        spouseMiddleName: 'Evelyn',
                        spouseLastName: 'Bean',
                        spouseGender: 'M',
                        spouseDateOfBirth: '08/27/1983',
                        spouseSsn: '162-56-6382',
                        spouseEmail: 'erat.semper.rutrum@luctus.ca',
                        spouseCellPhone: '(544) 905-2289',
                        jobTitle: 'non,',
                        driversLicenseNumber: '623463785',
                        driversLicenseState: 'TX',
                        fax: '(371) 520-2021',
                        email: 'fringilla.cursus@Vestibulum.com',
                        prefix: '',
                        gender: 'M'

                    }
                ],
                companies: [
                    {
                        name: 'Imperdiet Non Industries',
                        dba: 'Dui Foundation',
                        companyType: 'SCorp',
                        physicalZip: '81798',
                        physicalState: 'WY',
                        physicalCity: 'Laramie',
                        physicalCounty: 'Laramie',
                        physicalAddress: '3063 Vulputate Road',
                        dateFormed: '02/05/59',
                        fein: '86-8884',
                        mailAddress: '608-9369 Tincidunt Rd.',
                        mailZip: '43376',
                        mailCity: 'Rock Springs',
                        mailState: 'AK',
                        mailCounty: 'Cheyenne',
                        mailSuiteAptNumber: 'P.O. Box 690, 1034 Phasellus Avenue',
                        physicalSuiteAptNumber: 'P.O. Box 380, 2390 Purus. St.',
                        email: 'Etiam@metus.co.uk',
                        fax: '(741) 954-2292',
                        phone: '(983) 585-5679'
                    }
                ],
                applicationQuestionResponses: [
                    {
                        selectedValue: 'K493U7FO55F72U8',
                        masterApplicationQuestionId: '3',
                        questionText: 'What is your state license number?'
                    },
                    {
                        selectedValue: 'Yes',
                        masterApplicationQuestionId: '1',
                        questionText: 'Are you trust worthy?',
                        originType: 'Underwriting'
                    }
                ],
                obligee: {
                    contact1: 'John Krazinski',
                    contact2: 'Emily Redden',
                    contact3: 'Tyler Smith',
                    email: 'tortor.dictum@doloregestasrhoncus.ca',
                    fax: '(561) 372-0098',
                    mailAddress: '123 Oak Street',
                    mailCity: 'Joplin',
                    mailCounty: 'Jasper',
                    mailState: 'MO',
                    mailSuiteAptNumber: 'Suite 12',
                    mailZip: '64801',
                    name: 'Lotor Esrat Obligee',
                    phone: '(888) 811-8290',
                    physicalAddress: '123 Oak Street',
                    physicalCity: 'Joplin',
                    physicalCounty: 'Jasper',
                    physicalState: 'MO',
                    physicalSuiteAptNumber: 'Suite 12',
                    physicalZip: '64801',
                    websiteURL: 'doloregestasrhoncus.ca'
                },
                desiredEffectiveDate: '7/22/2018',
                submissionSignature: 'Automated Tester',
                nameOnBond: 'JOHN J ZRGKBXP'
            },
            {
                value: 10,
                label: '498 - CHARLES E TLNPGK',

                $ownerCount: '2',
                people: [
                    {
                        firstName: 'CHARLES',
                        middleName: 'E',
                        lastName: 'TLNPGK',
                        suffix: '',
                        ssn: '666-44-6271',
                        dateOfBirth: '2/6/1958',
                        physicalAddress: 'PO BOX 224 ',
                        mailAddress: 'PO BOX 224 ',
                        physicalCity: 'RIDGEWAY',
                        mailCity: 'RIDGEWAY',
                        physicalState: 'NC',
                        mailState: 'NC',
                        physicalZip: '27570',
                        mailZip: '27570',
                        homePhone: '(956) 131-1267',
                        cellPhone: '(956) 131-1267',
                        employerName: 'Amet Risus Consulting',
                        employerAddress: '837-2071 Fames Avenue',
                        employerSuiteAptNumber: '5675 Dui Av.',
                        employerZip: '64813',
                        employerState: 'LA',
                        employerCity: 'Lafayette',
                        employerCounty: 'New Orleans',
                        residenceType: 'Own',
                        dateMovedToResidence: '06/16/29',
                        mailCounty: 'ATLANTA',
                        physicalCounty: 'ATLANTA',
                        yearsExperience: 4,
                        maritalStatus: 'Married',
                        spouseFirstName: 'Glenna',
                        spouseMiddleName: 'Martina',
                        spouseLastName: 'Rosales',
                        spouseGender: 'F',
                        spouseDateOfBirth: '08/24/1966',
                        spouseSsn: '325-43-5950',
                        spouseEmail: 'libero@atiaculis.co.uk',
                        spouseCellPhone: '(456) 516-4874',
                        jobTitle: 'Sed',
                        driversLicenseNumber: '820362298',
                        driversLicenseState: 'MI',
                        fax: '(891) 783-1191',
                        email: 'Aenean@malesuada.net',
                        prefix: 'Mrs.',
                        gender: 'M'

                    },
                    {
                        firstName: 'THERESE',
                        middleName: 'C',
                        lastName: 'ZXUNGDF',
                        suffix: '',
                        ssn: '666-60-6057',
                        dateOfBirth: '01/29/1962',
                        physicalAddress: 'PO BOX 609638 ',
                        mailAddress: 'PO BOX 609638 ',
                        physicalCity: 'CLEVELAND',
                        mailCity: 'CLEVELAND',
                        physicalState: 'OH',
                        mailState: 'OH',
                        physicalZip: '44109',
                        mailZip: '44109',
                        homePhone: '(993) 560-4557',
                        cellPhone: '(993) 560-4557',
                        employerName: 'Nibh Donec Est LLP',
                        employerAddress: 'P.O. Box 439, 7701 Elit, Ave',
                        employerSuiteAptNumber: 'P.O. Box 391, 4000 Per Road',
                        mailCounty: 'ATLANTA',
                        physicalCounty: 'ATLANTA',
                        yearsExperience: 4,
                        employerZip: '86854',
                        employerState: 'CT',
                        employerCity: 'Hartford',
                        employerCounty: 'Hartford',
                        residenceType: 'Own',
                        dateMovedToResidence: '03/04/64',
                        maritalStatus: 'Married',
                        spouseFirstName: 'Quail',
                        spouseMiddleName: 'Nayda',
                        spouseLastName: 'Stephenson',
                        spouseGender: 'M',
                        spouseDateOfBirth: '09/14/1969',
                        spouseSsn: '776-44-2467',
                        spouseEmail: 'eu@antebibendum.edu',
                        spouseCellPhone: '(579) 873-2978',
                        jobTitle: 'porttitor',
                        driversLicenseNumber: '198989929',
                        driversLicenseState: 'MT',
                        fax: '(689) 713-2718',
                        email: 'sociis.natoque@nibhenimgravida.org',
                        prefix: 'Dr.',
                        gender: 'F'
                    }
                ],
                companies: [
                    {
                        name: 'Fames Ac PC',
                        dba: 'Nisl Elementum PC',
                        companyType: 'CCorp',
                        physicalZip: '32127',
                        physicalState: 'OK',
                        physicalCity: 'Lawton',
                        physicalCounty: 'Lawton',
                        physicalAddress: 'P.O. Box 653, 5502 Non St.',
                        dateFormed: '07/02/45',
                        fein: '14-1389',
                        mailAddress: 'Ap #448-5718 Dis Rd.',
                        mailZip: '13440',
                        mailCity: 'Broken Arrow',
                        mailState: 'AK',
                        mailCounty: 'Lawton',
                        mailSuiteAptNumber: 'Ap #664-6847 Dolor Rd.',
                        physicalSuiteAptNumber: '893-103 Bibendum Road',
                        email: 'in.magna.Phasellus@nequeNullamut.com',
                        fax: '(845) 284-6619',
                        phone: '(117) 531-1470'
                    }
                ],
                applicationQuestionResponses: [
                    {
                        selectedValue: 'X757Y4QS18U99E2',
                        masterApplicationQuestionId: '3',
                        questionText: 'What is your state license number?'
                    },
                    {
                        selectedValue: 'Yes',
                        masterApplicationQuestionId: '1',
                        questionText: 'Are you trust worthy?',
                        originType: 'Underwriting'
                    }
                ],
                obligee: {
                    contact1: 'John Krazinski',
                    contact2: 'Emily Redden',
                    contact3: 'Tyler Smith',
                    email: 'tortor.dictum@doloregestasrhoncus.ca',
                    fax: '(561) 372-0098',
                    mailAddress: '123 Oak Street',
                    mailCity: 'Joplin',
                    mailCounty: 'Jasper',
                    mailState: 'MO',
                    mailSuiteAptNumber: 'Suite 12',
                    mailZip: '64801',
                    name: 'Lotor Esrat Obligee',
                    phone: '(888) 811-8290',
                    physicalAddress: '123 Oak Street',
                    physicalCity: 'Joplin',
                    physicalCounty: 'Jasper',
                    physicalState: 'MO',
                    physicalSuiteAptNumber: 'Suite 12',
                    physicalZip: '64801',
                    websiteURL: 'doloregestasrhoncus.ca'
                },
                desiredEffectiveDate: '7/1/2018',
                submissionSignature: 'Automated Tester',
                nameOnBond: 'CHARLES E TLNPGK'
            }
        ];
    }
}

app.service(Injectables.ApplicationTestDataCreator, ApplicationTestDataCreator);
