import { IFilterService } from "angular";
import app from "../../main";

export class SpouseNameFilter {

    public static Factory(): any {
        return (object?: any, delimeter?: string) => {
            if (!object) { return ''; }

            let name = '';

            if (delimeter) {
                name = object.spouseFirstName + '{|}' + object.spouseMiddleName + '{|}' + object.spouseLastName;
            }
            else {
                name = (object.spouseFirstName ? object.spouseFirstName : '') + ' ' +
                    (object.spouseMiddleName ? object.spouseMiddleName : '') + ' ' +
                    (object.spouseLastName ? object.spouseLastName : '');
            }

            return name;
        };
    }
}

app.filter('spouseName', SpouseNameFilter.Factory);
