import { IHttpService, IPromise } from "angular";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/settings/systemSettings";
import app from "../main";
import A3ApiResponse from "./types/a3ApiResponse";
import { AddTicketCommentDto } from "./types/addTicketCommentDto";
import { CreateSupportTicketDto } from "./types/createSupportTicketDto";
import { BondTypeRequest } from "./types/model/bondTypeRequest";
import { DocumentCorrectionRequest } from "./types/model/documentCorrectionRequest";
import { SupportTicket } from "./types/model/supportTicket";
import { SupportTicketMessage } from "./types/model/supportTicketMessage";
import { RequestDocumentCorrectionDto } from "./types/requestDocumentCorrectionDto";
export class SupportService {
    public static $inject = [
        Injectables.$http,
        Injectables.SystemSettings
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly systemSettings: SystemSettings){  
    }

    public addTicketComment(ticketId: number, message: string, currentUserId: number, currentUser: string): IPromise<void> {
        const url = this.systemSettings.apiBaseUrl + 'SupportActions/AddTicketComment';

        const req: AddTicketCommentDto = { comment: message, userId: currentUserId, usersName: currentUser, ticketId: ticketId };

        return this.$http.post(url, req).then(() => {});
    }

    public createSupportTicket(title: string, category: string, urgency: string, comments: string): IPromise<void> {
        const url = this.systemSettings.apiBaseUrl + 'SupportActions/CreateSupportTicket';

        const request: CreateSupportTicketDto = {
            category: category,
            comments: comments,
            title: title,
            urgency: urgency,
        };

        return this.$http.post(url, request).then(() => {});
    }

    public getSupportNotificationCount(): IPromise<number> {
        return this.$http
            .get<A3ApiResponse<number>>(this.systemSettings.apiBaseUrl + 'SupportActions/GetNotificationCount')
            .then((response) => response.data.value);
    }

    public getTicketCommentsById(ticketId: number): IPromise<SupportTicketMessage[]> {
        const url = this.systemSettings.apiBaseUrl + 'SupportActions/GetSupportTicketComments?id=' + ticketId;

        return this.$http.get<A3ApiResponse<SupportTicketMessage[]>>(url)
            .then((response) => {
                return response.data.value;
            });
    }

    public getTickets(userId?: number, showClosed = false, reverseOrder = false, searchPhrase?: string): IPromise<SupportTicket[]> {
        let url = this.systemSettings.apiBaseUrl + 'SupportActions/GetSupportTickets?';

        if (userId) {
            url += `&userId=${userId}`;
        }

        if (showClosed) {
            url += '&showClosed=true';
        }

        if (reverseOrder) {
            url += '&reverseOrder=true';
        }

        if (searchPhrase) {
            url += `&searchPhrase=${searchPhrase}`;
        }

        return this.$http
            .get<A3ApiResponse<SupportTicket[]>>(url)
            .then((response) => response.data.value);
    }

    public requestDocumentCorrection(documentId: number, bondId: number, bondTransactionDocumentVersionId: number, comments: string): IPromise<DocumentCorrectionRequest> {
        const url = `${this.systemSettings.apiBaseUrl}SupportActions/RequestDocumentCorrection`;
        
        const request: RequestDocumentCorrectionDto = {
            bondId: bondId,
            bondTransactionDocumentVersionId: bondTransactionDocumentVersionId,
            comments: comments,
            documentId: documentId,
        };

        return this.$http
            .post<A3ApiResponse<DocumentCorrectionRequest>>(url, request)
            .then((response) => response.data.value);
    }

    public submitBondTypeRequest(request: BondTypeRequest): IPromise<void> {
        if (!request) {
            throw new Error('request object is not valid');
        }

        return this.$http
            .post(this.systemSettings.apiBaseUrl + 'SupportActions/SubmitBondTypeRequest', request)
            .then(() => {});
    }
}

app.service(Injectables.SupportService, SupportService);
